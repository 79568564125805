<ng-container>
  <div id="q-text" class="mb-6 flex w-full items-center justify-between">
    <div
      class="line-clamp-2"
      matTooltip="{{ question.Question_Text }}"
      matTooltipClass="custom-tooltip"
      appShowIfTruncated
    >
      <strong class="text-xl">{{ question.Position }}</strong>
      <span
        class="text-xl"
        (contextmenu)="
          copyToClipBoard(question.Question_Text);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.TITLE_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
      >
        - {{ question.Question_Text }}
      </span>
    </div>
    <mat-icon
      *ngIf="question.Description !== ''"
      (click)="toggleDescriptionVisibility()"
      matTooltip="{{ plainText }}"
      class="ml-4 cursor-pointer text-[35px] text-main"
      >help
    </mat-icon>
  </div>
  <div
    (click)="!reviewMode && toggleModelVisibility()"
    id="choose-model"
    [ngClass]="{ 'disabled-border-color': reviewMode, 'cursor-pointer hover:border-dialog': !reviewMode }"
    class="mat-ff-in-rems flex items-center justify-between rounded-lg border-2 transition"
  >
    <div [ngClass]="{ 'text-gray opacity-60': !(!!answerToShow && answerToShow !== '') }" class="px-2 line-clamp-2">
      {{ reviewMode || (!!answerToShow && answerToShow !== "") ? answerToShow : ("QUEST_ANS.LIST_BOX_TITLE" | translate) }}
    </div>
    <mat-icon>arrow_drop_down</mat-icon>
  </div>
</ng-container>

<div
  *ngIf="showOptions"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showOptions }"
  (click)="toggleModelVisibility()"
>
  <div
    id="selections-div"
    class="flex h-[fit-content] w-[27vw] min-w-min flex-col items-center justify-between gap-6 overflow-hidden rounded-3xl bg-white p-6"
    (click)="$event.stopPropagation()"
  >
    <div id="title" class="font-popppins text-4xl font-bold text-card">
      {{ "QUEST_ANS.LIST_BOX_TITLE" | translate }}
    </div>
    <!-- I use formControl only as Value of radio Group -->
    <mat-radio-group
      id="option-body"
      class="flex h-full w-full flex-col gap-4 overflow-hidden"
      [value]="listBoxForm.value['Input']"
    >
      <mat-radio-button
        id="one-option"
        class="w-full cursor-pointer break-words rounded-xl bg-body p-4"
        (click)="changeAnswer(option)"
        *ngFor="let option of question.Check_List_Box_Options"
        [value]="option['id']"
      >
        <p id="option-text" class="max-w-xl truncate" *ngIf="option['Answer_Value'].length < 150">
          {{ option["Answer_Value"] }}
        </p>
        <p
          id="option-text"
          class="line-clamp-3"
          *ngIf="option['Answer_Value'].length >= 150"
          matTooltip="{{ option['Answer_Value'] }}"
          matTooltipClass="custom-tooltip"
        >
          {{ option["Answer_Value"] }}
        </p>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div
  *ngIf="showDescription"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showDescription }"
  (click)="toggleDescriptionVisibility()"
>
  <div
    id="selections-div"
    class="flex h-[fit-content] w-[55rem] flex-col justify-between gap-6 overflow-hidden rounded-2xl bg-white p-6"
    (click)="$event.stopPropagation()"
  >
    <div id="title" class="font-popppins text-4xl font-bold text-card">
      {{ "DESCRIZIONE" | translate }}
    </div>
    <div [innerHTML]="question.Description" class="py-[3rem] text-base">
      {{ question.Description }}
    </div>
    <div class="mt-[10px] flex flex-row !justify-end">
      <button
        (click)="
          copyToClipBoard(plainText);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.DESCRIPTION_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
        class="mr-3 whiteBtn"
      >
        {{ "QUEST_CREATION.DESC_COP" | translate }}
      </button>
      <button class="colorBtn" (click)="toggleDescriptionVisibility()">
        {{ "UNDERSTOOD" | translate }}
      </button>
    </div>
  </div>
</div>
