import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { filterOutElements } from 'src/app/data/data-handlers/utils.service';
import { ElencoTopic } from 'src/app/data/models/ElencoTopic';
import { TopicStoreService } from 'src/app/Store/store-services/topic-store.service';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { TOPIC_ERRORS } from './topic-errors';

@Component({
  selector: 'app-new-topic',
  templateUrl: './new-topic.component.html',
  styleUrls: ['./new-topic.component.scss'],
})
export class NewTopicComponent implements OnInit, OnChanges {
  @Input()
  topics: ElencoTopic[] = [];

  @Input()
  TitlesOfTopicsAlreadyStored: string[] = [];

  @Input()
  ReadOnlyMode = false;

  @Input()
  Model_Status = null;

  @Output()
  newTopicEvent = new EventEmitter<ElencoTopic>();

  @Output()
  modifyTopicEvent = new EventEmitter<{
    topic: ElencoTopic;
    index: number;
    indexOldOrder: number;
  }>();

  @Output()
  deleteTopicEvent = new EventEmitter<number>();

  @Output()
  topicData = new EventEmitter<{ topic: ElencoTopic; index: number } | []>();

  selectedTopic: ElencoTopic;

  topic_option_index: number;

  show_topic_options = false;

  returnedTopic: ElencoTopic | null;

  TopicForm = new FormGroup({
    TopicTitle: new FormControl('', [Validators.required]),
    TitleDescription: new FormControl(''),
  });

  constructor(
    private dialogService: GeneralDialogService,
    private notify: NotifyService,
    private topicStoreService: TopicStoreService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.topics.previousValue !== undefined) {
      if (changes.topics.currentValue.length !== changes.topics.previousValue.length) {
        this.selectedTopic = this.topics[this.topics.length - 1];
      }
    }
  }

  ngOnInit(): void {
    this.selectedTopic = this.topics[0];
  }

  createTopic() {
    this.openTopicDialog({
      length: this.topics.length,
      assignedMode: false,
      Action: 'Add',
    });
  }

  modifyTopic(topic: ElencoTopic, i: number) {
    this.show_topic_options = false;

    this.openTopicDialog({
      Topic: topic,
      length: this.topics.length,
      Action: 'Modify',
      assignedMode: false,
      modelStatus: this.Model_Status,
    });
  }

  deleteTopic(topic: ElencoTopic, i: number) {
    this.dialogService.openConfirmDialog({
      info: CONFIRMDIALOGSTRINGS.CREATETOPIC,
      topic: topic,
    });

    this.dialogService.openConfirmDialogResponse().subscribe((res) => {
      if (res !== 'Cancel') {
        this.deleteTopicEvent.emit(i);
        this.show_topic_options = false;
        if (i === 0) {
          this.topicData.emit({ topic: this.topics[i], index: i });
        } else {
          this.topicData.emit({ topic: this.topics[i - 1], index: i - 1 });
        }
      }
    });
  }

  duplicateTopic(topic: ElencoTopic, i: number) {
    this.selectedTopic = topic;
    this.show_topic_options = false;
    const duplicate = JSON.parse(JSON.stringify(topic));
    let num = 1;

    while (true) {
      let flag = false;
      for (let j = 0; j < this.topics.length; j++) {
        if (this.topics[j].Title === topic.Title + num) {
          flag = true;
          break;
        }
      }
      if (flag) {
        num++;
      } else {
        duplicate.Title = topic.Title + num;
        break;
      }
    }

    duplicate.Order = this.topics.length + 1;

    if (!this.doesTitleAlreadyExists(this.TitlesOfTopicsAlreadyStored, duplicate.Title)) {
      this.newTopicEvent.emit(duplicate);
      this.show_topic_options = false;
    } else {
      this.notify.openWarningSwal('Impossibile inserire titolo già esistente');
    }
  }

  showTopicData(topic: ElencoTopic, i: number) {
    this.selectedTopic = topic;
    this.topicData.emit({ topic: this.selectedTopic, index: i });
  }

  doesTitleAlreadyExists(array: string[], title: string): boolean {
    return array.includes(title.toLowerCase().replace(/\s/g, ''));
  }

  drop(event: CdkDragDrop<ElencoTopic[]>) {
    let temp = [...this.topics];
    moveItemInArray(temp, event.previousIndex, event.currentIndex);
    temp = temp.map((item, index) => {
      item = { ...item, Order: index + 1 };
      return item;
    });
    this.topicStoreService.updateAllTopics(temp);
    this.showTopicData(temp[event.currentIndex], event.currentIndex);
  }

  openTopicDialog(dialogData: {
    Topic?: ElencoTopic;
    length: number;
    Action: 'Modify' | 'Add';
    assignedMode: boolean;
    modelStatus?: string;
  }): ElencoTopic | void {
    let oldOrder: number;
    if (!!dialogData.Topic) {
      oldOrder = dialogData.Topic.Order;
    }

    this.dialogService.topicCRUDdialog(dialogData);

    this.dialogService.topicCRUDdialogResponse().subscribe((res) => {
      if (res) {
        const arrayToCheck =
          dialogData.Action === 'Modify'
            ? filterOutElements(this.TitlesOfTopicsAlreadyStored, dialogData.Topic.Title)
            : this.TitlesOfTopicsAlreadyStored;
        if (!this.doesTitleAlreadyExists(arrayToCheck, res.Title)) {
          if (dialogData.Action === 'Add') {
            this.newTopicEvent.emit(res);
            this.topicData.emit({ topic: res, index: res.Order - 1 });
          } else {
            this.selectedTopic = res;
            this.modifyTopicEvent.emit({
              topic: res,
              index: res.Order - 1,
              indexOldOrder: oldOrder - 1,
            });
          }
        } else {
          this.notify.openWarningSwal(`${TOPIC_ERRORS.TITLE_ALREADY_EXISTS}`);
          return null;
        }
      }
    });
  }
}
