import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import {
  getQuestionnaireToBeAnswered,
  SubmitQuestionnaires,
} from 'src/app/Store/assignedQuestionnaires/actions/assigned-questionnaires.actions';
import { Store } from '@ngrx/store';
import { updateTopicsUser } from 'src/app/Store/topics-store/topic.actions';
import {
  isQuestionnaireSubmitted,
  selectContainsFile,
  selectFileRepoLoading,
  selectNumberOfCompletedTopics,
  seletQuestionnaireToAnswer,
} from 'src/app/Store/assignedQuestionnaires/selectors/assigned-questionnaires.selectors';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { User } from 'src/app/data/models/User';
import { homeQuestionnaire } from 'src/app/data/models/response-apis/homeQuestionnaire';
import {
  changeResponsible,
  closeProjectAction,
  getSingleProject,
} from 'src/app/Store/projects/actions/projects.actions';
import { QuestionnaireActionTypes } from 'src/app/Store/assignedQuestionnaires/actions/assigned-questionnaires.actions';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnairesAssignedToUser } from 'src/app/data/models/QuestionnairesAssignedToUser';
import { selectProjectToBeModified } from 'src/app/Store/projects/selectors/projects.selectors';
import { first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home-questionnaire',
  templateUrl: './home-questionnaire.component.html',
  styleUrls: ['./home-questionnaire.component.scss'],
})
export class HomeQuestionnaireComponent implements OnInit, OnDestroy {
  @Input()
  questionnaire: homeQuestionnaire;

  @Input()
  currentUser: string;

  @Input()
  users: {};

  private ngUnsubscribe = new Subject();

  showResponsiblePopUp = false;

  completedTopics: Observable<number>;

  typeOfPopUp:
    | 'ResponsibleAndFinished'
    | 'ResponsibleAndUnfinished'
    | 'notResponsibleAndfinished'
    | 'notResponsibleAndUnfinished'
    | 'watchlistFinished'
    | 'watchlistUnFinished';

  isCurrentUserResponsible = false;

  goForAnswer = ROUTES_STRINGS.ANSWERQUESTIONNAIRE;

  percentage = 0;

  notes: Observable<string[]>;

  numberOfUnreadedNotes = 0;

  questIDToExport: string;

  modelloName = '';
  responsabileName = '';
  questionarioName = '';
  questionariName = '';
  singleTopicName = '';
  topicName = '';

  currentDate = new Date();

  @Input()
  lang = '';

  //Export Popup
  showExportPopup = false;
  exportMethod: string;
  exMethodsArr: string[] = ['Questionnaire', 'Attachments'];
  infoFileRepo$: Observable<QuestionnairesAssignedToUser>;
  loadingFileRepo$: Observable<boolean>;
  containsFile$: Observable<boolean>;

  constructor(
    private dialog: GeneralDialogService,
    private store: Store,
    private route: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (Number(this.questionnaire['Number_Of_Notes']) > Number(this.questionnaire['Number_of_Readed_Notes'])) {
      this.numberOfUnreadedNotes =
        Number(this.questionnaire['Number_Of_Notes']) - Number(this.questionnaire['Number_of_Readed_Notes']);
    } else {
      this.numberOfUnreadedNotes = null;
    }

    this.completedTopics = this.store.select(selectNumberOfCompletedTopics, {
      assignedId: this.questionnaire.questAssignId,
    });
    this.isCurrentUserResponsible = this.questionnaire.responsible === this.currentUser;

    if (this.isCurrentUserResponsible) {
      this.percentage = (this.questionnaire.Completed_Topics / Number(this.questionnaire.Number_Of_Topics)) * 100;
      if (Number(this.questionnaire.Completed_Topics) === this.questionnaire.topics.length) {
        this.typeOfPopUp = 'ResponsibleAndFinished';
      } else {
        this.typeOfPopUp = 'ResponsibleAndUnfinished';
      }
    } else if (this.questionnaire.TopicsOfUsers === '0') {
      this.percentage = (this.questionnaire.Completed_Topics / Number(this.questionnaire.Number_Of_Topics)) * 100;
      if (Number(this.questionnaire.Completed_Topics) === this.questionnaire.topics.length) {
        this.typeOfPopUp = 'watchlistFinished';
      } else {
        this.typeOfPopUp = 'watchlistUnFinished';
      }
    } else {
      this.percentage =
        (Number(this.questionnaire['CompletedTopicsOfUsers']) / Number(this.questionnaire['TopicsOfUsers'])) * 100;
      if (Number(this.questionnaire['TopicsOfUsers']) === Number(this.questionnaire['CompletedTopicsOfUsers'])) {
        this.typeOfPopUp = 'notResponsibleAndfinished';
      } else {
        this.typeOfPopUp = 'notResponsibleAndUnfinished';
      }
    }
  }

  getNotes() {
    this.numberOfUnreadedNotes = null;

    this.dialog.openNotesModal({
      notes: null,
      Project_Title: this.questionnaire.projectTitle,
      Quest_Assing_ID: this.questionnaire.questAssignId,
      pageIndex: 0,
      Status: this.questionnaire.assignedStatus,
      user: this.questionnaire.responsible,
    });
  }

  exportSingleCsv(quest: any, exportMethod?: string) {
    this.showExportPopup = !this.showExportPopup;
    var isAttachment: boolean = false;
    if (exportMethod && exportMethod.toLowerCase().includes('attachments')) {
      isAttachment = true;
    }
    this.questIDToExport = quest.questAssignId;
    this.store.dispatch({
      type: QuestionnaireActionTypes.exportSingleCsv,
      data: [this.questIDToExport],
      lang: this.lang,
      topic: this.translate.instant('DICTIONARY.SingleTopic'),
      downloadFiles: isAttachment,
    });
  }

  goToAnwswer() {
    this.route.navigateByUrl(`/${ROUTES_STRINGS.ANSWERQUESTIONNAIRE}/${this.questionnaire.questAssignId}`);
  }

  openCompleteModal() {
    this.dialog.openHomePopUp(this.typeOfPopUp);
    this.dialog
      .openHomePopUpReturn()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        switch (res.result) {
          case 'goToUnfinishedTopic':
            this.goToAnwswer();
            break;
          case 'CompleteFromNotResponsible':
            this.assignQuestAssigneToTopics(res.note);
            break;
          case 'Submit':
            this.submitQuestionnaires();
            break;
          default:
            break;
        }
      });
  }

  changeResponsiblePopUp() {
    this.showResponsiblePopUp = !this.showResponsiblePopUp;
  }

  submitQuestionnaires() {
    let ids = [];
    ids.push({ id: this.questionnaire.questAssignId });

    this.store.dispatch(SubmitQuestionnaires({ ids }));

    //Close project if every questAssign is submitted
    this.store
      .select(isQuestionnaireSubmitted)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loading) => {
        if (!loading) {
          this.store.dispatch(
            getSingleProject({
              projectId: this.questionnaire.projectId,
            })
          );
          this.store
            .select(selectProjectToBeModified)
            .pipe(
              map((project) => {
                if (project) {
                  return Object.keys(project.questionnaires)
                    .map((k) => project.questionnaires[k])
                    .every((quest) => {
                      return quest.Status === 'Submitted';
                    });
                }
              }),
              first((val) => val),
              takeUntil(this.ngUnsubscribe)
            )
            .subscribe((isAllSubmitted) => {
              if (isAllSubmitted) {
                this.store.dispatch(
                  closeProjectAction({
                    projectId: this.questionnaire.projectId,
                  })
                );
              }
            });
        }
      });
  }

  assignQuestAssigneToTopics(note) {
    let topicsId = [];
    this.questionnaire.topics.map((topic) => {
      topicsId.push({ id: topic.Assigned_id });
    });
    this.store.dispatch(
      updateTopicsUser({
        Assigned_id: this.questionnaire.questAssignId,
        topicAssignedIds: topicsId,
        note: note,
        backToResponsible: true,
        fromHome: true,
      })
    );
  }

  changeResponsible(newResponsible: User) {
    let projectId = this.questionnaire.projectId;
    this.store.dispatch(
      changeResponsible({
        projectId,
        username: newResponsible.username,
        oldUser: this.questionnaire.responsible,
        assingedId: this.questionnaire.questAssignId,
      })
    );
  }

  toggleExportVisibility() {
    this.showExportPopup = !this.showExportPopup;
    if (this.showExportPopup) {
      this.store.dispatch(
        getQuestionnaireToBeAnswered({
          id: this.questionnaire.questAssignId,
        })
      );
      this.infoFileRepo$ = this.store.select(seletQuestionnaireToAnswer);
      this.loadingFileRepo$ = this.store.select(selectFileRepoLoading);
      this.containsFile$ = this.store.select(selectContainsFile);
    }
  }

  goToFileRepo() {
    this.dialog.openFileRepoModal({
      Quest_Assing_Id: this.questionnaire.questAssignId,
      Quest_Template_Id: this.questionnaire.templateQuestId,
      Quest_Assign_Title: this.questionnaire.projectTitle,
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
