import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, throwError } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { ActionTypes } from './state.actions';

import { convertToNewQuest, createDeleteArray } from 'src/app/data/data-handlers/utils.service';
import { Router } from '@angular/router';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';

import { GetQuestionnairService } from 'src/app/data/api/Questionnaire/get-questionnaires.service';
import { PostQuestionnaireService } from 'src/app/data/api/Questionnaire/post-questionnaire.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { englishToItalian } from 'src/app/utils/config/questionnaire-table.configuration';

@Injectable()
export class QuestionnaireEffects {
  exportModelCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.exportModelCsvType),
        tap((payload) =>
          this.questService.getModelCsv(payload['data'][0], payload['typeExcel'], payload['lang'], payload['topic'])
        )
      ),
    { dispatch: false }
  );

  loadQuestionnairesStructure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.loadQuestionnairesType),
      mergeMap((payload) =>
        this.questService
          .getQuestionnairyStructures(
            payload['pageIndex'],
            10,
            !!payload['title'] ? payload['title'] : 'Questionnaire_Title',
            !!payload['AscDesc'] ? payload['AscDesc'] : 'ASC',
            !!payload['archived'] ? payload['archived'] : false,
            !!payload['searchString'] ? payload['searchString'] : '',
            !!payload['modelFilter'] ? payload['modelFilter'] : '',
            !!payload['creatorFilter'] ? payload['creatorFilter'] : '',
            !!payload['statusFilter'] ? payload['statusFilter']['draft'] : true,
            !!payload['statusFilter'] ? payload['statusFilter']['active'] : true,
            payload['lastAssignmentFromFilter'] ? payload['lastAssignmentFromFilter'] : '',
            payload['lastAssignmentToFilter'] ? payload['lastAssignmentToFilter'] : '',
            payload['lastChangeFromFilter'] ? payload['lastChangeFromFilter'] : '',
            payload['lastChangeToFilter'] ? payload['lastChangeToFilter'] : '',
            payload['creationFromFilter'] ? payload['creationFromFilter'] : '',
            payload['creationToFilter'] ? payload['creationToFilter'] : ''
          )
          .pipe(
            map((quests) => ({
              type: ActionTypes.setQuestionnairesType,
              data: quests,
            })),
            catchError(() => EMPTY)
          )
      )
    );
  });

  PostQuestionnaires$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.addQuestionnairesType),
      mergeMap((payload: { newQuestionnaire: QuestionnairesStructure; type: ActionTypes.addQuestionnairesType }) =>
        this.postService.createQuestionnaire(payload.newQuestionnaire).pipe(
          map(() => {
            return { type: ActionTypes.addQuestionnairesTypeSuccess };
          }),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            //return {​​​​type: ActionTypes.addQuestionnairesTypeFail}​​​​;
            return of({
              type: ActionTypes.addQuestionnairesTypeFail,
              error: err,
            });
          })
        )
      )
    );
  });

  QuestionnairePostFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.addQuestionnairesTypeFail),
      mergeMap(() =>
        of(true).pipe(
          map(() => {
            this.route.navigateByUrl(`/${ROUTES_STRINGS.CREATENEWQUESTIONNAIRE}`);
            return { type: ActionTypes.REDIRECT_DONE };
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
  RedirectEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.addQuestionnairesTypeSuccess),
      mergeMap(() =>
        of(true).pipe(
          map(() => {
            this.route.navigateByUrl(`/${ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT}`);
            return { type: ActionTypes.REDIRECT_DONE };
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  UpdateQuestionnaires$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.updateQuestionnairesType),
      mergeMap((payload) =>
        this.postService.updateQuestionnaire(payload['modifiedQuestionnaire']).pipe(
          map(() => {
            return { type: ActionTypes.addQuestionnairesTypeSuccess };
          }),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            return of({
              type: ActionTypes.updateQuestionnairesTypeFail,
              modifiedQuestionnaire: payload['modifiedQuestionnaire'],
              error: err,
            });
          })
        )
      )
    );
  });
  UpdateQuestionnairesFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.updateQuestionnairesTypeFail),
      mergeMap((payload) =>
        of(true).pipe(
          map(() => {
            this.route.navigateByUrl(
              `/${ROUTES_STRINGS.MODIFYQUESTIONNAIRE}/${payload['modifiedQuestionnaire']['id']}`
            );
            return { type: ActionTypes.REDIRECT_DONE };
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GetSingleQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.getSingleQuestionnaire),
      mergeMap((payload: { id: string }) =>
        this.questService.getQuestionnaire({ id: payload.id }).pipe(
          map((result) => ({
            type: ActionTypes.setModifiedQuestionnaire,
            questionnaire: result,
          })),
          catchError(() => EMPTY)
        )
      )
    );
  });

  DuplicateQuestionnaireSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.duplicateQuestionnaireActionSuccess),
      mergeMap((payload) =>
        this.postService
          .createQuestionnaire(convertToNewQuest(payload['questionnaire'], payload['newTitle']), true)
          .pipe(
            map(() => ({
              type: ActionTypes.loadQuestionnairesType,
              pageIndex: payload['pageIndex'],
              title: payload['title'],
              AscDesc: payload['AscDesc'],
              archived: payload['archived'],
              searchString: payload['searchString'],
              modelFilter: payload['modelFilter'],
              creatorFilter: payload['creatorFilter'],
              statusFilter: payload['statusFilter'],
              lastAssignmentFromFilter: payload['lastAssignmentFromFilter'],
              lastAssignmentToFilter: payload['lastAssignmentToFilter'],
              lastChangeFromFilter: payload['lastChangeFromFilter'],
              lastChangeToFilter: payload['lastChangeToFilter'],
              creationFromFilter: payload['creationFromFilter'],
              creationToFilter: payload['creationToFilter'],
            })),
            catchError((err) => {
              this.notifyService.openWarningSwal(err.error.message);
              return throwError(err);
            })
          )
      )
    );
  });

  DuplicateQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.duplicateQuestionnaireAction),
      mergeMap((payload: { id: string; newTitle: string }) =>
        this.questService.getQuestionnaire({ id: payload.id }).pipe(
          map((result) => ({
            type: ActionTypes.duplicateQuestionnaireActionSuccess,
            questionnaire: result,
            newTitle: payload.newTitle,
            pageIndex: payload['pageIndex'],
            title: payload['title'],
            AscDesc: payload['AscDesc'],
            archived: payload['archived'],
            searchString: payload['searchString'],
            modelFilter: payload['modelFilter'],
            creatorFilter: payload['creatorFilter'],
            statusFilter: payload['statusFilter'],
            lastAssignmentFromFilter: payload['lastAssignmentFromFilter'],
            lastAssignmentToFilter: payload['lastAssignmentToFilter'],
            lastChangeFromFilter: payload['lastChangeFromFilter'],
            lastChangeToFilter: payload['lastChangeToFilter'],
            creationFromFilter: payload['creationFromFilter'],
            creationToFilter: payload['creationToFilter'],
          })),
          catchError(() => EMPTY)
        )
      )
    );
  });

  deleteQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.deleteQuestionnairesType),
      mergeMap((payload) =>
        this.postService.deleteQuestionnaire(createDeleteArray(payload['deletedIds'])).pipe(
          map(() => ({
            type: ActionTypes.loadQuestionnairesType,
            pageIndex: payload['pageIndex'],
            title: payload['title'],
            AscDesc: payload['AscDesc'],
            archived: payload['archived'],
            searchString: payload['searchString'],
            modelFilter: payload['modelFilter'],
            creatorFilter: payload['creatorFilter'],
            statusFilter: payload['statusFilter'],
            lastAssignmentFromFilter: payload['lastAssignmentFromFilter'],
            lastAssignmentToFilter: payload['lastAssignmentToFilter'],
            lastChangeFromFilter: payload['lastChangeFromFilter'],
            lastChangeToFilter: payload['lastChangeToFilter'],
            creationFromFilter: payload['creationFromFilter'],
            creationToFilter: payload['creationToFilter'],
          })),
          catchError(() => EMPTY)
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private questService: GetQuestionnairService,
    private postService: PostQuestionnaireService,
    private route: Router,
    private notifyService: NotifyService
  ) {}
}
