import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  userName: string;
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getUserToken();

    const headers = {
      Authorization: `Bearer ${token}`,
      responseType: 'json',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const headers2 = {
      Authorization: `Bearer ${token}`,
      responseType: 'json',
      Accept: 'application/json',
    };

    if (
      request.url.includes('searchProject') ||
      (request.url != 'upload' && !(request.url.indexOf('file') >= 0) && !request.url.includes('adminGroups'))
    ) {
      request = request.clone({
        url: `${environment.apiUrl}/${request.url}`,
        setHeaders: headers,
      });
    } else if (request.url.includes('adminGroups')) {
      request = request.clone({
        url: `${environment.apiUrl}/${request.url}`,
        setHeaders: headers2,
      });
    } else {
      request = request.clone({
        url: `${environment.apiUrl}/${request.url}`,
      });
    }

    return next.handle(request);
  }
}
