import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-box-question',
  templateUrl: './list-box-question.component.html',
  styleUrls: ['./list-box-question.component.scss'],
})
export class ListBoxQuestionComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  lang: string;
  @Input()
  listBoxForm: FormControl;

  plainText = '';
  showOptions = false;
  showDescription = false;

  answerToShow = null;
  checkboxValue: string | number;

  constructor(private clipboardc: Clipboard, private _snackBar: MatSnackBar, public translate: TranslateService) {
    super(clipboardc);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.plainText = this.extractContent(this.question.Description).replace(/  /gi, ' ');
    this.answerToShow = !!this.question.Answers[0] ? this.question.Answers[0].Value_Answer : '';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 1500 });
  }

  toggleModelVisibility() {
    this.showOptions = !this.showOptions;
  }

  toggleDescriptionVisibility() {
    this.showDescription = !this.showDescription;
  }

  extractContent(stringToConvert) {
    var span = document.createElement('span');
    span.innerHTML = stringToConvert;
    return span.textContent || span.innerText;
  }

  changeAnswer(option: { id: string; Answer_Value: string }) {
    // I use formControl only as Value of radio Group
    this.listBoxForm.setValue({ Input: option.id });
    this.answerToShow = option.Answer_Value;
    this.showOptions = false;
  }
}
