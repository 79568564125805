<ng-container *ngIf="modelVisibilityForm">
  <ng-container *ngIf="valueMissing">
    <p class="mt-2 flex w-full items-center justify-center text-[1rem] text-red-500">
      <mat-icon>warning</mat-icon>{{ "USER_TABLE.GROUP_MISS" | translate }}
    </p>
  </ng-container>
  <form [formGroup]="" class="mx-auto mt-4 h-[fit-content] w-[30vw]">
    <mat-form-field class="search-input-rounded w-full !text-[0.8rem]" appearance="outline">
      <mat-icon class="relative" matPrefix>search</mat-icon>
      <input
        type="search"
        matInput
        class="!text-xl"
        placeholder="{{ 'USER_TABLE.SEARCH_CLIENT_GROUP' | translate }}"
        [formControl]="nameFilter"
        value=""
      />
    </mat-form-field>
  </form>
  <div class="mr-auto ml-auto mt-4 w-[fit-content]" [formGroup]="modelVisibilityForm">
    <ng-container *ngIf="filteredClientGroup$ | async">
      <details class="w-[22vw]" open>
        <summary>
          <div class="mb-2 flex items-center justify-start gap-2">
            <mat-checkbox formControlName="admin" class="mx-2"> </mat-checkbox>
            <span class="text-black">Admin</span>
          </div>
        </summary>
        <ng-container *ngFor="let client of filteredClientGroup$ | async; let index = index">
          <details class="mb-2 mt-4 pl-8" open>
            <summary>
              <div>
                <mat-checkbox class="mx-2 mb-2" formControlName="{{ client.client_id }}"> </mat-checkbox>
                <span class="text-black">{{ client.client_name }}</span>
              </div>
            </summary>
            <ng-container *ngIf="client.groups.length > 0">
              <ng-container *ngFor="let group of client.groups; let index = index">
                <div class="mb-2 flex items-center justify-start gap-2 pl-10">
                  <mat-checkbox class="mx-2" formControlName="{{ group.id }}"> </mat-checkbox>
                  <span>{{ group.name }}</span>
                </div>
              </ng-container>
            </ng-container>
          </details>
        </ng-container>
      </details>
    </ng-container>
  </div>
</ng-container>
