import { createReducer, on } from '@ngrx/store';
import {
  createClient,
  createGroup,
  createGroupFail,
  getAllClients,
  getAllClientsSuccess,
  getGroupsByClientIdSuccess,
  getGroupWithUsers,
  getGroupWithUsersSuccess,
  setActiveClient,
  setActiveGroup,
  setClientId,
  setCreateMode,
  setEditMode,
  setFirstTimeOfCreateClient,
  setGroupId,
  setIsUpdate,
  updateClient,
  updateClientFail,
} from './admin-groups.actions';

import { initialAdminGroupsState } from './admin-groups.state';

export const _adminGroupsReducer = createReducer(
  initialAdminGroupsState,
  on(getAllClients, (state) => ({
    ...state,
    loading: true,
  })),
  on(getAllClientsSuccess, (state, { clientsData, clientsDataTotal, clientsDataPageIndex }) => {
    return {
      ...state,
      loading: false,
      clientsData: clientsData,
      clientsDataTotal: clientsDataTotal,
      clientsDataPageIndex: clientsDataPageIndex,
    };
  }),
  on(createClient, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(setActiveClient, (state, { activeClient }) => {
    return {
      ...state,
      loading: true,
      activeClient: activeClient,
    };
  }),
  on(setEditMode, (state, { editMode }) => {
    return {
      ...state,
      loading: true,
      editMode: editMode,
    };
  }),
  on(updateClient, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(updateClientFail, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(setClientId, (state, { client_id }) => {
    return {
      ...state,
      loading: false,
      client_id: client_id,
    };
  }),
  on(createGroup, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(createGroupFail, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(getGroupWithUsers, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(getGroupWithUsersSuccess, (state, { activeUsers, usersTotal, usersPageIndex }) => {
    return {
      ...state,
      loading: false,
      activeUsers: activeUsers,
      usersTotal: usersTotal,
      usersPageIndex: usersPageIndex,
    };
  }),
  on(setGroupId, (state, { group_id }) => {
    return {
      ...state,
      loading: false,
      group_id: group_id,
    };
  }),
  on(getGroupsByClientIdSuccess, (state, { clientGroups, clientGroupsTotal, clientGroupsPageIndex }) => {
    return {
      ...state,
      loading: false,
      clientGroups: clientGroups,
      clientGroupsTotal: clientGroupsTotal,
      clientGroupsPageIndex: clientGroupsPageIndex,
    };
  }),
  on(setCreateMode, (state, { createMode }) => {
    return {
      ...state,
      loading: false,
      createMode: 'create',
    };
  }),
  on(setActiveGroup, (state, { activeGroup }) => {
    return {
      ...state,
      loading: true,
      activeGroup: activeGroup,
    };
  }),
  on(setFirstTimeOfCreateClient, (state, { firstTimeCreateClient }) => {
    return {
      ...state,
      loading: false,
      firstTimeCreateClient: firstTimeCreateClient,
    };
  }),
  on(setIsUpdate, (state, { isUpdate }) => {
    return {
      ...state,
      loading: false,
      isUpdate: isUpdate,
    };
  })
);

export function adminGroupsReducer(state, action) {
  return _adminGroupsReducer(state, action);
}
