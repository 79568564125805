import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NotifyService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-domanda-vincolata-dialog',
  templateUrl: './domanda-vincolata-dialog.component.html',
  styleUrls: ['./domanda-vincolata-dialog.component.scss'],
})
export class DomandaVincolataDialogComponent implements OnInit {
  selectedQuestion: any;

  selectedOptions: any;

  freeTextConstraint: any;

  local_data = { ...this.data };

  questions = [];

  constructor(
    public DomVinDialog: MatDialogRef<DomandaVincolataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public notify: NotifyService
  ) {}

  ngOnInit(): void {
    this.local_data = { ...this.data };
    for (let item of this.local_data.questions) {
      if (this.local_data.currentQuestion !== undefined) {
        if (this.local_data.currentQuestion.id !== item.id) {
          this.questions.push(item);
        }
      } else {
        this.questions.push(item);
      }
    }

    if (this.local_data.action === 'Modify') {
      this.selectedQuestion = this.local_data.selectedQuestion;

      this.selectedOptions = this.local_data.selectedOptions;

      this.freeTextConstraint = this.local_data.freeTextConstraint;
    }
  }

  save() {
    if (
      this.selectedQuestion !== undefined &&
      (this.selectedQuestion.TypeOfQuestion === 'List box' ||
        this.selectedQuestion.TypeOfQuestion === 'Check-list box') &&
      this.selectedOptions === undefined
    ) {
      this.notify.openWarningSwal('Selezionare almeno una risposta');
    } else if (this.selectedQuestion === undefined) {
      this.notify.openWarningSwal('Specificare la domanda vincolante');
    } else {
      this.DomVinDialog.close({
        question: this.selectedQuestion,
        freeTextConstraint: this.selectedQuestion.TypeOfQuestion === 'Free text' ? this.freeTextConstraint : '',
        options: this.selectedOptions,
      });
    }
  }

  cancel() {
    this.DomVinDialog.close({
      message: 'Cancel',
    });
  }
}
