import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClientGroupId, NewUser, UsernamePassword } from 'src/app/data/models/User';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { UserActions } from 'src/app/Store/users/users.actions';

@Component({
  selector: 'admin-users-tab',
  templateUrl: './admin-users-tab.component.html',
  styleUrls: ['./admin-users-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersTabeComponent implements OnInit, OnDestroy {
  profileMissing = false;
  rightsMissing = false;
  accessMissing = false;
  userVisibilityMissing = false;
  modelVisibilityMissing = false;
  organizationMissing = false;

  newUser: NewUser;

  selectedTab;

  constructor(private route: Router, private store: Store) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  goBackToTable() {
    this.route.navigateByUrl(`/${ROUTES_STRINGS.ADMINGROUPS}/users-page`);
  }

  newProfileHandler(event: FormGroup) {
    if (event) {
      this.newUser = { ...this.newUser, ...event.value };
    } else {
      this.newUser = { ...this.newUser, name: null, surname: null, email: null, tel: null };
    }
  }

  newUsernamePasswordHandler(event: UsernamePassword) {
    this.newUser = { ...this.newUser, ...event };
  }

  newAccessHandler(event: FormGroup) {
    this.newUser = { ...this.newUser, user_access: event.value };
  }

  newUserVisibilityHandler(event: string[]) {
    if (event && event.length > 0) {
      this.userVisibilityMissing = false;
    }
    this.newUser = { ...this.newUser, userVisibility: event };
  }

  newModelVisibilityHandler(event: string[]) {
    if (event && event.length > 0) {
      this.modelVisibilityMissing = false;
    }
    this.newUser = { ...this.newUser, modelProjectVisibility: event };
  }

  newOrganizationHandler(event: ClientGroupId) {
    this.newUser = { ...this.newUser, organization: event };
  }

  saveHandler() {
    console.log('this.newUser', this.newUser);
    if (!this.newUser || !this.newUser.name || !this.newUser.surname || !this.newUser.email || !this.newUser.tel) {
      this.selectedTab = 0;
      this.profileMissing = true;
    } else if (!this.newUser.username || !this.newUser.password) {
      this.selectedTab = 1;
      this.rightsMissing = true;
    } else if (!this.newUser.user_access) {
      this.selectedTab = 2;
      this.accessMissing = true;
    } else if (
      !this.newUser.userVisibility ||
      (this.newUser.userVisibility && !(this.newUser.userVisibility.length > 0))
    ) {
      this.selectedTab = 3;
      this.userVisibilityMissing = true;
    } else if (
      !this.newUser.modelProjectVisibility ||
      (this.newUser.modelProjectVisibility && !(this.newUser.modelProjectVisibility.length > 0))
    ) {
      this.selectedTab = 4;
      this.modelVisibilityMissing = true;
    } else if (
      !this.newUser.organization ||
      !this.newUser.organization.client_id ||
      !this.newUser.organization.group_id
    ) {
      this.selectedTab = 5;
      this.organizationMissing = true;
    } else {
      this.store.dispatch({
        type: UserActions.createNewUser,
        user: this.newUser,
      });
      this.goBackToTable();
    }
  }
}
