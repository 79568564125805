import { Component, OnInit } from '@angular/core';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentApplicationVersion: string;
  appNameFooter: string;

  constructor(private magicString: MagicStringsService) {}

  ngOnInit(): void {
    this.currentApplicationVersion = environment.appVersion;
    // this.magicString.refresh()
    this.appNameFooter = this.magicString.AppName;
  }
}
