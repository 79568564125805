import { createAction, props } from '@ngrx/store';

export const enum AdministrativeActions {
  setTheme = '[Administrative] set Theme ',
  setImage = '[Administrative] set Image ',
  getImage = '[Administrative] get Image ',
  getImageLoginSuccess = '[Administrative] get Image Login sucess',
  getImageHeaderSuccess = '[Administrative] get Image  sucess',
  getImageFailure = '[Administrative] get Image failure',
}

export const setTheme = createAction(AdministrativeActions.setTheme, props<{ idTheme: number }>());
export const setImage = createAction(
  AdministrativeActions.setImage,
  props<{ logoLogin: string; logoHeader: string }>()
);

export const getImage = createAction(AdministrativeActions.getImage, props<{ idImage: number; kind: string }>());

export const getImageHeaderSuccess = createAction(AdministrativeActions.getImageHeaderSuccess, props<{ data: any }>());

export const getImageLoginSuccess = createAction(AdministrativeActions.getImageLoginSuccess, props<{ data: any }>());

export const getImageFailure = createAction(AdministrativeActions.getImageFailure, props<{ error: any }>());
