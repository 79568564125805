import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { ArticleDictionaryPipe } from 'src/app/utils/pipes/article-dictionary.pipe';
import { VerbPronounDictionaryPipe } from 'src/app/utils/pipes/verb-pronoun-dictionary.pipe';
import { PrepositionDictionaryPipe } from 'src/app/utils/pipes/preposition-dictionary.pipe';
import { takeUntil } from 'rxjs/operators';
import { selectCheckedProjects } from 'src/app/Store/projects/selectors/projects.selectors';
import { selectCheckedModels } from 'src/app/Store/questionnaire-store/state.selectors';
import { Project } from 'src/app/data/models/Project';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';

export const enum CONFIRMDIALOGSTRINGS {
  CREATETOPIC = 'Create Topic',
  DELETESINGLEQUESTION = 'delete single question',
  ELIMINATECONSTRAINTS = 'Eliminate constrained questions',
  CONSTRAINT_OPTION = 'Constraint option',
  CANCEL_QUESTIONNAIRE = 'Cancel questionnaire',
  FINALISE_QUESTIONNAIRE = 'Sei sicuro di voler salvare?',
  SAVE_QUESTIONNAIRE = 'Finalizza Quest?',
  QUESTION_UNSAVED_GO_HOME = 'Questions is unsaved',
  CHANGE_TOPIC = 'topic changed',
  CANCEL_ASSIGNMENT = 'Delete assignment',
  CHANGE_COLLABORATIVE_TO_N0 = 'changes field collaborative to false',
  CHANGE_ASSIGNEE_FOR_COLABORATIVE_FALSE = 'changes assignee when collaborative field is false',
  CHANGE_ASSIGNEE_FOR_COLABORATIVE_TRUE = 'changes assignee when collaborative field is true',
  CHANGE_DATE = 'changes the data',
  CHANGE_DATE_AND_CHOOSE_AN_EARLIER_ONE = 'changes the data and chooses an earlier date',
  CHANGE_TOPIC_ASSIGNEE = 'changes the assignee of the topic',
  DELETE_MODEL_NOPROJECTS = 'deletes models with no projects',
  DELETE_MODEL_WITHPROJECTS = 'deletes models with drafted/archived projects',
  DELETE_USER = 'deletes user',
  DISABLE_USER = 'disabled user',
  ENABLE_USER = 'enable user',
  DELETE_MULTIPLE_USERS = 'deletes multiple users',
  DISABLE_MULTIPLE_USERS = 'disable multiple users',
  COLLABORATIVE_FALSE = 'enables pop-up when a project becomes non-collaborative and it was collaborative',
  CANCEL_MODIFICATION_CREATION = 'enables when a new question modal is canceled with unsaved changes',
  ARCHIVE_SINGLE_MODEL = 'archives a questionnaire',
  ARCHIVE_MULTIPLE_MODELS = 'archives multiple questionnaires',
  DELETE_MULTIPLE_MODELS = 'deletes multiple questionnaires',
  ARCHIVE_SINGLE_ACTIVE_PROJECT = 'archives an active project',
  ARCHIVE_SINGLE_NOTACTIVE_PROJECT = 'archives a not active project',
  ARCHIVE_MULTIPLE_PROJECTS = 'archives multiple projects',
  DELETE_SINGLE_PROJECT = 'deletes a project',
  DELETE_MULTIPLE_PROJECTS = 'deletes multiple projects',
  NOTIFICATION_NAVIGATE_TO_PROJECT = 'navigate to project',
  DELETEGROUP = 'delete group',
  DELETECLIENT = 'delete client',
  DELETEMULTIPLEGROUPS = 'delete multiple groups',
  NOTDELETEGROUPS = 'can not delete groups that have users',
  NOTDELETECLIENT = 'can not delete client',
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  dialogTitle = 'Attenzione!';
  dialogTitleENG = 'Warning!';

  strongText: string;

  dialogText: string;
  dialogTextENG: string;

  cancelText = 'Annulla';

  showSaveButton = false;

  showThirdOption = false;

  saveText = 'Conferma';

  questionarioName: string;

  topicName: string;
  singleTopicName: string;

  responsabileName: string;

  questionariName: string;

  modelloName: string;
  modelliName: string;

  progettoName: string;
  progettiName: string;

  artPipe = new ArticleDictionaryPipe();
  verbPipe = new VerbPronounDictionaryPipe();
  prepPipe = new PrepositionDictionaryPipe();
  lang: string;

  deleteAllText: string;
  deleteOnlyBindingText: string;
  questAssegneeText: string;
  topicAssegneeText: string;

  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  checkedProjects: Project[];
  checkedModels: QuestionnairesStructure[];

  constructor(
    public ConfirmationDialog: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private magicStringsService: MagicStringsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    // this.magicStringsService.refresh();
    this.questionarioName = this.magicStringsService.Questionario.toLowerCase();
    this.topicName = this.magicStringsService.Topic.toLowerCase();
    this.singleTopicName = this.magicStringsService.SingleTopic.toLowerCase();
    this.responsabileName = this.magicStringsService.Responsabile.toLowerCase();
    this.questionarioName = this.magicStringsService.Questionario.toLowerCase();
    this.questionariName = this.magicStringsService.Questionari.toLowerCase();
    this.modelloName = this.magicStringsService.Modello.toLowerCase();
    this.modelliName = this.magicStringsService.Modelli.toLowerCase();
    this.progettoName = this.magicStringsService.Progetto.toLowerCase();
    this.progettiName = this.magicStringsService.Progetti.toLowerCase();
    this.getCheckedProjects();
    this.getCheckedModels();

    this.store
      .select(selectLanguage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lang) => {
        this.lang = lang;
      });
    this.deleteAllText =
      this.lang === 'it' ? 'Si, elimina anche le domande vincolate' : 'Yes, delete linked questions too';
    this.deleteOnlyBindingText =
      this.lang === 'it' ? 'No, elimina solamente la domanda vincolante' : 'No, delete the constrained question only';
    this.questAssegneeText = this.lang === 'it' ? 'SOLO QUESTIONARIO' : 'QUESTIONNAIRE ONLY';
    this.topicAssegneeText =
      this.lang === 'it'
        ? 'ANCHE ' + this.topicName.toUpperCase()
        : this.magicStringsService.TopicENG.toUpperCase() + ' TOO';

    switch (this.data.info) {
      case CONFIRMDIALOGSTRINGS.NOTDELETEGROUPS:
        this.dialogText = `Sei sicuro di voler eliminare group? `;
        this.dialogTextENG = `You can't delete groups that contain users.
        Please reassign or delete the users before to proceed. `;
        this.strongText = ``;
        break;

      case CONFIRMDIALOGSTRINGS.NOTDELETECLIENT:
        this.dialogText = `Sei sicuro di voler eliminare client? `;
        this.dialogTextENG = `You can't delete clients that contain users.
        Please reassign or delete the users before to proceed. `;
        this.strongText = ``;
        break;

      case CONFIRMDIALOGSTRINGS.DELETEGROUP:
        this.dialogText = `Sei sicuro di voler eliminare group? `;
        this.dialogTextENG = `Are you sure you want to delete this group? `;
        this.strongText = ``;
        break;

      case CONFIRMDIALOGSTRINGS.DELETECLIENT:
        this.dialogText = `Sei sicuro di voler eliminare client? `;
        this.dialogTextENG = `Are you sure you want to delete this client? `;
        this.strongText = ``;
        break;

      case CONFIRMDIALOGSTRINGS.DELETEMULTIPLEGROUPS:
        this.dialogText = `(${this.data.length}) Sei sicuro di voler eliminare groups? ${this.artWord(
          this.progettiName,
          'plural'
        )} `;
        this.dialogTextENG = `(${this.data.length}) Are you sure you want to delete these groups?`;
        break;

      case CONFIRMDIALOGSTRINGS.CREATETOPIC:
        this.dialogText = `Sei sicuro di voler eliminare ${this.artWord(this.singleTopicName, 'singular')} `;
        this.dialogTextENG = `Are you sure you want to delete the ${this.magicStringsService.SingleTopicENG.toLowerCase()} `;
        this.strongText = `${this.data.topic.Title}?`;
        break;
      case CONFIRMDIALOGSTRINGS.DELETESINGLEQUESTION:
        this.dialogText = 'Stai eliminando una domanda, vuoi continuare?';
        this.dialogTextENG = 'You are deleting a question, do you wish to continue?';
        this.strongText = '';
        break;
      case CONFIRMDIALOGSTRINGS.CONSTRAINT_OPTION:
        this.dialogText =
          ' È stata cambiata/rimossa una risposta legata a una domanda vincolata. Procedendo con il salvataggio la domanda vincolata verrà automaticamente eliminata. Continuare?';
        this.dialogTextENG =
          ' An answer linked to a constrained question has been changed/removed. Procceding with the saving the question will be automatically deleted. Do you wish to continue?';
        break;
      case CONFIRMDIALOGSTRINGS.ELIMINATECONSTRAINTS:
        this.dialogText = 'Stai eliminando una domanda vincolante. Eliminare anche le domande ad essa vincolate?';
        this.dialogTextENG = 'You are deleting a constrained question. Do you wish to delete the linked questions too?';
        this.showSaveButton = true;
        break;
      case CONFIRMDIALOGSTRINGS.CANCEL_QUESTIONNAIRE:
        this.dialogText = 'Sei sicuro di non voler salvare? Eventuali modifiche non salvate andranno perse';
        this.dialogTextENG = "Are you sure you don't want to save? Any unsaved changes will be lost";
        break;
      case CONFIRMDIALOGSTRINGS.FINALISE_QUESTIONNAIRE:
        this.dialogText = `Procedendo con la pubblicazione, ${this.artWord(
          this.modelloName,
          'singular'
        )} potrà essere assegnato a un ${this.progettoName} ma non potrà più essere modificato. Continuare?`;
        this.dialogTextENG = `By proceeding with the publication, the ${this.magicStringsService.ModelloENG.toLowerCase()} can be assigned to a ${this.magicStringsService.ProgettoENG.toLowerCase()} but cannot be modified anymore. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.SAVE_QUESTIONNAIRE:
        this.dialogText = 'Sei sicuro di voler salvare?';
        this.dialogTextENG = 'Are you sure you want to save?';
        break;
      case CONFIRMDIALOGSTRINGS.QUESTION_UNSAVED_GO_HOME:
        this.dialogText = 'Tornando alla Homepage perderai i dati non salvati. Continuare?';
        this.dialogTextENG = 'When you return to the homepage you will lose unsaved data. Do you wish to continue?';
        break;
      case CONFIRMDIALOGSTRINGS.CHANGE_TOPIC:
        this.dialogText = `Stai passando a un ${this.verbWord(`altro ${this.singleTopicName}`, 'singular')} ${
          this.singleTopicName
        } senza salvare le tue modifiche. Sei sicuro?`;
        this.dialogTextENG = `You are switching to another ${this.magicStringsService.SingleTopicENG.toLowerCase()} without saving your changes. Are you sure?`;
        break;
      case CONFIRMDIALOGSTRINGS.CANCEL_ASSIGNMENT:
        this.dialogText = 'Stai eliminando le assegnazioni selezionate. Vuoi confermare';
        this.dialogTextENG = 'You are deleting selected assignments. Do you want to confirm?';
        break;
      case CONFIRMDIALOGSTRINGS.CHANGE_COLLABORATIVE_TO_N0:
        this.dialogText = `Il ${
          this.questionarioName
        } è passato da collaborativo a non collaborativo, tutti gli assegnatari ${this.prepWord(
          `di ${this.topicName}`,
          'plural'
        )} verranno automaticamente rimossi e la compilazione delle domande di ognuno verrà cancellata. L’operazione è irreversibile. Continuare?`;
        this.dialogTextENG = `This ${this.magicStringsService.QuestionarioENG.toLowerCase()} has changed from collaborative to non-collaborative, all ${this.magicStringsService.SingleTopicENG.toLowerCase()} assignees will be automatically removed and the completion of each one\'s questions will be deleted. The operation is irreversible. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.CHANGE_ASSIGNEE_FOR_COLABORATIVE_FALSE:
        this.dialogText = `Se la compilazione delle domande ${this.prepWord(
          `di ${this.topicName}`,
          'plural'
        )} fosse già iniziata, cambiando assegnatario si cancelleranno irreversibilmente tutte le risposte compilate. Continuare?`;
        this.dialogTextENG = `If the compilation of ${this.magicStringsService.SingleTopicENG.toLowerCase()} questions had already begun, changing assignees will irreversibly erase all compiled answers. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.CHANGE_ASSIGNEE_FOR_COLABORATIVE_TRUE:
        this.dialogText = `Vuoi cambiare solo l’assegnatario ${this.prepWord(
          `di ${this.questionariName}`,
          'plural'
        )} o anche gli assegnatari ${this.prepWord(`di ${this.topicName}`, 'plural')}?`;
        this.dialogTextENG = `Do you want to change only the ${this.magicStringsService.QuestionarioENG.toLowerCase()} assignee or the ${this.magicStringsService.SingleTopicENG.toLowerCase()} assignees?`;
        this.showThirdOption = true;
        break;
      case CONFIRMDIALOGSTRINGS.CHANGE_DATE:
        this.dialogText = `Vuoi assegnare la nuova data di scadenza automaticamente anche a ${this.verbWord(
          `tutto ${this.topicName}`,
          'plural'
        )} ${this.artWord(this.topicName, 'plural')}?`;
        this.dialogTextENG = `Do you want to automatically assign the new expiry date to all ${this.magicStringsService.TopicENG.toLowerCase()} as well?`;
        break;
      case CONFIRMDIALOGSTRINGS.CHANGE_TOPIC_ASSIGNEE:
        this.dialogText = `Cambiando assegnatario ${this.prepWord(
          `di ${this.topicName}`,
          'plural'
        )} tutte le risposte compilate saranno azzerate irreversibilmente. Continuare?`;
        this.dialogTextENG = `By changing the ${this.magicStringsService.SingleTopicENG.toLowerCase()} assignee all entered answers will be irreversibly reset. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.CHANGE_DATE_AND_CHOOSE_AN_EARLIER_ONE:
        this.dialogText = `Con questa modifica la nuova data di scadenza verrà ereditata anche ${this.prepWord(
          `da ${this.topicName}`,
          'plural'
        )}. Continuare?`;
        this.dialogTextENG = `With this modification the new expiry date will be inherited also by the ${this.magicStringsService.TopicENG.toLowerCase()}. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.DELETE_MODEL_NOPROJECTS:
        this.dialogText = "Confermi l'eliminazione? L'operazione è irreversibile";
        this.dialogTextENG = 'Do you confirm the deletion? The operation is irreversible';
        break;
      case CONFIRMDIALOGSTRINGS.DELETE_MODEL_WITHPROJECTS:
        this.dialogText = `Se elimini questo ${this.modelloName} anche tutti ${this.artWord(
          this.progettiName,
          'plural'
        )} collegati verranno eliminati. Continuare?`;
        this.dialogTextENG = `If you delete this ${this.magicStringsService.ModelloENG} also all linked ${this.magicStringsService.ProgettiENG} will be deleted. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.DELETE_USER:
        this.dialogText = `Se elimini questo utente anche tutti ${this.artWord(
          this.progettiName,
          'plural'
        )} collegati verranno eliminati. Continuare?`;
        this.dialogTextENG = `If you delete this user also all linked ${this.magicStringsService.ProgettiENG} will be deleted. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.DISABLE_USER:
        this.dialogText = `Vuoi disattivare questo utente?`;
        this.dialogTextENG = `Do you want to disable this user?`;
        break;
      case CONFIRMDIALOGSTRINGS.ENABLE_USER:
        this.dialogText = `Vuoi riattivare questo utente?`;
        this.dialogTextENG = `Do you want to reactivate this user?`;
        break;
      case CONFIRMDIALOGSTRINGS.DELETE_MULTIPLE_USERS:
        this.dialogText = `(${this.data.length}) Se elimini questi utenti anche tutti ${this.artWord(
          this.progettiName,
          'plural'
        )} collegati verranno eliminati. Continuare?`;
        this.dialogTextENG = `(${this.data.length}) If you delete these users also all linked ${this.magicStringsService.ProgettiENG} will be deleted. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.DISABLE_MULTIPLE_USERS:
        this.dialogText = `(${this.data.length}) Vuoi disattivare questI utenti?`;
        this.dialogTextENG = `(${this.data.length}) Do you want to disable these users?`;
        break;
      case CONFIRMDIALOGSTRINGS.COLLABORATIVE_FALSE:
        this.dialogText = `${this.artWord(this.singleTopicName, 'singular')} non sarà più ${this.verbWord(
          `collaborativo ${this.topicName}`,
          'singular'
        )}. ${this.verbWord(`Tutto ${this.topicName}`, 'plural')} ${this.artWord(
          this.topicName,
          'plural'
        )} di ogni questionario verranno ${this.verbWord(`assegnato ${this.topicName}`, 'plural')} all\'utente ${
          this.responsabileName
        } ma i progressi non andranno persi. Continuare?`;
        this.dialogTextENG = `The ${this.magicStringsService.SingleTopicENG.toLowerCase()} will no longer be collaborative. All ${this.magicStringsService.TopicENG.toLowerCase()} in each questionnaire will be assigned to the ${this.magicStringsService.ResponsabileENG.toLowerCase()} user but progress will not be lost. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.CANCEL_MODIFICATION_CREATION:
        this.dialogText = 'Tutte le modifiche non salvate andranno perse. Continuare?';
        this.dialogTextENG = 'Any unsaved changes will be lost. Do you wish to continue?';
        break;
      case CONFIRMDIALOGSTRINGS.ARCHIVE_SINGLE_MODEL:
        this.dialogText = `${this.artWord(this.modelloName, 'singular')} verrà archiviato. Continuare?`;
        this.dialogTextENG = `The ${this.magicStringsService.ModelloENG.toLowerCase()} will be archived. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.ARCHIVE_MULTIPLE_MODELS:
        this.dialogText = `(${this.checkedModels.length}) ${this.modelliName} selezionati verranno archiviati. Continuare?`;
        this.dialogTextENG = `(${
          this.checkedModels.length
        }) selected ${this.magicStringsService.ModelliENG.toLowerCase()} with status completed will be archived. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.DELETE_MULTIPLE_MODELS:
        this.dialogText = `(${this.checkedModels.length}) ${this.modelliName} selezionati con status diverso da completato e senza ${this.progettiName} attivi verranno eliminati. Questa operazione è irreversibile. Continuare?`;
        this.dialogTextENG = `(${
          this.checkedModels.length
        }) selected ${this.magicStringsService.ModelliENG.toLowerCase()} with status different from completed and without active ${this.magicStringsService.ProgettiENG.toLowerCase()} will be deleted. This operation is irreversible. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.ARCHIVE_SINGLE_ACTIVE_PROJECT:
        this.dialogText = `Alcuni ${this.questionariName} non sono ancora stati completati e verranno eliminati al momento dell'archiviazione. Questa operazione è irreversibile. Continuare?`;
        this.dialogTextENG = `Some ${this.magicStringsService.QuestionariENG.toLowerCase()} have not yet been completed and they will be deleted upon archiving. This operation is irreversible. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.ARCHIVE_SINGLE_NOTACTIVE_PROJECT:
        this.dialogText = `${this.artWord(this.progettoName, 'singular')} verrà archiviato. Continuare?`;
        this.dialogTextENG = `The ${this.magicStringsService.ProgettoENG.toLowerCase()} will be archived. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.ARCHIVE_MULTIPLE_PROJECTS:
        this.dialogText = `(${this.checkedProjects.length}) ${this.progettiName} selezionati con status diverso da attivo verranno archiviati. Continuare?`;
        this.dialogTextENG = `(${
          this.checkedProjects.length
        }) selected ${this.magicStringsService.ProgettiENG.toLowerCase()} with status different from active will be archived. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.DELETE_SINGLE_PROJECT:
        this.dialogText = `${this.artWord(
          this.progettoName,
          'singular'
        )} verrà eliminato. Questa operazione è irreversibile. Continuare?`;
        this.dialogTextENG = `The ${this.magicStringsService.ProgettoENG.toLowerCase()} will be deleted. This operation is irreversible. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.DELETE_MULTIPLE_PROJECTS:
        this.dialogText = `(${this.checkedProjects.length}) ${this.progettiName} selezionati con status archiviato e/o draft verranno eliminati. Questa operazione è irreversibile. Continuare?`;
        this.dialogTextENG = `(${
          this.checkedProjects.length
        }) selected ${this.magicStringsService.ProgettiENG.toLowerCase()} with archived/draft status will be deleted. This operation is irreversible. Do you wish to continue?`;
        break;
      case CONFIRMDIALOGSTRINGS.NOTIFICATION_NAVIGATE_TO_PROJECT:
        this.dialogText = this.data.message;
        this.dialogTextENG = this.data.message;
        this.dialogTitle = 'Dettagli della notifica';
        this.dialogTitleENG = 'Notification Details';
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  delete(text: string) {
    this.navigateAwaySelection$.next(false);
    this.ConfirmationDialog.close(text);
  }

  save(element?: string) {
    if (this.showThirdOption && element) {
      this.ConfirmationDialog.close(element);
    } else {
      this.ConfirmationDialog.close();
    }
    this.navigateAwaySelection$.next(true);
  }

  private artWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.artPipe.transform(word, plural, this.lang);
    return newWord;
  }
  private prepWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.prepPipe.transform(word, plural, this.lang);
    return newWord;
  }
  private verbWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.verbPipe.transform(word, plural, this.lang);
    return newWord;
  }

  private getCheckedProjects() {
    this.store
      .select(selectCheckedProjects)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.checkedProjects = data));
  }

  private getCheckedModels() {
    this.store
      .select(selectCheckedModels)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.checkedModels = data));
  }
}
