import { Pipe, PipeTransform } from '@angular/core';
import { protocol } from 'socket.io-client';
import { Project } from 'src/app/data/models/Project';
import { User } from 'src/app/data/models/User';

@Pipe({
  name: 'getQuestionnaireWatchList',
})
export class GetQuestionnaireWatchListPipe implements PipeTransform {
  transform(user: string | User, watchlist?: { [username: string]: string[] }, project?: Project): string {
    let username: string;
    if (user.hasOwnProperty('username')) {
      username = user['username'];
    } else {
      username = user as string;
    }
    if (!!watchlist[username]) {
      return watchlist[username].length as unknown as string;
    } else if (!!project) {
      if (!!project['questionnaires'][username] && !!project['questionnaires'][username]['watchlist']) {
        return project['questionnaires'][username]['watchlist'].length;
      } else {
        return 0 as unknown as string;
      }
    } else {
      return 0 as unknown as string;
    }
  }
}
