import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  // Our socket connection
  private socket;

  constructor() {}

  connect(token: string): Subject<MessageEvent> {
    // If you aren't familiar with environment variables then
    // you can hard code `environment.ws_url` as `http://localhost:5000`
    let url = '';
    url = environment.apiUrl;

    this.socket = (io as any)(url, {
      path: '/socket.io',
      transports: ['polling', 'websocket'],
      secure: true,
      extraHeaders: { Authorization: `Bearer ${token}` },
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 2,
    });

    // We define our observable which will observe any incoming messages
    // from our socket.io server.
    let observable = new Observable((observer) => {
      this.socket.on('ws', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });

    this.socket.on('unauthorized', (error, callback) => {
      if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
        // redirect user to login page perhaps or execute callback:
        callback();
        //console.log('User token has expired');
      }
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
      next: (data: Object) => {
        this.socket.emit('ws', JSON.stringify(data));
      },
    };

    // we return our Rx.Subject which is a combination
    // of both an observer and observable.
    return Subject.create(observer, observable);
  }
}
