import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WebSocketService } from './webSocketService';
import { WebSocketDataHandlerService } from '../../data-handlers/web-socket-data-handler.service';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WSService {
  public messages: Subject<any>;
  private ifConnect = false;

  constructor(
    private wsService: WebSocketService,
    private socketHandler: WebSocketDataHandlerService,
    public authService: AuthService
  ) {}

  connect(token: string) {
    this.messages = <Subject<any>>this.wsService.connect(token).pipe(
      map((response: any): any => {
        this.ifConnect = true;
        return this.getWSData(response);
      })
    );
  }
  // Our simplified interface for sending
  // messages back to our socket.io server
  sendMessageToWebSocket(msg) {
    if (!!this.messages) {
      this.messages.next(msg);
    } else {
      this.connect(this.authService.getUserToken());
      this.messages.next(msg);
    }
  }

  disconnectFromWS() {
    this.messages.next('disconnect');
  }

  getWSData(obj: { type: string; body: { data: any; length: number } }) {
    this.socketHandler.dataHander(obj);
  }

  returnWSSUbject() {
    return this.messages.subscribe();
  }
}
