import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private notificationsService: NotificationsService, public router: Router) {}

  // logout the user
  logOut() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  // converts 2020-01-03T10:25:47.590Z to 3/1/2020 10:25:47 (GMT+ 2)
  // or Sat Mar 14 2020 08:15:10 GMT+0200 (GMT+02:00) to 14/3/2020 8:15:19 (GMT+2)
  formateDate2(unformatedDate) {
    const date = new Date(unformatedDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const mo = month < 10 ? '0' + month : month;
    const day = date.getDate();
    const da = day < 10 ? '0' + day : day;
    const hours = date.getHours();
    const ho = hours < 10 ? '0' + hours : hours;
    const minutes = date.getMinutes();
    const mi = minutes < 10 ? '0' + minutes : minutes;
    const seconds = date.getSeconds();
    const se = seconds < 10 ? '0' + seconds : seconds;
    const offset = new Date().getTimezoneOffset();
    let gmtOffset = '';
    if (offset < 0) {
      gmtOffset = 'GMT+' + offset / -60;
    } else {
      gmtOffset = 'GMT-' + offset / -60;
    }
    const formatedDate = da + '/' + mo + '/' + year;
    return formatedDate;
  }

  // open successful notification
  openSuccessNotification(message) {
    this.notificationsService.success(message, '', {
      timeOut: 3000,
      showProgressBar: false,
      pauseOnHover: true,
      clickToClose: true,
    });
  }

  // open error notification
  openErrorNotification(message) {
    this.notificationsService.error(message, '', {
      // timeOut: 5000,
      showProgressBar: false,
      pauseOnHover: true,
      clickToClose: true,
    });
  }

  // open successful swal
  openSuccessSwal(message, route?: any) {
    swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: true,
      timer: 3000,
    });
  }

  // open warning swal
  openWarningSwal(message) {
    swal.fire({
      icon: 'warning',
      title: message,
      showConfirmButton: true,
    });
  }

  // open error swal
  openErrorSwal(message) {
    swal.fire({
      icon: 'error',
      title: message,
      showConfirmButton: true,
      // timer: 5000
    });
  }
}
