import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { WebSocketRequest } from 'src/app/data/models/request-apis/WebSocketRequest';
import { NotificationsActions } from '../actions/notifications.actions';
import { WSService } from 'src/app/data/api/WSServices/WSService';
import { Store } from '@ngrx/store';
import { selectNotifications } from '../selectors/notifications.selectors';
import { of } from 'rxjs';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Injectable()
export class NotificationsEffects {
  snackBarMarkAsReadEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.snackBarMarkAsRead),
      withLatestFrom(this.store.select(selectNotifications)),
      mergeMap(([payload, notifications]) =>
        of(true).pipe(
          map(() => {
            const notificationsToBeSent: WebSocketRequest = {
              type: 'notifications',
              data: {
                notifications: notifications.notifications,
                action: 'Read',
                username: this.authService.getUserName(),
              },
            };
            this.wsService.sendMessageToWebSocket(notificationsToBeSent);
            return {
              type: NotificationsActions.snackBarMarkAsReadSuccess,
              username: payload['username'],
            };
          })
        )
      ),
      catchError((err) => {
        return of(err);
      })
    );
  });

  constructor(
    private actions$: Actions,
    private wsService: WSService,
    private store: Store,
    public authService: AuthService
  ) {}
}
