export interface HomePageState {
  loading?: boolean;
  pageIndex?: number;
  pageSizeValue?: number;
  sortColValue?: string;
  total?: number;
  sortOrderValue?: string;
}

export const initialHomePageState: HomePageState = {
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSizeValue: 10,
  sortColValue: '',
  sortOrderValue: '',
};
