import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionTopicTypes, addTopic, deleteTopic, updateTopic } from '../topics-store/topic.actions';
import {
  selectTitlesOfAllTopics,
  selectTopics,
  selectTopicsWithTheirQuestions,
} from '../topics-store/topics.selectors';
import { Observable } from 'rxjs/internal/Observable';
import { ElencoTopic } from 'src/app/data/models/ElencoTopic';
import { TopicWithOnlyQuestionText } from 'src/app/data/models/TopicWithOnlyQuestionText';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { VerbPronounDictionaryPipe } from 'src/app/utils/pipes/verb-pronoun-dictionary.pipe';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopicStoreService implements OnDestroy {
  lang = '';
  langToUnsubscribe: Subscription;

  constructor(private store: Store, private magic: MagicStringsService) {
    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
    });
  }

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
  }

  verbPipe = new VerbPronounDictionaryPipe();

  initialiseTopics() {
    let topicName = this.lang === 'it' ? this.magic.SingleTopic : 'New ' + this.magic.SingleTopicENG;
    let newWord = 'Nuovo ' + topicName;
    let newTopicWord = this.verbPipe.transform(newWord, 'singular', this.lang) + ' ' + topicName;
    this.store.dispatch({
      type: ActionTopicTypes.topicsInitialise,
      topics: [
        {
          Title: newTopicWord,
          Description: '',
          Order: 1,
          Questions: [],
        },
      ],
    });
  }

  selectTopics(): Observable<ElencoTopic[]> {
    return this.store.select(selectTopics);
  }

  selectAllTitlesOFTopics(): Observable<string[]> {
    return this.store.select(selectTitlesOfAllTopics);
  }

  selectTopisWithTheirQuestions(): Observable<TopicWithOnlyQuestionText[]> {
    return this.store.select(selectTopicsWithTheirQuestions);
  }

  addTopicsInStore(topic: ElencoTopic) {
    this.store.dispatch(addTopic({ topic }));
  }

  modifyTopic(event: { topic: ElencoTopic; index: number; indexOldOrder: number }) {
    this.store.dispatch(
      updateTopic({
        topic: event.topic,
        index: event.index,
        indexOld: event.indexOldOrder,
      })
    );
  }

  deleteTopic(index: number) {
    this.store.dispatch(deleteTopic({ index }));
  }

  updateTopicOnDrop(topic: ElencoTopic) {
    this.store.dispatch({
      type: ActionTopicTypes.updateTopicsAction,
      topics: topic,
    });
  }

  updateAllTopics(topics: ElencoTopic[]) {
    this.store.dispatch({
      type: ActionTopicTypes.updateAllTopicsAction,
      topics,
    });
  }
}
