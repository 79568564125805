import { AfterContentChecked, AfterViewChecked, Component, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectStoreService } from 'src/app/Store/store-services/project-store.service';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectCloneLoading } from 'src/app/Store/projects/selectors/projects.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-clone-project-options',
  templateUrl: './clone-project-options.component.html',
  styleUrls: ['./clone-project-options.component.scss'],
})
export class CloneProjectOptionsComponent {
  checkImportAns = false;
  checkImportUser = false;
  checkImportOption = false;
  checkImportWatchList = false;
  checkImportTopicAssign = false;

  progettoName = '';

  responsabiliName = '';

  topicName = '';

  language = '';
  loading: Observable<boolean>;

  ClonedProjectInfo = this.fb.group({
    ClonedTitle: ['', Validators.required],
    ClonedDescription: [''],
    Options: this.fb.group({
      IMPORT_ALL_OPTIONS: [false],
      IMPORT_ALL_USERS: [false],
      IMPORT_ALL_ANS: [false],
      IMPORT_WATCHLIST: [false],
      IMPORT_LAST_COMPILER: [false],
    }),
  });

  ngOnInit(): void {
    // this.magicStrings.refresh()
    this.loading = this.store.select(selectCloneLoading);
    this.progettoName = this.magicStrings.Progetto;
    this.topicName = this.magicStrings.Topic;
    this.responsabiliName = this.magicStrings.Responsabili;
    this.language = this.translate.currentLang;
  }

  constructor(
    private store: Store,
    public CloneProjectOptionsDialog: MatDialogRef<CloneProjectOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private projectStore: ProjectStoreService,
    private magicStrings: MagicStringsService,
    private translate: TranslateService
  ) {}

  cancel() {
    this.CloneProjectOptionsDialog.close();
  }

  saveOptions() {
    if (this.ClonedProjectInfo.valid) {
      this.projectStore.cloneProject(
        this.data.projectId,
        this.ClonedProjectInfo.get('ClonedTitle').value,
        this.ClonedProjectInfo.get('ClonedDescription').value,
        this.ClonedProjectInfo.get('Options').value
      );
    } else {
      this.ClonedProjectInfo.markAsTouched();
    }
  }

  selectImportAns() {
    this.checkImportAns = !this.checkImportAns;
    if (this.checkImportAns) {
      this.checkImportUser = true;
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_ALL_USERS: true,
      });
    } else {
      if (this.checkImportUser) {
        this.ClonedProjectInfo.get('Options').patchValue({
          IMPORT_ALL_USERS: true,
        });
      }
    }
  }
  selectImportWatchList() {
    this.checkImportWatchList = !this.checkImportWatchList;
    if (this.checkImportWatchList) {
      this.checkImportUser = true;
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_ALL_USERS: true,
      });
    } else {
      if (this.checkImportUser) {
        this.ClonedProjectInfo.get('Options').patchValue({
          IMPORT_ALL_USERS: true,
        });
      }
    }
  }

  selectImportOption() {
    this.checkImportOption = !this.checkImportOption;
    if (!this.checkImportOption) {
      this.checkImportTopicAssign = false;
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_LAST_COMPILER: false,
      });
    }
    if (this.checkImportUser) {
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_ALL_USERS: true,
      });
    }
    if (this.checkImportAns) {
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_ALL_ANS: true,
      });
    }
    if (this.checkImportWatchList) {
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_WATCHLIST: true,
      });
    }
  }

  selectImportUserAssign() {
    this.checkImportUser = !this.checkImportUser;
    if (!this.checkImportUser) {
      this.checkImportAns = false;
      this.checkImportWatchList = false;
      this.checkImportTopicAssign = false;
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_ALL_ANS: false,
      });
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_WATCHLIST: false,
      });
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_LAST_COMPILER: false,
      });
    }
  }

  selectImportTopicAssign() {
    this.checkImportTopicAssign = !this.checkImportTopicAssign;
    if (this.checkImportTopicAssign) {
      this.checkImportOption = true;
      this.checkImportUser = true;
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_ALL_OPTIONS: true,
      });
      this.ClonedProjectInfo.get('Options').patchValue({
        IMPORT_ALL_USERS: true,
      });
    }
  }
}
