import {
  createUser,
  getUsersPaginated,
  getUsersPaginatedForOwner,
  updatePassword,
  updateUser,
  UserActions,
} from './../users/users.actions';
import { UserSend } from './../../data/models/request-apis/User-request';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  selectUsers,
  selectUsersFullname,
  selectUsersLength,
  selectUsersLoading,
  selectUsersPageIndex,
  selectUsersPaginated,
} from '../users/users.selectors';
import { User } from 'src/app/data/models/User';
import { Password } from 'src/app/data/models/request-apis/Password';

@Injectable({
  providedIn: 'root',
})
export class UsersStoreService {
  constructor(private store: Store) {}

  dispatchUsersPaginated(
    usedForCheangeOwnerFlag: boolean,
    pageIndex: number,
    pageSize: number,
    toBeExcluded?: User[],
    disabled?: boolean
  ) {
    if (!usedForCheangeOwnerFlag) {
      this.store.dispatch(getUsersPaginated({ pageIndex, pageSize, toBeExcluded, disabled }));
    } else {
      this.store.dispatch(getUsersPaginatedForOwner({ pageIndex, pageSize, toBeExcluded }));
    }
  }

  getUsersAction(): Observable<User[]> {
    return this.store.select(selectUsers);
  }

  getLoadingUsers(): Observable<boolean> {
    return this.store.select(selectUsersLoading);
  }

  getUsersPaginated(): Observable<User[]> {
    return this.store.select(selectUsersPaginated);
  }

  getUsersLength(): Observable<number> {
    return this.store.select(selectUsersLength);
  }

  getUsersPageIndex(): Observable<number> {
    return this.store.select(selectUsersPageIndex);
  }

  createUser(user: UserSend) {
    this.store.dispatch(createUser({ user }));
  }

  updateUser(user: UserSend) {
    this.store.dispatch(updateUser({ user }));
  }

  updatePassword(password: Password) {
    this.store.dispatch(updatePassword({ password }));
  }

  deleteUser(user: UserSend, pageIndex: number, pageSize: number, disabled: boolean) {
    this.store.dispatch({
      type: UserActions.deleteUser,
      user: user,
      pageIndex: pageIndex,
      pageSize: pageSize,
      disabled: disabled,
    });
  }

  reactivateUser(user: UserSend, pageIndex: number, pageSize: number, disabled: boolean) {
    this.store.dispatch({
      type: UserActions.reactivateUser,
      user: user,
      pageIndex: pageIndex,
      pageSize: pageSize,
      disabled: disabled,
    });
  }

  searchUser(search: string, selectedUsers: User[], shouldOnlyGetResponsible: boolean, disabled: boolean) {
    this.store.dispatch({
      type: UserActions.searchUsers,
      pageIndex: 0,
      pageSize: 10,
      searchString: search,
      toBeExcluded: selectedUsers,
      shouldOnlyReturnResponsible: shouldOnlyGetResponsible,
      disabled: disabled,
    });
  }

  getUsersFullName() {
    return this.store.select(selectUsersFullname);
  }
}
