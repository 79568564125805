<mat-form-field class="my-form-field" *ngIf="local_data.action === 'Add'">
  <mat-label>Selezionare la risposta</mat-label>
  <mat-select class="my-form-field" name="question" [(ngModel)]="selectedQuestion">
    <mat-option class="my-form-field" *ngFor="let question of questions" [value]="question">
      {{ question.Question_Text }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="my-form-field" *ngIf="local_data.action === 'Modify'">
  <mat-label>{{ selectedQuestion.Question_Text }}</mat-label>
  <mat-select class="my-form-field" [(value)]="selectedQuestion">
    <ng-container *ngFor="let question of local_data.questions">
      <mat-option *ngIf="question.id !== local_data.questionToBeModified.id" class="my-form-field" [value]="question">
        {{ question.Question_Text }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>

<div *ngIf="selectedQuestion !== undefined">
  <div *ngIf="selectedQuestion.TypeOfQuestion === 'Free text'">
    <mat-checkbox style="color: black" [(ngModel)]="freeTextConstraint"
      >Sara visibile se Domanda {{ selectedQuestion.Question_Text }} e compilata</mat-checkbox
    >
  </div>

  <div *ngIf="selectedQuestion.TypeOfQuestion === 'List box' || selectedQuestion.TypeOfQuestion === 'Check-list box'">
    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> Risposte Vincolanti </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field class="my-form-field">
          <mat-label>Select Options</mat-label>
          <mat-select class="my-form-field" [(ngModel)]="selectedOptions" name="option" multiple>
            <mat-option
              class="my-form-field"
              *ngFor="let options of selectedQuestion.Check_List_Box_Options"
              [value]="options"
            >
              {{ options }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<mat-dialog-actions>
  <button mat-button (click)="save()">Salva</button>
  <button mat-button (click)="cancel()">Annulla</button>
</mat-dialog-actions>
