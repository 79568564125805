<div class="assign-users-form-area flex h-full flex-col justify-between gap-4 rounded-3xl bg-white p-4 shadow-md">
  <div>
    <div class="text-xl font-bold text-main mb-4">
      <div>
        {{ "PROJECT_OPTIONS.TITLE_TABLE1" | translate }}
        {{ "DICTIONARY.Responsabili" | translate | titlecase }} (
        {{ "DICTIONARY.Questionari" | translate | titlecase }}
        {{ "assegnato " + ("DICTIONARY.Questionari" | translate) | verbPronounDictionary: "plural":lang }}
        {{ "PROJECT_OPTIONS.TITLE_TABLE2" | translate }} )
      </div>
    </div>
    <div>
      <!-- check scroll -6px later -->
      <div class="one-line-grid mt-4 w-full border-b-2 border-black pr-[6px]">
        <div class="user flex items-center text-base font-bold">
          {{ "PROJECT_OPTIONS.USER" | translate }}
        </div>
        <div class="status flex items-center justify-center text-base font-bold">
          {{ "PROJECT_OPTIONS.STATUS" | translate }}
        </div>
        <div class="topic flex items-center justify-center text-base font-bold">
          {{ "DICTIONARY.Topic" | translate | titlecase }}
        </div>
        <div class="assignment flex items-center justify-center text-base font-bold">
          {{ "PROJECT_OPTIONS.ASSIGNMENT" | translate }}
        </div>
        <div class="whenCompleted flex items-center justify-center text-base font-bold">
          {{ "PROJECT_OPTIONS.COMPLETION" | translate }}
        </div>
        <div class="watchlist flex items-center justify-center text-base font-bold">{{ "WATCHLIST" | translate }}</div>
        <div class="score flex items-center justify-center text-base font-bold">
          {{ "PROJECT_OPTIONS.SCORE" | translate }}
        </div>
        <div class="action"></div>
      </div>
      <div class="w-full max-h-[28vh] overflow-y-auto overflow-x-hidden 2xl:max-h-[48vh]">
        <ng-container *ngFor="let user of usersChosenForShowing; let ind = index">
          <ng-container *ngIf="!isModified || (isModified && !!projectToBeModified)">
            <div
              class="one-line-grid mb-2 w-full bg-[#f2f2f2] p-2"
              [ngClass]="{ '!mb-0': ind === usersChosenForShowing.length - 1 }"
            >
              <div class="user flex items-center text-base">
                <div
                  class="max-w-full truncate pr-4"
                  [matTooltip]="user.Name + ' ' + user.Surname"
                  matTooltipClass="custom-tooltip"
                  appShowIfTruncated
                  *ngIf="!projectToBeModified"
                >
                  {{ user.Name }}
                  {{ user.Surname }}
                </div>
                <div
                  class="max-w-full truncate pr-4"
                  [matTooltip]="getUserFullName[user]"
                  matTooltipClass="custom-tooltip"
                  appShowIfTruncated
                  *ngIf="!!projectToBeModified"
                >
                  {{ getUserFullName[user] }}
                </div>
              </div>
              <div class="status flex items-center justify-center text-base">
                {{
                  !!projectToBeModified && projectToBeModified.PROJECT_STATUS !== "Draft"
                    ? ("PROJECT_OPTIONS.ASSIGNED" | translate)
                    : ("PROJECT_OPTIONS.NOT_ASSIGNED" | translate)
                }}
              </div>
              <div class="topic flex items-center justify-center text-base">
                {{
                  !!projectToBeModified
                    ? !!projectToBeModified["questionnaires"][user] &&
                      !!projectToBeModified["questionnaires"][user]["topicsCompleted"]
                      ? projectToBeModified["questionnaires"][user]["topicsCompleted"] +
                        "/" +
                        projectToBeModified["Number_Of_Topics"]
                      : 0 + "/" + projectToBeModified["Number_Of_Topics"]
                    : 0 + "/" + activeQuestionnaire.Number_Of_Topics
                }}
              </div>
              <div class="assignment flex items-center justify-center text-base">
                {{
                  !!projectToBeModified &&
                  !!projectToBeModified["questionnaires"][user] &&
                  projectToBeModified.PROJECT_STATUS !== "Draft"
                    ? projectToBeModified["questionnaires"][user]["Date_Assigned"].split("T")[0]
                    : newDate.split("T")[0]
                }}
              </div>
              <div class="whenCompleted flex items-center justify-center text-base">
                {{
                  !!projectToBeModified
                    ? projectToBeModified.PROJECT_STATUS === "Draft"
                      ? " "
                      : !!projectToBeModified["questionnaires"][user] &&
                        projectToBeModified["questionnaires"][user]["topicsCompleted"] ===
                          projectToBeModified["Number_Of_Topics"] * 1
                      ? ("PROJECT_OPTIONS.COMPLETED" | translate)
                      : ("PROJECT_OPTIONS.NOT_COMPLETED" | translate)
                    : ""
                }}
              </div>
              <div class="watchlist flex items-center justify-center text-base">
                {{ user | getQuestionnaireWatchList: watchListUsers:projectToBeModified }}
              </div>
              <div class="score flex items-center justify-center text-base">
                {{
                  !!projectToBeModified &&
                  !!projectToBeModified["questionnaires"] &&
                  !!projectToBeModified["questionnaires"][user]
                    ? projectToBeModified["questionnaires"][user]["score"]
                    : 0
                }}
              </div>
              <div class="action flex items-center justify-center text-base">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon matTooltip="{{ 'ACTIONS' | translate }}" matTooltipClass="custom-tooltip"
                    >more_vert
                  </mat-icon>
                </button>
  
                <mat-menu #menu="matMenu">
                  <button
                    *ngIf="(!isProjectDraft && isModified) || userInSession === user"
                    (click)="goToAnwswer(projectToBeModified['questionnaires'][user]['PK_QUEST_ASSIGN'])"
                    mat-menu-item
                  >
                    <mat-icon>launch</mat-icon>
                    <span>{{ "APRI" | translate }}</span>
                  </button>
                  <button
                    (click)="toggleUserTable(true, user)"
                    mat-menu-item
                    *ngIf="projectToBeModified?.PROJECT_STATUS !== 'Closed'"
                  >
                    <mat-icon>account_circle</mat-icon>
                    <span
                      >{{ "PROJECT_OPTIONS.CHANGE" | translate }}
                      {{ "DICTIONARY.Responsabile" | translate | titlecase }}</span
                    >
                  </button>
                  <button mat-menu-item (click)="addUsersToWatchlistDialog(user)">
                    <mat-icon> fact_check </mat-icon>
                    <span>{{ "WATCHLIST" | translate }}</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="exportQuestionnairefunc(projectToBeModified['questionnaires'][user])"
                    *ngIf="projectToBeModified?.PROJECT_STATUS !== 'Draft'"
                  >
                    <mat-icon>import_export</mat-icon>
                    <span>{{ "PROJECT_OPTIONS.EXPORT" | translate }}</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="removeUser(ind)"
                    *ngIf="projectToBeModified?.PROJECT_STATUS !== 'Closed'"
                  >
                    <mat-icon>move_to_inbox</mat-icon>
                    <span>{{ "PROJECT_OPTIONS.REMOVE" | translate }}</span>
                  </button>
                  <button
                    *ngIf="!isProjectDraft && isModified"
                    mat-menu-item
                    (click)="getNotes(projectToBeModified['questionnaires'][user], user)"
                  >
                    <mat-icon>sms</mat-icon>
                    <span>{{ "PROJECT_OPTIONS.NOTE" | translate }}</span>
                  </button>
                  <button
                    *ngIf="!isProjectDraft && isModified"
                    mat-menu-item
                    (click)="openFileRepo(projectToBeModified['questionnaires'][user])"
                  >
                    <mat-icon>folder</mat-icon>
                    <span>File Repository</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>


  <div class="flex justify-end">
    <div class="flex">
      <label class="mr-3 flex items-center whiteBtn">
        <input onclick="this.value = null" (change)="onFileChange($event)" type="file" class="hidden" />
        {{ "PROJECT_OPTIONS.IMPORT_USER" | translate }}
        <app-spinner [height]="'5px'" [width]="'30'" *ngIf="importProjectLoadingUsers[0]"></app-spinner>
      </label>
    </div>
    <div>
      <button
        [ngClass]="{
          disabled:
            this.ProjectFormGroup.value['questionnaireForAssignment'] === null ||
            projectToBeModified?.PROJECT_STATUS === 'Closed'
        }"
        (click)="toggleUserTable(false)"
        class="colorBtn"
      >
        {{ "PROJECT_OPTIONS.ADD_USER" | translate }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="showUserTable" class="overlayHidden" [ngClass]="{ overlayShow: showUserTable }">
  <changeUserOrAdd
    (multipleUserEvent)="getUser($event)"
    [selectedUsers]="finalUsersChosen"
    (hideTableEvent)="showUserTable = false"
    [isModified]="isModified"
    [selectionMode]="false"
    [isActive]="!!projectToBeModified ? projectToBeModified.PROJECT_STATUS === 'Active' : false"
    (changeResponsibleEvent)="confirmUsers()"
  >
  </changeUserOrAdd>
</div>
<!-- watch list table -->
<div *ngIf="showWatchListDialog" class="overlayHidden" [ngClass]="{ overlayShow: showWatchListDialog }">
  <changeUserOrAdd
    (multipleUserEvent)="getUserWatchList($event)"
    [selectedUsers]="questPreselectedWatchlist"
    [watchListFullUsers]="fullUsersWatchlist"
    [usedForWatchlist]="true"
    [questionnaireOwner]="questionnaireUserToAddToWatchlist"
    (hideTableEvent)="closeWatchListWithNoConfirmation()"
    [isModified]="isModified"
    [selectionMode]="false"
    [isActive]="!!projectToBeModified ? projectToBeModified.PROJECT_STATUS === 'Active' : false"
    (changeResponsibleEvent)="confirmWatchListUsers()"
  >
  </changeUserOrAdd>
</div>
<!-- watch list table -->
<div *ngIf="changeResponsinbleTableShow" class="overlayHidden" [ngClass]="{ overlayShow: changeResponsinbleTableShow }">
  <changeUserOrAdd
    [selectedUsers]="finalUsersChosen"
    (hideTableEvent)="changeResponsinbleTableShow = false"
    [isModified]="isModified"
    [selectionMode]="true"
    [isCollaborative]="ProjectFormGroup.get('isProjectCollaborative').value"
    [isActive]="!!projectToBeModified ? projectToBeModified.PROJECT_STATUS === 'Active' : false"
    (changeResponsibleEvent)="changeResponsible($event)"
    (collaborativeOptionSelected)="collaborativeOptions($event)"
  >
  </changeUserOrAdd>
</div>
