<mat-card class="reset-password-form text-center border border-light p-5">
  <mat-card-header>
    <img class="img-responsive" src="assets/images/QuestApp.png" />
  </mat-card-header>

  <h2 style="margin-left: 20%; margin-top: -20px">Reset Password</h2>

  <form [formGroup]="" class="example-form">
    <mat-form-field class="example-full-width my-form-field" style="width: 100%">
      <mat-label>Username</mat-label>
      <input matInput type="text" [formControl]="usernameFormControl" placeholder="Enter your Username" />
      <mat-icon matSuffix>account_circle</mat-icon>
      <mat-error *ngIf="usernameFormControl.hasError('required')"> Username is <strong>required</strong> </mat-error>
    </mat-form-field>
  </form>

  <form [formGroup]="" class="example-form">
    <mat-form-field class="example-full-width my-form-field" style="width: 100%">
      <mat-label>New Password</mat-label>
      <input
        matInput
        type="password"
        [formControl]="passwordFormControl"
        [type]="hide ? 'password' : 'text'"
        placeholder="Enter your new Password"
      />
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-pressed]="hide">
        <mat-icon style="font-size: 150%">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      <mat-error *ngIf="passwordFormControl.hasError('required')">
        New Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </form>

  <div
    style="margin-left: 60%; font-size: small; color: black; cursor: pointer"
    (click)="this.router.navigate(['/login'])"
  >
    Back to login
  </div>

  <div style="margin-top: 5%; margin-left: 5%">
    <button mat-button class="resetPasswordButton" (click)="resetPassword()">Reset Password</button>
  </div>

  <mat-error *ngIf="error">
    {{ error }}
  </mat-error>
</mat-card>

<!-- <simple-notifications></simple-notifications> -->
