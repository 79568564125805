<div class="projects-container mt-[2vh] w-full rounded-3xl bg-matCardColorCustom px-10 pb-6">
  <div class="title-line mb-[2.5rem] flex w-full !items-center justify-between pt-[2.5rem] text-4xl text-main">
    <div class="flex items-center self-start justify-self-start">
      <span class="mb-0 text-2xl font-semibold tracking-[-1px]">{{
        "DICTIONARY.Progetti" | translate | titlecase
      }}</span>
      <div class="w-[24vw] pl-12 font-poppins text-base">
        {{ "PROJECT_TABLE.DESCRIPTION1" | translate }}
        {{ "DICTIONARY.Progetti" | translate | lowercase | articleDictionary: "plural":lang }}
        {{ "DICTIONARY.Topic" | translate | verbPronounDictionary: "plural":lang }}
        {{ "PROJECT_TABLE.DESCRIPTION2" | translate }}
      </div>
    </div>
    <div class="flex flex-col">
      <app-advanced-filter
        [lang]="lang"
        (updateProjectsFilters)="updateProjectsFilters($event)"
        (projectSearch)="projectSearch($event)"
        (projectReset)="projectReset()"
        [modelFilter]="modelFilter"
        [modelOrProject]="'project'"
        class="z-20"
      >
      </app-advanced-filter>
    </div>
  </div>
  <div class="projects-table-container" matSort (matSortChange)="sortProjects($event)">
    <div class="one-line-grid mb-[1vh]">
      <div class="select-area flex items-center justify-center font-bold">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isPageChecked()"
          [indeterminate]="checkedProjects.length > 0 && !isPageChecked()"
        >
        </mat-checkbox>
      </div>
      <div mat-sort-header="TITLE" class="title-desc flex items-center text-base font-bold">
        <h2>{{ "PROJECT_TABLE.TITLE_&_DESCRIPTION" | translate }}</h2>
      </div>
      <div mat-sort-header="Questionnaire_Title" class="modello flex items-center text-base font-bold">
        <h2>{{ "DICTIONARY.Modello" | translate | titlecase }}</h2>
      </div>
      <div mat-sort-header="Creator" class="created-from created-from flex items-center text-base font-bold">
        <h2>{{ "PROJECT_TABLE.MANAGER" | translate }}</h2>
      </div>
      <div mat-sort-header="PROJECT_STATUS" class="status flex items-center justify-center text-base font-bold">
        <h2>{{ "PROJECT_TABLE.STATUS" | translate }}</h2>
      </div>
      <div mat-sort-header="DATE_CREATED" class="created-date flex items-center justify-center text-base font-bold">
        <h2>{{ "PROJECT_TABLE.DATE_CREATED" | translate }}</h2>
      </div>
      <div mat-sort-header="Expiry_Date" class="expired-date flex items-center justify-center text-base font-bold">
        <h2>{{ "PROJECT_TABLE.DEADLINE" | translate }}</h2>
      </div>
      <div mat-sort-header="USERS" class="users-assigned flex items-center justify-center text-base font-bold">
        <h2>{{ "PROJECT_TABLE.USER_ASSIGNED" | translate }}</h2>
      </div>
      <div mat-sort-header="Completed" class="completed flex items-center justify-center text-base font-bold">
        <h2>{{ "PROJECT_TABLE.COMPLETED" | translate }}</h2>
      </div>
      <div class="actions"></div>
    </div>

    <div
      class="all-projects h-[38vh] overflow-auto overflow-x-hidden xl:h-[40vh] 2xl:h-[54vh] 1.5xl:h-[54vh] 3xl:h-[60vh]"
      [ngClass]="{
        'flex w-full items-center justify-center': loadingProjects,
        hidden: totalPageSizeAndIndex.total === 0 && !loadingProjects
      }"
    >
      <div *ngIf="loadingProjects === false; else Loading">
        <div *ngIf="usersFullName | async as getUsername">
          <div
            *ngFor="let project of data; index as i"
            class="model-template mb-[0.8rem] h-[fit-content] bg-white text-black"
            [ngClass]="{ '!mb-0': i / 9 === 1 }"
          >
            <div class="select-area flex items-center justify-center">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selectionToggle(project, $event.checked) : null"
                [checked]="selection.isSelected(project)"
              ></mat-checkbox>
            </div>
            <div class="title-desc flex min-h-[4rem] flex-col items-start justify-center text-base">
              <div
                class="quest-title max-w-full truncate pr-4"
                [matTooltip]="project.TITLE"
                matTooltipClass="custom-tooltip"
                appShowIfTruncated
              >
                {{ project.TITLE }}
              </div>
              <div
                class="max-w-full truncate pr-4 text-sm tracking-normal"
                [matTooltip]="project.DESCRIPTION"
                matTooltipClass="custom-tooltip"
                appShowIfTruncated
              >
                {{ project.DESCRIPTION }}
              </div>
            </div>
            <div class="modello-text modello flex flex-col items-start justify-center text-base">
              <div
                (click)="showModelClicked(project.Questionnaire_Title, project.FK_QUEST)"
                class="max-w-full cursor-pointer truncate pr-4 text-dialog underline"
                [matTooltip]="project.Questionnaire_Title"
                matTooltipClass="custom-tooltip"
                appShowIfTruncated
              >
                {{ project.Questionnaire_Title }}
              </div>
            </div>
            <div class="created-from flex flex-col items-start justify-center">
              <div
                class="max-w-full truncate"
                [matTooltip]="getUsername[project.Creator]"
                matTooltipClass="custom-tooltip"
                appShowIfTruncated
              >
                {{ getUsername[project.Creator] }}
              </div>
            </div>

            <div class="flex items-center justify-center">
              <div
                class="status rounded[6px] flex h-[65%] w-[80%] items-center justify-center"
                [ngClass]="project.PROJECT_STATUS"
              >
                {{ project.PROJECT_STATUS | convertToItalian: lang }}
              </div>
            </div>
            <div class="created-date flex items-center justify-center">
              {{ project.DATE_CREATED.split("T")[0] }}
            </div>
            <div class="expired-date flex items-center justify-center">
              {{ project.Expiry_Date.split("T")[0] }}
            </div>
            <div class="users-assigned flex items-center justify-center">
              {{ !!project["number_of_users"] ? project["number_of_users"] : 0 }}
            </div>
            <div class="completed flex items-center justify-center">
              {{ project.Completed }}
            </div>
            <div class="actions flex items-center justify-center">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon class="text-dialog" matTooltip="{{ 'ACTIONS' | translate }}" matTooltipClass="custom-tooltip">
                  more_vert</mat-icon
                >
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="modifyProject(project.PK_PROJECT)" mat-menu-item>
                  <mat-icon>launch</mat-icon>
                  <span>{{ "APRI" | translate }}</span>
                </button>
                <button mat-menu-item (click)="cloneProject(project['PK_PROJECT'], project.number_of_users)">
                  <mat-icon>content_copy</mat-icon>
                  <span>{{ "CLONA" | translate }}</span>
                </button>
                <button
                  mat-menu-item
                  (click)="
                    addUsersToProjectWatchlistDialog(
                      project['PK_PROJECT'],
                      project['projectWatchlist'],
                      project['prelectedUsers']
                    )
                  "
                >
                  <mat-icon>fact_check</mat-icon>
                  <span>{{ "WATCHLIST" | translate }}</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="project['PROJECT_STATUS'] !== 'Archived'"
                  (click)="archiveProject(project)"
                >
                  <mat-icon>move_to_inbox</mat-icon>
                  <span>{{ "ARCHIVIA" | translate }}</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="project['PROJECT_STATUS'] === 'Archived' || project['PROJECT_STATUS'] === 'Draft'"
                  (click)="deleteProject(project)"
                >
                  <mat-icon>delete</mat-icon>
                  <span>{{ "ELIMINA" | translate }}</span>
                </button>
                <button mat-menu-item (click)="toggleChangeOwner(project['PK_PROJECT'])">
                  <mat-icon>account_circle</mat-icon>
                  <span>{{ "CAMBIA_GESTORE" | translate }}</span>
                </button>
                <button
                  mat-menu-item
                  (click)="indexToExport = i; toggleExportVisibility()"
                  *ngIf="project['PROJECT_STATUS'] !== 'Draft'"
                >
                  <mat-icon>file_download</mat-icon>
                  <span>{{ "PROJECT_TABLE.EXPORT_ZIP" | translate }}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No data found warning -->
    <div
      id="noDataFound"
      class="all-projects h-[38vh] overflow-auto overflow-x-hidden xl:h-[40vh] 2xl:h-[54vh] 1.5xl:h-[54vh] 3xl:h-[60vh]"
      *ngIf="totalPageSizeAndIndex.pageIndex === 0 && totalPageSizeAndIndex.total === 0 && !loadingProjects"
    >
      <div class="flex h-full flex-col items-center justify-center gap-8">
        <mat-icon class="mb-8 !text-card">search_off</mat-icon>
        <span id="main-text" class="text-5xl font-semibold text-main">{{
          "PROJECT_TABLE.NO_DATA_FOUND" | translate | titlecase
        }}</span>
        <span id="sub-text" class="max-w-4xl text-center text-3xl text-gray-400">{{
          lang === "it"
            ? "Non è possibile trovare alcun " +
              ("DICTIONARY.Progetto" | translate | lowercase) +
              " corrispondente alla tua ricerca"
            : "It is not possible to find any " +
              ("DICTIONARY.Progetto" | translate | lowercase) +
              " matching your search"
        }}</span>
      </div>
    </div>

    <div class="mt-4 flex items-center justify-end" *ngIf="loadingProjects === false">
      <mat-paginator
        [length]="totalPageSizeAndIndex.total"
        [pageIndex]="totalPageSizeAndIndex.pageIndex"
        [pageSize]="10"
        (page)="getNextProjectsPage($event)"
        [hidePageSize]="true"
      >
      </mat-paginator>
    </div>

    <div id="buttons" class="flex justify-start" *ngIf="loadingProjects === false">
      <div class="inline-flex items-center gap-6" id="selection-buttons">
        <button
          id="archive-button"
          class="redBtn disabled:redBtn-disabled"
          (click)="archiveProject()"
          [disabled]="checkedProjects.length === 0"
        >
          {{ "ARCHIVIA" | translate }} ({{ checkedProjects.length }})
        </button>
        <button
          id="delete-button"
          class="redBtn disabled:redBtn-disabled"
          (click)="deleteProject()"
          [disabled]="checkedProjects.length === 0"
        >
          {{ "ELIMINA" | translate }} ({{ checkedProjects.length }})
        </button>
      </div>
    </div>

    <div
      *ngIf="showUserOwnerTableForChange"
      class="overlayHidden"
      [ngClass]="{ overlayShow: showUserOwnerTableForChange }"
    >
      <changeUserOrAdd
        (click)="changeProjectOwner($event)"
        (hideTableEvent)="showUserOwnerTableForChange = false"
        [usedforChangeResponsabile]="true"
        (changeResponsibleEvent)="changeProjectOwner($event)"
      >
      </changeUserOrAdd>
    </div>
  </div>
</div>
<ng-template #Loading>
  <app-spinner class="flex justify-center"></app-spinner>
</ng-template>

<div *ngIf="showWatchListDialog" class="overlayHidden" [ngClass]="{ overlayShow: showWatchListDialog }">
  <changeUserOrAdd
    (multipleUserEvent)="getUserWatchList($event)"
    [selectedUsers]="preselectedUsers"
    [watchListFullUsers]="fullUsersWatchlist"
    [usedForWatchlist]="true"
    (hideTableEvent)="closeWatchListWithNoConfirmation()"
    [isModified]="true"
    [selectionMode]="false"
    [isActive]="true"
    (changeResponsibleEvent)="confirmWatchListUsers()"
  >
  </changeUserOrAdd>
</div>

<!-- Export Popup -->
<div
  id="exportPopup"
  *ngIf="showExportPopup"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showExportPopup }"
  (click)="toggleExportVisibility()"
>
  <div (click)="$event.stopPropagation()">
    <div>
      <mat-card class="flex w-full justify-start !rounded-xl bg-white !p-10">
        <div class="flex flex-col items-start gap-8">
          <div class="flex items-start justify-between">
            <p class="font-poppins text-3xl font-black tracking-normal text-dialog">Export Questionnaire</p>
          </div>
          <div class="flex items-center justify-start gap-6">
            <mat-icon class="!text-dialog">error</mat-icon>
            <label id="export-radio-group-label" class="text-xl text-welcome">Choose what you want to export:</label>
          </div>
          <mat-radio-group aria-labelledby="export-radio-group-label" class="flex gap-4" [(ngModel)]="exportMethod">
            <mat-radio-button *ngFor="let method of exMethodsArr" [value]="method" disableRipple="true">
              <p class="text-xl">{{ method }}</p>
            </mat-radio-button>
          </mat-radio-group>
          <div id="popup-buttons" class="flex w-full items-center justify-end gap-4">
            <button class="whiteBtn" (click)="toggleExportVisibility()">
              {{ "ANNULLA" | translate }}
            </button>
            <button
              class="colorBtn"
              (click)="openExportZip(data[indexToExport]['PK_PROJECT'], exportMethod); toggleExportVisibility()"
            >
              {{ "CONFERMA" | translate }}
            </button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
