import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { concatMap, map, catchError } from 'rxjs/operators';
import { UserService } from 'src/app/data/api/users/users-service.service';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { loadUsersFailure, UserActions } from './users.actions';

@Injectable()
export class UserEffects {
  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getAllUsersAction),
      concatMap((data) =>
        this.userService.getUsers().pipe(
          map(
            (users) => ({
              type: UserActions.setAllUsersAction,
              users: users['data'],
            }),
            catchError((error) => of(loadUsersFailure({ error })))
          )
        )
      )
    );
  });

  getUserByUsername$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUserByUsername),
      concatMap((payload) =>
        this.userService.getUserByUsername(payload['username']).pipe(
          map(
            (response) => ({
              type: UserActions.getUserByUsernameSuccess,
              language: response['Language'],
            }),
            catchError((error) => of(loadUsersFailure({ error })))
          )
        )
      )
    );
  });

  importUsersToProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.importUsersFrom),
      concatMap((data) =>
        this.userService.importUsersToProject(data['users']).pipe(
          map(
            (users) => {
              if (users['notImported'].length > 0) {
                let message = `I seguenti utenti non sono stati importati perché non presenti nel sistema:\n
              `;
                users['notImported'].forEach((element) => {
                  message += element['NAME'] + ' ' + element['SURNAME'] + ', ';
                });

                this.notifyService.openWarningSwal(message);
              }
              return {
                type: UserActions.importUsersFromSuccess,
                users: users['users'],
              };
            },
            catchError((error) => of(loadUsersFailure({ error })))
          )
        )
      )
    );
  });

  searchUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.searchUsers),
      concatMap((payload) =>
        this.userService
          .searchUsers(
            payload['searchTerm'],
            payload['pageIndex'],
            payload['pageSize'],
            payload['sortCol'],
            payload['sortOrder'],
            {
              clientFilter: payload['clientFilter'],
              groupFilter: payload['groupFilter'],
              emailFilter: payload['emailFilter'],
              statusActive: payload['statusActive'],
              statusDisabled: payload['statusDisabled'],
            }
          )
          .pipe(
            map(
              (users) => ({
                type: UserActions.searchUsersSuccess,
                users: users['data'],
                pageIndex: users['pageIndex'],
                total: users['length'],
              }),
              catchError((error) => of(loadUsersFailure({ error })))
            )
          )
      )
    );
  });

  reassignClientGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.reassignClientGroup),
      concatMap((payload) =>
        this.userService.reassignClientGroup(payload['username'], payload['client_id'], payload['group_id']).pipe(
          map(
            (users) => ({
              type: UserActions.reassignClientGroupSuccess,
              searchTerm: payload['searchTerm'],
              pageIndex: payload['pageIndex'],
              pageSize: payload['pageSize'],
              sortCol: payload['sortCol'],
              sortOrder: payload['sortOrder'],
              filter: {
                clientFilter: payload['clientFilter'],
                groupFilter: payload['groupFilter'],
                emailFilter: payload['emailFilter'],
                statusActive: payload['statusActive'],
                statusDisabled: payload['statusDisabled'],
              },
            }),
            catchError((error) => of(loadUsersFailure({ error })))
          )
        )
      )
    );
  });

  searchClientGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.searchClientGroup),
      concatMap((payload) =>
        this.userService
          .searchClientGroup(
            payload['searchTerm'],
            payload['field'],
            payload['pageIndex'],
            payload['pageSize'],
            payload['sortCol'],
            payload['sortOrder'],
            payload['client_id']
          )
          .pipe(
            map(
              (users) => ({
                type: UserActions.searchClientGroupSuccess,
                clientGroup: users['data'],
                pageIndex: users['pageIndex'],
                total: users['length'],
                field: payload['field'],
              }),
              catchError((error) => of(loadUsersFailure({ error })))
            )
          )
      )
    );
  });

  searchAllClientGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.searchAllClientGroup),
      concatMap((payload) =>
        this.userService.searchAllClientGroup(payload['admin']).pipe(
          map(
            (users) => ({
              type: UserActions.searchAllClientGroupSuccess,
              data: users['data'],
            }),
            catchError((error) => of(loadUsersFailure({ error })))
          )
        )
      )
    );
  });

  loadUsersPaginated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserActions.getUsersPaginated,
        UserActions.deleteUserSuccess,
        UserActions.disableUserSuccess,
        UserActions.reactivateUserSuccess,
        UserActions.createUserSuccess,
        UserActions.createNewUserSuccess,
        UserActions.reassignClientGroupSuccess
      ),
      concatMap((payload) =>
        this.userService
          .searchUsers(
            payload['searchTerm'] ? payload['searchTerm'] : '',
            payload['pageIndex'],
            payload['pageSize'],
            payload['sortCol'] ? payload['sortCol'] : 'Name',
            payload['sortOrder'] ? payload['sortOrder'] : 'asc',
            {
              clientFilter: payload['filter'] ? payload['filter']['clientFilter'] : '',
              groupFilter: payload['filter'] ? payload['filter']['groupFilter'] : '',
              emailFilter: payload['filter'] ? payload['filter']['emailFilter'] : '',
              statusActive: payload['filter'] ? payload['filter']['statusActive'] : true,
              statusDisabled: payload['filter'] ? payload['filter']['statusDisabled'] : true,
            }
          )
          .pipe(
            map(
              (users) => ({
                type: UserActions.getUsersPaginatedSuccess,
                users: users,
              }),
              catchError((error) => of(loadUsersFailure({ error })))
            )
          )
      )
    );
  });

  loadUsersPaginatedForOwner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUsersPaginatedForOwner),
      concatMap((data) =>
        this.userService.getUsersPaginatedForOwner(data['pageIndex'], data['pageSize'], data['toBeExcluded']).pipe(
          map(
            (users) => ({
              type: UserActions.getUsersPaginatedSuccess,
              users: users,
            }),
            catchError((error) => of(loadUsersFailure({ error })))
          )
        )
      )
    );
  });

  createUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.createUser),
      concatMap((data) =>
        this.userService.createUser(data['user']).pipe(
          map((users) => ({ type: UserActions.createUserSuccess })),
          catchError((error) => {
            this.notifyService.openWarningSwal(error.error.message);
            return of(loadUsersFailure({ error }));
          })
        )
      )
    );
  });
  createNewUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.createNewUser),
      concatMap((data) =>
        this.userService.createNewUser(data['user']).pipe(
          map((users) => ({ type: UserActions.createNewUserSuccess })),
          catchError((error) => {
            this.notifyService.openWarningSwal(error.error.message);
            return of(loadUsersFailure({ error }));
          })
        )
      )
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUser),
      concatMap((data) =>
        this.userService.updateUser(data['user']).pipe(
          map((users) => {
            if (
              this.authService.getUserName() === users['updatedUser']['PK_USER'] &&
              users['updatedUser']['Language']
            ) {
              sessionStorage.setItem('abilities', JSON.stringify(users['updatedUser']['USERS_ABILITIES']));
            }
            return {
              type: UserActions.updateUserSuccess,
              user: users['updatedUser'],
            };
          }),
          catchError((error) => {
            this.notifyService.openWarningSwal(error.error.message);
            return of(loadUsersFailure({ error }));
          })
        )
      )
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updatePassword),
      concatMap((data) =>
        this.userService.updatePassword(data['password']).pipe(
          map(() => {
            this.dialogService.changePasswordModal.close();
            return { type: UserActions.updatePasswordSuccess };
          }),
          catchError((error) => {
            this.notifyService.openWarningSwal(error.error.message);
            return of(loadUsersFailure({ error }));
          })
        )
      )
    );
  });

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.deleteUser),
      concatMap((payload) =>
        this.userService.deleteUser(payload['username']).pipe(
          map((users) => ({
            type: UserActions.deleteUserSuccess,
            searchTerm: payload['searchTerm'],
            pageIndex: payload['pageIndex'],
            pageSize: payload['pageSize'],
            sortCol: payload['sortCol'],
            sortOrder: payload['sortOrder'],
            filter: {
              clientFilter: payload['clientFilter'],
              groupFilter: payload['groupFilter'],
              emailFilter: payload['emailFilter'],
              statusActive: payload['statusActive'],
              statusDisabled: payload['statusDisabled'],
            },
          })),
          catchError((error) => {
            this.notifyService.openWarningSwal(error.error.message);
            return of(loadUsersFailure({ error }));
          })
        )
      )
    );
  });
  disabledUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.disableUser),
      concatMap((payload) =>
        this.userService.disableUser(payload['username']).pipe(
          map((users) => ({
            type: UserActions.disableUserSuccess,
            searchTerm: payload['searchTerm'],
            pageIndex: payload['pageIndex'],
            pageSize: payload['pageSize'],
            sortCol: payload['sortCol'],
            sortOrder: payload['sortOrder'],
            filter: {
              clientFilter: payload['clientFilter'],
              groupFilter: payload['groupFilter'],
              emailFilter: payload['emailFilter'],
              statusActive: payload['statusActive'],
              statusDisabled: payload['statusDisabled'],
            },
          })),
          catchError((error) => {
            this.notifyService.openWarningSwal(error.error.message);
            return of(loadUsersFailure({ error }));
          })
        )
      )
    );
  });

  reactivateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.reactivateUser),
      concatMap((payload) =>
        this.userService.reactivateUser(payload['username']).pipe(
          map((users) => ({
            type: UserActions.reactivateUserSuccess,
            searchTerm: payload['searchTerm'],
            pageIndex: payload['pageIndex'],
            pageSize: payload['pageSize'],
            sortCol: payload['sortCol'],
            sortOrder: payload['sortOrder'],
            filter: {
              clientFilter: payload['clientFilter'],
              groupFilter: payload['groupFilter'],
              emailFilter: payload['emailFilter'],
              statusActive: payload['statusActive'],
              statusDisabled: payload['statusDisabled'],
            },
          })),
          catchError((error) => {
            this.notifyService.openWarningSwal(error.error.message);
            return of(loadUsersFailure({ error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private notifyService: NotifyService,
    private userService: UserService,
    private dialogService: GeneralDialogService,
    public authService: AuthService
  ) {}
}
