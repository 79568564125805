<div>
  <input type="text" mat-input matInput placeholder="Opzione" [(ngModel)]="option" />
  <button mat-button (click)="addOption()">Aggiungi</button>
  <button mat-button (click)="cancel()" style="margin-left: 15%">Annulla</button>
</div>
<strong *ngIf="data.action !== 'Modify'">
  <mat-hint>Le risposte inserite devono essere separate da ";;"</mat-hint>
</strong>
<strong *ngIf="data.action === 'Modify'">
  <mat-hint>Inserire una sola risposta</mat-hint>
</strong>
