import { AllGroupClient, ClientOrGroup } from 'src/app/data/models/ClientGroup';
import { User } from 'src/app/data/models/User';

export interface UserState {
  username: string;
  users?: User[];
  usersPaginated?: User[];
  usersPageIndex?: number;
  length?: number;
  loadingUsers?: boolean;
  importingUsersLoading: boolean;
  usersToImport: User[];
  language: string;
  loadingAllGroupClient?: boolean;
  loadingClient?: boolean;
  clientPaginated?: ClientOrGroup[];
  allClientGroup?: AllGroupClient[];
  searchTermClientGroup?: string;
  searchTermClientGroupModel?: string;
  clientPageIndex?: number;
  clientlength?: number;
  loadingGroup?: boolean;
  groupPaginated?: ClientOrGroup[];
  groupPageIndex?: number;
  grouplength?: number;
}

export const userInitialState: UserState = {
  username: '',
  users: [],
  usersPaginated: [],
  usersPageIndex: 0,
  length: 10,
  loadingUsers: false,
  loadingAllGroupClient: false,
  importingUsersLoading: false,
  usersToImport: [],
  language: 'it',
};
