import { createAction, props } from '@ngrx/store';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { StatusFilter } from 'src/app/data/models/statusFilter';

export const enum ArchivedActionTypes {
  loadQuestionnairesArchived = '[Questionnaires] load archived questionnaires',
  setQuestionnairesToBeArchived = '[Questionnaires] set questionnaires to be archived',
  setArchivedQuestionnaires = '[Questionnaires] set archived questionnaires',
  setQuestionnairesToBeStructured = '[Questionnaires] set questionnaires to be structured',
}

export const loadQuestionnairesArchived = createAction(ArchivedActionTypes.loadQuestionnairesArchived);

export const setQuestionnairesToBeArchived = createAction(
  ArchivedActionTypes.setQuestionnairesToBeArchived,
  props<{
    data: string[];
    pageIndex: number;
    title: string;
    AscDesc: string;
    archived?: boolean;
    searchString?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    lastAssignmentFromFilter?: string;
    lastAssignmentToFilter?: string;
    lastChangeFromFilter?: string;
    lastChangeToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const setArchivedQuestionnaires = createAction(
  ArchivedActionTypes.setArchivedQuestionnaires,
  props<{ data: QuestionnairesStructure[] }>()
);

export const setQuestionnairesToBeStructured = createAction(
  ArchivedActionTypes.setQuestionnairesToBeStructured,
  props<{ data: string[] }>()
);
