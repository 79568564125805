import { createAction, props } from '@ngrx/store';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { StatusFilter, UserStatusFilter } from 'src/app/data/models/statusFilter';

export const enum ActionTypes {
  saveModelFilters = '[Questionnaires] save Model Filters',
  saveUserFilters = '[Admin User] save User Filters',
  exportModelCsvType = '[Questionnaires] export model in csv',
  loadQuestionnairesType = '[Questionnaires] get all Questionnaires',
  setQuestionnairesType = '[Questionnaires] get all Questionnaires success',
  updateQuestionnairesType = '[Questionnaires] update a questionnaire',
  updateQuestionnairesTypeFail = '[Questionnaires] update a questionnaire failed',
  postNewQuestionnaireType = '[Questionnaires] post a questionnaire',
  addQuestionnairesType = '[Questionnaires] add a new questionnaire',
  deleteQuestionnairesType = '[Questionnaires] delete questionnaire',
  addQuestionnairesTypeSuccess = '[Questionnaires] add a new questionnaire Success',
  addQuestionnairesTypeFail = '[Questionnaires] add a new questionnaire Failed',
  deleteQuestionnairesSuccess = '[Questionnaires] delete questionnaire success',
  loadAssignedquestionnaires = 'loadAssignedquestionnaires',
  setAssignedQuestionnairesType = 'setAssignedQuestionnairesType',
  getSingleQuestionnaire = '[Questionnaires] get single questionnaire',
  duplicateQuestionnaireAction = '[Questionnaires] duplicate questionnaire',
  duplicateQuestionnaireActionSuccess = '[Questionnaires] duplicate questionnaire success',
  setModifiedQuestionnaire = '[Questionnaires] set modified questionnaire',
  removeAssignedQuestionnaire = '[Questionnaires] remove assigned questionnaire',
  removeArchivedQuestsFromStructured = '[Questionnaires] get single questionnaire',
  updateQuestionnaires = 'updateQuestionnaires',
  searchPerTerm = '[Questionnaires] get models by search term',
  REDIRECT_DONE = 'redirect page',
  setUserAbilities = '[Questionnaires] Set user abilities',
  storeCheckedModels = '[Questionnaires] store Checked Models',
  reduceNumberOfProjects = '[Questionnaires] reduce Number Of Projects',
}

export const saveModelFilters = createAction(
  ActionTypes.saveModelFilters,
  props<{
    saved: boolean;
    searchTerm?: string;
    creatorFilter?: string;
    status?: StatusFilter;
    lastAssignmentFromFilter?: string;
    lastAssignmentToFilter?: string;
    lastChangeFromFilter?: string;
    lastChangeToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);
export const saveUserFilters = createAction(
  ActionTypes.saveUserFilters,
  props<{
    saved: boolean;
    searchTerm?: string;
    status?: UserStatusFilter;
    clientFilter?: string;
    groupFilter?: string;
    emailFilter?: string;
  }>()
);

export const reduceNumberOfProjects = createAction(ActionTypes.reduceNumberOfProjects, props<{ project_ids: any[], questionnairesReduceProject: {} }>());

export const exportModelCsv = createAction(
  ActionTypes.exportModelCsvType,
  props<{ id: string; typeExcel: string; lang: string; topic: string }>()
);

export const setUserAbilities = createAction(ActionTypes.setUserAbilities, props<{ data: { Ability: string }[] }>());

export const loadQuestionnaires = createAction(
  ActionTypes.loadQuestionnairesType,
  props<{
    dontResetQuestionnaireToBeModified?: boolean;
    pageIndex: number;
    title: string;
    AscDesc: string;
    archived?: boolean;
    searchString?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    lastAssignmentFromFilter?: string;
    lastAssignmentToFilter?: string;
    lastChangeFromFilter?: string;
    lastChangeToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const setQuestionnairesType = createAction(
  ActionTypes.setQuestionnairesType,
  props<{ data: QuestionnairesStructure[] }>()
);

export const postQuestionnaire = createAction(
  ActionTypes.postNewQuestionnaireType,
  props<{ newQuestionnaire: QuestionnairesStructure }>()
);

export const addQuestionnaires = createAction(
  ActionTypes.addQuestionnairesType,
  props<{ newQuestionnaire: QuestionnairesStructure }>()
);

export const addQuestionnairesSuccess = createAction(ActionTypes.addQuestionnairesTypeSuccess);

export const addQuestionnairesFail = createAction(ActionTypes.addQuestionnairesTypeFail);

export const updateQuestionnaires = createAction(
  ActionTypes.updateQuestionnairesType,
  props<{ modifiedQuestionnaire: QuestionnairesStructure }>()
);
export const updateQuestionnairesFail = createAction(
  ActionTypes.updateQuestionnairesTypeFail,
  props<{ modifiedQuestionnaire: QuestionnairesStructure }>()
);

export const deleteQuestionnairesAction = createAction(
  ActionTypes.deleteQuestionnairesType,
  props<{
    deletedIds: QuestionnairesStructure[];
    pageIndex: number;
    title: string;
    AscDesc: string;
    archived?: boolean;
    searchString?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    lastAssignmentFromFilter?: string;
    lastAssignmentToFilter?: string;
    lastChangeFromFilter?: string;
    lastChangeToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const deleteQuestionnairesSuccess = createAction(ActionTypes.deleteQuestionnairesSuccess);

export const getSingleQuestionnaire = createAction(ActionTypes.getSingleQuestionnaire, props<{ id: string }>());

export const duplicateQuestionnaire = createAction(
  ActionTypes.duplicateQuestionnaireAction,
  props<{
    id: string;
    newTitle: string;
    pageIndex: number;
    title: string;
    AscDesc: string;
    archived?: boolean;
    searchString?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    lastAssignmentFromFilter?: string;
    lastAssignmentToFilter?: string;
    lastChangeFromFilter?: string;
    lastChangeToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const duplicateQuestionnaireSuccess = createAction(
  ActionTypes.duplicateQuestionnaireActionSuccess,
  props<{
    newQuestionnaire: QuestionnairesStructure;
    newTitle: string;
    pageIndex: number;
    title: string;
    AscDesc: string;
    archived?: boolean;
    searchString?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    lastAssignmentFromFilter?: string;
    lastAssignmentToFilter?: string;
    lastChangeFromFilter?: string;
    lastChangeToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const setModifiedQuestionnaire = createAction(
  ActionTypes.setModifiedQuestionnaire,
  props<{ questionnaire: QuestionnairesStructure }>()
);

export const removeAssignedQuestionnaire = createAction(
  ActionTypes.removeAssignedQuestionnaire,
  props<{ assignedQuests: QuestionnairesStructure[] }>()
);

export const removeArchivedQuestsFromStructured = createAction(
  ActionTypes.removeArchivedQuestsFromStructured,
  props<{ data: string }>()
);

export const searchPerTerm = createAction(
  ActionTypes.searchPerTerm,
  props<{
    pageIndex: number;
    title?: string;
    ascdesc?: string;
    archived?: boolean;
    searchString: string;
  }>()
);

export const storeCheckedModels = createAction(
  ActionTypes.storeCheckedModels,
  props<{ modelArray: QuestionnairesStructure[] }>()
);
