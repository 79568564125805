<ng-container>
  <div id="q-text" class="mb-6 flex min-w-[11vw] justify-between">
    <div
      class="line-clamp-2"
      matTooltip="{{ question.Question_Text }}"
      matTooltipClass="custom-tooltip"
      appShowIfTruncated
    >
      <strong class="text-xl">{{ question.Position }}</strong>
      <span
        class="text-xl"
        (contextmenu)="
          copyToClipBoard(question.Question_Text);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.TITLE_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
      >
        - {{ question.Question_Text }}
      </span>
    </div>

    <mat-icon
      *ngIf="question.Description !== ''"
      (click)="toggleDescriptionVisibility()"
      matTooltip="{{ plainText }}"
      class="ml-4 cursor-pointer text-main"
      >help
    </mat-icon>
  </div>
  <!-- I use formControl only as Value of radio Group -->
  <mat-radio-group aria-label="Select an option" [value]="yesNoForm.value['Input']">
    <mat-radio-button
      class="mr-5"
      [disabled]="reviewMode"
      [ngClass]="{
        'font-bold': yesNoForm.value['Input'] === question.Check_List_Box_Options[0]['id'],
        'disabled-text-color': reviewMode
      }"
      [value]="question.Check_List_Box_Options[0]['id']"
      (click)="!reviewMode && changeAnswer(question.Check_List_Box_Options[0])"
    >
      {{ "YES" | translate }}
    </mat-radio-button>

    <mat-radio-button
      [disabled]="reviewMode"
      [ngClass]="{
        'font-bold': yesNoForm.value['Input'] === question.Check_List_Box_Options[1]['id'],
        'disabled-text-color': reviewMode
      }"
      [value]="question.Check_List_Box_Options[1]['id']"
      (click)="!reviewMode && changeAnswer(question.Check_List_Box_Options[1])"
    >
      No
    </mat-radio-button>
  </mat-radio-group>
</ng-container>

<div
  *ngIf="showDescription"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showDescription }"
  (click)="toggleDescriptionVisibility()"
>
  <div
    id="selections-div"
    class="flex h-[fit-content] w-[55rem] flex-col justify-between gap-6 overflow-hidden rounded-2xl bg-white p-6"
    (click)="$event.stopPropagation()"
  >
    <div id="title" class="font-popppins text-4xl font-bold text-card">
      {{ "DESCRIZIONE" | translate }}
    </div>
    <div [innerHTML]="question.Description" class="py-[3rem] text-base">
      {{ question.Description }}
    </div>
    <div class="mt-[10px] flex flex-row !justify-end">
      <button
        (click)="
          copyToClipBoard(plainText);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.DESCRIPTION_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
        class="mr-3 whiteBtn"
      >
        {{ "QUEST_CREATION.DESC_COP" | translate }}
      </button>
      <button class="colorBtn" (click)="toggleDescriptionVisibility()">
        {{ "UNDERSTOOD" | translate }}
      </button>
    </div>
  </div>
</div>
