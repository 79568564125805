import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError, concatMap } from 'rxjs/operators';
import { ArchivedActionTypes } from './archive.state.actions';
import { ActionTypes } from '../questionnaire-store/state.actions';
import { transformBody } from 'src/app/data/data-handlers/utils.service';
import { GetQuestionnairService } from 'src/app/data/api/Questionnaire/get-questionnaires.service';
import { PostQuestionnaireService } from 'src/app/data/api/Questionnaire/post-questionnaire.service';

@Injectable()
export class ArchiveQuestionnaireEffects {
  loadQuestionnairesArchived$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArchivedActionTypes.loadQuestionnairesArchived),
      mergeMap(() =>
        this.questService.getQuestionnairyStructures(0, 10, 'Questionaire_Title', 'ASC', ['Archived']).pipe(
          map((quests) => ({
            type: ArchivedActionTypes.setArchivedQuestionnaires,
            data: quests['data'],
          })),
          catchError(() => EMPTY)
        )
      )
    );
  });

  setQuestionnairesToBeArchived$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArchivedActionTypes.setQuestionnairesToBeArchived),
      concatMap((payload) =>
        this.postService.changeQuestionnaireStatus(transformBody(payload['data'], 'Archived')).pipe(
          map((quests) => ({
            type: ActionTypes.loadQuestionnairesType,
            pageIndex: payload['pageIndex'],
            title: payload['title'],
            AscDesc: payload['AscDesc'],
            archived: payload['archived'],
            searchString: payload['searchString'],
            modelFilter: payload['modelFilter'],
            creatorFilter: payload['creatorFilter'],
            statusFilter: payload['statusFilter'],
            lastAssignmentFromFilter: payload['lastAssignmentFromFilter'],
            lastAssignmentToFilter: payload['lastAssignmentToFilter'],
            lastChangeFromFilter: payload['lastChangeFromFilter'],
            lastChangeToFilter: payload['lastChangeToFilter'],
            creationFromFilter: payload['creationFromFilter'],
            creationToFilter: payload['creationToFilter'],
          })),
          catchError(() => EMPTY)
        )
      )
    );
  });

  loadNewQuestionnairesArchived$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ArchivedActionTypes.setQuestionnairesToBeStructured),
      mergeMap((payload: { data: string[]; type: string }) =>
        this.postService.changeQuestionnaireStatus(transformBody(payload.data, 'Completed')).pipe(
          map((quests) => ({
            type: ActionTypes.loadQuestionnairesType,
            data: quests,
          })),
          catchError(() => EMPTY)
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private questService: GetQuestionnairService,
    private postService: PostQuestionnaireService
  ) {}
}
