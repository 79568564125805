import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Docufile } from 'src/app/data/models/Docufile';
import * as fromFileUploadActions from 'src/app/Store/upload-file-store/upload-file.actions';
import * as fromFileUploadSelectors from 'src/app/Store/upload-file-store/upload-file.selectors';

@Component({
  selector: 'app-file-repository',
  templateUrl: './file-repository.component.html',
  styleUrls: ['./file-repository.component.scss'],
})
export class FileRepositoryComponent implements OnInit, OnDestroy {
  isFileStored$: Observable<boolean>;
  isFileLoading$: Observable<boolean>;
  selectDownloading: Observable<boolean>;

  dataSource: MatTableDataSource<Docufile>;
  displayedColumns = ['select', 'topic', 'question', 'comment', 'file', 'date'];
  selection = new SelectionModel<Docufile>(true, []);
  selectedFiles: Docufile[] = [];

  dataToUnsub: Subscription;

  constructor(
    private store$: Store,
    public fileRepoModal: MatDialogRef<FileRepositoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.selectDownloading = this.store$.select(fromFileUploadSelectors.selectIsFileDownloading);
    this.store$.dispatch(
      fromFileUploadActions.GetFilesAction({
        questAssignId: this.data.Quest_Assing_Id,
      })
    );
    this.isFileStored$ = this.store$.select(fromFileUploadSelectors.selectIsFileStored);
    this.isFileLoading$ = this.store$.select(fromFileUploadSelectors.selectIsFileLoading);

    this.dataToUnsub = this.store$.select(fromFileUploadSelectors.selectStoredFiles).subscribe((data: Docufile[]) => {
      this.dataSource = new MatTableDataSource(data);
    });
  }

  downloadFile() {
    if (this.selectedFiles.length === 1) {
      this.selectedFiles.forEach((file: Docufile) => {
        this.store$.dispatch(
          fromFileUploadActions.DownloadFileAction({
            fileId: file.id,
          })
        );
      });
    } else if (this.selectedFiles.length > 1) {
      this.store$.dispatch(
        fromFileUploadActions.DownloadZipAction({
          fileList: this.selectedFiles,
          questTitle: this.data.Quest_Assign_Title,
        })
      );
    }
  }

  closeDialog() {
    this.fileRepoModal.close();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedFiles = [...this.selectedFiles];
      this.selectedFiles.length = 0;
    } else {
      this.selectedFiles = [...this.selectedFiles];
      this.selectedFiles.length = 0;
      this.dataSource.data.forEach((file) => {
        this.selection.select(file);
        this.selectedFiles = [...this.selectedFiles, file];
      });
    }
  }

  onSelectionChange(file: Docufile, isChecked: boolean) {
    this.selection.toggle(file);
    if (isChecked) {
      this.selectedFiles.push(file);
    } else {
      let index = this.selectedFiles.indexOf(file);
      this.selectedFiles.splice(index, 1);
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  ngOnDestroy() {
    this.dataToUnsub.unsubscribe();
  }
}
