import { createReducer, on } from '@ngrx/store';
import { getImageHeaderSuccess, getImageLoginSuccess, setImage, setTheme } from './administrative-page.actions';
import { administrativePageInitialState } from './administrative-page.state';

const _administrativeReducer = createReducer(
  administrativePageInitialState,

  on(setTheme, (state, payload) => ({
    ...state,
    idTheme: payload.idTheme,
  })),
  on(setImage, (state, payload) => ({
    ...state,
    logoHeader: payload.logoHeader,
    logoLogin: payload.logoLogin,
  })),
  on(getImageHeaderSuccess, (state, data) => ({
    ...state,
    logoHeader: data.data,
  })),
  on(getImageLoginSuccess, (state, data) => ({
    ...state,
    logoLogin: data.data,
  }))
);

export function administrativeReducer(state, action) {
  return _administrativeReducer(state, action);
}
