import { createReducer, on } from '@ngrx/store';
import { initialState } from '../state';
import { loadQuestionnairesArchived, setArchivedQuestionnaires } from './archive.state.actions';

export const _archiveQuestionnaireReducer = createReducer(
  initialState,
  on(loadQuestionnairesArchived, (state) => ({
    ...state,
    loading: false,
  })),
  on(setArchivedQuestionnaires, (state, { data }) => ({
    ...state,
    ArchivedQuestionnaires: data,
    loading: false,
  }))
);

export function archiveQuestionnaireReducer(state, action) {
  return _archiveQuestionnaireReducer(state, action);
}
