import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';

@Pipe({
  name: 'modelFilter',
})
export class ModelFilterPipe implements PipeTransform {
  transform(value: QuestionnairesStructure[], projectSelected?: string): QuestionnairesStructure[] {
    if (projectSelected) {
      return value.filter((quest) => quest['Questionnaire_Title'].toLowerCase() !== projectSelected.toLowerCase());
    } else {
      return value;
    }
  }
}
