import { Pipe, PipeTransform } from '@angular/core';
import { MagicStringsService } from '../magic-strings.service';

@Pipe({
  name: 'customazationString',
})
export class CustomazationStringPipe implements PipeTransform {
  constructor(private magicStringsService: MagicStringsService) {}

  transform(value: string): string {
    let result;
    if (value.toLowerCase().includes('topic')) {
      result = value.toLowerCase().replace('topic', this.magicStringsService.Topic);
    } else if (value.toLowerCase().includes('topics')) {
      result = value.toLowerCase().replace('topics', this.magicStringsService.Topic);
    } else if (value.toLowerCase().includes('modello')) {
      result = value.toLowerCase().replace('modello', this.magicStringsService.Modello);
    } else if (value.toLowerCase().includes('progetto')) {
      result = value.toLowerCase().replace('progetto', this.magicStringsService.Progetto);
    } else if (value.toLowerCase().includes('progetti')) {
      result = value.toLowerCase().replace('progetti', this.magicStringsService.Progetti);
    } else if (value.toLowerCase().includes('modelli')) {
      result = value.toLowerCase().replace('modelli', this.magicStringsService.Modelli);
    } else if (value.toLowerCase().includes('responsabile')) {
      result = value.toLowerCase().replace('responsabile', this.magicStringsService.Responsabile);
    } else if (value.toLowerCase().includes('responsabili')) {
      result = value.toLowerCase().replace('responsabili', this.magicStringsService.Responsabili);
    } else if (value.toLowerCase().includes('questionario')) {
      result = value.toLowerCase().replace('questionario', this.magicStringsService.Questionario);
    } else if (value.toLowerCase().includes('questionari')) {
      result = value.toLowerCase().replace('questionari', this.magicStringsService.Questionari);
    } else if (value.toLowerCase().includes('questApp')) {
      result = value.toLowerCase().replace('questApp', this.magicStringsService.AppName);
    }
    return result;
  }
}
