import { Pipe, PipeTransform } from '@angular/core';
import { TopicsAnswered } from 'src/app/data/models/TopicsAnswered';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Pipe({
  name: 'hideCompleted',
})
export class TopicsCompletedPipePipe implements PipeTransform {
  userInSession: string;

  constructor(private auth: AuthService) {
    this.userInSession = this.auth.getUserName();
  }

  transform(
    topics: TopicsAnswered[],
    shouldHide:
      | 'Completed'
      | 'In Progress'
      | 'Draft'
      | 'No Filter'
      | 'NotCompleted'
      | 'Assigned_To_Me'
      | 'Assigned_To_Others'
  ): TopicsAnswered[] {
    if (shouldHide === 'No Filter') {
      return topics;
    } else if (shouldHide !== 'Completed') {
      if (shouldHide === 'In Progress') {
        return topics.filter((topic) => topic.Assigned_Status === 'In Progress');
      } else if (shouldHide === 'Draft') {
        return topics.filter((topic) => topic.Assigned_Status === 'Assigned');
      } else if (shouldHide === 'Assigned_To_Me') {
        return topics.filter((topic) => topic.Assigned_To === this.userInSession);
      } else if (shouldHide === 'Assigned_To_Others') {
        return topics.filter((topic) => topic.Assigned_To !== this.userInSession);
      } else {
        return topics.filter((topic) => topic.Assigned_Status !== 'Completed');
      }
    } else if (shouldHide === 'Completed') {
      return topics.filter((topic) => topic.Assigned_Status === 'Completed');
    }
  }
}
