import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/datepick/datepick.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { selectProjectFilterOptions } from 'src/app/Store/projects/selectors/projects.selectors';
import { take, takeUntil } from 'rxjs/operators';
import { selectModelsFilterOptions } from 'src/app/Store/questionnaire-store/state.selectors';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdvancedFilterComponent implements OnInit {
  defaultOptions = true;

  panelOpenState = false;
  showFilter = false;
  statusExpand = false;
  expiryExpand = false;
  modelExpand = false;
  creationExpand = false;
  userExpand = false;
  lastAssignmentExpand = false;
  lastChangeExpand = false;
  disableAnimation = true;

  //filter tags
  showTags = false;
  statusActiveTag = false;
  statusArchivedTag = false;
  statusClosedTag = false;
  statusDraftTag = false;
  creatorTag = false;
  expireFromTag = false;
  expireToTag = false;
  lastAssignmentFromTag = false;
  lastAssignmentToTag = false;
  lastChangeFromTag = false;
  lastChangeToTag = false;
  creationFromTag = false;
  creationToTag = false;
  modelTag = false;

  abilities: any;
  isAdmin = true;

  @Input()
  modelOrProject: string;

  @Output()
  projectSearch = new EventEmitter<{}>();

  @Output()
  modelSearch = new EventEmitter<{}>();

  @Output()
  projectReset = new EventEmitter<{}>();

  @Output()
  modelReset = new EventEmitter<{}>();

  @Output()
  updateProjectsFilters = new EventEmitter<{}>();

  @Output()
  updateModelsFilters = new EventEmitter<{}>();

  @Input()
  modelFilter = '';
  creatorFilter = '';
  @Input()
  searchTerm = '';
  expireFromFilter = '';
  expireToFilter = '';
  creationFromFilter = '';
  creationToFilter = '';
  lastAssignmentFromFilter = '';
  lastAssignmentToFilter = '';
  lastChangeFromFilter = '';
  lastChangeToFilter = '';
  saved = false;
  popUpMode = this.router.url === '/questionnaires-management' ? false : true;

  status: {
    draft: boolean;
    active: boolean;
    closed: boolean;
    archived: boolean;
    count: number;
  };

  createDateCount = 0;
  expireDateCount = 0;
  lastAssignmentDateCount = 0;
  lastChangeDateCount = 0;

  @Input()
  lang: string;
  private ngUnsubscribe = new Subject();

  constructor(public authService: AuthService, private store: Store, private router: Router) {}

  ngOnInit(): void {
    if (this.popUpMode) {
      this.status = {
        draft: false,
        active: true,
        closed: false,
        archived: false,
        count: 1,
      };
    } else {
      this.status = {
        draft: true,
        active: true,
        closed: true,
        archived: false,
        count: 2,
      };
    }

    if (this.modelOrProject === 'project') {
      this.abilities = this.authService.getUserAbilities();
      this.status.count = 3;
      if (this.abilities.length < 6) {
        this.isAdmin = false;
      }
    }

    if (this.modelOrProject === 'project') {
      this.store
        .select(selectProjectFilterOptions)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((options) => {
          if (options.saved) {
            this.saved = true;
            this.modelFilter = options.modelFilter;
            this.creatorFilter = options.creatorFilter;
            this.searchTerm = options.searchTerm;
            this.expireFromFilter = options.expireFromFilter;
            this.expireToFilter = options.expireToFilter;
            this.creationFromFilter = options.creationFromFilter;
            this.creationToFilter = options.creationToFilter;
            this.status = { ...options.status, count: 3 };

            //load counters
            this.status.count = Object.values(options.status).filter((el) => el).length;
            this.expireDateCount = (this.expireFromFilter ? 1 : 0) + (this.expireToFilter ? 1 : 0);
            this.createDateCount = (this.creationFromFilter ? 1 : 0) + (this.creationToFilter ? 1 : 0);

            //update project component
            this.updateProjectsFilters.emit(options);
          }
        });
    } else {
      this.store
        .select(selectModelsFilterOptions)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((options) => {
          if (options.saved && !this.popUpMode) {
            this.saved = true;
            this.searchTerm = options.searchTerm;
            this.creatorFilter = options.creatorFilter;
            this.status.active = options.status.active;
            this.status.draft = options.status.draft;
            this.status.archived = options.status.archived;
            this.lastAssignmentFromFilter = options.lastAssignmentFromFilter;
            this.lastAssignmentToFilter = options.lastAssignmentToFilter;
            this.lastChangeFromFilter = options.lastChangeFromFilter;
            this.lastChangeToFilter = options.lastChangeToFilter;
            this.creationFromFilter = options.creationFromFilter;
            this.creationToFilter = options.creationToFilter;

            //load counters
            this.status.count = Object.values(options.status).filter((el) => el).length;
            this.lastAssignmentDateCount =
              (this.lastAssignmentFromFilter ? 1 : 0) + (this.lastAssignmentToFilter ? 1 : 0);
            this.lastChangeDateCount = (this.lastChangeFromFilter ? 1 : 0) + (this.lastChangeToFilter ? 1 : 0);
            this.createDateCount = (this.creationFromFilter ? 1 : 0) + (this.creationToFilter ? 1 : 0);

            // update models component
            this.updateModelsFilters.emit(options);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  searchDispatch() {
    if (this.modelOrProject === 'project') {
      this.projectSearch.emit({
        saved: this.saved,
        searchTerm: this.searchTerm,
        modelFilter: this.modelFilter,
        creatorFilter: this.creatorFilter,
        status: {
          draft: this.status.draft,
          active: this.status.active,
          closed: this.status.closed,
          archived: this.status.archived,
        },
        expireFromFilter: this.expireFromFilter,
        expireToFilter: this.expireToFilter,
        creationFromFilter: this.creationFromFilter,
        creationToFilter: this.creationToFilter,
      });
    } else {
      this.modelSearch.emit({
        saved: true,
        searchTerm: this.searchTerm,
        modelFilter: this.modelFilter,
        creatorFilter: this.creatorFilter,
        status: {
          draft: this.status.draft,
          active: this.status.active,
          closed: this.status.closed,
          archived: this.status.archived,
        },
        creationFromFilter: this.creationFromFilter,
        creationToFilter: this.creationToFilter,
        lastAssignmentFromFilter: this.lastAssignmentFromFilter,
        lastAssignmentToFilter: this.lastAssignmentToFilter,
        lastChangeFromFilter: this.lastChangeFromFilter,
        lastChangeToFilter: this.lastChangeToFilter,
      });
    }
    this.closeAllExpands();
    this.toggleFilter(false);
    this.applyTag();
    this.toggleDefaultOptions();
  }

  clearAll() {
    if (this.modelOrProject === 'project') {
      this.projectReset.emit();
    } else {
      this.modelReset.emit();
    }

    this.resetFilters();
    this.closeAllExpands();
    this.toggleFilter(false);
  }

  toggleFilter(show) {
    this.showFilter = show;
  }

  closeAllExpands() {
    this.statusExpand = false;
    this.expiryExpand = false;
    this.modelExpand = false;
    this.creationExpand = false;
    this.userExpand = false;
    this.lastAssignmentExpand = false;
    this.lastChangeExpand = false;
  }

  resetFilters() {
    this.defaultOptions = true;

    this.modelFilter = '';
    this.creatorFilter = '';
    this.searchTerm = '';
    this.expireFromFilter = '';
    this.expireToFilter = '';
    this.creationFromFilter = '';
    this.creationToFilter = '';
    this.lastAssignmentFromFilter = '';
    this.lastAssignmentToFilter = '';
    this.lastChangeFromFilter = '';
    this.lastChangeToFilter = '';
    this.status = {
      draft: this.popUpMode ? false : true,
      active: true,
      closed: this.popUpMode ? false : true,
      archived: false,
      count: this.modelOrProject === 'project' ? 3 : 2,
    };
    this.createDateCount = 0;
    this.expireDateCount = 0;
    this.lastAssignmentDateCount = 0;
    this.lastChangeDateCount = 0;
    //filter tags
    this.statusActiveTag = true;
    this.statusArchivedTag = false;
    this.statusClosedTag = true;
    this.statusDraftTag = this.popUpMode ? false : true;
    this.creatorTag = false;
    this.expireFromTag = false;
    this.expireToTag = false;
    this.lastAssignmentFromTag = false;
    this.lastAssignmentToTag = false;
    this.lastChangeFromTag = false;
    this.lastChangeToTag = false;
    this.creationFromTag = false;
    this.creationToTag = false;
    this.modelTag = false;
  }

  statusChecked(event, tag) {
    this.status[event.source.id] = event.checked;
    if (event.checked) {
      this.status.count++;
    } else {
      this[tag] = false;
      this.status.count--;
    }
  }

  createDateChecked(event, filter) {
    if (event !== '') {
      if (this[filter] === '') {
        this.createDateCount++;
      }
    } else {
      this.createDateCount--;
    }
    // this[filter] = event['_i']['year'] + '-' + event['_i']['month'] + '-' + event['_i']['date'];
  }

  expireDateChecked(event, filter) {
    if (event !== '') {
      if (this[filter] === '') {
        this.expireDateCount++;
      }
    } else {
      this.expireDateCount--;
    }
    // this[filter] = event['_i']['year'] + '-' + event['_i']['month'] + '-' + event['_i']['date'];
  }

  lastAssignmentDateChecked(event, filter) {
    if (event !== '') {
      if (this[filter] === '') {
        this.lastAssignmentDateCount++;
      }
    } else {
      this.lastAssignmentDateCount--;
    }
    // this[filter] = event['_i']['year'] + '-' + event['_i']['month'] + '-' + event['_i']['date'];
  }

  lastChangeDateChecked(event, filter) {
    if (event !== '') {
      if (this[filter] === '') {
        this.lastChangeDateCount++;
      }
    } else {
      this.lastChangeDateCount--;
    }
    // this[filter] = event['_i']['year'] + '-' + event['_i']['month'] + '-' + event['_i']['date'];
  }

  applyTag() {
    if (this.status.active === true) {
      this.statusActiveTag = true;
    }
    if (this.status.archived === true) {
      this.statusArchivedTag = true;
    }
    if (this.status.closed === true) {
      this.statusClosedTag = true;
    }
    if (this.status.draft === true) {
      this.statusDraftTag = true;
    }
    if (this.creatorFilter !== '') {
      this.creatorTag = true;
    }
    if (this.expireFromFilter !== '') {
      this.expireFromTag = true;
    }
    if (this.expireToFilter !== '') {
      this.expireToTag = true;
    }
    if (this.lastAssignmentFromFilter !== '') {
      this.lastAssignmentFromTag = true;
    }
    if (this.lastAssignmentToFilter !== '') {
      this.lastAssignmentToTag = true;
    }
    if (this.lastChangeFromFilter !== '') {
      this.lastChangeFromTag = true;
    }
    if (this.lastChangeToFilter !== '') {
      this.lastChangeToTag = true;
    }
    if (this.creationFromFilter !== '') {
      this.creationFromTag = true;
    }
    if (this.creationToFilter !== '') {
      this.creationToTag = true;
    }
    if (this.modelFilter !== '') {
      this.modelTag = true;
    }
  }

  removeTag(filter, tag?, count?) {
    this.status[filter] = false;
    this.status[count]--;
    this[filter] = '';
    this[count]--;
    this[tag] = false;
    this.searchDispatch();
  }

  filterDateChange(newValue: MatDatepickerInputEvent<String>, filter) {
    this[filter] = formatDate(`${newValue}`, 'yyyy-MM-dd', 'en-US');
  }

  toggleDefaultOptions() {
    if (
      !(this.status.active && this.status.draft && this.status.closed) ||
      this.status.archived ||
      !!this.lastAssignmentFromFilter ||
      this.lastAssignmentToFilter ||
      !!this.lastChangeFromFilter ||
      !!this.lastChangeToFilter ||
      !!this.creationFromFilter ||
      !!this.creationToFilter ||
      !!this.creatorFilter ||
      !!this.expireFromFilter ||
      !!this.expireToFilter ||
      !!this.modelFilter
    ) {
      this.defaultOptions = false;
    } else {
      this.defaultOptions = true;
    }
  }
}
