import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

export const toastAnimation: AnimationTriggerMetadata = trigger('toast', [
  state(
    'show',
    style({
      display: 'inherit',
    })
  ),
  state(
    'hide',
    style({
      display: 'none',
    })
  ),
  transition('hide => show', animate('1s ease')),
  transition('show => hide', animate('1s ease')),
]);
