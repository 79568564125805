import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToItalian',
})
export class ConvertToItalianPipe implements PipeTransform {
  transform(value: string, optional?: string) {
    if (!value) {
      return;
    } else {
      if (optional === 'it') {
        switch (value) {
          case 'Completed':
            return 'Completato';
          case 'Archived':
            return 'Archiviato';
          case 'Draft':
            return 'Draft';
          case 'Deleted':
            return 'Eliminato';
          case 'Closed':
            return 'Chiuso';
          case 'Active':
            return 'Attivo';
          case 'Assigned':
            return 'Assegnato';
          case 'In progress':
            return 'In Corso';

          default:
            return value;
        }
      } else {
        return value;
      }
    }
  }
}
