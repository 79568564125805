import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Password } from 'src/app/data/models/request-apis/Password';
import { UsersStoreService } from 'src/app/Store/store-services/users-store.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Output()
  changePasswordInfo = new EventEmitter<Password>();

  passwordForm: FormGroup;

  hide = true;
  hideConf = true;
  hideOld = true;
  samePass=false;

  get primPassword() {
    return this.passwordForm.get('new_password');
  }

  get repeatPassword() {
    return this.passwordForm.get('repeatPassword');
  }

  constructor(
    public changePasswordDialog: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private userStore: UsersStoreService
  ) {}

  ngOnInit(): void {
    this.passwordForm = new FormGroup({
      old_password: new FormControl(null, {
        validators: [Validators.required],
      }),
      new_password: new FormControl(null, {
        validators: [Validators.required],
      }),
      repeatPassword: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  changePassword() {}

  setHide(event) {
    event.preventDefault();
    this.hide = !this.hide;
  }

  setHideConf(event) {
    event.preventDefault();
    this.hideConf = !this.hideConf;
  }
  setHideOld(event) {
    event.preventDefault();
    this.hideOld = !this.hideOld;
  }

  cancel() {
    this.changePasswordDialog.close();
  }

  updatePassword() {
    this.userStore.updatePassword({
      username: this.data.username,
      oldPassword: this.passwordForm.get('old_password').value,
      newPassword: this.passwordForm.get('new_password').value,
    });
  }
  checkPassValues(){
    let password = this.passwordForm.get('new_password').value
    let repeatpass = this.passwordForm.get('repeatPassword').value
  
    if(password===repeatpass){
      this.samePass=true
      this.passwordForm.get('new_password').setErrors({'differentPass': null})
      this.passwordForm.get('repeatPassword').setErrors({'differentPass': null})
      this.passwordForm.get('new_password').updateValueAndValidity();
      this.passwordForm.get('repeatPassword').updateValueAndValidity();
      this.passwordForm.markAllAsTouched();
    }else{
      this.samePass=false
      this.passwordForm.get('new_password').setErrors({'differentPass': true});
      this.passwordForm.get('repeatPassword').setErrors({'differentPass': true});
      this.passwordForm.markAllAsTouched();
    }
  }
}
