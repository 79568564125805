import { createAction, props } from '@ngrx/store';
import { AllGroupClient, ClientOrGroup } from 'src/app/data/models/ClientGroup';
import { Password } from 'src/app/data/models/request-apis/Password';
import { UserSend } from 'src/app/data/models/request-apis/User-request';
import { NewUser, User } from 'src/app/data/models/User';

export const enum UserActions {
  changeLanguage = '[Language]change Language',

  getAllUsersAction = '[Users] get usersActions',
  getUserByUsername = '[Users] get getUserByUsername',
  getUserByUsernameSuccess = '[Users] get getUserByUsernameSuccess',
  getUsersPaginated = '[Users] get usersActions paginated',
  getUsersPaginatedSuccess = '[Users] get usersActions paginated success',
  getUsersPaginatedForOwner = '[Users] get usersActions paginated for changing Owner of Project',
  getUsersPaginatedForOwnerSuccess = '[Users] success on get usersActions paginated for changing Owner of Project',
  setAllUsersAction = '[Users] set users',
  loadUsersFailure = '[Users] users failure',
  createUser = '[Users] create user',
  createUserSuccess = '[Users] create user success',
  createNewUser = '[Users] create new user',
  createNewUserSuccess = '[Users] create new user success',
  updateUser = '[Users] update user',
  updateUserSuccess = '[Users] update user success',
  updatePassword = '[Users] update password',
  updatePasswordSuccess = '[Users] update password success',
  deleteUser = '[Users] delete user',
  deleteUserSuccess = '[Users] delete user success',
  disableUser = '[Users] disable user',
  disableUserSuccess = '[Users] disable user success',
  reactivateUser = '[Users] reactivate user',
  reactivateUserSuccess = '[Users] reactivate user success',
  searchUsers = '[Users] search user by name or surname',
  searchUsersSuccess = '[Users] search user by name or surname success',
  searchClientGroup = '[Users] search client and group',
  searchClientGroupSuccess = '[Users] search client and group success',
  searchAllClientGroup = '[Users] search all client and group',
  searchAllClientGroupSuccess = '[Users] search all client and group success',
  searchFilterClientGroup = '[Users] search filter term client and group',
  searchFilterClientGroupModel = '[Users] search filter term client group model visibility',
  reassignClientGroup = '[Users] reassign client and group',
  reassignClientGroupSuccess = '[Users] reassign client and group success',
  importUsersFrom = '[Users] import  user from excel',
  importUsersFromSuccess = '[Users] import  user from excel success',
  emptyImportUsers = '[Users] emptyImportUsers import  user',
}

export const changeLanguage = createAction(UserActions.changeLanguage, props<{ language: string }>());

export const getUsers = createAction(UserActions.getAllUsersAction);

export const getUserByUsername = createAction(UserActions.getUserByUsername, props<{ username: string }>());

export const getUserByUsernameSuccess = createAction(
  UserActions.getUserByUsernameSuccess,
  props<{ language: string }>()
);

export const searchUsers = createAction(
  UserActions.searchUsers,
  props<{
    pageSize: number;
    pageIndex: number;
    searchTerm: string;
    clientFilter: string;
    groupFilteer: string;
    emailFilter: string;
    statusActive: boolean;
    statusDisabled: boolean;
  }>()
);

export const searchUsersSuccess = createAction(
  UserActions.searchUsersSuccess,
  props<{ users: User[]; pageIndex: number; total: number }>()
);

export const searchFilterClientGroup = createAction(
  UserActions.searchFilterClientGroup,
  props<{ searchTerm: string }>()
);
export const searchFilterClientGroupModel = createAction(
  UserActions.searchFilterClientGroupModel,
  props<{ searchTerm: string }>()
);

export const searchClientGroup = createAction(
  UserActions.searchClientGroup,
  props<{
    pageSize: number;
    pageIndex: number;
    searchTerm: string;
    sortCol: string;
    sortOrder: string;
    field: 'Client' | 'Group';
    client_id: string;
  }>()
);

export const searchClientGroupSuccess = createAction(
  UserActions.searchClientGroupSuccess,
  props<{ clientGroup: ClientOrGroup[]; pageIndex: number; total: number; field: 'Client' | 'Group' }>()
);
export const searchAllClientGroup = createAction(UserActions.searchAllClientGroup, props<{ admin?: string }>());

export const searchAllClientGroupSuccess = createAction(
  UserActions.searchAllClientGroupSuccess,
  props<{ data: AllGroupClient[] }>()
);

export const reassignClientGroup = createAction(
  UserActions.reassignClientGroup,
  props<{
    username: string[];
    client_id: string;
    group_id: string;
    pageSize: number;
    pageIndex: number;
    searchTerm: string;
    clientFilter: string;
    groupFilteer: string;
    emailFilter: string;
    statusActive: boolean;
    statusDisabled: boolean;
  }>()
);

export const reassignClientGroupSuccess = createAction(
  UserActions.reassignClientGroupSuccess,
  props<{
    pageSize: number;
    pageIndex: number;
    searchTerm: string;
    clientFilter: string;
    groupFilteer: string;
    emailFilter: string;
    statusActive: boolean;
    statusDisabled: boolean;
  }>()
);

export const getUsersPaginatedSuccess = createAction(UserActions.getUsersPaginatedSuccess, props<{ users: User[] }>());

export const getUsersPaginated = createAction(
  UserActions.getUsersPaginated,
  props<{
    pageIndex: number;
    pageSize: number;
    toBeExcluded?: User[];
    disabled?: boolean;
  }>()
);

export const getUsersPaginatedForOwner = createAction(
  UserActions.getUsersPaginatedForOwner,
  props<{ pageIndex: number; pageSize: number; toBeExcluded?: User[] }>()
);

export const getUsersPaginatedForOwnerSuccess = createAction(
  UserActions.getUsersPaginatedForOwnerSuccess,
  props<{ users: User[] }>()
);

export const setUsers = createAction(UserActions.setAllUsersAction, props<{ users: User[] }>());

export const loadUsersFailure = createAction(UserActions.loadUsersFailure, props<{ error: any }>());

export const createUser = createAction(UserActions.createUser, props<{ user: UserSend }>());

export const createUserSuccess = createAction(UserActions.createUserSuccess);

export const createNewUser = createAction(UserActions.createNewUser, props<{ user: NewUser }>());

export const createNewUserSuccess = createAction(UserActions.createNewUserSuccess);

export const updateUser = createAction(UserActions.updateUser, props<{ user: UserSend }>());

export const updateUserSuccess = createAction(UserActions.updateUserSuccess, props<{ user: User }>());

export const updatePassword = createAction(UserActions.updatePassword, props<{ password: Password }>());

export const updatePasswordSuccess = createAction(UserActions.updatePasswordSuccess);

export const deleteUser = createAction(
  UserActions.deleteUser,
  props<{
    username: string | string[];
  }>()
);

export const deleteUserSuccess = createAction(
  UserActions.deleteUserSuccess,
  props<{
    username: string | string[];
  }>()
);
export const disableUser = createAction(
  UserActions.disableUser,
  props<{
    username: string | string[];
  }>()
);

export const disableUserSuccess = createAction(
  UserActions.disableUserSuccess,
  props<{
    username: string | string[];
  }>()
);

export const reactivateUser = createAction(
  UserActions.reactivateUser,
  props<{
    user: UserSend;
    pageIndex: number;
    pageSize: number;
    disabled: boolean;
  }>()
);

export const reactivateUserSuccess = createAction(
  UserActions.reactivateUserSuccess,
  props<{
    user: UserSend;
    pageIndex: number;
    pageSize: number;
    disabled: boolean;
  }>()
);

export const importUsers = createAction(
  UserActions.importUsersFrom,
  props<{ users: [{ NAME: string; SURNAME: string; EMAIL: string }] }>()
);

export const importUsersFromSuccess = createAction(UserActions.importUsersFromSuccess);
export const emptyImportUsers = createAction(UserActions.emptyImportUsers);
