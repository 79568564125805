<div class="modal-container flex flex-col px-8 py-6">
  <div class="mb-12 flex flex-col">
    <span class="text-5xl font-semibold text-main">
      {{ "ANALYTICS_QUESTION_DETAILS.TITLE" | translate }}
    </span>
    <span class="text-2xl text-card">{{ data.title }}</span>
    <span *ngIf="data.description !== ''" class="text-card">
      {{ data.description }}
    </span>
    <span *ngIf="data.option" class="mt-6 text-5xl font-semibold text-main">
      {{ "ANALYTICS_QUESTION_DETAILS.ANSWER" | translate }}
    </span>
    <span *ngIf="data.option" class="text-2xl text-card">
      {{ data.option.answer }}
    </span>
  </div>

  <div class="mb-14 w-full">
    <mat-form-field class="search-input-rounded w-full rounded-full bg-white !text-[0.4rem]" appearance="outline">
      <mat-icon class="relative" matPrefix>search</mat-icon>
      <input
        class="!text-[1.5rem]"
        type="search"
        matInput
        placeholder="Search"
        [ngModel]="searchTerm"
        (ngModelChange)="search($event); pageIndex = 0"
        value=""
      />
    </mat-form-field>
  </div>

  <div
    class="flex w-[45vw] flex-col text-base text-black"
    matSort
    (matSortChange)="sortActiveColumn($event); pageIndex = 0"
    matSortDisableClear
    matSortActive="responsible"
    matSortDirection="asc"
  >
    <div
      class="mb-[1vh] grid grid-cols-2 px-8"
      [ngClass]="{
        '!grid-cols-3': data.questionType === 'Free text',
        'table-grid !grid-cols-4': data.questionType === 'File'
      }"
    >
      <div *ngIf="data.questionType === 'File'" class="checkBoxes flex items-center">
        <mat-checkbox (change)="checkAll($event.checked)" [checked]="filesIDs.length === data.details.length">
        </mat-checkbox>
      </div>
      <div mat-sort-header="responsible" class="responsibles flex items-center">
        <span>{{ "DICTIONARY.Responsabili" | translate }}</span>
      </div>
      <div
        mat-sort-header="answers"
        *ngIf="['File', 'Free text'].includes(data.questionType)"
        class="answers flex items-center"
      >
        <span>{{
          data.questionType === "Free text"
            ? ("ANALYTICS_QUESTION_DETAILS.ANSWER" | translate)
            : ("ANALYTICS_QUESTION_DETAILS.File" | translate)
        }}</span>
      </div>
      <div mat-sort-header="compiler" class="compiler flex items-center">
        <span>{{ "ANALYTICS_QUESTION_DETAILS.COMPILER" | translate }}</span>
      </div>
    </div>

    <div
      *ngFor="let user of details; index as i"
      class="mb-[1vh] grid h-[fit-content] grid-cols-2 !border-none bg-white px-8 py-4 text-base text-tableText"
      [ngClass]="{
        '!mb-0': i / 9 === 1,
        '!grid-cols-3': data.questionType === 'Free text',
        'table-grid !grid-cols-4': data.questionType === 'File'
      }"
    >
      <div *ngIf="data.questionType === 'File'" class="flex items-center">
        <mat-checkbox
          (change)="checkFile($event.checked, user.answers[0])"
          [checked]="filesIDs.includes(user.answers[0])"
        ></mat-checkbox>
      </div>

      <div class="flex items-center">
        <span>{{ user.responsible }}</span>
      </div>

      <div *ngIf="data.questionType === 'File'" class="flex items-center">
        <span>{{ getFileName(user.answers[0]) }}</span>
      </div>

      <div *ngIf="data.questionType === 'Free text'" class="flex items-center">
        <span>{{ user.answers[0] }}</span>
      </div>

      <div class="flex items-center">
        <span>{{ user.compiler }}</span>
      </div>
    </div>

    <div class="mb-2 flex items-center justify-end">
      <mat-paginator
        #paginator
        [length]="length"
        [pageSize]="pageSize"
        [hidePageSize]="true"
        [pageIndex]="pageIndex"
        (page)="getNext($event)"
      >
      </mat-paginator>
    </div>
  </div>

  <div class="flex w-full justify-end">
    <button class="whiteBtn" (click)="closeModal()">
      {{ "PROJECT_OPTIONS.CLOSE_BUTTON" | translate }}
    </button>
    <button
      [disabled]="filesIDs.length === 0 || (selectDownloading | async)"
      *ngIf="data.questionType === 'File'"
      class="ml-4 flex items-center justify-center colorBtn disabled:colorBtn-disabled"
      (click)="downloadFile()"
    >
      <span>{{ "ANALYTICS_PANEL.DOWNLOAD" | translate }}</span>
      <app-spinner
        *ngIf="selectDownloading | async"
        [width]="20"
        [height]="20"
        [color]="'white'"
        class="ml-4"
      ></app-spinner>
    </button>
  </div>
</div>
