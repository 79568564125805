<div class="h-full w-full">
  <div
    class="grid h-full grid-cols-[1fr,9fr,4fr,1fr] gap-x-6 gap-y-7 p-10 md:grid-rows-[20%,60%,12%] 2xl:grid-rows-[15%,72%,8%]"
    *ngIf="topics$ | async as topics"
  >
    <!-- TOGGLE-PANEL-AREA -->
    <div id="toggle-panel-area" class="row-span-full row-start-2">
      <mat-button-toggle-group
        #togglePanel="matButtonToggleGroup"
        class="!flex !flex-col items-center justify-center gap-8 border-none"
        value="edit"
      >
        <mat-button-toggle
          value="edit"
          id="edit-button"
          class="justidy-center flex h-24 w-24 items-center rounded-md !border-none bg-transparent text-card"
        >
          <mat-icon class="!text-7xl" [ngClass]="{ 'text-white': togglePanel.value === 'edit' }">edit</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="admin"
          id="admin-button"
          class="justidy-center flex h-24 w-24 items-center rounded-md !border-none bg-transparent text-card"
        >
          <mat-icon class="!text-7xl" [ngClass]="{ 'text-white': togglePanel.value === 'admin' }"
            >admin_panel_settings</mat-icon
          >
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- TITLE-AREA -->
    <div id="title-area" class="col-span-2 col-start-2">
      <app-title-of-questionnaire [inputForm]="formvalue"></app-title-of-questionnaire>
    </div>

    <!-- EDIT-MODEL-AREA -->
    <div id="edit-model-area" class="row-start-2" *ngIf="togglePanel.value === 'edit'">
      <app-new-question
        (newTopicChanged)="setTopicInside($event)"
        [currentTopic]="topics[index]"
        [topicsWithQuestions]="AllTopicsStoredWithQuestions | async"
        [ReadOnlyMode]="ReadOnlyMode"
        [Model_Status]="!!formBody ? this.formBody.Status : null"
      >
      </app-new-question>
    </div>

    <!-- ADMIN-AREA -->
    <div id="admin-area" class="row-start-2" *ngIf="togglePanel.value === 'admin'">
      <app-admin-panel></app-admin-panel>
    </div>

    <div class="row-start-2 rounded-3xl bg-white shadow-md" *ngIf="togglePanel.value === 'admin'">
      <div class="flex h-full items-center justify-center">
        <button (click)="togglePanel.value = 'edit'">
          <span class="text-2xl font-semibold text-card">Please go back to edit relevant info</span>
        </button>
      </div>
    </div>

    <!-- EDIT-TOPIC-AREA -->
    <div id="edit-topic-area" class="row-start-2" *ngIf="togglePanel.value === 'edit'">
      <app-new-topic
        [topics]="topics"
        [TitlesOfTopicsAlreadyStored]="topicTitlesInStore | async"
        [ReadOnlyMode]="ReadOnlyMode"
        (newTopicEvent)="storeTopic($event)"
        (modifyTopicEvent)="modifyTopic($event)"
        (deleteTopicEvent)="deleteTopic($event)"
        (topicData)="showTopicData($event)"
        [Model_Status]="!!formBody ? this.formBody.Status : null"
      ></app-new-topic>
    </div>

    <!-- BUTTONS-AREA -->
    <div id="buttons-area" class="col-span-2 row-start-3">
      <div class="flex justify-end gap-4 rounded-3xl bg-whiteBg p-5 shadow-md">
        <button class="whiteBtn" (click)="cancelModel()">
          {{ formBody?.Status !== "Archived" ? ("ANNULLA" | translate) : ("BACK" | translate) }}
        </button>
        <button
          class="whiteBtn disabled:whiteBtn-disabled"
          (click)="saveModel('Draft')"
          *ngIf="ReadOnlyMode === false && formBody?.Status !== 'Completed' && formBody?.Status !== 'Archived'"
          [disabled]="!formChange && !titleChange && formBody?.Status !== 'Import'"
        >
          {{ "QUEST_CREATION.SAVE_IN_DRAFT" | translate }}
        </button>
        <button
          *ngIf="formBody?.Status === 'Completed'"
          class="colorBtn disabled:colorBtn-disabled"
          (click)="saveModel('update')"
          [disabled]="ReadOnlyMode || (!formChange && !titleChange)"
        >
          {{ "QUEST_CREATION.UPDATE" | translate }}
          {{ "DICTIONARY.Modello" | translate | uppercase }}
        </button>
        <button
          *ngIf="formBody?.Status !== 'Completed' && formBody?.Status !== 'Archived'"
          class="colorBtn disabled:colorBtn-disabled"
          (click)="saveModel('finalize')"
          [disabled]="!formChange && !titleChange && formBody?.Status !== 'Draft' && formBody?.Status !== 'Import'"
        >
          {{ "QUEST_CREATION.PUBLISH" | translate }}
          {{ "DICTIONARY.Modello" | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
