import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verifyOptions',
})
export class VerifyOptionsPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'URL (https://xxx/xxxx)' || value === 'Email (xxxxx@xxxxx)' || value === 'Personalizzata') {
      return null;
    } else {
      return value;
    }
  }
}
