import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { animate, style, transition, trigger } from '@angular/animations';
import { DocufileUplaodService } from '../../administrative/service/docufileUpload.service';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromFileUploadSelectors from 'src/app/Store/upload-file-store/upload-file.selectors';
import * as fromFileUploadActions from 'src/app/Store/upload-file-store/upload-file.actions';
import { TopicsAnswered } from 'src/app/data/models/TopicsAnswered';
import { map } from 'rxjs/operators';
import { Docufile } from 'src/app/data/models/Docufile';
import { FILE_EXT_LIST } from './file-extensions.config';

@Component({
  selector: 'app-file-question',
  templateUrl: './file-question.component.html',
  styleUrls: ['./file-question.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FileQuestionComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  lang: string;
  @Input()
  activeTopic: TopicsAnswered;
  @Input()
  fileForm: FormControl;

  showPopup = false;
  currentFile: File;
  fileId: string;
  fileSize: string;
  uploadedFilename: string;
  iconPath: string;

  showDescription = false;
  plainText = '';

  storedFile$: Observable<Docufile[]>;
  progress$: Observable<number | null>;
  isInProgress$: Observable<boolean>;
  isFileStored: boolean = false;

  ALLOWED_FILE_EXT = FILE_EXT_LIST;

  constructor(
    private clipboardc: Clipboard,
    private _snackBar: MatSnackBar,
    private _docuFileService: DocufileUplaodService,
    public translate: TranslateService,
    private store$: Store
  ) {
    super(clipboardc);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.plainText = this.extractContent(this.question.Description).replace(/  /gi, ' ');

    this.getFiles();

    this.progress$ = this.store$.select(fromFileUploadSelectors.selectUploadFileProgress);

    this.isInProgress$ = this.store$.select(fromFileUploadSelectors.selectUploadFileInProgress);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 1500 });
  }

  toggleModelVisibility() {
    this.showPopup = !this.showPopup;
  }

  toggleDescriptionVisibility() {
    this.showDescription = !this.showDescription;
  }

  extractContent(stringToConvert) {
    var span = document.createElement('span');
    span.innerHTML = stringToConvert;
    return span.textContent || span.innerText;
  }

  dropFile(event: any, kind: string): void {
    const file: File = kind === 'dropped' ? event[0].file : event.target.files.item(0);
    const fileExt = file.name.split('.').pop().toLowerCase();

    let fileInfo = this.ALLOWED_FILE_EXT.filter((row) => {
      if (row.fileType === this.question.File_Type.toLowerCase()) {
        return true;
      }
    });

    if (fileInfo.length > 0) {
      fileInfo[0].ext.some((ext) => {
        if (this.question.File_Type.toLowerCase() === 'any' || ext.split('.').pop() === fileExt) {
          this.isFileStored = false;
          this.currentFile = file;
          this.uploadedFilename = file.name;
          this.sendFiles();
        }
      });

      let noMatches: boolean;
      noMatches = fileInfo[0].ext.every((ext) => {
        if (this.question.File_Type.toLowerCase() !== 'any' && ext.split('.').pop() !== fileExt) {
          return true;
        }
      });
      if (noMatches) {
        let toastString: string =
          this.lang === 'it' ? 'Il file selezionato non è supportato' : 'Selected file format is not supported';
        this._snackBar.open(toastString, this.translate.instant('QUESTION_TYPES_FORM.CLOSE'), {
          duration: 2000,
        });
      }
    }
  }

  private getFiles() {
    this.fileId = this.question.Answers[0].Value_Answer;
    let answerValue = this.fileId;

    if (answerValue) {
      this.uploadedFilename = this.question.Answers[0].Value_Answer.substring(37);
      this.isFileStored = true;
    }

    this.storedFile$ = this.store$.select(fromFileUploadSelectors.selectSingleFile(this.question.id));
  }

  private sendFiles(): void {
    const myId = uuid();
    this.fileId = `${myId}_${this.uploadedFilename}`;
    this.fileForm.setValue({ Input: this.fileId });
    let creationDate = new Date(this.currentFile.lastModified).toISOString();

    let sendFile = {
      id: this.fileId,
      file: this.currentFile,
      creationDate: creationDate,
      topicName: this.activeTopic.Title,
      questionName: this.question.Question_Text,
      questionId: this.question.id,
      comment: this.question.Answers[0].Comment,
    };

    this._docuFileService.sendCurrentFile(sendFile);
  }

  downloadFile(fileId: string) {
    if (fileId) {
      this.store$.dispatch(fromFileUploadActions.DownloadFileAction({ fileId }));
    }
  }

  deleteFile(id: string) {
    let sendFile = {
      id: id,
      questionId: this.question.id,
    };
    this._docuFileService.sendDeletedFile(sendFile);
    this.fileForm.setValue({ Input: null });
    if (this.uploadedFilename === this.question.Answers[0].Value_Answer.substring(37)) {
      this.uploadedFilename = null;
      this.isFileStored = false;
    }

    this.storedFile$ = this.storedFile$.pipe(
      map((files) => {
        return files && files.length && files.filter((file) => file.id !== id);
      })
    );
  }

  applyIcons(filename: string) {
    let fileExt = filename.split('.').pop().toLowerCase();
    let selectedExt = this.ALLOWED_FILE_EXT.filter((row) => {
      for (let i = 0; i < row.ext.length; i++) {
        if (row.ext[i].split('.').pop() === fileExt) {
          return true;
        }
      }
    });
    if (selectedExt.length > 0) {
      let icon = selectedExt[0].iconPath;
      return icon;
    } else {
      return 'assets/images/icons/other_file.svg';
    }
  }
}
