import { LoginWithSSo, logOut } from './../login-store/login.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { login } from 'src/app/Store/login-store/login.actions';
import { forgotPassword as forgotAction } from './../../Store/login-store/login.actions';

@Injectable({
  providedIn: 'root',
})
export class LoginStoreService {
  constructor(private store: Store) {}

  // loggedIn() {
  //   this.store.dispatch(loggedIn())
  // }

  login({ username, password }) {
    this.store.dispatch(login({ username, password }));
  }

  loginSSO({ username, token }) {
    this.store.dispatch(LoginWithSSo({ username, token }));
  }

  forgotPassword(email) {
    this.store.dispatch(forgotAction({ email }));
  }

  logOut() {
    this.store.dispatch(logOut());
  }
}
