import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-yes-no-question',
  templateUrl: './yes-no-question.component.html',
  styleUrls: ['./yes-no-question.component.scss'],
})
export class YesNoQuestionComponent extends BaseComponent implements OnInit, OnDestroy {
  answer: string = null;
  @Input()
  lang: string;
  @Input()
  yesNoForm: FormControl;

  showDescription = false;
  plainText = '';
  conditions = {};

  constructor(private clipboardc: Clipboard, private _snackBar: MatSnackBar, public translate: TranslateService) {
    super(clipboardc);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.plainText = this.extractContent(this.question.Description).replace(/  /gi, ' ');    
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 1500 });
  }

  toggleDescriptionVisibility() {
    this.showDescription = !this.showDescription;
  }

  extractContent(stringToConvert) {
    var span = document.createElement('span');
    span.innerHTML = stringToConvert;
    return span.textContent || span.innerText;
  }

  changeAnswer(answer) {
    // I use formControl only as Value of radio Group
    this.yesNoForm.setValue({ Input: answer.id });
  }
}
