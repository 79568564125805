<div class="clone h-[50vh] min-h-[38rem] w-[40vw] min-w-[40rem]" (click)="$event.stopPropagation()">
  <p class="font- black justify-center text-3xl text-dialog">
    {{ "PROJECT_TABLE.POP_UP_CLONE_TITLE" | translate }}
  </p>
  <div class="flex h-[80%] flex-col justify-around">
    <form [formGroup]="ClonedProjectInfo">
      <div class="flex flex-col justify-between pb-8">
        <mat-form-field>
          <mat-label class="mb-5 text-lg"
            >{{ "PROJECT_TABLE.POP_UP_CLONE_PLACE11" | translate }}
            {{ "DICTIONARY.Progetto" | translate | titlecase }}
            {{ "PROJECT_TABLE.POP_UP_CLONE_PLACE12" | translate }}</mat-label
          >
          <input
            type="text"
            [disabled]="loading | async"
            name="ClonedTitle"
            formControlName="ClonedTitle"
            matInput
            class="input-clone"
            placeholder="{{ 'PROJECT_TABLE.POP_UP_CLONE_PLACE11' | translate }} {{
              'DICTIONARY.Progetto' | translate | titlecase
            }} {{ 'PROJECT_TABLE.POP_UP_CLONE_PLACE12' | translate }}"
            #ProjectTitle
            maxlength="100"
          />
          <mat-hint>{{ ProjectTitle.value.length }}/100</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="mb-5 text-lg">{{ "PROJECT_TABLE.POP_UP_CLONE_PLACE2" | translate }}</mat-label>
          <input
            [disabled]="loading | async"
            type="text"
            name="ClonedDescription"
            formControlName="ClonedDescription"
            matInput
            class="input-clone"
            placeholder="{{ 'PROJECT_TABLE.POP_UP_CLONE_PLACE2' | translate }}"
            #ProjectDesc
            maxlength="500"
          />
          <mat-hint>{{ ProjectDesc.value.length }}/500</mat-hint>
        </mat-form-field>
      </div>
    </form>

    <div class="pt-4">
      <form [formGroup]="ClonedProjectInfo">
        <div formGroupName="Options" style="display: flex; flex-direction: column; justify-content: space-between">
          <mat-checkbox
            [disabled]="loading | async"
            name="IMPORT_ALL_OPTIONS"
            formControlName="IMPORT_ALL_OPTIONS"
            (change)="selectImportOption()"
            [checked]="checkImportOption"
            >{{ "PROJECT_TABLE.POP_UP_CLONE_CHECK_PROJECT1" | translate }}
            {{ "DICTIONARY.Progetto" | translate | titlecase }}
            {{ "PROJECT_TABLE.POP_UP_CLONE_CHECK_PROJECT2" | translate }}</mat-checkbox
          >
          <mat-checkbox
            [disabled]="loading | async"
            name="IMPORT_ALL_USERS"
            formControlName="IMPORT_ALL_USERS"
            *ngIf="data.totalUsers > 0"
            (change)="selectImportUserAssign()"
            [checked]="checkImportUser"
            >{{ "PROJECT_TABLE.POP_UP_CLONE_CHECK_RESPONSIBLE" | translate }}
            {{ "DICTIONARY.Responsabili" | translate | titlecase }}</mat-checkbox
          >
          <mat-checkbox
            [disabled]="loading | async"
            name="IMPORT_ALL_ANS"
            formControlName="IMPORT_ALL_ANS"
            *ngIf="data.totalUsers > 0"
            (change)="selectImportAns()"
            [checked]="checkImportAns"
            >{{ "PROJECT_TABLE.POP_UP_CLONE_CHECK_ANSWER" | translate }}</mat-checkbox
          >
          <mat-checkbox
            [disabled]="loading | async"
            name="IMPORT_WATCHLIST"
            formControlName="IMPORT_WATCHLIST"
            *ngIf="data.totalUsers > 0"
            (change)="selectImportWatchList()"
            [checked]="checkImportWatchList"
            >{{ "PROJECT_TABLE.POP_UP_CLONE_CHECK_WATCHLIST" | translate }}</mat-checkbox
          >
          <mat-checkbox
            [disabled]="loading | async"
            name="IMPORT_LAST_COMPILER"
            formControlName="IMPORT_LAST_COMPILER"
            *ngIf="data.totalUsers > 0"
            (change)="selectImportTopicAssign()"
            [checked]="checkImportTopicAssign"
            >{{ "PROJECT_TABLE.POP_UP_CLONE_CHECK_ASSIGNMENT1" | translate }}
            {{ "DICTIONARY.Topic" | translate | titlecase | articleDictionary: "plural":language }}
            {{ "PROJECT_TABLE.POP_UP_CLONE_CHECK_ASSIGNMENT2" | translate }}</mat-checkbox
          >
        </div>
      </form>
    </div>
  </div>
</div>

<div class="flex justify-end gap-7">
  <button [disabled]="loading | async" class="marginButtonsPop whiteBtn" (click)="cancel()">
    {{ "ANNULLA" | translate }}
  </button>
  <button [disabled]="loading | async" class="colorBtn" (click)="saveOptions()">
    {{ "CONFERMA" | translate }}
  </button>
  <app-spinner [height]="'20px'" [width]="'44'" *ngIf="loading | async"></app-spinner>
</div>
