import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as selectors from 'src/app/Store/questionnaire-store/state.selectors';
import { selectQuestionnairesStructured, selectTotalAndIndex } from 'src/app/Store/questionnaire-store/state.selectors';
import { selectAssingedLoading } from 'src/app/Store/assignedQuestionnaires/selectors/assigned-questionnaires.selectors';
import { Project } from 'src/app/data/models/Project';
import {
  getAllProjectsSelector,
  selectLoadingProjects,
  selectProjectFilterOptions,
  selectProjectsTotalAndIndex,
} from 'src/app/Store/projects/selectors/projects.selectors';
import { ProjectStoreService } from 'src/app/Store/store-services/project-store.service';
import { ProjectActionTypes } from 'src/app/Store/projects/actions/projects.actions';
import { take, takeUntil } from 'rxjs/operators';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { ActionTypes } from 'src/app/Store/questionnaire-store/state.actions';

@Component({
  selector: 'app-questionnaire-management',
  templateUrl: './management-questionnaire.component.html',
  styleUrls: ['./management-questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireManagementComponent implements OnInit, OnDestroy {
  totalAndPageIndex: Observable<{ total: number; pageIndex: number }>;
  totalQuestAssignedAndPageIndex: Observable<{
    total: number;
    pageIndex: number;
  }>;

  loading: Observable<boolean>;
  loadingAssigned: Observable<boolean>;
  loadingProjects: Observable<boolean>;

  models: Observable<QuestionnairesStructure[]>;
  projects: Observable<Project[]>;

  isProjectPage = false;
  modelsOrProjects = 0;
  lang = '';

  private ngUnsubscribe = new Subject();

  constructor(private store: Store, private projectService: ProjectStoreService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isProjectPage = this.projectService.getIsProjectPage();
    if (this.isProjectPage) {
      this.projectService.setIsProjectPage(false);
      this.modelsOrProjects = 1;
    } else {
      this.modelsOrProjects = 0;
    }

    this.models = this.store.select(selectQuestionnairesStructured);
    this.store
      .select(selectors.selectModelsFilterOptions)
      .pipe(take(1))
      .subscribe((options) => {
        this.store.dispatch({
          type: ActionTypes.loadQuestionnairesType,
          pageIndex: 0,
          title: 'Questionaire_Title',
          ascdesc: 'ASC',
          archived: options.status.archived,
          searchString: options.searchTerm,
          creatorFilter: options.creatorFilter,
          statusFilter: options.status,
          lastAssignmentFromFilter: options.lastAssignmentFromFilter,
          lastAssignmentToFilter: options.lastAssignmentToFilter,
          lastChangeFromFilter: options.lastChangeFromFilter,
          lastChangeToFilter: options.lastChangeToFilter,
          creationFromFilter: options.creationFromFilter,
          creationToFilter: options.creationToFilter,
        });
      });

    this.store
      .select(selectProjectFilterOptions)
      .pipe(take(1))
      .subscribe((options) => {
        this.store.dispatch({
          type: ProjectActionTypes.getAllProjects,
          archived: options.status.archived,
          searchString: options.searchTerm,
          modelFilter: options.modelFilter,
          creatorFilter: options.creatorFilter,
          statusFilter: options.status,
          expireFromFilter: options.expireFromFilter,
          expireToFilter: options.expireToFilter,
          creationFromFilter: options.creationFromFilter,
          creationToFilter: options.creationToFilter,
        });
      });

    this.projects = this.store.select(getAllProjectsSelector);

    this.store
      .select(selectLanguage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lang) => {
        this.lang = lang;
      });

    this.loading = this.store.select(selectors.selectLoading);
    this.loadingAssigned = this.store.select(selectAssingedLoading);
    this.totalAndPageIndex = this.store.select(selectTotalAndIndex);
    this.totalQuestAssignedAndPageIndex = this.store.select(selectProjectsTotalAndIndex);
    this.loadingProjects = this.store.select(selectLoadingProjects);
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  showProjectsSelected() {
    this.modelsOrProjects = 1;
  }

  showModelSelected() {
    this.modelsOrProjects = 0;
  }
}
