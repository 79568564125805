import { createAction, props } from '@ngrx/store';

export const enum HomePageActionTypes {
  loadQuestionnairesHomepage = '[Questionnaires HomePage] load assigned questionaires per user',
  loadQuestionnairesHomepageSuccess = '[Questionnaires HomePage] load succesfully assigned questionaires per user',
}

export const loadQuestionnairesHomepage = createAction(HomePageActionTypes.loadQuestionnairesHomepage);

export const loadQuestionnairesHomepageSuccess = createAction(
  HomePageActionTypes.loadQuestionnairesHomepageSuccess,
  props<{ total: number; pageIndex: number }>()
);
