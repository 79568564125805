<form class="assign-modal-container" [formGroup]="formGroup">
  <div>
    <mat-form-field floatLabel="never">
      <mat-label>Progetto</mat-label>
      <input type="text" matInput placeholder="" name="Project" formControlName="Project" readonly />
    </mat-form-field>
    <button mat-icon-button (click)="showProjectInfo('progetto')">
      <mat-icon> info </mat-icon>
    </button>
  </div>
  <div>
    <mat-checkbox name="Collaborative" formControlName="Collaborative" (change)="changeCollaborativeField($event)">
      Collaborativo</mat-checkbox
    >
  </div>

  <div layout="row" flex>
    <app-users-table [singleSelection]="data.singleSelection" (userToBeAssigned)="addUser($event)" [disabled]="false">
    </app-users-table>
  </div>

  <div>
    <app-datepick
      (date)="getTheDate($event)"
      [topicDate]="!!data.activeQuestionnaire ? data.activeQuestionnaire.Expiry_Date : ''"
    ></app-datepick>
  </div>

  <div>
    <button mat-button (click)="cancel()">Annulla</button>
    <button mat-button (click)="save()">Salva</button>
  </div>
</form>
