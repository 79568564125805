import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnalyticsData } from 'src/app/data/models/AnalyticsData';

@Component({
  selector: 'app-analytics-panel',
  templateUrl: './analytics-panel.component.html',
  styleUrls: ['./analytics-panel.component.scss'],
})
export class AnalyticsPanelComponent implements OnInit, OnChanges {
  @Input()
  analyticsData: AnalyticsData;
  @Input()
  projectID: string;

  showFilter = false;
  questionnaireData: {};
  topicData: {};
  questionData: {};

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.analyticsData) {      
      this.questionnaireData = [
        this.analyticsData.numberQuestionnaireAssigned,
        this.analyticsData.numberQuestionnaireInProgress,
        this.analyticsData.numberQuestionnaireCompleted,
      ];
      this.topicData = [
        this.analyticsData.numberTopicAssigned,
        this.analyticsData.numberTopicInProgress,
        this.analyticsData.numberTopicCompleted,
      ];
      this.questionData = [this.analyticsData.numberQuestionAssigned, 0, this.analyticsData.numberQuestionCompleted];
    }
  }

  ngOnInit(): void {}
}
