import { map, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  getQuestionnaireAssingedToUser,
  emptyQuestionnaireToBeAnswered,
} from 'src/app/Store/assignedQuestionnaires/actions/assigned-questionnaires.actions';
import {
  selectAssingedLoading,
  selectHomeQuestionnaires,
} from 'src/app/Store/assignedQuestionnaires/selectors/assigned-questionnaires.selectors';
import { selectUsersFullname } from 'src/app/Store/users/users.selectors';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { QuestionnairesAssignedToUser } from 'src/app/data/models/QuestionnairesAssignedToUser';
import { ABILITIES_HELPER } from 'src/app/data/models/abilities';
import { selectProjectLoading } from 'src/app/Store/projects/selectors/projects.selectors';
import { selectLanguage } from 'src/app/Store/users/users.selectors';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  abilities = Object.keys(ABILITIES_HELPER);
  objectKeys = Object.keys;

  loading: Observable<boolean>;
  projectLoading: Observable<boolean>;
  combinedLoadings: Observable<[boolean, boolean]>;

  usersFullName: Observable<{}>;
  currentUser;

  questionnaireToAnswer: Observable<{
    [id: string]: QuestionnairesAssignedToUser;
  }>;

  filter = null;
  searchByNameTerm = '';
  filterStartDate = '';
  filterEndDate = '';
  showPanel = false;

  lang: string;

  constructor(private store: Store, private authService: AuthService) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getUserName();
    this.getQuestionnaires('Questionnaire_Title');
    this.loading = this.store.select(selectAssingedLoading);
    this.projectLoading = this.store.select(selectProjectLoading).pipe(
      map((val) => {
        if (!val) {
          this.dispatchforQuestionnaires();
        }

        return val;
      })
    );
    this.combinedLoadings = combineLatest([this.loading, this.projectLoading]);

    this.usersFullName = this.store.select(selectUsersFullname);

    this.store
      .select(selectLanguage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lang) => {
        this.lang = lang;
      });
  }

  sort($event) {
    this.getQuestionnaires($event);
  }

  filterQuests(event) {
    this.filter = event;
  }

  searchByName(event: string) {
    this.searchByNameTerm = event;
  }

  private getQuestionnaires(sortTitle: string) {
    this.questionnaireToAnswer = this.store.select(selectHomeQuestionnaires, {
      user: this.currentUser,
      sort: sortTitle,
    });
  }

  private dispatchforQuestionnaires() {
    this.store.dispatch(
      getQuestionnaireAssingedToUser({
        id: this.authService.getUserName(),
        pageIndex: 0,
      })
    );
    this.store.dispatch(emptyQuestionnaireToBeAnswered());
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // getSSOCredentials() {
  //   try {
  //     this.activeRouter.queryParams.subscribe((params) => {
  //       if (this.checkIfUrlParamsAreValid(params)) {
  //         let user = decodeURIComponent(params['user']);
  //         let token = decodeURIComponent(params['token']);
  //         let abilities = decodeURIComponent(params['abilities'])
  //           .split(',')
  //           .map((ability) => {
  //             return { Ability: ability };
  //           });
  //         this.authService.samlSetUserInfo({
  //           user: user,
  //           token: token,
  //           abilities: abilities,
  //         });
  //         this.store.dispatch({ type: ActionLoginTypes.USERlOGGEDIN });
  //       }
  //     });
  //   } catch (error) {}
  // }
}
