<ng-container class="min-h-[70px]">
  <div id="container" class="min-w-[25vw] max-w-[30vw]">
    <mat-dialog-content>
      <div id="popup-content" class="flex flex-col gap-6">
        <div id="popup-text" class="flex flex-col gap-6">
          <span class="flex justify-between items-center text-dialog text-3xl font-bold tracking-normal">
            {{ lang === "it" ? dialogTitle : dialogTitleENG }}
          </span>
          <span class="inline-flex items-start gap-4">
            <mat-icon class="!text-dialog">error</mat-icon>
            <p class="text-xl">
              {{ lang === "it" ? dialogText : dialogTextENG }}<strong>{{ strongText }}</strong>
            </p>
          </span>
        </div>
        <div id="popup-buttons" class="flex justify-end gap-4 items-center">
          <button class="whiteBtn" (click)="delete('Cancel')" [mat-dialog-close]="true">
            {{ "ANNULLA" | translate }}
          </button>
          <button class="colorBtn" *ngIf="!showSaveButton" (click)="save()">
            <p *ngIf="!showThirdOption">{{ "CONTINUA" | translate }}</p>
            <p *ngIf="showThirdOption">{{ questAssegneeText }}</p>
          </button>
          <button class="colorBtn" *ngIf="showThirdOption" (click)="save('Update all')">
            {{ topicAssegneeText }}
          </button>
          <button class="colorBtn" *ngIf="showSaveButton" (click)="delete('Delete All')">
            {{ deleteAllText }}
          </button>
          <button *ngIf="showSaveButton" (click)="delete('Delete only Binding')">
            {{ deleteOnlyBindingText }}
          </button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</ng-container>
