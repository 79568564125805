import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestionAnswered } from 'src/app/data/models/QuestionAnswered';
import { Answers } from 'src/app/data/models/request-apis/Answers';
import { TopicsAnswered } from 'src/app/data/models/TopicsAnswered';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { IsConstraintPipe } from 'src/app/utils/pipes/is-constraint.pipe';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent implements OnInit, OnChanges {
  @Input()
  activeTopic: TopicsAnswered;

  @Input()
  currentUser: string;

  @Input()
  questionnaireId: string;

  @Input()
  lang: string;

  @Output()
  unsaved = new EventEmitter<boolean>();

  @Output()
  formFinalValue = new EventEmitter<Answers>();

  @Output()
  scrollTop = new EventEmitter<void>();

  @Output()
  formvalidityEvent = new EventEmitter<boolean>();

  @Output()
  unCompletedObligatoryQuestions = new EventEmitter<number[]>();

  @Output()
  showCliboardAnimation = new EventEmitter<boolean>();

  question_dict = {};

  obligatoryAnswers: { [id: string]: 'show' | 'hidden' } = {};

  reviewMode = false;

  constraintPipe = new IsConstraintPipe();

  currentQuestionsHelperObj = {};

  questionsFormGroup = new FormGroup({});

  formValidity = false;

  validations = [];

  currentFormValue = '';

  firstTimeChange = true;
  resettingFormGroup = false;

  regularExpresion = {};

  allRegularExpressionsAreKoble = true;

  listOfConstraintsOptions: {
    question_id: string;
    parentQuestPosition: number;
    isFreeText: boolean;
    constraintOption?: any;
  }[] = [];

  appSvgTheme = 0;

  constructor(private magicStringsService: MagicStringsService) {}

  ngOnInit(): void {
    this.appSvgTheme = +this.magicStringsService.IdTheme;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.activeTopic) {
      this.firstTimeChange = true
      this.question_dict = {};
      this.activeTopic.Questions.forEach((question) => {
        this.question_dict[question.id] = question;
      });
      if (this.questionsFormGroup.value !== {}) {
        let formKeys = Object.keys(this.questionsFormGroup.value);

        if (formKeys.length > 0) {
          // we need this because when a formGroup changes we check for validations lower
          this.resettingFormGroup = true;
          formKeys.forEach((key) => {
            this.questionsFormGroup.removeControl(key);
          });
          // once everything is removed set resettingFormGroup = false
          this.resettingFormGroup = false;
        }
      }
      // if activeTopic changed setUpForm
      this.setUpForm();
    }
  }

  submitQuestions() {
    let answers = this.fillAnswersArray();

    this.formFinalValue.emit({
      Topic_Assigned_id: this.activeTopic.Assigned_id,
      Assigned_Id: this.questionnaireId,
      Topic_Id: this.activeTopic.id,
      Answers: answers,
    });
  }

  pushValidation(validQuestion: { questionId: string; validity: boolean }) {
    this.regularExpresion[validQuestion.questionId] = validQuestion.validity;
    let valids = [];
    Object.keys(this.regularExpresion).forEach((key) => {
      valids.push(this.regularExpresion[key]);
    });
    this.removeFromRegularExprObj();
    this.allRegularExpressionsAreKoble = !valids.includes(false);
    this.formvalidityEvent.emit(this.allRegularExpressionsAreKoble); // alliws an einai la8os to regex sto go home zitaei na ginei save
  }

  setObligatoryQuestionHidden(question: { questionId: string }) {
    this.obligatoryAnswers[question.questionId] = 'hidden';
    this.checkFormValidity();
  }

  setObligatoryQuestionShow(question: { questionId: string }) {
    this.obligatoryAnswers[question.questionId] = 'show';
    this.checkFormValidity();
  }

  private removeRegular() {
    let valids = [];
    Object.keys(this.regularExpresion).forEach((key) => {
      valids.push(this.regularExpresion[key]);
    });
    this.removeFromRegularExprObj();
    this.allRegularExpressionsAreKoble = !valids.includes(false);
    if (this.formValidity) {
      this.formvalidityEvent.emit(this.allRegularExpressionsAreKoble); // alliws an einai la8os to regex sto go home zitaei na ginei save
    }
  }

  private removeFromRegularExprObj() {
    this.listOfConstraintsOptions.forEach((constraint) => {
      if (constraint.isFreeText) {
        if (this.activeTopic.Questions[constraint.parentQuestPosition].id &&
          [null, constraint.constraintOption].includes(
            this.questionsFormGroup.value[this.activeTopic.Questions[constraint.parentQuestPosition].id]['Input']
          )
        ) {
          this.regularExpresion[constraint.question_id] = true;
          this.obligatoryAnswers[constraint.question_id] = 'hidden';
        } else {
          this.obligatoryAnswers[constraint.question_id] = 'show';
        }
      } else {
        let found = false;
        if (this.activeTopic.Questions[constraint.parentQuestPosition]) {
          this.activeTopic.Questions[constraint.parentQuestPosition]['Check_List_Box_Options'].forEach((answer) => {
            if (
              answer['id'] ===
              this.questionsFormGroup.value[this.activeTopic.Questions[constraint.parentQuestPosition].id]['Input']
            ) {
              if (constraint.constraintOption === answer['Answer_Value']) {
                this.regularExpresion[constraint.question_id] = true;
                found = true;
              }
            }
          });
        }
      }
    });
  }

  private setUpForm() {
    this.obligatoryAnswers = {};
    this.reviewMode = this.currentUser !== this.activeTopic.Assigned_To;

    this.questionsFormGroup = new FormGroup({});
    this.activeTopic.Questions.forEach((quest) => {
      this.currentQuestionsHelperObj[quest.id] = {
        type: quest.TypeOfQuestion,
        answer_value: quest.Check_List_Box_Options,
        score: quest.score,
      };
      let initial = { Input: this.setUpInitialValue(quest) };
      this.questionsFormGroup.addControl(quest.id, new FormControl(initial));
      // if comment is true then create formControl for comment
      if (quest.Comment) {
        let comment = quest.Answers[0] ? (quest.Answers[0]['Comment'] ? quest.Answers[0]['Comment'] : '') : '';
        this.questionsFormGroup.addControl(quest.id + 'Comment', new FormControl({value: comment, disabled: this.reviewMode }));
      }
    });

    this.fillObligatoryAnswers();

    // First time before changes
    if (this.firstTimeChange) {
      this.firstTimeChange = false;
      this.currentFormValue = JSON.stringify(this.questionsFormGroup.value);
    }
    this.formValidity = false;
    // on valueChanges and also check if value changed for sure
    this.questionsFormGroup.valueChanges.subscribe((val) => {
      if (!this.resettingFormGroup) {
        this.checkIfNeedsSaving();
        this.checkFormValidity();
      }
    });
    this.scrollTop.emit();
  }

  private fillAnswersArray(): {
    Question_Id: string;
    Answer_Id: string;
    Answer: string;
    score: number;
    Comment: string;
  }[] {
    let answers = [];
    Object.keys(this.questionsFormGroup.value).forEach((key) => {
      let questionIsHidden = true;
      let currentQuestion = this.question_dict[key];
      if (currentQuestion?.Constrained) {
        questionIsHidden = this.checkIfIsHidden(key);
      }

      // If it's not a comment (comment formcontrol have Comment at the end) and it's not hidden
      if (!key.includes('Comment') && !(questionIsHidden && currentQuestion.Constrained)) {
        let answerId, answer, ischeckbox, answerScore, comment;
        let checkbxanswersid = [];
        let checkAnswers = [];
        if (
          this.currentQuestionsHelperObj[key].type === 'List box' ||
          this.currentQuestionsHelperObj[key].type === 'Check-list box' ||
          this.currentQuestionsHelperObj[key].type === 'Yes or no'
        ) {
          if (this.questionsFormGroup.value[key]['Input'] !== null) {
            if (Array.isArray(this.questionsFormGroup.value[key]['Input'])) {
              // checklist box
              if (this.questionsFormGroup.value[key]['Input'].length > 1) {
                ischeckbox = true;
                this.questionsFormGroup.value[key]['Input'].forEach((element) => {
                  checkbxanswersid.push(element);
                });
              } else {
                answerId = this.questionsFormGroup.value[key]['Input'][0];
              }
            } else if (this.questionsFormGroup.value[key]['Input']) {
              // listbox or yes/no one value from user
              answerId = this.questionsFormGroup.value[key]['Input'];
            } else {
              // value came from db and didnt change
              answerId = this.questionsFormGroup.value[key];
              if (answerId.hasOwnProperty('Input') && this.questionsFormGroup.value[key]['Input'] === null) {
                answerId = null;
              }
            }
            this.currentQuestionsHelperObj[key].answer_value.map((ans) => {
              if ((!ischeckbox && ans.Answer_Value === answerId) || ans.id === answerId) {
                answer = ans.Answer_Value;
                answerScore = ans.score;
              } else if (
                (ischeckbox && checkbxanswersid.includes(ans.Answer_Value)) ||
                checkbxanswersid.includes(ans.id)
              ) {
                checkAnswers.push({
                  Answer_Id: ans.id,
                  Answer: ans.Answer_Value,
                  answerScore: ans.score,
                });
              }
            });
          } else {
            answer = null;
          }
        } else {
          answer = this.questionsFormGroup.value[key] !== null ? this.questionsFormGroup.value[key]['Input'] : null;
          answerScore = this.question_dict[key].score;
        }

        comment = !!this.questionsFormGroup.value[key + 'Comment']
          ? this.questionsFormGroup.value[key + 'Comment']
          : '';

        if ((answerId !== null && answer !== null && !ischeckbox && answer !== '') || (!ischeckbox && comment !== '')) {
          answers.push({
            Question_Id: key,
            Answer_Id: answerId,
            Answer: answer,
            score: answerScore,
            Comment: comment,
          });
        } else if ((answerId !== null && ischeckbox) || (ischeckbox && comment !== '')) {
          checkAnswers.forEach((value) => {
            answers.push({
              Question_Id: key,
              score: value.answerScore,
              Comment: comment,
              Answer_Id: value.Answer_Id,
              Answer: value.Answer,
            });
          });
        }
      }
    });
    return answers;
  }

  private fillObligatoryAnswers() {
    this.activeTopic.Questions.forEach((quest) => {
      if (quest.Obligatory && !quest.Constrained) {
        this.obligatoryAnswers[quest.id] = 'show';
      } else if (quest.Constrained) {
        if (quest.Obligatory) {
          let isHidden = this.checkIfIsHidden(quest.id);
          this.obligatoryAnswers[quest.id] = isHidden ? 'hidden' : 'show';
        }
        let typeOfQuestion = this.question_dict[quest.Constrained_with as string]['TypeOfQuestion'];
        if (typeOfQuestion === 'Free text' || typeOfQuestion === 'File') {
          this.listOfConstraintsOptions.push({
            question_id: quest.id,
            isFreeText: true,
            parentQuestPosition: this.question_dict[quest.Constrained_with as string]['Position'] - 1,
          });
        } else {
          this.listOfConstraintsOptions.push({
            question_id: quest.id,
            isFreeText: false,
            constraintOption: quest['ConstraintOptions'][0],
            parentQuestPosition: this.question_dict[quest.Constrained_with as string]['Position'] - 1,
          });
        }
      }
    });
  }

  private checkFormValidity() {
    let indexArray = [];
    let index = 0;
    
    Object.keys(this.obligatoryAnswers).forEach((key) => {
      if (this.question_dict[key] && this.question_dict[key].Constrained) {
        let hiddenChild = this.checkIfIsHidden(key);        
        this.obligatoryAnswers[key] = hiddenChild ? 'hidden' : 'show';        
      }
      let currentAnswer = this.questionsFormGroup.value[key];
      
      if (this.obligatoryAnswers[key] !== 'hidden') {
        index++;
        if (currentAnswer !== undefined) {          
          if (
            currentAnswer === null ||
            currentAnswer['Input'] === '' ||
            currentAnswer['Input'] === null ||
            (Array.isArray(currentAnswer['Input']) &&
              (currentAnswer['Input'].length === 0 || currentAnswer['Input'][0] === null))
          ) {
            indexArray.push(index);
          }
        }
      }
    });
    
    this.unCompletedObligatoryQuestions.emit(indexArray);
    this.removeRegular();
    this.validations = [];
  }

  private checkIfNeedsSaving() {
    let val = JSON.stringify(this.questionsFormGroup.value);
    // check if values are different
    let shouldSave = this.currentFormValue.localeCompare(val) !== 0;

    this.unsaved.emit(shouldSave);
  }

  private setUpInitialValue(quest: QuestionAnswered) {
    if (quest.TypeOfQuestion === 'Free text') {
      return !!quest.Answers[0].Value_Answer ? quest.Answers[0].Value_Answer : '';
    } else if (quest.TypeOfQuestion === 'File') {
      return !!quest.Answers[0].Value_Answer ? quest.Answers[0].Value_Answer : null;
    } else if (quest.TypeOfQuestion === 'List box' || quest.TypeOfQuestion === 'Yes or no') {
      return quest.Answers[0].Answer_id;
    } else {
      return this.setInFormTheCheckBoxAnswers(quest.Answers);
    }
  }

  private setInFormTheCheckBoxAnswers(answers: { Answer_id: string; Value_Answer: string }[]): string[] {
    let answerIds = [];
    answers.forEach((ans) => answerIds.push(ans.Answer_id));
    return answerIds;
  }

  private checkIfIsHidden(currentID) {
    let currentQuestion = this.question_dict[currentID];
    let parentID = currentQuestion.Constrained_with;
    let isHidden = false;
    
    if (currentQuestion.ConstraintOptions.length > 0 && currentQuestion.ConstraintOptions[0] !== null) {
      let found = false;

      this.question_dict[parentID].Check_List_Box_Options.forEach((option) => {
        if (
          this.questionsFormGroup.value[parentID] &&
          this.questionsFormGroup.value[parentID]['Input'] === option.id &&
          currentQuestion.ConstraintOptions.includes(option.Answer_Value)
        ) {
          found = true;
        } 
      });
      
      isHidden = found ? false : true;
    } else {            
      if (
        this.questionsFormGroup.value[parentID] &&
        this.questionsFormGroup.value[parentID]['Input'] &&
        this.questionsFormGroup.value[parentID]['Input'] !== '' &&
        this.questionsFormGroup.controls[parentID].valid
      ) {
        isHidden = false;
      } else {
        isHidden = true;
      }
    }
    return isHidden;
  }
}
