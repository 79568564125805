import { Component, OnInit, Input, HostListener, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { QuestionnaireStoreService } from 'src/app/Store/store-services/questionnaire-store.service';
import { TopicStoreService } from 'src/app/Store/store-services/topic-store.service';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { TopicWithOnlyQuestionText } from 'src/app/data/models/TopicWithOnlyQuestionText';
import { ElencoTopic } from 'src/app/data/models/ElencoTopic';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { Store } from '@ngrx/store';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { PrepositionDictionaryPipe } from 'src/app/utils/pipes/preposition-dictionary.pipe';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Component({
  selector: 'app-create-new-questionnaire',
  templateUrl: './create-new-questionnaire.component.html',
  styleUrls: ['./create-new-questionnaire.component.scss'],
})
export class CreateNewQuestionnaireComponent implements OnInit, OnDestroy {
  @Input()
  formBody: QuestionnairesStructure;

  @Output()
  changesMade = new EventEmitter<{}>();
  index = 0;
  @Output()
  saveChanges = new EventEmitter<boolean>();

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate() {
    return this.runBeforeUnload();
  }

  topicTitlesInStore: Observable<string[]>;

  AllTopicsStoredWithQuestions: Observable<TopicWithOnlyQuestionText[]>;

  actionToTakePlace = 'create';

  ReadOnlyMode = false;

  loading: Observable<boolean>;

  formvalue = new FormGroup({
    QuestionnaireTitle: new FormControl('', [Validators.required]),
    QuestionnaireDescription: new FormControl(''),
  });

  topics$: Observable<ElencoTopic[]>;
  topicsInside: ElencoTopic[];
  startingTopics: ElencoTopic[];

  disableActions = false;
  titleSubscription: Subscription;
  titleChange = false;
  formChange = false;
  saveTrigger = false;
  sendChanges = { titleChange: false, formChange: false };
  startingValue: ElencoTopic[];
  titleWarning: string;
  questionWarning: string;

  lang: string;
  langToUnsubscribe: Subscription;

  prepPipe = new PrepositionDictionaryPipe();
  questionarioName: string;
  questionarioNameENG: string;
  singleTopicName: string;
  singleTopicNameENG: string;

  constructor(
    private topicStoreService: TopicStoreService,
    private questStoreService: QuestionnaireStoreService,
    private router: Router,
    private store: Store,
    private dialogService: GeneralDialogService,
    private notify: NotifyService,
    private magicStringsService: MagicStringsService,
    public authService: AuthService
  ) {
    if (!!this.router.getCurrentNavigation() && !!this.router.getCurrentNavigation().extras.state) {
      this.formBody = this.router.getCurrentNavigation().extras.state.importData;
    }
  }

  ngOnInit(): void {
    let abilities = this.authService.getUserAbilities();
    let found = false;
    abilities.forEach((permission) => {
      if (permission.Ability === 'UpdateActiveModels') {
        found = true;
      }
    });
    if (!found) {
      this.ReadOnlyMode = true;
    }

    this.startingTopics = !!this.formBody ? this.formBody.Topics : null;

    this.singleTopicName = this.magicStringsService.SingleTopic.toLowerCase();
    this.singleTopicNameENG = this.magicStringsService.SingleTopicENG.toLowerCase();
    this.questionarioName = this.magicStringsService.Questionario.toLowerCase();
    this.questionarioNameENG = this.magicStringsService.QuestionarioENG.toLowerCase();

    this.loading = this.questStoreService.selectLoadingOfQuestionnnaires();
    this.topics$ = this.topicStoreService.selectTopics().pipe(
      map((value) => {
        this.topicsInside = value;
        this.hasFormChange(this.topicsInside);
        return this.topicsInside;
      })
    );
    this.checkForChanges();
    this.topicTitlesInStore = this.topicStoreService.selectAllTitlesOFTopics();
    this.AllTopicsStoredWithQuestions = this.topicStoreService.selectTopisWithTheirQuestions();
    this.formBody !== undefined ? this.patchFormValue() : this.topicStoreService.initialiseTopics();

    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
      this.titleWarning =
        this.lang == 'it'
          ? 'Inserire il titolo ' + this.prepPipe.transform('di ' + this.questionarioName, 'singular', 'it')
          : 'Insert the ' + this.questionarioNameENG + ' title';
      this.questionWarning =
        this.lang == 'it'
          ? 'Inserire almeno una domanda per ' + this.singleTopicName
          : 'Insert at least one question for each ' + this.singleTopicNameENG;
    });
  }

  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
    this.langToUnsubscribe.unsubscribe();
  }

  storeTopic(event: ElencoTopic) {
    this.topicStoreService.addTopicsInStore(event);
  }

  modifyTopic(event: { topic: ElencoTopic; index: number; indexOldOrder: number }) {
    this.topicStoreService.modifyTopic(event);
  }

  deleteTopic(index: number) {
    this.topicStoreService.deleteTopic(index);
  }

  saveModel(action: string) {
    if (this.formvalue.valid) {
      this.saveTrigger = true;
      this.saveChanges.emit(this.saveTrigger);

      switch (this.formvalue.status) {
        case 'VALID':
          this.checkAndDispatchQuestionnaire(action);
          break;
        case 'INVALID':
          this.formvalue.markAllAsTouched();
          break;
        default:
          this.formvalue.markAllAsTouched();
      }
    } else {
      this.notify.openWarningSwal(this.titleWarning);
    }
  }

  dispatchQuestionnaire(quest: QuestionnairesStructure) {
    this.actionToTakePlace === 'create' || this.formBody.Status === 'Draft' || this.formBody.Status === 'Import'
      ? this.questStoreService.addQuestionnaire(quest)
      : this.questStoreService.updateQuestionnaire(quest);
  }

  cancelModel() {
    this.router.navigateByUrl(`${ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT}`);
  }

  showTopicData(event: { topic: ElencoTopic; index: number }) {
    this.index = event.index;
  }

  setTopicInside(topic: ElencoTopic) {
    let copiedTopics = this.topicsInside.concat([]);
    copiedTopics[topic.Order - 1] = {
      ...copiedTopics[topic.Order - 1],
      Questions: topic.Questions,
    };
    this.topicsInside = copiedTopics;
    this.topicStoreService.updateTopicOnDrop(topic);
  }

  checkForChanges() {
    let startingTitle =
      this.formBody !== undefined
        ? {
            QuestionnaireTitle: this.formBody.Title,
            QuestionnaireDescription: this.formBody.Description,
          }
        : this.formvalue.getRawValue();
    this.titleSubscription = this.formvalue.valueChanges.subscribe((val) => {
      if (JSON.stringify(startingTitle) === JSON.stringify(val)) {
        this.titleChange = false;
        this.changesMade.emit({ formChange: false });
      } else {
        this.titleChange = true;
        this.changesMade.emit({ formChange: true });
      }
    });
  }

  private patchFormValue() {
    this.formvalue.get('QuestionnaireTitle').patchValue(this.formBody['Title']);
    this.formvalue.get('QuestionnaireDescription').patchValue(this.formBody['Description']);
    this.actionToTakePlace = this.formBody['Status'] !== 'Import' ? 'update' : 'Import';
  }

  private hasFormChange(currentTopics: ElencoTopic[]) {
    // check if values are different
    this.formChange = JSON.stringify(currentTopics) !== JSON.stringify(this.startingTopics);
  }

  private checkAndDispatchQuestionnaire(action: string) {
    if (this.topicsInside.length) {
      if (this.checkEachTopicQuestionsLength()) {
        const newQuestionnaire = {
          Project: '',
          id: this.actionToTakePlace !== 'update' ? null : this.formBody['id'],
          Title: this.formvalue.value.QuestionnaireTitle,
          Description: this.formvalue.value.QuestionnaireDescription,
          Status: action === 'finalize' || action === 'update' ? 'Completed' : 'Draft',
          Topics: this.topicsInside,
        } as QuestionnairesStructure;
        if (action === 'Draft') {
          this.dialogService.openConfirmDialog({
            info: CONFIRMDIALOGSTRINGS.SAVE_QUESTIONNAIRE,
          });
          this.dialogService.openConfirmDialogResponse().subscribe((res) => {
            if (res !== 'Cancel') {
              this.dispatchQuestionnaire(newQuestionnaire);
              this.disableActions = false;
            }
          });
        } else {
          this.dispatchQuestionnaire(newQuestionnaire);
          this.disableActions = false;
        }
      } else {
        this.notify.openWarningSwal(this.questionWarning);
        this.disableActions = false;
      }
    }
  }

  private checkEachTopicQuestionsLength(): boolean {
    let flag = true;
    this.topicsInside.forEach((eachTopic) => {
      if (eachTopic.Questions.length === 0) {
        flag = false;
      }
    });
    return flag;
  }

  private runBeforeUnload() {
    if (!this.saveTrigger) {
      if (this.formChange !== false || this.titleChange !== false) {
        history.pushState(null, null, this.router.url);
        this.dialogService.openConfirmDialog({
          info: CONFIRMDIALOGSTRINGS.CANCEL_MODIFICATION_CREATION,
        });
        this.dialogService.openConfirmDialogResponse();
        return this.dialogService.confirmDialog.componentInstance.navigateAwaySelection$;
      } else {
        return true;
      }
    }
    return true;
  }
}
