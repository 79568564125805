import { AppState } from './../state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const appState = createFeatureSelector<AppState>('store');

export const selectQuestionnairesStructured = createSelector(appState, (state) => state.Questionnaires);
export const selectLoading = createSelector(appState, (state) => state.loading);
export const selectQuestionnaireToBeModified = createSelector(appState, (state) => state.questionnaireToBeModified);
export const selectTotalNumber = createSelector(appState, (state) => state.total);
export const selectPageIndex = createSelector(appState, (state) => state.pageIndex);
export const selectTotalAndIndex = createSelector(selectTotalNumber, selectPageIndex, (total, index) => {
  return { total: total, pageIndex: index };
});
export const selectModelsFilterOptions = createSelector(appState, (state) => state.filterOptions);
export const selectCheckedModels = createSelector(appState, (state) => state.checkedModels);

export const selectUserFilterOptions = createSelector(appState, (state) => state.userFilterOptions);
export const selectCheckedUsers = createSelector(appState, (state) => state.checkedsers);
