import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possessiveAdjectiveDictionary',
})
export class PossessiveAdjectiveDictionaryPipe implements PipeTransform {
  transform(value: string, pluralCheck: string, languageCheck: string): string {
    var adjective = '';
    var counter = value.indexOf(' ');
    adjective = value.substring(0, counter).toLowerCase();
    var otherWords = value.substring(counter + 1, value.length);

    if (languageCheck === 'en') {
      return otherWords;
    }

    var firstTwoChar = adjective.substring(0, 2).toLowerCase();
    var stringToCheck = '';
    var counterOther = otherWords.indexOf(' ');

    if (counterOther === -1) {
      // one word
      stringToCheck = otherWords;
    } else {
      // 2+ words
      stringToCheck = otherWords.substring(0, counterOther); // first word
    }

    var lastChar = stringToCheck.charAt(stringToCheck.length - 1).toLowerCase();

    if (pluralCheck === 'singular') {
      if (lastChar === 'a') {
        return firstTwoChar + 'a ' + otherWords;
      } else {
        return firstTwoChar + 'o ' + otherWords;
      }
    } else if (pluralCheck === 'plural') {
      if (lastChar === 'e') {
        return firstTwoChar + 'e ' + otherWords;
      } else {
        if (adjective === 'tuo' || adjective === 'suo') {
          return adjective + 'i ' + otherWords;
        } else if (adjective === 'mio') {
          return 'miei ' + otherWords;
        } else {
          return otherWords;
        }
      }
    } else {
      return adjective;
    }
  }
}
