import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ActionTypes, storeCheckedModels } from 'src/app/Store/questionnaire-store/state.actions';
import { ArchivedActionTypes } from 'src/app/Store/archive-questionnaire/archive.state.actions';
import {
  selectLoading,
  selectQuestionnairesStructured,
  selectTotalAndIndex,
} from 'src/app/Store/questionnaire-store/state.selectors';
import { Observable, of, Subject } from 'rxjs';
import { selectUsersFullname } from 'src/app/Store/users/users.selectors';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getItalianPaginator } from 'src/app/utils/paginator-settings';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { User } from 'src/app/data/models/User';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { UserAbilitiesEnum } from 'src/app/data/models/abilities';
import { PostQuestionnaireService } from 'src/app/data/api/Questionnaire/post-questionnaire.service';

import { NotifyService } from 'src/app/utils/notification.service';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { ProjectActionTypes } from 'src/app/Store/projects/actions/projects.actions';
import { VerbPronounDictionaryPipe } from 'src/app/utils/pipes/verb-pronoun-dictionary.pipe';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { AdvancedFilterComponent } from '../advanced-filter/advanced-filter.component';
import {
  selectDeleteMode,
  selectLoadingDeleteMode,
  selectModelClickedFromProjects,
} from 'src/app/Store/projects/selectors/projects.selectors';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { take, takeUntil } from 'rxjs/operators';
import { PrepositionDictionaryPipe } from 'src/app/utils/pipes/preposition-dictionary.pipe';
import { IndefiniteArticleDictionaryPipe } from 'src/app/utils/pipes/indefinite-article-dictionary.pipe';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-models-table',
  templateUrl: './models-table.component.html',
  styleUrls: ['./models-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useValue: getItalianPaginator() }],
})
export class ModelsTableComponent implements OnInit, OnDestroy {
  @Input()
  dataSource: QuestionnairesStructure[];

  @Input()
  lang;

  @Input()
  selectMode = false;

  @Input()
  mode: Boolean;

  @Input()
  projectSelected = '';

  @Output()
  closeViewModeOut = new EventEmitter<boolean>();

  @Output()
  showProjectsSelected = new EventEmitter<void>();

  @Output()
  showModelSelected = new EventEmitter<void>();

  @Output()
  viewModeSingleSelection = new EventEmitter<QuestionnairesStructure>();

  @ViewChild(AdvancedFilterComponent)
  private advancedFilter: AdvancedFilterComponent;

  private ngUnsubscribe = new Subject();

  selectedQuestionnaire = null;

  totalAndPageIndex: Observable<{ total: number; pageIndex: number }>;

  structureTableSortingOptions = {
    pageIndex: 0,
    title: 'Questionaire_Title',
    ascdesc: 'ASC',
  };

  modelFilter = '';
  creatorFilter = '';
  searchTerm = '';
  lastAssignmentFromFilter = '';
  lastAssignmentToFilter = '';
  lastChangeFromFilter = '';
  lastChangeToFilter = '';
  creationFromFilter = '';
  creationToFilter = '';
  status = {
    draft: null,
    active: null,
    closed: null,
    archived: false,
  };

  usersFullName: Observable<User>;

  loading: Observable<boolean>;

  canCreateModifyModel = false;

  canEditActiveModels = false;

  selectedFiles?: FileList;

  currentFile?: File;

  showExport = false;

  questIDToExport: string;

  openNewTitleDialog = false;

  idToClone: string;

  lengthTitle = 0;

  verbPipe = new VerbPronounDictionaryPipe();
  prepPipe = new PrepositionDictionaryPipe();
  indArtPipe = new IndefiniteArticleDictionaryPipe();

  showFilter = false;

  popUpMode = this.route.url !== '/questionnaires-management';

  panelOpenState = false;

  selection = new SelectionModel<QuestionnairesStructure>(true, []);
  checkedModels: QuestionnairesStructure[] = [];

  //DELETE MODE
  quest: QuestionnairesStructure;
  activeStatus: boolean;
  loadingStatus: boolean;
  //---------//

  constructor(
    private route: Router,
    private store: Store,
    private auth: AuthService,
    private postQuestionnaireService: PostQuestionnaireService,
    private notifyService: NotifyService,
    private dialogService: GeneralDialogService,
    private magic: MagicStringsService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.selection.isSelected = this.isRowChecked.bind(this);

    //DELETE MODE
    this.store
      .select(selectLoadingDeleteMode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dataLoading) => {
        this.loadingStatus = dataLoading;

        if (this.loadingStatus === false) {
          this.store
            .select(selectDeleteMode)
            .pipe(take(1))
            .subscribe((data) => {
              this.activeStatus = data;
              this.deleteSingleQuestionnaireLogic();
            });
        }
      });
    //---------//

    this.store
      .select(selectModelClickedFromProjects)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((filter) => {
        if (filter) {
          this.searchTerm = filter;
          this.advancedFilter.resetFilters();
          this.advancedFilter.closeAllExpands();
          this.advancedFilter.toggleFilter(false);
        }
      });

    this.auth.getUserAbilities().forEach((e) => {
      if (e.Ability === UserAbilitiesEnum.CreateModifyModel) {
        this.canCreateModifyModel = true;
      }

      if (e.Ability === UserAbilitiesEnum.UpdateActiveModels) {
        this.canEditActiveModels = true;
      }
    });


    this.totalAndPageIndex = this.store.select(selectTotalAndIndex);

    this.usersFullName = this.store.select(selectUsersFullname);

    this.loading = this.store.select(selectLoading);

  }

  updateModelsFilters(options){
    this.modelFilter = options.modelFilter;
    this.creatorFilter = options.creatorFilter;
    this.searchTerm = options.searchTerm;
    this.lastAssignmentFromFilter = options.lastAssignmentFromFilter;
    this.lastAssignmentToFilter = options.lastAssignmentToFilter;
    this.lastChangeFromFilter = options.lastChangeFromFilter;
    this.lastChangeToFilter = options.lastChangeToFilter;
    this.creationFromFilter = options.creationFromFilter;
    this.creationToFilter = options.creationToFilter;
    this.status = {
      draft: options.status.draft,
      active: options.status.active,
      closed: options.status.closed,
      archived: options.status.archived,
    };
  }

  modelReset() {
    if (this.popUpMode) {
      this.store.dispatch({
        type: ActionTypes.loadQuestionnairesType,
        pageIndex: 0,
        title: this.structureTableSortingOptions.title,
        AscDesc: this.structureTableSortingOptions.ascdesc,
        archived: false,
        statusFilter: {
          draft: false,
          active: true,
        },
      });
    } else {
      this.store.dispatch({
        type: ActionTypes.loadQuestionnairesType,
        pageIndex: 0,
        title: this.structureTableSortingOptions.title,
        AscDesc: this.structureTableSortingOptions.ascdesc,
        archived: false,
      });
    }

    this.modelFilter = '';
    this.creatorFilter = '';
    this.searchTerm = '';
    this.lastAssignmentFromFilter = '';
    this.lastAssignmentToFilter = '';
    this.lastChangeFromFilter = '';
    this.lastChangeToFilter = '';
    this.creationFromFilter = '';
    this.creationToFilter = '';
    this.status = {
      draft: this.popUpMode ? false : null,
      active: this.popUpMode ? true : null,
      closed: null,
      archived: false,
    };

    this.store.dispatch({
      type: ActionTypes.saveModelFilters,
      saved: false,
      creatorFilter: this.creatorFilter,
      searchTerm: this.searchTerm,
      lastAssignmentFromFilter: this.lastAssignmentFromFilter,
      lastAssignmentToFilter: this.lastAssignmentToFilter,
      lastChangeFromFilter: this.lastChangeFromFilter,
      lastChangeToFilter: this.lastChangeToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
      status: {
        draft: this.status.draft,
        active: this.status.active,
        archived: this.status.archived,
      },
    });
  }

  modelSearch(data) {
    this.updateModelsFilters(data);

    if (
      data.saved === true ||
      this.searchTerm ||
      this.creatorFilter ||
      this.creationFromFilter ||
      this.creationToFilter ||
      this.lastAssignmentFromFilter ||
      this.lastAssignmentToFilter ||
      this.lastChangeFromFilter ||
      this.lastChangeToFilter ||
      this.status.active === false ||
      this.status.draft === false ||
      this.status.archived === true
    ) {
      this.store.dispatch({
        type: ActionTypes.saveModelFilters,
        saved: true,
        creatorFilter: this.creatorFilter,
        searchTerm: this.searchTerm,
        lastAssignmentFromFilter: this.lastAssignmentFromFilter,
        lastAssignmentToFilter: this.lastAssignmentToFilter,
        lastChangeFromFilter: this.lastChangeFromFilter,
        lastChangeToFilter: this.lastChangeToFilter,
        creationFromFilter: this.creationFromFilter,
        creationToFilter: this.creationToFilter,
        status: {
          draft: this.status.draft,
          active: this.status.active,
          archived: this.status.archived,
        },
      });
    }

    this.store.dispatch({
      type: ActionTypes.loadQuestionnairesType,
      pageIndex: 0,
      AscDesc: this.structureTableSortingOptions.ascdesc,
      archived: this.status.archived,
      searchString: this.searchTerm,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      statusFilter: this.status,
      lastAssignmentFromFilter: this.lastAssignmentFromFilter,
      lastAssignmentToFilter: this.lastAssignmentToFilter,
      lastChangeFromFilter: this.lastChangeFromFilter,
      lastChangeToFilter: this.lastChangeToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
    });
  }

  getNextQuestionnairyStructures(event: {
    length: number;
    pageIndex: number;
    pageSize: number;
    previousPageIndex: number;
  }) {
    this.structureTableSortingOptions.pageIndex = event.pageIndex;

    this.store.dispatch({
      type: ActionTypes.loadQuestionnairesType,
      pageIndex: this.structureTableSortingOptions.pageIndex,
      title: this.structureTableSortingOptions.title,
      AscDesc: this.structureTableSortingOptions.ascdesc,
      archived: this.status.archived,
      searchString: this.searchTerm,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      statusFilter: this.status,
      lastAssignmentFromFilter: this.lastAssignmentFromFilter,
      lastAssignmentToFilter: this.lastAssignmentToFilter,
      lastChangeFromFilter: this.lastChangeFromFilter,
      lastChangeToFilter: this.lastChangeToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
    });
  }

  openExportCsv(id: string) {
    this.showExport = !this.showExport;
    this.questIDToExport = id;
  }
  exportCsvRead() {
    this.showExport = false;
    this.store.dispatch({
      type: ActionTypes.exportModelCsvType,
      data: [this.questIDToExport],
      typeExcel: 'readable',
      lang: this.lang,
      topic: this.translate.instant('DICTIONARY.SingleTopic'),
    });
  }
  exportCsvRaw() {
    this.showExport = false;
    this.store.dispatch({
      type: ActionTypes.exportModelCsvType,
      data: [this.questIDToExport],
      typeExcel: 'importable',
      lang: this.lang,
      topic: this.translate.instant('DICTIONARY.SingleTopic'),
    });
  }

  modifyQuestionnaire(id: string) {
    this.route.navigateByUrl(`/${ROUTES_STRINGS.MODIFYQUESTIONNAIRE}/${id}`);
  }

  archiveModel(ciao?: QuestionnairesStructure) {
    let infoDialog: string;
    let archivedArray: QuestionnairesStructure[] = [];
    let notArchivedArray: QuestionnairesStructure[] = [];

    if (ciao) {
      infoDialog = CONFIRMDIALOGSTRINGS.ARCHIVE_SINGLE_MODEL;
      archivedArray.push(ciao);
    } else if (this.checkedModels.length > 0) {
      infoDialog = CONFIRMDIALOGSTRINGS.ARCHIVE_MULTIPLE_MODELS;
      this.checkedModels.forEach((checked) => {
        if (checked.Questionnaire_Status !== 'Archived') {
          archivedArray.push(checked);
        } else {
          notArchivedArray.push(checked);
        }
      });
    }

    if (archivedArray.length > 0) {
      this.store.dispatch(storeCheckedModels({ modelArray: archivedArray }));
      this.dialogService.openConfirmDialog({
        info: infoDialog,
      });
      this.dialogService
        .openConfirmDialogResponse()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((btn) => {
          if (btn !== 'Cancel') {
            this.store.dispatch({
              type: ArchivedActionTypes.setQuestionnairesToBeArchived,
              data: archivedArray.map((a) => a.Questionnaire_id),
              pageIndex: this.structureTableSortingOptions.pageIndex,
              title: this.structureTableSortingOptions.title,
              AscDesc: this.structureTableSortingOptions.ascdesc,
              archived: this.status.archived,
              searchString: this.searchTerm,
              modelFilter: this.modelFilter,
              creatorFilter: this.creatorFilter,
              statusFilter: this.status,
              lastAssignmentFromFilter: this.lastAssignmentFromFilter,
              lastAssignmentToFilter: this.lastAssignmentToFilter,
              lastChangeFromFilter: this.lastChangeFromFilter,
              lastChangeToFilter: this.lastChangeToFilter,
              creationFromFilter: this.creationFromFilter,
              creationToFilter: this.creationToFilter,
            });
            this.checkedModels.length = 0;
            this.selection.clear();

            if (notArchivedArray.length > 0) {
              this._snackBar.open(
                this.lang === 'it'
                  ? `(${notArchivedArray.length}) ${this.magic.Modelli.toLowerCase()} erano già stati archiviati`
                  : `(${notArchivedArray.length}) ${this.magic.ModelliENG.toLowerCase()} had already been archived`,
                this.translate.instant('QUESTION_TYPES_FORM.CLOSE'),
                {
                  duration: 4000,
                }
              );
            }
          }
        });
    }
  }

  createProject(id: string) {
    this.route.navigateByUrl(`/${ROUTES_STRINGS.ASSIGNMODEL}/${id}`);
  }

  duplicateModel(newTitle: string) {
    this.lengthTitle = 0;
    let id = this.idToClone;
    this.idToClone = '';
    this.openNewTitleDialog = false;
    this.store.dispatch({
      type: ActionTypes.duplicateQuestionnaireAction,
      id: id,
      newTitle: newTitle,
      pageIndex: this.structureTableSortingOptions.pageIndex,
      title: this.structureTableSortingOptions.title,
      AscDesc: this.structureTableSortingOptions.ascdesc,
      archived: this.status.archived,
      searchString: this.searchTerm,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      statusFilter: this.status,
      lastAssignmentFromFilter: this.lastAssignmentFromFilter,
      lastAssignmentToFilter: this.lastAssignmentToFilter,
      lastChangeFromFilter: this.lastChangeFromFilter,
      lastChangeToFilter: this.lastChangeToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
    });
  }

  openNewTitle(id: string) {
    this.idToClone = id;
    this.openNewTitleDialog = true;
  }

  toCreateNewQuestionnaire() {
    this.route.navigate([ROUTES_STRINGS.CREATENEWQUESTIONNAIRE]);
  }

  closeViewMode() {
    this.closeViewModeOut.emit(true);
    this.selectedQuestionnaire = null;
  }

  selectModel(quest) {
    this.selectedQuestionnaire = quest;
  }

  returnModelAndClose() {
    this.viewModeSingleSelection.emit(this.selectedQuestionnaire);
    this.selectedQuestionnaire = null;
  }

  sortData(event) {
    const direction = event.direction === ('asc' || '') ? 'ASC' : 'DESC';
    this.structureTableSortingOptions.ascdesc = direction;
    this.structureTableSortingOptions.title = event.active;

    this.store.dispatch({
      type: ActionTypes.loadQuestionnairesType,
      pageIndex: this.structureTableSortingOptions.pageIndex,
      title: this.structureTableSortingOptions.title,
      AscDesc: this.structureTableSortingOptions.ascdesc,
      archived: this.status.archived,
      searchString: this.searchTerm,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      statusFilter: this.status,
      lastAssignmentFromFilter: this.lastAssignmentFromFilter,
      lastAssignmentToFilter: this.lastAssignmentToFilter,
      lastChangeFromFilter: this.lastChangeFromFilter,
      lastChangeToFilter: this.lastChangeToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
    });
  }

  upload(): void {
    this.loading = of(true);
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.postQuestionnaireService
          .upload(this.currentFile)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (event: any) => {
              if (!!event['body']) {
                this.loading = of(false);
                this.store.dispatch({
                  type: ActionTypes.setModifiedQuestionnaire,
                  questionnaire: event['body']['importData'],
                });
                this.route.navigate([ROUTES_STRINGS.CREATENEWQUESTIONNAIRE], {
                  state: { importData: event['body']['importData'] },
                });
              }
            },
            (err: any) => {
              if (err.error && err.error.message) {
                this.loading = of(false);
                console.error(err.error.message);
                this.notifyService.openWarningSwal(err.error.message);
              } else {
              }

              this.currentFile = undefined;
            }
          );
      }

      this.selectedFiles = undefined;
    }
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  close() {
    this.lengthTitle = 0;
    this.openNewTitleDialog = false;
  }

  onKey($event) {
    this.lengthTitle = $event;
  }

  showProjectsOfModel(title, id) {
    this.store.dispatch({
      type: ProjectActionTypes.saveProjectFilters,
      saved: true,
      modelFilter: title,
      creatorFilter: '',
      searchTerm: '',
      expireFromFilter: '',
      expireToFilter: '',
      creationFromFilter: '',
      creationToFilter: '',
      status: {
        draft: true,
        active: true,
        closed: true,
        archived: true,
      },
    });

    this.store.dispatch({
      type: ProjectActionTypes.getAllProjects,
      pageIndex: 0,
      AscDesc: 'ASC',
      allStatus: true,
      archived: true,
      modelID: id,
    });

    this.showProjectsSelected.emit();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  deleteMultipleQuestionnaires() {
    let infoDialog: string;
    let deletedArray: QuestionnairesStructure[] = [];
    let notDeletedArray: QuestionnairesStructure[] = [];

    if (this.checkedModels.length > 0) {
      this.checkedModels.forEach((checked) => {
        if (checked.Questionnaire_Status !== 'Completed' && !checked.hasActiveProjects) {
          infoDialog = CONFIRMDIALOGSTRINGS.DELETE_MULTIPLE_MODELS;
          deletedArray.push(checked);
        } else {
          notDeletedArray.push(checked);
        }
      });
    }

    if (deletedArray.length > 0) {
      this.store.dispatch(storeCheckedModels({ modelArray: deletedArray }));
      this.dialogService.openConfirmDialog({
        info: infoDialog,
      });
      this.dialogService
        .openConfirmDialogResponse()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((btn) => {
          if (btn !== 'Cancel') {
            this.store.dispatch({
              type: ActionTypes.deleteQuestionnairesType,
              deletedIds: deletedArray,
              pageIndex: this.structureTableSortingOptions.pageIndex,
              title: this.structureTableSortingOptions.title,
              AscDesc: this.structureTableSortingOptions.ascdesc,
              archived: this.status.archived,
              searchString: this.searchTerm,
              modelFilter: this.modelFilter,
              creatorFilter: this.creatorFilter,
              statusFilter: this.status,
              lastAssignmentFromFilter: this.lastAssignmentFromFilter,
              lastAssignmentToFilter: this.lastAssignmentToFilter,
              lastChangeFromFilter: this.lastChangeFromFilter,
              lastChangeToFilter: this.lastChangeToFilter,
              creationFromFilter: this.creationFromFilter,
              creationToFilter: this.creationToFilter,
            });
            this.checkedModels.length = 0;
            this.selection.clear();

            if (notDeletedArray.length > 0) {
              this._snackBar.open(
                this.lang === 'it'
                  ? `(${
                      notDeletedArray.length
                    }) ${this.magic.Modelli.toLowerCase()} completati e/o con ${this.magic.Progetti.toLowerCase()} attivi non sono stati eliminati`
                  : `(${
                      notDeletedArray.length
                    }) completed ${this.magic.ModelliENG.toLowerCase()} and/or ${this.magic.ModelliENG.toLowerCase()} with active ${this.magic.ProgettiENG.toLowerCase()} have not been deleted`,
                this.translate.instant('QUESTION_TYPES_FORM.CLOSE'),
                {
                  duration: 4000,
                }
              );
            }
          }
        });
    } else {
      this._snackBar.open(
        this.lang === 'it'
          ? `Puoi eliminare solo ${this.magic.Modelli.toLowerCase()} con status draft/archiviato senza progetti attivi ad essi collegati`
          : `You can delete only draft/archived ${this.magic.ModelliENG.toLowerCase()} and without active ${this.magic.ProgettiENG.toLowerCase()} linked to them`,
        this.translate.instant('QUESTION_TYPES_FORM.CLOSE'),
        {
          duration: 2000,
        }
      );
    }
  }

  deleteSingleQuestionnaire(quest?: QuestionnairesStructure) {
    //To activate DELETE MODE
    this.quest = quest;

    // save new project filters (model Title and all status) and getAllProjects with these filters
    this.store.dispatch({
      type: ProjectActionTypes.saveProjectFilters,
      modelFilter: quest.Questionnaire_Title,
      creatorFilter: '',
      searchTerm: '',
      expireFromFilter: '',
      expireToFilter: '',
      creationFromFilter: '',
      creationToFilter: '',
      status: {
        draft: true,
        active: true,
        closed: true,
        archived: true,
      },
    });
    
    this.store.dispatch({
      type: ProjectActionTypes.getAllProjects,
      pageIndex: 0,
      title: 'TITLE',
      AscDesc: 'ASC',
      allStatus: true,
      archived: true,
      modelID: quest.Questionnaire_id,
      modelFilter: quest.Questionnaire_Title,
      deleteMode: true,
    });
  }

  private deleteSingleQuestionnaireLogic() {
    if (!!this.quest['Number_Of_Projects'] && this.quest.Number_Of_Projects > 0) {      
      if (this.activeStatus) {
        this.notifyService.openWarningSwal(
          this.lang === 'it'
            ? `L\'eliminazione ${this.prepWord(
                `di ${this.magic.Modello.toLowerCase()}`,
                'singular'
              )} non è possibile, è presente almeno ${this.indArtWord(
                this.magic.Progetto.toLowerCase(),
                'singular'
              )} attivo ad esso collegato`
            : `${
                this.magic.ModelloENG
              }'s deletion is not possible, there is at least one active ${this.magic.ProgettoENG.toLowerCase()} linked to it`
        );
        this.showProjectsSelected.emit();
      } else {
        this.showProjectsSelected.emit();

        this.dialogService.openConfirmDialog({
          info: CONFIRMDIALOGSTRINGS.DELETE_MODEL_WITHPROJECTS,
        });
        this.dialogService
          .openConfirmDialogResponse()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((btn) => {
            if (btn !== 'Cancel') {
              this.store.dispatch({
                type: ActionTypes.deleteQuestionnairesType,
                deletedIds: [this.quest],
                pageIndex: this.structureTableSortingOptions.pageIndex,
                title: this.structureTableSortingOptions.title,
                AscDesc: this.structureTableSortingOptions.ascdesc,
                archived: this.status.archived,
                searchString: this.searchTerm,
                modelFilter: this.modelFilter,
                creatorFilter: this.creatorFilter,
                statusFilter: this.status,
                lastAssignmentFromFilter: this.lastAssignmentFromFilter,
                lastAssignmentToFilter: this.lastAssignmentToFilter,
                lastChangeFromFilter: this.lastChangeFromFilter,
                lastChangeToFilter: this.lastChangeToFilter,
                creationFromFilter: this.creationFromFilter,
                creationToFilter: this.creationToFilter,
              });
              this.showModelSelected.emit();
            }
          });
      }
    } else {
      this.dialogService.openConfirmDialog({
        info: CONFIRMDIALOGSTRINGS.DELETE_MODEL_NOPROJECTS,
      });
      this.dialogService
        .openConfirmDialogResponse()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((btn) => {
          if (btn !== 'Cancel') {
            this.store.dispatch({
              type: ActionTypes.deleteQuestionnairesType,
              deletedIds: [this.quest],
              pageIndex: this.structureTableSortingOptions.pageIndex,
              title: this.structureTableSortingOptions.title,
              AscDesc: this.structureTableSortingOptions.ascdesc,
              archived: this.status.archived,
              searchString: this.searchTerm,
              modelFilter: this.modelFilter,
              creatorFilter: this.creatorFilter,
              statusFilter: this.status,
              lastAssignmentFromFilter: this.lastAssignmentFromFilter,
              lastAssignmentToFilter: this.lastAssignmentToFilter,
              lastChangeFromFilter: this.lastChangeFromFilter,
              lastChangeToFilter: this.lastChangeToFilter,
              creationFromFilter: this.creationFromFilter,
              creationToFilter: this.creationToFilter,
            });
          }
        });
    }
  }

  prepWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.prepPipe.transform(word, plural, this.lang);
    return newWord;
  }

  indArtWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.indArtPipe.transform(word, plural, this.lang);
    return newWord;
  }

  //Selection Methods
  masterToggle() {
    if (this.isPageChecked()) {
      this.dataSource.forEach((model) => {
        this.selection.deselect(model);
        this.checkedModels = this.checkedModels.filter(
          (checked) => checked.Questionnaire_id !== model.Questionnaire_id
        );
      });
    } else {
      this.checkedModels = [...this.checkedModels];
      //Reset page selection
      let resetArray: QuestionnairesStructure[] = [];
      this.dataSource.forEach((model) => {
        this.selection.selected.filter((row) => {
          if (row.Questionnaire_id === model.Questionnaire_id) {
            resetArray.push(row);
          }
        });
      });
      resetArray.forEach((resetEl) => {
        this.checkedModels = this.checkedModels.filter(
          (checked) => checked.Questionnaire_id !== resetEl.Questionnaire_id
        );
      });
      //Add page selection
      this.dataSource.forEach((model) => {
        this.selection.select(model);
        this.checkedModels = [...this.checkedModels, model];
      });
    }
  }

  selectionToggle(row: QuestionnairesStructure, isChecked: boolean) {
    this.selection.toggle(row);
    if (isChecked) {
      this.checkedModels.push(row);
    } else {
      this.checkedModels = this.checkedModels.filter((checked) => checked.Questionnaire_id !== row.Questionnaire_id);
    }
  }

  isPageChecked(): boolean {
    const fetched = this.dataSource;
    let checkedModelsPerPage = [];
    this.dataSource.forEach((model) => {
      this.checkedModels.forEach((checked) => {
        if (model.Questionnaire_id === checked.Questionnaire_id) {
          checkedModelsPerPage.push(model);
        }
      });
    });

    let isPageChecked: boolean =
      checkedModelsPerPage.length == fetched.length &&
      checkedModelsPerPage.every((element, index) => {
        return element === fetched[index];
      });
    return isPageChecked;
  }

  isRowChecked(row: QuestionnairesStructure): boolean {
    const found = this.checkedModels.find((checked) => checked.Questionnaire_id === row.Questionnaire_id);
    if (found) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
