<ng-container *ngIf="userOrganizationForm">
  <form [formGroup]="userOrganizationForm" class="mx-auto mt-4 h-[fit-content] w-[30vw]">
    <span class="mt-8 text-2xl font-semibold text-card">{{ "USER_TABLE.CLIENT_NAME" | translate }}</span>
    <mat-form-field appearance="outline" class="mb-[1.75rem] w-full pt-4">
      <mat-label>{{ "USER_TABLE.CLIENT" | translate }}</mat-label>
      <mat-select formControlName="client" name="client">
        <mat-option class="text-black" *ngFor="let client of allClientGroup$ | async" [value]="client.client_id">
          {{ client.client_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="mt-4 text-2xl font-semibold text-card">{{ "USER_TABLE.GROUP_NAME" | translate }}</span>
    <mat-form-field appearance="outline" class="mb-[1.75rem] w-full pt-4">
      <mat-label>{{ "USER_TABLE.GROUP" | translate }}</mat-label>
      <mat-select name="group" formControlName="group">
        <ng-container *ngFor="let client of allClientGroup$ | async">
          <ng-container *ngIf="client.client_id === clientIdSelected">
            <mat-option class="text-black" *ngFor="let group of client.groups" [value]="group.id">
              {{ group.name }}
            </mat-option>
          </ng-container>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </form>
</ng-container>
<!-- [disabled]="!userOrganizationForm.controls.group.value" -->
