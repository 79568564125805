<form [formGroup]="profileForm" class="flex h-full items-center justify-between p-4 pl-[6vw] pr-[8vw]">
  <div class="flex w-64 flex-col items-center gap-6">
    <span class="text-2xl font-semibold text-main">{{ "ADMIN_USER_TABS.PROFILE_PICTURE" | translate }}</span>
    <div class="h-[10vw] w-[10vw] rounded-full border border-dialog"></div>
    <button id="profile-upload-button" class="flex h-16 w-[10vw] items-center justify-center gap-2 text-2xl colorBtn">
      <mat-icon> file_upload </mat-icon>
      <span>{{ "ADMIN_USER_TABS.UPLOAD" | translate }}</span>
    </button>
  </div>

  <div class="flex gap-4">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="mb-4 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.NAME" | translate }}</span>
        <mat-form-field appearance="outline" class="w-[16vw]">
          <input required matInput type="text" formControlName="name" name="name" />
        </mat-form-field>
      </div>
      <div class="flex flex-col">
        <span class="mb-4 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.EMAIL" | translate }}</span>
        <mat-form-field appearance="outline" class="w-[16vw]">
          <input required matInput type="email" formControlName="email" name="email" />
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="mb-4 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.SURNAME" | translate }}</span>
        <mat-form-field appearance="outline" class="w-[16vw]">
          <input required matInput type="text" formControlName="surname" name="surname" />
        </mat-form-field>
      </div>
      <div class="flex flex-col">
        <span class="mb-4 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.TELEPHONE" | translate }}</span>
        <mat-form-field appearance="outline" class="w-[16vw]">
          <input required matInput type="tel" formControlName="tel" name="tel" />
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
