<ng-container *ngIf="!(loadingProject | async); else Loading">
  <app-assign-model-page
    [isModified]="true"
    [projectToBeModified]="project | async"
    (changeSend)="formChanged($event)"
    (saveTriggered)="save($event)"
  >
  </app-assign-model-page>
</ng-container>
<ng-template #Loading>
  <app-spinner class="absolute top-1/2 left-1/2"></app-spinner>
</ng-template>
