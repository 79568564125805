import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Component, Input, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-free-text-question',
  templateUrl: './free-text-question.component.html',
  styleUrls: ['./free-text-question.component.scss'],
})
export class FreeTextQuestionComponent extends BaseComponent implements OnDestroy, OnInit {
  @Output()
  formValidity = new EventEmitter<{ questionId: string; validity: boolean }>();

  @Input()
  lang: string;
  @Input()
  freeTextForm: FormControl;
  textValue = '';

  urlPattern =
    '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})';
  emailPattern = '[a-zA-Z0-9.-_+]+@{1}[a-zA-Z.-_+]+\\.{1}[a-z]{2,}';
  numberPattern = '^[0-9]+$';
  placeholder = '';
  patternText = '';
  plainText = '';
  showDescription = false;

  constructor(private clipboardc: Clipboard, private _snackBar: MatSnackBar, public translate: TranslateService) {
    super(clipboardc);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.plainText = this.extractContent(this.question.Description).replace(/  /gi, ' ');
    this.textValue = this.freeTextForm.value['Input'];
    this.freeTextForm.setValidators(this.returnFormControlValidators());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 1500 });
  }

  private returnFormControlValidators() {
    switch (this.question.TypeOfVerificationInput) {
      case 'Email':
        this.placeholder = 'something@something.com';
        this.patternText = this.lang === 'it' ? 'Inserire un indirizzo e-mail valido' : 'Send a valid email address';
        return [this.customValidator(this.emailPattern)];
      case 'customized':
        this.patternText = this.lang === 'it' ? 'Inserire una risposta valida' : 'Insert a valid answer';
        return [this.customValidator(this.question.Reg_Ex)];
      case 'URL':
        this.placeholder = 'www.google.com';
        this.patternText = this.lang === 'it' ? 'Inserire un indirizzo URL valido' : 'Please enter a valid URL address';
        return [this.customValidator(this.urlPattern)];
      default:
        if (this.question.TypeOfVerificationInput === 'Date') {
          this.placeholder = 'yyyy-mm-dd';
        }
        return [];
    }
  }

  toggleDescriptionVisibility() {
    this.showDescription = !this.showDescription;
  }

  extractContent(stringToConvert) {
    var span = document.createElement('span');
    span.innerHTML = stringToConvert;
    return span.textContent || span.innerText;
  }

  formChanged(input: String) {
    // I use formControl only as Value of mat input
    this.freeTextForm.setValue({ Input: input });

    if (this.question.TypeOfVerificationInput !== null) {
      this.formValidity.emit({
        questionId: this.question.id,
        validity: this.freeTextForm.valid,
      });
    }
  }

  customValidator(pattern: string): ValidatorFn {
    return (): ValidationErrors => {
      if (pattern.charAt(0) === '/') {
        pattern = pattern.slice(1);
      }
      const regex = new RegExp(pattern);
      // If pattern is wrong or empty value set invalid
      return !regex.test(this.freeTextForm.value['Input']) && this.freeTextForm.value['Input'] !== ''
        ? { customValidator: false }
        : null;
    };
  }
}
