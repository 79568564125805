import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { concatMap, map, catchError, tap } from 'rxjs/operators';
import { NotifyService } from 'src/app/utils/notification.service';
import { UploadFileService } from '../service/UploadFile.service';
import { AdministrativeActions } from './administrative-page.actions';

@Injectable()
export class AdministrativeEffects {
  setTheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdministrativeActions.setTheme),
        tap((payload) => {
          let subscription = this.uploadFileService.setTheme(payload['idTheme']).subscribe(() => {
            subscription.unsubscribe();
          });
        })
      ),
    { dispatch: false }
  );

  getImageEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdministrativeActions.getImage),
      concatMap((data) =>
        this.uploadFileService.getImage(data['idImage'], data['kind']).pipe(
          map(
            (img) => {
              var binary = '';
              var bytes = new Uint8Array(img.data);
              var len = bytes.byteLength;
              for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
              }
              let image64 = window.btoa(binary);
              let objectURL = 'data:image/svg+xml;base64,' + image64;

              if (data['kind'] === 'login') {
                sessionStorage.setItem('imageLogin', objectURL);
                return {
                  type: AdministrativeActions.getImageLoginSuccess,
                  data: objectURL,
                };
              } else {
                sessionStorage.setItem('imageHeader', objectURL);
                return {
                  type: AdministrativeActions.getImageHeaderSuccess,
                  data: objectURL,
                };
              }
            },
            catchError((err) => {
              this.notifyService.openWarningSwal(err.error.message);
              return of(AdministrativeActions.getImageFailure, {
                error: err.error.message,
              });
            })
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private uploadFileService: UploadFileService,
    private notifyService: NotifyService,
    private sanitizer: DomSanitizer
  ) {}
}
