import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ErrorMessages } from 'src/app/components/login/login.component';
import { LoginService } from 'src/app/data/api/login/login.service';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { UserActions } from '../users/users.actions';
import { ActionLoginTypes } from './login.actions';

@Injectable()
export class LoginEffects {
  loginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionLoginTypes.LOGINACTION),
      mergeMap((payload: { username: string; password: string }) =>
        this.loginService.login(payload.username, payload.password).pipe(
          map((user) => {
            this.authService.setUserInfo(user);
            this.router.navigate(['/home']);
            return {
              type: ActionLoginTypes.USERlOGGEDIN,
              username: payload['username'],
            };
          }),
          catchError(() => {
            return of({
              type: ActionLoginTypes.LOGIN_FAILURE,
              error: ErrorMessages.INVALID_CREDENTIALS,
            });
          })
        )
      )
    );
  });

  UserLoggedInEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionLoginTypes.USERlOGGEDIN),
      mergeMap((payload) =>
        of(true).pipe(
          map(() => {
            localStorage.setItem('username', this.authService.getUserName());
            localStorage.setItem('token', this.authService.getUserToken());
            localStorage.setItem('abilities', this.authService.getUserAbilities().toString());
            return {
              type: UserActions.getUserByUsername,
              username: payload['username'],
            };
          }),
          catchError((err) => of(err))
        )
      )
    );
  });

  loginEffectWithSSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionLoginTypes.LOGINWITHSSO),
      mergeMap((payload: { username: string; token: string }) =>
        this.loginService.loginWithSso(payload.username, payload.token).pipe(
          map((user) => {
            this.authService.setUserInfo(user);
            this.router.navigate(['/home']);
            return {
              type: ActionLoginTypes.USERlOGGEDIN,
              username: payload['username'],
            };
          }),
          catchError(() => {
            return of({
              type: ActionLoginTypes.LOGIN_FAILURE,
              error: ErrorMessages.INVALID_CREDENTIALS,
            });
          })
        )
      )
    );
  });

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionLoginTypes.FORGOTPASSWORD),
      mergeMap((payload: { email: string }) =>
        this.loginService.forgotPassword(payload.email).pipe(
          map((result) => {
            this.notification.openSuccessSwal(
              `Your password reset email has been sent! We have sent a password reset email to youe email adress: ${payload.email}`
            );
            return { type: ActionLoginTypes.FORGOTPASSWORDSEND };
          }),
          catchError((error: HttpErrorResponse) => EMPTY)
        )
      )
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionLoginTypes.RESETPASSWORD),
      mergeMap((payload: { username: string; password: string }) =>
        this.loginService.resetPassword(payload).pipe(
          map((result) => {
            this.router.navigate(['/login']);
            return { type: ActionLoginTypes.RESETPASSWORDSEND };
          }),
          catchError((error: HttpErrorResponse) => EMPTY)
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private notification: NotifyService,
    private loginService: LoginService,
    private authService: AuthService
  ) {}
}
