import { createAction, props } from '@ngrx/store';

export const enum ActionLoginTypes {
  LOGINACTION = '[Login] login user',
  LOGINWITHSSO = '[Login] login user with user',
  FORGOTPASSWORD = '[Login] forgotPassword',
  FORGOTPASSWORDSEND = '[Login] password email send',
  RESETPASSWORD = '[Login] reset password',
  RESETPASSWORDSEND = '[Login] reset password email send',
  USERlOGGEDIN = '[Login] user logged in',
  USERLOGGOUT = '[Login] user logged OUT',
  LOGIN_FAILURE = '[Login] login failed',
}

export const login = createAction(ActionLoginTypes.LOGINACTION, props<{ username: string; password: string }>());

export const loggedIn = createAction(ActionLoginTypes.USERlOGGEDIN, props<{ username?: string }>());

export const logOut = createAction(ActionLoginTypes.USERLOGGOUT);

export const LoginWithSSo = createAction(ActionLoginTypes.LOGINWITHSSO, props<{ username: string; token: string }>());

export const login_failure = createAction(ActionLoginTypes.LOGIN_FAILURE, props<{ error: string }>());

export const forgotPassword = createAction(ActionLoginTypes.FORGOTPASSWORD, props<{ email: string }>());

export const forgotPasswordSend = createAction(ActionLoginTypes.FORGOTPASSWORDSEND);

export const resetPassword = createAction(
  ActionLoginTypes.RESETPASSWORD,
  props<{ username: string; password: string }>()
);

export const resetPasswordSend = createAction(ActionLoginTypes.RESETPASSWORDSEND);
