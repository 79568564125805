import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { findIndexAtArray } from 'src/app/data/data-handlers/utils.service';
import { ElencoTopic } from 'src/app/data/models/ElencoTopic';
import { User } from 'src/app/data/models/User';
import { englishToItalian } from 'src/app/utils/config/questionnaire-table.configuration';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { Store } from '@ngrx/store';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { TranslateService } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-create-new-topic',
  templateUrl: './create-new-topic.component.html',
  styleUrls: ['./create-new-topic.component.scss'],
  providers: [TitleCasePipe],
})
export class CreateNewTopicComponent implements OnInit, OnDestroy {
  local_data;

  formGroup = new FormGroup({
    TopicTitleFormControl: new FormControl('', [Validators.required]),
    TopicDescriptionFormControl: new FormControl(),
    readOnlyUsers: new FormControl(''),
  });

  assignmentFormGroup = new FormGroup({
    usersForAssignmentFormControl: new FormControl('', [Validators.required]),
    expiryDateFormControl: new FormControl(''),
  });

  NotesFormControl = new FormControl('');

  modalTitle: string;

  positions: number[];

  selected: number;

  usersForAssignment: User[] = [];

  expiryDate = '';

  showUsers = false;

  showNotes = false;

  users: Observable<User[]>;

  topicName: string;

  questionarioName: string;

  lang: string;
  langToUnsubscribe: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CreateNewTopicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifyService: NotifyService,
    private store: Store,
    private magicStringsService: MagicStringsService,
    private translate: TranslateService,
    private titlecase: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.topicName = this.magicStringsService.Topic;
    this.questionarioName = this.magicStringsService.Questionario;

    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
    });

    this.local_data = { ...this.data.dialogData };
    this.users = this.data.dialogData.users;

    if (this.data.dialogData.modelStatus === 'Archived') {
      this.formGroup.controls['TopicTitleFormControl'].disable();
      this.formGroup.controls['TopicDescriptionFormControl'].disable();
    }

    if (this.data.dialogData.assignedMode) {
      this.data.dialogData.users.forEach((user) => {
        if (user.username === this.data.dialogData.Topic.Assigned_To) {
          this.usersForAssignment = [
            {
              Email: user.Email,
              Name: user.Name,
              username: user.username,
              Surname: user.Surname,
            },
          ];
          return this.usersForAssignment;
        }
      });
      this.expiryDate = this.data.dialogData.Topic.Expiry_Date;
    } else {
      if (this.data.dialogData.Action === 'Modify') {
        let wordTitle: string =
          this.data.dialogData.modelStatus !== 'Archived'
            ? this.translate.instant('QUEST_CREATION.TOOL_EDIT')
            : this.translate.instant('QUEST_CREATION.TOOL_DETAILS');
        this.modalTitle = `${wordTitle} ${this.titlecase.transform(this.translate.instant('DICTIONARY.SingleTopic'))}`;

        this.positions = Array.from({ length: this.data.dialogData.length }, (_, i) => i + 1);

        this.selected = this.data.dialogData.Topic.Order;
        this.formGroup.controls['TopicTitleFormControl'].setValue(this.data.dialogData.Topic.Title);

        this.formGroup.get('TopicTitleFormControl').patchValue(this.data.dialogData.Topic.Title);
        this.formGroup.get('TopicDescriptionFormControl').patchValue(this.data.dialogData.Topic.Description);
      } else if (this.data.dialogData.Action === 'Add') {
        this.modalTitle =
          this.translate.instant('QUEST_CREATION.ADD') +
          ' ' +
          this.titlecase.transform(this.translate.instant('DICTIONARY.SingleTopic'));
        this.positions = Array.from({ length: this.data.dialogData.length + 1 }, (_, i) => i + 1);
        this.selected = this.positions.slice(-1)[0];
      }
    }
  }

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
  }

  saveTopic() {
    if (this.data.dialogData.Action === 'Modify') {
      this.dialogRef.close(
        (this.data.dialogData = {
          id: this.data.dialogData.Topic.id,
          Title: this.formGroup.get('TopicTitleFormControl').value,
          Description: this.formGroup.get('TopicDescriptionFormControl').value,
          Order: this.selected,
          Questions: this.data.dialogData.Topic.Questions,
        } as ElencoTopic)
      );
    } else if (this.data.dialogData.Action === 'Add') {
      if (this.formGroup.valid) {
        this.dialogRef.close(
          (this.data = {
            Title: this.formGroup.get('TopicTitleFormControl').value,
            Description: this.formGroup.get('TopicDescriptionFormControl').value,
            Order: this.selected,
            Questions: [],
          })
        );
      } else {
        this.formGroup.markAllAsTouched();
      }
    } else {
      this.assignmentFormGroup.get('usersForAssignmentFormControl').patchValue(this.usersForAssignment);
      this.assignmentFormGroup.get('expiryDateFormControl').patchValue(new Date(this.expiryDate).toISOString());
      if (this.assignmentFormGroup.valid) {
        this.dialogRef.close(
          (this.data = {
            Users: this.usersForAssignment,
            Expiry_Date: this.assignmentFormGroup.get('expiryDateFormControl').value,
            Top_Assign_ID: this.data.dialogData.Topic.Assigned_id,
            Notes: this.NotesFormControl.value,
            Quest_ID: this.data.dialogData.Quest_ID,
            Topic_ID: this.data.dialogData.Topic.id,
            Quest_Assing_ID: this.data.dialogData.Quest_Assing_ID,
          })
        );
      } else {
        this.notifyService.openWarningSwal(englishToItalian['Please select at least one user and an expiration date']);
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  showUsersTable($event) {
    $event.stopPropagation();
    this.showUsers = !this.showUsers;
  }

  toggleNotes() {
    this.showNotes = !this.showNotes;
  }

  getUsersForAssignment(user: User) {
    if (!this.usersForAssignment.includes(user)) {
      this.data.dialogService.openConfirmDialog({
        info: CONFIRMDIALOGSTRINGS.CHANGE_TOPIC_ASSIGNEE,
      });
      this.data.dialogService.openConfirmDialogResponse().subscribe((res) => {
        if (res !== 'Cancel') {
          this.showUsers = !this.showUsers;
          this.usersForAssignment.push(user);
          this.usersForAssignment.splice(0, 1);
        } else {
          this.showUsers = !this.showUsers;
        }
      });
    } else {
      this.usersForAssignment.splice(findIndexAtArray(this.usersForAssignment, user), 1);
      this.showUsers = !this.showUsers;
    }
  }

  getExpiryDate(event: string) {
    this.assignmentFormGroup.get('expiryDateFormControl').patchValue(new Date(event).toISOString());
    this.expiryDate = event;
  }
}
