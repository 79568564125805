import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isIncluded',
})
export class IsIncludedPipe implements PipeTransform {
  transform(value: string[], element: string): unknown {
    return value.includes(element);
  }
}
