import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prepositionDictionary',
})
export class PrepositionDictionaryPipe implements PipeTransform {
  isVowel(value: string): boolean {
    var charToCheck = value.charAt(0).toLowerCase();
    return (
      charToCheck === 'a' || charToCheck === 'e' || charToCheck === 'i' || charToCheck === 'o' || charToCheck === 'u'
    );
  }

  // function that adds chars to the preposition
  partToAdd(value: string, pluralCheck: string): string {
    var stringToCheck = '';
    var counter = value.indexOf(' '); // check number of words in string

    if (counter === -1) {
      // one word
      stringToCheck = value;
    } else {
      // 2+ words
      stringToCheck = value.substring(0, counter); // first word
    }

    var firstChar = stringToCheck.charAt(0).toLowerCase();
    var secondChar = stringToCheck.charAt(1).toLowerCase();
    var firstTwoChar = stringToCheck.substring(0, 2).toLowerCase();
    var lastChar = stringToCheck.charAt(stringToCheck.length - 1).toLowerCase();

    if (pluralCheck === 'singular') {
      // singular

      if (this.isVowel(firstChar)) {
        return "ll'" + value;
      } else {
        if (lastChar === 'a') {
          return 'lla ' + value;
        } else if (
          firstChar === 'z' ||
          firstChar === 'x' ||
          firstChar === 'y' ||
          ((firstChar === 'i' || firstChar === 'j') && this.isVowel(secondChar)) ||
          (firstChar === 's' && !this.isVowel(secondChar)) ||
          (!this.isVowel(firstChar) &&
            firstChar !== 's' &&
            !this.isVowel(secondChar) &&
            secondChar !== 'l' &&
            secondChar != 'r') ||
          firstTwoChar === 'gn' ||
          firstTwoChar === 'ps' ||
          firstTwoChar === 'pn'
        ) {
          return 'llo ' + value;
        } else {
          return 'l ' + value;
        }
      }
    } else if (pluralCheck === 'plural') {
      // plural

      if (lastChar === 'e') {
        return 'lle ' + value;
      } else if (
        this.isVowel(firstChar) ||
        firstChar === 'z' ||
        firstChar === 'x' ||
        firstChar === 'y' ||
        ((firstChar === 'i' || firstChar === 'j') && this.isVowel(secondChar)) ||
        (firstChar === 's' && !this.isVowel(secondChar)) ||
        (!this.isVowel(firstChar) &&
          firstChar !== 's' &&
          !this.isVowel(secondChar) &&
          secondChar !== 'l' &&
          secondChar != 'r') ||
        firstTwoChar === 'gn' ||
        firstTwoChar === 'ps' ||
        firstTwoChar === 'pn'
      ) {
        return 'gli ' + value;
      } else {
        return 'i ' + value;
      }
    } else {
      return value;
    }
  }

  transform(value: string, pluralCheck: string, languageCheck: string): string {
    var preposition = '';
    var counter = value.indexOf(' ');
    preposition = value.substring(0, counter).toLowerCase();
    var otherWords = value.substring(counter + 1, value.length);

    if (languageCheck === 'en') {
      return otherWords;
    }

    if (preposition === 'di') {
      return 'de' + this.partToAdd(otherWords, pluralCheck);
    } else if (preposition === 'a') {
      return 'a' + this.partToAdd(otherWords, pluralCheck);
    } else if (preposition === 'da') {
      return 'da' + this.partToAdd(otherWords, pluralCheck);
    } else if (preposition === 'in') {
      return 'ne' + this.partToAdd(otherWords, pluralCheck);
    } else if (preposition === 'su') {
      return 'su' + this.partToAdd(otherWords, pluralCheck);
    } else {
      return preposition + ' ' + otherWords;
    }
  }
}
