import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { UsernamePassword } from 'src/app/data/models/User';

@Component({
  selector: 'app-rights-tab',
  templateUrl: './rights-tab.component.html',
  styleUrls: ['./rights-tab.component.scss'],
})
export class RightsTabComponent implements OnInit {
  @Input() set missingValue(value: boolean) {
    if (value) {
      this.passwordForm.markAllAsTouched();
    }
  }
  @Output() newUsernamePassword = new EventEmitter<UsernamePassword>();

  isNewUser = false;

  passwordForm: FormGroup;

  usernamePassword: UsernamePassword;
  hide = true;
  hideConf = true;
  hideOld = true;
  samePass = false;

  get primPassword() {
    return this.passwordForm.get('new_password');
  }

  get repeatPassword() {
    return this.passwordForm.get('repeatPassword');
  }

  constructor() {}

  ngOnInit(): void {
    this.passwordForm = new FormGroup({
      username: new FormControl(null, {
        validators: [Validators.required],
      }),
      new_password: new FormControl(null, {
        validators: [Validators.required],
      }),
      repeatPassword: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    if (window.location.pathname === '/create-user') {
      this.isNewUser = true;
    } else {
      this.passwordForm.addControl(
        'old_password',
        new FormControl(null, {
          validators: [Validators.required],
        })
      );
    }

    this.passwordForm.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
      if (this.passwordForm.valid) {
        this.usernamePassword = {
          username: this.passwordForm.value.username,
          password: this.passwordForm.value.new_password,
        };
        this.newUsernamePassword.emit(this.usernamePassword);
      } else {
        this.newUsernamePassword.emit({
          username: null,
          password: null,
        });
      }
    });
  }

  setHide(event) {
    event.preventDefault();
    this.hide = !this.hide;
  }

  setHideConf(event) {
    event.preventDefault();
    this.hideConf = !this.hideConf;
  }
  setHideOld(event) {
    event.preventDefault();
    this.hideOld = !this.hideOld;
  }

  checkPassValues() {
    let password = this.passwordForm.get('new_password').value;
    let repeatpass = this.passwordForm.get('repeatPassword').value;

    if (password === repeatpass) {
      this.samePass = true;
      this.passwordForm.get('new_password').setErrors({ differentPass: null });
      this.passwordForm.get('repeatPassword').setErrors({ differentPass: null });
      this.passwordForm.get('new_password').updateValueAndValidity();
      this.passwordForm.get('repeatPassword').updateValueAndValidity();
      this.passwordForm.get('new_password').markAsTouched();
      this.passwordForm.get('repeatPassword').markAsTouched();
    } else {
      this.samePass = false;
      this.passwordForm.get('new_password').setErrors({ differentPass: true });
      this.passwordForm.get('repeatPassword').setErrors({ differentPass: true });
      this.passwordForm.get('new_password').markAsTouched();
      this.passwordForm.get('repeatPassword').markAsTouched();
    }
  }
}
