<div class="rounded-[10px] min-h-[70vh] min-w-[85vh] p-8 flex flex-col bg-white justify-between container-modifica">
  <div class="text-3xl text-dialog font-black mb-4">{{ popupTitle }}</div>
  <div class="user-table-div">
    <app-users-table
      class=""
      (userToBeAssigned)="getUser($event)"
      [singleSelection]="true"
      [selectedUsers]="selectedUser"
      [mode]="true"
      [disabled]="false"
    >
    </app-users-table>
  </div>

  <div class="mb-[20px] pt-1 flex flex-col gap-2">
    <h3 class="text-welcome font-semibold text-lg">{{ popupNoteTitle }}</h3>
    <div class="w-full text-area">
      <textarea
        class="p-4 h-24 border-[1px] border-[#EEEEEE] bg-[#FDFDFD] rounded-md w-full text-main"
        [formControl]="textArea"
        name="question.id"
        rows="3"
        rows="1"
        cols="50"
      ></textarea>
    </div>
  </div>
  <div class="flex justify-end">
    <button (click)="cancel()" class="whiteBtn mr-3">
      {{ "ANNULLA" | translate }}
    </button>
    <button (click)="reAssign()" class="colorBtn">
      {{ "CONFERMA" | translate }}
    </button>
  </div>
</div>
