import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, mergeMap } from 'rxjs/operators';
import * as ProjectActions from '../actions/projects.actions';
import { ActionTypes } from '../../questionnaire-store/state.actions';
import { Router } from '@angular/router';
import { GetProjectService } from 'src/app/data/api/Projects/get-projects.service';
import { PostProjectService } from 'src/app/data/api/Projects/post-project.service';
import { PostQuestionnaireService } from 'src/app/data/api/Questionnaire/post-questionnaire.service';
import { convertToAssignRequest } from 'src/app/data/data-handlers/utils.service';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { englishToItalian } from 'src/app/utils/config/questionnaire-table.configuration';
import { ProjectActionTypes } from '../actions/projects.actions';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable()
export class ProjectEffects {
  //bellow all project effects

  exportProjectZip$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActionTypes.exportProjectZipType),
        tap((payload) =>
          this.getProjectService.getProjectZip(
            payload['data'][0],
            payload['lang'],
            payload['topic'],
            payload['downloadFiles']
          )
        )
      ),
    { dispatch: false }
  );

  getAllProjectsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ProjectActions.getAllProjects,
        ProjectActions.archiveProjectSuccess,
        ProjectActions.deleteProjectSuccess,
        ProjectActions.addProjectWatchListSuccess
      ),
      mergeMap((payload) =>
        this.getProjectService
          .getAllProjects(
            !!payload['pageIndex'] ? payload['pageIndex'] : 0,
            10,
            !!payload['title'] ? payload['title'] : 'TITLE',
            !!payload['AscDesc'] ? payload['AscDesc'] : 'ASC',
            !!payload['allStatus'] ? payload['allStatus'] : '',
            !!payload['archived'] ? payload['archived'] : '',
            !!payload['searchString'] ? payload['searchString'] : '',
            !!payload['modelID'] ? payload['modelID'] : '',
            !!payload['modelFilter'] ? payload['modelFilter'] : '',
            !!payload['creatorFilter'] ? payload['creatorFilter'] : '',
            !!payload['statusFilter'] ? payload['statusFilter']['draft'] : '',
            !!payload['statusFilter'] ? payload['statusFilter']['active'] : '',
            !!payload['statusFilter'] ? payload['statusFilter']['closed'] : '',
            payload['expireFromFilter'] ? payload['expireFromFilter'] : '',
            payload['expireToFilter'] ? payload['expireToFilter'] : '',
            payload['creationFromFilter'] ? payload['creationFromFilter'] : '',
            payload['creationToFilter'] ? payload['creationToFilter'] : ''
          )
          .pipe(
            map((data) => ({
              type: ProjectActionTypes.getAllProjectsSuccess,
              Projects: data['data'],
              total: data['length'],
              pageIndex: data['pageIndex'],
              deleteMode: payload['deleteMode'],
            })),
            catchError((err) => {
              this.notifyService.openWarningSwal(err);
              return of(ProjectActions.loadProjectsFailure({ error: err }));
            })
          )
      )
    );
  });

  // getAllProjectsEffect$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(
  //       ProjectActions.getAllProjects,
  //       ProjectActions.archiveProjectSuccess,
  //       ProjectActions.deleteProjectSuccess,
  //       ProjectActions.addProjectWatchListSuccess
  //     ),
  //     concatMap((payload) =>
  //       this.getProjectService
  //         .getAllProjects(
  //           !!payload['pageIndex'] ? payload['pageIndex'] : 0,
  //           10,
  //           !!payload['title'] ? payload['title'] : 'TITLE',
  //           !!payload['AscDesc'] ? payload['AscDesc'] : 'ASC',
  //           !!payload['archived'] ? payload['archived'] : '',
  //           !!payload['searchString'] ? payload['searchString'] : '',
  //           !!payload['modelID'] ? payload['modelID'] : '',
  //           !!payload['modelFilter'] ? payload['modelFilter'] : '',
  //           !!payload['creatorFilter'] ? payload['creatorFilter'] : '',
  //           !!payload['statusFilter'] ? payload['statusFilter']['draft'] : '',
  //           !!payload['statusFilter'] ? payload['statusFilter']['active'] : '',
  //           !!payload['statusFilter'] ? payload['statusFilter']['closed'] : '',
  //           payload['expireFromFilter'] ? payload['expireFromFilter'] : '',
  //           payload['expireToFilter'] ? payload['expireToFilter'] : '',
  //           payload['creationFromFilter'] ? payload['creationFromFilter'] : '',
  //           payload['creationToFilter'] ? payload['creationToFilter'] : ''
  //         )
  //         .pipe(
  //           map((data) => ({
  //             type: ProjectActionTypes.getAllProjectsSuccess,
  //             Projects: data['data'],
  //             total: data['length'],
  //             pageIndex: data['pageIndex'],
  //             deleteMode: payload['deleteMode'],
  //             modelId: payload['modelId'],
  //           })),
  //           catchError((err) => {
  //             this.notifyService.openWarningSwal(err);
  //             return of(ProjectActions.loadProjectsFailure({ error: err }));
  //           })
  //         )
  //     )
  //   );
  // });

  cloneProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.cloneProject),
      concatMap((payload) =>
        this.postProjectService
          .cloneProject(payload.projectId, payload.title, payload.description, payload.options)
          .pipe(
            map((data) => ({
              type: ProjectActionTypes.cloneProjectSuccess,
              projectId: data['clonedProject']['PK_PROJECT'],
            })),
            catchError((err) => {
              this.notifyService.openWarningSwal(err.error.message);
              return of(ProjectActions.loadProjectsFailure({ error: err.error.message }));
            })
          )
      )
    );
  });

  cloneProjectSucessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.cloneProjectSuccess),
      mergeMap((payload) =>
        of(true).pipe(
          map(() => {
            this.route.navigateByUrl(`/${ROUTES_STRINGS.MODIFYPROJECT}/${payload['projectId']}`);
            this.generalDialog.cloneProjectModal.close();
            return { type: ActionTypes.REDIRECT_DONE };
          }),
          catchError((err) => of(err))
        )
      )
    );
  });

  singleProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getSingleProject),
      concatMap((payload) =>
        this.getProjectService.getSingleProject(payload.projectId).pipe(
          map((data) => ({
            type: ProjectActionTypes.getSingleProjectsSuccess,
            project: data['project'],
          })),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, project could not be retrieved');
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  analyticsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getAnalyticsData),
      concatMap((payload) =>
        this.getProjectService
          .getAnalyticsData(
            payload.projectId,
            payload.questionnairesAssignedIDs ? payload.questionnairesAssignedIDs : null,
            payload.topicsIDs ? payload.topicsIDs : null,
            payload.questionsIDs ? payload.questionsIDs : null
          )
          .pipe(
            map((data) => ({
              type: ProjectActionTypes.getAnalyticsDataSuccess,
              analyticsData: data['analytics'],
            })),
            catchError((err) => {
              this.notifyService.openWarningSwal('Error occured, Analytics Data could not be retrieved');
              return of(ProjectActions.loadProjectsFailure({ error: err }));
            })
          )
      )
    );
  });

  updateDraftProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.updateDraftProjectAction),
      concatMap((payload) =>
        this.postProjectService.updateDraftProject(payload.project).pipe(
          map(() => {
            return { type: ProjectActionTypes.updateDraftProjectSuccess };
          }),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  updateActiveProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.updateActiveProject),
      concatMap((payload) =>
        this.postProjectService.updateActiveProject(payload.project).pipe(
          map((data) => ({
            type: ProjectActionTypes.updateactiveProjectSuccess,
          })),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  changeProjectResponsible$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.changeResponsible),
      concatMap((payload) =>
        this.postProjectService.changeUserResponsible(payload).pipe(
          map((data) => ({
            type: ProjectActionTypes.changeResponsibleSuccess,
          })),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, project could not be updated');
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  archiveProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.archiveProject),
      concatMap((payload) =>
        this.postProjectService.archiveProject(payload.projectId).pipe(
          map((data) => ({
            type: ProjectActionTypes.getAllProjects,
            pageIndex: payload['pageIndex'],
            title: payload['title'],
            AscDesc: payload['AscDesc'],
            allStatus: payload['allStatus'],
            archived: payload['archived'],
            searchString: payload['searchString'],
            modelID: payload['modelID'],
            modelFilter: payload['modelFilter'],
            creatorFilter: payload['creatorFilter'],
            statusFilter: payload['statusFilter'],
            expireFromFilter: payload['expireFromFilter'],
            expireToFilter: payload['expireToFilter'],
            creationFromFilter: payload['creationFromFilter'],
            creationToFilter: payload['creationToFilter'],
          })),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, project could not be archived');
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  deleteProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.deleteProject),
      mergeMap((payload) =>
        this.postProjectService.deleteProject(payload.project_ids).pipe(
          map((response) => {
            this.store.dispatch({
              type: ActionTypes.reduceNumberOfProjects, project_ids: payload['project_ids'], questionnairesReduceProject: payload['questionnairesReduceProject'],
            });
            return {
              type: ProjectActionTypes.getAllProjects,
              pageIndex: payload['pageIndex'],
              title: payload['title'],
              AscDesc: payload['AscDesc'],
              allStatus: payload['allStatus'],
              archived: payload['archived'],
              searchString: payload['searchString'],
              modelID: payload['modelID'],
              modelFilter: payload['modelFilter'],
              creatorFilter: payload['creatorFilter'],
              statusFilter: payload['statusFilter'],
              expireFromFilter: payload['expireFromFilter'],
              expireToFilter: payload['expireToFilter'],
              creationFromFilter: payload['creationFromFilter'],
              creationToFilter: payload['creationToFilter'],
            };
          }),
          catchError((err) => {
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  closeProjectEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.closeProjectAction),
      concatMap((payload) =>
        this.postProjectService.closeProject(payload.projectId).pipe(
          map(
            (data) => (
              this.route.navigateByUrl(`/${ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT}​​​​​`),
              { type: ProjectActionTypes.closeProjectSuccess }
            )
          ),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, project could not be closed');
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  publishProjectEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.publishProjectAction),
      concatMap((payload) =>
        this.postProjectService.publishProject(payload.projectId, payload.projectTitle).pipe(
          map((data) => ({ type: ProjectActionTypes.publishProjectSuccess })),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  publishProjectSpecialCaseEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.publishProjectNoOrMoreUsers),
      concatMap((payload) =>
        this.postProjectService
          .publishProjectNoUsersBeforeOrMore(payload.projectId, convertToAssignRequest(payload.payload))
          .pipe(
            map(
              () => (
                this.route.navigateByUrl(`${ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT}​​​​​`),
                { type: ProjectActionTypes.publishProjectNoOrMoreUsersSuccess }
              )
            ),
            catchError((err) => {
              this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
              return of(ProjectActions.loadProjectsFailure({ error: err }));
            })
          )
      )
    );
  });

  addToProjectWatchListEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.addProjectWatchList),
      concatMap((payload) =>
        this.postProjectService.addToProjectWatchList(payload).pipe(
          map(() => ({ type: ProjectActionTypes.addProjectWatchListSuccess })),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  notifyUnfinsihedProjectsResponsibles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.notifyAllResponsiblesAction),
      concatMap((payload) =>
        this.postProjectService.notifyUncompletedProjectsResponsibles(payload).pipe(
          map(() => ({
            type: ProjectActionTypes.notifyAllResponsiblesSuccess,
          })),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  publishProjectDirectlyEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.publishProjectDirectly),
      concatMap((payload) =>
        this.postProjectService.publishProjectDirectly(convertToAssignRequest(payload.payload)).pipe(
          map(
            () => (
              this.route.navigateByUrl('questionnaires-management'),
              { type: ProjectActionTypes.publishProjectDirectlySuccess }
            )
          ),
          catchError((err) => {
            this.notifyService.openWarningSwal(englishToItalian[err.error.message]);
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  setAssignedQuestionnairess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.createProjectAction),
      concatMap((payload) =>
        this.postQuestService
          .assignQuestionnaires({
            project: convertToAssignRequest(payload.payload),
            flag: payload.flag,
          })
          .pipe(
            map(
              (quests) => (
                this.route.navigateByUrl(`/${ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT}​​​​​`),
                { type: ProjectActionTypes.createProjectSuccess }
              )
            ),
            catchError((err) => {
              if (err.error.message.includes('This project already exists')) {
                this.notifyService.openWarningSwal('Impossibile assegnare il modello, il progetto è già esistente!');
              } else {
                this.notifyService.openWarningSwal('ERRORE: progetto già associato ad un modello!');
              }

              return of({ type: ProjectActionTypes.createProjectFailAction });
            })
          )
      )
    );
  });

  changeOwner = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.changeOwner),
      mergeMap((payload) =>
        this.postQuestService.submitChangeProjectOwner(payload).pipe(
          map((data) => {
            return { type: ProjectActionTypes.changeOwnerSuccess, data: data };
          }),
          catchError((err) => {
            this.notifyService.openWarningSwal('Errore, Cambio Gestore Progetto fallito');
            return of(ProjectActions.loadProjectsFailure({ error: err }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private route: Router,
    private generalDialog: GeneralDialogService,
    private getProjectService: GetProjectService,
    private postProjectService: PostProjectService,
    private postQuestService: PostQuestionnaireService,
    private notifyService: NotifyService,
    private store: Store
  ) {}
}
