import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AdministrativeActions } from '../features/administrative/store/administrative-page.actions';

@Injectable({
  providedIn: 'root',
})
export class MagicStringsService {
  update = false;
  IdTheme = '';

  AppName = '';
  SingleTopic = '';
  Topic = '';
  Responsabile = '';
  Responsabili = '';
  Questionario = '';
  Questionari = '';
  Modello = '';
  Modelli = '';
  Progetto = '';
  Progetti = '';
  Welcome = '';
  ImageLogin = '';
  ImageHeader = '';

  AppNameENG = '';
  SingleTopicENG = '';
  TopicENG = '';
  ResponsabileENG = '';
  ResponsabiliENG = '';
  QuestionarioENG = '';
  QuestionariENG = '';
  ModelloENG = '';
  ModelliENG = '';
  ProgettoENG = '';
  ProgettiENG = '';
  WelcomeENG = '';
  ImageLoginENG = '';
  ImageHeaderENG = '';

  constructor(private store: Store, private readonly translate: TranslateService) {}

  initialize(data: any): boolean {
    for (const element of data) {
      if (element['pk_custom'] === 'ImageLogin' || element['pk_custom'] === 'ImageHeader') {
        var binary = '';
        var bytes = new Uint8Array(element['value'].data);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        let image64 = window.btoa(binary);
        let objectURL = 'data:image/svg+xml;base64,' + image64;
        if (element['pk_custom'] === 'ImageLogin') {
          this.ImageLogin = objectURL;
          sessionStorage.setItem('ImageLogin', objectURL);
        } else if (element['pk_custom'] === 'ImageHeader') {
          this.ImageHeader = objectURL;
          sessionStorage.setItem('ImageHeader', objectURL);
        }
      } else {
        sessionStorage.setItem(element['pk_custom'], element['value']);
        this[element['pk_custom']] = element['value'];
      }
    }
    this.store.dispatch({
      type: AdministrativeActions.setImage,
      logoLogin: this.ImageLogin,
      logoHeader: this.ImageHeader,
    });

    return true;
  }

  refresh(): boolean {
    if (this.update) {
      this.update = false;
      this.IdTheme = sessionStorage.getItem('IdTheme');
      // need translation
      this.AppName = sessionStorage.getItem('AppName');
      this.SingleTopic = sessionStorage.getItem('SingleTopic');
      this.Topic = sessionStorage.getItem('Topic');
      this.Responsabili = sessionStorage.getItem('Responsabili');
      this.Responsabile = sessionStorage.getItem('Responsabile');
      this.Questionario = sessionStorage.getItem('Questionario');
      this.Questionari = sessionStorage.getItem('Questionari');
      this.Modello = sessionStorage.getItem('Modello');
      this.Modelli = sessionStorage.getItem('Modelli');
      this.Progetto = sessionStorage.getItem('Progetto');
      this.Progetti = sessionStorage.getItem('Progetti');
      this.Welcome = sessionStorage.getItem('Welcome');

      let i18nENG = require(`../../assets/i18n/en.json`);
      let i18nIT = require(`../../assets/i18n/it.json`);

      let engDictionary = {
        'DICTIONARY.AppName': this.AppNameENG,
        'DICTIONARY.SingleTopic': this.SingleTopicENG,
        'DICTIONARY.Topic': this.TopicENG,
        'DICTIONARY.Responsabili': this.ResponsabiliENG,
        'DICTIONARY.Responsabile': this.ResponsabileENG,
        'DICTIONARY.Questionario': this.QuestionarioENG,
        'DICTIONARY.Questionari': this.QuestionariENG,
        'DICTIONARY.Modello': this.ModelloENG,
        'DICTIONARY.Modelli': this.ModelliENG,
        'DICTIONARY.Progetto': this.ProgettoENG,
        'DICTIONARY.Progetti': this.ProgettiENG,
        'DICTIONARY.Welcome': this.WelcomeENG,
      };

      let itDictionary = {
        'DICTIONARY.AppName': this.AppName,
        'DICTIONARY.SingleTopic': this.SingleTopic,
        'DICTIONARY.Topic': this.Topic,
        'DICTIONARY.Responsabili': this.Responsabili,
        'DICTIONARY.Responsabile': this.Responsabile,
        'DICTIONARY.Questionario': this.Questionario,
        'DICTIONARY.Questionari': this.Questionari,
        'DICTIONARY.Modello': this.Modello,
        'DICTIONARY.Modelli': this.Modelli,
        'DICTIONARY.Progetto': this.Progetto,
        'DICTIONARY.Progetti': this.Progetti,
        'DICTIONARY.Welcome': this.Welcome,
      };

      this.translate.setTranslation('en', { ...i18nENG, ...engDictionary }, false);

      this.translate.setTranslation('it', { ...i18nIT, ...itDictionary }, false);

      return true;
    }
  }
}
