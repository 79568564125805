<div class="scale-95 transition duration-100 ease-in-out hover:scale-100">
  <div
    id="single-quest"
    class="h-[26rem] w-[31rem] rounded-3xl bg-card-gradient p-0 shadow-md"
    [ngClass]="{ 'reponsible-quest': isCurrentUserResponsible }"
  >
    <div id="card-header" class="h-[30%] px-6 pt-6 pb-2">
      <div
        id="card-ribbon"
        *ngIf="(currentDate | date: 'yyyy-MM-dd') > questionnaire.expiryDate.split('T')[0]"
        class="group absolute left-0 top-0 w-0 rounded-tl-3xl bg-ribbon-gradient p-8 transition-all duration-300 hover:w-full"
      >
        <div class="absolute top-0 left-0 flex items-center justify-center gap-4 p-1">
          <mat-icon class="!text-3xl text-white">watch_later</mat-icon>
          <p class="text-2xl text-white opacity-0 transition-all duration-300 group-hover:opacity-100">
            {{ lang === "it" ? "Scaduto" : "Expired" }}!
          </p>
        </div>
      </div>
      <div id="title-quest" class="flex h-1/2 items-center justify-between text-2xl text-white">
        <h2
          [matTooltip]="questionnaire.projectTitle"
          [matTooltipPosition]="'above'"
          class="max-w-xs truncate"
          matTooltipClass="custom-tooltip"
          appShowIfTruncated
        >
          {{ questionnaire.projectTitle }}
        </h2>
        <div classs="flex">
          <button (click)="getNotes()" mat-icon-button>
            <link
              href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined"
              rel="stylesheet"
            />
            <mat-icon
              fontSet="material-icons-outlined"
              [matBadge]="this.numberOfUnreadedNotes"
              matTooltip="{{ 'HOME_PAGE.HOME_QUESTIONNAIRE.TOOLTIP_NOTES' | translate }}"
              matTooltipClass="custom-tooltip"
              class="!inline-flex !items-center"
              >sticky_note_2
            </mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon
              fontSet="material-icons-outlined"
              matTooltip="{{ 'HOME_PAGE.HOME_QUESTIONNAIRE.TOOLTIP_ACTIONS' | translate }}"
              matTooltipClass="custom-tooltip"
              class="!inline-flex !items-center"
              >more_vert
            </mat-icon>
            <mat-menu #menu="matMenu">
              <div id="quest-actions">
                <div>
                  <button (click)="goToAnwswer()" mat-menu-item class="home-actions">
                    <mat-icon class="!inline-flex !items-center">launch</mat-icon>
                    <span>{{ "HOME_PAGE.HOME_QUESTIONNAIRE.ACTION_LAUNCH" | translate | titlecase }}</span>
                  </button>
                </div>
                <div>
                  <button
                    *ngIf="questionnaire.TopicsOfUsers !== '0' || isCurrentUserResponsible"
                    (click)="openCompleteModal()"
                    mat-menu-item
                    class="home-actions"
                  >
                    <mat-icon class="!inline-flex !items-center">done_outline</mat-icon>
                    <span>{{ "HOME_PAGE.HOME_QUESTIONNAIRE.ACTION_DONE" | translate | titlecase }}</span>
                  </button>
                  <button
                    *ngIf="isCurrentUserResponsible && questionnaire.RESPONSIBLE_REASSIGN"
                    (click)="changeResponsiblePopUp()"
                    mat-menu-item
                    class="home-actions"
                  >
                    <mat-icon class="!inline-flex !items-center">account_circle</mat-icon>
                    <span
                      >{{ "HOME_PAGE.HOME_QUESTIONNAIRE.ACTION_USER" | translate | titlecase }}
                      {{ "DICTIONARY.Responsabile" | titlecase }}</span
                    >
                  </button>
                  <button
                    *ngIf="
                      (isCurrentUserResponsible ||
                        !(
                          (questionnaire.COLLABORATIVE && questionnaire.TopicsOfUsers !== '0') ||
                          isCurrentUserResponsible
                        )) &&
                      questionnaire.EXPORTABLE_DATA
                    "
                    (click)="toggleExportVisibility()"
                    mat-menu-item
                    class="home-actions"
                  >
                    <mat-icon class="!inline-flex !items-center">file_download</mat-icon>
                    <span>{{ "HOME_PAGE.HOME_QUESTIONNAIRE.ACTION_EXPORT" | translate }}</span>
                  </button>
                  <button (click)="goToFileRepo()" mat-menu-item class="home-actions">
                    <mat-icon class="!inline-flex !items-center">folder</mat-icon>
                    <span>File Repository</span>
                  </button>
                </div>
              </div>
            </mat-menu>
          </button>
        </div>
      </div>
      <div id="deadline" class="flex h-1/2 items-center text-base text-deadline">
        <mat-icon class="!inline-flex !items-center">schedule</mat-icon>
        <span id="deadline-text" class="ml-2 flex items-center font-semibold">
          {{ questionnaire.expiryDate.split("T")[0] }}
        </span>
      </div>
      <div
        id="home-icons"
        class="ml-[17rem] -mt-4 flex w-32 justify-between text-login"
        [ngClass]="{
          'ml-60 w-4': isCurrentUserResponsible && questionnaire.COLLABORATIVE && questionnaire.TopicsOfUsers === '0'
        }"
      >
        <div
          *ngIf="isCurrentUserResponsible"
          id="white-circle"
          class="align-center flex justify-center rounded-full bg-white p-1.5"
        >
          <mat-icon id="gray-inside" class="!inline-flex !items-center !justify-center rounded-full bg-[#eeeeee] p-1">
            star
          </mat-icon>
        </div>
        <div
          *ngIf="questionnaire.COLLABORATIVE"
          id="white-circle"
          class="align-center flex justify-center rounded-full bg-white p-1.5"
        >
          <mat-icon id="gray-inside" class="!inline-flex !items-center !justify-center rounded-full bg-[#eeeeee] p-1">
            groups
          </mat-icon>
        </div>
        <div
          *ngIf="questionnaire.TopicsOfUsers === '0'"
          id="white-circle"
          class="align-center flex justify-center rounded-full bg-white p-1.5"
        >
          <mat-icon id="gray-inside" class="!inline-flex !items-center !justify-center rounded-full bg-[#eeeeee] p-1">
            fact_check
          </mat-icon>
        </div>
      </div>
    </div>
    <div id="container" class="mt-2 grid h-[70%] grid-cols-2 p-6">
      <div id="left-row" class="grid h-full grid-rows-[repeat(3,auto)] justify-items-start">
        <div id="mat-icon-with-text" class="self-start text-main">
          <span id="card-title" class="flex items-center">
            <mat-icon fontSet="material-icons-outlined">assignment</mat-icon>
            <h3 id="card-text" class="ml-2 text-base font-semibold">
              {{ "HOME_PAGE.HOME_QUESTIONNAIRE.CARD_TEXT_ONE" | translate | titlecase }}
            </h3>
          </span>
          <p *ngIf="questionnaire.questTitle.length <= 28" class="font-opensans text-base text-main">
            {{ questionnaire.questTitle }}
          </p>
          <p
            *ngIf="questionnaire.questTitle.length > 28"
            class="max-w-[14rem] truncate font-opensans text-base text-main"
            [matTooltip]="questionnaire.questTitle"
            [matTooltipPosition]="'above'"
            matTooltipClass="custom-tooltip"
          >
            {{ questionnaire.questTitle }}
          </p>
        </div>
        <div id="mat-icon-with-text" class="self-center text-main">
          <span id="card-title" class="flex items-center">
            <mat-icon>task_alt</mat-icon>
            <h3 id="card-text" class="ml-2 text-base font-semibold">
              {{ "HOME_PAGE.HOME_QUESTIONNAIRE.CARD_TEXT_TWO" | translate | titlecase }}
            </h3>
          </span>
          <p *ngIf="typeOfPopUp === 'ResponsibleAndFinished'" class="font-opensans text-base text-main">
            {{ "DICTIONARY.Topic" | translate | titlecase }}
            {{ "completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":lang
            }}{{ "HOME_PAGE.HOME_QUESTIONNAIRE.COMPLETED" | translate }},
            {{ "DICTIONARY.Questionario" | translate | lowercase }}
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.TO_BE_CONFIRMED" | translate }}
          </p>

          <p *ngIf="typeOfPopUp === 'notResponsibleAndfinished'" class="font-opensans text-base text-main">
            {{ "DICTIONARY.Topic" | translate | titlecase }}
            {{ "completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":lang
            }}{{ "HOME_PAGE.HOME_QUESTIONNAIRE.COMPLETED" | translate }},
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.COMPLETE_TO_REASSIGN" | translate }}
            {{ "a " + ("DICTIONARY.Responsabile" | translate) | titlecase | prepositionDictionary: "singular":lang }}
          </p>

          <p *ngIf="typeOfPopUp === 'watchlistFinished'" class="font-opensans text-base text-main">
            {{ "DICTIONARY.Topic" | translate | titlecase }}
            {{ "completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":lang
            }}{{ "HOME_PAGE.HOME_QUESTIONNAIRE.COMPLETED" | translate }},
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.THE" | translate
            }}{{ "DICTIONARY.Responsabile" | translate | titlecase | articleDictionary: "singular":lang }}
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.MUST_CONFIRM" | translate }}
            {{ "DICTIONARY.Questionario" | translate | lowercase | articleDictionary: "singular":lang }}
          </p>

          <p
            *ngIf="
              typeOfPopUp === 'watchlistUnFinished' ||
              typeOfPopUp === 'notResponsibleAndUnfinished' ||
              typeOfPopUp === 'ResponsibleAndUnfinished'
            "
            class="font-opensans text-base text-main"
          >
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.THERE_ARE" | translate }}
            {{ "di
            " + ("DICTIONARY.Topic" | translate) | lowercase | prepositionDictionary: "plural":lang }}
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.TO_FILL_IN" | translate }}
          </p>
        </div>
        <div id="mat-icon-with-text" class="self-end text-main">
          <span id="card-title" class="flex items-center">
            <mat-icon>person_outline</mat-icon>
            <h3 id="card-text" class="ml-2 text-base font-semibold">
              {{ "DICTIONARY.Responsabile" | translate | titlecase }}
            </h3>
          </span>
          <p class="font-opensans text-base text-main">
            {{
              isCurrentUserResponsible
                ? ("HOME_PAGE.HOME_QUESTIONNAIRE.YOU_ARE" | translate) +
                  ("DICTIONARY.Responsabile" | translate | titlecase | articleDictionary: "singular":lang)
                : users[questionnaire.responsible]
            }}
          </p>
        </div>
      </div>
      <div id="right-row" class="grid h-full grid-rows-[25%,50%,25%] items-center justify-items-center">
        <div id="topic-top" class="self-start pt-1 text-center text-base font-bold text-main">
          {{ "DICTIONARY.Topic" | translate | titlecase }}
          {{ "Completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":lang
          }}{{ "HOME_PAGE.HOME_QUESTIONNAIRE.COMPLETED" | translate | titlecase }}
        </div>
        <div id="progress-circle" class="relative my-0 mx-auto flex h-full w-full items-center justify-center">
          <mat-progress-spinner
            id="track-spinner"
            class="!absolute opacity-30"
            mode="determinate"
            diameter="85"
            value="100"
            color="black"
          >
          </mat-progress-spinner>
          <mat-progress-spinner
            id="over-spinner"
            class="!absolute"
            mode="determinate"
            diameter="85"
            [value]="percentage"
          >
          </mat-progress-spinner>
          <div *ngIf="questionnaire.TopicsOfUsers === '0' || isCurrentUserResponsible; else notRespTemplate">
            <h1 id="topic-sum" class="my-0 mx-auto text-3xl font-bold text-main">
              {{ questionnaire.Completed_Topics }}/{{ questionnaire.Number_Of_Topics }}
            </h1>
          </div>
          <ng-template #notRespTemplate>
            <h1 id="topic-sum" class="my-0 mx-auto text-3xl font-bold text-main">
              {{ questionnaire["CompletedTopicsOfUsers"] }}/{{ questionnaire.TopicsOfUsers }}
            </h1>
          </ng-template>
        </div>
        <div
          id="topic-bottom"
          class="self-end text-center text-base font-bold text-dialog"
          *ngIf="(questionnaire.COLLABORATIVE && questionnaire.TopicsOfUsers !== '0') || isCurrentUserResponsible"
        >
          <p *ngIf="1.01 > (completedTopics | async) && 0.99 < (completedTopics | async)">
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.THERE_IS" | translate }}
            {{ completedTopics | async }}
            {{ "DICTIONARY.SingleTopic" | translate | lowercase }}
            {{ "assegnato " + ("DICTIONARY.SingleTopic" | translate) | verbPronounDictionary: "singular":lang
            }}{{ "HOME_PAGE.HOME_QUESTIONNAIRE.ASSIGNED_TO_YOU" | translate | lowercase }}
          </p>
          <p *ngIf="1 < (completedTopics | async)">
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.TOPIC_DESCRIPTION_PLURAL" | translate }}
            {{ completedTopics | async }}
            {{ "DICTIONARY.Topic" | translate | lowercase }}
            {{ "assegnato
            " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":lang
            }}{{ "HOME_PAGE.HOME_QUESTIONNAIRE.ASSIGNED_TO_YOU" | translate | lowercase }}
          </p>
        </div>
        <div
          id="topic-bottom"
          class="self-end text-center text-base font-bold text-dialog"
          *ngIf="!((questionnaire.COLLABORATIVE && questionnaire.TopicsOfUsers !== '0') || isCurrentUserResponsible)"
        >
          <p>
            {{ "HOME_PAGE.HOME_QUESTIONNAIRE.WATCHLISTER" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div
      *ngIf="showResponsiblePopUp"
      class="overlayHidden"
      [ngClass]="{ overlayShow: showResponsiblePopUp }"
      (click)="changeResponsiblePopUp()"
    >
      <changeUserOrAdd
        [selectedUsers]="[questionnaire.responsible]"
        (hideTableEvent)="showResponsiblePopUp = false"
        [isModified]="false"
        [selectionMode]="true"
        (changeResponsibleEvent)="changeResponsible($event)"
      >
      </changeUserOrAdd>
    </div>
  </div>
</div>

<!-- Export Popup -->
<div
  id="exportPopup"
  *ngIf="showExportPopup"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showExportPopup }"
  (click)="toggleExportVisibility()"
>
  <div (click)="$event.stopPropagation()">
    <div>
      <mat-card class="flex w-full justify-start !rounded-xl bg-white !p-10">
        <ng-container *ngIf="!(loadingFileRepo$ | async) && (containsFile$ | async)">
          <div class="flex flex-col items-start gap-8">
            <div class="flex items-start justify-between">
              <p class="font-poppins text-3xl font-black tracking-normal text-dialog">Export Questionnaire</p>
            </div>
            <div class="flex items-center justify-start gap-6">
              <mat-icon class="!text-dialog">error</mat-icon>
              <label id="export-radio-group-label" class="text-xl text-welcome">Choose what you want to export:</label>
            </div>
            <mat-radio-group aria-labelledby="export-radio-group-label" class="flex gap-4" [(ngModel)]="exportMethod">
              <mat-radio-button *ngFor="let method of exMethodsArr" [value]="method" disableRipple="true">
                <p class="text-xl">{{ method }}</p>
              </mat-radio-button>
            </mat-radio-group>
            <div id="popup-buttons" class="flex w-full items-center justify-end gap-4">
              <button class="!whiteBtn" (click)="toggleExportVisibility()">
                {{ "ANNULLA" | translate }}
              </button>
              <button class="colorBtn" (click)="exportSingleCsv(questionnaire, exportMethod); toggleExportVisibility()">
                {{ "CONFERMA" | translate }}
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="loadingFileRepo$ | async">
          <app-spinner [width]="70" [height]="70"></app-spinner>
        </ng-container>

        <ng-container *ngIf="!(loadingFileRepo$ | async) && !(containsFile$ | async)">
          {{ exportSingleCsv(questionnaire) }}
        </ng-container>
      </mat-card>
    </div>
  </div>
</div>
