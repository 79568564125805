import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';

export enum InfoType {
  questionTypeOfExistingInfo = 'tipo_domanda_cambio',
  changeTitleOfQuestionInfo = 'Change the title of the question',
  textQuestionInfo = 'new text question',
}

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  infotype = InfoType;

  oldValue = this.data.oldValue;

  newValue = this.data.newValue;

  TitleOfDuplicatedQuestion = new FormControl('', [Validators.required]);

  TitleOfDuplicatedTopic = new FormControl('', [Validators.required]);

  questionarioName: string;

  topicName: string;

  responsabiliName: string;

  questionariName: string;

  modelloName: string;

  modelliName: string;

  progettoName: string;

  progettiName: string;

  constructor(
    public infoDialog: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private magicStringsService: MagicStringsService
  ) {}

  ngOnInit(): void {
    // this.magicStringsService.refresh();
    this.questionarioName = this.magicStringsService.Questionario;
    this.questionariName = this.magicStringsService.Questionari;
    this.topicName = this.magicStringsService.Topic;
    this.responsabiliName = this.magicStringsService.Responsabili;
    this.modelloName = this.magicStringsService.Modello;
    this.modelliName = this.magicStringsService.Modelli;
    this.progettoName = this.magicStringsService.Progetto;
    this.progettiName = this.magicStringsService.Progetti;
  }

  save() {
    if (this.data.Info === this.infotype.changeTitleOfQuestionInfo) {
      this.infoDialog.close(this.TitleOfDuplicatedQuestion.value);
    } else {
      this.infoDialog.close(this.newValue);
    }
  }

  cancel() {
    this.infoDialog.close(this.oldValue);
  }
}
