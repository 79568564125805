import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  constructor(private http: HttpClient) {}

  pushFileToStorage(file: File, kind: string): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();

    formdata.append('file', file);

    const req = new HttpRequest('POST', `file/uploadImage/${kind}`, formdata, {
      reportProgress: true,
      responseType: 'text',
    });

    return this.http.request(req);
  }

  getFiles(kind: string): Observable<any> {
    return this.http.get(`file/info/${kind}`);
  }

  getImage(id: number, kind: string): Observable<any> {
    return this.http.get(`file/${id}/${kind}`);
  }

  deleteImage(id: number, kind: string): Observable<any> {
    return this.http.get(`file/delete/${id}/${kind}`);
  }

  getDefault() {
    return this.http.get(`custom/get`);
  }

  getImgDefault() {
    return this.http.get(`custom/imgDefault`);
  }

  setFieldCustom(pk_custom: string, value: string) {
    return this.http.post(`custom/setField/${pk_custom}/${value}`, '');
  }

  setTheme(idTheme: number) {
    return this.http.post(`custom/setTheme/${idTheme}`, '');
  }
  getTheme() {
    return this.http.get(`custom/getTheme`);
  }
}
