import { createAction, props } from '@ngrx/store';
import { AnalyticsData } from 'src/app/data/models/AnalyticsData';
import { AssignQuestionnairesModel } from 'src/app/data/models/AssignQuestionnairesModel';
import { Project } from 'src/app/data/models/Project';
import { cloneProjectOptions } from 'src/app/data/models/request-apis/cloneProjectOptions';
import { updateActiveProjectRequest } from 'src/app/data/models/request-apis/updateActiveProjectRequest';
import { StatusFilter } from 'src/app/data/models/statusFilter';
import { User } from 'src/app/data/models/User';

export const enum ProjectActionTypes {
  saveProjectFilters = '[PROJECT STORE] Save Project Filters',
  setModelFilter = '[PROJECT STORE] Set Model Filter',
  setModelClickedFromProjects = '[PROJECT STORE] Set Model Clicked From Projects',

  notifyAllResponsibles = '[PROJECT STORE] notifyAllResponsibles of the project',
  notifyAllResponsiblesSuccess = '[PROJECT STORE] notifyAllResponsibles of the project success',
  changeResponsible = '[PROJECT STORE] update changeResponsible project',
  changeResponsibleSuccess = '[PROJECT STORE] update changeResponsible project success',
  updateactiveProject = '[PROJECT STORE] update active project',
  updateactiveProjectSuccess = '[PROJECT STORE] update active project success',
  updateDraftProject = '[PROJECT STORE] update draft project',
  updateDraftProjectSuccess = '[PROJECT STORE] update draft project success',
  getSingleProjects = '[PROJECT STORE] get Single project available',
  getSingleProjectsSuccess = '[PROJECT STORE] get Single project available success',
  getAnalyticsData = '[PROJECT STORE] get Analytics Data available',
  getAnalyticsDataSuccess = '[PROJECT STORE] get Analytics Data available success',
  resetAnalyticsData = '[PROJECT STORE] reset Analytics Data',
  saveAnalyticsFiltersNames = '[PROJECT STORE] save Analytics Filters Names',
  saveAnalyticsFiltersSelections = '[PROJECT STORE] save Analytics Filters Selections',
  getAllProjects = '[PROJECT STORE] get all projects available',
  getAllProjectsSuccess = '[PROJECT STORE] get all projects available success',
  cloneProject = '[PROJECT STORE] cloneProject action',
  cloneProjectSuccess = '[PROJECT STORE] cloneProjectSuccess action success',
  archiveProject = '[PROJECT STORE] archive project action',
  archiveProjectSuccess = '[PROJECT STORE] archive project action success',
  deleteProject = '[PROJECT STORE] delete project action',
  deleteProjectSuccess = '[PROJECT STORE] delete project action success',
  changeOwner = '[PROJECT STORE] Change the owner of the project',
  changeOwnerSuccess = '[PROJECT STORE] Success in change the owner of the project',
  createProject = '[PROJECT STORE] set AssignedQuestionnairess',
  createProjectFailAction = '[PROJECT STORE] project creation failed',
  createProjectSuccess = '[PROJECT STORE] project created successfully',
  publishProject = '[PROJECT STORE] project published send',
  publishProjectSuccess = '[AssignedQuestionnaires] project created successfully',
  publishProjectNoOrMoreUsers = '[PROJECT STORE] project special case published send',
  publishProjectNoOrMoreUsersSuccess = '[PROJECT STORE] project special case created successfully',
  publishProjectDirectly = '[PROJECT STORE] publish project directly',
  publishProjectDirectlySuccess = '[PROJECT STORE] publish project directly successfully',
  closeProject = '[PROJECT STORE] project closed send',
  closeProjectSuccess = '[PROJECT STORE]project closed successfully',
  getProjectsBySearchTerm = '[PROJECT STORE] get all projects by search term',
  addProjectWatchList = '[PROJECT STORE] send users to project watchlist',
  addProjectWatchListSuccess = '[PROJECT STORE] send users to  project watchlist success',
  exportProjectZipType = '[PROJECT STORE] export project in zip',
  storeCheckedProjects = '[PROJECT STORE] store checked projects',

  setActiveProject = '[PROJECT STORE] set active Project',
  //
  ERROR = '[AssignedQuestionnaires] error',
  ERRORHANDLED = '[AssignedQuestionnaires] error handled',
}

export const ERROR = createAction(ProjectActionTypes.ERROR);
export const errorHandled = createAction(ProjectActionTypes.ERRORHANDLED);
export const setActiveProject = createAction(
  ProjectActionTypes.setActiveProject,
  props<{
    activeProject: any;
  }>()
);

export const loadProjectsFailure = createAction(ProjectActionTypes.ERROR, props<{ error: any }>());

export const saveProjectFilters = createAction(
  ProjectActionTypes.saveProjectFilters,
  props<{
    saved: boolean;
    modelFilter: string;
    creatorFilter: string;
    searchTerm: string;
    expireFromFilter: string;
    expireToFilter: string;
    creationFromFilter: string;
    creationToFilter: string;
    status: StatusFilter;
  }>()
);

export const setModelFilter = createAction(ProjectActionTypes.setModelFilter, props<{ modelFilter: string }>());

export const setModelClickedFromProjects = createAction(
  ProjectActionTypes.setModelClickedFromProjects,
  props<{ modelFilter: string }>()
);

export const changeResponsible = createAction(
  ProjectActionTypes.changeResponsible,
  props<{
    projectId: string;
    username: string;
    oldUser: string;
    assingedId: string;
  }>()
);
export const notifyAllResponsiblesAction = createAction(
  ProjectActionTypes.notifyAllResponsibles,
  props<{ projectId: string; message: string; fullName: string }>()
);

export const notifyAllResponsiblesSuccess = createAction(ProjectActionTypes.notifyAllResponsiblesSuccess);
export const addProjectWatchList = createAction(
  ProjectActionTypes.addProjectWatchList,
  props<{ projectId: string; users: User[] }>()
);

export const addProjectWatchListSuccess = createAction(ProjectActionTypes.addProjectWatchListSuccess);

export const changeResponsibleSuccess = createAction(ProjectActionTypes.changeResponsibleSuccess);

export const updateActiveProject = createAction(
  ProjectActionTypes.updateactiveProject,
  props<{ project: updateActiveProjectRequest }>()
);

export const updateActiveProjectSuccess = createAction(ProjectActionTypes.updateactiveProjectSuccess);

export const updateDraftProjectAction = createAction(
  ProjectActionTypes.updateDraftProject,
  props<{ project: Project }>()
);

export const updateDraftProjectSuccess = createAction(ProjectActionTypes.updateDraftProjectSuccess);

export const closeProjectSuccessAction = createAction(ProjectActionTypes.closeProjectSuccess);

export const closeProjectAction = createAction(ProjectActionTypes.closeProject, props<{ projectId: string }>());

export const publishProjectAction = createAction(
  ProjectActionTypes.publishProject,
  props<{
    projectId: string;
    payload?: AssignQuestionnairesModel;
    projectTitle: String;
  }>()
);

export const publishProjectSuccessAction = createAction(ProjectActionTypes.publishProjectSuccess);

export const publishProjectNoOrMoreUsers = createAction(
  ProjectActionTypes.publishProjectNoOrMoreUsers,
  props<{ projectId: string; payload: AssignQuestionnairesModel }>()
);

export const publishProjectNoOrMoreUsersSuccess = createAction(ProjectActionTypes.publishProjectNoOrMoreUsersSuccess);

export const publishProjectDirectly = createAction(
  ProjectActionTypes.publishProjectDirectly,
  props<{ payload: AssignQuestionnairesModel }>()
);

export const publishProjectDirectlySuccess = createAction(ProjectActionTypes.publishProjectDirectlySuccess);

export const cloneProjectSuccess = createAction(ProjectActionTypes.cloneProjectSuccess, props<{ projectId: string }>());

export const cloneProject = createAction(
  ProjectActionTypes.cloneProject,
  props<{
    projectId: string;
    title: string;
    description?: string;
    options?: cloneProjectOptions;
  }>()
);

export const getSingleProjectSuccess = createAction(
  ProjectActionTypes.getSingleProjectsSuccess,
  props<{ project: Project }>()
);

export const getSingleProject = createAction(ProjectActionTypes.getSingleProjects, props<{ projectId: string }>());

export const getAnalyticsDataSuccess = createAction(
  ProjectActionTypes.getAnalyticsDataSuccess,
  props<{ analyticsData: AnalyticsData }>()
);

export const getAnalyticsData = createAction(
  ProjectActionTypes.getAnalyticsData,
  props<{ projectId: string; questionnairesAssignedIDs?: []; topicsIDs?: []; questionsIDs?: [] }>()
);

export const resetAnalyticsData = createAction(ProjectActionTypes.resetAnalyticsData);

export const saveAnalyticsFiltersNames = createAction(
  ProjectActionTypes.saveAnalyticsFiltersNames,
  props<{ filtersNames: { users: any[]; topics: any[] } }>()
);

export const saveAnalyticsFiltersSelections = createAction(
  ProjectActionTypes.saveAnalyticsFiltersSelections,
  props<{ users: any[]; topics: any[]; questions: any[] }>()
);

export const archiveProjectSuccess = createAction(ProjectActionTypes.archiveProjectSuccess);

export const archiveProject = createAction(
  ProjectActionTypes.archiveProject,
  props<{
    projectId: string;
    pageIndex: number;
    title: string;
    AscDesc: string;
    allStatus?: boolean;
    archived?: boolean;
    searchString?: string;
    modelID?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    expireFromFilter?: string;
    expireToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const deleteProjectSuccess = createAction(
  ProjectActionTypes.deleteProjectSuccess,
  props<{
    projectId: string;
    pageIndex: number;
    title: string;
    AscDesc: string;
    allStatus?: boolean;
    archived?: boolean;
    searchString?: string;
    modelID?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    expireFromFilter?: string;
    expireToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const deleteProject = createAction(
  ProjectActionTypes.deleteProject,
  props<{
    project_ids: any[];
    questionnairesReduceProject: {};
    pageIndex: number;
    title: string;
    AscDesc: string;
    allStatus?: boolean;
    archived?: boolean;
    searchString?: string;
    modelID?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    expireFromFilter?: string;
    expireToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  }>()
);

export const changeOwner = createAction(
  ProjectActionTypes.changeOwner,
  props<{ projectId: string; newUser: string }>()
);

export const changeOwnerSuccess = createAction(
  ProjectActionTypes.changeOwnerSuccess,
  props<{ data: { projectId: string; newUser: string } }>()
);

export const getAllProjects = createAction(
  ProjectActionTypes.getAllProjects,
  props<{
    pageIndex: number;
    title: string;
    AscDesc: string;
    allStatus?: boolean;
    archived?: boolean;
    searchString?: string;
    modelID?: string;
    modelFilter?: string;
    creatorFilter?: string;
    statusFilter?: StatusFilter;
    expireFromFilter?: string;
    expireToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
    deleteMode?: boolean;
  }>()
);

export const getAllProjectsSuccess = createAction(
  ProjectActionTypes.getAllProjectsSuccess,
  props<{
    Projects: Project[];
    total: number;
    pageIndex: number;
    deleteMode?: boolean;
  }>()
);

export const createProjectAction = createAction(
  ProjectActionTypes.createProject,
  props<{ payload: AssignQuestionnairesModel; flag?: boolean }>()
);

export const createProjectSuccessAction = createAction(ProjectActionTypes.createProjectSuccess);
//
export const createProjectFailAction = createAction(ProjectActionTypes.createProjectFailAction);

export const exportProjectZip = createAction(
  ProjectActionTypes.exportProjectZipType,
  props<{ id: string; lang: string; topic: string; downloadFiles: boolean }>()
);

export const storeCheckedProjects = createAction(
  ProjectActionTypes.storeCheckedProjects,
  props<{ projectArray: Project[] }>()
);
