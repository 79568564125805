import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round',
})
export class roundPipe implements PipeTransform {
  transform(value: number) {
    return Math.round(value);
  }
}
