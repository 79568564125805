import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-profile-tab',
  templateUrl: './profile-tab.component.html',
  styleUrls: ['./profile-tab.component.scss'],
})
export class ProfileTabComponent implements OnInit {
  @Input() set missingValue(value: boolean) {
    if (value) {
      this.profileForm.markAllAsTouched();
    }
  }

  @Output() newProfile = new EventEmitter<FormGroup>();

  profileForm: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      name: new FormControl(null, {
        validators: [Validators.required],
      }),
      surname: new FormControl(null, {
        validators: [Validators.required],
      }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
      }),
      tel: new FormControl(null, {
        validators: [Validators.required, Validators.pattern('[- +()0-9]+')],
      }),
    });

    this.profileForm.valueChanges.pipe(debounceTime(500)).subscribe((val) => {
      if (this.profileForm.valid) {
        this.newProfile.emit(this.profileForm);
      } else {
        this.newProfile.emit(undefined);
      }
    });
  }
}
