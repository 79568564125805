<div class="flex h-full items-center justify-center p-4">
  <form class="user-form flex flex-col gap-4" [formGroup]="passwordForm">
    <div class="flex flex-col">
      <span class="mb-2 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.USERNAME" | translate }}</span>
      <mat-form-field appearance="outline" class="w-[25vw]">
        <input required matInput type="text" formControlName="username" name="username" />
      </mat-form-field>
    </div>

    <div *ngIf="!isNewUser" class="flex flex-col">
      <span class="mb-2 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.OLD_PASSWORD" | translate }}</span>
      <mat-form-field appearance="outline" class="w-[25vw]">
        <input
          required
          matInput
          [type]="hideOld ? 'password' : 'text'"
          formControlName="old_password"
          name="old_password"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="setHideOld($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideOld"
        >
          <mat-icon class="text-dialog">{{ hideOld ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="flex flex-col">
      <span class="mb-2 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.NEW_PASSWORD" | translate }}</span>
      <mat-form-field appearance="outline" class="w-[25vw]">
        <input
          required
          matInput
          (ngModelChange)="!!repeatPassword.value && checkPassValues()"
          [type]="hide ? 'password' : 'text'"
          formControlName="new_password"
          name="new_password"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="setHide($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon class="text-dialog">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="flex flex-col">
      <span class="mb-2 text-xl font-semibold text-main">{{ "ADMIN_USER_TABS.REPEAT_PASSWORD" | translate }}</span>
      <mat-form-field appearance="outline" class="w-[25vw]">
        <input
          required
          matInput
          (ngModelChange)="checkPassValues()"
          [type]="hideConf ? 'password' : 'text'"
          formControlName="repeatPassword"
          name="repeatPassword"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="setHideConf($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideConf"
        >
          <mat-icon class="text-dialog">{{ hideConf ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-error *ngIf="!!repeatPassword.value && !!primPassword.value && repeatPassword.value !== primPassword.value"
        >{{ "CHANGE_PASS.ERROR" | translate }}
      </mat-error>
    </div>
  </form>
</div>
