import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApexOptions } from 'apexcharts';
import { ApexChart, ApexDataLabels, ApexLegend, ApexNonAxisChartSeries, ApexResponsive } from 'ng-apexcharts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectLanguage } from 'src/app/Store/users/users.selectors';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  legend: ApexLegend;
  colors: any;
  labels: any;
  dataLabels: ApexDataLabels;
  color: ApexOptions;
  responsive: ApexResponsive[];
};

@Component({
  selector: 'analytics-panel-overall',
  templateUrl: './analytics-panel-overall.component.html',
  styleUrls: ['./analytics-panel-overall.component.scss'],
})
export class AnalyticsPanelOverallComponent implements OnInit, OnDestroy {
  public chartOptions: Partial<ChartOptions>;
  private ngUnsubscribe = new Subject();

  @Input()
  questionnaires: [1, 1, 1];
  @Input()
  topics: [1, 1, 1];
  @Input()
  questions: [1, 0, 1]; // second 0 to not show In progress (1 assign, 2 progress, 3 completed)

  constructor(private translate: TranslateService, private store: Store) {
    this.chartOptions = {
      chart: {
        height: '212%',
        width: '100%',
        type: 'donut',
      },
      colors: ['#FF8D80', '#66A7FF', '#9EFF87'],
      color: {
        fill: {
          colors: ['#FF8D80', '#66A7FF', '#9EFF87'],
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        textAnchor: 'middle',
        style: {
          fontSize: '1.3rem',
          fontWeight: '500',
          colors: ['#ffffff'],
        },
      },
      series: [5, 4, 3],
      labels: ['ANALYTICS_PANEL.TO_BE_DONE', 'ANALYTICS_PANEL.IN_PROGRESS', 'ANALYTICS_PANEL.COMPLETED'].map((x) =>
        this.translate.instant(x)
      ),
      legend: {
        position: 'bottom',
      },
    };
  }

  ngOnInit(): void {
    let changedLang = false;
    this.store
      .select(selectLanguage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lang) => {
        if (changedLang) {
          // Set labels when lang change
          setTimeout(() => {
            this.chartOptions.labels = [
              'ANALYTICS_PANEL.TO_BE_DONE',
              'ANALYTICS_PANEL.IN_PROGRESS',
              'ANALYTICS_PANEL.COMPLETED',
            ].map((x) => this.translate.instant(x));
          }, 500);
        }
        changedLang = true;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
