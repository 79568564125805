import { QuestionnairesStructure } from '../data/models/QuestionnairesStructure';
import { StatusFilter, UserStatusFilter } from '../data/models/statusFilter';
import { User } from '../data/models/User';

export interface AppState {
  Questionnaires?: QuestionnairesStructure[];
  ArchivedQuestionnaires?: QuestionnairesStructure[];
  loading?: boolean;
  pageIndex?: number;
  pageSizeValue?: number;
  sortColValue?: string;
  total?: number;
  sortOrderValue?: string;
  questionnaireToBeModified?: QuestionnairesStructure;
  isUserLoggedIn?: boolean;
  UserAbilities: { Ability: string }[];
  filterOptions: {
    saved: boolean;
    searchTerm?: string;
    creatorFilter?: string;
    status?: StatusFilter;
    lastAssignmentFromFilter?: string;
    lastAssignmentToFilter?: string;
    lastChangeFromFilter?: string;
    lastChangeToFilter?: string;
    creationFromFilter?: string;
    creationToFilter?: string;
  };
  userFilterOptions: {
    saved: boolean;
    status?: UserStatusFilter;
    searchTerm?: string;
    clientFilter?: string;
    groupFilter?: string;
    emailFilter?: string;
  };
  checkedModels?: QuestionnairesStructure[];
  checkedsers?: User[];
}

export const initialState: AppState = {
  Questionnaires: [],
  ArchivedQuestionnaires: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSizeValue: 10,
  sortColValue: '',
  sortOrderValue: '',
  questionnaireToBeModified: null,
  isUserLoggedIn: false,
  UserAbilities: [],
  filterOptions: {
    saved: false,
    creatorFilter: '',
    searchTerm: '',
    lastAssignmentFromFilter: '',
    lastAssignmentToFilter: '',
    lastChangeFromFilter: '',
    lastChangeToFilter: '',
    creationFromFilter: '',
    creationToFilter: '',
    status: {
      draft: null,
      active: null,
      archived: null,
    },
  },
  userFilterOptions: {
    saved: false,
    status: {
      disabled: true,
      active:true
    },
    searchTerm:"",
    clientFilter:"",
    groupFilter:"",
    emailFilter:"",
  },
  checkedsers:[],
  checkedModels: [],
};
