import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/data/models/User';

@Pipe({
  name: 'addFullWatchListUsers',
})
export class AddFullWatchListUsersPipe implements PipeTransform {
  transform(users: User[], usedForWatchlist: boolean, watchListUsers: User[]): unknown {
    if (usedForWatchlist) {
      return watchListUsers.concat(users);
    } else {
      return users;
    }
  }
}
