<ng-container *ngIf="obs | async">
  <ng-container *ngIf="questionnaireAccesibleInComponent">
    <div
      id="questionnaire-container"
      class="h-auto md:h-full w-auto overflow-hidden flex flex-col justify-center items-center mx-20"
    >
      <div
        id="answered-quest-grid"
        class="w-full px-1 py-10 gap-6 grid md:px-20 md:grid-cols-[7fr,3fr] md:grid-rows-[13vh,64vh] 2xl:grid-rows-[13vh,72vh] lg:px-40"
      >
        <div
          id="title-grid-area"
          class="bg-white p-6 rounded-3xl flex flex-col gap-2 justify-center items-start shadow-md"
        >
          <p id="main-title" class="font-bold text-4xl text-main">
            {{ questionnaireAccesibleInComponent["Project"] }}
          </p>
          <p
            id="main-subtitle"
            class="truncate max-w-max text-main text-2xl font-poppins"
            appShowIfTruncated
            matTooltipClass="custom-tooltip"
            [matTooltip]="questionnaireAccesibleInComponent['PROJECT_DESCRIPTION']"
          >
            {{ questionnaireAccesibleInComponent["PROJECT_DESCRIPTION"] }}
          </p>
        </div>
        <div
          id="date-grid-area"
          class="bg-white p-6 rounded-3xl flex flex-col gap-2 justify-center items-start shadow-md"
        >
          <div id="expiration-row" class="flex flex-row items-center text-main">
            <mat-icon class="!inline-flex !items-center">today</mat-icon>
            <span class="ml-2">
              <span id="date-text" class="text-xl font-semibold"
                >{{ "QUEST_ANS.EXPIRY" | translate | titlecase }}:
              </span>
              <span id="date-input" class="text-xl">
                {{ questionnaireAccesibleInComponent.Expiry_Date.split("T")[0] }}
              </span>
            </span>
            <div class="fake"></div>
          </div>
          <div id="resp-row" class="flex flex-row items-center text-main">
            <mat-icon class="!inline-flex !items-center">account_box</mat-icon>
            <span class="ml-2">
              <span id="date-text" class="text-xl font-semibold"
                >{{ "DICTIONARY.Responsabile" | translate | titlecase }}:
              </span>
              <span id="date-input" class="text-xl">
                {{ getUserFullName[questionnaireAccesibleInComponent.Assigned_To] }}
              </span>
            </span>
            <div class="fake"></div>
          </div>
        </div>
        <div
          id="questionForm-grid-area"
          class="bg-white p-6 rounded-3xl grid grid-rows-[min-content,10fr,min-content] gap-2 shadow-md"
        >
          <ng-container *ngIf="questionnaireAccesibleInComponent.Topics[activeTopicIndex] as ActiveTopic">
            <div id="form-title" class="flex justify-between">
              <div class="font-bold flex flex-col gap-2">
                <div
                  (contextmenu)="copyTitleAndAnimate(ActiveTopic.Title)"
                  appShowIfTruncated
                  [matTooltip]="ActiveTopic.Title"
                  [matTooltipPosition]="'above'"
                  matTooltipClass="custom-tooltip"
                  class="truncate max-w-3xl md:max-w-[48vw]"
                >
                  <span id="topic-title" class="text-login text-2xl font-poppins">{{ ActiveTopic.Title }}</span>
                </div>
                <div
                  (contextmenu)="copyTitleAndAnimate(ActiveTopic.Description)"
                  appShowIfTruncated
                  [matTooltip]="ActiveTopic.Description"
                  matTooltipClass="custom-tooltip"
                  id="topic-subtitle"
                  class="truncate max-w-3xl md:max-w-[48vw] text-main text-xl font-poppins"
                >
                  {{ ActiveTopic.Description }}
                </div>
              </div>
            </div>
            <div id="form-questions" class="overflow-auto">
              <app-question-form
                [currentUser]="currentUser"
                (showCliboardAnimation)="animateToast()"
                (scrollTop)="questionFormScrollTop()"
                (formvalidityEvent)="formvalidity = $event"
                (unCompletedObligatoryQuestions)="unFinishedQuestions($event)"
                [questionnaireId]="questionnaireAccesibleInComponent.Assigned_id"
                (unsaved)="setUnsaved($event)"
                (formFinalValue)="saveQuestionnaire($event)"
                [activeTopic]="ActiveTopic"
                [lang]="lang"
              >
              </app-question-form>
            </div>
            <div id="form-legend" class="flex flex-row items-center justify-end">
              <img
                id="icon-obligatory"
                src="assets/images/Risposta_Obbligatoria_montain.svg"
                *ngIf="appSvgTheme === 1"
                class="text-card"
              />
              <img
                id="icon-obligatory"
                src="assets/images/Risposta_Obbligatoria_sea.svg"
                *ngIf="appSvgTheme === 0"
                class="text-card"
              />
              <img
                id="icon-obligatory"
                src="assets/images/Risposta_Obbligatoria_forest.svg"
                *ngIf="appSvgTheme === 2"
                class="text-card"
              />
              <p class="text-login font-opensans text-xl">
                {{ "QUEST_ANS.MANDATORY_QUEST" | translate | titlecase }}
              </p>
            </div>

            <div id="progress-bar" class="flex items-center justify-end gap-8" *ngIf="isInProgress$ | async">
              <span class="text-dialog">
                <p>{{ "FILE.UPLOADING" | translate }}: {{ progress$ | async }}%</p>
              </span>
              <mat-progress-bar mode="determinate" [value]="progress$ | async" class="rounded-full !w-[20vw] !h-3">
              </mat-progress-bar>
            </div>

            <div
              id="form-buttons"
              class="flex justify-between items-end"
              [ngClass]="{
                'pointer-events-none opacity-30': (isInProgress$ | async)
              }"
            >
              <div id="home-button">
                <button (click)="homePressed()" class="whiteBtn !py-2 !px-4 flex items-center justify-center">
                  <mat-icon class="!inline-flex !items-center">home</mat-icon>
                </button>
              </div>
              <div
                id="save-buttons"
                class=""
                *ngIf="
                  topicsAssignedToUser !== 0 ||
                  currentUser === questionnaireAccesibleInComponent.Assigned_To ||
                  currentUser === questionnaireAccesibleInComponent.Topics[activeTopicIndex].Assigned_To
                "
              >
                <div class="flex items-end gap-6">
                  <button
                    (click)="clearTopicAnswer()"
                    [disabled]="!unsaved"
                    class="whiteBtn disabled:whiteBtn-disabled"
                    matTooltip="{{ lang === 'it' ? cancelTooltip : cancelTooltipENG }}"
                    matTooltipClass="custom-tooltip"
                  >
                    {{ "RESET" | translate }}
                  </button>
                  <button
                    matTooltip="{{ lang === 'it' ? saveTooltip : saveTooltipENG }}"
                    matTooltipClass="custom-tooltip"
                    [disabled]="(!formvalidity || !unsaved) && !((this.topicsAssignedToUser === this.TopicsCompletedByUser && this.topicsAssignedToUser === 1) || this.topicsAssignedToUser === this.TopicsCompletedByUser)"
                    (click)="(formvalidity && unsaved) ? saveAnswers() : showWhatToDoModal()"
                    class="colorBtn disabled:colorBtn-disabled"
                  >
                    {{ "QUEST_ANS.SAVE_BTN" | translate | uppercase }}
                  </button>
                </div>
                <app-spinner [height]="'20px'" [width]="'44'" *ngIf="(submitterOrError | async)[0]"></app-spinner>
              </div>
              <div
                id="topic-buttons"
                class="flex items-end gap-6"
                *ngIf="
                  !(
                    topicsAssignedToUser !== 0 ||
                    currentUser === questionnaireAccesibleInComponent.Assigned_To ||
                    currentUser === questionnaireAccesibleInComponent.Topics[activeTopicIndex].Assigned_To
                  )
                "
              >
                <button
                  (click)="goToPriviousTopic()"
                  class="whiteBtn disabled:whiteBtn-disabled"
                  [disabled]="questionnaireAccesibleInComponent.Topics[activeTopicIndex].Order === 1"
                >
                  <p>
                    {{
                      lang === "it"
                        ? ("DICTIONARY.SingleTopic" | translate | uppercase) + " PRECEDENTE"
                        : "PREVIOUS
                    " + ("DICTIONARY.SingleTopic" | translate | uppercase)
                    }}
                  </p>
                </button>
                <button
                  (click)="goToNextTopic()"
                  class="colorBtn disabled:colorBtn-disabled"
                  [disabled]="
                    questionnaireAccesibleInComponent.Topics[activeTopicIndex].Order ===
                    questionnaireAccesibleInComponent.Number_Of_Topics
                  "
                >
                  <p>
                    {{
                      lang === "it"
                        ? ("DICTIONARY.SingleTopic" | translate | uppercase) +
                          " " +
                          (wordNext | verbPronounDictionary: "singular":"it" | uppercase)
                        : "NEXT " + ("DICTIONARY.SingleTopic" | translate | uppercase)
                    }}
                  </p>
                </button>
              </div>
            </div>
          </ng-container>
        </div>

        <div
          id="topic-grid-area"
          class="bg-white p-6 rounded-3xl flex flex-col gap-2 justify-center items-start shadow-md"
        >
          <app-topic-menu
            id="topic-menu"
            class="contents"
            (activeTopicEmit)="setActiveTopic($event)"
            (reloadAssingedment)="reloadData()"
            [getUsername]="getUserFullName"
            [currentUser]="currentUser"
            [activeTopicIndex]="activeTopicIndex"
            [countTopicsOfUser]="topicsAssignedToUser"
            [questionnaire]="questionnaireAccesibleInComponent"
            [lang]="lang"
          ></app-topic-menu>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!questionnaireAccesibleInComponent">
    <app-spinner id="page-spinner" class="absolute left-1/2 top-1/2"></app-spinner>
  </ng-container>
</ng-container>

<div *ngIf="attentzione" class="overlayHidden" [ngClass]="{ overlayShow: attentzione }" (click)="attentzione = false">
  <div
    id="warning-popup-"
    class="bg-white flex flex-col justify-between p-6 rounded-3xl w-[40vw]"
    (click)="$event.stopPropagation()"
  >
    <div id="warning-title" class="text-dialog text-2xl font-semibold mb-8">
      {{ "QUEST_ANS.WARN_TITLE" | translate | titlecase }}!
    </div>
    <div id="warning-text" class="text-base break-words max-w-[35vw]">
      <span>
        {{
          lang === "it"
            ? "Hai modificato delle risposte " +
              ("in " + topicName | prepositionDictionary: "plural":"it" | lowercase) +
              ", se non
        salvi perderai le modifiche effettuate. Desideri continuare?"
            : "You edited some questions in " +
              ("DICTIONARY.Topic" | translate | lowercase) +
              ", if you don't save you will
        lose all changes made. Do you wish to continue?"
        }}
      </span>
    </div>
    <div id="warning-buttons" class="flex justify-end mt-10">
      <button (click)="attentzione = false; annullaIsPressed = false" class="whiteBtn mr-3">
        {{ "ANNULLA" | translate }}
      </button>
      <button (click)="discardAndContinue()" class="colorBtn">
        {{ "CONTINUA" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="showObligatoryQuestionsNotCompleted"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showObligatoryQuestionsNotCompleted }"
  (click)="showObligatoryQuestionsNotCompleted = false"
>
  <div
    id="warning-popup"
    class="bg-white flex flex-col justify-between p-6 rounded-3xl w-[40vw]"
    (click)="$event.stopPropagation()"
  >
    <div id="warning-title" class="text-dialog text-2xl font-semibold mb-8">
      {{ "QUEST_ANS.WARN_TITLE" | translate | titlecase }}!
    </div>
    <div id="warning-text" class="text-base">
      {{ "QUEST_ANS.WARN_TEXT" | translate }}
    </div>

    <div id="warning-buttons" class="flex justify-end mt-10">
      <button (click)="showObligatoryQuestionsNotCompleted = false" class="whiteBtn mr-3">
        {{ "QUEST_ANS.WARN_BTN" | translate | uppercase }}
        {{
          lang === "it"
            ? ("a " + topicName | prepositionDictionary: "plural":"it" | uppercase)
            : ("to " + topicNameENG | uppercase)
        }}
      </button>
      <button (click)="saveWithUnFinished()" class="colorBtn">
        {{ "CONTINUA" | translate }}
      </button>
    </div>
  </div>
</div>
