import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActionLoginTypes, loggedIn } from 'src/app/Store/login-store/login.actions';
import { decrypt } from 'src/app/components/spinner/spinner.component';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import * as CryptoJS from 'crypto-js';
// export interface DeactivationGuarded {
//   canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
// }
// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGuardService implements CanActivate, CanDeactivate<any> {
//   constructor(private authService: AuthService, private route: Router) {}

//   canActivate(route: ActivatedRouteSnapshot) {
//     if (this.authService.isAuthenticated()) {
//       //within the app check f the user has the abilitie to go there
//       if (Object.entries(route.data).length === 1 && !this.authService.checkAuthorization(route.data)) {
//         this.route.navigate(['/']);
//         return false;
//       } else {
//         return true;
//       }
//     } else {
//       this.route.navigate(['/login']);
//     }
//     return false;
//   }

//   canDeactivate(component: DeactivationGuarded): Observable<boolean> | Promise<boolean> | boolean {
//     return component.canDeactivate();
//   }
// }

export interface DeactivationGuarded {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanDeactivate<any> {
  constructor(private authService: AuthService, private route: Router, private store: Store) {}

  decrypt = (data) => {
    return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  };
  canActivate(route: ActivatedRouteSnapshot) {
    let abilitiesObj = [];
    let token: string;
    let user: string;
    if (!this.authService.isAuthenticated()) {
      let cookies = document.cookie.split(';');
      cookies.forEach((cookie) => {
        if (cookie.includes('token')) {
          token = cookie.split('=')[1];
        }
        if (cookie.includes('user')) {
          user = decodeURIComponent(cookie.split('=')[1]);
        }
        if (cookie.includes('abilities')) {
          let abilities = cookie.split('=')[1];
          let decodedAbilities = decodeURIComponent(decodeURIComponent(abilities));
          let originalText = decrypt(decodedAbilities);
          originalText.split('%2C').forEach((abi) => {
            if (abi.includes('%26')) {
              abilitiesObj.push({ Ability: abi.replace('%26', '&') });
            } else {
              abilitiesObj.push({ Ability: abi });
            }
          });
        }
      });
      const userCookie = {
        user: user,
        token: token,
        abilities: abilitiesObj,
      };
      if (userCookie.user && userCookie.token && userCookie.abilities.length) {
        this.authService.setUserInfo(userCookie);
        this.store.dispatch({ type: ActionLoginTypes.USERlOGGEDIN, user: userCookie });
      }
    }

    if (environment.production && user && token && !this.authService.isAuthenticated()) {
      // i am connected with the sso
      return true;
    } else if (environment.production && !this.authService.isAuthenticated()) {
      // i am connected not connected
      // send a request to the sso provider of mps
      window.location.href = `${window.location.origin}/api/loginsaml`;
    } else if (this.authService.isAuthenticated()) {
      //within the app check f the user has the abilitie to go there
      if (Object.entries(route.data).length === 1 && !this.authService.checkAuthorization(route.data)) {
        if (!environment.production) {
          this.route.navigate(['/']);
        } else {
          if (route.url[0].path.includes('home')) {
            return true;
          } else {
            this.route.navigate(['/home']);
          }
        }
        return false;
      } else {
        return true;
      }
    } else {
      this.route.navigate(['/login']);
    }
    return false;
  }

  canDeactivate(component: DeactivationGuarded): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate();
  }
}
