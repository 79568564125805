import { DoCheck } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip][appShowIfTruncated]',
})
export class ShowIfTruncatedDirective implements DoCheck {
  constructor(private matTooltip: MatTooltip, private elementRef: ElementRef<HTMLElement>) {}

  ngDoCheck(): void {
    const element = this.elementRef.nativeElement;
    this.matTooltip.disabled = !(
      element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight
    );
  }
}
