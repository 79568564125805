import { AuthService } from 'src/app/utils/auth/auth.service';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { getFormattedDate } from 'src/app/data/data-handlers/utils.service';
import { updateActiveProjectRequest } from 'src/app/data/models/request-apis/updateActiveProjectRequest';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { ActionTypes, getSingleQuestionnaire } from 'src/app/Store/questionnaire-store/state.actions';
import {
  selectQuestionnairesStructured,
  selectQuestionnaireToBeModified,
} from 'src/app/Store/questionnaire-store/state.selectors';
import {
  selectImportLoading,
  selectSessionUserFullName,
  selectUsers,
  selectUsersFullname,
  selectUsersToBeImported,
} from 'src/app/Store/users/users.selectors';
import { INFO_TEXT } from './info-service';
import { Project } from 'src/app/data/models/Project';
import { User } from 'src/app/data/models/User';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { emptyImportUsers, importUsers } from 'src/app/Store/users/users.actions';
import {
  selectActiveProject,
  selectAnalyticsData,
  selectAnalyticsDataLoading,
  selectProjectLoading,
} from 'src/app/Store/projects/selectors/projects.selectors';
import {
  closeProjectAction,
  createProjectAction,
  notifyAllResponsiblesAction,
  ProjectActionTypes,
  publishProjectDirectly,
  publishProjectNoOrMoreUsers,
  saveAnalyticsFiltersNames,
  saveAnalyticsFiltersSelections,
  updateActiveProject,
  updateDraftProjectAction,
} from 'src/app/Store/projects/actions/projects.actions';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { ProjectStoreService } from 'src/app/Store/store-services/project-store.service';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { NotifyService } from 'src/app/utils/notification.service';
import { PrepositionDictionaryPipe } from 'src/app/utils/pipes/preposition-dictionary.pipe';
import { ArticleDictionaryPipe } from 'src/app/utils/pipes/article-dictionary.pipe';
import { IndefiniteArticleDictionaryPipe } from 'src/app/utils/pipes/indefinite-article-dictionary.pipe';
import { AnalyticsData } from 'src/app/data/models/AnalyticsData';

@Component({
  selector: 'app-assign-model-page',
  templateUrl: './assign-model-page.component.html',
  styleUrls: ['./assign-model-page.component.scss'],
})
export class AssignModelPageComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  isModified = false;

  @Input()
  projectToBeModified: Project;

  @Output()
  shouldSelect = new EventEmitter<boolean>();

  @Output()
  changeSend = new EventEmitter<{}>();

  @Output()
  saveTriggered = new EventEmitter<{}>();

  project: Observable<any>;

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate() {
    if (!this.saveTrigger) {
      if (this.formChange.title || this.formChange.checkboxes || this.formChange.users) {
        history.pushState(null, null, this.route.url);
        this.dialog.openConfirmDialog({
          info: CONFIRMDIALOGSTRINGS.CANCEL_MODIFICATION_CREATION,
        });
        this.dialog.openConfirmDialogResponse();
        return this.dialog.confirmDialog.componentInstance.navigateAwaySelection$;
      } else {
        return true;
      }
    }
    return true;
  }

  private ngUnsubscribe = new Subject();

  activeProjectData: {
    oldUser: string;
    newUser: string;
    backEndFlag: 'delete' | 'update' | 'add new user';
    allTopicsToNewUR?: boolean;
  }[] = [];

  showUserTable = false;

  oldUser = null;

  disabledSaveButtonInfoText = INFO_TEXT.DISABLED_SAVE_BUTTON_INFO_TEXT;

  changeResponsinbleTableShow = false;
  userToBeResponsible: User = null;
  shouldDisable = false;

  isProjectDraft = true;

  showModelsTable = false;

  trueCondition = true;

  showNotify = false;

  questionnaireForAssignment: QuestionnairesStructure;

  currentUser: string;

  importedUsersForProjectAndLoading: Observable<[boolean, User[]]>;

  ultimaDate = getFormattedDate(new Date());

  showProjectCollaborativePopUp = false;

  showDialogClose = false;

  usersChosen = [];
  finalUsersChosen = [];
  starterusers = [];

  formvalue = new FormGroup({
    QuestionnaireTitle: new FormControl('', [Validators.required]),
    QuestionnaireDescription: new FormControl(''),
  });

  ProjectFormGroup = this.fb.group({
    questionnaireForAssignment: [null, Validators.required],
    Date_Expired: ['', Validators.required],
    isProjectCollaborative: [false],
    isSeenByAllUsers: [false],
    everybodyCanReAssign: [false],
    onlyTheResponsibleCanChange: [false],
    userCanExportData: [false],
  });

  users: Observable<User[]>;
  usersFullName: Observable<{}>;
  questid: string;
  activeQuestionnaire: QuestionnairesStructure;
  loadingAction: 'None' | 'GoProjetti' = 'None';
  project$loading: Observable<boolean>;
  noUsersWhenOpened = false;
  initialUsers: string[];
  firstTime = true;
  allTopicsToNewUR: boolean;
  userInSession: string;
  formChange = {
    title: false,
    checkboxes: false,
    users: false,
    watchlist: false,
  };
  saveTrigger = false;
  questId;
  showWatchListDialog = false;
  questionnaireUserToAddToWatchlist: string;
  watchListUsers: { [id: string]: string[] } = {};
  questPreselectedWatchlist = [];
  currentUserFullName: string;
  message = '';
  progettoName: string;
  progettoNameENG: string;
  responsabileName: string;
  responsabileNameENG: string;
  questionarioName: string;
  lang: string;

  titleWarning: string;
  userWarning: string;
  prepPipe = new PrepositionDictionaryPipe();
  articlePipe = new ArticleDictionaryPipe();
  indefinitePipe = new IndefiniteArticleDictionaryPipe();
  italianTitleWarning: string;

  loadingAnalyticsData: Observable<boolean>;
  analyticsData: AnalyticsData;
  projectDataChanged = true;

  bestScoreObj: {};
  worstScoreObj: {};
  averageScore = null;
  totalScore = null;
  firstTimeAnalytics = true;
  expDate: string;

  models: Observable<QuestionnairesStructure[]>;

  constructor(
    private route: Router,
    private dialog: GeneralDialogService,
    private store: Store,
    private fb: FormBuilder,
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private notify: NotifyService,
    private magicStringsService: MagicStringsService,
    private projectService: ProjectStoreService
  ) {}

  ngOnChanges(): void {
    if (this.isModified) {
      if (this.projectToBeModified) {
        this.isProjectDraft = this.projectToBeModified.PROJECT_STATUS === 'Draft';
        this.setFieldsForModified();
        this.projectDataChanged = true;
      }
    }
  }

  ngOnInit(): void {
    // Models for change Model pop up
    this.models = this.store.select(selectQuestionnairesStructured);
    
    // ----- Analytics
    this.store.dispatch({
      type: ProjectActionTypes.resetAnalyticsData,
    });
    this.loadingAnalyticsData = this.store.select(selectAnalyticsDataLoading);
    this.firstTimeAnalytics = true;
    this.store.select(selectAnalyticsData).subscribe((data) => {
      if (data) {
        // The first time When we get ALL the Analytics Data we Save Users, Topics names and IDs
        if (this.firstTimeAnalytics) {
          this.firstTimeAnalytics = false;
          let analyticsFilterData = data.topics.map((topic) => {
            return {
              PK_TOP: topic['PK_TOP'],
              Title: topic['Title'],
              questions: topic.questions.map((question) => {
                return { PK_QUESTIONS: question['PK_QUESTIONS'], Question_Text: question['Question_Text'] };
              }),
            };
          });
          // Save all responsibles (Username, Fullname)
          let users = [];
          let allFullNames;
          this.usersFullName.pipe(take(1)).subscribe((fullUsers) => {
            allFullNames = fullUsers;
          });
          for (const [key, user] of Object.entries(this.projectToBeModified.questionnaires)) {
            let fullName = allFullNames[key];
            users.push({ questionnaireAssignedID: user['PK_QUEST_ASSIGN'], fullName: fullName });
          }

          // Sort by Fullname
          users.sort(function (a, b) {
            if (a['fullName'] < b['fullName']) {
              return -1;
            }
            if (b['fullName'] > a['fullName']) {
              return 1;
            }
            return 0;
          });
          // Save Topics/Questions Text,ids
          this.store.dispatch(
            saveAnalyticsFiltersNames({ filtersNames: { users: users, topics: analyticsFilterData } })
          );
          // Reset previous saved Filters
          this.store.dispatch(saveAnalyticsFiltersSelections({ users: [], topics: [], questions: [] }));
          // Set scores
          this.bestScoreObj = {
            score: data.Best_score,
            user: data.Best_user,
          };
          this.worstScoreObj = {
            score: data.Worst_score,
            user: data.Worst_user,
          };
          this.averageScore = data.Average_score;
          this.totalScore = data.TotalPossibleScore;
        }
        // Change analyticsData
        this.analyticsData = data;
      }
    });

    this.store
      .select(selectSessionUserFullName)
      .pipe(take(1))
      .subscribe((name) => {
        if (name.length > 0) {
          this.currentUserFullName = name[0].Name + ' ' + name[0].Surname;
        }
      });
    if (this.isModified) {
      this.projectService.setIsProjectPage(true);
      this.project = this.store.select(selectActiveProject);
      this.project.subscribe((data: any) => {
        this.expDate = data?.Date_Expired;
        this.ProjectFormGroup.patchValue({
          Date_Expired: data?.Date_Expired,
        });
      });
    }
    this.progettoName = this.magicStringsService.Progetto.toLowerCase();
    this.progettoNameENG = this.magicStringsService.ProgettoENG.toLowerCase();
    this.responsabileName = this.magicStringsService.Responsabile.toLowerCase();
    this.responsabileNameENG = this.magicStringsService.ResponsabileENG.toLowerCase();
    this.store.dispatch(emptyImportUsers());
    this.userInSession = this.authService.getUserName();
    if (!this.isModified) {
      this.setFieldsForNewProject();
    }
    this.checkForChanges();
    this.importedUsersForProjectAndLoading = combineLatest([
      this.store.select(selectImportLoading),
      this.store.select(selectUsersToBeImported),
    ]);

    this.users = this.store.select(selectUsers);
    this.usersFullName = this.store.select(selectUsersFullname);
    this.project$loading = this.store.select(selectProjectLoading).pipe(
      map((value) => {
        if (this.showModelsTable) {
          return false;
        }
        if (!value && !this.firstTime) {
          this.route.navigate([`/${ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT}`]);
        } else if (this.firstTime && value === false) {
          this.firstTime = !this.firstTime;
        }
        return value;
      })
    );

    this.store
      .select(selectLanguage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lang) => {
        this.lang = lang;
        this.titleWarning =
          this.lang == 'it'
            ? 'Inserire il titolo ' + this.prepPipe.transform('di ' + this.progettoName, 'singular', 'it')
            : 'Insert the ' + this.progettoNameENG + ' title';
        this.userWarning =
          this.lang == 'it'
            ? 'Inserire almeno ' +
              this.indefinitePipe.transform(this.responsabileName, 'singular', 'it') +
              ' per ' +
              this.articlePipe.transform(this.progettoName, 'singular', 'it')
            : 'Insert at least one ' + this.responsabileNameENG + ' for the ' + this.progettoNameENG;
      });
  }

  setFieldsForModified() {
    this.formvalue.get('QuestionnaireTitle').patchValue(this.projectToBeModified.TITLE);
    this.formvalue.get('QuestionnaireDescription').patchValue(this.projectToBeModified.DESCRIPTION);
    this.ProjectFormGroup.get('isProjectCollaborative').patchValue(this.projectToBeModified.COLLABORATIVE);
    this.ProjectFormGroup.get('isSeenByAllUsers').patchValue(this.projectToBeModified.VIEWABLE_FROM_ALL);
    this.ProjectFormGroup.get('everybodyCanReAssign').patchValue(this.projectToBeModified.ALL_REASSIGN);
    this.ProjectFormGroup.get('onlyTheResponsibleCanChange').patchValue(this.projectToBeModified.RESPONSIBLE_REASSIGN);
    this.ProjectFormGroup.get('userCanExportData').patchValue(this.projectToBeModified.EXPORTABLE_DATA);
    this.ProjectFormGroup.get('Date_Expired').patchValue(this.projectToBeModified.Expiry_Date);

    this.noUsersWhenOpened = this.projectToBeModified.USERS.length === 0;
    this.initialUsers = this.projectToBeModified.USERS;

    var questID = this.projectToBeModified.FK_QUEST;
    this.activeQuestionnaire = {
      Number_Of_Projects: 0,
      Number_Of_Topics: this.projectToBeModified.Number_Of_Topics,
      Questionnaire_Status: 'Completed',
      Questionnaire_Title: this.projectToBeModified.Questionnaire_Title,
      Questionnaire_id: questID,
    };

    this.ProjectFormGroup.get('questionnaireForAssignment').patchValue(this.activeQuestionnaire);
    this.currentUser = this.projectToBeModified.Creator;
    this.finalUsersChosen = this.projectToBeModified.USERS.map((user) => user);
    this.starterusers = this.finalUsersChosen;

    this.ultimaDate = this.projectToBeModified.LAST_MODIFIED.split('T')[0];
    this.shouldDisable = this.projectToBeModified.PROJECT_STATUS === 'Closed';
  }

  setFieldsForNewProject() {
    this.currentUser = this.authService.getUserName();
    try {
      this.questid = this.activeRoute.snapshot.paramMap.get('id');
    } catch (error) {}

    this.store.dispatch(getSingleQuestionnaire({ id: this.questid }));
    this.store
      .select(selectQuestionnaireToBeModified)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((quest) => {
        if (!!quest) {
          this.activeQuestionnaire = {
            Number_Of_Projects: 0,
            Number_Of_Topics: quest.Topics.length,
            Questionnaire_Status: quest.Questionnaire_Status,
            Questionnaire_Title: quest.Title,
            Questionnaire_id: quest.id,
          };
          this.ProjectFormGroup.get('questionnaireForAssignment').patchValue({
            Number_Of_Projects: 0,
            Number_Of_Topics: this.activeQuestionnaire?.Topics?.length,
            Questionnaire_Status: this.activeQuestionnaire.Questionnaire_Status,
            Questionnaire_Title: this.activeQuestionnaire.Title,
            Questionnaire_id: this.activeQuestionnaire.id,
          });
          this.ProjectFormGroup.get('Date_Expired').patchValue(new Date());
        }
        return quest;
      });
  }

  openManagementQuestionnaire() {
    this.route.navigateByUrl(`/${ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT}`);
  }

  addResponsible(users: User[]) {
    let questionnaire = this.projectToBeModified.questionnaires[this.projectToBeModified.USERS[0]];
    let newObj = {};
    let newUsers = [];
    let existingUsers = this.finalUsersChosen.concat([]);
    users.forEach((user) => {
      if (this.projectToBeModified.USERS.length !== 0) {
        newObj[user.username] = {
          ...questionnaire,
          score: 0,
          Date_Assigned: new Date().toISOString(),
          Status: 'Assigned',
          topicsCompleted: 0,
        };
      } else {
        const questionnaire = this.projectToBeModified.questionnaires;
        newObj[user.username] = {
          ...questionnaire,
          Date_Assigned: new Date().toISOString(),
          Status: 'Assigned',
          topicsCompleted: 0,
        };
      }
      newUsers.push(user.username);
    });
    Object.keys(this.projectToBeModified.questionnaires).map((key) => {
      newObj[key] = this.projectToBeModified.questionnaires[key];
    });

    this.projectToBeModified = {
      ...this.projectToBeModified,
      questionnaires: newObj,
    };
    this.finalUsersChosen = existingUsers.concat(newUsers);
  }

  getTheDate(event) {
    this.ProjectFormGroup.get('Date_Expired').patchValue(event);
  }

  save() {
    if (this.formvalue.valid) {
      this.saveTrigger = true;
      this.saveTriggered.emit(this.saveTrigger);

      if (this.isProjectDraft && !!this.projectToBeModified && this.projectToBeModified['IsAtLeastCloned']) {
        this.modifyProject(this.projectToBeModified, 'Save');
      } else if (this.isModified) {
        this.modifyProject(this.projectToBeModified, 'Active');
      } else {
        this.store.dispatch(createProjectAction({ payload: this.createProject(), flag: true }));
        this.loadingAction = 'GoProjetti';
      }
    } else {
      this.notify.openWarningSwal(this.titleWarning);
    }
  }

  modifyProject(project: Project, projectStatus: string) {
    if (this.isProjectDraft && !this.projectToBeModified['IsAtLeastCloned']) {
      let updatedProj = this.createProject();
      let projectToSent = {
        ...project,
        PK_PROJECT: project.PK_PROJECT,
        EXPIRY_DATE: updatedProj.expiry_date,
        ALL_REASSIGN: updatedProj.ALL_REASSIGN,
        PROJECT_STATUS: updatedProj.PROJECT_STATUS,
        COLLABORATIVE: updatedProj.collaborative,
        VIEWABLE_FROM_ALL: updatedProj.VIEWABLE_FROM_ALL,
        TITLE: updatedProj.project,
        DESCRIPTION: updatedProj.projectDescription,
        RESPONSIBLE_REASSIGN: updatedProj.RESPONSIBLE_REASSIGN,
        USERS: updatedProj.users,
        watchListUsers: this.watchListUsers,
        EXPORTABLE_DATA: updatedProj.EXPORTABLE_DATA,
      };
      this.store.dispatch(updateDraftProjectAction({ project: projectToSent }));
    } else if (
      (!!this.projectToBeModified && this.projectToBeModified['IsAtLeastCloned']) ||
      project.PROJECT_STATUS === 'Active'
    ) {
      let quest = project.questionnaires[Object.keys(project.questionnaires)[0]];
      let objectToSend: updateActiveProjectRequest = {
        PROJECT_STATUS: projectStatus === 'Save' ? project.PROJECT_STATUS : 'Active',
        TITLE: this.formvalue.get('QuestionnaireTitle').value,
        DESCRIPTION: this.formvalue.get('QuestionnaireDescription').value,
        FK_PROJECT: project.PK_PROJECT,
        Collaborative: this.ProjectFormGroup.get('isProjectCollaborative').value,
        viewable_from_all: this.ProjectFormGroup.get('isSeenByAllUsers').value,
        all_reassign: this.ProjectFormGroup.get('everybodyCanReAssign').value,
        responsible_reassign: this.ProjectFormGroup.get('onlyTheResponsibleCanChange').value,
        description: this.formvalue.value['QuestionnaireDescription'],
        FK_QUEST: quest.FK_QUEST,
        Date_Expired: this.ProjectFormGroup.value['Date_Expired'],
        Creator: quest.Creator,
        Project: project.TITLE,
        USERS: this.activeProjectData,
        questionnaires: project.questionnaires,
        watchListUsers: this.watchListUsers,
        EXPORTABLE_DATA: this.ProjectFormGroup.get('userCanExportData').value,
      };
      this.store.dispatch(updateActiveProject({ project: objectToSend }));
    }
  }

  getNotes(event: { notes: any; Quest_Assing_ID: string; pageIndex: 0; Status: string; user?: string }) {
    this.dialog.openNotesModal(event);
  }

  goToAnwswer(id: string) {
    this.route.navigateByUrl(`/${ROUTES_STRINGS.ANSWERQUESTIONNAIRE}/${id}`);
  }

  toggleModelVisibility() {
    this.showModelsTable = !this.showModelsTable;
    if (this.showModelsTable) {
      this.store.dispatch({
        type: ActionTypes.loadQuestionnairesType,
        pageIndex: 0,
        title: 'Questionaire_Title',
        ascdesc: 'ASC',
        archived: false,
        dontResetQuestionnaireToBeModified: true,
      });
    }
  }

  selectedModel(quest: QuestionnairesStructure) {
    this.toggleModelVisibility();
    let newObj = {};
    if (quest !== null) {
      if (this.isModified) {
        if (this.projectToBeModified.USERS.length !== 0) {
          Object.keys(this.projectToBeModified.questionnaires).map((key) => {
            newObj[key] = {
              ...this.projectToBeModified.questionnaires[key],
              Date_Assigned: new Date(Date.now()).toISOString(),
              FK_QUEST: quest.Questionnaire_id,
              TotalTopics: quest.Number_Of_Topics,
              topicsCompleted: 0,
            };
          });
        } else {
          newObj = {
            ...this.projectToBeModified.questionnaires,
            Date_Assigned: new Date(Date.now()).toISOString(),
            FK_QUEST: quest.Questionnaire_id,
            TotalTopics: quest.Number_Of_Topics,
            topicsCompleted: 0,
          };
        }

        this.projectToBeModified = {
          ...this.projectToBeModified,
          DATE_CREATED: new Date(Date.now()).toISOString(),
          Questionnaire_Title: quest.Questionnaire_Title,
          Number_Of_Topics: quest.Number_Of_Topics,
          FK_QUEST: quest.Questionnaire_id,
          questionnaires: newObj,
        };
      } else {
        this.activeQuestionnaire = quest;
      }
      this.ProjectFormGroup.get('questionnaireForAssignment').patchValue(quest);
    }
  }

  publishProject() {
    var userAssigned = this.finalUsersChosen.length > 0.1;
    if (this.formvalue.valid && userAssigned) {
      if (!!this.projectToBeModified && this.projectToBeModified['IsAtLeastCloned']) {
        this.saveTriggered.emit(this.saveTrigger);
        this.modifyProject(this.projectToBeModified, 'Active');
      } else if (
        !(
          this.isProjectDraft &&
          !!this.projectToBeModified &&
          !this.projectToBeModified['IsAtLeastCloned'] &&
          this.isModified
        )
      ) {
        this.saveTrigger = true;
        this.saveTriggered.emit(this.saveTrigger);
        this.store.dispatch(publishProjectDirectly({ payload: this.createProject() }));
        this.loadingAction = 'GoProjetti';
      } else {
        this.saveTrigger = true;
        this.saveTriggered.emit(this.saveTrigger);
        this.store.dispatch(
          publishProjectNoOrMoreUsers({
            projectId: this.projectToBeModified.PK_PROJECT,
            payload: this.createProject(),
          })
        );
        this.loadingAction = 'GoProjetti';
      }
    } else if (!this.formvalue.valid) {
      this.notify.openWarningSwal(this.titleWarning);
    } else {
      this.notify.openWarningSwal(this.userWarning);
    }
  }

  closeProject() {
    this.store.dispatch(closeProjectAction({ projectId: this.projectToBeModified.PK_PROJECT }));
    this.loadingAction = 'GoProjetti';
  }

  enableCheckboxes() {
    if (this.isModified && !this.ProjectFormGroup.get('isProjectCollaborative').value) {
      this.dialog.openConfirmDialog({
        info: CONFIRMDIALOGSTRINGS.COLLABORATIVE_FALSE,
      });

      this.dialog
        .openConfirmDialogResponse()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          if (res !== 'Cancel') {
            this.ProjectFormGroup.get('isProjectCollaborative').patchValue(false);
            this.ProjectFormGroup.get('isSeenByAllUsers').patchValue(false);
            this.ProjectFormGroup.get('everybodyCanReAssign').patchValue(false);
            this.ProjectFormGroup.get('userCanExportData').patchValue(false);
          } else {
            this.ProjectFormGroup.get('isProjectCollaborative').patchValue(true);
          }
        });
    }
  }

  checkForChanges() {
    let startingTitle = this.formvalue.getRawValue();
    let form = this.ProjectFormGroup.getRawValue();
    let startingCheckboxes = {
      everybodyCanReAssign: form.everybodyCanReAssign,
      isProjectCollaborative: form.isProjectCollaborative,
      isSeenByAllUsers: form.isSeenByAllUsers,
      userCanExportData: form.userCanExportData,
      onlyTheResponsibleCanChange: form.onlyTheResponsibleCanChange,
    };
    this.formvalue.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
      if (JSON.stringify(startingTitle) === JSON.stringify(val)) {
        this.formChange.title = false;
        this.changeSend.emit(this.formChange);
      } else {
        this.formChange.title = true;
        this.changeSend.emit(this.formChange);
      }
    });
    this.ProjectFormGroup.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
      let newValue = {
        everybodyCanReAssign: val.everybodyCanReAssign,
        isProjectCollaborative: val.isProjectCollaborative,
        userCanExportData: val.userCanExportData,
        isSeenByAllUsers: val.isSeenByAllUsers,
        onlyTheResponsibleCanChange: val.onlyTheResponsibleCanChange,
      };
      if (JSON.stringify(startingCheckboxes) === JSON.stringify(newValue)) {
        this.formChange.checkboxes = false;
        this.changeSend.emit(this.formChange);
      } else {
        this.formChange.checkboxes = true;
        this.changeSend.emit(this.formChange);
      }
    });

    Object.values(this.watchListUsers).forEach((array) => {
      if (array.length > 0) {
        this.formChange.watchlist = true;
      }
    });
  }

  exportQuestionnairefunc(event: { link: string; assingId: string }) {
    window.open(
      `${event.link}/exportQuestionnaire?questId=${event.assingId}&secret_token=${this.authService.getUserToken()}`,
      '_blank'
    );
  }

  sendAndUpdateChange(event: { title: boolean; checkboxes: boolean; users: boolean; watchlist: boolean }) {
    this.formChange = event;
    this.changeSend.emit(this.formChange);
  }

  showNotifyFunc() {
    this.showNotify = true;
    this.message = '';
  }
  cancelNotifyFUnct() {
    this.showNotify = false;
  }

  notifyResponsiblesfunc() {
    if (this.message !== '') {
      this.showNotify = false;
      this.store.dispatch(
        notifyAllResponsiblesAction({
          projectId: this.projectToBeModified.PK_PROJECT,
          message: this.message,
          fullName: this.currentUserFullName,
        })
      );
    }
  }

  private createProject() {
    if (!this.ProjectFormGroup.value['questionnaireForAssignment']['Questionnaire_id']) {
      this.ProjectFormGroup.get('questionnaireForAssignment').patchValue(this.activeQuestionnaire);
    }

    const assignmentData = {
      questionnairesToBeAssigned: [this.ProjectFormGroup.value['questionnaireForAssignment']],
      users: this.finalUsersChosen,
      expiry_date: this.ProjectFormGroup.value['Date_Expired'],
      project: this.formvalue.value['QuestionnaireTitle'],
      projectDescription: this.formvalue.value['QuestionnaireDescription'],
      Note: null,
      PROJECT_STATUS: 'Draft',
      collaborative: this.ProjectFormGroup.value['isProjectCollaborative'],
      VIEWABLE_FROM_ALL: this.ProjectFormGroup.value['isSeenByAllUsers'],
      ALL_REASSIGN: this.ProjectFormGroup.value['everybodyCanReAssign'],
      RESPONSIBLE_REASSIGN: this.ProjectFormGroup.value['onlyTheResponsibleCanChange'],
      watchListUsers: this.watchListUsers,
      EXPORTABLE_DATA: this.ProjectFormGroup.value['userCanExportData'],
    };
    return assignmentData;
  }

  sendUsersFromExcel(users: [{ NAME: string; SURNAME: string; EMAIL: string }]) {
    this.store.dispatch(importUsers({ users: users }));
  }

  dispatchAnalyticsData() {
    // if project data didnt change don't update analytics
    if (this.projectDataChanged) {
      this.projectDataChanged = false;
      this.store.dispatch({
        type: ProjectActionTypes.getAnalyticsData,
        projectId: this.projectToBeModified.PK_PROJECT,
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
