import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bottomCalculationPipe',
})
export class BottomCalculationPipePipe implements PipeTransform {
  transform(index: number, lastIndex: number, length: number, lineHeight: number, rowHeight: number): number {
    if (length === 1) {
      return -10;
    } else {
      let indexDif = lastIndex - index;
      if (indexDif === 0) {
        return -10;
      }
      return lineHeight - (lineHeight - rowHeight * indexDif) - 12;
    }
  }
}
