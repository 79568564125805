import { createAction, props } from '@ngrx/store';
import { ElencoTopic } from 'src/app/data/models/ElencoTopic';

export const enum ActionTopicTypes {
  topicsInitialise = '[Topics] initial set',
  addTopicAction = '[Topics] load topics',
  deleteTopicAction = '[Topics] delete topic',
  updateTopicAction = '[Topics] update a topic',
  loadTopicsAction = '[Topics] add a new topic',
  updateAllTopicsAction = '[Topics] update all Topics after drop',
  updateTopicsAction = '[Topics] update one Topic after drop',
  PostQuestionnaire = '[Topics] questionnaire posted',
  PostQuestionnaireSuccess = '[Topics] confirmTopicSuccessSuccess',
  submitionForReviewSuccess = '[Topics] put topic in review submition success',
  ERROR = '[Topics] error in the request',
  updateTopicsUser = '[Topics] updateTopicsUser',
  updateTopicsUserSuccess = '[Topics] updateTopicsUser Success',
  noAction = '[Topics] no action',
}

export const TopicError = createAction(ActionTopicTypes.ERROR);

export const submitionForReviewSuccess = createAction(
  ActionTopicTypes.submitionForReviewSuccess,
  props<{ id: string }>()
);
export const deleteTopic = createAction(ActionTopicTypes.deleteTopicAction, props<{ index: number }>());

export const addTopic = createAction(ActionTopicTypes.addTopicAction, props<{ topic: ElencoTopic }>());

export const initialTopicSetAction = createAction(
  ActionTopicTypes.topicsInitialise,
  props<{ topics: ElencoTopic[] }>()
);

export const updateTopic = createAction(
  ActionTopicTypes.updateTopicAction,
  props<{ topic: ElencoTopic; index: number; indexOld: number }>()
);

export const loadTopics = createAction(ActionTopicTypes.loadTopicsAction);

export const updateOneTopicsAfterDrop = createAction(
  ActionTopicTypes.updateTopicsAction,
  props<{ topics: ElencoTopic }>()
);

export const updateAllTopicsAfterDrop = createAction(
  ActionTopicTypes.updateAllTopicsAction,
  props<{ topics: ElencoTopic[] }>()
);

export const PostQuestionnaire = createAction(ActionTopicTypes.PostQuestionnaire);

export const PostQuestionnaireSuccess = createAction(ActionTopicTypes.PostQuestionnaireSuccess);

export const updateTopicsUser = createAction(
  ActionTopicTypes.updateTopicsUser,
  props<{
    Assigned_id: string;
    topicAssignedIds: { Assigned_id: string }[];
    note: string;
    backToResponsible: boolean;
    newUser?: string;
    fromHome: boolean;
  }>()
);

export const updateTopicsUserSuccess = createAction(
  ActionTopicTypes.updateTopicsUserSuccess,
  props<{
    Assigned_id: string;
    fromHome: boolean;
    backToResponsible: boolean;
  }>()
);

export const noAction = createAction(ActionTopicTypes.noAction);
