import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { LoginStoreService } from 'src/app/Store/store-services/login-store.service';
import { Router } from '@angular/router';
import { ABILITIES_HELPER, UserAbilitiesEnum } from 'src/app/data/models/abilities';
import { WSService } from 'src/app/data/api/WSServices/WSService';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UserActions } from 'src/app/Store/users/users.actions';
import { ActionTypes } from 'src/app/Store/questionnaire-store/state.actions';
import { ProjectActionTypes } from 'src/app/Store/projects/actions/projects.actions';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  show = false;
  @Input()
  location: Location;

  @Input()
  abilities;

  @Input()
  lang: string;

  @Input()
  opened: boolean;

  @Input()
  userMenu: boolean;

  @Output()
  closed = new EventEmitter<boolean>();

  abilitiesHelper = ABILITIES_HELPER;

  linksArray: any;

  constructor(
    private store: Store,
    public authService: AuthService,
    public logoutService: LoginStoreService,
    private wsService: WSService,
    public router: Router,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    Object.keys(this.abilitiesHelper).forEach((ability) => {
      this.abilitiesHelper[ability] = false;
    });
    this.abilities.forEach((ability) => {
      this.abilitiesHelper[ability.Ability] = true;
    });

    if (this.userMenu) {
      this.linksArray = [{ icon: 'logout', url: '/login', name: 'LOGOUT', permission: '*' }];
    } else {
      this.linksArray = [
        {
          icon: 'home',
          url: '/home',
          name: 'HOMEPAGE',
          permission: UserAbilitiesEnum.HomepageFullAccess,
        },
        {
          icon: 'work',
          url: '/questionnaires-management',
          name: 'MODELS_AND_PROJECTS',
          permission: UserAbilitiesEnum.ModelsProjectsAccess,
        },
        {
          icon: 'manage_accounts',
          url: '/profile',
          name: 'USER_MANAGEMENT',
          permission: UserAbilitiesEnum.UsersManagementFullAccess,
        },
        {
          icon: 'admin_panel_settings',
          url: '/admin-page',
          name: 'ADMIN_PAGE',
          permission: 'AdminPageAccess',
        },
        {
          icon: 'brush',
          url: '/administrative-page',
          name: 'ADMINISTRATIVE',
          permission: 'CustomPageAccess',
        },
        {
          icon: 'account_box',
          url: '/admin-groups',
          name: 'ADMINGROUPS',
          permission: UserAbilitiesEnum.HomepageFullAccess,
        },
      ];
    }
  }

  logout() {
    // Reset Advanced filters
    this.store.dispatch({
      type: ActionTypes.saveModelFilters,
      saved: false,
      creatorFilter: '',
      searchTerm: '',
      lastAssignmentFromFilter: '',
      lastAssignmentToFilter: '',
      lastChangeFromFilter: '',
      lastChangeToFilter: '',
      creationFromFilter: '',
      creationToFilter: '',
      status: {
        draft: null,
        active: null,
        archived: false,
      },
    });
    this.store.dispatch({
      type: ActionTypes.saveUserFilters,
      saved: false,
      status: {
        disabled: null,
        active:null
      },
      searchTerm:"",
      clientFilter:"",
      groupFilter:"",
      emailFilter:"",
    });

    this.store.dispatch({
      type: ProjectActionTypes.saveProjectFilters,
      saved: false,
      modelFilter: '',
      creatorFilter: '',
      searchTerm: '',
      expireFromFilter: '',
      expireToFilter: '',
      creationFromFilter: '',
      creationToFilter: '',
      status: {
        draft: null,
        active: null,
        closed: null,
        archived: false,
      },
    });

    this.authService.logout();
    this.logoutService.logOut();
    this.wsService.disconnectFromWS();
    this.closed.emit(true);
  }

  changeLanguage(language) {
    this.store.dispatch({
      type: UserActions.changeLanguage,
      language: language,
    });
  }
}
