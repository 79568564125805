import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const enum ReAssingStrings {
  CREATETOPIC = 'Create Topic',
  TITLE = 'Riassegna Topic',
  ANNULLA = 'Annulla',
  iNVIA = 'Invia',
  PERCHE = 'Perchè il topic dev’essere ricompilato?',
  INSERTACOMMENT = "Inserire i commenti relativi all'assegnazione",
}

@Component({
  selector: 'app-re-assign-topic',
  templateUrl: './re-assign-topic.component.html',
  styleUrls: ['./re-assign-topic.component.scss'],
})
export class ReAssignTopicComponent implements OnInit {
  dialogTitle: string;

  strongText = ReAssingStrings.PERCHE;

  cancelText = ReAssingStrings.ANNULLA;

  showSaveButton = false;

  showThirdOption = false;

  saveText = ReAssingStrings.iNVIA;

  placeholder = ReAssingStrings.INSERTACOMMENT;

  deleteAllText = 'Si, elimina anche le domande vincolate';
  deleteOnlyBindingText = 'Elimina solamente la domanda vincolante';

  freeTextForm: FormGroup = new FormGroup({
    freeText: new FormControl(' '),
  });

  constructor(public dialog: MatDialogRef<ReAssignTopicComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.dialogTitle = ReAssingStrings.TITLE;
  }

  close() {
    this.dialog.close(undefined);
  }

  save() {
    this.dialog.close(this.freeTextForm.value['freeText']);
  }
}
