import {
  changeLanguage,
  emptyImportUsers,
  getUserByUsernameSuccess,
  getUsersPaginated,
  getUsersPaginatedSuccess,
  importUsers,
  importUsersFromSuccess,
  searchClientGroup,
  searchClientGroupSuccess,
  searchUsers,
  searchUsersSuccess,
  updateUserSuccess,
  searchAllClientGroup,
  searchAllClientGroupSuccess,
  searchFilterClientGroup,
  searchFilterClientGroupModel,
} from './users.actions';
import { createReducer, on } from '@ngrx/store';
import { setUsers } from '../users/users.actions';
import { userInitialState } from './users.state';
import { loggedIn } from '../login-store/login.actions';

const _userReducer = createReducer(
  userInitialState,
  on(changeLanguage, (state, { language }) => ({
    ...state,
    language: language,
  })),
  on(getUserByUsernameSuccess, (state, { language }) => {
    return {
      ...state,
      language: (language === 'en' || state.language === 'en') && state.language !== 'itChanged' ? 'en' : 'it',
    };
  }),
  on(setUsers, (state, { users }) => ({
    ...state,
    users,
  })),
  on(loggedIn, (state, { username }) => ({
    ...state,
    username: username,
  })),
  on(searchUsers, (state) => ({
    ...state,
    loadingUsers: true,
  })),
  on(searchUsersSuccess, (state, { users, pageIndex, total }) => ({
    ...state,
    usersPaginated: users,
    usersPageIndex: pageIndex,
    length: total,
    loadingUsers: false,
  })),
  on(searchClientGroup, (state, { field }) => {
    if (field === 'Client') {
      return {
        ...state,
        loadingClient: true,
      };
    } else {
      return {
        ...state,
        loadingGroup: true,
      };
    }
  }),
  on(searchClientGroupSuccess, (state, { clientGroup, pageIndex, total, field }) => {
    if (field === 'Client') {
      return {
        ...state,
        clientPaginated: clientGroup,
        clientPageIndex: pageIndex,
        clientlength: total,
        loadingClient: false,
      };
    } else {
      return {
        ...state,
        groupPaginated: clientGroup,
        groupPageIndex: pageIndex,
        grouplength: total,
        loadingGroup: false,
      };
    }
  }),
  on(searchAllClientGroup, (state) => {
    return {
      ...state,
      loadingAllGroupClient: true,
    };
  }),
  on(searchFilterClientGroup, (state, { searchTerm }) => {
    return {
      ...state,
      searchTermClientGroup: searchTerm,
    };
  }),
  on(searchFilterClientGroupModel, (state, { searchTerm }) => {
    return {
      ...state,
      searchTermClientGroupModel: searchTerm,
    };
  }),
  on(searchAllClientGroupSuccess, (state, { data }) => {
    return {
      ...state,
      loadingAllGroupClient: false,
      allClientGroup: data,
    };
  }),
  on(getUsersPaginated, (state) => ({
    ...state,
    loadingUsers: true,
  })),
  on(importUsers, (state) => ({
    ...state,
    importingUsersLoading: true,
  })),
  on(emptyImportUsers, (state) => ({
    ...state,
    importingUsersLoading: false,
    usersToImport: [],
  })),
  on(importUsersFromSuccess, (state, users) => ({
    ...state,
    importingUsersLoading: false,
    usersToImport: users['users'],
  })),
  on(getUsersPaginatedSuccess, (state, { users }) => ({
    ...state,
    usersPaginated: users['data'],
    usersPageIndex: users['pageIndex'],
    length: users['length'],
    loadingUsers: false,
  })),
  on(updateUserSuccess, (state, { user }) => ({
    ...state,
    usersPaginated: state.usersPaginated
      .map((userp) => ({ ...userp }))
      .map((userp) => {
        if (userp.username === user['PK_USER']) {
          user = { ...user, username: user['PK_USER'] };
          return user;
        } else {
          return userp;
        }
      }),
  }))
);

export function userReducer(state, action) {
  return _userReducer(state, action);
}
