import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromFileUploadActions from 'src/app/Store/upload-file-store/upload-file.actions';
import * as fromFileUploadSelectors from 'src/app/Store/upload-file-store/upload-file.selectors';

@Component({
  selector: 'question-details-modal',
  templateUrl: './question-details-modal.component.html',
  styleUrls: ['./question-details-modal.component.scss'],
})
export class QuestionDetailsModalComponent implements OnInit {
  details = [];
  fullArray = [];
  pageIndex = 0;
  pageSize = 10;
  length = 0;
  sortColumn = 'responsible';
  ascDesc = 'asc';
  searchTerm = '';

  filesIDs = [];
  selectDownloading: Observable<boolean>;

  constructor(
    private store$: Store,
    public analyticsQuestionDetailsModal: MatDialogRef<QuestionDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.selectDownloading = this.store$.select(fromFileUploadSelectors.selectIsFileDownloading);
    this.sortArray(this.data.details);
    this.fullArray = this.data.details;
    this.initialize();
  }

  initialize() {
    this.length = this.fullArray.length;
    this.details = this.fullArray.slice(this.pageIndex * this.pageSize, (this.pageIndex + 1) * this.pageSize);
  }

  sortArray(array) {
    let col = this.sortColumn;
    let direction = this.ascDesc;
    let questionType = this.data.questionType;
    array.sort(function (a, b) {
      let a_compare;
      let b_compare;
      if (['File', 'Free text'].includes(questionType)) {
        a_compare = a[col][0];
        b_compare = b[col][0];
      } else {
        a_compare = a[col];
        b_compare = b[col];
      }

      if (a_compare < b_compare) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a_compare > b_compare) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  sortActiveColumn(sortData: { active: string; direction: string }) {
    this.sortColumn = sortData.active;
    this.ascDesc = sortData.direction;
    this.sortArray(this.fullArray);
    this.details = this.fullArray.slice(this.pageIndex * this.pageSize, (this.pageIndex + 1) * this.pageSize);
  }

  search(term) {
    if (term !== '') {
      this.searchTerm = term;
      this.details = this.fullArray.filter((user) => {
        let indexSplit = user['responsible'].indexOf(' ');
        let name = user['responsible'].toLowerCase().slice(0, indexSplit);
        let surname = user['responsible'].toLowerCase().slice(indexSplit + 1, user['responsible'].length);
        return name.startsWith(term.toLowerCase()) || surname.startsWith(term.toLowerCase());
      });
      this.length = this.details.length;
      this.fullArray = this.details;
      this.details = this.fullArray.slice(this.pageIndex * this.pageSize, (this.pageIndex + 1) * this.pageSize);
    } else {
      this.searchTerm = '';
      this.fullArray = this.data.details;
      this.initialize();
    }
  }

  closeModal() {
    this.analyticsQuestionDetailsModal.close(false);
  }

  getFileName(dirtyFile: string) {
    let indexSplit = dirtyFile.indexOf('_');
    let result = dirtyFile.slice(indexSplit + 1, dirtyFile.length);
    return result;
  }

  getNext(pageData) {
    this.pageIndex = pageData.pageIndex;
    this.filesIDs = [];
    this.search(this.searchTerm);
  }

  checkFile(checked, fileID) {
    if (!checked) {
      let index = this.filesIDs.indexOf(fileID);
      if (index > -1) {
        this.filesIDs.splice(index, 1);
      }
    } else {
      this.filesIDs.push(fileID);
    }
  }

  checkAll(checked) {
    this.filesIDs = [];
    if (checked) {
      this.details.forEach((user) => {
        this.filesIDs.push(user.answers[0]);
      });
    }
  }

  downloadFile() {
    if (this.filesIDs.length === 1) {
      this.store$.dispatch(
        fromFileUploadActions.DownloadFileAction({
          fileId: this.filesIDs[0],
        })
      );
    } else if (this.filesIDs.length > 1) {
      this.store$.dispatch(
        fromFileUploadActions.DownloadZipAction({
          fileList: [...this.filesIDs],
          questTitle: this.data.zipName,
        })
      );
    }
  }
}
