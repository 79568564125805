import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excludeQuestionnaires',
})
export class HomeQuestionnaireFilterPipe implements PipeTransform {
  transform(
    value: string[],
    questionnaireObj: {},
    filter: string,
    user?: string,
    searchByNameTerm?: string,
    filterStartDate?: string,
    filterEndDate?: string
  ): {} {
    let quests = [];

    // Search Term and initialize quest[]
    if (searchByNameTerm) {
      value.map((key) => {
        if (questionnaireObj[key]['projectTitle'].toLowerCase().includes(searchByNameTerm.toLowerCase())) {
          quests.push(questionnaireObj[key]);
        }
      });
    } else {
      value.map((key) => quests.push(questionnaireObj[key]));
    }

    // Advanced filter
    switch (filter) {
      case 'responsible':
        quests = quests.filter((quest) => quest.responsible === user);
        break;
      case 'expired':
        if (filterStartDate !== '' || filterEndDate !== '') {
          quests = quests.filter((quest) => {
            if (filterStartDate !== '' && filterEndDate !== '') {
              return (
                new Date(quest.expiryDate) > new Date(filterStartDate) &&
                new Date(quest.expiryDate) < new Date(filterEndDate)
              );
            } else if (filterStartDate !== '') {
              return new Date(quest.expiryDate) > new Date(filterStartDate);
            } else if (filterEndDate !== '') {
              return new Date(quest.expiryDate) < new Date(filterEndDate);
            }
          });
        }
        break;
      case 'topicsToCompile':
        quests = quests.filter((quest) => {
          if (quest.responsible === user || quest.TopicsOfUsers === '0') {
            return quest.Completed_Topics !== quest.Number_Of_Topics;
          } else {
            return quest.CompletedTopicsOfUsers !== quest.TopicsOfUsers;
          }
        });
        break;
      case 'notesToBeRead':
        quests = quests.filter((quest) => {
          return quest.Number_Of_Notes !== quest.Number_of_Readed_Notes;
        });
        break;
      case 'completedTopics':
        quests = quests.filter((quest) => {
          if (quest.responsible === user || quest.TopicsOfUsers === '0') {
            return quest.Completed_Topics === quest.Number_Of_Topics;
          } else {
            return quest.CompletedTopicsOfUsers === quest.TopicsOfUsers;
          }
        });
        break;
      default:
        return quests;
    }
    return quests;
  }
}
