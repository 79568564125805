<div class="flex h-full flex-col justify-center rounded-3xl bg-white p-2 font-bold text-dialog shadow-md">
  <form #f="ngForm" [formGroup]="inputForm" *ngIf="inputForm" class="mx-9 mt-3 p-4">
    <div class="flex w-full select-none justify-center gap-2 text-lg font-bold">
      <div floatLabel="never" class="ml-2 w-5/6 border-0 pb-4 text-dialog">
        <!-- <mat-label>Titolo</mat-label> -->
        <input
          class="w-full border-b-2 border-dialog pl-4 text-3xl font-bold text-dialog placeholder-login outline-none"
          type="text"
          tagName="QuestionnaireTitle"
          name="QuestionnaireTitle"
          formControlName="QuestionnaireTitle"
          required
          #QuestTitle
          maxlength="300"
          placeholder="{{ 'PROJECT_OPTIONS.PLACE_TITLE' | translate }} *"
        />
        <mat-error *ngIf="inputForm.get('QuestionnaireTitle').invalid"></mat-error>
      </div>
      <div class="w-1/12 self-center text-[1rem]">
        {{ QuestTitle.value.length }}
        / 300
      </div>
    </div>

    <div class="flex w-full select-none justify-center gap-2 text-lg font-bold">
      <div floatLabel="never" class="ml-2 w-5/6 border-0 pb-0 text-dialog">
        <!-- <mat-label>Descrizione</mat-label> -->
        <input
          class="w-full border-b-2 border-dialog pl-4 text-2xl text-dialog placeholder-login outline-none"
          type="text"
          tagName="QuestionnaireDescription"
          name="QuestionnaireDescription"
          formControlName="QuestionnaireDescription"
          #QuestDesc
          maxlength="500"
          placeholder="{{ 'PROJECT_OPTIONS.PLACE_DESCRIPTION' | translate }}"
        />
      </div>
      <div class="w-1/12 self-center text-[1rem]">
        {{ QuestDesc.value.length }}
        / 500
      </div>
    </div>
  </form>
</div>
