import { createAction, props } from '@ngrx/store';
import { Question } from 'src/app/data/models/Question';

export const enum ActionQuestionTypes {
  addQuestionAction = '[Question] add question',
  duplicateQuestionAction = '[Question] duplicate question',
  deleteQuestionAction = '[Question] delete Question Action',
  updateQuestionAction = '[Question] update a topic',
  loadQuestionAction = '[Question] add a new topic',
}

export const addQuestion = createAction(
  ActionQuestionTypes.addQuestionAction,
  props<{ index: number; question: Question }>()
);

export const deleteQuestion = createAction(
  ActionQuestionTypes.deleteQuestionAction,
  props<{ index: number; idsToBeRemoved: number[]; typeOfDeletion: string }>()
);

export const updateQuestion = createAction(
  ActionQuestionTypes.updateQuestionAction,
  props<{
    question: Question;
    topicIndex: number;
    changedPosition: { flag: boolean; oldPosition: number };
  }>()
);

export const duplcateQuestionAction = createAction(
  ActionQuestionTypes.duplicateQuestionAction,
  props<{ topicIndex: number; question: Question }>()
);
