import { ElencoTopic } from 'src/app/data/models/ElencoTopic';
import { Question } from 'src/app/data/models/Question';
import { GeneralDialogService } from '../general-dialog.service';

export interface AddQuestionInterface {
  Topic: ElencoTopic;
  action: 'Modify' | 'Add';
  TopicIndex: number;
  currentQuestion: Question;
  dialogService?: GeneralDialogService;
  readOnlyMode?: boolean;
  topicsWithQuestions?: any[];
  childAndParentQuestionsWithPositions?: { id: string; Position: number }[];
  modelStatus?: string;
}

export const TYPE_OF_QUEST_OPTIONS = [
  { value: 'Free text', viewValue: 'Free text', viewValueIT: 'Testo libero' },
  { value: 'List box', viewValue: 'List box', viewValueIT: 'List box' },
  {
    value: 'Check-list box',
    viewValue: 'Check-list box',
    viewValueIT: 'Check-list box',
  },
  { value: 'Yes or no', viewValue: 'Yes/no', viewValueIT: 'Si/No' },
  { value: 'File', viewValue: 'File', viewValueIT: 'File' },
];

export const TYPE_OF_VERIFICATIONS_OPTIONS = [
  {
    value: 'Date',
    viewValue: 'Date (xx/xx/xx)',
    viewValueIT: 'Data (xx/xx/xx)',
  },
  {
    value: 'Email',
    viewValue: 'Email (xxxxx@xxxxx)',
    viewValueIT: 'Email (xxxxx@xxxxx)',
  },
  { value: 'Time', viewValue: 'Time (xx:xx)', viewValueIT: 'Ora (xx:xx)' },
  {
    value: 'URL',
    viewValue: 'URL (https://xxx/xxxx)',
    viewValueIT: 'URL (https://xxx/xxxx)',
  },
  {
    value: 'customized',
    viewValue: 'Customized',
    viewValueIT: 'Personalizzata',
  },
];

export const TYPE_OF_ANSWER_OPTIONS = [
  { value: 'Text', viewValue: 'Text', viewValueIT: 'Testo' },
  { value: 'Number', viewValue: 'Numeric', viewValueIT: 'Numerico' },
];

export const FILE_OPTIONS = [
  { value: 'Image', viewValue: 'Image' },
  { value: 'Video', viewValue: 'Video' },
  { value: 'PDF', viewValue: 'PDF' },
  { value: 'Excel', viewValue: 'Excel' },
  { value: 'Word', viewValue: 'Word' },
  { value: 'Presentation', viewValue: 'Presentation' },
  { value: 'Any', viewValue: 'Any' },
];
