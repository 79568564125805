<div class="analytics-container flex h-full flex-col overflow-hidden rounded-3xl bg-[#f7f9fb]">
  <mat-tab-group mat-align-tabs="start" class="h-full">
    <mat-tab class="h-full w-full text-4xl">
      <ng-template mat-tab-label class="mr-4">
        {{ "ANALYTICS_PANEL.OVERALL" | translate }}
      </ng-template>
      <analytics-panel-overall [questionnaires]="questionnaireData" [topics]="topicData" [questions]="questionData">
      </analytics-panel-overall>
    </mat-tab>
    <mat-tab class="w-full text-4xl">
      <ng-template mat-tab-label>
        {{ "ANALYTICS_PANEL.DETAILS" | translate }}
      </ng-template>
      <analytics-panel-details [topics]="analyticsData.topics" [project]="analyticsData.project">
      </analytics-panel-details>
    </mat-tab>

    <mat-tab disabled class="z-50 flex w-full p-0">
      <ng-template mat-tab-label class="flex w-full p-0">
        <analytics-filter [projectID]="projectID"></analytics-filter>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
