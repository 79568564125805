import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isConstraint',
})
export class IsConstraintPipe implements PipeTransform {
  transform(value: any, constraintOptions: string[], question: any): boolean {
    if (value === undefined || value === null) {
      return false;
    } else {
      let optionValue;
      // checkbox list
      if (Array.isArray(value['Input'])) {
        // answers are set by id so we set them by value to compare
        let answersSetWithValues = [];
        value['Input'].forEach((answer) => {
          question.Check_List_Box_Options.forEach((option) => {
            if (answer === option.id) {
              answersSetWithValues.push(option.Answer_Value);
            }
          });
        });
        // if every constraintOptions includes in answersSetWithValues true
        return constraintOptions.every((val) => answersSetWithValues.includes(val));
      } else {
        // List box
        optionValue = question.Check_List_Box_Options.filter((opt) => {
          if (value.hasOwnProperty('Input')) {
            return value['Input'] === opt.id;
          } else {
            return value[0].Answer_id === opt.id;
          }
        });
        // Yes or no
        if (!!optionValue[0] && optionValue[0].Answer_Value) {
          if (constraintOptions.includes(optionValue[0].Answer_Value)) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      }
    }
  }
}
