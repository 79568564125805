import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { Project } from 'src/app/data/models/Project';
import { ProjectActionTypes } from 'src/app/Store/projects/actions/projects.actions';
import {
  selectloadingSingleProject,
  selectProjectToBeModified,
} from 'src/app/Store/projects/selectors/projects.selectors';

import { GeneralDialogService } from 'src/app/utils/general-dialog.service';

@Component({
  selector: 'app-modify-project',
  templateUrl: './modify-project.component.html',
  styleUrls: ['./modify-project.component.scss'],
})
export class ModifyProjectComponent implements OnInit {
  projectPK = '';
  project: Observable<Project>;
  loadingProject: Observable<Boolean>;
  formChanges = {
    title: false,
    checkboxes: false,
    users: false,
    watchlist: false,
  };
  formSaved = false;

  constructor(
    private store: Store,
    private activeRoute: ActivatedRoute,
    private route: Router,
    private dialogService: GeneralDialogService
  ) {}

  ngOnInit(): void {
    try {
      this.projectPK = this.activeRoute.snapshot.paramMap.get('projectPK');
    } catch (error) {}
    this.store.dispatch({
      type: ProjectActionTypes.getSingleProjects,
      projectId: this.projectPK,
    });
    this.project = this.store.select(selectProjectToBeModified);
    this.project.subscribe((data) => {
      this.store.dispatch({ type: ProjectActionTypes.setActiveProject, activeProject: data });
    });
    this.loadingProject = this.store.select(selectloadingSingleProject);
  }
  formChanged(formStatus) {
    this.formChanges = formStatus;
  }
  save(event) {
    this.formSaved = event;
  }
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate() {
    if (!this.formSaved) {
      if (
        this.formChanges.title ||
        this.formChanges.checkboxes ||
        this.formChanges.users ||
        this.formChanges.watchlist
      ) {
        history.pushState(null, null, this.route.url);
        this.dialogService.openConfirmDialog({
          info: CONFIRMDIALOGSTRINGS.CANCEL_MODIFICATION_CREATION,
        });
        this.dialogService.openConfirmDialogResponse();
        return this.dialogService.confirmDialog.componentInstance.navigateAwaySelection$;
      } else {
        return true;
      }
    }
    return true;
  }
}
