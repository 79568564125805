<div class="userTabs-container flex h-full w-full flex-col items-center">
  <div class="flex flex-col">
    <span class="mt-4 mb-12 text-3xl font-semibold text-main">
      {{ "ADMIN_USER_TABS.HEADER" | translate }}
    </span>

    <mat-tab-group [(selectedIndex)]="selectedTab" mat-align-tabs="start" class="mb-12 min-w-[66vw]">
      <mat-tab class="h-full w-full text-4xl">
        <ng-template mat-tab-label>
          {{ "ADMIN_USER_TABS.PROFILE" | translate }}
        </ng-template>
        <app-profile-tab [missingValue]="profileMissing" (newProfile)="newProfileHandler($event)"> </app-profile-tab>
      </mat-tab>

      <mat-tab class="h-full w-full text-4xl">
        <ng-template mat-tab-label>
          {{ "ADMIN_USER_TABS.RIGHTS" | translate }}
        </ng-template>
        <app-rights-tab [missingValue]="rightsMissing" (newUsernamePassword)="newUsernamePasswordHandler($event)">
        </app-rights-tab>
      </mat-tab>

      <mat-tab class="h-full w-full text-4xl">
        <ng-template mat-tab-label>
          {{ "ADMIN_USER_TABS.ACCESS" | translate }}
        </ng-template>
        <app-access-tab [missingValue]="accessMissing" (newAccess)="newAccessHandler($event)"> </app-access-tab>
      </mat-tab>

      <mat-tab class="h-full w-full text-4xl">
        <ng-template mat-tab-label>
          {{ "ADMIN_USER_TABS.USER_VISIBILITY" | translate }}
        </ng-template>
        <app-user-visibility-tab
          [missingValue]="userVisibilityMissing"
          (newUserVisibility)="newUserVisibilityHandler($event)"
        >
        </app-user-visibility-tab>
      </mat-tab>

      <mat-tab class="h-full w-full text-4xl">
        <ng-template mat-tab-label>
          {{ "DICTIONARY.Modello" | translate }} & {{ "DICTIONARY.Progetto" | translate }}
          {{ "ADMIN_USER_TABS.VISIBILITY" | translate }}
        </ng-template>
        <app-model-project-visibility-tab
          [missingValue]="modelVisibilityMissing"
          (newModelVisibility)="newModelVisibilityHandler($event)"
        >
        </app-model-project-visibility-tab>
      </mat-tab>

      <mat-tab class="h-full w-full text-4xl">
        <ng-template mat-tab-label>
          {{ "ADMIN_USER_TABS.ORGANIZATION" | translate }}
        </ng-template>
        <app-organization-tab [missingValue]="organizationMissing" (newOrganization)="newOrganizationHandler($event)">
        </app-organization-tab>
      </mat-tab>
    </mat-tab-group>

    <div id="user-main-buttons" class="flex items-center gap-10 self-center text-xl">
      <button id="user-back-button" class="whiteBtn" (click)="goBackToTable()">
        {{ "ADMIN_PAGE.GO_TO_HOME" | translate }}
      </button>
      <button id="user-save-button" class="flex items-center justify-center gap-2 colorBtn" (click)="saveHandler()">
        <mat-icon>save</mat-icon>
        <span>{{ "SALVA" | translate }}</span>
      </button>
    </div>
  </div>
</div>
