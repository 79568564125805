import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'src/app/data/models/User';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { PrepositionDictionaryPipe } from 'src/app/utils/pipes/preposition-dictionary.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'changeUserOrAdd',
  templateUrl: './change-responsible.component.html',
  styleUrls: ['./change-responsible.component.scss'],
})
export class ChangeResponsibleComponent implements OnInit, OnDestroy {
  @Input()
  selectionMode = true;

  @Input()
  selectedUsers: User[];

  @Input()
  isModified = false;

  @Input()
  isCollaborative: boolean;

  @Input()
  isActive: boolean;

  @Input()
  usedForWatchlist = false;

  @Input()
  usedforChangeResponsabile: boolean = false;

  @Input()
  questionnaireOwner: string;

  @Input()
  watchListFullUsers: User[] = [];

  @Output()
  hideTableEvent = new EventEmitter<boolean>();

  @Output()
  changeResponsibleEvent = new EventEmitter<User | User[]>();

  @Output()
  multipleUserEvent = new EventEmitter<User>();

  @Output()
  collaborativeOptionSelected = new EventEmitter<string>();

  oldUserChecked = 0;
  numUsersChecked = 0;

  enableColl: boolean;

  enableUser = false;

  userToBeResponsible: User | User[];

  topicName: string;

  questionariName: string;

  responsabileName: string;

  lang: string;
  langToUnsubscribe: Subscription;

  prepositionPipe = new PrepositionDictionaryPipe();

  title: string = 'Seleziona gli utenti cui assegnare i questionari';

  constructor(private store: Store, private magicString: MagicStringsService) {}

  ngOnInit(): void {
    this.topicName = this.magicString.Topic;
    this.questionariName = this.magicString.Questionari;
    this.responsabileName = this.magicString.Responsabile;

    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
      this.title =
        this.lang === 'it'
          ? 'Seleziona gli utenti cui assegnare i ' + this.questionariName.toLowerCase()
          : 'Select the users for the ' + this.magicString.QuestionariENG.toLowerCase() + ' assignment';

      if (this.usedforChangeResponsabile) {
        this.title =
          this.lang === 'it'
            ? 'Seleziona il Gestore ' +
              this.prepositionPipe.transform('di ' + this.magicString.Progetto, 'singular', this.lang)
            : 'Select the Manager of the ' + this.magicString.ProgettoENG;
      }

      if (this.usedForWatchlist) {
        this.title = this.lang === 'it' ? 'Aggiungi utenti alla watchlist' : 'Add users to the watchlist';
      }
    });

    if (this.isCollaborative && this.isActive) {
      this.enableColl = false;
    } else {
      this.enableColl = true;
    }
  }

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
  }

  hideTable() {
    this.hideTableEvent.emit(false);
  }

  changeResponsible() {
    this.changeResponsibleEvent.emit(this.userToBeResponsible);
    this.hideTableEvent.emit(false);
  }

  setUserResponsible(user: User) {
    this.enableUser = true;
    if (this.selectionMode) {
      this.userToBeResponsible = user;
    } else {
      this.multipleUserEvent.emit(user);
    }
  }

  collaborativeOptions(option: Event) {
    this.enableColl = true;
    this.collaborativeOptionSelected.emit(option['value']);
  }

  getNumChecked(num) {
    this.oldUserChecked = this.numUsersChecked;
    this.numUsersChecked = num;
  }
}
