<h1 mat-dialog-title>Forgot Password?</h1>
<div mat-dialog-content>
  <p style="text-align: justify">
    Enter your email address below to reset your password. You will be sent an email which you will need to open to
    continue. You many need to check your spam folder.
  </p>
  <mat-form-field class="example-full-width my-form-field" style="width: 100%">
    <mat-label>Email</mat-label>
    <input matInput type="text" [(ngModel)]="emailForgotPassword" placeholder="Enter your Email" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="emailForgotPassword">Reset Password</button>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>Cancel</button>
</div>
