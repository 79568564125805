import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdminGroupsService {
  constructor(private http: HttpClient) {}

  public getAllClients(pageIndexValue, pageSizeValue, sortColValue, sortOrderValue, searchString?: string) {
    if (searchString !== '' && searchString !== null) {
      var getString = `?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortColValue}&sortOrder=${sortOrderValue}&searchString=${encodeURIComponent(
        searchString
      )}`;
    } else {
      var getString = `?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortColValue}&sortOrder=${sortOrderValue}`;
    }
    return this.http.get(`adminGroups/getAllClients${getString}`);
  }

  public createClient(name: string, description: string, imageLocation: File) {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);
    formData.append('imageLocation', imageLocation);

    return this.http.post('adminGroups/createClient', formData);
  }

  public updateClient(id: string, name: string, description: string, image: File) {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);
    formData.append('image', image);

    return this.http.put(`adminGroups/updateClient/${id}`, formData);
  }

  public deleteClient(client_id: string) {
    return this.http.delete(`adminGroups/deleteClient/${client_id}`);
  }

  public createGroup(client_id: string, group_id: string, name: string, description: string) {
    let body = {
      client_id: client_id,
      group_id: group_id,
      name: name,
      description: description,
    };

    return this.http.post(`adminGroups/createGroup`, body);
  }

  public getGroupWithUsers(
    group_id: string,
    pageIndexValue,
    pageSizeValue,
    sortColValue,
    sortOrderValue,
    searchString?: string
  ) {
    if (searchString !== '' && searchString !== null) {
      var getString = `?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortColValue}&sortOrder=${sortOrderValue}&searchString=${encodeURIComponent(
        searchString
      )}`;
    } else {
      var getString = `?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortColValue}&sortOrder=${sortOrderValue}`;
    }
    return this.http.get(`adminGroups/getGroupWithUsers/${group_id}${getString}`);
  }

  public updateGroup(group_id: string, name: string, description: string) {
    let body = {
      name: name,
      description: description,
    };

    return this.http.put(`adminGroups/updateGroup/${group_id}`, body);
  }

  public getGroupsByClientId(
    client_id: string,
    pageIndexValue,
    pageSizeValue,
    sortColValue,
    sortOrderValue,
    searchString?: string
  ) {
    if (searchString !== '' && searchString !== null) {
      var getString = `?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortColValue}&sortOrder=${sortOrderValue}&searchString=${encodeURIComponent(
        searchString
      )}`;
    } else {
      var getString = `?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortColValue}&sortOrder=${sortOrderValue}`;
    }

    return this.http.get(`adminGroups/getGroupsByClientId/${client_id}${getString}`);
  }

  public deleteGroup(group_id: any[]) {
    let body = {
      group_id: group_id,
    };

    return this.http.post(`adminGroups/deleteGroup`, body);
  }
}
