import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsState } from '../notifications-state';

export const notificationState = createFeatureSelector<NotificationsState>('notifications');
export const selectUnReadNotifications = createSelector(notificationState, (state) => state?.notifications.filter((note)=>!note.Notification_Viewed));

export const selectReadNotifications = createSelector(notificationState, (state) => state?.notifications.filter((note)=>note.Notification_Viewed));

export const selectNotifications = createSelector(
  selectUnReadNotifications,
  selectReadNotifications,
  (unRead, read) => {
    if (!!unRead && unRead.length > 0) {
      let length = 0;
      unRead.forEach((notif) => {
        if (!notif.Notification_Viewed) {
          length++;
        }
      });
      if (length < 10) {
        return {
          length: length,
          notifications: unRead.concat(read).slice(0, 9),
        };
      } else {
        return { length: length, notifications: unRead };
      }
    } else {
      return { length: unRead?.length, notifications: read };
    }
  }
);
