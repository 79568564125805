<ng-container>
  <div id="q-text" class="mb-6 flex items-center justify-between">
    <div
      matTooltip="{{ question.Question_Text }}"
      matTooltipClass="custom-tooltip"
      appShowIfTruncated
      class="line-clamp-2"
    >
      <strong class="text-xl">{{ question.Position }}</strong>
      <span
        class="text-xl"
        (contextmenu)="
          copyToClipBoard(question.Question_Text);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.TITLE_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
      >
        - {{ question.Question_Text }}
      </span>
    </div>

    <mat-icon
      *ngIf="question.Description !== ''"
      (click)="toggleDescriptionVisibility()"
      matTooltip="{{ plainText }}"
      class="ml-4 cursor-pointer text-[35px] text-main"
      >help
    </mat-icon>
  </div>

  <div [ngSwitch]="question.TypeOfVerificationInput">
    <ng-container *ngSwitchCase="null">
      <mat-form-field appearance="outline" class="w-full">
        <input
          matInput
          name="question.id"
          [disabled]="reviewMode"
          [(ngModel)]="textValue"
          (ngModelChange)="formChanged(textValue)"
          [type]="question.TypeOfAnswer === 'Text' ? 'text' : 'number'"
          name="Input"
          [placeholder]="question.TypeOfAnswer !== 'Text' ? 'Numeric' : ''"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'Date'">
      <mat-form-field appearance="outline" class="w-full">
        <input
          type="date"
          matInput
          name="Input"
          [disabled]="reviewMode"
          [(ngModel)]="textValue"
          (ngModelChange)="formChanged(textValue)"
          placeholder="{{ this.placeholder }}"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'Time'">
      <mat-form-field appearance="outline" class="w-full">
        <input
          type="time"
          [disabled]="reviewMode"
          [(ngModel)]="textValue"
          (ngModelChange)="formChanged(textValue)"
          matInput
          name="Input"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'URL'">
      <mat-form-field appearance="outline" class="w-full">
        <input
          type="text"
          matInput
          name="Input"
          [disabled]="reviewMode"
          [(ngModel)]="textValue"
          (ngModelChange)="formChanged(textValue)"
          placeholder="{{ this.placeholder }}"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'Email'">
      <mat-form-field appearance="outline" class="w-full">
        <input
          type="email"
          matInput
          name="Input"
          [disabled]="reviewMode"
          [(ngModel)]="textValue"
          (ngModelChange)="formChanged(textValue)"
          placeholder="{{ this.placeholder }}"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'customized'">
      <mat-form-field appearance="outline" class="w-full">
        <input
          type="text"
          [disabled]="reviewMode"
          [(ngModel)]="textValue"
          (ngModelChange)="formChanged(textValue)"
          matInput
          name="question.id"
        />
      </mat-form-field>
    </ng-container>
  </div>
</ng-container>

<div id="error" class="text-base text-red-700" *ngIf="freeTextForm.invalid">
  <strong>{{ this.patternText }}</strong>
</div>

<div
  *ngIf="showDescription"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showDescription }"
  (click)="toggleDescriptionVisibility()"
>
  <div
    id="selections-div"
    class="flex h-[fit-content] w-[55rem] flex-col justify-between gap-6 overflow-hidden rounded-2xl bg-white p-6"
    (click)="$event.stopPropagation()"
  >
    <div id="title" class="font-popppins text-4xl font-bold text-card">
      {{ "DESCRIZIONE" | translate }}
    </div>
    <div [innerHTML]="question.Description" class="py-[3rem] text-base">
      {{ question.Description }}
    </div>
    <div class="mt-[10px] flex flex-row !justify-end">
      <button
        (click)="
          copyToClipBoard(plainText);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.DESCRIPTION_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
        class="mr-3 whiteBtn"
      >
        {{ "QUEST_CREATION.DESC_COP" | translate }}
      </button>
      <button class="colorBtn" (click)="toggleDescriptionVisibility()">
        {{ "UNDERSTOOD" | translate }}
      </button>
    </div>
  </div>
</div>
