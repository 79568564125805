<div
  id="myQuestion"
  class="grid h-full grid-cols-1 grid-rows-customRowsQuest gap-3 rounded-3xl bg-white p-5 shadow-md"
  *ngIf="dragAndDropHelpers$ | async"
>
  <div *ngIf="currentTopic !== undefined" class="row-start-1 flex h-[fit-content] flex-col px-2 py-1 text-dialog">
    <h1
      class="w-5/6 whitespace-normal break-words p-0 text-2xl font-semibold line-clamp-2"
      matTooltip="{{ currentTopic.Title }}"
    >
      {{ currentTopic.Title }}
    </h1>
    <h2
      class="w-5/6 whitespace-normal break-words p-0 text-xl line-clamp-2"
      matTooltip="{{ currentTopic.Description }}"
    >
      {{ currentTopic.Description }}
    </h2>
  </div>

  <div
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    id="QuestionsScroll"
    class="row-start-2 flex flex-col overflow-auto"
    *ngIf="currentTopic !== undefined && currentTopic?.Questions.length > 0"
  >
    <div
      id="singleQuestion"
      cdkDrag
      [cdkDragDisabled]="Model_Status === 'Archived'"
      *ngFor="let question of currentTopic?.Questions; let i = index"
      class="relative mx-5 mb-6 flex h-[4rem] w-11/12 cursor-pointer items-center justify-between self-start rounded-lg bg-whiteBg p-1 shadow-md"
    >
      <div class="w-[90%]">
        <img
          class="absolute top-0 left-0"
          src="assets/images/Risposta_Obbligatoria_sea.svg"
          *ngIf="question.Obligatory && appSvgTheme === 0"
        />
        <img
          class="absolute top-0 left-0"
          src="assets/images/Risposta_Obbligatoria_montain.svg"
          *ngIf="question.Obligatory && appSvgTheme === 1"
        />
        <img
          class="absolute top-0 left-0"
          src="assets/images/Risposta_Obbligatoria_forest.svg"
          *ngIf="question.Obligatory && appSvgTheme === 2"
        />
        <p
          class="overflow-hidden overflow-ellipsis whitespace-nowrap pl-5 text-xl font-semibold"
          appShowIfTruncated="questionCreation"
          matTooltip="{{ question.Question_Text }}"
        >
          <strong>{{ (i < 9 ? "00" : i < 99 ? "0" : "") + (i + 1) + " - " }}</strong>
          {{ question.Question_Text }}
        </p>

        <p
          class="overflow-hidden overflow-ellipsis whitespace-nowrap pl-5"
          appShowIfTruncated
          matTooltip="{{ question.Description }}"
        >
          {{ question.Description }}
        </p>
      </div>

      <div class="flex h-[fit-content] w-[fit-content] justify-evenly">
        <mat-menu #menu="matMenu">
          <button
            *ngIf="Model_Status === 'Archived'"
            (click)="modifyQuestion(question, i)"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>launch</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_OPEN" | translate }}</span>
          </button>
          <button
            *ngIf="Model_Status !== 'Archived'"
            (click)="modifyQuestion(question, i)"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>edit</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_EDIT" | translate }}</span>
          </button>
          <button
            *ngIf="ReadOnlyMode === false && Model_Status !== 'Archived'"
            (click)="deleteQuestion(question, i)"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>delete</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_DELETE" | translate }}</span>
          </button>
          <button *ngIf="ReadOnlyMode" class="cursor-pointer" disabled class="flex items-center" mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_DELETE" | translate }}</span>
          </button>
          <button
            *ngIf="ReadOnlyMode === false && Model_Status !== 'Archived'"
            (click)="duplicateQuestion(question, i)"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>content_copy</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_CLONE" | translate }}</span>
          </button>
          <button *ngIf="ReadOnlyMode" class="cursor-pointer" disabled class="flex items-center" mat-menu-item>
            <mat-icon class="cursor-pointer">content_copy</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_CLONE" | translate }}</span>
          </button>
        </mat-menu>

        <div class="flex justify-evenly">
          <button
            mat-icon-button
            (click)="showQuestionOptions(question, i)"
            matTooltip="Menu"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon cdkDragHandle>drag_handle</mat-icon>
          </button>
        </div>
      </div>

      <div
        class="absolute right-0 z-50 m-0 flex h-4 w-2 select-none flex-col justify-center overflow-hidden rounded-l-full bg-redCustom p-4 transition-all duration-500 ease-in-out"
        [ngClass]="{
          'h-7 w-28 p-4 text-white transition-all duration-500 ease-in-out': showTree[question.id] === true
        }"
        *ngIf="!!constraintsParentHelperObj[question.id]"
        (click)="showOrHideTree(question.id)"
      >
        <span
          class="text-white"
          [ngClass]="
            showTree[question.id] === true ? 'block text-white transition-all duration-500 ease-in-out' : 'hidden'
          "
          >{{ "QUEST_CREATION.BINDING" | translate }}</span
        >
      </div>
      <div
        class="absolute right-0 z-50 m-0 flex h-4 w-2 select-none flex-col justify-center overflow-hidden rounded-l-full bg-redCustomShade p-4 transition-all duration-500 ease-in-out"
        [ngClass]="{
          'h-7 w-28 p-4 text-white transition-all duration-500 ease-in-out':
            showTree[dragAndDropChecker[question.id].constrainedId]
        }"
        *ngIf="question.Constrained"
        (click)="showOrHideTree(dragAndDropChecker[question.id].constrainedId)"
      >
        <span
          class="text-white"
          [ngClass]="
            showTree[dragAndDropChecker[question.id].constrainedId]
              ? 'block text-white transition-all duration-500 ease-in-out'
              : 'hidden'
          "
          >{{ "QUEST_CREATION.DEPENDENT" | translate }}</span
        >
      </div>
      <ng-container *ngIf="!!constraintsParentHelperObj[question.id]">
        <app-vincolata-lines
          *ngIf="showTree[question.id]"
          class="relative left-4 bottom-4 transition-all duration-500 ease-in-out"
          [parentIndex]="i"
          [childIndexes]="constraintsParentHelperObj[question.id]"
        ></app-vincolata-lines>
      </ng-container>
    </div>
  </div>
  <div class="row-start-3 flex items-center justify-end">
    <button
      class="colorBtn disabled:colorBtn-disabled"
      [disabled]="ReadOnlyMode"
      (click)="addQuestion()"
      *ngIf="ReadOnlyMode === false && Model_Status !== 'Completed' && Model_Status !== 'Archived'"
    >
      {{ "QUEST_CREATION.ADD_QUESTION" | translate }}
    </button>
  </div>
</div>
