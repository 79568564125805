<div id="topics-container-grid" class="h-full w-full grid grid-rows-[min-content] gap-6">
  <div id="title-area" class="flex items-center justify-between">
    <div id="title-text" class="text-2xl text-card font-semibold" [ngSwitch]="lang">
      {{ questionnaire["Completed_Topics"] }}/{{ questionnaire["Number_Of_Topics"] }}
      <p *ngSwitchCase="'it'">
        {{ "DICTIONARY.Topic" | translate | titlecase }}
        {{ "completato " + ("DICTIONARY.Topic" | translate | lowercase) | verbPronounDictionary: "plural":"it" }}
      </p>
      <p *ngSwitchCase="'en'">{{ "DICTIONARY.Topic" | translate | titlecase }} completed</p>
    </div>
    <div id="title-icons" class="flex flex-row">
      <div id="icon-topic-filter">
        <button class="filter-menu-Option" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon
            id="topic-icon"
            matTooltip="{{ 'QUEST_ANS.TOOLTIP_FILTER' | translate | titlecase }}"
            matTooltipClass="custom-tooltip"
            class="text-dialog !inline-flex !items-center"
            >filter_list
          </mat-icon>
          <mat-menu #menu="matMenu" id="filter-menu" class="p-2 pr-4">
            <div class="inline-flex items-center gap-2">
              <button
                [ngClass]="{ 'opacity-30': 'No Filter' === filterOfTopics }"
                (click)="filterTopics('No Filter')"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="'No Filter' !== filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_unchecked
                </mat-icon>
                <mat-icon
                  *ngIf="'No Filter' === filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_checked
                </mat-icon>
              </button>
              <p class="text-xl" [ngClass]="{ 'opacity-30': 'No Filter' === filterOfTopics }">
                {{ "QUEST_ANS.FILTER_NONE" | translate }}
              </p>
            </div>
            <div class="inline-flex items-center gap-2">
              <button
                [ngClass]="{ 'opacity-30': 'Draft' === filterOfTopics }"
                (click)="filterTopics('Draft')"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="'Draft' !== filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                  >radio_button_unchecked
                </mat-icon>
                <mat-icon
                  *ngIf="'Draft' === filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                  >radio_button_checked
                </mat-icon>
              </button>
              <p class="text-xl" [ngClass]="{ 'opacity-30': 'Draft' === filterOfTopics }">
                {{ "QUEST_ANS.FILTER_NOTCOMPLETED" | translate | titlecase }}
              </p>
            </div>
            <div class="inline-flex items-center gap-2">
              <button
                [ngClass]="{ 'opacity-30': 'In Progress' === filterOfTopics }"
                (click)="filterTopics('In Progress')"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="'In Progress' !== filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_unchecked
                </mat-icon>
                <mat-icon
                  *ngIf="'In Progress' === filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_checked
                </mat-icon>
              </button>
              <p class="text-xl" [ngClass]="{ 'opacity-30': 'In Progress' === filterOfTopics }">
                {{ "QUEST_ANS.FILTER_PROGRESS" | translate | titlecase }}
              </p>
            </div>
            <div class="inline-flex items-center gap-2">
              <button
                [ngClass]="{
                  'opacity-30': 'Assigned_To_Me' === filterOfTopics
                }"
                (click)="filterTopics('Assigned_To_Me')"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="'Assigned_To_Me' !== filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_unchecked
                </mat-icon>
                <mat-icon
                  *ngIf="'Assigned_To_Me' === filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_checked
                </mat-icon>
              </button>
              <p
                class="text-xl"
                [ngClass]="{
                  'opacity-30': 'Assigned_To_Me' === filterOfTopics
                }"
                [ngSwitch]="lang"
              >
                <span *ngSwitchCase="'it'"
                  >Mostra solo {{ "DICTIONARY.Topic" | translate | lowercase }} a me
                  {{
                    "assegnato " + ("DICTIONARY.Topic" | translate | lowercase) | verbPronounDictionary: "plural":"it"
                  }}</span
                >
                <span *ngSwitchCase="'en'">Shows mine {{ "DICTIONARY.Topic" | translate | lowercase }} only</span>
              </p>
            </div>
            <div class="inline-flex items-center gap-2">
              <button
                [ngClass]="{
                  'opacity-30': 'Assigned_To_Others' === filterOfTopics
                }"
                (click)="filterTopics('Assigned_To_Others')"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="'Assigned_To_Others' !== filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                  >radio_button_unchecked
                </mat-icon>
                <mat-icon
                  *ngIf="'Assigned_To_Others' === filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                  >radio_button_checked
                </mat-icon>
              </button>
              <p
                class="text-xl"
                [ngClass]="{
                  'opacity-30': 'Assigned_To_Others' === filterOfTopics
                }"
                [ngSwitch]="lang"
              >
                <span *ngSwitchCase="'it'"
                  >Mostra solo {{ "DICTIONARY.Topic" | translate | lowercase }}
                  {{
                    "assegnato " + ("DICTIONARY.Topic" | translate | lowercase) | verbPronounDictionary: "plural":"it"
                  }}
                  ad altri</span
                >
                <span *ngSwitchCase="'en'">Shows other {{ "DICTIONARY.Topic" | translate | lowercase }} only</span>
              </p>
            </div>
            <div class="inline-flex items-center gap-2">
              <button
                [ngClass]="{ 'opacity-30': 'Completed' === filterOfTopics }"
                (click)="filterTopics('Completed')"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="'Completed' !== filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_unchecked
                </mat-icon>
                <mat-icon
                  *ngIf="'Completed' === filterOfTopics"
                  id="topic-icon"
                  class="text-dialog !inline-flex !items-center"
                >
                  radio_button_checked
                </mat-icon>
              </button>
              <p class="text-xl" [ngClass]="{ 'opacity-30': 'Completed' === filterOfTopics }">
                {{ "QUEST_ANS.FILTER_COMPLETED" | translate | titlecase }}
              </p>
            </div>
          </mat-menu>
        </button>
      </div>
      <div id="icon-topic-notes">
        <button (click)="getNotes()" mat-icon-button>
          <mat-icon
            matTooltip="{{ 'QUEST_ANS.TOOLTIP_NOTES' | translate | titlecase }}"
            matTooltipClass="custom-tooltip"
            [matBadge]="this.numberOfUnreadedNotes"
            id="topic-icon"
            class="text-dialog !inline-flex !items-center"
            >sms</mat-icon
          >
        </button>
      </div>
    </div>
  </div>
  <div id="topics-area" class="flex flex-col p-1 gap-4 items-center justify-start overflow-y-auto overflow-x-hidden">
    <ng-container *ngFor="let topic of questionnaire.Topics | hideCompleted: filterOfTopics; index as i">
      <div
        *ngIf="
          questionnaire.Assigned_To === currentUser ||
          questionnaire.VIEWABLE_FROM_ALL ||
          topic.Assigned_To === currentUser ||
          countTopicsOfUser === 0
        "
        [ngClass]="{
          '!bg-bg shadow-inner': topic.Order - 1 === activeTopicIndex
        }"
        name="topic-card"
        class="h-[fit-content] w-full bg-[#0000000c] rounded-xl p-6 cursor-pointer shadow relative"
        id="item-{{ i }}"
      >
        <button
          *ngIf="
            ((questionnaire.Assigned_To === currentUser && questionnaire.Collaborative) ||
            (topic.Assigned_To === currentUser && questionnaire.ALL_REASSIGN)) && topic.Order - 1 === activeTopicIndex
          "
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="!absolute top-4 right-2"
        >
          <mat-icon
            matTooltip="{{ 'QUEST_ANS.TOOLTIP_ACTIONS' | translate | titlecase }}"
            matTooltipClass="custom-tooltip"
            class="!inline-flex !items-center"
            >more_vert
          </mat-icon>
          <mat-menu #menu="matMenu">
            <div id="topic-content-actions">
              <button (click)="reAssignTopic(topic)" mat-menu-item>
                <mat-icon>account_circle</mat-icon>
                {{ "QUEST_ANS.TOPIC_REASSIGN" | translate | titlecase }}
              </button>
            </div>
          </mat-menu>
        </button>
        <div id="topic-content" class="flex flex-col gap-2" (click)="setActiveTopic($event, topic.Order - 1)">
          <div id="topic-content-title" class="flex items-center justify-between text-main text-base">
            <h3 class="text-wrap" appShowIfTruncated matTooltip="{{ topic.Title }}" matTooltipClass="custom-tooltip">
              {{ (topic.Order < 9 ? "00" : i < 99 ? "0" : "") + topic.Order + " - " }}{{ topic.Title }}
            </h3>
          </div>
          <div id="topic-subcontent">
            <div id="topic-content-row" class="px-6">
              <div id="topic-content-text" class="text-constrast">
                <span id="data-text" class="font-opensans text-base"
                  >{{ "QUEST_ANS.TOPIC_STATUS" | translate | titlecase }}:</span
                >
                <span id="data-text" class="font-opensans text-base">
                  {{ topic.Assigned_Status | convertToItalian: lang
                  }}<span *ngIf="!!topic.Compile_from">{{ ", " + getUsername[topic.Compile_from] }}</span>
                </span>
              </div>
              <div class="fake"></div>
            </div>
            <div id="topic-content-row" class="pl-6 flex flex-row gap-6 items-center justify-between">
              <span id="topic-content-text" class="text-constrast">
                <span id="data-text" class="font-opensans text-base"
                  >{{ "QUEST_ANS.TOPIC_USER" | translate | titlecase }}:
                </span>
                <span id="data-text" class="font-opensans text-base">
                  {{ getUsername[topic.Assigned_To] }}
                </span>
              </span>
              <div
                (click)="$event.stopPropagation()"
                *ngIf="
                  questionnaire.Assigned_To === currentUser
                    ? reAssignMultiplaMode && questionnaire.Collaborative
                      ? true
                      : false
                    : topic.Assigned_To === currentUser && reAssignMultiplaMode && questionnaire.Collaborative
                "
                id="checkbox"
                class="inline-flex"
              >
                <input (change)="addRemoveTopic($event, topic)" type="checkbox" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="shouldShowMultipla" id="buttons-area" class="flex items-end justify-end">
    <button
      [ngClass]="{ disabled: !shouldShowMultipla }"
      (click)="openMultiplaMode()"
      *ngIf="!reAssignMultiplaMode"
      class="whiteBtn"
    >
      {{ "QUEST_ANS.TOPIC_BTN" | translate | uppercase }}
    </button>
    <div id="mutlipla-buttons" *ngIf="reAssignMultiplaMode" class="flex justify-end items-end gap-6">
      <button (click)="closeMultiplaMode()" class="whiteBtn">
        {{ "ANNULLA" | translate }}
      </button>
      <button [disabled]="topicsChecked.length === 0" (click)="reassignMultiplaMode()" class="colorBtn">
        {{ "CONFERMA" | translate }}
      </button>
    </div>
  </div>
</div>
<div *ngIf="overlay" class="overlayHidden" [ngClass]="{ overlayShow: overlay }">
  <div class="user-selection-div" (click)="$event.stopPropagation()">
    <app-modify-topic
      (cancelOut)="cancel()"
      (reAssignOut)="reAssingMultiple($event)"
      [selectedUser]="assignedUser"
    ></app-modify-topic>
  </div>
</div>
<ng-container *ngIf="topicLoading | async"></ng-container>
