import { createReducer, on } from '@ngrx/store';
import { reOrder, insert, deepCopyV2, copyAndDelete, editTopic } from 'src/app/data/data-handlers/utils.service';
import { typeOfQuestionDeletion } from 'src/app/utils/config/questionnaire-table.configuration';

import { addQuestionnairesSuccess } from '../questionnaire-store/state.actions';
import { addQuestion, deleteQuestion, duplcateQuestionAction, updateQuestion } from '../questions/questions.actions';
import {
  addTopic,
  deleteTopic,
  updateTopic,
  initialTopicSetAction,
  PostQuestionnaire,
  PostQuestionnaireSuccess,
  TopicError,
  submitionForReviewSuccess,
  updateTopicsUser,
  updateTopicsUserSuccess,
  updateOneTopicsAfterDrop,
  updateAllTopicsAfterDrop,
} from './topic.actions';
import { initialTopicState } from './topics.state';

const enum reducerStrings {
  BINDING = 'Binding',
  CONSTRAINTOPTIONS = 'ConstraintOptions',
  FREETEXTCONSTRAINT = 'freeTextConstraint',
}

const _topicReducer = createReducer(
  initialTopicState,
  on(addTopic, (state, { topic }) => ({
    Topics: reOrder(topic.Order, insert(deepCopyV2(state.Topics), topic.Order - 1, topic), true),
  })),
  on(TopicError, (state) => ({
    loading: false,
  })),
  on(updateTopicsUser, (state) => ({
    loading: true,
  })),
  on(updateTopicsUserSuccess, (state) => ({
    loading: false,
  })),
  on(addQuestionnairesSuccess, (state) => ({
    Topics: [],
    loading: false,
  })),
  on(updateOneTopicsAfterDrop, (state, { topics }) => {
    const newArray = [...state.Topics]; //making a new array
    newArray[topics.Order - 1] = topics; //changing value in the new array
    return { ...state, Topics: newArray };
  }),
  on(updateAllTopicsAfterDrop, (state, { topics }) => ({
    Topics: topics,
  })),
  on(PostQuestionnaire, (state) => ({
    loading: true,
  })),
  on(PostQuestionnaireSuccess, (state) => ({
    loading: false,
  })),
  on(submitionForReviewSuccess, (state, id) => ({
    AssingedIdReviewed: id['id'],
  })),
  on(deleteTopic, (state, { index }) => {
    return {
      ...state,
      Topics: reOrder(index, copyAndDelete(state.Topics, index), false),
    };
  }),
  on(updateTopic, (state, { topic, index, indexOld }) => ({
    Topics: reOrder(topic.Order, editTopic(state.Topics.slice(), index, topic, indexOld), true),
  })),
  on(initialTopicSetAction, (state, { topics }) => ({
    ...state,
    Topics: topics,
  })),
  on(duplcateQuestionAction, (state, { topicIndex, question }) => ({
    Topics: state.Topics.map((topic) => {
      if (topic === state.Topics[topicIndex]) {
        let newQuest = JSON.parse(JSON.stringify(question));
        let newQuestions = JSON.parse(JSON.stringify(topic.Questions));

        newQuest.Position = state.Topics[topicIndex].Questions.length + 1;
        newQuestions.push(newQuest);

        return { ...topic, Questions: newQuestions };
      }
      return topic;
    }),
  })),
  on(deleteQuestion, (state, { index, idsToBeRemoved, typeOfDeletion }) => {
    const topics = deepCopyV2(state.Topics);
    switch (typeOfDeletion) {
      case typeOfQuestionDeletion.DELETEALL:
        // ids are sorted Ascending so we must remove them in reverse (slice removes and moves all the questions with bigger index -1)
        for (let q = idsToBeRemoved.length - 1; q >= 0; q--) {
          topics[index].Questions.splice(idsToBeRemoved[q], 1);
        }
        topics[index].Questions.sort((a, b) => (a.Position < b.Position ? -1 : a.Position > b.Position ? 1 : 0));
        break;
      case typeOfQuestionDeletion.DELETEONLYBINDING:
        for (let k = 1; k < idsToBeRemoved.length; k++) {
          topics[index].Questions[idsToBeRemoved[k]].Constrained = false;
          const property =
            topics[index].Questions[idsToBeRemoved[k]].ConstraintOptions !== undefined || topics[index].Questions[idsToBeRemoved[k]].ConstraintOptions[0] !== null
              ? reducerStrings.CONSTRAINTOPTIONS
              : reducerStrings.FREETEXTCONSTRAINT;
          delete topics[index].Questions[idsToBeRemoved[k]][property];
        }
        topics[index].Questions.splice(idsToBeRemoved[0], 1);
        break;
      case typeOfQuestionDeletion.DELETESIMPLEQUESTION:
        topics[index].Questions.splice(idsToBeRemoved[0], 1);
        break;
      default:
        break;
    }
    for (let k = 0; k < topics[index].Questions.length; k++) {
      topics[index].Questions[k].Position = k + 1;
    }

    return { ...state, Topics: topics };
  }),
  on(addQuestion, (state, { index, question }) => {
    const newArray = deepCopyV2(state.Topics);
    if (newArray[index].Questions.length > 0) {
      newArray[index].Questions.splice(question.Position - 1, 0, question);
      for (let k = question.Position; k < newArray[index].Questions.length; k++) {
        newArray[index].Questions[k].Position = newArray[index].Questions[k].Position + 1;
      }
    } else {
      newArray[index].Questions.push(question);
    }

    return { ...state, Topics: newArray };
  }),
  on(updateQuestion, (state, { question, topicIndex, changedPosition }) => {
    const newArray = deepCopyV2(state.Topics);

    // combine the fields (empty values are missing but we need them so state won't change)
    let oldQuestion = newArray[topicIndex].Questions[changedPosition.oldPosition - 1];
    question = { ...oldQuestion, ...question };

    // If an Answer Value changed, Change all Constraint Depedent Answer
    if (
      ['List box', 'Check-list box'].includes(question.TypeOfQuestion) &&
      question.Check_List_Box_Options.length > 0 &&
      JSON.stringify(question.Check_List_Box_Options) !== JSON.stringify(oldQuestion['Check_List_Box_Options'])
    ) {
      newArray[topicIndex].Questions.forEach((currentQuestion, questionIndex) => {
        if (currentQuestion['Constrained_with'] === question.id) {
          // Check if every old value exist in ConstraintOptions
          oldQuestion.Check_List_Box_Options.forEach((answer, answerIndex) => {
            currentQuestion['ConstraintOptions'].forEach((option) => {
              if (option === answer) {
                option = question.Check_List_Box_Options[answerIndex];
                newArray[topicIndex].Questions[questionIndex]['ConstraintOptions'][answerIndex] = option;
              }
            });
          });
        }
      });
    }

    // If position changed
    if (changedPosition.flag) {
      newArray[topicIndex].Questions.splice(changedPosition.oldPosition - 1, 1);
      newArray[topicIndex].Questions.splice(question.Position - 1, 0, question);
      let index = 0;
      newArray[topicIndex].Questions = newArray[topicIndex].Questions.map((quest) => ({ ...quest })).map((quest) => {
        quest.Position = index + 1;
        index++;
        return quest;
      });
    } else {
      newArray[topicIndex].Questions[question.Position - 1] = question;
    }

    return {
      ...state,
      Topics: newArray,
    };
  })
);

export function topicReducer(state, action) {
  return _topicReducer(state, action);
}
