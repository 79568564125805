import { createAction, props } from '@ngrx/store';
import { NotificationImpl } from '../../../data/models/NotificationImpl';

export const enum NotificationsActions {
  AllNotificationsLoaded = '[Notifications] all notifications loaded',
  error = '[notifications] notification error',
  newNotification = '[Notifications] new notification',
  readNotifications = '[Notifications] readNotifications',
  snackBarMarkAsRead = '[Notifications] snackBarMarkAsRead',
  snackBarMarkAsReadSuccess = '[Notifications]  snackBarMarkAsRead Success',
}

export const errorHandled = createAction(NotificationsActions.error);

export const newNotification = createAction(
  NotificationsActions.newNotification,
  props<{ incomingNotification: NotificationImpl }>()
);
// project actions
export const AllNotificationsLoaded = createAction(
  NotificationsActions.AllNotificationsLoaded,
  props<{ notifications: NotificationImpl[] }>()
);

export const readNotifications = createAction(
  NotificationsActions.readNotifications,
  props<{ notifications: NotificationImpl[] }>()
);

export const snackBarMarkAsRead = createAction(
  NotificationsActions.snackBarMarkAsRead,
  props<{ notification: NotificationImpl }>()
);

export const snackBarMarkAsReadSuccess = createAction(NotificationsActions.snackBarMarkAsReadSuccess);
