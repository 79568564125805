<div class="absolute left-[-1rem] transition-all duration-500">
  <div class="flex">
    <svg height="20" width="20">
      <circle cx="12" cy="11" r="8" fill="red" />

      Sorry, your browser does not support inline SVG.
    </svg>
    <svg height="20" width="10">
      <line x1="0" y1="11" x2="10" y2="11" style="stroke: #ef4444; stroke-width: 2" />
      Sorry, your browser does not support inline SVG.
    </svg>
    <div>
      <div>
        <svg attr.height="{{ lineLength }}" width="10">
          <line x1="0" y1="10" x2="0" attr.y2="{{ lineLength }}" style="stroke: #ef4444; stroke-width: 4" />
          Sorry, your browser does not support inline SVG.
        </svg>
        <div class="relative">
          <ng-container *ngFor="let index of childIndexes">
            <div
              [ngStyle]="{
                'bottom.px': index | bottomCalculationPipe: lastIndex:childIndexes.length:lineLength:oneRowHeight
              }"
              class="absolute bottom-[-10px] right-[9px] flex"
            >
              <div>
                <svg height="15" width="15">
                  <polygon attr.y1="{{ index }}" points="16 1, 16 16, 1 10" fill="red" />

                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div>
                <svg height="20" width="10">
                  <line x1="0" y1="10" x2="10" y2="10" style="stroke: #ef4444; stroke-width: 2" />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
