import { Pipe, PipeTransform } from '@angular/core';
import { QuestionnairesAssigned } from 'src/app/data/models/QuestionnairesAssigned';

@Pipe({
  name: 'isCompletedProject',
})
export class IsCompletedProjectPipe implements PipeTransform {
  transform(quests: QuestionnairesAssigned[]): boolean {
    let returned = false;
    quests.forEach((quest) => {
      if (quest.Assigned_Status !== 'Submitted') {
        returned = true;
      }
    });
    return returned;
  }
}
