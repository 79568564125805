import { Component, Input, OnInit } from '@angular/core';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';

@Component({
  selector: 'analytics-panel-details',
  templateUrl: './analytics-panel-details.component.html',
  styleUrls: ['./analytics-panel-details.component.scss'],
})
export class AnalyticsPanelDetailsComponent implements OnInit {
  @Input()
  topics: any[];

  @Input()
  project: string;

  constructor(private dialogService: GeneralDialogService,) {}

  ngOnInit(): void {}

  getCurrectOptionShowOrder(current_option, options) {
    let id = 0;
    for (let option of options) {
      if (option.numberWhoAnswered > 0) {
        if (current_option.answer === option.answer) {
          break;
        }
        id++;
      }
    }
    return id;
  }

  // Calculate how many different segments we have in a progressBar
  getSegments(numberNotAnswered_question, options) {
    let totalSegments = 0;
    if (numberNotAnswered_question > 0) {
      totalSegments++;
    }
    if (options.length === 0) {
      totalSegments++;
    } else {
      options.forEach((option) => {
        if (option.numberWhoAnswered > 0) {
          totalSegments++;
        }
      });
    }
    return totalSegments;
  }

  // Calculate width of each segment of a progressBar
  getWidth(numberAnswered_segment, numberAnswered_question, numberNotAnswered_question, options) {
    let totalSegments = this.getSegments(numberNotAnswered_question, options);
    let remDifferenceFromGaps = ((totalSegments - 1) * 0.5) / totalSegments;
    let normalWidth = (numberAnswered_segment / (numberAnswered_question + numberNotAnswered_question)) * 100;
    let finalString = 'calc(' + normalWidth + '% - ' + remDifferenceFromGaps + 'rem)';

    return finalString;
  }

  showPopUp(question, option?, topicIndex?, questionIndex?) {
    let details = Object.values(question.details);
    if (option) {
      details = details.filter((user) => {
        return user['answers'].includes(option.answer);
      });
      this.dialogService.openQuestionDetails({
        title: question.Question_Text,
        descriptions: question.Description,
        details: details,
        questionType: question.TypeOfQuestion,
        option: option,
      });
    }else {
      let zipName = this.project + '_' + (topicIndex + 1) + '_' + (questionIndex + 1) + '_files'
      this.dialogService.openQuestionDetails({
        title: question.Question_Text, 
        descriptions: question.Description, 
        zipName: zipName,
        details: details,
        questionType: question.TypeOfQuestion,
      });
    }
  }
}
