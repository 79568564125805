<p class="text-dialog font-semibold mt-6 mb-2">
  {{ "QUEST_ANS.COMMENT" | translate }}
</p>
<mat-form-field appearance="outline" class="w-full">
  <input
    type="text"
    matInput
    name="comment"
    [formControl]="commentForm"
    placeholder="{{ 'QUEST_ANS.COMMENT' | translate }}"
  />
</mat-form-field>
