import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verbPronounDictionary',
})
export class VerbPronounDictionaryPipe implements PipeTransform {
  transform(value: string, pluralCheck: string, languageCheck: string): string {
    if (languageCheck === 'en') {
      return '';
    }

    var wordToChange = '';
    var wordToCheck = '';

    var counter = value.indexOf(' ');
    wordToChange = value.substring(0, counter);
    var otherWords = value.substring(counter + 1, value.length);
    counter = otherWords.indexOf(' ');

    if (counter === -1) {
      wordToCheck = otherWords;
    } else {
      wordToCheck = otherWords.substring(0, counter);
    }

    if (pluralCheck === 'singular') {
      // singular

      if (wordToCheck.charAt(wordToCheck.length - 1) === 'a') {
        return wordToChange.substring(0, wordToChange.length - 1) + 'a';
      } else {
        return wordToChange.substring(0, wordToChange.length - 1) + 'o';
      }
    } else if (pluralCheck === 'plural') {
      // plural

      if (wordToCheck.charAt(wordToCheck.length - 1) === 'e') {
        return wordToChange.substring(0, wordToChange.length - 1) + 'e';
      } else {
        return wordToChange.substring(0, wordToChange.length - 1) + 'i';
      }
    } else {
      return wordToChange;
    }
  }
}
