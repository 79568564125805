import { environment } from 'src/environments/environment';
import { ActionLoginTypes } from './../../Store/login-store/login.actions';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { LoginStoreService } from 'src/app/Store/store-services/login-store.service';
import { ABILITIES_HELPER } from 'src/app/data/models/abilities';
import { Store } from '@ngrx/store';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { selectIdTheme, selectLogoLogin } from 'src/app/features/administrative/store/administrative-page.selectors';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from 'src/app/features/administrative/service/UploadFile.service';
import { AdministrativeActions } from 'src/app/features/administrative/store/administrative-page.actions';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserActions } from 'src/app/Store/users/users.actions';

export interface DialogData {
  emailForgotPassword: string;
}

export const enum ErrorMessages {
  INVALID_CREDENTIALS = 'Utente inesistente!',
  MISSING_CREDENTIALS = 'Inserire tutti i campi obbligatori',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  abilities = Object.keys(ABILITIES_HELPER);
  loginForm: FormGroup;

  error$: Observable<string>;
  hide = true;
  emailForgotPassword: string;
  notFocused = true;
  invalid_cred_error: string;
  appName: string;
  checked = false;
  imageUrl: any;
  welcome: string;
  appSvgTheme: number;
  showDropDown = false;

  constructor(
    private uploadFileService: UploadFileService,
    private loginStoreService: LoginStoreService,
    public router: Router,
    public dialog: MatDialog,
    private actions$: Actions,
    private store: Store,
    private magicString: MagicStringsService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.uploadFileService
      .getTheme()
      .pipe(first())
      .subscribe((data) => {
        this.store.dispatch({
          type: AdministrativeActions.setTheme,
          idTheme: +data['theme'],
        });
      });
    this.store.select(selectIdTheme).subscribe((id) => {
      this.appSvgTheme = id;
    });

    this.loginForm = new FormGroup({
      usernameFormControl: new FormControl('', [Validators.required]),
      passwordFormControl: new FormControl(null, [Validators.required]),
    });

    this.error$ = this.actions$.pipe(ofType(ActionLoginTypes.LOGIN_FAILURE));
    this.store.select(selectLogoLogin).subscribe((data) => {
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(data);
    });

    this.appName = this.magicString.AppName;
    this.welcome = this.magicString.Welcome;
    this.appSvgTheme = +this.magicString.IdTheme;
  }

  // call login when enter button is pressed
  onkeyup(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    this.login();
  }

  checkEnter(event) {
    console.log(event);
  }

  // login user and get jwt token from passport
  login() {
    if (this.loginForm.valid) {
      this.loginStoreService.login({
        username: this.loginForm.get('usernameFormControl').value,
        password: this.loginForm.get('passwordFormControl').value,
      });
    } else {
      this.invalid_cred_error = ErrorMessages.MISSING_CREDENTIALS;
    }
  }

  // open the forgot password dialog
  openForgotPasswordDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordDialog, {
      width: '400px',
      data: { emailForgotPassword: this.emailForgotPassword },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.emailForgotPassword = result;
      if (this.typeOf(this.emailForgotPassword) !== 'undefined') {
        this.loginStoreService.forgotPassword(this.emailForgotPassword);
      } else {
        // this.utilsService.openWarningSwal('Please provide a valid email');
      }
    });
  }

  // returns the typeOf a value
  typeOf(value) {
    return typeof value;
  }

  passwordHidden() {
    this.notFocused = !this.notFocused;
  }

  loginSSO() {}

  changeLanguage(language) {
    if (language !== this.translate.currentLang) {
      this.translate.use(language);
      this.showDropDown = false;
      if (language === 'it') {
        language = 'itChanged'; // Save change only if changed from english to italian
      }
      this.store.dispatch({
        type: UserActions.changeLanguage,
        language: language,
      });
    }
  }

  @HostListener('click', ['$event'])
  publiconClick(event: any): void {
    event.preventDefault();
  }
}

@Component({
  selector: 'forgot-password-dialog',
  templateUrl: 'forgot-password-dialog.html',
})
export class ForgotPasswordDialog {
  emailForgotPassword;

  constructor(public dialogRef: MatDialogRef<ForgotPasswordDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
