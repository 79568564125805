import { map, take } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Store } from '@ngrx/store';
import { deleteQuestion } from 'src/app/Store/questions/questions.actions';
import { typeOfQuestionDeletion } from 'src/app/utils/config/questionnaire-table.configuration';
import { convertQuestionToBeStored, deepCopyV2 } from 'src/app/data/data-handlers/utils.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop/drag-events';
import { selectDragAnDropHelpers } from 'src/app/Store/topics-store/topics.selectors';
import { QUESTION_MODAL_ERRORS } from '../new-question-modal/errors';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { QuestionsStoreService } from 'src/app/Store/store-services/questions-store.service';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { ElencoTopic } from 'src/app/data/models/ElencoTopic';
import { TopicWithOnlyQuestionText } from 'src/app/data/models/TopicWithOnlyQuestionText';
import { Question } from 'src/app/data/models/Question';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';

@Component({
  selector: 'app-new-question',
  templateUrl: './new-question.component.html',
  styleUrls: ['./new-question.component.scss'],
})
export class NewQuestionComponent implements OnChanges, OnInit {
  @Input()
  currentTopic: ElencoTopic;

  @Input()
  topicsWithQuestions: TopicWithOnlyQuestionText[];

  @Input()
  ReadOnlyMode = false;

  @Input()
  Model_Status = null;

  @Output()
  newTopicChanged = new EventEmitter<ElencoTopic>();

  showTree = {};

  questionsSrollPosition = null;
  currentTopicID = null;

  dragAndDropHelpers$: Observable<{
    dragAndDropChecker: {};
    dragAndDropIndexHelper: any[];
    constraintsParentHelperObj: {};
  }>;

  childAndParentQuestions = [];

  childAndParentQuestionsWithPositions: { id: string; Position: number }[] = [];

  selectedQuestion: Question;

  dragAndDropChecker: {
    [id: string]: {
      currentIndex: number;
      isConstrained: boolean;
      constrainedId: string;
    };
  } = {};
  dragAndDropIndexHelper = [];
  constraintsParentHelperObj: { [id: string]: number[] } = {};

  show_question_options = false;

  starterQuestion = {
    Check_List_Box_Options: [],
    Constrained: false,
    Constrained_with: 'Binding',
    ConstraintOptions: [],
    Description: '',
    Obligatory: false,
    Positions: null,
    Question_Text: null,
    Reg_Ex: '',
    TypeOfVerificationInput: null,
    Verify_Input: false,
    // freeTextConstraint: null,
    id: '',
  };

  appSvgTheme = 0;

  constructor(
    private magicStringsService: MagicStringsService,
    private dialogService: GeneralDialogService,
    private store: Store,
    private notify: NotifyService,
    private questionStore: QuestionsStoreService
  ) {}

  ngOnInit(): void {
    this.appSvgTheme = +this.magicStringsService.IdTheme;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.currentTopic.currentValue) {
      this.dragAndDropHelpers$ = this.store
        .select(selectDragAnDropHelpers, {
          topicIndex: this.currentTopic.Order - 1,
        })
        .pipe(
          map((value) => {
            this.dragAndDropChecker = value?.dragAndDropChecker;
            this.dragAndDropIndexHelper = value?.dragAndDropIndexHelper;
            this.constraintsParentHelperObj = value?.constraintsParentHelperObj;
            return value;
          })
        );
      if (this.dialogService.addQuestionDialog && this.dialogService.addQuestionDialog.componentInstance) {
        this.dialogService.addQuestionDialog.componentInstance.data.Topic = changes.currentTopic.currentValue;
      }
      // Scroll top when you change Topic
      if (this.currentTopicID !== changes.currentTopic.currentValue.id && document.querySelector('#QuestionsScroll')) {
        this.currentTopicID = changes.currentTopic.currentValue.id;
        document.querySelector('#QuestionsScroll').scrollTop = 0;
      }
      // fix annoying scroll when you modify a question
      if(document.querySelector('#QuestionsScroll') && this.questionsSrollPosition) {
        fromEvent(document.querySelector('#QuestionsScroll'), 'scroll')
        .pipe(take(1))
        .subscribe((x => {
          document.querySelector('#QuestionsScroll').scrollTop = this.questionsSrollPosition;
          this.questionsSrollPosition = null;
        }));
      }
      
    }

  }

  addQuestion() {
    this.questionsSrollPosition = document.querySelector('#QuestionsScroll') ? document.querySelector('#QuestionsScroll').scrollTop : 0;

    this.show_question_options = false;
    this.starterQuestion.id = uuidv4();
    this.dialogService.openAddQuestionDialog({
      readOnlyMode: false,
      Topic: this.currentTopic,
      TopicIndex: this.currentTopic.Order - 1,
      action: 'Add',
      currentQuestion: {
        ...this.starterQuestion,
        Check_List_Box_Options: [],
        score: 0,
        Comment: false,
      },
      topicsWithQuestions: this.topicsWithQuestions,
    });

    this.dialogService.addQuestionDialogResponse().subscribe((res) => {
      if (res) {
        const question = convertQuestionToBeStored(res, null);
        const questionIndex = this.currentTopic.Questions.length + 1;
        this.questionStore.addQuestion(this.currentTopic.Order - 1, question);
      } else if (!res) {
        return;
      }
    });
  }

  modifyQuestion(question: Question, index: number) {
    this.questionsSrollPosition = document.querySelector('#QuestionsScroll') ? document.querySelector('#QuestionsScroll').scrollTop : 0;

    this.show_question_options = false;

    this.setIndexesToBeRemoved();
    this.dialogService.openAddQuestionDialog({
      currentQuestion: {
        ...question,
        Check_List_Box_Options: ['List box', 'Check-list box'].includes(question.TypeOfQuestion)
          ? deepCopyV2(question.Check_List_Box_Options)
          : [],
      },
      action: 'Modify',
      readOnlyMode: this.ReadOnlyMode,
      TopicIndex: this.currentTopic.Order - 1,
      Topic: deepCopyV2(this.currentTopic),
      topicsWithQuestions: deepCopyV2(this.topicsWithQuestions),
      childAndParentQuestionsWithPositions: this.childAndParentQuestionsWithPositions,
      modelStatus: this.Model_Status,
    });

    this.dialogService.addQuestionDialogResponse().subscribe((res) => {
      if (res) {
        this.questionStore.updateQuestion(
          convertQuestionToBeStored(res, question.Check_List_Box_Options_id),
          this.currentTopic.Order - 1,
          {
            flag: convertQuestionToBeStored(res, question.Check_List_Box_Options_id).Position !== question.Position,
            oldPosition: question.Position,
          }
        );
      }
    });
  }

  deleteQuestion(question, index) {
    this.questionsSrollPosition = document.querySelector('#QuestionsScroll') ? document.querySelector('#QuestionsScroll').scrollTop : 0;
    
    this.show_question_options = false;
    this.setIndexesToBeRemoved();

    const title =
      this.childAndParentQuestions.length > 1
        ? CONFIRMDIALOGSTRINGS.ELIMINATECONSTRAINTS
        : CONFIRMDIALOGSTRINGS.DELETESINGLEQUESTION;

    this.dialogService.openConfirmDialog({ question: question, info: title });

    this.dialogService.openConfirmDialogResponse().subscribe((res) => {
      if (res !== 'Cancel') {
        if (this.childAndParentQuestions.length > 1) {
          this.questionStore.deleteQuestion(
            this.currentTopic.Order - 1,
            this.childAndParentQuestions.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
            res
          );
          this.store.dispatch(
            deleteQuestion({
              index: this.currentTopic.Order - 1,
              idsToBeRemoved: this.childAndParentQuestions.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
              typeOfDeletion: res,
            })
          );
        } else {
          this.questionStore.deleteQuestion(
            this.currentTopic.Order - 1,
            this.childAndParentQuestions,
            typeOfQuestionDeletion.DELETESIMPLEQUESTION
          );
        }
      }
    });
  }

  duplicateQuestion(question: Question, index: number) {
    this.questionsSrollPosition = document.querySelector('#QuestionsScroll') ? document.querySelector('#QuestionsScroll').scrollTop : 0;

    this.show_question_options = false;
    const duplicate = JSON.parse(JSON.stringify(question));
    duplicate.Question_Text = duplicate.Question_Text + '-Copy';
    duplicate.id = uuidv4();
    if (!this.doesQuestionAlreadyExists(this.currentTopic, duplicate.Question_Text)) {
      this.questionStore.duplicateQuestion(this.currentTopic.Order - 1, duplicate);
    } else {
      this.notify.openWarningSwal('Impossibile inserire titolo già esistente');
    }
  }

  showQuestionOptions(question, i) {
    this.selectedQuestion = question;
    this.show_question_options = !this.show_question_options;
  }

  drop(event: CdkDragDrop<Question[]>) {
    this.questionsSrollPosition = document.querySelector('#QuestionsScroll') ? document.querySelector('#QuestionsScroll').scrollTop : 0;
    let questionToBeChanged = this.dragAndDropChecker[this.dragAndDropIndexHelper[event.previousIndex]];

    if (!questionToBeChanged?.isConstrained) {
      if (!!this.constraintsParentHelperObj[this.currentTopic.Questions[event.previousIndex].id]) {
        let constrainedChildPositions =
          this.constraintsParentHelperObj[this.currentTopic.Questions[event.previousIndex].id];
        let min = this.findMinPositionOfDependend(constrainedChildPositions);
        if (event.currentIndex < min) {
          this.swapTwoQuestions(event.previousIndex, event.currentIndex);
        } else {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.NON_VALID_POSITION}`);
        }
      } else {
        this.swapTwoQuestions(event.previousIndex, event.currentIndex);
      }
    } else {
      const parentQuestionId = this.dragAndDropChecker[this.dragAndDropIndexHelper[event.previousIndex]].constrainedId;
      let parentQuestionPosition = this.dragAndDropChecker[parentQuestionId].currentIndex;
      if (event.currentIndex > parentQuestionPosition) {
        this.swapTwoQuestions(event.previousIndex, event.currentIndex);
      } else {
        this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.NON_VALID_POSITION}`);
      }
    }
    this.newTopicChanged.emit(this.currentTopic);
  }

  showOrHideTree(index: string) {
    if (!!this.showTree[index]) {
      this.showTree = {};
    } else {
      this.showTree = {};
      this.showTree[index] = true;
    }
  }

  private setIndexesToBeRemoved() {
    this.childAndParentQuestions = [this.selectedQuestion.Position - 1];

    for (const loopquestion of this.currentTopic.Questions) {
      if (loopquestion.Constrained && loopquestion.Constrained_with === this.selectedQuestion.id) {
        this.childAndParentQuestions.push(loopquestion.Position - 1);
        this.childAndParentQuestionsWithPositions.push({
          id: loopquestion.id,
          Position: loopquestion.Position,
        });
      }
    }
  }

  private findMinPositionOfDependend(array: number[]): number {
    let min = 100000000;
    array.forEach((value) => {
      if (value < min) {
        min = value;
      }
    });
    return min;
  }

  private swapTwoQuestions(previous: number, next: number) {
    let questions = this.currentTopic.Questions.concat([]);

    const question = questions[previous];
    questions.splice(previous, 1);
    questions.splice(next, 0, question);
    const helper = this.dragAndDropIndexHelper[previous];
    this.dragAndDropIndexHelper.splice(previous, 1);
    this.dragAndDropIndexHelper.splice(next, 0, helper);
    this.dragAndDropChecker[question.id].currentIndex = next;
    let newIndex = 1;
    questions = questions.map((quest) => {
      quest = { ...quest, Position: newIndex };
      newIndex++;
      return quest;
    });
    this.currentTopic = { ...this.currentTopic, Questions: questions };
  }

  doesQuestionAlreadyExists(array: ElencoTopic, title: string): boolean {
    for (let item of array.Questions) {
      if (item.Question_Text === title) {
        return true;
      }
    }
    return false;
  }
}
