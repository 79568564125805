import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneProjectOptions } from 'src/app/data/models/request-apis/cloneProjectOptions';
import { ProjectActionTypes } from '../projects/actions/projects.actions';

@Injectable({
  providedIn: 'root',
})
export class ProjectStoreService {
  isProjectPage = false;

  constructor(private store: Store) {}

  cloneProject(projectId: string, title: string, description?: string, options?: cloneProjectOptions) {
    return this.store.dispatch({
      type: ProjectActionTypes.cloneProject,
      projectId: projectId,
      title: title,
      description: description,
      options: options,
    });
  }

  setIsProjectPage(check) {
    this.isProjectPage = check;
  }

  getIsProjectPage() {
    return this.isProjectPage;
  }
}
