import { OnDestroy, EventEmitter } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Output } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { QuestionAnswered } from 'src/app/data/models/QuestionAnswered';

@Component({
  selector: 'app-base',
  template: ``,
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {
  @Input()
  question: QuestionAnswered;

  @Input()
  reviewMode = false;

  @Output()
  copyToClipBoardEvent = new EventEmitter<boolean>();

  @Output()
  setHidden = new EventEmitter<{ questionId: string }>();

  @Output()
  setShow = new EventEmitter<{ questionId: string }>();

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {
    if (this.question.Constrained && this.question.Obligatory) {
      this.setShow.emit({ questionId: this.question.id });
    }
  }

  ngOnDestroy(): void {
    if (this.question.Constrained && this.question.Obligatory) {
      this.setHidden.emit({ questionId: this.question.id });
    }
  }

  copyToClipBoard(text: string) {
    this.clipboard.copy(text);
    this.copyToClipBoardEvent.emit(true);

    return false;
  }
}
