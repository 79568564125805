import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AllGroupClient } from 'src/app/data/models/ClientGroup';
import { ClientGroupId } from 'src/app/data/models/User';
import { UserActions } from 'src/app/Store/users/users.actions';
import { selectFilteredClientGroup, selectAllClientGroup } from 'src/app/Store/users/users.selectors';

@Component({
  selector: 'app-organization-tab',
  templateUrl: './organization-tab.component.html',
  styleUrls: ['./organization-tab.component.scss'],
})
export class OrganizationTabComponent implements OnInit {
  @Input() set missingValue(value: boolean) {
    if (value) {
      this.userOrganizationForm.markAllAsTouched();
    }
  }

  @Output() newOrganization = new EventEmitter<ClientGroupId>();

  allClientGroup$: Observable<AllGroupClient[]>;

  userOrganizationForm: FormGroup;

  clientIdSelected: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch({
      type: UserActions.searchAllClientGroup,
      admin: '',
    });
    this.allClientGroup$ = this.store.select(selectAllClientGroup);

    this.userOrganizationForm = new FormGroup({
      client: new FormControl(null, Validators.required),
      group: new FormControl(null, Validators.required),
    });

    this.userOrganizationForm.controls.group.disable();

    this.userOrganizationForm.valueChanges.subscribe((val) => {
      let newClientId = val.client;
      let newGroupId = val.group;
      if (val.client !== this.clientIdSelected) {
        newGroupId = '';
      }
      this.clientIdSelected = val.client;
      this.userOrganizationForm.controls.group.enable({
        emitEvent: false,
      });
      if (this.userOrganizationForm.valid) {
        this.newOrganization.emit({
          client_id: newClientId,
          group_id: newGroupId,
        });
      }
    });
  }
}
