import { createReducer, on } from '@ngrx/store';
import { deepCopyV2 } from '../../../data/data-handlers/utils.service';
import {
  AllNotificationsLoaded,
  newNotification,
  readNotifications,
  snackBarMarkAsRead,
} from '../actions/notifications.actions';

import { initialStateNotifications } from '../notifications-state';

export const notifyStateFeatureKey = 'notifications';

export const notificationsReducer = createReducer(
  initialStateNotifications,
  on(AllNotificationsLoaded, (state, { notifications }) => {
    return{
    ...state,
    notifications,
  }}),
  on(readNotifications, (state, { notifications }) => {
    let temp = deepCopyV2(notifications);
    for (let notif of temp) {
      if (notif.Notification_Viewed === false) {
        notif.Notification_Viewed = true;
        notif.unclicked = false;
      } else {
        notif.unclicked = true;
      }
    }
    return {
      ...state,
      notifications: temp,
    };
  }),
  on(snackBarMarkAsRead, (state, { notification }) => {
    let temp = deepCopyV2(state.notifications);
    for (let notif of temp) {
      if (notif.PK_NOTIF === notification.PK_NOTIF) {
        notif.Notification_Viewed = true;
        notif.unclicked = false;
      }
    }
    return {
      ...state,
      notifications: temp,
      snackBarLoading: false,
    };
  }),
  on(newNotification, (state, { incomingNotification }) => {
    if (state.notifications.length > 0) {
      if (state.notifications[0]['PK_NOTIF'] !== incomingNotification['PK_NOTIF']) {
        if (state.notifications.length < 6) {
          var temp = [incomingNotification].concat(state.notifications);
          return {
            ...state,
            notifications: temp,
          };
        } else {
          var temp = [incomingNotification].concat(state.notifications);
          temp.pop();
          return {
            ...state,
            notifications: temp,
          };
        }
      }else{
        return {
          ...state,
        };
      }
    } else {
      return {
        ...state,
      };
    }
  })
);
