<!-- ADVANCED FILTER -->
<div class="w-[47vw] h-[fit-content] search-projects" [ngClass]="{ 'filter-searchbar': showFilter }">
  <mat-form-field class="w-full !text-[0.8rem] search-input-projects" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <input
      class="!text-[1.25rem] !m-0"
      (keyup.enter)="searchDispatch()"
      type="text"
      [(ngModel)]="searchTerm"
      placeholder="{{ 'PROJECT_TABLE.SEARCH' | translate }} {{
        modelOrProject === 'project'
          ? ('DICTIONARY.Progetto' | translate | titlecase)
          : ('DICTIONARY.Modello' | translate)
      }}"
      matInput
    />
    <button class="!bg-transparent" (click)="toggleFilter(!showFilter)" *ngIf="!showFilter" matSuffix mat-icon-button>
      <span
        *ngIf="!defaultOptions"
        class="absolute top-1 right-1 inline-flex h-4 w-4 rounded-full bg-login z-10"
      ></span>
      <span
        *ngIf="!defaultOptions"
        class="absolute top-1 right-1 inline-flex h-4 w-4 rounded-full bg-login z-10 animate-ping"
      ></span>
      <mat-icon *ngIf="!showFilter" class="relative my-fab">filter_list</mat-icon>
    </button>
    <div
      id="filter-buttons"
      class="search-buttons flex flex-row gap-2 items-center border-dialog pl-4 relative bottom-[0.2rem] h-14"
      matSuffix
      *ngIf="showFilter"
    >
      <button (click)="toggleFilter(!showFilter)" mat-icon-button>
        <mat-icon class="text-gray-400">cancel</mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>
<div id="filter-wrapper" *ngIf="showFilter" class="flex flex-col gap-6">
  <div
    id="advanced-filter"
    class="flex flex-row gap-[0.5vw] justify-evenly w-[47vw] h-24 bg-transparent border-l-[1px] border-r-[1px] border-b-[1px] border-dialog p-6 z-10"
    [ngClass]="{ popWidth: popUpMode }"
  >
    <div id="status-filter" class="text-base" *ngIf="!popUpMode">
      <mat-expansion-panel
        [expanded]="statusExpand"
        (opened)="panelOpenState = true; closeAllExpands(); statusExpand = true"
        (closed)="panelOpenState = false; statusExpand = false"
        class="!rounded-none"
      >
        <mat-expansion-panel-header expandedHeight="46px" collapsedHeight="46px">
          <p class="flex flex-row justify-between w-full mr-4">
            <span>{{ "ADV_FILTER.STATUS.TITLE" | translate | titlecase }}</span>
            <span
              matBadgeSize="small"
              matBadgeOverlap="false"
              matBadge="{{ status.count }}"
              class="filter-badge"
            ></span>
          </p>
        </mat-expansion-panel-header>
        <mat-checkbox
          id="draft"
          [checked]="status.draft"
          (change)="statusChecked($event, 'statusDraftTag')"
          class="text-base mb-2"
        >
          {{ "ADV_FILTER.STATUS.DRAFT" | translate | titlecase }}</mat-checkbox
        >
        <mat-checkbox
          id="active"
          [checked]="status.active"
          (change)="statusChecked($event, 'statusActiveTag')"
          class="text-base mb-2"
        >
          {{
            modelOrProject === "project"
              ? ("ADV_FILTER.STATUS.ACTIVE" | translate | titlecase)
              : ("ADV_FILTER.STATUS.COMPLETED" | translate | titlecase)
          }}</mat-checkbox
        >
        <mat-checkbox
          id="closed"
          [checked]="status.closed"
          (change)="statusChecked($event, 'statusClosedTag')"
          *ngIf="modelOrProject === 'project'"
          class="text-base mb-2"
        >
          {{ "ADV_FILTER.STATUS.CLOSED" | translate | titlecase }}</mat-checkbox
        >
        <mat-checkbox
          id="archived"
          [checked]="status.archived"
          (change)="statusChecked($event, 'statusArchivedTag')"
          class="text-base"
        >
          {{ "ADV_FILTER.STATUS.ARCHIVED" | translate | titlecase }}</mat-checkbox
        >
      </mat-expansion-panel>
    </div>
    <div id="expiry-filter" *ngIf="modelOrProject === 'project'" class="text-base">
      <mat-expansion-panel
        [expanded]="expiryExpand"
        (opened)="panelOpenState = true; closeAllExpands(); expiryExpand = true"
        (closed)="panelOpenState = false; expiryExpand = false"
        class="!rounded-none"
      >
        <mat-expansion-panel-header>
          <p class="flex flex-row justify-between w-full mr-4">
            <span>{{ "ADV_FILTER.EXPIRY_DATE.TITLE" | translate | titlecase }}</span>
            <span
              matBadgeSize="small"
              matBadgeOverlap="false"
              matBadge="{{ expireDateCount }}"
              class="filter-badge"
            ></span>
          </p>
        </mat-expansion-panel-header>
        <div class="flex flex-col gap-2 items-center justify-center">
          <div id="start-date" class="">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.EXPIRY_DATE.FROM" | translate | titlecase }}</mat-label>
              <input
                matInput
                [max]="expireToFilter"
                [matDatepicker]="startPicker"
                name="filterStartDate"
                [ngModel]="expireFromFilter"
                (ngModelChange)="expireDateChecked($event, 'expireFromFilter')"
                (dateChange)="filterDateChange($event.value, 'expireFromFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div id="end-date" class="">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.EXPIRY_DATE.TO" | translate | titlecase }}</mat-label>
              <input
                matInput
                [min]="expireFromFilter"
                [matDatepicker]="endPicker"
                name="filterEndDate"
                [ngModel]="expireToFilter"
                (ngModelChange)="expireDateChecked($event, 'expireToFilter')"
                (dateChange)="filterDateChange($event.value, 'expireToFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div id="lastAssignment-filter" *ngIf="modelOrProject === 'model'" class="text-base">
      <mat-expansion-panel
        [expanded]="lastAssignmentExpand"
        (opened)="panelOpenState = true; closeAllExpands(); lastAssignmentExpand = true"
        (closed)="panelOpenState = false; lastAssignmentExpand = false"
        class="!rounded-none"
      >
        <mat-expansion-panel-header>
          <p class="flex flex-row justify-between w-full mr-4">
            <span>{{ "ADV_FILTER.LAST_ASSIGNMENT_DATE.TITLE" | translate | titlecase }}</span>
            <span
              matBadgeSize="small"
              matBadgeOverlap="false"
              matBadge="{{ lastAssignmentDateCount }}"
              MatBadgePosition="above after"
              class="filter-badge"
            ></span>
          </p>
        </mat-expansion-panel-header>
        <div class="flex flex-col gap-2 items-center justify-center">
          <div id="start-date">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.LAST_ASSIGNMENT_DATE.FROM" | translate | titlecase }} </mat-label>
              <input
                matInput
                [max]="lastAssignmentToFilter"
                [matDatepicker]="startPicker"
                name="filterStartDate"
                [ngModel]="lastAssignmentFromFilter"
                (ngModelChange)="lastAssignmentDateChecked($event, 'lastAssignmentFromFilter')"
                (dateChange)="filterDateChange($event.value, 'lastAssignmentFromFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div id="end-date" class="">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.LAST_ASSIGNMENT_DATE.TO" | translate | titlecase }}</mat-label>
              <input
                matInput
                [min]="lastAssignmentFromFilter"
                [matDatepicker]="endPicker"
                name="filterEndDate"
                [ngModel]="lastAssignmentToFilter"
                (ngModelChange)="lastAssignmentDateChecked($event, 'lastAssignmentToFilter')"
                (dateChange)="filterDateChange($event.value, 'lastAssignmentToFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div id="lastChange-filter" *ngIf="modelOrProject === 'model'" class="text-base">
      <mat-expansion-panel
        [expanded]="lastChangeExpand"
        (opened)="panelOpenState = true; closeAllExpands(); lastChangeExpand = true"
        (closed)="panelOpenState = false; lastChangeExpand = false"
        class="!rounded-none"
      >
        <mat-expansion-panel-header>
          <p class="flex flex-row justify-between w-full mr-4">
            <span>{{ "ADV_FILTER.LAST_CHANGE_DATE.TITLE" | translate | titlecase }}</span>
            <span
              matBadgeSize="small"
              matBadgeOverlap="false"
              matBadge="{{ lastChangeDateCount }}"
              MatBadgePosition="above after"
              class="filter-badge"
            ></span>
          </p>
        </mat-expansion-panel-header>
        <div class="flex flex-col gap-2 items-center justify-center">
          <div id="start-date">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.LAST_CHANGE_DATE.FROM" | translate | titlecase }}</mat-label>
              <input
                matInput
                [max]="lastChangeToFilter"
                [matDatepicker]="startPicker"
                name="filterStartDate"
                [ngModel]="lastChangeFromFilter"
                (ngModelChange)="lastChangeDateChecked($event, 'lastChangeFromFilter')"
                (dateChange)="filterDateChange($event.value, 'lastChangeFromFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div id="end-date" class="">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.LAST_CHANGE_DATE.TO" | translate | titlecase }}</mat-label>
              <input
                matInput
                [min]="lastChangeFromFilter"
                [matDatepicker]="endPicker"
                name="filterEndDate"
                [ngModel]="lastChangeToFilter"
                (ngModelChange)="lastChangeDateChecked($event, 'lastChangeToFilter')"
                (dateChange)="filterDateChange($event.value, 'lastChangeToFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div id="model-filter" *ngIf="modelOrProject === 'project'" class="text-base">
      <mat-expansion-panel
        [expanded]="modelExpand"
        (opened)="panelOpenState = true; closeAllExpands(); modelExpand = true"
        (closed)="panelOpenState = false; modelExpand = false"
        class="!rounded-none"
      >
        <mat-expansion-panel-header>
          <p class="flex flex-row justify-between w-full mr-4">
            <span>{{ "ADV_FILTER.MODEL.TITLE" | translate | titlecase }}</span>
            <span
              matBadgeSize="small"
              matBadgeOverlap="false"
              matBadge="{{ modelFilter ? 1 : 0 }}"
              class="filter-badge"
            ></span>
          </p>
        </mat-expansion-panel-header>
        <div>
          <p class="flex items-center justify-center" id="searchModel">
            <mat-form-field appearance="outline" class="!w-[6vw]">
              <input
                matInput
                (keyup.enter)="searchDispatch()"
                placeholder="{{ lang === 'it' ? 'Nome Modello' : 'Model Name' }}"
                [(ngModel)]="modelFilter"
              />
            </mat-form-field>
          </p>
        </div>
      </mat-expansion-panel>
    </div>
    <div id="creation-filter" class="text-base">
      <mat-expansion-panel
        [expanded]="creationExpand"
        (opened)="panelOpenState = true; closeAllExpands(); creationExpand = true"
        (closed)="panelOpenState = false; creationExpand = false"
        class="!rounded-none"
      >
        <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
          <p class="flex flex-row justify-between w-full pr-2">
            <span>{{ "ADV_FILTER.CREAT_DATE.TITLE" | translate | titlecase }}</span>
            <span
              matBadgeSize="small"
              matBadgeOverlap="false"
              matBadge="{{ createDateCount }}"
              MatBadgePosition="above after"
              class="filter-badge"
            ></span>
          </p>
        </mat-expansion-panel-header>
        <div class="flex flex-col gap-2 items-center justify-center">
          <div id="start-date" class="">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.CREAT_DATE.FROM" | translate | titlecase }}</mat-label>
              <input
                matInput
                [max]="creationToFilter"
                [matDatepicker]="creatStartPicker"
                name="filterStartDate"
                [ngModel]="creationFromFilter"
                (ngModelChange)="createDateChecked($event, 'creationFromFilter')"
                (dateChange)="filterDateChange($event.value, 'creationFromFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="creatStartPicker"></mat-datepicker-toggle>
              <mat-datepicker #creatStartPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div id="end-date" class="">
            <mat-form-field class="!w-[8vw]" appearance="outline">
              <mat-label>{{ "ADV_FILTER.CREAT_DATE.TO" | translate | titlecase }}</mat-label>
              <input
                matInput
                [min]="creationFromFilter"
                [matDatepicker]="creatEndPicker"
                name="filterEndDate"
                [ngModel]="creationToFilter"
                (ngModelChange)="createDateChecked($event, 'creationToFilter')"
                (dateChange)="filterDateChange($event.value, 'creationToFilter')"
              />
              <mat-datepicker-toggle matSuffix [for]="creatEndPicker"></mat-datepicker-toggle>
              <mat-datepicker #creatEndPicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div id="user-filter" class="text-base">
      <mat-expansion-panel
        [expanded]="userExpand"
        (opened)="panelOpenState = true; closeAllExpands(); userExpand = true"
        (closed)="panelOpenState = false; userExpand = false"
        class="!rounded-none"
      >
        <mat-expansion-panel-header>
          <p class="flex flex-row justify-between w-full mr-4">
            <span>{{
              modelOrProject === "project"
                ? ("ADV_FILTER.MANAGER.TITLE" | translate | titlecase)
                : ("ADV_FILTER.USER.TITLE" | translate | titlecase)
            }}</span>
            <span
              matBadgeSize="small"
              matBadgeOverlap="false"
              matBadge="{{ creatorFilter ? 1 : 0 }}"
              class="filter-badge"
            ></span>
          </p>
        </mat-expansion-panel-header>
        <div>
          <p class="flex items-center justify-center" id="searchUser">
            <mat-form-field appearance="outline" class="!w-[6vw]">
              <input
                matInput
                (keyup.enter)="searchDispatch()"
                placeholder="{{ lang === 'it' ? 'Nome Utente' : 'Username' }}"
                [(ngModel)]="creatorFilter"
              />
            </mat-form-field>
          </p>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div id="filter-tag-wrapper" class="flex flex-row justify-between">
    <div id="filter-tags" class="flex flex-row flex-wrap max-w-[35vw] gap-4 text-white z-0">
      <div *ngIf="statusActiveTag && !popUpMode" class="text-base px-4 py-2 bg-[#9A59B5] shadow">
        <span class="inline-flex items-center gap-4">
          <p>
            {{
              modelOrProject === "project"
                ? ("ADV_FILTER.STATUS.ACTIVE" | translate | titlecase)
                : ("ADV_FILTER.STATUS.COMPLETED" | translate | titlecase)
            }}
          </p>
          <button mat-icon-button (click)="removeTag('active', 'statusActiveTag', 'count')">
            <mat-icon class="close-tags">cancel</mat-icon>
          </button>
        </span>
      </div>
      <div *ngIf="statusDraftTag" class="text-base px-4 py-2 bg-[#9A59B5] shadow">
        <span class="inline-flex items-center gap-4">
          <p>{{ "ADV_FILTER.STATUS.DRAFT" | translate | titlecase }}</p>
          <button mat-icon-button (click)="removeTag('draft', 'statusDraftTag', 'count')">
            <mat-icon class="close-tags">cancel</mat-icon>
          </button>
        </span>
      </div>
      <div *ngIf="statusClosedTag && modelOrProject === 'project'" class="text-base px-4 py-2 bg-[#9A59B5] shadow">
        <span class="inline-flex items-center gap-4">
          <p>{{ "ADV_FILTER.STATUS.CLOSED" | translate | titlecase }}</p>
          <button mat-icon-button (click)="removeTag('closed', 'statusClosedTag', 'count')">
            <mat-icon class="close-tags">cancel</mat-icon>
          </button>
        </span>
      </div>
      <div *ngIf="statusArchivedTag" class="text-base px-4 py-2 bg-[#9A59B5] shadow">
        <span class="inline-flex items-center gap-4">
          <p>{{ "ADV_FILTER.STATUS.ARCHIVED" | translate | titlecase }}</p>
          <button mat-icon-button (click)="removeTag('archived', 'statusArchivedTag', 'count')">
            <mat-icon class="close-tags">cancel</mat-icon>
          </button>
        </span>
      </div>
      <div
        id="expiry-filter-from"
        *ngIf="expireFromTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#13B123] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.EXPIRY_DATE.FROM" | translate | titlecase }}:
          {{ expireFromFilter }}
        </p>
        <button mat-icon-button (click)="removeTag('expireFromFilter', 'expireFromTag', 'expireDateCount')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="expiry-filter-to"
        *ngIf="expireToTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#13B123] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.EXPIRY_DATE.TO" | translate | titlecase }}:
          {{ expireToFilter }}
        </p>
        <button mat-icon-button (click)="removeTag('expireToFilter', 'expireToTag', 'expireDateCount')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="lastAssignment-filter-from"
        *ngIf="lastAssignmentFromTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#13B123] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.LAST_ASSIGNMENT_DATE.FROM" | translate | titlecase }}:
          {{ lastAssignmentFromFilter }}
        </p>
        <button
          mat-icon-button
          (click)="removeTag('lastAssignmentFromFilter', 'lastAssignmentFromTag', 'lastAssignmentDateCount')"
        >
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="lastAssignment-filter-to"
        *ngIf="lastAssignmentToTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#13B123] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.LAST_ASSIGNMENT_DATE.TO" | translate | titlecase }}:
          {{ lastAssignmentToFilter }}
        </p>
        <button
          mat-icon-button
          (click)="removeTag('lastAssignmentToFilter', 'lastAssignmentToTag', 'lastAssignmentDateCount')"
        >
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="lastChange-filter-from"
        *ngIf="lastChangeFromTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#21C4A4] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.LAST_CHANGE_DATE.FROM" | translate | titlecase }}:
          {{ lastChangeFromFilter }}
        </p>
        <button mat-icon-button (click)="removeTag('lastChangeFromFilter', 'lastChangeFromTag', 'lastChangeDateCount')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="lastChange-filter-to"
        *ngIf="lastChangeToTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#21C4A4] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.LAST_CHANGE_DATE.TO" | translate | titlecase }}:
          {{ lastChangeToFilter }}
        </p>
        <button mat-icon-button (click)="removeTag('lastChangeToFilter', 'lastChangeToTag', 'lastChangeDateCount')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="model-filter"
        *ngIf="modelTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#21c4a4] shadow"
      >
        <p class="inline-flex items-center">{{ modelFilter }}</p>
        <button mat-icon-button (click)="removeTag('modelFilter', 'modelTag')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="creation-filter-from"
        *ngIf="creationFromTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#b22222] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.CREAT_DATE.FROM" | translate | titlecase }}:
          {{ creationFromFilter }}
        </p>
        <button mat-icon-button (click)="removeTag('creationFromFilter', 'creationFromTag', 'createDateCount')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="creation-filter-to"
        *ngIf="creationToTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#b22222] shadow"
      >
        <p class="inline-flex items-center">
          {{ "ADV_FILTER.CREAT_DATE.TO" | translate | titlecase }}:
          {{ creationToFilter }}
        </p>
        <button mat-icon-button (click)="removeTag('creationToFilter', 'creationToTag', 'createDateCount')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
      <div
        id="user-filter"
        *ngIf="creatorTag"
        class="text-base inline-flex items-center gap-4 px-4 py-2 bg-[#E98600] shadow"
      >
        <p class="inline-flex items-center">{{ creatorFilter }}</p>
        <button mat-icon-button (click)="removeTag('creatorFilter', 'creatorTag')">
          <mat-icon class="close-tags">cancel</mat-icon>
        </button>
      </div>
    </div>
    <div id="filter-buttons" class="flex flex-row justify-end gap-2">
      <button (click)="clearAll()" class="whiteBtn h-12 flex items-center">
        <span>{{ "ADV_FILTER.BTN.CLEAR" | translate | titlecase }}</span>
      </button>
      <button (click)="searchDispatch()" class="colorBtn h-12 flex items-center">
        <span>{{ "ADV_FILTER.BTN.SEARCH" | translate | titlecase }}</span>
      </button>
    </div>
  </div>
</div>

<!-- ADVANCED FILTER -->
