import { FormControl } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VerbPronounDictionaryPipe } from 'src/app/utils/pipes/verb-pronoun-dictionary.pipe';
import { PrepositionDictionaryPipe } from 'src/app/utils/pipes/preposition-dictionary.pipe';
@Component({
  selector: 'app-home-dialog',
  templateUrl: './home-dialog.component.html',
  styleUrls: ['./home-dialog.component.scss'],
})
export class HomeDialogComponent implements OnInit {
  noteInput = new FormControl(null);
  currentPath: string;
  singleTopicName: string;
  topicName: string;
  responsabileName: string;
  questionarioName: string;
  language: string;
  firstTopic: string;
  verbPipe = new VerbPronounDictionaryPipe();
  prepPipe = new PrepositionDictionaryPipe();

  constructor(
    public dialog: MatDialogRef<HomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data:
      | 'notResponsibleAndUnfinished'
      | 'notResponsibleAndfinished'
      | 'ResponsibleAndUnfinished'
      | 'ResponsibleAndFinished'
      | 'watchlistUnFinished',
    private router: Router,
    private translate: TranslateService
  ) {
    this.currentPath = this.router.url;
  }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
  }

  closeDialog(result: string) {
    this.dialog.close({ result, note: this.noteInput.value });
  }
}
