import { Docufile } from 'src/app/data/models/Docufile';

export enum Status {
  Ready = 'Ready',
  Requested = 'Requested',
  Started = 'Started',
  Uploading = 'Uploading',
  Failed = 'Failed',
  Completed = 'Completed',
}

export interface DocufileState {
  status: Status;
  error: string | null;
  progress: number | null;
  filesUploading: boolean;
  download: Status;
  getFiles: {
    getStatus: Status;
    files: Docufile[];
    filesLoading: boolean;
  };
  deleteFile: Status;
}

export const initialState: DocufileState = {
  status: Status.Ready,
  error: null,
  progress: null,
  filesUploading: null,
  download: Status.Ready,
  getFiles: { getStatus: Status.Ready, files: null, filesLoading: null },
  deleteFile: Status.Ready,
};
