<div
  class="min-h-[fit-content] rounded-[10px] min-w-[60vw] bg-white py-6 px-10 flex flex-col gap-4 justify-center"
  (click)="$event.stopPropagation()"
>
  <div
    *ngIf="!!questionariName"
    class="inline-flex justify-center items-center text-3xl font-black font-poppins text-dialog"
  >
    {{ title }}
  </div>
  <app-users-table
    [watchListFullUsers]="watchListFullUsers"
    (userToBeAssigned)="setUserResponsible($event)"
    (numChecked)="getNumChecked($event)"
    [singleSelection]="selectionMode"
    [usedForWatchlist]="usedForWatchlist"
    [selectedUsers]="selectedUsers"
    [mode]="isModified"
    [questionnaireOwner]="questionnaireOwner"
    [disabled]="false"
    [usedForChangeOwnerProject]="usedforChangeResponsabile"
  >
  </app-users-table>
  <div *ngIf="isCollaborative && isActive">
    <mat-radio-group>
      <mat-radio-button (change)="collaborativeOptions($event)" value="allTopicsToNewUR">
        {{ "PROJECT_OPTIONS.RADIO_POP21" | translate }}
        {{
          "tutto " + ("DICTIONARY.Topic" | translate | articleDictionary: "plural":lang)
            | verbPronounDictionary: "plural":lang
        }}
        {{ "DICTIONARY.Topic" | translate | articleDictionary: "plural":lang }}
        {{ "PROJECT_OPTIONS.RADIO_POP22" | translate }} {{ "DICTIONARY.Responsabile" | translate }}.
      </mat-radio-button>
      <br />
      <br />
      <mat-radio-button (change)="collaborativeOptions($event)" value="someTopicsToNewUR">
        {{ "PROJECT_OPTIONS.RADIO_POP11" | translate }} {{ "DICTIONARY.Responsabile" | translate | titlecase }}
        {{ "PROJECT_OPTIONS.RADIO_POP12" | translate }}
        {{ "DICTIONARY.Topic" | translate | lowercase | articleDictionary: "plural":lang }}
        {{ "PROJECT_OPTIONS.RADIO_POP13" | translate }}
        <span class="block"
          >{{
            "altro " + ("DICTIONARY.Topic" | translate)
              | verbPronounDictionary: "plural":lang
              | articleDictionary: "plural":lang
          }}
          {{ "DICTIONARY.Topic" | translate | lowercase }} {{ "PROJECT_OPTIONS.RADIO_POP14" | translate }}.
        </span>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="w-full flex items-end justify-end gap-4">
    <button class="whiteBtn" (click)="hideTable()">
      {{ "ANNULLA" | translate }}
    </button>
    <button [ngClass]="{ disabled: numUsersChecked === 0 }" class="colorBtn" (click)="changeResponsible()">
      {{ "CONFERMA" | translate }}
    </button>
  </div>
</div>
