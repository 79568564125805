export const FILE_EXT_LIST = [
  // array of file class list based on type
  {
    ext: ['.doc', ' .docx'],
    iconPath: 'assets/images/icons/word_file.svg',
    fileType: 'word',
  },
  {
    ext: ['.xls', ' .xlsx'],
    iconPath: 'assets/images/icons/excel_file.svg',
    fileType: 'excel',
  },
  {
    ext: ['.ppt', ' .pptx'],
    iconPath: 'assets/images/icons/ppt_file.svg',
    fileType: 'presentation',
  },
  {
    ext: ['.jpg', ' .jpeg', ' .png'],
    iconPath: 'assets/images/icons/img_file.svg',
    fileType: 'image',
  },
  {
    ext: ['.mp4'],
    iconPath: 'assets/images/icons/other_file.svg',
    fileType: 'video',
  },
  {
    ext: ['.pdf'],
    iconPath: 'assets/images/icons/other_file.svg',
    fileType: 'pdf',
  },
  {
    ext: [''],
    iconPath: 'assets/images/icons/other_file.svg',
    fileType: 'any',
  },
];
