<ng-container>
  <mat-nav-list class="bg-dialog font-normal text-white">
    <ng-template ngFor let-item [ngForOf]="linksArray" let-i="index">
      <a
        *ngIf="item.icon !== 'logout' && abilitiesHelper[item.permission]"
        [ngClass]="{ 'active-link': this.location.path() === item.url }"
        mat-list-item
        routerLink="{{ item.url }}"
        routerLinkActive="active"
        (click)="closed.emit(true)"
      >
        <mat-icon class="text-white">
          {{ item.icon }}
        </mat-icon>
        <span *ngIf="item.name !== 'MODELS_AND_PROJECTS'" class="pl-3 !text-white">
          {{ "HEADER.MENU_OPTIONS." + item.name | translate }}
        </span>
        <span *ngIf="item.name === 'MODELS_AND_PROJECTS'" class="pl-3 !text-white">
          {{ "DICTIONARY.Modelli" | translate }} {{ "AND" | translate }}
          {{ "DICTIONARY.Progetti" | translate }}
        </span>
      </a>
      <a *ngIf="item.icon === 'logout'" mat-list-item routerLink="{{ item.url }}" (click)="logout()">
        <mat-icon class="text-white">
          {{ item.icon }}
        </mat-icon>
        <span class="pl-3 !text-white">
          {{ "HEADER.MENU_OPTIONS." + item.name | translate }}
        </span>
      </a>
    </ng-template>

    <a *ngIf="userMenu">
      <div matLine class="mt-[10px] flex h-[50px] flex-col items-center justify-between">
        <hr class="h-[1px] w-[80%] bg-white" />
        <div class="h-full w-full px-[17px]">
          <div class="float-left flex h-full w-1/2 items-center justify-center bg-dialog">
            <img
              src="../../assets/countries/it.svg"
              alt=""
              class="h-[22px] w-[28px] cursor-pointer"
              (click)="changeLanguage('it')"
            />
          </div>
          <div class="float-right flex h-full w-1/2 items-center justify-center bg-dialog">
            <img
              src="../../assets/countries/en.svg"
              alt=""
              class="h-[22px] w-[28px] cursor-pointer"
              (click)="changeLanguage('en')"
            />
          </div>
        </div>
      </div>
    </a>
  </mat-nav-list>
  <span (click)="closed.emit(true)"></span>
</ng-container>
