<div
  class="models-container mt-[2vh] h-[fit-content] w-full rounded-3xl bg-matCardColorCustom px-10 pt-0 pb-6"
  [ngClass]="{ 'modal-model-container': selectMode }"
>
  <div
    class="title-line mb-[2.5rem] flex w-full items-center justify-between pt-[2.5rem] text-3xl text-main"
    [ngClass]="{ 'flex flex-col': selectMode }"
  >
    <div *ngIf="!selectMode" class="flex items-center self-start justify-self-start">
      <span class="mb-0 text-2xl font-semibold tracking-[-1px]">{{
        "DICTIONARY.Modelli" | translate | titlecase
      }}</span>
      <div *ngIf="lang === 'it'" class="w-[24vw] pl-12 font-poppins text-base">
        {{ "MODEL_TABLE.DESCRIPTION1" | translate }}
        {{ "DICTIONARY.Modelli" | translate | lowercase | articleDictionary: "plural":lang }}
        di {{ "DICTIONARY.Questionario" | translate | lowercase }}
        {{ "MODEL_TABLE.DESCRIPTION2" | translate }}
        {{ "a " + ("DICTIONARY.Responsabili" | translate) | lowercase | prepositionDictionary: "plural":lang }}
        {{ "MODEL_TABLE.DESCRIPTION3" | translate }}
        {{ "DICTIONARY.Progetti" | translate | lowercase }}
      </div>
      <div *ngIf="lang === 'en'" class="w-[24vw] pl-12 font-poppins text-base">
        {{ "MODEL_TABLE.DESCRIPTION1" | translate }}
        {{ "DICTIONARY.Questionari" | translate | lowercase }}
        {{ "DICTIONARY.Modelli" | translate | lowercase }}
        {{ "MODEL_TABLE.DESCRIPTION2" | translate }}
        {{ "DICTIONARY.Responsabili" | translate | lowercase }}
        {{ "MODEL_TABLE.DESCRIPTION3" | translate }}
        {{ "DICTIONARY.Progetti" | translate | lowercase }}
      </div>
    </div>
    <!-- <span !visible>Questa sezione permette di strutturare modelli di questionario e assegnarli ai responsabili tramite la creazione di progetti created</span> -->
    <div class="pb-8 text-3xl font-black text-dialog" *ngIf="selectMode">
      {{ "MODEL_TABLE.DESCRIPTION_SELECT1" | translate }}
      {{ "DICTIONARY.Modello" | translate | lowercase | articleDictionary: "singular":lang }}
      {{ "MODEL_TABLE.DESCRIPTION_SELECT2" | translate }}
      {{ "a " + ("DICTIONARY.Progetto" | translate) | prepositionDictionary: "singular":lang | lowercase }}
    </div>
    <div class="flex flex-col">
      <app-advanced-filter
        [lang]="lang"
        (updateModelsFilters)="updateModelsFilters($event)"
        (modelReset)="modelReset()"
        (modelSearch)="modelSearch($event)"
        [modelOrProject]="'model'"
        [searchTerm]="searchTerm"
        class="z-20"
      >
      </app-advanced-filter>
    </div>
  </div>
  <div class="models-table-container" matSort (matSortChange)="sortData($event)">
    <div [ngClass]="{ 'select-mode-grid': selectMode }" class="one-line-grid mb-[1vh] h-12">
      <div *ngIf="!selectMode" class="select-area flex items-center justify-center text-base font-bold">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isPageChecked()"
          [indeterminate]="checkedModels.length > 0 && !isPageChecked()"
        >
        </mat-checkbox>
      </div>
      <div mat-sort-header="Questionnaire_Title" class="title-desc flex items-center text-base font-bold">
        <h2>{{ "MODEL_TABLE.TITLE" | translate }}</h2>
        &nbsp;
        <h2 *ngIf="!selectMode">
          {{ "MODEL_TABLE.&_DESCRIPTION" | translate }}
        </h2>
      </div>
      <div mat-sort-header="Creator" class="created-from flex items-center text-base font-bold">
        <h2>{{ "MODEL_TABLE.CREATED_FROM" | translate }}</h2>
      </div>
      <div
        mat-sort-header="Questionnaire_Status"
        *ngIf="!selectMode"
        class="status flex items-center justify-center text-base font-bold"
      >
        <h2>{{ "MODEL_TABLE.STATUS" | translate }}</h2>
      </div>
      <div
        mat-sort-header="Questionnaire_Cretion_Date"
        class="created-date flex items-center justify-center text-base font-bold"
        *ngIf="!selectMode"
      >
        <h2>{{ "MODEL_TABLE.DATE_CREATED" | translate }}</h2>
      </div>
      <div mat-sort-header="ult.assignment" class="final-assign flex items-center justify-center text-base font-bold">
        <h2>{{ "MODEL_TABLE.LAST_ASSIGNMENTS" | translate }}</h2>
      </div>
      <div mat-sort-header="ult.modification" class="final-mod flex items-center justify-center text-base font-bold">
        <h2>{{ "MODEL_TABLE.LAST_CHANGE" | translate }}</h2>
      </div>
      <div
        mat-sort-header="Number_Of_Topics"
        class="topics-number flex items-center justify-center text-base font-bold"
      >
        <h2>N.{{ "DICTIONARY.Topic" | translate | titlecase }}</h2>
      </div>
      <div
        mat-sort-header="Number_Of_Projects"
        *ngIf="!selectMode"
        class="projects-number flex items-center justify-center text-base font-bold"
      >
        <h2>N.{{ "DICTIONARY.Progetti" | translate | titlecase }}</h2>
      </div>
      <div *ngIf="!selectMode" class="actions"></div>
    </div>

    <div
      id="table-content"
      class="all-models h-[38vh] overflow-y-auto overflow-x-hidden xl:h-[40vh] 2xl:h-[54vh] 1.5xl:h-[54vh] 3xl:h-[60vh]"
      [ngClass]="{
        'flex w-full items-center justify-center': (loading | async),
        hidden: (totalAndPageIndex | async).total === 0 && !(loading | async)
      }"
    >
      <div *ngIf="!(loading | async); else Loading">
        <div
          [ngClass]="{ 'select-mode-grid': selectMode, '!mb-0': ind / 9 === 1 }"
          *ngFor="let quest of dataSource; let ind = index"
          class="model-template mb-[0.8rem] h-[fit-content] bg-[#ffffff] text-[#000000]"
        >
          <div *ngIf="!selectMode" class="select-area flex items-center justify-center">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selectionToggle(quest, $event.checked) : null"
              [checked]="selection.isSelected(quest)"
            >
            </mat-checkbox>
          </div>

          <div class="list flex items-center justify-center">
            <mat-radio-group [(ngModel)]="projectSelected">
              <mat-radio-button
                [value]="quest.Questionnaire_Title"
                (change)="selectModel(quest)"
                *ngIf="selectMode"
                class="example-radio-button"
              ></mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="title-desc flex min-h-[4rem] flex-col items-start justify-center text-base">
            <div
              class="max-w-full truncate pr-4"
              [matTooltip]="quest['Questionnaire_Title']"
              matTooltipClass="custom-tooltip"
              appShowIfTruncated
            >
              {{ quest["Questionnaire_Title"] }}
            </div>
            <div
              *ngIf="!selectMode"
              class="max-w-full truncate pr-4 text-sm"
              [matTooltip]="quest.Description"
              matTooltipClass="custom-tooltip"
              appShowIfTruncated
            >
              {{ quest.Description }}
            </div>
          </div>
          <div class="created-from flex flex-col items-start justify-center">
            <div
              class="max-w-full truncate"
              [matTooltip]="(usersFullName | async)[quest.Creator]"
              matTooltipClass="custom-tooltip"
              appShowIfTruncated
            >
              {{ (usersFullName | async)[quest.Creator] }}
            </div>
          </div>

          <div *ngIf="!selectMode" class="flex items-center justify-center">
            <div
              class="status rounded[6px] flex h-[65%] w-[80%] items-center justify-center"
              [ngClass]="quest.Questionnaire_Status"
            >
              {{ quest["Questionnaire_Status"] | convertToItalian: lang }}
            </div>
          </div>
          <div class="created-date flex items-center justify-center" *ngIf="!selectMode">
            {{ quest["DATE_CREATED"] !== null ? quest["DATE_CREATED"].split("T")[0] : "--" }}
          </div>
          <div class="final-assign flex items-center justify-center">
            {{ quest["Project_Last_Modified"] !== null ? quest["Project_Last_Modified"].split("T")[0] : "--" }}
          </div>
          <div class="final-mod flex items-center justify-center">
            {{ quest["Last_modified"] !== null ? quest["Last_modified"].split("T")[0] : "--" }}
          </div>
          <div class="topics-number flex items-center justify-center">
            {{ quest.Number_Of_Topics }}
          </div>
          <div *ngIf="!selectMode" class="projects-number flex items-center justify-center">
            <a
              (click)="showProjectsOfModel(quest.Questionnaire_Title, quest.Questionnaire_id)"
              class="cursor-pointer text-dialog underline"
              >{{ quest["Number_Of_Projects"] }}</a
            >
          </div>
          <div *ngIf="!selectMode" class="actions flex items-center justify-center">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon class="text-dialog" matTooltip="{{ 'ACTIONS' | translate }}" matTooltipClass="custom-tooltip">
                more_vert</mat-icon
              >
            </button>
            <mat-menu #menu="matMenu">
              <button
                (click)="modifyQuestionnaire(quest.Questionnaire_id)"
                mat-menu-item
                [disabled]="
                  !canCreateModifyModel && !(quest.Questionnaire_Status === 'Completed' && canEditActiveModels)
                "
              >
                <mat-icon
                  [ngClass]="{
                    'disabled-icon':
                      !canCreateModifyModel && !(quest.Questionnaire_Status === 'Completed' && canEditActiveModels)
                  }"
                  >launch</mat-icon
                >
                <span>{{ "APRI" | translate }}</span>
              </button>
              <button (click)="openNewTitle(quest.Questionnaire_id)" mat-menu-item [disabled]="!canCreateModifyModel">
                <mat-icon [ngClass]="{ 'disabled-icon': !canCreateModifyModel }">content_copy</mat-icon>
                <span>{{ "CLONA" | translate }}</span>
              </button>
              <button
                mat-menu-item
                (click)="createProject(quest.Questionnaire_id)"
                *ngIf="quest.Questionnaire_Status !== 'Draft'"
              >
                <mat-icon>note_add</mat-icon>
                <span
                  >{{ "MODEL_TABLE.BUTTON_NEW" | translate
                  }}{{ "Nuovo " + ("DICTIONARY.Progetto" | translate) | verbPronounDictionary: "singular":lang }}
                  {{ "DICTIONARY.Progetto" | translate | titlecase }}</span
                >
              </button>
              <button
                mat-menu-item
                (click)="openExportCsv(quest.Questionnaire_id)"
                *ngIf="quest.Questionnaire_Status !== 'Draft'"
              >
                <mat-icon>file_download</mat-icon>
                <span>{{ "MODEL_TABLE.BUTTON_EXPORT" | translate }}</span>
              </button>
              <button
                *ngIf="quest.Questionnaire_Status !== 'Archived'"
                mat-menu-item
                (click)="archiveModel(quest)"
                [disabled]="!canCreateModifyModel"
              >
                <mat-icon [ngClass]="{ 'disabled-icon': !canCreateModifyModel }">move_to_inbox</mat-icon>
                <span>{{ "ARCHIVIA" | translate }}</span>
              </button>
              <button
                *ngIf="quest.Questionnaire_Status !== 'Completed'"
                mat-menu-item
                (click)="deleteSingleQuestionnaire(quest)"
              >
                <mat-icon>delete</mat-icon>
                <span>{{ "ELIMINA" | translate | titlecase }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>

    <!-- No data found warning -->
    <div
      id="noDataFound"
      class="all-models h-[38vh] overflow-y-auto overflow-x-hidden xl:h-[40vh] 2xl:h-[54vh] 1.5xl:h-[54vh] 3xl:h-[60vh]"
      *ngIf="
        (totalAndPageIndex | async).pageIndex === 0 && (totalAndPageIndex | async).total === 0 && !(loading | async)
      "
    >
      <div class="flex h-full flex-col items-center justify-center gap-8">
        <mat-icon class="mb-8 !text-card">search_off</mat-icon>
        <span id="main-text" class="text-5xl font-semibold text-main">{{
          "MODEL_TABLE.NO_DATA_FOUND" | translate | titlecase
        }}</span>
        <span id="sub-text" class="max-w-4xl text-center text-3xl text-gray-400">{{
          lang === "it"
            ? "Non è possibile trovare alcun " +
              ("DICTIONARY.Modello" | translate | lowercase) +
              " corrispondente alla tua ricerca"
            : "It is not possible to find any " +
              ("DICTIONARY.Modello" | translate | lowercase) +
              " matching your search"
        }}</span>
      </div>
    </div>

    <div class="my-4 flex items-center justify-end">
      <mat-paginator
        [ngClass]="{ 'noIcon !text-transparent': (loading | async) }"
        [length]="(totalAndPageIndex | async).total"
        [pageIndex]="(totalAndPageIndex | async).pageIndex"
        [pageSize]="10"
        [hidePageSize]="true"
        (page)="getNextQuestionnairyStructures($event)"
      >
      </mat-paginator>
    </div>

    <div id="buttons" *ngIf="!selectMode && !(loading | async)" class="flex justify-between">
      <div class="inline-flex items-center gap-4" id="selection-buttons">
        <button
          id="archive-button"
          class="redBtn disabled:redBtn-disabled"
          [disabled]="checkedModels.length === 0"
          (click)="archiveModel()"
        >
          {{ "ARCHIVIA" | translate }} ({{ checkedModels.length }})
        </button>
        <button
          id="delete-button"
          class="redBtn disabled:redBtn-disabled"
          [disabled]="checkedModels.length === 0"
          (click)="deleteMultipleQuestionnaires()"
        >
          {{ "ELIMINA" | translate }} ({{ checkedModels.length }})
        </button>
      </div>
      <div id="main-buttons" class="inline-flex items-center gap-4">
        <button
          id="new-model-button"
          class="whiteBtn"
          (click)="toCreateNewQuestionnaire()"
          *ngIf="canCreateModifyModel"
        >
          {{ "MODEL_TABLE.BUTTON_NEW" | translate }}
          {{ "Nuovo " + ("DICTIONARY.Modello" | translate) | verbPronounDictionary: "singular":lang }}
          {{ "DICTIONARY.Modello" | translate | titlecase }} +
        </button>
        <button id="import-model-button">
          <label
            class="text-4 non-italic active:bg-light custom-file-upload flex cursor-pointer select-none items-center rounded-xl bg-card py-4 px-8 font-normal text-white transition-colors duration-300 active:text-card"
          >
            <input onclick="this.value = null" (change)="selectFile($event)" type="file" />
            {{ "MODEL_TABLE.BUTTON_IMPORT" | translate }}
            {{ "DICTIONARY.Modello" | translate | titlecase }}&nbsp;&nbsp;
          </label>
        </button>
      </div>
    </div>

    <div *ngIf="selectMode" id="other-buttons" class="mt-12 flex items-center justify-end gap-7">
      <button class="!whiteBtn" (click)="closeViewMode()">
        {{ "ANNULLA" | translate }}
      </button>
      <button class="colorBtn" (click)="returnModelAndClose()">
        {{ "CONFERMA" | translate }}
      </button>
    </div>
  </div>
  <ng-template #Loading>
    <app-spinner class="flex justify-center"></app-spinner>
  </ng-template>

  <div *ngIf="showExport" class="overlayHidden" [ngClass]="{ overlayShow: showExport }" (click)="showExport = false">
    <div
      class="input-note ml-[10px] mt-[10px] flex h-[20rem] w-[74rem] min-w-[500px] flex-col justify-between rounded-[10px] bg-white p-6"
      (click)="$event.stopPropagation()"
    >
      <h2 class="non-italic flex justify-start pt-4 font-poppins text-4xl font-bold tracking-normal text-dialog">
        {{ "MODEL_TABLE.POP_UP_EXPORT_TITLE1" | translate }}
        {{ "DICTIONARY.Modello" | translate | titlecase }}
        {{ "MODEL_TABLE.POP_UP_EXPORT_TITLE2" | translate }}
      </h2>
      <div class="self-start font-opensans text-base">
        {{ "MODEL_TABLE.POP_UP_EXPORT_DESCRIPTION1" | translate }}
        {{ "DICTIONARY.Modello" | translate | lowercase | articleDictionary: "singular":lang }}
        {{ "MODEL_TABLE.POP_UP_EXPORT_DESCRIPTION2" | translate }}
      </div>

      <div class="flex items-center justify-end self-end pt-[5px]">
        <button class="mr-[0.8vw] whiteBtn" (click)="showExport = false">
          {{ "ANNULLA" | translate }}
        </button>
        <button (click)="exportCsvRead()" class="mr-[0.8vw] colorBtn">
          {{ "MODEL_TABLE.POP_UP_EXPORT_BUTTON_READABLE" | translate }}
        </button>
        <button (click)="exportCsvRaw()" class="colorBtn">
          {{ "MODEL_TABLE.POP_UP_EXPORT_BUTTON_IMPORTABLE" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="openNewTitleDialog"
    class="overlayHidden"
    [ngClass]="{ overlayShow: openNewTitleDialog }"
    (click)="openNewTitleDialog = false"
  >
    <div
      class="input-note ml-[10px] mt-[10px] flex h-[32vh] w-[40vw] flex-col items-center justify-between rounded-[10px] bg-white p-6"
      (click)="$event.stopPropagation()"
    >
      <h2 class="non-italic flex justify-start pt-4 font-poppins text-4xl font-bold tracking-normal text-dialog">
        {{ "MODEL_TABLE.POP_UP_CLONE_TITLE" | translate }}
        {{ "DICTIONARY.Modello" | translate | titlecase }}
      </h2>
      <input
        class="!mt-[3rem] !w-[85%] border-b-2 border-dialog !pl-[1rem] !text-xl"
        type="text"
        name="ClonedTitle"
        placeholder="{{ 'MODEL_TABLE.POP_UP_CLONE_PLACEHOLDER1' | translate }} {{
          'DICTIONARY.Modello' | translate | titlecase
        }} {{ 'MODEL_TABLE.POP_UP_CLONE_PLACEHOLDER2' | translate }}"
        #ModelTitle
        maxlength="100"
      />
      <mat-hint class="self-end pr-[4rem] text-sm">{{ ModelTitle.value.length }}/100</mat-hint>
      <div class="flex items-center justify-end self-end">
        <button class="mr-[0.8vw] whiteBtn" (click)="openNewTitleDialog = false">
          {{ "ANNULLA" | translate }}
        </button>
        <button (click)="duplicateModel(ModelTitle.value)" class="colorBtn">
          {{ "CONFERMA" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
