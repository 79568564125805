<div class="h-full overflow-y-auto">
  <div class="relative h-full w-full px-20 py-4">
    <ng-container *ngFor="let topic of topics; let topicIndex = index">
      <mat-expansion-panel hideToggle class="mat-elevation-z0 expansionCustom !mb-2">
        <mat-expansion-panel-header
          class="disable_ripple !p-0"
          expandedHeight="fit-content"
          collapsedHeight="fit-content"
        >
          <span class="flex items-center justify-center text-2xl font-semibold text-card">
            <mat-icon class="mr-2">expand_more</mat-icon>
            {{ topic.Title }}
          </span>
        </mat-expansion-panel-header>
        <div
          class="flex w-full flex-col py-4 px-16"
          *ngFor="let question of topic.questions; let questionIndex = index"
        >
          <span class="mb-2 text-card underline">{{ question.Question_Text }}</span>
          <div class="progressBars flex h-8 w-[70%] w-full rounded-full">
            <div
              (click)="showPopUp(question, option)"
              *ngFor="let option of question.options; let optionIndex = index"
              attr.id="{{ topicIndex }}{{ questionIndex }}{{ getCurrectOptionShowOrder(option, question.options) }}"
              matTooltip="{{ option.numberWhoAnswered }} {{
                option.numberWhoAnswered > 1
                  ? ('ANALYTICS_DETAILS.USERS_ANSWERED' | translate)
                  : ('ANALYTICS_DETAILS.USER_ANSWERED' | translate)
              }}: {{ option.answer }}"
              matTooltipClass="progress-bar-tooltip"
              class="flex h-8 cursor-pointer items-center justify-center rounded-full"
              [ngClass]="{
                'mr-2':
                  getCurrectOptionShowOrder(option, question.options) <
                    getSegments(question.notAnswerNumber, question.options) - (question.notAnswerNumber > 0 ? 2 : 1) &&
                  option.numberWhoAnswered > 0
              }"
              [ngStyle]="{
                width:
                  question.options.length > 0
                    ? getWidth(
                        option.numberWhoAnswered,
                        question.answerNumber,
                        question.notAnswerNumber,
                        question.options
                      )
                    : '0px'
              }"
            >
              <span *ngIf="option.numberWhoAnswered > 0" class="text-2xl">{{ option.numberWhoAnswered }}</span>
            </div>

            <div
              (click)="showPopUp(question, null, topicIndex, questionIndex)"
              *ngIf="question.options.length === 0 && question.answerNumber > 0"
              attr.id="{{ topicIndex }}{{ questionIndex }}0"
              matTooltip="{{ question.answerNumber }} {{
                question.answerNumber > 1
                  ? ('ANALYTICS_DETAILS.USERS_ANSWERED' | translate)
                  : ('ANALYTICS_DETAILS.USER_ANSWERED' | translate)
              }} {{ 'ANALYTICS_DETAILS.TO_THIS_QUESTION' | translate }}"
              matTooltipClass="progress-bar-tooltip"
              class="flex h-8 cursor-pointer items-center justify-center rounded-full"
              [ngStyle]="{
                width: getWidth(
                  question.answerNumber,
                  question.answerNumber,
                  question.notAnswerNumber,
                  question.options
                )
              }"
            >
              <span class="text-2xl">{{ question.answerNumber }}</span>
            </div>

            <div
              *ngIf="question.notAnswerNumber > 0"
              matTooltip="{{ question.notAnswerNumber }} {{
                question.notAnswerNumber > 1
                  ? ('ANALYTICS_DETAILS.USERS_NOT_ANSWERED' | translate)
                  : ('ANALYTICS_DETAILS.USER_NOT_ANSWERED' | translate)
              }} {{ 'ANALYTICS_DETAILS.TO_THIS_QUESTION' | translate }}"
              matTooltipClass="progress-bar-tooltip"
              class="notAnswered flex h-8 items-center justify-center rounded-full"
              [ngClass]="{ 'ml-2': question.answerNumber > 0 }"
              [ngStyle]="{
                width: getWidth(
                  question.notAnswerNumber,
                  question.answerNumber,
                  question.notAnswerNumber,
                  question.options
                )
              }"
            >
              <span class="text-2xl"> {{ question.notAnswerNumber }}</span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </div>
</div>
