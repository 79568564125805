import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule, Title } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './modules/app-routing.module';
import { PipeModule } from './modules/pipes.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DatepickComponent } from './components/datepick/datepick.component';
import { DomandaVincolataDialogComponent } from './components/domanda-vincolata-dialog/domanda-vincolata-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { ListBoxComponentComponent } from './components/list-box-component/list-box-component.component';
import { LoginComponent, ForgotPasswordDialog } from './components/login/login.component';
import { ModifyTopicComponent } from './components/modify-topic/modify-topic.component';
import { NotesComponent } from './components/notes/notes.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TitleOfQuestionnaireComponent } from './components/title-of-questionnaire/title-of-questionnaire.component';
import { ArchiveQuestionnaireEffects } from './Store/archive-questionnaire/archive.state.effects';
import { archiveQuestionnaireReducer } from './Store/archive-questionnaire/archive.state.reducers';
import { AssignedQuestionnairesEffects } from './Store/assignedQuestionnaires/effects/assigned-questionnaires.effects';
import { asssignedQuestionnaireReducer } from './Store/assignedQuestionnaires/reducers/assigned-questionnaires.reducer';
import { HomePageEffects } from './Store/home-page/home-page.effects';
import { homePageReducer } from './Store/home-page/home-page.reducers';
import { LoginEffects } from './Store/login-store/login.effects';
import { QuestionnaireEffects } from './Store/questionnaire-store/state.effects';
import { questionnaireReducer } from './Store/questionnaire-store/state.reducers';
import { TopicEffects } from './Store/topics-store/topic.effects';
import { topicReducer } from './Store/topics-store/topic.reducer';
import { UserEffects } from './Store/users/user.effects';
import { userReducer } from './Store/users/users.reducers';
import { ChangeResponsibleComponent } from './components/change-responsible/change-responsible.component';
import { HeaderInterceptor } from './data/api/header-interceptor';
import { notificationsReducer } from './Store/notifications-store/reducers/notifications.reducer';
import { HomeDialogComponent } from './features/home-page/home-dialog/home-dialog.component';
import { HomeFilterComponent } from './features/home-page/home-filter/home-filter.component';
import { HomePageComponent } from './features/home-page/home-page.component';
import { HomeQuestionnaireComponent } from './features/home-page/home-questionnaire/home-questionnaire.component';
import { CreateNewQuestionnaireComponent } from './features/questionnaire-creation/create-new-questionnaire/create-new-questionnaire.component';
import { CreateNewTopicComponent } from './features/questionnaire-creation/create-new-topic/create-new-topic.component';
import { NewTopicComponent } from './features/questionnaire-creation/new-topic/new-topic.component';
import { NewQuestionModalComponent } from './features/questionnaire-creation/questions/new-question-modal/new-question-modal.component';
import { NewQuestionComponent } from './features/questionnaire-creation/questions/new-question/new-question.component';
import { VincolataLinesComponent } from './features/questionnaire-creation/vincolata-lines/vincolata-lines.component';
import { AssignModelPageComponent } from './features/questionnaire-management/assign-model-page/assign-model-page.component';
import { CloneProjectOptionsComponent } from './features/questionnaire-management/clone-project-options/clone-project-options.component';
import { EditProjectComponent } from './features/questionnaire-management/edit-project/edit-project.component';
import { QuestionnaireManagementComponent } from './features/questionnaire-management/management-questionnaire.component';
import { ModelsTableComponent } from './features/questionnaire-management/models-table/models-table.component';
import { ModifyProjectComponent } from './features/questionnaire-management/modify-project/modify-project.component';
import { ModifyQuestionnaireComponent } from './features/questionnaire-management/modify-questionnaire/modify-questionnaire.component';
import { ProjectsTableComponent } from './features/questionnaire-management/projects-table/projects-table.component';
import { BaseComponent } from './features/questionnaire-to-answer/base/base.component';
import { CheckListQuestionComponent } from './features/questionnaire-to-answer/check-list-question/check-list-question.component';
import { FreeTextQuestionComponent } from './features/questionnaire-to-answer/free-text-question/free-text-question.component';
import { ListBoxQuestionComponent } from './features/questionnaire-to-answer/list-box-question/list-box-question.component';
import { QuestionFormComponent } from './features/questionnaire-to-answer/question-form/question-form.component';
import { QuestionnaireToAnswerComponent } from './features/questionnaire-to-answer/questionnaire-to-answer.component';
import { ReAssignTopicComponent } from './features/questionnaire-to-answer/re-assign-topic/re-assign-topic.component';
import { TopicMenuComponent } from './features/questionnaire-to-answer/topic-menu/topic-menu.component';
import { AssingModelTableComponent } from './features/questionnaire-management/assign-model-page/assing-model-table/assing-model-table.component';
import { ShowIfTruncatedDirective } from './utils/directive/show-if-truncated.directive';
import { SharedModule } from './features/shared/shared.module';
import { ProjectEffects } from './Store/projects/effects/projects.effects';
import { projectsReducer } from './Store/projects/reducers/projects.reducer';
import { administrativeReducer } from './features/administrative/store/administrative-page.reducers';
import { CustomazationStringPipe } from './utils/pipes/customazation-string.pipe';
import { AdministrativeEffects } from './features/administrative/store/administrative-page.effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IndefiniteArticleDictionaryPipe } from './utils/pipes/indefinite-article-dictionary.pipe';
import { PossessiveAdjectiveDictionaryPipe } from './utils/pipes/possessive-adjective-dictionary.pipe';
import { AdvancedFilterComponent } from './features/questionnaire-management/advanced-filter/advanced-filter.component';
import { YesNoQuestionComponent } from './features/questionnaire-to-answer/yes-no-question/yes-no-question.component';
import { FileQuestionComponent } from './features/questionnaire-to-answer/file-question/file-question.component';
import { DragndropDirective } from './utils/directive/dragndrop.directive';
import { CommentQuestionComponent } from './features/questionnaire-to-answer/comment-question/comment-question.component';
import { UploadFileStoreModule } from './Store/upload-file-store/upload-file-store.module';
import { FileRepositoryComponent } from './components/file-repository/file-repository.component';
import { NotificationsEffects } from './Store/notifications-store/effects/notifications.effects';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { AnalyticsPanelComponent } from './components/analytics-panel/analytics-panel.component';
import { AnalyticsScorePanelComponent } from './components/analytics-score-panel/analytics-score-panel.component';
import { AnalyticsPanelOverallComponent } from './components/analytics-panel/analytics-panel-overall/analytics-panel-overall.component';
import { AnalyticsPanelDetailsComponent } from './components/analytics-panel/analytics-panel-details/analytics-panel-details.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AnalyticsFilter } from './components/analytics-panel/analytics-filter/analytics-filter.component';
import { QuestionDetailsModalComponent } from './components/analytics-panel/analytics-panel-details/question-details-modal/question-details-modal.component';
import { adminGroupsReducer } from './Store/admin-groups/admin-groups.reducers';
import { AdminGroupsEffects } from './Store/admin-groups/admin-groups.effects';

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    StoreModule.forRoot({
      store: questionnaireReducer,
      topics: topicReducer,
      archived: archiveQuestionnaireReducer,
      assignedQuestionnaires: asssignedQuestionnaireReducer,
      Projects: projectsReducer,
      users: userReducer,
      home: homePageReducer,
      notifications: notificationsReducer,
      administrativeState: administrativeReducer,
      adminGroups: adminGroupsReducer,
    }),

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([
      QuestionnaireEffects,
      LoginEffects,
      TopicEffects,
      ArchiveQuestionnaireEffects,
      AssignedQuestionnairesEffects,
      UserEffects,
      HomePageEffects,
      ProjectEffects,
      AdministrativeEffects,
      NotificationsEffects,
      AdminGroupsEffects,
    ]),
    UploadFileStoreModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpBackend],
        useFactory: translateHttpLoaderFactory,
      },
    }),
    NgApexchartsModule,
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    SideNavComponent,
    LoginComponent,
    HomePageComponent,
    QuestionnaireManagementComponent,
    ModelsTableComponent,
    TitleOfQuestionnaireComponent,
    CreateNewTopicComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    DomandaVincolataDialogComponent,
    ListBoxComponentComponent,
    ForgotPasswordDialog,
    LoginComponent,
    BaseComponent,
    ResetPasswordComponent,
    DatepickComponent,
    QuestionnaireToAnswerComponent,
    QuestionFormComponent,
    ListBoxQuestionComponent,
    YesNoQuestionComponent,
    CommentQuestionComponent,
    FreeTextQuestionComponent,
    CheckListQuestionComponent,
    EditProjectComponent,
    ReAssignTopicComponent,
    HomeQuestionnaireComponent,
    QuestionFormComponent,
    NotesComponent,
    HomeFilterComponent,
    HomeDialogComponent,
    FooterComponent,
    CreateNewQuestionnaireComponent,
    NewTopicComponent,
    NewQuestionComponent,
    NewQuestionModalComponent,
    TopicMenuComponent,
    ModifyTopicComponent,
    AssignModelPageComponent,
    ModifyTopicComponent,
    ProjectsTableComponent,
    ModifyQuestionnaireComponent,
    ModifyProjectComponent,
    ChangeResponsibleComponent,
    CloneProjectOptionsComponent,
    VincolataLinesComponent,
    AssingModelTableComponent,
    ShowIfTruncatedDirective,
    CustomazationStringPipe,
    IndefiniteArticleDictionaryPipe,
    PossessiveAdjectiveDictionaryPipe,
    AdvancedFilterComponent,
    FileQuestionComponent,
    DragndropDirective,
    FileRepositoryComponent,
    AdminPanelComponent,
    AnalyticsPanelComponent,
    AnalyticsScorePanelComponent,
    AnalyticsPanelOverallComponent,
    AnalyticsPanelDetailsComponent,
    QuestionDetailsModalComponent,

    AnalyticsFilter,
  ],
  entryComponents: [
    CreateNewTopicComponent,
    EditProjectComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    DomandaVincolataDialogComponent,
    ForgotPasswordDialog,
    ListBoxComponentComponent,
    ReAssignTopicComponent,
    HomeDialogComponent,
    NotesComponent,
    NewQuestionModalComponent,
  ],
  providers: [
    Title,
    MatDatepickerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
