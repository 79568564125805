import { createAction, props } from '@ngrx/store';
import { Docufile } from 'src/app/data/models/Docufile';

export enum ActionTypes {
  UPLOAD_REQUEST = '[File Upload Form] Request',
  UPLOAD_CANCEL = '[File Upload Form] Cancel',
  UPLOAD_RESET = '[File Upload Form] Reset',
  UPLOAD_STARTED = '[File Upload API] Started',
  UPLOAD_PROGRESS = '[File Upload API] Progress',
  UPLOAD_WAITING = '[File Upload API] Uploading',
  UPLOAD_FAILURE = '[File Upload API] Failure',
  UPLOAD_COMPLETED = '[File Upload API] Success',
  DOWNLOAD_FILE_STARTED = '[File Download API] Started',
  DOWNLOAD_FILE_SUCCESS = '[File Download API] Success',
  GET_FILES_STARTED = '[File Get API] Started',
  GET_FILES_SUCCESS = '[File Get API] Success',
  GET_FILES_BYQUESTID_STARTED = '[File GetAll API] Started',
  GET_FILES_BYQUESTID_SUCCESS = '[File GetAll API] Success',
  DELETE_FILE_STARTED = '[File Delete API] Started',
  DELETE_FILE_SUCCESS = '[File Delete API] Success',
  DOWNLOAD_ZIP_STARTED = '[Zip Download API] Started',
  DOWNLOAD_ZIP_SUCCESS = '[Zip Download API] Success',
}

//UPLOAD ACTIONS
export const UploadRequestAction = createAction(
  ActionTypes.UPLOAD_REQUEST,
  props<{
    file: File;
    fileId: string;
    creationDate: string;
    topicName: string;
    questionName: string;
    comment: string;
    questionId: string;
    questAssignId: string;
    deletedFileId?: string;
  }>()
);
export const UploadCancelAction = createAction(ActionTypes.UPLOAD_CANCEL);
export const UploadResetAction = createAction(ActionTypes.UPLOAD_RESET);
export const UploadStartedAction = createAction(ActionTypes.UPLOAD_STARTED);
export const UploadWaitingAction = createAction(ActionTypes.UPLOAD_WAITING);
export const UploadProgressAction = createAction(ActionTypes.UPLOAD_PROGRESS, props<{ progress: number }>());
export const UploadErrorAction = createAction(ActionTypes.UPLOAD_FAILURE, props<{ error: string }>());
export const UploadCompletedAction = createAction(ActionTypes.UPLOAD_COMPLETED);

//DOWNLOAD FILE ACTIONS
export const DownloadFileAction = createAction(ActionTypes.DOWNLOAD_FILE_STARTED, props<{ fileId: string }>());
export const DownloadFileSuccess = createAction(ActionTypes.DOWNLOAD_FILE_SUCCESS);

//GET ACTIONS
export const GetFilesAction = createAction(ActionTypes.GET_FILES_BYQUESTID_STARTED, props<{ questAssignId: string }>());
export const GetFilesSuccess = createAction(ActionTypes.GET_FILES_BYQUESTID_SUCCESS, props<{ fileList: Docufile[] }>());

//DELETE ACTIONS
export const DeleteFileAction = createAction(
  ActionTypes.DELETE_FILE_STARTED,
  props<{ file: { id: string; questionId: string } }>()
);
export const DeleteFileSuccess = createAction(ActionTypes.DELETE_FILE_SUCCESS, props<{ deletedFileId: string }>());

//DOWNLOAD ZIP ACTIONS
export const DownloadZipAction = createAction(
  ActionTypes.DOWNLOAD_ZIP_STARTED,
  props<{ fileList: Docufile[]; questTitle: string }>()
);
export const DownloadZipSuccess = createAction(ActionTypes.DOWNLOAD_ZIP_SUCCESS);
