import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MY_DATE_FORMATS } from 'src/app/utils/config/custom-date.configuration';
import * as _moment from 'moment';
import * as _rollupMoment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-datepick',
  templateUrl: './datepick.component.html',
  styleUrls: ['./datepick.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class DatepickComponent implements OnInit {
  @Input()
  maxDate: string = '';

  @Input()
  topicDate: string = '';

  my_custom_format = MY_DATE_FORMATS;

  todayDate: Date = new Date();

  Expiry_Date = new FormControl();

  @Output()
  date = new EventEmitter<string>();

  ngOnInit() {
    if (this.topicDate !== '') {
      this.Expiry_Date.patchValue(moment(this.topicDate).format('YYYY-MM-DD').toLocaleString());
    }
  }

  sendDate(event) {
    if (!!event) {
      this.date.emit(moment(event['_d']).format('YYYY-MM-DD').toLocaleString());
    }
  }

  setTopic(element: string) {
    this.topicDate = element;
  }
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
