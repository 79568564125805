<div class="flex flex-col">
  <div class="h-[fit-content] w-[60vw] overflow-x-clip overflow-y-clip">
    <form [formGroup]="QuestionInfo">
      <ng-container class="flex h-[fit-content] flex-col overflow-auto overflow-x-hidden">
        <h2 *ngIf="data.modelStatus !== 'Archived'" class="w-full text-3xl font-black text-dialog">
          {{
            data.action === "Add"
              ? ("QUEST_CREATION.QUEST_POP_TITLE" | translate)
              : ("QUEST_CREATION.QUEST_POP_TITLE_EDIT" | translate)
          }}
        </h2>
        <h2 *ngIf="data.modelStatus === 'Archived'" class="w-full text-3xl font-black text-dialog">
          {{ "QUEST_CREATION.QUEST_POP_TITLE_DETAILS" | translate }}
        </h2>
        <div class="mb-7 flex justify-end">
          <mat-checkbox name="Question_has_comment" formControlName="Comment"
            >{{ "QUEST_CREATION.QUEST_POP_COMMENT_QUEST" | translate }}
          </mat-checkbox>
          <mat-checkbox
            [checked]="QuestionInfo.get('Question_Obbligatory').value === true"
            name="Question_Obbligatory"
            formControlName="Question_Obbligatory"
            class="ml-6"
            >{{ "QUEST_CREATION.QUEST_POP_MANDATORY_QUEST" | translate }}
          </mat-checkbox>
        </div>

        <div class="mb-6 flex justify-between">
          <mat-form-field appearance="outline" class="w-[10%]">
            <mat-label>
              {{ "QUEST_CREATION.QUEST_POP_POSITION_LABEL" | translate }}
            </mat-label>
            <mat-select class="text-center" name="Question_Position" formControlName="Question_Position">
              <mat-option class="text-black" *ngFor="let position of positions" [value]="position">
                {{ position }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="ml-6 w-[15%]">
            <mat-label>
              {{ "QUEST_CREATION.QUEST_POP_PLACE_TYPE_QUEST" | translate }}
            </mat-label>
            <mat-select formControlName="Question_Type" name="Question_Type" (valueChange)="changeQuestionType($event)">
              <mat-option class="mat-option-color" *ngFor="let type of TypeOfQuestionOptions" [value]="type.value">
                {{ lang === "en" ? type.viewValue : type.viewValueIT }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="this.QuestionInfo.get('Question_Type').value === 'Free text'"
            appearance="outline"
            class="ml-6 w-[15%]"
          >
            <mat-label>{{ "QUEST_CREATION.QUEST_POP_TYPE_ANS" | translate }} </mat-label>
            <mat-select name="Answer_Type" formControlName="Answer_Type">
              <mat-option class="mat-option-color" *ngFor="let type of TypeOfAnswerOptions" [value]="type.value">
                {{ lang === "en" ? type.viewValue : type.viewValueIT }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="this.QuestionInfo.get('Question_Type').value === 'File'"
            appearance="outline"
            class="ml-6 w-[15%]"
          >
            <mat-label>File Type</mat-label>
            <mat-select name="File_Type" formControlName="File_Type">
              <mat-option class="text-black" *ngFor="let option of fileOptions" [value]="option.value">
                <div>
                  {{ option.viewValue }}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            floatLabel="never"
            class="ml-6 w-[calc(75%-(2*1.5rem))]"
            [ngClass]="{
              'w-[calc(60%-(3*1.5rem))]': ['Free text', 'File'].includes(this.QuestionInfo.get('Question_Type').value)
            }"
            appearance="outline"
          >
            <mat-label>{{ "QUEST_CREATION.QUEST_POP_PLACE_DESC" | translate }}</mat-label>
            <input
              type="text"
              matInput
              name="Question_Desc"
              formControlName="Question_Desc"
              placeholder="{{ 'QUEST_CREATION.QUEST_POP_PLACE_DESC' | translate }}"
              #QuestionDesc
              maxlength="1200"
            />
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="mb-6 w-full">
          <mat-label>
            {{ "QUEST_CREATION.QUEST_POP_PLACE_TITLE" | translate }}
          </mat-label>
          <input
            type="text"
            matInput
            name="Question_Text"
            formControlName="Question_Text"
            placeholder="{{ 'QUEST_CREATION.QUEST_POP_PLACE_TITLE' | translate }}"
            #QuestionTitle
            maxlength="1200"
          />
        </mat-form-field>

        <div
          *ngIf="
            this.QuestionInfo.get('Question_Type').value &&
            !['Free text', 'File'].includes(this.QuestionInfo.get('Question_Type').value)
          "
          class="mb-6 flex h-[fit-content] max-h-[30vh] w-full overflow-y-scroll rounded-lg border-2 border-solid p-6 pb-0"
          [ngClass]="{
            '!overflow-y-hidden border-0 border-transparent !p-0':
              this.QuestionInfo.get('Question_Type').value === 'Yes or no'
          }"
        >
          <div
            class="flex h-[fit-content] w-full flex-col justify-center"
            formGroupName="ListBoxOption"
            [ngClass]="{
              'mr-6 w-[45%] !overflow-y-hidden rounded-lg border-2 border-solid p-6 pb-0':
                this.QuestionInfo.get('Question_Type').value === 'Yes or no'
            }"
          >
            <div
              [ngClass]="{
                'mb-6': this.QuestionInfo.get('Question_Type').value !== 'Check-list box'
              }"
            >
              <span class="text-2xl font-bold text-dialog">
                {{ "QUEST_CREATION.QUEST_POP_CHECK_TITLE" | translate }}
              </span>
              <div
                class="flex w-full items-center justify-end"
                *ngIf="this.QuestionInfo.get('Question_Type').value === 'Check-list box'"
              >
                <p class="text-dialog">Range of selectable options</p>
                <mat-form-field appearance="outline" class="ml-6 w-[8%] !text-xs">
                  <mat-label class="!text-[1rem]">Min</mat-label>
                  <input
                    class="!text-[1rem]"
                    type="number"
                    matInput
                    name="MinimumOptions"
                    formControlName="MinimumOptions"
                    placeholder="{{ 'QUEST_CREATION.QUEST_POP_CHECK_NUMBER_MIN' | translate }}"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline" class="ml-6 w-[8%] !text-xs">
                  <mat-label class="!text-[1rem]">Max</mat-label>
                  <input
                    class="!text-[1rem]"
                    type="number"
                    matInput
                    name="MaximumOptions"
                    formControlName="MaximumOptions"
                    placeholder="{{ 'QUEST_CREATION.QUEST_POP_CHECK_NUMBER_MAX' | translate }}"
                  />
                </mat-form-field>
                <div class="ml-6 w-1/4"></div>
              </div>
            </div>

            <div class="mb-6 flex w-full">
              <p
                class="w-3/4 font-semibold text-dialog"
                [ngClass]="{
                  'w-2/3': this.QuestionInfo.get('Question_Type').value === 'Yes or no'
                }"
              >
                {{ "QUEST_CREATION.QUEST_POP_CHECK_ANS" | translate }}
              </p>
              <p
                class="w-1/4 font-semibold text-dialog"
                [ngClass]="{
                  'w-1/3': this.QuestionInfo.get('Question_Type').value === 'Yes or no'
                }"
              >
                {{ "QUEST_CREATION.QUEST_POP_CHECK_SCORE" | translate }}
              </p>
            </div>

            <ng-container *ngIf="this.QuestionInfo.get('Question_Type').value !== 'Yes or no'">
              <div
                class="mb-6 flex w-full items-center"
                *ngFor="let alias of Check_List_Box_Options.controls; let i = index"
              >
                <mat-form-field
                  class="text-area-height mr-6 w-[calc(75%-(1.5rem))]"
                  appearance="outline"
                  formArrayName="Check_List_Box_Options"
                >
                  <textarea
                    tabindex="{{ i * 2 + 1 }}"
                    matInput
                    class="!text-[1rem]"
                    type="text"
                    [name]="i"
                    [formControlName]="i"
                    maxlength="500"
                    #ListBoxOption
                  ></textarea>
                </mat-form-field>

                <mat-form-field
                  appearance="outline"
                  class="mr-6 w-[calc(12.5%-(1.5rem/2))]"
                  formArrayName="Check_List_Box_Options_Score"
                >
                  <input
                    tabindex="{{ i * 2 + 2 }}"
                    matInput
                    [min]="-10000"
                    [max]="10000"
                    type="number"
                    [name]="i"
                    [formControlName]="i"
                  />
                </mat-form-field>

                <div class="w-[calc(12.5%-(1.5rem/2))]">
                  <button
                    type="button"
                    [disabled]="cantModify"
                    class="mr-6 h-full w-12 bg-blue-50 text-2xl"
                    [ngClass]="{ disabled: cantModify }"
                    (click)="addInput()"
                  >
                    +
                  </button>
                  <button
                    type="button"
                    [disabled]="cantModify || optionsNumber <= 1"
                    class="font-semibold text-red-500"
                    [ngClass]="{
                      'disabled text-gray-500': cantModify || optionsNumber <= 1
                    }"
                    (click)="deleteInput(alias, i)"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="this.QuestionInfo.get('Question_Type').value === 'Yes or no'">
              <div
                class="mb-6 flex w-full items-center justify-between"
                *ngFor="let alias of yesNo_Score.controls; let i = index"
              >
                <p class="font-semibold text-dialog">
                  {{ (i === 0 ? "Yes" : "No") | translate }}
                </p>
                <mat-form-field appearance="outline" class="w-1/3" formArrayName="yesNo_Score">
                  <input matInput [min]="-10000" [max]="10000" type="number" name="Score" [formControlName]="i" />
                </mat-form-field>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="this.QuestionInfo.get('Question_Type').value !== 'Yes or no'; else ConstTemplate">
          </ng-container>
        </div>

        <div
          *ngIf="
            this.QuestionInfo.get('Question_Type').value === 'Free text' &&
            this.QuestionInfo.get('Answer_Type').value === 'Text'
          "
          name="Verification"
          formGroupName="Verification"
          class="mb-6"
        >
          <mat-checkbox name="Verify_Input" formControlName="Verify_Input" (change)="changeVerificationInput($event)">
            <p>{{ "QUEST_CREATION.QUEST_POP_CHECK_TEXT" | translate }}</p>
          </mat-checkbox>
        </div>

        <div
          class="mb-6 flex w-full justify-between"
          *ngIf="['Free text', 'File'].includes(this.QuestionInfo.get('Question_Type').value)"
        >
          <div
            *ngIf="
              this.QuestionInfo.get('Question_Type').value === 'Free text' &&
              this.QuestionInfo.get('Answer_Type').value === 'Text' &&
              QuestionInfo.get('Verification.Verify_Input').value
            "
            formGroupName="Verification"
            class="flex w-[83%] justify-between"
          >
            <mat-form-field
              appearance="outline"
              class="w-full"
              [ngClass]="{
                'w-[49%]': QuestionInfo.get('Verification.Verify_Input_Options').value === 'customized'
              }"
            >
              <mat-label>{{ "QUEST_CREATION.QUEST_POP_CONTROL_TYPE" | translate }}</mat-label>
              <mat-select name="Verify_Input_Options" formControlName="Verify_Input_Options">
                <mat-option
                  class="text-black"
                  *ngFor="let verification of TypeOfVerificationOptions"
                  [value]="verification.value"
                >
                  {{ lang === "en" ? verification.viewValue : verification.viewValueIT }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="QuestionInfo.get('Verification.Verify_Input_Options').value === 'customized'"
              class="w-[49%]"
              floatLabel="never"
              appearance="outline"
            >
              <mat-label>{{ "QUEST_CREATION.QUEST_POP_CONTROL_REGEX" | translate }}</mat-label>
              <input type="text" name="Regular_Expression" matInput formControlName="Regular_Expression" />
              <button type="button" mat-icon-button matSuffix class="" (click)="getInstructions()">
                <mat-icon
                  matTooltip="{{ 'QUEST_CREATION.QUEST_POP_CONTROL_TOOL' | translate }}"
                  matTooltipClass="custom-tooltip"
                  >info
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" class="w-[15%]">
            <mat-label>Score</mat-label>
            <input
              matInput
              class="text-center"
              [min]="-10000"
              [max]="10000"
              type="number"
              name="Score"
              formControlName="Score"
            />
          </mat-form-field>
        </div>

        <ng-container *ngIf="this.QuestionInfo.get('Question_Type').value === 'Yes or no'; else ConstTemplate">
        </ng-container>
      </ng-container>
    </form>
  </div>
  <div class="flex flex-row items-center justify-between">
    <mat-error [ngClass]="{ invisible: data.modelStatus === 'Archived' }">
      {{ "QUEST_CREATION.QUEST_POP_MAND_FIELD" | translate }}
    </mat-error>
    <div class="flex items-center justify-end gap-4">
      <button class="whiteBtn" (click)="discard()">
        {{ data.modelStatus !== "Archived" ? ("ANNULLA" | translate) : ("BACK" | translate) }}
      </button>
      <button *ngIf="data.modelStatus !== 'Archived'" class="colorBtn" (click)="save()">
        {{ "CONFERMA" | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #ConstTemplate>
  <div [formGroup]="QuestionInfo" class="w-[fit-content]">
    <div formGroupName="Constraints" class="w-[fit-content]">
      <mat-checkbox
        name="Constrained"
        formControlName="Constrained"
        (change)="changeConstraintQuestion($event)"
        [ngClass]="{ disabled: constrained_disabled }"
      >
        <p>{{ "QUEST_CREATION.QUEST_POP_CONS" | translate }}</p>
      </mat-checkbox>

      <div *ngIf="this.QuestionInfo.get('Constraints.Constrained').value" class="mt-6 flex w-full">
        <mat-form-field appearance="outline" class="w-[10vw]">
          <mat-label>
            {{ "QUEST_CREATION.QUEST_POP_LINK" | translate }}
          </mat-label>
          <mat-select
            panelClass="custom-height-mat-select"
            name="Constrained_with"
            formControlName="Constrained_with"
            (selectionChange)="getParentQuestion($event)"
          >
            <ng-container *ngFor="let question of data.Topic.Questions">
              <mat-option class="text-black" *ngIf="question.id !== data.currentQuestion.id" [value]="question.id">
                {{ question.Question_Text }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="['List box', 'Check-list box', 'Yes or no'].includes((Constrained_with | async)?.TypeOfQuestion)"
          appearance="outline"
          class="ml-6 w-[10vw]"
        >
          <mat-label>
            {{ "QUEST_CREATION.QUEST_POP_LINK_ANS" | translate }}
          </mat-label>
          <mat-select
            panelClass="custom-height-mat-select"
            name="Constraint_options"
            formControlName="Constraint_options"
            multiple
          >
            <mat-option
              class="text-black"
              *ngFor="let response of (Constrained_with | async)?.Check_List_Box_Options"
              [value]="response"
            >
              {{ response }}
            </mat-option>
            <mat-option
              class="text-black"
              *ngFor="let response of (Constrained_with | async)?.yesNo_Score; let i = index"
              [value]="i === 0 ? 'Yes' : 'No'"
            >
              {{ i === 0 ? "Yes" : "No" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>
