import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ProjectActionTypes, saveAnalyticsFiltersSelections } from 'src/app/Store/projects/actions/projects.actions';
import {
  selectAnalyticsFiltersNames,
  selectAnalyticsFiltersSelections,
} from 'src/app/Store/projects/selectors/projects.selectors';

@Component({
  selector: 'analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss'],
})
export class AnalyticsFilter implements OnInit {
  @Input()
  projectID: string;
  panelOpenState = false;

  topicsQuestionsExpand = false;
  usersExpand = false;

  questionsCount = 0;
  usersCount = 0;

  allUsers = [];
  allTopics = [];
  filteredUsers = [];
  filteredTopics = [];
  allQuestionsNumber = 0;

  usersSelected = [];
  topicsSelected = [];
  questionsSelected = [];

  selectAllQuestionsChecked = {};
  allSelected = false;

  searchTermTopicsQuestions = '';
  searchTermUsers = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.panelOpenState = false;
    // get old selected Filters
    this.store
      .select(selectAnalyticsFiltersSelections)
      .pipe(take(1))
      .subscribe((oldSelectedFilters) => {
        this.usersSelected = oldSelectedFilters.users;
        this.topicsSelected = oldSelectedFilters.topics;
        this.questionsSelected = oldSelectedFilters.questions;
        this.usersCount = this.usersSelected.length;
        this.questionsCount = this.questionsSelected.length;
      });
    // get All the topics/questions names
    this.store
      .select(selectAnalyticsFiltersNames)
      .pipe(take(1))
      .subscribe((data) => {
        this.allUsers = data.users;
        this.allTopics = data.topics;
        this.allTopics.forEach((topic) => {
          this.selectAllQuestionsChecked[topic.PK_TOP] = false;
          this.allQuestionsNumber += topic.questions.length;
        });
        this.filteredUsers = data.users;
        this.filteredTopics = data.topics;
      });
  }

  selectAll(check) {
    this.allSelected = true;
    if (check) {
      this.allTopics.forEach((topic) => {
        this.topicsSelected = [...this.topicsSelected, topic.PK_TOP];
        topic.questions.forEach((question) => {
          this.questionClicked(true, question.PK_QUESTIONS, topic.PK_TOP, topic.questions);
        });
      });
    } else {
      this.allTopics.forEach((topic) => {
        this.selectAllQuestionsChecked[topic.PK_TOP] = false;
      });
      this.topicsSelected = [];
      this.questionsSelected = [];
      this.questionsCount = 0;
    }
  }

  userClicked(check, user) {
    if (check) {
      this.usersSelected = [...this.usersSelected, user];
      this.usersCount++;
    } else {
      this.usersSelected = this.usersSelected.filter((currentUser) => {
        return currentUser !== user;
      });
      this.usersCount--;
    }
  }

  topicClicked(check, topic, questions) {
    if (check) {
      // Add all questions
      this.selectAllQuestionsChecked[topic] = true;
      questions.forEach((question) => {
        if (!this.questionsSelected.includes(question.PK_QUESTIONS)) {
          this.questionClicked(true, question.PK_QUESTIONS, topic);
        }
      });
      let checkIfAllSelected = this.checkIfAllTopicsQuestionsSelected();
      if (checkIfAllSelected) {
        this.allSelected = true;
      }
    } else {
      // Remove all questions
      questions.forEach((questionToDelete) => {
        if (this.questionsSelected.includes(questionToDelete.PK_QUESTIONS)) {
          this.questionClicked(false, questionToDelete.PK_QUESTIONS, topic, questions);
        }
      });
      this.selectAllQuestionsChecked[topic] = false;
      this.allSelected = false;
    }
  }

  questionClicked(check, question, topic?, questions?) {
    if (check) {
      // Add question
      if (!this.questionsSelected.includes(question)) {
        this.questionsSelected = [...this.questionsSelected, question];
        this.questionsCount++;
        // if Topic doesn't exist add it
        if (!this.topicsSelected.includes(topic)) {
          this.topicsSelected = [...this.topicsSelected, topic];
        }
        // Check if all questions checked
        if (questions) {
          let questionNumber = 0;
          questions.forEach((question) => {
            if (this.questionsSelected.includes(question.PK_QUESTIONS)) {
              questionNumber++;
            }
          });
          if (questionNumber === questions.length) {
            this.selectAllQuestionsChecked[topic] = true;
            let checkIfAllSelected = this.checkIfAllTopicsQuestionsSelected();
            if (checkIfAllSelected) {
              this.allSelected = true;
            }
          }
        }
      }
    } else {
      // Remove question
      this.questionsSelected = this.questionsSelected.filter((currentQuestion) => {
        return currentQuestion !== question;
      });
      this.questionsCount--;
      // Check if all questions unchecked
      let questionNumber = 0;
      questions.forEach((question) => {
        if (this.questionsSelected.includes(question.PK_QUESTIONS)) {
          questionNumber++;
        }
      });
      if (questionNumber === 0) {
        this.topicsSelected = this.topicsSelected.filter((currentTopic) => {
          return currentTopic !== topic;
        });
      }
      this.selectAllQuestionsChecked[topic] = false;
      this.allSelected = false;
    }
  }

  filterAnalytics() {
    let dispatchObject = {
      type: ProjectActionTypes.getAnalyticsData,
      projectId: this.projectID,
    };

    if (this.usersSelected.length > 0 && this.usersSelected.length < this.allUsers.length) {
      dispatchObject['questionnairesAssignedIDs'] = this.usersSelected;
    }
    if (this.topicsSelected.length > 0 && this.topicsSelected.length < this.allTopics.length) {
      dispatchObject['topicsIDs'] = this.topicsSelected;
    }
    if (this.questionsSelected.length > 0 && this.questionsSelected.length < this.allQuestionsNumber) {
      dispatchObject['questionsIDs'] = this.questionsSelected;
    }
    this.store.dispatch(dispatchObject);

    // save Filters in store to get them when we open the Analytics Filter again
    this.store.dispatch(
      saveAnalyticsFiltersSelections({
        users: this.usersSelected,
        topics: this.topicsSelected,
        questions: this.questionsSelected,
      })
    );
  }

  checkIfAllTopicsQuestionsSelected() {
    let checkIfAllSelected = true;
    this.allTopics.forEach((topic) => {
      if (this.selectAllQuestionsChecked[topic.PK_TOP] === false) {
        checkIfAllSelected = false;
      }
    });
    return checkIfAllSelected;
  }

  resetFilters() {
    this.filteredTopics = this.allTopics;
    this.filteredUsers = this.allUsers;
    this.closeAllExpands();
    this.usersSelected = [];
    this.topicsSelected = [];
    this.questionsSelected = [];
    this.questionsCount = 0;
    this.usersCount = 0;
    // Get all analytics without any filter applied
    this.store.dispatch({
      type: ProjectActionTypes.getAnalyticsData,
      projectId: this.projectID,
    });
    // reset filters in store
    this.store.dispatch(saveAnalyticsFiltersSelections({ users: [], topics: [], questions: [] }));
  }

  closeAllExpands() {
    this.topicsQuestionsExpand = false;
    this.usersExpand = false;
    this.filteredTopics = this.allTopics;
    this.filteredUsers = this.allUsers;
    this.allSelected = false;
  }

  searchTopicsQuestions(term) {
    let topics = [];
    this.allQuestionsNumber = 0;
    this.allTopics.forEach(topic => {
      let questions = topic.questions.filter(question => {
        return question.Question_Text.toLowerCase().includes(term.toLowerCase())
      });
      this.allQuestionsNumber += questions.length;
      if(topic.Title.toLowerCase().includes(term.toLowerCase()) || questions.length > 0) {
        topics = [...topics, {
          PK_TOP: topic.PK_TOP,
          Title: topic.Title,
          questions: questions
        }]
      }

    })
    this.filteredTopics = topics;
  }

  searchUsers(term) {
    this.filteredUsers = this.allUsers.filter((user) => {
      let [name, surname] = user.fullName.split(' ');
      return name.toLowerCase().startsWith(term.toLowerCase()) || surname.toLowerCase().startsWith(term.toLowerCase());
    });
  }
}
