import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { WSService } from './data/api/WSServices/WSService';
import { UploadFileService } from './features/administrative/service/UploadFile.service';
import { ActionLoginTypes } from './Store/login-store/login.actions';
import { selectIsLoggiedIn } from './Store/login-store/login.selector';
import { getUsers } from './Store/users/users.actions';
import { AuthService } from './utils/auth/auth.service';
import { MagicStringsService } from './utils/magic-strings.service';
import { TranslateService } from '@ngx-translate/core';
import { selectLanguage } from './Store/users/users.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isUserLoggedIn: Observable<boolean>;
  wsSocket: Subscription;
  customazationLoaded = false;
  dictionaryLoaded = false;
  appSvgTheme = 0;

  constructor(
    public authService: AuthService,
    public router: Router,
    private store: Store,
    private wsService: WSService,
    private uploadFileService: UploadFileService,
    private magicStringsService: MagicStringsService,
    private titleService: Title,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (!!this.authService.getUserToken()) {
      this.store.dispatch({
        type: ActionLoginTypes.USERlOGGEDIN,
        username: this.authService.getUserName(),
      });
    }

    this.translate.setDefaultLang('it');

    this.uploadFileService
      .getDefault()
      .pipe(take(1))
      .subscribe((data) => {
        this.customazationLoaded = this.magicStringsService.initialize(data);
        this.magicStringsService.update = true;
        this.dictionaryLoaded = this.magicStringsService.refresh();

        this.store
          .select(selectLanguage)
          .pipe(take(1))
          .subscribe((lang) => {
            if (lang === 'it') {
              let appName = this.magicStringsService.AppName;
              this.titleService.setTitle(appName);
            } else {
              let appName = this.magicStringsService.AppNameENG;
              this.titleService.setTitle(appName);
            }
          });

        this.appSvgTheme = +this.magicStringsService.IdTheme;

        switch (this.appSvgTheme) {
          case 0:
            document.documentElement.style.setProperty('--body-background-color', '#e5ebef');
            document.documentElement.style.setProperty('--main-color', '#013571');
            document.documentElement.style.setProperty('--card-header-color', '#0459a9');
            document.documentElement.style.setProperty('--login-border-icon-color', '#e98600');
            document.documentElement.style.setProperty('--deadline-color', '#f7ce72');
            document.documentElement.style.setProperty('--dialog-color', '#0f79d3');
            document.documentElement.style.setProperty('--bg-light-color', '#B1C6CE');
            document.documentElement.style.setProperty('--constrast-color', '#125614');
            document.documentElement.style.setProperty('--shade-card-color', '#4f8bc3');
            document.documentElement.style.setProperty('--shade-login-color', '#f0aa4d');
            document.documentElement.style.setProperty('--welcome-login-color', '#013571');

            break;
          case 1:
            document.documentElement.style.setProperty('--body-background-color', '#fafafa');
            document.documentElement.style.setProperty('--main-color', '#4e4e4e');
            document.documentElement.style.setProperty('--card-header-color', '#6b6b6b');
            document.documentElement.style.setProperty('--login-border-icon-color', '#fe9166');
            document.documentElement.style.setProperty('--deadline-color', '#f7ce72');
            document.documentElement.style.setProperty('--dialog-color', '#f4946d');
            document.documentElement.style.setProperty('--bg-light-color', '#CCC3C4');
            document.documentElement.style.setProperty('--constrast-color', '#D9929A');
            document.documentElement.style.setProperty('--shade-card-color', '#979797');
            document.documentElement.style.setProperty('--shade-login-color', '#f0aa4d');
            document.documentElement.style.setProperty('--welcome-login-color', '#6B7C8E');

            break;
          case 2:
            document.documentElement.style.setProperty('--body-background-color', '#f9fffa');
            document.documentElement.style.setProperty('--main-color', '#000000');
            document.documentElement.style.setProperty('--card-header-color', '#43b02a');
            document.documentElement.style.setProperty('--login-border-icon-color', '#046a38');
            document.documentElement.style.setProperty('--deadline-color', '#ffffff');
            document.documentElement.style.setProperty('--dialog-color', '#86bc25');
            document.documentElement.style.setProperty('--bg-light-color', '#E8F7DE');
            document.documentElement.style.setProperty('--constrast-color', '#00A3E0');
            document.documentElement.style.setProperty('--shade-card-color', '#7bc86a');
            document.documentElement.style.setProperty('--shade-login-color', '#feb294');
            document.documentElement.style.setProperty('--welcome-login-color', '#000');

            break;
          default:
            break;
        }
      });

    this.isUserLoggedIn = this.store.select(selectIsLoggiedIn).pipe(
      map((value) => {
        if (value) {
          this.store.dispatch(getUsers());
          this.wsService.connect(this.authService.getUserToken());
          this.wsSocket = this.wsService.returnWSSUbject();
        }
        return value;
      })
    );
  }

  ngOnDestroy(): void {
    this.wsSocket.unsubscribe();
    this.wsService.disconnectFromWS();
  }

  @HostListener('window:beforeunload', ['$event'])
  clearLocalStorage(event) {
    localStorage.clear();
  }
}
