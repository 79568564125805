import { FormGroup, FormControl } from '@angular/forms';
import { findIndexAtArray } from 'src/app/data/data-handlers/utils.service';
import { Component, Inject, OnInit } from '@angular/core';
import { displayedUserColumns } from 'src/app/utils/config/questionnaire-table.configuration';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { User } from 'src/app/data/models/User';
import { QuestionnairesAssigned } from 'src/app/data/models/QuestionnairesAssigned';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit {
  formGroup = new FormGroup({
    Project: new FormControl(''),
    Collaborative: new FormControl(false),
    Expiry_Date: new FormControl(false),
  });

  users: User[] = [];

  displayedUserColumns = displayedUserColumns;

  show = false;

  dataSource: User[];

  forceChanges = {
    Expiry_Date: null,
    Assigned_to: null,
  };

  constructor(
    public editProject: MatDialogRef<EditProjectComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      users: User[];
      activeQuestionnaire: QuestionnairesAssigned;
      singleSelection: boolean;
      dialogService: GeneralDialogService;
    },
    public utils: NotifyService
  ) {}

  ngOnInit() {
    this.dataSource = this.data.users;
    this.formGroup.patchValue({
      Project: this.data.activeQuestionnaire.Project,
      Collaborative: this.data.activeQuestionnaire.Collaborative,
      Expiry_Date: this.data.activeQuestionnaire.Expiry_Date,
    });
  }

  changeCollaborativeField(event): void {}

  addUser(element: User) {
    if (!this.users.includes(element)) {
      this.users.push(element);
    } else {
      this.users.splice(findIndexAtArray(this.users, element), 1);
    }
  }

  getTheDate(element: string) {
    this.formGroup.get('Expiry_Date').patchValue(element);
  }

  toggle() {
    this.show = !this.show;
  }

  showProjectInfo(element: string) {
    this.data.dialogService.openInfoDialog({
      Info: element,
      disableClose: false,
    });
    this.data.dialogService.infoDialogResponse();
  }

  save() {
    if (this.formGroup.valid && this.users.length > 0) {
      this.editProject.close({
        Users: this.users,
        Expiry_Date: this.formGroup.get('Expiry_Date').value,
        Project: this.formGroup.get('Project').value,
        Collaborative: this.formGroup.get('Collaborative').value,
      });
    } else {
      this.utils.openWarningSwal('Please assign at least one user');
    }
  }

  cancel() {
    this.editProject.close('Cancel');
  }
}
