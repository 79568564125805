import { createReducer, on } from '@ngrx/store';
import { Docufile } from 'src/app/data/models/Docufile';
import * as fromFileUploadActions from './upload-file.actions';
import { initialState, Status } from './upload-file.state';

export const fileUploadReducer = createReducer(
  initialState,

  on(fromFileUploadActions.UploadRequestAction, (state, {}) => ({
    ...state,
    status: Status.Requested,
    progress: null,
    error: null,
    filesUploading: true,
  })),

  on(fromFileUploadActions.UploadCancelAction, (state, {}) => ({
    ...state,
    status: Status.Ready,
    progress: null,
    error: null,
  })),

  on(fromFileUploadActions.UploadResetAction, (state, {}) => ({
    ...state,
    status: Status.Ready,
    progress: null,
    error: null,
  })),

  on(fromFileUploadActions.UploadErrorAction, (state, { error }) => ({
    ...state,
    status: Status.Failed,
    progress: null,
    error: error,
  })),

  on(fromFileUploadActions.UploadProgressAction, (state, { progress }) => ({
    ...state,
    progress: progress,
  })),

  on(fromFileUploadActions.UploadWaitingAction, (state, {}) => ({
    ...state,
    status: Status.Uploading,
  })),

  on(fromFileUploadActions.UploadCompletedAction, (state, {}) => ({
    ...state,
    status: Status.Completed,
    progress: 100,
    filesUploading: false,
  })),

  on(fromFileUploadActions.DownloadFileAction, (state, {}) => ({
    ...state,
    download: Status.Requested,
  })),

  on(fromFileUploadActions.DownloadFileSuccess, (state, {}) => ({
    ...state,
    download: Status.Completed,
  })),

  on(fromFileUploadActions.GetFilesAction, (state, {}) => ({
    ...state,
    getFiles: {
      getStatus: Status.Requested,
      files: null,
      filesLoading: true,
    },
  })),

  on(fromFileUploadActions.GetFilesSuccess, (state, { fileList }) => ({
    ...state,
    getFiles: {
      getStatus: Status.Completed,
      files: [...fileList],
      filesLoading: false,
    },
  })),

  on(fromFileUploadActions.DeleteFileAction, (state, {}) => ({
    ...state,
    deleteFile: Status.Requested,
  })),

  on(fromFileUploadActions.DeleteFileSuccess, (state, { deletedFileId }) => ({
    ...state,
    getFiles: {
      getStatus: Status.Ready,
      files: state.getFiles.files.filter((file: Docufile) => file.id !== deletedFileId),
      filesLoading: false,
    },
    deleteFile: Status.Completed,
  })),

  on(fromFileUploadActions.DownloadZipAction, (state, {}) => ({
    ...state,
    download: Status.Requested,
  })),

  on(fromFileUploadActions.DownloadZipSuccess, (state, {}) => ({
    ...state,
    download: Status.Completed,
  }))
);
