import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { QuestionnaireActionTypes } from 'src/app/Store/assignedQuestionnaires/actions/assigned-questionnaires.actions';
import {
  selectloadingNotes,
  selectNotes,
} from 'src/app/Store/assignedQuestionnaires/selectors/assigned-questionnaires.selectors';
import { selectUsersFullname } from 'src/app/Store/users/users.selectors';
import { NotifyService } from 'src/app/utils/notification.service';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
  usernameOfTheSession: string;

  usersFullName: Observable<{}>;

  notesFormControl = new FormControl('');

  notes: Observable<string[]>;

  loadingNotes: Observable<boolean>;

  lang: string;
  langToUnsubscribe: Subscription;

  notesPlaceholder: string;

  constructor(
    public notesModalDialog: MatDialogRef<NotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store,
    private notifyService: NotifyService,
    private cdr: ChangeDetectorRef,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.usernameOfTheSession = this.authService.getUserName();
    this.usersFullName = this.store.select(selectUsersFullname);
    const data = {
      Assigned_id: this.data.Quest_Assing_ID,
      Topic_Assigned_id: '',
      pageIndex: 0,
      pageSize: 999,
      Status: this.data.Status,
      user: this.data.user,
    };

    this.store.dispatch({
      type: QuestionnaireActionTypes.getQuestionnaireNotes,
      data,
    });
    this.notes = this.store.select(selectNotes);
    this.loadingNotes = this.store.select(selectloadingNotes);

    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
    });
    this.notesPlaceholder = this.lang === 'it' ? 'Inserisci una nuova nota' : 'Please insert a new note';
  }

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
  }

  sendNote() {
    const notes = {
      Project_Title: this.data.Project_Title,
      Assigned_id: this.data.Quest_Assing_ID,
      Topic_Assigned_id: '',
      Note: this.notesFormControl.value,
      pageIndex: this.data.pageIndex,
    };

    if (this.notesFormControl.value !== '') {
      this.store.dispatch({
        type: QuestionnaireActionTypes.SubmitNotes,
        notes,
      });
      this.store.dispatch({
        type: QuestionnaireActionTypes.ReadNotes,
        quest_id: this.data.Quest_Assing_ID,
      });
      this.notesModalDialog.close();
    } else {
      this.notifyService.openWarningSwal(
        this.lang === 'it'
          ? 'Se vuole inserire note, deve scrivere qualcosa'
          : 'If you want to insert notes, you have to write something'
      );
    }
  }

  closeNotesDialog() {
    this.notesModalDialog.close();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}
