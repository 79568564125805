import { createReducer, on } from '@ngrx/store';
import { loadQuestionnairesHomepage, loadQuestionnairesHomepageSuccess } from './home-page.actions';
import { initialHomePageState } from './home-page.state';

const _homePageReducer = createReducer(
  initialHomePageState,
  on(loadQuestionnairesHomepage, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadQuestionnairesHomepageSuccess, (state, { total, pageIndex }) => ({
    ...state,
    total,
    pageIndex,
    loading: false,
  }))
);

export function homePageReducer(state, action) {
  return _homePageReducer(state, action);
}
