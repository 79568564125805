<h2 class="text-3xl text-dialog tracking-normal font-black font-poppins flex items-center justify-between mb-12">
  {{ "HOME_POP.TITLE" | translate }}
</h2>
<ng-container [ngSwitch]="data">
  <ng-container *ngSwitchCase="'notResponsibleAndUnfinished'">
    <div class="inline-flex items-center">
      <mat-icon class="!text-dialog mr-4">error</mat-icon>
      <div class="text-xl">
        {{ "HOME_POP.NOT_RESP_UNFIN1" | translate }}
        {{ "di " + ("DICTIONARY.Topic" | translate) | lowercase | prepositionDictionary: "plural":language }}
        {{ "HOME_POP.NOT_RESP_UNFIN2" | translate }}
        {{ "completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language
        }}{{ "HOME_POP.NOT_RESP_UNFIN3" | translate }}
      </div>
    </div>
    <div class="p-[5px] min-w-[fit-content] mt-6 rounded-[10px] border-solid border-2 border-[#bce0fd] input-note">
      <input type="text" matInput [formControl]="noteInput" required />
    </div>
    <div class="flex flex-row justify-between items-center mt-12 min-w-[50vw]">
      <mat-error>
        {{ "HOME_POP.MANDATORY_FIELD" | translate }}
      </mat-error>
      <div class="flex justify-end items-center pl-4">
        <button class="whiteBtn h-24 lg:h-20 mr-3" (click)="closeDialog('cancel')">
          {{ "HOME_POP.BUTTON_HOME" | translate }}
        </button>
        <button class="colorBtn h-24 lg:h-20 mr-3" (click)="closeDialog('goToUnfinishedTopic')">
          {{ "HOME_POP.NOT_RESP_UNFIN_BUTTON11" | translate }}
          {{
            "a " + ("primo " + ("DICTIONARY.SingleTopic" | translate) | verbPronounDictionary: "singular":language)
              | prepositionDictionary: "singular":language
              | uppercase
          }}
          <br />
          {{ "DICTIONARY.SingleTopic" | translate | uppercase }}
          {{ "HOME_POP.NOT_RESP_UNFIN_BUTTON12" | translate }}
        </button>
        <button
          class="colorBtn h-24 lg:h-20 mr-3"
          [ngClass]="{
            disabled: noteInput.value === null || noteInput.value === ''
          }"
          (click)="closeDialog('CompleteFromNotResponsible')"
        >
          {{ "HOME_POP.NOT_RESP_UNFIN_BUTTON21" | translate }}
          {{ "a " + ("DICTIONARY.Responsabile" | translate) | prepositionDictionary: "singular":language | uppercase }}
          <br />
          {{ "HOME_POP.NOT_RESP_UNFIN_BUTTON22" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <!-- POP-UP when a non-responsible user finishes all the topics-->
  <ng-container *ngSwitchCase="'notResponsibleAndfinished'">
    <div class="inline-flex items-center">
      <mat-icon class="!text-dialog mr-4">error</mat-icon>
      <div class="text-xl">
        {{ "HOME_POP.NOT_RESP_FIN1" | translate }}
        {{ "tutto " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language }}
        {{ "DICTIONARY.Topic" | translate | articleDictionary: "plural":language | lowercase }}
        {{ "HOME_POP.NOT_RESP_FIN2" | translate }}
        {{ "assegnato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language
        }}{{ "HOME_POP.NOT_RESP_FIN3" | translate }}
      </div>
    </div>
    <div class="p-[5px] min-w-[fit-content] mt-6 rounded-[10px] border-solid border-2 border-[#bce0fd] input-note">
      <input type="text" matInput [formControl]="noteInput" value="" />
    </div>
    <div class="flex justify-end items-center mt-12 min-w-[50vw]">
      <button class="whiteBtn mr-3" (click)="closeDialog('cancel')">
        {{ "HOME_POP.BUTTON_HOME" | translate }}
      </button>
      <button
        *ngIf="currentPath !== '/home'"
        class="whiteBtn mr-3"
        (click)="closeDialog('goToNotResponsibleNextTopic')"
      >
        {{ "HOME_POP.NOT_RESP_FIN_BUTTON1" | translate }}
        {{ "a " + ("DICTIONARY.Topic" | translate) | prepositionDictionary: "plural":language | uppercase }}
      </button>
      <button class="colorBtn" (click)="closeDialog('CompleteFromNotResponsible')">
        {{ "HOME_POP.NOT_RESP_FIN_BUTTON2" | translate }}
        {{ "a " + ("DICTIONARY.Responsabile" | translate) | prepositionDictionary: "singular":language | uppercase }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'notResponsibleAndfinishedWithOneTopic'">
    <div class="inline-flex items-center">
      <mat-icon class="!text-dialog mr-4">error</mat-icon>
      <div class="text-xl">
        {{ "HOME_POP.NOT_RESP_FIN1" | translate }}
        {{ "tutto " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language }}
        {{ "DICTIONARY.Topic" | translate | articleDictionary: "plural":language | lowercase }}
        {{ "HOME_POP.NOT_RESP_FIN2" | translate }}
        {{ "assegnato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language
        }}{{ "HOME_POP.NOT_RESP_FIN3" | translate }}
      </div>
    </div>
    <div class="p-[5px] min-w-[fit-content] mt-6 rounded-[10px] border-solid border-2 border-[#bce0fd] input-note">
      <input type="text" matInput [formControl]="noteInput" value="" />
    </div>
    <div class="flex justify-end items-center mt-12 min-w-[50vw]">
      <button class="whiteBtn mr-3" (click)="closeDialog('cancel')">
        {{ "HOME_POP.BUTTON_HOME" | translate }}
      </button>
      <button
        *ngIf="currentPath !== '/home'"
        class="whiteBtn mr-3"
        (click)="closeDialog('goToNotResponsibleNextTopic')"
      >
        {{ "HOME_POP.NOT_RESP_FIN_BUTTON1" | translate }}
        {{ "a " + ("DICTIONARY.Topic" | translate) | prepositionDictionary: "plural":language | uppercase }}
      </button>
      <button class="colorBtn" (click)="closeDialog('CompleteFromNotResponsible')">
        {{ "HOME_POP.NOT_RESP_FIN_BUTTON2" | translate }}
        {{ "a " + ("DICTIONARY.Responsabile" | translate) | prepositionDictionary: "singular":language | uppercase }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'ResponsibleAndUnfinished'">
    <div class="inline-flex items-center">
      <mat-icon class="!text-dialog mr-4">error</mat-icon>
      <div *ngIf="language === 'it'" class="text-xl">
        {{ "DICTIONARY.Questionario" | translate | articleDictionary: "singular":language | titlecase }}
        presenta ancora
        {{ "di " + ("DICTIONARY.Topic" | translate) | prepositionDictionary: "plural":language | lowercase }}
        non
        {{ "completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language }}
        o attualmente
        {{ "assegnato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language }}
        ad altri utenti, cosa vuoi fare?
      </div>
      <div *ngIf="language === 'en'" class="text-xl">
        There are still some
        {{ "DICTIONARY.Topic" | translate | lowercase }} uncompleted or assigned to other users, what do you prefer to
        do?
      </div>
    </div>

    <div class="flex justify-end items-center mt-12 min-w-[50vw]">
      <button class="whiteBtn h-24 lg:h-20 mr-3" (click)="closeDialog('cancel')">
        {{ "HOME_POP.BUTTON_HOME" | translate }}
      </button>
      <button class="colorBtn h-24 lg:h-20" (click)="closeDialog('goToUnfinishedTopic')">
        {{ "HOME_POP.NOT_RESP_UNFIN_BUTTON11" | translate }}
        {{
          "a " + ("primo " + ("DICTIONARY.SingleTopic" | translate) | verbPronounDictionary: "singular":language)
            | prepositionDictionary: "singular":language
            | uppercase
        }}
        <br />
        {{ "DICTIONARY.SingleTopic" | translate | uppercase }}
        {{ "HOME_POP.NOT_RESP_UNFIN_BUTTON12" | translate }}
      </button>
    </div>
  </ng-container>
  <!--Pop up for user responsible when all topics are finished-->
  <ng-container *ngSwitchCase="'ResponsibleAndFinished'">
    <div class="inline-flex items-center">
      <mat-icon class="!text-dialog mr-4">error</mat-icon>
      <div class="text-xl">
        {{ "HOME_POP.RESP_FIN1" | translate
        }}{{ "Tutto " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language }}
        {{ "DICTIONARY.Topic" | translate | lowercase | articleDictionary: "plural":language }}
        {{ "HOME_POP.RESP_FIN2" | translate }}
        {{ "completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":language
        }}{{ "HOME_POP.RESP_FIN3" | translate }}
      </div>
    </div>
    <div class="flex justify-end items-center mt-12 min-w-[50vw]">
      <button class="whiteBtn mr-3" (click)="closeDialog('cancel')">
        {{ "HOME_POP.BUTTON_HOME" | translate }}
      </button>
      <button *ngIf="currentPath !== '/home'" class="whiteBtn mr-3" (click)="closeDialog('goToResponsibleNextTopic')">
        {{ "HOME_POP.RESP_FIN_BUTTON1" | translate }}
        {{ "a " + ("DICTIONARY.Topic" | translate) | prepositionDictionary: "plural":language | uppercase }}
      </button>
      <button class="colorBtn" (click)="closeDialog('Submit')">
        {{ "HOME_POP.RESP_FIN_BUTTON2" | translate }}
      </button>
    </div>
  </ng-container>
</ng-container>
