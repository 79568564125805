import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  AllNotificationsLoaded,
  newNotification,
  readNotifications,
  snackBarMarkAsRead,
} from '../notifications-store/actions/notifications.actions';
import { selectNotifications } from '../notifications-store/selectors/notifications.selectors';
import { NotificationImpl } from 'src/app/data/models/NotificationImpl';

@Injectable({
  providedIn: 'root',
})
export class NotificationsStoreService {
  constructor(private store: Store) {}

  loadInitialNotifications(notifications: NotificationImpl[]) {
    this.store.dispatch(AllNotificationsLoaded({ notifications }));
  }

  newNotificationIncoming(incomingNotification: NotificationImpl) {
    this.store.dispatch(newNotification({ incomingNotification }));
  }

  selectNotes(): Observable<{
    length: number;
    notifications: NotificationImpl[];
  }> {
    return this.store.select(selectNotifications);
  }

  readNotes(notifications: NotificationImpl[]) {
    this.store.dispatch(readNotifications({ notifications }));
  }

  snackBarMarkAsRead(notification: NotificationImpl) {
    this.store.dispatch(snackBarMarkAsRead({ notification }));
  }
}
