import { createReducer, on } from '@ngrx/store';
import { updateTopicsUser } from '../../topics-store/topic.actions';
import * as ProjectActionTypes from '../actions/projects.actions';
import {
  changeResponsible,
  changeResponsibleSuccess,
  cloneProject,
  cloneProjectSuccess,
  closeProjectAction,
  closeProjectSuccessAction,
  errorHandled,
  getAllProjects,
  getAllProjectsSuccess,
  getSingleProject,
  getSingleProjectSuccess,
  publishProjectAction,
  publishProjectNoOrMoreUsers,
  loadProjectsFailure,
  publishProjectNoOrMoreUsersSuccess,
  publishProjectDirectlySuccess,
  publishProjectSuccessAction,
  updateActiveProject,
  updateActiveProjectSuccess,
  updateDraftProjectAction,
  updateDraftProjectSuccess,
} from '../actions/projects.actions';
import { initialProjectState } from '../project-state';

export const assignedQuestionnairesFeatureKey = 'assignedQuestionnaires';

export const projectsReducer = createReducer(
  initialProjectState,
  on(loadProjectsFailure, (state) => ({
    ...state,
    projectLoading: false,
    loadingClone: false,
    error: true,
  })),

  on(ProjectActionTypes.setActiveProject, (state, { activeProject }) => ({
    ...state,
    activeProject: activeProject,
  })),
  on(ProjectActionTypes.setModelFilter, (state, { modelFilter }) => ({
    ...state,
    modelFilter: modelFilter,
  })),
  on(
    ProjectActionTypes.saveProjectFilters,
    (
      state,
      {
        saved,
        modelFilter,
        creatorFilter,
        searchTerm,
        expireFromFilter,
        expireToFilter,
        creationFromFilter,
        creationToFilter,
        status,
      }
    ) => ({
      ...state,
      filterOptions: {
        saved: saved,
        modelFilter: modelFilter,
        creatorFilter: creatorFilter,
        searchTerm: searchTerm,
        expireFromFilter: expireFromFilter,
        expireToFilter: expireToFilter,
        creationFromFilter: creationFromFilter,
        creationToFilter: creationToFilter,
        status: {
          draft: status.draft,
          active: status.active,
          closed: status.closed,
          archived: status.archived,
        },
      },
    })
  ),
  on(ProjectActionTypes.setModelClickedFromProjects, (state, { modelFilter }) => ({
    ...state,
    modelClickedFromProjects: modelFilter,
  })),
  on(errorHandled, (state) => ({
    ...state,
    error: false,
  })),

  on(getAllProjects, (state, { deleteMode }) => {
    if (deleteMode) {
      return {
        ...state,
        loadingProjects: true,
        loadingDeleteMode: true,
      };
    } else {
      return {
        ...state,
        loadingProjects: true,
      };
    }
  }),
  on(getAllProjectsSuccess, (state, { Projects, total, pageIndex, deleteMode }) => {
    let activeStatus = false;
    let loadingStatus = true;

    if (deleteMode) {
      loadingStatus = false;
      Projects.forEach((project) => {
        if (project.PROJECT_STATUS === 'Active') {
          activeStatus = true;
        }
      });
      if (activeStatus === false) {
        return {
          ...state,
          deleteStatus: activeStatus,
          loadingDeleteMode: loadingStatus,
        };
      } else {
        return {
          ...state,
          Projects: Projects,
          total: total,
          pageIndex: pageIndex,
          projectToBeModified: null,
          loadingProjects: false,
          deleteStatus: activeStatus,
          loadingDeleteMode: loadingStatus,
        };
      }
    } else {
      return {
        ...state,
        Projects: Projects,
        total: total,
        pageIndex: pageIndex,
        projectToBeModified: null,
        loadingProjects: false,
      };
    }
  }),
  on(ProjectActionTypes.createProjectAction, (state, payload) => ({
    ...state,
    projectLoading: true,
  })),
  on(ProjectActionTypes.createProjectFailAction, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(ProjectActionTypes.createProjectSuccessAction, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(getSingleProject, (state, payload) => ({
    ...state,
    loadingSingleProject: true,
  })),
  on(getSingleProjectSuccess, (state, payload) => ({
    ...state,
    projectToBeModified: payload.project,
    loadingSingleProject: false,
  })),
  on(ProjectActionTypes.getAnalyticsData, (state, payload) => ({
    ...state,
    loadingAnalyticsData: true,
  })),
  on(ProjectActionTypes.getAnalyticsDataSuccess, (state, payload) => ({
    ...state,
    analyticsData: payload.analyticsData,
    loadingAnalyticsData: false,
  })),
  on(ProjectActionTypes.resetAnalyticsData, (state, payload) => ({
    ...state,
    analyticsData: null,
  })),
  on(ProjectActionTypes.saveAnalyticsFiltersNames, (state, payload) => ({
    ...state,
    analyticsFilterNames: payload.filtersNames,
  })),
  on(ProjectActionTypes.saveAnalyticsFiltersSelections, (state, payload) => ({
    ...state,
    analyticsFilterSelections: {
      users: payload.users,
      topics: payload.topics,
      questions: payload.questions,
    },
  })),
  on(closeProjectSuccessAction, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(cloneProject, (state, payload) => ({
    ...state,
    loadingClone: true,
  })),
  on(cloneProjectSuccess, (state, payload) => ({
    ...state,
    loadingClone: false,
  })),
  on(closeProjectAction, (state, payload) => ({
    ...state,
    projectLoading: true,
  })),
  on(publishProjectSuccessAction, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(publishProjectAction, (state, payload) => ({
    ...state,
    projectLoading: true,
  })),
  on(publishProjectNoOrMoreUsersSuccess, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(publishProjectDirectlySuccess, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(publishProjectNoOrMoreUsers, (state, payload) => ({
    ...state,
    projectLoading: true,
  })),
  on(updateDraftProjectAction, (state, payload) => ({
    ...state,
    projectLoading: true,
  })),
  on(updateDraftProjectSuccess, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(updateActiveProject, (state, payload) => ({
    ...state,
    projectLoading: true,
  })),
  on(updateActiveProjectSuccess, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(changeResponsible, (state, payload) => ({
    ...state,
    projectLoading: true,
  })),
  on(changeResponsibleSuccess, (state, payload) => ({
    ...state,
    projectLoading: false,
  })),
  on(updateTopicsUser, (state) => ({
    ...state,
    loadingAssigned: true,
  })),
  on(ProjectActionTypes.changeOwnerSuccess, (state, payload) => {
    return {
      ...state,
      Projects: state.Projects.map((project) => ({ ...project })).map((project) => {
        if (project.PK_PROJECT === payload.data['body']['projectId']) {
          return { ...project, Creator: payload.data['body']['newUser'] };
        }
        return project;
      }),
    };
  }),
  on(ProjectActionTypes.storeCheckedProjects, (state, { projectArray }) => ({
    ...state,
    checkedProjects: projectArray,
  }))
);
