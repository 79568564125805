<div class="flex filter">
  <ng-container *ngIf="panelOpenState">
    <div class="relative flex-col">
      <mat-expansion-panel
        id="topicsQuestions-filter"
        [expanded]="topicsQuestionsExpand"
        (opened)="closeAllExpands(); topicsQuestionsExpand = true"
        (closed)="topicsQuestionsExpand = false"
        class="z-10 !h-[fit-content] !rounded-none"
        [ngClass]="{ '!shadow-none': topicsQuestionsExpand }"
      >
        <mat-expansion-panel-header expandedHeight="3rem" collapsedHeight="3rem" class="w-24 text-base">
          <span class="mr-2 text-main"
            >{{ "DICTIONARY.Topic" | translate }} {{ "AND" | translate }}
            {{ "ANALYTICS_FILTER.QUESTIONS" | translate }}</span
          >
          <span
            matBadgeSize="small"
            matBadgeOverlap="false"
            matBadge="{{ questionsCount }}"
            class="filter-badge"
          ></span>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <div
        *ngIf="topicsQuestionsExpand"
        class="customExpansions absolute flex !w-[fit-content] max-w-[40rem] flex-col items-start bg-white"
      >
        <div class="flex w-full items-center justify-between p-2">
          <mat-checkbox
            (change)="selectAll($event.checked)"
            [checked]="allSelected"
            class="text-base font-semibold text-main"
          >
            {{ "ANALYTICS_FILTER.SELECT_ALL" | translate }}
          </mat-checkbox>
          <mat-form-field class="search-input-rounded w-3/5 rounded-full bg-white !text-[0.4rem]" appearance="outline">
            <mat-icon class="searchIcon relative" matPrefix>search</mat-icon>
            <input
              class="!text-[1rem]"
              type="search"
              matInput
              placeholder="Search"
              [ngModel]="searchTermTopicsQuestions"
              (ngModelChange)="searchTopicsQuestions($event)"
            />
          </mat-form-field>
        </div>
        <div class="flex max-h-[15rem] w-full flex-col overflow-y-auto px-2 pb-4">
          <ng-container *ngFor="let topic of filteredTopics; let topicIndex = index">
            <div class="mt-2 flex w-full items-center border-t border-b border-main py-2 pl-4">
              <mat-checkbox
                class="text-base"
                [checked]="selectAllQuestionsChecked[topic.PK_TOP] === true"
                (change)="topicClicked($event.checked, topic.PK_TOP, topic.questions)"
              >
              </mat-checkbox>
              <span
                class="ml-2 truncate text-base font-semibold text-main"
                appShowIfTruncated
                [matTooltip]="topic.Title"
                matTooltipClass="custom-tooltip"
                >{{ "ANALYTICS_FILTER.ALL_CHOICES" | translate }} {{ topic.Title }}
              </span>
            </div>
            <ng-container *ngFor="let question of topic.questions; let questionIndex = index">
              <div class="mt-2 flex w-full items-center">
                <mat-checkbox
                  class="text-base"
                  [checked]="questionsSelected.includes(question.PK_QUESTIONS)"
                  (change)="questionClicked($event.checked, question.PK_QUESTIONS, topic.PK_TOP, topic.questions)"
                >
                </mat-checkbox>
                <span
                  appShowIfTruncated
                  class="ml-2 truncate text-base text-main"
                  [matTooltip]="question.Question_Text"
                  matTooltipClass="custom-tooltip"
                  >{{ question.Question_Text }}
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="relative flex-col">
      <mat-expansion-panel
        id="users-filter"
        [expanded]="usersExpand"
        (opened)="closeAllExpands(); usersExpand = true"
        (closed)="usersExpand = false"
        class="z-10 !ml-4 !h-[fit-content] !w-[12rem] !rounded-none"
        [ngClass]="{ '!shadow-none': usersExpand }"
      >
        <mat-expansion-panel-header expandedHeight="3rem" collapsedHeight="3rem" class="text-base">
          <span class="mr-2 text-main">{{ "DICTIONARY.Responsabile" | translate }}</span>
          <span matBadgeSize="small" matBadgeOverlap="false" matBadge="{{ usersCount }}" class="filter-badge"></span>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <div
        *ngIf="usersExpand"
        class="customExpansions max-w-[25rem] absolute ml-4 flex w-[fit-content] flex-col items-start bg-white"
      >
        <div class="p-2">
          <mat-form-field class="search-input-rounded w-full rounded-full bg-white !text-[0.4rem]" appearance="outline">
            <mat-icon class="searchIcon relative" matPrefix>search</mat-icon>
            <input
              class="!text-[1rem]"
              type="search"
              matInput
              placeholder="Search"
              [ngModel]="searchTermUsers"
              (ngModelChange)="searchUsers($event)"
            />
          </mat-form-field>
        </div>

        <div class="max-h-[15rem] w-full overflow-y-auto px-2 pb-4 flex flex-col">
          <div class="flex items-center mt-2" *ngFor="let user of filteredUsers; let UserIndex = index">
            <mat-checkbox
              [checked]="usersSelected.includes(user.questionnaireAssignedID)"
              (change)="userClicked($event.checked, user.questionnaireAssignedID)"
              class="text-base"
            >
            </mat-checkbox>
            <span
              class="ml-2 truncate text-base font-semibold text-main"
              appShowIfTruncated
              [matTooltip]="user.fullName"
              matTooltipClass="custom-tooltip"
              >{{ user.fullName }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <button class="ml-4 flex h-12 w-24 items-center justify-center !rounded-[5px] colorBtn" (click)="filterAnalytics()">
      <span>{{ "ANALYTICS_FILTER.APPLY" | translate }}</span>
    </button>

    <button class="mx-4 flex h-12 w-24 items-center justify-center !rounded-[5px] whiteBtn" (click)="resetFilters()">
      <span>{{ "ANALYTICS_FILTER.CLEAR_ALL" | translate }}</span>
    </button>
  </ng-container>

  <button
    class="flex h-12 w-32 items-center justify-center !rounded-[5px] !py-1 !px-4 whiteBtn"
    (click)="panelOpenState = !panelOpenState"
  >
    <mat-icon class="relative mr-2">
      {{ !panelOpenState ? "filter_list" : "filter_list_off" }}
    </mat-icon>
    <span>{{
      !panelOpenState ? ("ANALYTICS_PANEL.FILTERS" | translate) : ("ANALYTICS_PANEL.NO_FILTERS" | translate)
    }}</span>
  </button>
</div>
