<mat-form-field class="!text-[0.9rem]" appearance="outline">
  <mat-label class="!text-[1rem]">
    {{ "PROJECT_OPTIONS.EXPIRATION_DATE" | translate }}
  </mat-label>
  <input
    class="!!text-[1rem]"
    matInput
    [min]="todayDate"
    [max]="maxDate"
    [matDatepicker]="picker"
    [formControl]="Expiry_Date"
    (ngModelChange)="sendDate($event)"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
