<div class="rounded-lg flex flex-col gap-8 overflow-hidden">
  <h2
    id="popup-title"
    class="text-dialog text-3xl font-medium font-poppins tracking-normal flex justify-between items-center"
  >
    {{ "NOTES.TITLE" | translate | titlecase }}
    <span>
      <button mat-icon-button (click)="closeNotesDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </h2>

  <ng-container *ngIf="loadingNotes | async">
    <app-spinner [width]="70" [height]="70"></app-spinner>
  </ng-container>

  <ng-container *ngIf="!(loadingNotes | async)">
    <ng-container *ngIf="(notes | async).length > 0">
      <div
        id="popup-content"
        #scrollMe
        [scrollTop]="scrollMe.scrollHeight"
        class="overflow-auto w-[40vw] h-[fit-content] max-h-[20vw] flex flex-col gap-8"
      >
        <ng-container *ngFor="let note of notes | async; let i = index">
          <div
            id="note-container"
            class="flex px-8"
            [ngClass]="note?.User === usernameOfTheSession ? 'justify-end' : 'justify-start'"
          >
            <div class="w-[52%] h-[fit-content] flex flex-col gap-3">
              <div
                *ngIf="usersFullName | async as users"
                class="flex flex-row gap-4"
                [ngClass]="note?.User === usernameOfTheSession ? 'justify-end' : 'justify-start'"
              >
                <span class="text-welcome text-base font-semibold">{{ users[note?.User] }}</span>
                <span class="text-base text-[#B9B9B9] font-semibold">{{ note.Date | date: "short" }}</span>
              </div>
              <span
                class="break-words w-full p-4 rounded-md text-welcome text-base font-semibold border-[1px] border-[#EEEEEE]"
                [ngClass]="note?.User === usernameOfTheSession ? 'bg-[#FDFDFD]' : 'bg-[#EEEEEE]'"
              >
                {{ note.Note }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="(notes | async).length === 0">
      <div id="no-notes" class="inline-flex items-center gap-4">
        <mat-icon class="!text-dialog">error</mat-icon>
        <span class="w-[25vw] text-xl font-semibold text-welcome">
          {{ "NOTES.NO_NOTES" | translate }}
        </span>
      </div>
    </ng-container>

    <div id="popup-textarea" class="px-8" *ngIf="data.Status !== 'Submitted'">
      <div class="flex flex-col gap-2 items-start w-full">
        <h3 class="text-welcome text-lg font-semibold">
          {{ "NOTES.TEXT" | translate | titlecase }}
        </h3>
        <textarea
          class="text-area p-4 h-24 border-[1px] border-[#EEEEEE] bg-[#FDFDFD] rounded-md w-full"
          [placeholder]="notesPlaceholder"
          [formControl]="notesFormControl"
        ></textarea>
      </div>
    </div>
    <div id="popup-buttons" class="flex flex-row items-center justify-end gap-4 w-full px-8 pb-2">
      <button (click)="closeNotesDialog()" class="whiteBtn">
        {{ "NOTES.CANCEL_BTN" | translate | titlecase }}
      </button>
      <button (click)="sendNote()" class="colorBtn">
        {{ "NOTES.SEND_BTN" | translate | titlecase }}
      </button>
    </div>
  </ng-container>
</div>
