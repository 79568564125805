import { ElencoTopic } from 'src/app/data/models/ElencoTopic';

export interface TopicsState {
  Topics?: ElencoTopic[];
  loading?: boolean;
  AssingedIdReviewed?: string;
}

export const initialTopicState: TopicsState = {
  Topics: [],
  loading: false,
  AssingedIdReviewed: null,
};
