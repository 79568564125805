import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectUsersFullname } from '../../users/users.selectors';

import { StateAssigned } from '../assigned-state';

export const appState = createFeatureSelector<StateAssigned>('assignedQuestionnaires');
export const selectAssignedQuestionnaires = createSelector(appState, (state) => state.AssignedQuestionnaires);

export const selectQuestionnairesAssigned = createSelector(appState, (state) => state.AssignedQuestionnairesToUser);
export const selectAssingedLoading = createSelector(appState, (state) => state.loadingAssigned);
export const selectFileRepoLoading = createSelector(appState, (state) => state.loadingFileRepo);
export const selectContainsFile = createSelector(appState, (state) => state.containsFile);
export const selectNotes = createSelector(appState, (state) => state.Notes);
export const selectloadingNotes = createSelector(appState, (state) => state.loadingNotes);

export const selectAnwerQuestionnairedTopicMetrics = createSelector(appState, (state) => state.topicMetrics);

export const selectAssignedQuestsTotalNumber = createSelector(appState, (state) => state.total);

export const selectAssignedQuestsPageIndex = createSelector(appState, (state) => state.pageIndex);
export const selectAssignedQuestsTotalAndIndex = createSelector(
  selectAssignedQuestsTotalNumber,
  selectAssignedQuestsPageIndex,
  (total, index) => {
    return { total, pageIndex: index };
  }
);
export const selectHomeUser = createSelector(appState, (state) => {
  if (!!state) {
    return state.homeUser;
  }
});

export const seletQuestionnaireToAnswer = createSelector(appState, (state) => {
  return state.questionnaireToBeAnswered;
});

export const selectError = createSelector(appState, (state) => {
  return state.error;
});

export const isQuestionnaireSubmitted = createSelector(appState, (state) => {
  return state.submittingAnswers;
});

export const groupAssignedByProject = createSelector(selectAssignedQuestionnaires, (assignedQuestionnaires) => {
  let projects = {};
  assignedQuestionnaires.forEach((quest) => {
    if (projects[quest.Project]) {
      projects[quest.Project].push(quest);
    } else if (projects[quest.Project] === undefined) {
      projects[quest.Project] = [];
      projects[quest.Project].push(quest);
    } else {
      projects[quest.Project].push(quest);
    }
  });
  return projects;
});

export const selectHomeQuestionnaires = createSelector(
  selectQuestionnairesAssigned,
  selectUsersFullname,
  (questionnaires, usersfullname, { user, sort }) => {
    let obj = {};
    Object.entries(questionnaires)
      .concat([])
      .sort((a, b) => {
        switch (sort) {
          case 'Assigned_Status':
            let a_temp, b_temp;
            if (a[1]['responsible'] === user) {
              if (a[1]['Completed_Topics'] === a[1]['Number_Of_Topics']) {
                a_temp = 3;
              } else {
                a_temp = -1;
              }
            } else if (a[1]['TopicsOfUsers'] === '0') {
              if (a[1]['Completed_Topics'] === a[1]['Number_Of_Topics']) {
                a_temp = 2;
              } else {
                a_temp = -1;
              }
            } else {
              if (a[1]['CompletedTopicsOfUsers'] === a[1]['TopicsOfUsers']) {
                a_temp = 1;
              } else {
                a_temp = -1;
              }
            }
            if (b[1]['responsible'] === user) {
              if (b[1]['Completed_Topics'] === b[1]['Number_Of_Topics']) {
                b_temp = 3;
              } else {
                b_temp = -1;
              }
            } else if (b[1]['TopicsOfUsers'] === '0') {
              if (b[1]['Completed_Topics'] === b[1]['Number_Of_Topics']) {
                b_temp = 2;
              } else {
                b_temp = -1;
              }
            } else {
              if (b[1]['CompletedTopicsOfUsers'] === b[1]['TopicsOfUsers']) {
                b_temp = 1;
              } else {
                b_temp = -1;
              }
            }
            return a_temp > b_temp ? -1 : 1;
          case 'Questionnaire_Title':
            return a[1]['projectTitle'].toLowerCase() > b[1]['projectTitle'].toLowerCase() ? 1 : -1;
          case 'Assigned_to':
            if (a[1]['responsible'] === user) {
              return -1;
            } else if (b[1]['responsible'] === user) {
              return 1;
            } else {
              return usersfullname[a[1]['responsible']].localeCompare(usersfullname[b[1]['responsible']]);
            }
          case 'Completed_Topics':
            const firstDiff = a[1]['Number_Of_Topics'] - a[1]['Completed_Topics'];
            const secondDiff = b[1]['Number_Of_Topics'] - b[1]['Completed_Topics'];

            return -(firstDiff - secondDiff);
          case 'Expiry_Date':
            return a[1]['expiryDate'] > b[1]['expiryDate'] ? 1 : -1;
          default:
            return a[1][sort] > b[1][sort] ? 1 : -1;
        }
      })
      .forEach((arrayOne) => {
        obj[arrayOne[0]] = arrayOne[1];
      });
    return obj;
  }
);

export const seletOneQuestionnairFilterUser = createSelector(seletQuestionnaireToAnswer, (state, user) => {
  if (!!state) {
    return {
      ...state,
    };
  }
});

export const seletQuestionnaireToAnswerTopics = createSelector(appState, (state, user) => {
  if (!!state.questionnaireToBeAnswered) {
    return state.questionnaireToBeAnswered.Topics.filter((topic) => {
      if (topic.Assigned_To === user.user) {
        return topic;
      } else if (topic.Assigned_To !== user.user) {
        if (state.questionnaireToBeAnswered.Assigned_To === user.user) {
          return topic;
        }
      }
    });
  }
});

export const selectNumberOfCompletedTopics = createSelector(
  selectQuestionnairesAssigned,
  (questionnaires, { assignedId }) => {
    if (!!questionnaires[assignedId]) {
      return questionnaires[assignedId]?.TopicsOfUsers;
    } else return 0;
  }
);

export const selectNumberOfNotes = createSelector(selectQuestionnairesAssigned, (questionnaires, { assignedId }) => {
  if (!!questionnaires[assignedId]) {
    return questionnaires[assignedId].Number_Of_Notes;
  } else return 0;
});

export const selectTopicsAssignedToUser = createSelector(seletQuestionnaireToAnswer, (questionnaire, { user }) => {
  let assignTopics = 0;

  questionnaire.Topics.forEach((topic) => {
    if (topic.Assigned_To === user) {
      assignTopics++;
    }
  });
  return assignTopics;
});
