import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from 'src/app/data/models/User';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { Store } from '@ngrx/store';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { ArticleDictionaryPipe } from 'src/app/utils/pipes/article-dictionary.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modify-topic',
  templateUrl: './modify-topic.component.html',
  styleUrls: ['./modify-topic.component.scss'],
})
export class ModifyTopicComponent implements OnInit, OnDestroy {
  @Output()
  cancelOut = new EventEmitter<boolean>();

  @Output()
  reAssignOut = new EventEmitter<{ user: User; note: string }>();

  @Input()
  selectedUser;

  textArea = new FormControl();

  userToBeAssigned: User;

  lang: string;
  langToUnsubscribe: Subscription;

  artPipe = new ArticleDictionaryPipe();
  popupTitle: string;
  popupNoteTitle: string;
  topicName: string;

  constructor(private magicStringsService: MagicStringsService, private store: Store) {}

  ngOnInit(): void {
    this.topicName = this.magicStringsService.Topic.toLowerCase();

    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
      this.popupTitle =
        this.lang === 'it'
          ? "Seleziona l'utente cui riassegnare " + this.artWord(this.topicName, 'plural')
          : 'Select the user to reassign ' + this.magicStringsService.TopicENG.toLowerCase() + ' to';
      this.popupNoteTitle =
        this.lang === 'it'
          ? 'Puoi eventualmente aggiungere una nota all’assegnazione'
          : 'You can eventually add a note to the assignment';
    });
  }

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
  }

  getUser(user: User) {
    this.userToBeAssigned = user;
  }

  cancel() {
    this.cancelOut.emit(true);
    this.userToBeAssigned = null;
  }

  reAssign() {
    this.reAssignOut.emit({
      user: this.userToBeAssigned,
      note: this.textArea.value,
    });
    this.userToBeAssigned = null;
  }

  artWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.artPipe.transform(word, plural, this.lang);
    return newWord;
  }
}
