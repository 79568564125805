<div id="home-page" class="box-content h-auto flex flex-col items-center py-6 px-12">
  <div
    id="title-filter"
    class="flex flex-col relative h-64 mx-auto p-6 sm:w-[75vw] md:h-64 lg:w-[68vw]"
    [ngClass]="{
      'transition-all duration-200 bg-card rounded-t-3xl mb-48 !h-[29rem] md:!h-80 ': showPanel
    }"
  >
    <div id="filter-line" class="flex flex-col items-center justify-between md:flex-row">
      <h1
        id="title"
        class="text-3xl font-semibold color-main"
        [ngClass]="{ 'text-white p-4 md:mb-12 md:px-0': showPanel }"
      >
        {{ "DICTIONARY.Questionari" | translate | titlecase }}
        {{ "HOME_PAGE.TITLE" | translate | titlecase }}
      </h1>
      <app-home-filter
        (searchObservable)="searchByName($event)"
        (filter)="filterQuests($event)"
        (sort)="sort($event)"
        (HideShowPanel)="showPanel = !showPanel"
        (filterStartDateChange)="filterStartDate = $event"
        (filterEndDateChange)="filterEndDate = $event"
        [lang]="lang"
      ></app-home-filter>
    </div>
    <div id="legend" class="inline-block">
      <div id="legend-row" class="text-main flex items-center">
        <mat-icon class="text-login h-auto w-auto !inline-flex !items-center">star</mat-icon>
        <span [ngSwitch]="lang" id="legend-text" class="text-base text-main ml-3">
          <span *ngSwitchCase="'it'">
            {{ "DICTIONARY.Questionari" | translate | titlecase }} per cui sei il
            {{ "DICTIONARY.Responsabile" | translate | lowercase }}</span
          >
          <span *ngSwitchCase="'en'"
            >{{ "DICTIONARY.Questionari" | translate | titlecase }} you are
            {{ "DICTIONARY.Responsabile" | translate | lowercase }} for</span
          >
        </span>
      </div>
      <div id="legend-row" class="text-main flex items-center">
        <mat-icon class="text-login h-auto w-auto !inline-flex !items-center">groups</mat-icon>
        <span id="legend-text" class="text-base text-main ml-3">
          {{ "DICTIONARY.Questionari" | translate | titlecase }}
          {{ "HOME_PAGE.LEGEND_ROW_TWO" | translate }}
        </span>
      </div>
      <div id="legend-row" class="text-main flex items-center">
        <mat-icon class="text-login h-auto w-auto !inline-flex !items-center"> fact_check </mat-icon>
        <span id="legend-text" class="text-base text-main ml-3">
          {{ "DICTIONARY.Questionari" | translate | titlecase }}
          {{ "HOME_PAGE.LEGEND_ROW_THREE" | translate }}
        </span>
      </div>
    </div>
  </div>

  <div
    id="grid-container"
    class="grid gap-10 items-center justify-center px-20 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
    *ngIf="!(combinedLoadings | async)[0]"
  >
    <ng-container *ngIf="questionnaireToAnswer | async as questionnaireObj">
      <ng-container
        *ngFor="
          let quest of objectKeys(questionnaireObj)
            | excludeQuestionnaires: questionnaireObj:filter:currentUser:searchByNameTerm:filterStartDate:filterEndDate
        "
      >
        <app-home-questionnaire
          [users]="usersFullName | async"
          [currentUser]="currentUser"
          [questionnaire]="quest"
          [lang]="lang"
        ></app-home-questionnaire>
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="(combinedLoadings | async)[0]">
    <app-spinner id="home-spinner" class="relative color-main"></app-spinner>
  </div>
</div>
