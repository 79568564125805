<div class="flex justify-center items-center w-full h-full min-h-[48rem] bg-white rounded-3xl px-6">
  <div *ngIf="totalScore > 0 && bestScore.score >= 0" class="flex flex-col justify-center h-full w-[fit-content]">
    <div class="flex items-center p-8">
      <div
        class="average-score-cyle mr-6 inline-flex h-48 w-48 flex-none content-center items-center justify-center rounded-full text-4xl font-bold text-white"
      >
        <h3>{{ (averageScore / totalScore) * 100 | round }}%</h3>
      </div>
      <div class="flex-initial">
        <h3 class="text-4xl text-card">{{ "ANALYTICS_PANEL.AVERAGE" | translate }}</h3>
        <p class="text-lg text-card">{{ "ANALYTICS_PANEL.AVERAGE_LOW" | translate }}</p>
      </div>
    </div>
    <div class="flex items-center p-8">
      <div
        class="best-score-cycle mr-6 inline-flex h-48 w-48 flex-none content-center items-center justify-center rounded-full text-4xl font-bold text-white"
      >
        <h3>{{ (bestScore.score / totalScore) * 100 | round }}%</h3>
      </div>
      <div class="flex-initial">
        <h3 class="text-4xl text-card">{{ "ANALYTICS_PANEL.BEST_SCORE" | translate }}</h3>
        <p class="text-lg text-card">{{ "DICTIONARY.Responsabile" | translate }}: {{ bestScore.user }}</p>
      </div>
    </div>
    <div class="flex items-center p-8">
      <div
        class="worst-score-cycle mr-6 inline-flex h-48 w-48 flex-none content-center items-center justify-center rounded-full text-4xl font-bold text-white"
      >
        <h3>{{ (worstScore.score / totalScore) * 100 | round }}%</h3>
      </div>
      <div class="flex-initial">
        <h3 class="text-4xl text-card">{{ "ANALYTICS_PANEL.WORST_SCORE" | translate }}</h3>
        <p class="text-lg text-card">{{ "DICTIONARY.Responsabile" | translate }}: {{ worstScore.user }}</p>
      </div>
    </div>
  </div>
  <!-- If no score is defined (0 default) -->
  <span *ngIf="totalScore === 0 && bestScore.score === 0" class="text-card text-2xl font-semibold text-center">
    {{ "ANALYTICS_PANEL.NO_SCORE_MESSAGE" | translate }}{{ "DICTIONARY.Progetto" | translate }}
  </span>
  <!-- If nobody submited (best score -100000) -->
  <span *ngIf="bestScore.score < 0" class="text-card text-2xl font-semibold text-center">
    {{ "ANALYTICS_PANEL.NOBODY_SUBMITED_MESSAGE_START" | translate }}{{ "DICTIONARY.Questionari" | translate
    }}{{ "ANALYTICS_PANEL.NOBODY_SUBMITED_MESSAGE_END" | translate }}
  </span>
</div>
