import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StatusFilter } from '../../models/statusFilter';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GetProjectService {
  constructor(private httpClient: HttpClient, private authService: AuthService) {}

  public getAllProjects(
    pageIndexValue,
    pageSizeValue,
    sortCol,
    sortOrderValue,
    allStatus,
    getArchived,
    searchString?: string,
    modelID?: string,
    modelFilter?: string,
    creatorFilter?: string,
    draft?: boolean,
    active?: boolean,
    closed?: boolean,
    expireFromFilter?: string,
    expireToFilter?: string,
    creationFromFilter?: string,
    creationToFilter?: string
  ) {
    searchString = searchString ? `&searchString=${encodeURIComponent(searchString)}` : ``;
    modelID = modelID ? `&modelID=${encodeURIComponent(modelID)}` : ``;
    modelFilter = modelFilter ? `&modelFilter=${encodeURIComponent(modelFilter)}` : ``;
    creatorFilter = creatorFilter ? `&creatorFilter=${encodeURIComponent(creatorFilter)}` : ``;
    let allStatusQuery = allStatus ? `&allStatus=${allStatus}` : ``;
    let draftQuery = ``;
    let activeQuery = ``;
    let closedQuery = ``;
    let archivedQuery = getArchived ? `&archived=${getArchived}` : ``;


    if(draft && active && closed) {
      allStatus = true;
      allStatusQuery = `&allStatus=${allStatus}`
    }else {
      draftQuery = draft ? `&statusDraftFilter=${draft}` : ``;
      activeQuery = active ? `&statusActiveFilter=${active}` : ``;
      closedQuery = closed ? `&statusClosedFilter=${closed}` : ``;
    }
    
    expireFromFilter = expireFromFilter ? `&expireFromFilter=${encodeURIComponent(expireFromFilter)}` : ``;
    expireToFilter = expireToFilter ? `&expireToFilter=${encodeURIComponent(expireToFilter)}` : ``;
    creationFromFilter = creationFromFilter ? `&creationFromFilter=${encodeURIComponent(creationFromFilter)}` : ``;
    creationToFilter = creationToFilter ? `&creationToFilter=${encodeURIComponent(creationToFilter)}` : ``;

    let getString = `searchProject?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortCol}&sortOrder=${sortOrderValue}${archivedQuery}${searchString}${modelFilter}${creatorFilter}${draftQuery}${activeQuery}${closedQuery}${allStatusQuery}${expireFromFilter}${expireToFilter}${creationFromFilter}${creationToFilter}${modelID}`;

    return this.httpClient.get(getString);
  }

  public getSingleProject(id) {
    const getString = `getSingleProject?id=${id}`;

    return this.httpClient.get(getString);
  }

  public getAnalyticsData(id, questionnairesAssignedIDs, topicsIDs, questionsIDs) {
    let getString = `getAnalyticsData?id=${id}`;
    let body = {}
    if(questionnairesAssignedIDs) {
      body['questionnairesAssigned'] = questionnairesAssignedIDs;
    }
    if(topicsIDs) {
      body['topics'] = topicsIDs;
    }
    if(questionsIDs) {
      body['questions'] = questionsIDs
    }
    if(Object.keys(body).length > 0) {
      let extra = '&data='+ encodeURIComponent( JSON.stringify(body));

      getString += extra;
    }
    
    return this.httpClient.get(getString);
  }

  public getProjectZip(id: string, lang: string, topic: string, downloadFiles: boolean) {
    let link;
    link = environment.apiUrl;

    window.open(
      `${link}/exportQuestionnaireZip?projectId=${id}&lang=${lang}&topic=${topic}&downloadFiles=${downloadFiles}&secret_token=${this.authService.getUserToken()}`,
      '_blank'
    );
  }
}
