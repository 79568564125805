import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { AllGroupClient } from 'src/app/data/models/ClientGroup';
import { UserActions } from 'src/app/Store/users/users.actions';
import {
  selectFilteredClientGroup,
  selectAllClientGroup,
  selectFilteredClientGroupModel,
} from 'src/app/Store/users/users.selectors';

@Component({
  selector: 'app-model-project-visibility-tab',
  templateUrl: './model-project-visibility-tab.component.html',
  styleUrls: ['./model-project-visibility-tab.component.scss'],
})
export class ModelProjectVisibilityTabComponent implements OnInit, OnDestroy {
  @Output() newModelVisibility = new EventEmitter<string[]>();
  @Input() set missingValue(value: boolean) {
    if (value) {
      this.valueMissing = true;
    }
  }

  valueMissing: boolean = false;

  allClientGroup$: Observable<AllGroupClient[]>;

  filteredClientGroup$: Observable<AllGroupClient[]>;

  allClientSubscription: Subscription;

  formChangesSubscription: Subscription;

  nameFilter = new FormControl();

  modelVisibilityForm: FormGroup;

  previousVal: { [id: string]: boolean };

  groupsCheched: string[] = [];

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    if (this.allClientSubscription) this.allClientSubscription.unsubscribe();
    if (this.formChangesSubscription) this.formChangesSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: UserActions.searchAllClientGroup,
      admin: '',
    });
    this.filteredClientGroup$ = this.store.select(selectFilteredClientGroupModel);

    this.allClientGroup$ = this.store.select(selectAllClientGroup);

    this.allClientSubscription = this.allClientGroup$.subscribe((data) => {
      const controls = {};
      if (data) {
        controls['admin'] = new FormControl(null);
        data.forEach((client) => {
          controls[client.client_id] = new FormControl(null);
          this.previousVal = { ...this.previousVal, [client.client_id]: false };
          if (client.groups.length > 0) {
            client.groups.forEach((group) => {
              controls[group.id] = new FormControl(null);
              this.previousVal = { ...this.previousVal, [group.id]: false };
            });
          }
        });

        this.modelVisibilityForm = new FormGroup(controls);

        this.subscribeToChange();
      }
    });

    this.nameFilter.valueChanges.pipe(debounceTime(500)).subscribe((value) =>
      this.store.dispatch({
        type: UserActions.searchFilterClientGroupModel,
        searchTerm: value,
      })
    );
  }

  subscribeToChange() {
    this.formChangesSubscription = this.modelVisibilityForm.valueChanges.subscribe((val) => {
      let isClient: boolean;
      const idChanged = Object.keys(val).find((currentId) => {
        return (val[currentId] && !this.previousVal[currentId]) || (!val[currentId] && this.previousVal[currentId]);
      });
      this.previousVal = { ...val };
      if (idChanged !== 'admin') {
        this.allClientGroup$.pipe(take(1)).subscribe((allGroupClient: AllGroupClient[]) => {
          isClient = allGroupClient.some((client) => client.client_id === idChanged);
          if (isClient) {
            const groupsToChange = allGroupClient
              .find((client) => client.client_id === idChanged)
              .groups.map((group) => group.id);
            if (val[idChanged]) {
              groupsToChange
                .filter((groupId) => !this.groupsCheched.includes(groupId))
                .forEach((groupId) => {
                  this.groupsCheched.push(groupId);
                  this.previousVal[groupId] = true;
                  this.modelVisibilityForm.controls[groupId].patchValue(true, { emitEvent: false });
                });
            } else {
              groupsToChange
                .filter((groupId) => this.groupsCheched.includes(groupId))
                .forEach((groupId) => {
                  this.groupsCheched = this.groupsCheched.filter((group) => group !== groupId);
                  this.previousVal[groupId] = false;
                  this.modelVisibilityForm.controls[groupId].patchValue(false, { emitEvent: false });
                });
            }
          } else {
            if (val[idChanged]) {
              this.groupsCheched.push(idChanged);
            } else {
              this.groupsCheched = this.groupsCheched.filter((group_id) => group_id !== idChanged);
            }
            allGroupClient.forEach((client) => {
              if (client.groups.length > 0) {
                let isClientComplete: boolean = true;
                client.groups.forEach((group) => {
                  if (!this.groupsCheched.includes(group.id)) {
                    isClientComplete = false;
                  }
                });

                this.modelVisibilityForm.controls[client.client_id].patchValue(isClientComplete, { emitEvent: false });
                this.previousVal[client.client_id] = isClientComplete;
              }
            });
          }
        });
      } else {
        if (val[idChanged]) {
          this.allClientGroup$.pipe(take(1)).subscribe((allGroupClient: AllGroupClient[]) => {
            allGroupClient.forEach((client) => {
              this.previousVal[client.client_id] = true;
              this.modelVisibilityForm.controls[client.client_id].patchValue(true, { emitEvent: false });
              client.groups.forEach((group) => {
                if (!this.groupsCheched.find((groupId) => groupId === group.id)) {
                  this.groupsCheched.push(group.id);
                }
                this.previousVal[group.id] = true;
                this.modelVisibilityForm.controls[group.id].patchValue(true, { emitEvent: false });
              });
            });
          });
        } else {
          this.allClientGroup$.pipe(take(1)).subscribe((allGroupClient: AllGroupClient[]) => {
            allGroupClient.forEach((client) => {
              this.previousVal[client.client_id] = false;
              this.modelVisibilityForm.controls[client.client_id].patchValue(false, { emitEvent: false });
              client.groups.forEach((group) => {
                this.groupsCheched = this.groupsCheched.filter((groupId) => groupId !== group.id);
                this.previousVal[group.id] = false;
                this.modelVisibilityForm.controls[group.id].patchValue(false, { emitEvent: false });
              });
            });
          });
        }
      }
      if (this.groupsCheched && this.groupsCheched.length > 0) {
        this.valueMissing = false;
      }
      this.newModelVisibility.emit(this.groupsCheched);
    });
  }
}
