<div class="relative flex h-screen w-full flex-1 flex-row justify-start bg-white bg-none">
  <div class="flex w-3/6 flex-nowrap content-start justify-center overflow-auto bg-img-login bg-cover bg-center">
    <div
      class="flex w-full flex-col flex-wrap content-center justify-center bg-contain bg-bottom bg-no-repeat"
      [ngClass]="{
        'overlay-pic0': appSvgTheme !== 1 && appSvgTheme !== 2,
        'overlay-pic1': appSvgTheme === 1,
        'overlay-pic2': appSvgTheme === 2
      }"
    >
      <h1 class="whitespace-pre-line text-5xl tracking-tight text-white">
        {{ "DICTIONARY.Welcome" | translate }}
      </h1>
      <hr class="mt-2 mb-4 h-[0.15rem] w-20 border-solid border-login bg-login" />
      <p class="text-xl text-white">{{ "DICTIONARY.AppName" | translate }} &copy; Deloitte Risk Advisory Team</p>
    </div>
  </div>

  <div class="w-1/2 overflow-auto">
    <div class="p-6">
      <div class="mx-[1rem] mt-[2rem] mb-[5rem] flex items-center justify-between">
        <img class="bg-y-[10px] max-h-[8rem] max-w-[21rem] bg-cover" [src]="imageUrl" />
        <div class="height-[fit-content] relative">
          <button class="flex items-center justify-center gap-1" (click)="showDropDown = !showDropDown">
            <img src="../../assets/images/login-language.svg" />
            <span class="text-main">{{ "LOGIN_PAGE.LANGUAGE" | translate }}</span>
            <img src="../../assets/images/login-language-arrow.svg" />
          </button>
          <div
            *ngIf="showDropDown"
            class="absolute z-10 mt-2 w-40 rounded-md border border-main bg-white px-6 py-4 shadow-xl"
          >
            <div
              class="mb-2 flex cursor-pointer items-center gap-2 text-center hover:bg-[#e5ebef]"
              (click)="changeLanguage('en')"
            >
              <img src="../../assets/countries/en.svg" alt="english" class="h-[22px] w-[28px]" />
              <span class="font-semibold text-main">{{ "LOGIN_PAGE.ENGLISH" | translate }}</span>
            </div>
            <div class="flex cursor-pointer items-center gap-2 pr-6 hover:bg-[#e5ebef]" (click)="changeLanguage('it')">
              <img src="../../assets/countries/it.svg" alt="italian" class="h-[22px] w-[28px]" />
              <span class="font-semibold text-[#013571]">{{ "LOGIN_PAGE.ITALIAN" | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="h-full">
        <div class="m-auto flex w-[fit-content] flex-col items-center justify-between">
          <div class="mb-12">
            <div class="flex items-center justify-start text-5xl font-bold tracking-tight">
              <div class="text-welcome">{{ "LOGIN_PAGE.WELCOME" | translate }}&nbsp;</div>
              <div class="text-login">
                {{ "DICTIONARY.AppName" | translate }}
              </div>
            </div>
            <h2 class="mt-4 text-2xl text-welcome">
              {{ "LOGIN_PAGE.PLEASE_LOGIN" | translate }}
            </h2>
          </div>
          <form [formGroup]="loginForm" class="mb-12 flex w-full flex-col items-center">
            <mat-form-field class="mb-6 w-[70%] bg-white" appearance="outline">
              <mat-label>Username</mat-label>
              <input
                matInput
                class="text-xl"
                type="text"
                name="usernameFormControl"
                [formControlName]="'usernameFormControl'"
                onkeypress=""
                (focus)="passwordHidden()"
                (focusout)="passwordHidden()"
              />
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
            <mat-form-field class="w-[70%]" appearance="outline">
              <mat-label>Password</mat-label>
              <input
                matInput
                type="password"
                name="passwordFormControl"
                [formControlName]="'passwordFormControl'"
                (keydown.enter)="onkeyup($event)"
                [type]="hide ? 'password' : 'text'"
              />
              <button matSuffix (click)="hide = !hide" tabindex="-1">
                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
            </mat-form-field>
          </form>

          <div>
            <mat-error *ngIf="loginForm.invalid">{{ invalid_cred_error }}</mat-error>
            <mat-error *ngIf="(error$ | async)?.error !== '' && notFocused">{{ (error$ | async)?.error }}</mat-error>
          </div>

          <button class="mb-6 flex w-[70%] justify-center text-2xl colorBtnLogin" (click)="login()">LOGIN</button>

          <div class="mb-6 flex w-[70%] items-center justify-between">
            <hr class="h-[0.15rem] w-20 border-solid border-grayBorder bg-grayBorder" />
            <p class="text-xl">
              {{ "LOGIN_PAGE.OR_CONTINUE" | translate }}
            </p>
            <hr class="h-[0.15rem] w-20 border-solid border-grayBorder bg-grayBorder" />
          </div>

          <button class="w-[70%] text-2xl whiteBtn" (click)="loginSSO()">SSO</button>
        </div>
      </div>
    </div>
  </div>
</div>
