  <ng-container *ngIf="usersFullName | async as getUserFullName">
      <div class="flex h-full items-center justify-center">
        <div
          class="grid h-full w-full grid-cols-[1fr,9fr,4fr,1fr] grid-rows-[1fr,5fr,min-content] gap-x-6 gap-y-7 p-10"
        >
          <!-- TOGGLE-PANEL-AREA -->
          <div id="toggle-panel-area" class="row-span-full row-start-2">
            <mat-button-toggle-group
              #togglePanel="matButtonToggleGroup"
              class="!flex !flex-col items-center justify-center gap-8 border-none"
              value="edit"
            >
              <mat-button-toggle
                value="edit"
                id="edit-button"
                class="flex h-24 w-24 items-center justify-center rounded-md !border-none bg-transparent text-card"
              >
                <mat-icon class="!text-7xl" [ngClass]="{ 'text-white': togglePanel.value === 'edit' }">edit</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle
                value="admin"
                id="admin-button"
                class="flex h-24 w-24 items-center justify-center rounded-md !border-none bg-transparent text-card"
              >
                <mat-icon class="!text-7xl" [ngClass]="{ 'text-white': togglePanel.value === 'admin' }">
                  admin_panel_settings</mat-icon
                >
              </mat-button-toggle>
              <mat-button-toggle
                value="analytics"
                (click)="dispatchAnalyticsData()"
                id="analytics-button"
                class="flex h-24 w-24 items-center justify-center rounded-md !border-none bg-transparent text-card"
                [disabled]="projectToBeModified?.PROJECT_STATUS === 'Draft'"
                [ngClass]="{
                  '!bg-transparent': projectToBeModified?.PROJECT_STATUS === 'Draft'
                }"
              >
                <mat-icon
                  class="!text-7xl"
                  [ngClass]="{
                    'text-white': togglePanel.value === 'analytics',
                    'text-gray-300': projectToBeModified?.PROJECT_STATUS === 'Draft'
                  }"
                  >insights</mat-icon
                >
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!-- TITLE-AREA -->
          <div
            id="title-area"
            *ngIf="projectToBeModified?.PROJECT_STATUS !== 'Closed'"
            class="col-start-2 flex w-full flex-col items-center"
          >
            <app-title-of-questionnaire [inputForm]="formvalue" class="h-full w-full"> </app-title-of-questionnaire>
          </div>

          <!-- TITLE AREA (CLOSED PROJECTS) -->
          <div
            id="title-area-closed-project"
            *ngIf="projectToBeModified?.PROJECT_STATUS === 'Closed'"
            class="col-start-2 rounded-3xl bg-white text-dialog shadow-md"
          >
            <div class="ml-8 flex h-full flex-col justify-center">
              <p class="text-left text-3xl font-bold">
                {{ projectToBeModified.TITLE }}
              </p>
              <p class="text-2xl">{{ projectToBeModified.DESCRIPTION }}</p>
            </div>
          </div>

          <!-- INFO-AREA -->
          <div id="info-area" class="flex flex-col justify-center rounded-3xl bg-white p-5 text-dialog shadow-md">
            <div class="flex items-center text-login">
              <mat-icon class="text-main">access_time</mat-icon>
              <span class="mx-2 text-base font-medium text-main"
                >{{ "PROJECT_OPTIONS.STATUS1" | translate }}
                {{ "DICTIONARY.Progetto" | translate | titlecase }}
                {{ "PROJECT_OPTIONS.STATUS2" | translate }}</span
              >
              <span class="text-base font-bold text-main">
                {{ !!projectToBeModified ? projectToBeModified.PROJECT_STATUS : "Draft" }}
              </span>
            </div>
            <div class="flex items-center text-login">
              <mat-icon class="text-main">account_box</mat-icon>
              <span class="mx-2 text-base font-medium text-main">{{ "PROJECT_OPTIONS.OWNER" | translate }}</span>
              <span class="text-base font-bold text-main"> {{ getUserFullName[currentUser] }}</span>
            </div>
            <div class="flex items-center text-login">
              <mat-icon class="text-main">today</mat-icon>
              <span class="mx-2 text-base font-medium text-main">{{ "PROJECT_OPTIONS.LAST_CHANGE" | translate }}</span>
              <span class="text-base font-bold text-main">{{ ultimaDate }}</span>
            </div>
          </div>

          <!-- EDIT-PROJECT-AREA -->
          <app-assing-model-table
            id="edit-project-area"
            *ngIf="togglePanel.value === 'edit'"
            class="col-start-2"
            [importProjectLoadingUsers]="importedUsersForProjectAndLoading | async"
            [finalUsersChosen]="finalUsersChosen"
            [isModified]="isModified"
            [projectToBeModified]="projectToBeModified"
            [getUserFullName]="getUserFullName"
            [activeQuestionnaire]="activeQuestionnaire"
            [isProjectDraft]="isProjectDraft"
            [ProjectFormGroup]="ProjectFormGroup"
            [userInSession]="userInSession"
            [starterusers]="starterusers"
            [formChange]="formChange"
            [lang]="lang"
            (goToAnswer)="goToAnwswer($event)"
            (exportQuest)="exportQuestionnairefunc($event)"
            (changeSend)="sendAndUpdateChange($event)"
            (openNotesModal)="getNotes($event)"
            (addUserResponsible)="addResponsible($event)"
            (setFinalChosenUsers)="finalUsersChosen = $event"
            (setWatchListUsers)="watchListUsers = $event"
            (setActiveProjectData)="activeProjectData = $event"
            (sendUsersFromExcel)="sendUsersFromExcel($event)"
          >
          </app-assing-model-table>

          <!-- ADMIN-AREA -->
          <div id="admin-area" class="col-start-2" *ngIf="togglePanel.value === 'admin'">
            <app-admin-panel></app-admin-panel>
          </div>

          <div class="rounded-3xl bg-white shadow-md" *ngIf="togglePanel.value === 'admin'">
            <div class="flex h-full items-center justify-center">
              <button (click)="togglePanel.value = 'edit'">
                <span class="text-2xl font-semibold text-card">Please go back to edit relevant info</span>
              </button>
            </div>
          </div>

          <!-- ANALYTICS-GRAPHS-AREA -->
          <div
            id="analytics-graphs-area"
            class="col-start-2 overflow-y-auto"
            [ngClass]="{
              'flex h-full w-full items-center justify-center rounded-3xl bg-white': loadingAnalyticsData | async
            }"
            *ngIf="togglePanel.value === 'analytics'"
          >
            <ng-container *ngIf="!(loadingAnalyticsData | async)">
              <app-analytics-panel
                [analyticsData]="analyticsData"
                [projectID]="projectToBeModified.PK_PROJECT"
              ></app-analytics-panel>
            </ng-container>
            <app-spinner [height]="'20px'" [width]="'44'" *ngIf="loadingAnalyticsData | async"></app-spinner>
          </div>

          <!-- ANALYTICS-SCORE-AREA -->
          <div
            id="analytics-score-area"
            [ngClass]="{
              'flex h-full w-full items-center justify-center rounded-3xl bg-white': loadingAnalyticsData | async
            }"
            *ngIf="togglePanel.value === 'analytics'"
          >
            <ng-container *ngIf="!(loadingAnalyticsData | async) || !firstTimeAnalytics">
              <app-analytics-score-panel
                [bestScore]="bestScoreObj"
                [averageScore]="averageScore"
                [worstScore]="worstScoreObj"
                [totalScore]="totalScore"
              ></app-analytics-score-panel>
            </ng-container>
            <app-spinner
              [height]="'20px'"
              [width]="'44'"
              *ngIf="(loadingAnalyticsData | async) && firstTimeAnalytics"
            ></app-spinner>
          </div>

          <!-- PROJECT-OPTIONS-AREA -->
          <div
            id="project-options-area"
            *ngIf="togglePanel.value === 'edit'"
            [formGroup]="ProjectFormGroup"
            class="grid grid-rows-[min-content,auto] rounded-3xl bg-white p-5 text-dialog shadow-md"
          >
            <div class="text-2xl font-bold text-card">
              <div class="">
                {{ "PROJECT_OPTIONS.TITLE1" | translate }}
                {{ "DICTIONARY.Progetto" | translate | titlecase }}
                {{ "PROJECT_OPTIONS.TITLE2" | translate }}
              </div>
            </div>
            <div class="checkboxes items-center justify-center overflow-y-auto overflow-x-hidden pt-6 text-sm">
              <div
                [ngClass]="{
                  disabled: isModified && projectToBeModified.PROJECT_STATUS !== 'Draft'
                }"
                [ngClass]="{
                  'pointer-events-none border-[1.5px] border-solid border-grayBorder text-grayBorder':
                    isModified && projectToBeModified.PROJECT_STATUS !== 'Draft'
                }"
                (click)="toggleModelVisibility()"
                class="relative mb-5 flex w-[98%] cursor-pointer items-center justify-between rounded-lg border border-solid border-grayBorder p-2 text-lg font-semibold"
              >
                <span
                  [ngClass]="{
                    ' text-grayBorder': isModified && projectToBeModified.PROJECT_STATUS !== 'Draft'
                  }"
                  class="absolute top-[-8px] left-[5.5px] bg-white px-[4px] text-base font-normal text-black"
                  >{{ "DICTIONARY.Modello" | translate }}</span
                >
                <span class="w-[20vw] overflow-hidden whitespace-normal break-all line-clamp-2">
                  {{
                    isModified
                      ? !!projectToBeModified
                        ? projectToBeModified.Questionnaire_Title
                        : ""
                      : !!activeQuestionnaire
                      ? activeQuestionnaire.Questionnaire_Title
                      : ""
                  }}
                </span>

                <mat-icon>arrow_drop_down</mat-icon>
              </div>

              <app-datepick
                [ngClass]="{ disabled: isModified && shouldDisable }"
                [topicDate]="expDate"
                #myDatePick
                (date)="getTheDate($event)"
              ></app-datepick>
              <div class="checkbox-list max-h-[26vh] w-full self-start">
                <div class="flex items-center p-[1vh]">
                  <mat-checkbox
                    [ngClass]="{ disabled: isModified && shouldDisable }"
                    name="isProjectCollaborative"
                    formControlName="isProjectCollaborative"
                    (change)="enableCheckboxes()"
                  >
                    <p
                      *ngIf="lang === 'it'"
                      class="max-w-[15vw] overflow-ellipsis whitespace-normal break-all text-lg font-semibold leading-normal"
                    >
                      {{ "PROJECT_OPTIONS.COLLABORATIVE" | translate }}
                      {{ "DICTIONARY.Progetto" | translate | titlecase }}
                      {{
                        "Collaborativo " + ("DICTIONARY.Progetto" | translate) | verbPronounDictionary: "singular":lang
                      }}.
                      <mat-icon class="ml-[0.5rem] align-middle text-[35px]">supervisor_account</mat-icon>
                    </p>
                    <p
                      *ngIf="lang === 'en'"
                      class="max-w-[15vw] overflow-ellipsis whitespace-normal break-all text-lg font-semibold leading-normal"
                    >
                      {{ "PROJECT_OPTIONS.COLLABORATIVE" | translate }}
                      {{ "DICTIONARY.Progetto" | translate | titlecase }}.
                      <mat-icon class="ml-[0.5rem] align-middle text-[35px]">supervisor_account</mat-icon>
                    </p>
                  </mat-checkbox>
                </div>
                <div class="flex items-center p-[1vh]">
                  <mat-checkbox
                    [ngClass]="{
                      disabled: (isModified && shouldDisable) || !ProjectFormGroup.get('isProjectCollaborative').value
                    }"
                    name="isSeenByAllUsers"
                    formControlName="isSeenByAllUsers"
                  >
                    <p
                      class="max-w-[98%] overflow-ellipsis whitespace-normal break-normal text-lg font-semibold leading-normal"
                    >
                      {{ "PROJECT_OPTIONS.CHECK11" | translate }}
                      {{
                        "DICTIONARY.SingleTopic" | translate | lowercase | indefiniteArticleDictionary: "singular":lang
                      }}
                      {{
                        "assegnato " + ("DICTIONARY.SingleTopic" | translate) | verbPronounDictionary: "singular":lang
                      }}
                      {{ "PROJECT_OPTIONS.CHECK12" | translate }}
                      {{ "tutto " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":lang }}
                      {{ "DICTIONARY.Topic" | translate | lowercase | articleDictionary: "plural":lang }}
                      {{ "PROJECT_OPTIONS.CHECK13" | translate }}
                      {{
                        "di " + ("DICTIONARY.Questionario" | translate | lowercase)
                          | prepositionDictionary: "singular":lang
                      }}.
                    </p>
                  </mat-checkbox>
                </div>
                <div class="flex items-center p-[1vh]">
                  <mat-checkbox
                    [ngClass]="{
                      disabled: (isModified && shouldDisable) || !ProjectFormGroup.get('isProjectCollaborative').value
                    }"
                    name="everybodyCanReAssign"
                    formControlName="everybodyCanReAssign"
                  >
                    <p
                      class="max-w-[98%] overflow-ellipsis whitespace-normal break-normal text-lg font-semibold leading-normal"
                    >
                      {{ "PROJECT_OPTIONS.CHECK21" | translate }}
                      {{ "DICTIONARY.Responsabile" | translate | lowercase }}
                      {{ "PROJECT_OPTIONS.CHECK22" | translate }}
                      {{
                        "suo " + ("DICTIONARY.Topic" | translate | lowercase)
                          | possessiveAdjectiveDictionary: "plural":lang
                          | articleDictionary: "plural":lang
                      }}
                      {{ "PROJECT_OPTIONS.CHECK23" | translate }}
                    </p>
                  </mat-checkbox>
                </div>
                <div class="flex items-center p-[1vh]">
                  <mat-checkbox
                    class="max-w-[98%] overflow-ellipsis whitespace-normal break-normal text-lg font-semibold leading-normal"
                    [ngClass]="{ disabled: isModified && shouldDisable }"
                    name="userCanExportData"
                    formControlName="userCanExportData"
                  >
                    <p>
                      {{ "PROJECT_OPTIONS.CHECKNEW1" | translate }}
                      {{ "DICTIONARY.Responsabile" | translate | lowercase }}
                      {{ "PROJECT_OPTIONS.CHECKNEW2" | translate }}
                    </p>
                  </mat-checkbox>
                </div>
                <div class="flex items-center p-[1vh]">
                  <mat-checkbox
                    [ngClass]="{ disabled: true }"
                    name="onlyTheResponsibleCanChange"
                    formControlName="onlyTheResponsibleCanChange"
                  >
                    <p
                      class="max-w-[98%] overflow-ellipsis whitespace-normal break-normal text-lg font-semibold leading-normal"
                    >
                      {{ "PROJECT_OPTIONS.CHECK31" | translate }}
                      {{ "DICTIONARY.Responsabile" | translate | lowercase }}
                      {{ "PROJECT_OPTIONS.CHECK32" | translate }}
                      {{ "DICTIONARY.Responsabile" | translate | lowercase | articleDictionary: "singular":lang }}
                      {{ "PROJECT_OPTIONS.CHECK33" | translate }}
                      {{
                        "di " + ("DICTIONARY.Questionario" | translate | lowercase)
                          | prepositionDictionary: "singular":lang
                      }}.
                    </p>
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <!-- BUTTONS-AREA -->
          <div id="button-area" class="col-span-2 flex flex-col rounded-3xl bg-white p-5 text-dialog shadow-md">
            <div class="flex h-full items-center justify-between">
              <div id="other-buttons" class="inline-flex flex-row items-center gap-4">
                <button
                  *ngIf="!isProjectDraft && isModified && projectToBeModified?.PROJECT_STATUS !== 'Closed'"
                  (click)="showNotifyFunc()"
                  class="whiteBtn"
                >
                  {{ "PROJECT_OPTIONS.SEND_NOTIFICATION" | translate }}
                </button>
                <button
                  *ngIf="!isProjectDraft && isModified && projectToBeModified?.PROJECT_STATUS !== 'Closed'"
                  (click)="showDialogClose = true"
                  class="whiteBtn"
                >
                  {{ "PROJECT_OPTIONS.CLOSE_BUTTON" | translate }}
                  {{ "DICTIONARY.Progetto" | translate | uppercase }}
                </button>
              </div>
              <div id="save-buttons" class="inline-flex flex-row items-center gap-4">
                <button
                  class="whiteBtn"
                  (click)="openManagementQuestionnaire()"
                  *ngIf="projectToBeModified?.PROJECT_STATUS !== 'Closed'"
                >
                  {{ "ANNULLA" | translate }}
                </button>
                <button
                  class="whiteBtn"
                  (click)="openManagementQuestionnaire()"
                  *ngIf="projectToBeModified?.PROJECT_STATUS === 'Closed'"
                >
                  {{ "PROJECT_OPTIONS.GO_BACK" | translate }}
                </button>
                <button
                  class="whiteBtn"
                  (click)="save()"
                  *ngIf="projectToBeModified?.PROJECT_STATUS === 'Draft'"
                  [ngClass]="{
                    disabled: !!projectToBeModified ? false : !formvalue.valid || ProjectFormGroup.status === 'INVALID'
                  }"
                >
                  {{ "PROJECT_OPTIONS.SAVE_BUTTON" | translate }}
                </button>

                <ng-container
                  *ngIf="
                    projectToBeModified?.PROJECT_STATUS !== 'Closed' &&
                    projectToBeModified?.PROJECT_STATUS !== 'Draft' &&
                    projectToBeModified?.PROJECT_STATUS !== 'Active'
                  "
                >
                  <button class="whiteBtn" (click)="save()">
                    {{ "PROJECT_OPTIONS.SAVE_BUTTON" | translate }}
                  </button>
                </ng-container>

                <button
                  class="colorBtn"
                  (click)="save()"
                  *ngIf="projectToBeModified?.PROJECT_STATUS === 'Active'"
                  [ngClass]="{
                    disabled: !!projectToBeModified ? false : !formvalue.valid || ProjectFormGroup.status === 'INVALID'
                  }"
                >
                  {{ "SALVA" | translate }}
                </button>
                <app-spinner [height]="'20px'" [width]="'44'" *ngIf="project$loading | async"></app-spinner>
                <button
                  *ngIf="(isProjectDraft && isModified) || projectToBeModified?.PROJECT_STATUS == null"
                  (click)="publishProject()"
                  class="colorBtn"
                >
                  {{ "PROJECT_OPTIONS.PUBLISH_BUTTON" | translate }}
                  {{ "DICTIONARY.Progetto" | translate | uppercase }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </ng-container>



<div *ngIf="showModelsTable" class="overlayHidden" [ngClass]="{ overlayShow: showModelsTable }">
  <div class="w-[70vw]">
    <app-models-table
      [lang]="lang"
      [dataSource]="models | async"
      (closeViewModeOut)="toggleModelVisibility()"
      (viewModeSingleSelection)="selectedModel($event)"
      [selectMode]="true"
      *ngIf="showModelsTable"
      [mode]="isModified"
      [projectSelected]="
        projectToBeModified ? projectToBeModified.Questionnaire_Title : activeQuestionnaire.Questionnaire_Title
      "
    >
    </app-models-table>
  </div>
</div>

<div
  *ngIf="showDialogClose"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showDialogClose }"
  (click)="showDialogClose = false"
>
  <div (click)="$event.stopPropagation()">
    <div>
      <mat-card class="p-10px ml-4 mt-[10px] flex w-[40vw] justify-start rounded-[10px] bg-white">
        <div
          class="flex items-start justify-between pl-4 pt-2 font-poppins text-3xl font-black tracking-normal text-dialog"
        >
          {{ "ATTENZIONE" | translate }}
        </div>
        <div class="inline-flex items-center p-4">
          <mat-icon class="mr-4 !text-dialog">error</mat-icon>
          <div class="my-4 text-xl">
            {{ "PROJECT_OPTIONS.POP_UP_CLOSE_DESC1" | translate }}
            {{ "questo " + ("DICTIONARY.Progetto" | translate) | verbPronounDictionary: "singular":lang }}
            {{ "DICTIONARY.Progetto" | translate | lowercase }}?
            <br />
            {{ "PROJECT_OPTIONS.POP_UP_CLOSE_DESC2" | translate }}
          </div>
        </div>

        <div class="flex items-center justify-end p-[5px]">
          <button class="mr-[0.8vw] whiteBtn" (click)="showDialogClose = false">
            {{ "ANNULLA" | translate }}
          </button>
          <button class="colorBtn" (click)="closeProject()">
            {{ "CONFERMA" | translate }}
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="showNotify" class="overlayHidden" [ngClass]="{ overlayShow: showNotify }">
  <div
    (click)="$event.stopPropagation()"
    class="flex h-auto min-h-[15vh] w-[40vw] flex-col justify-between rounded-3xl bg-white p-8"
  >
    <h2 class="flex items-center justify-between text-3xl font-medium tracking-normal text-dialog">
      {{ "PROJECT_OPTIONS.NOTIFICATION1" | translate }}
      {{ "DICTIONARY.Responsabile" | translate | titlecase }}
      {{ "PROJECT_OPTIONS.NOTIFICATION2" | translate }}
      <span>
        <button mat-icon-button (click)="cancelNotifyFUnct()">
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </h2>
    <div class="flex w-full flex-col items-start gap-2 px-8 py-6">
      <h3 class="text-lg font-semibold text-welcome">
        {{ "NOTES.TEXT" | translate | titlecase }}
      </h3>
      <textarea matInput placeholder="Inserisci una nuona message" [(ngModel)]="message"></textarea>
      <div class="flex flex-row items-center justify-end gap-4 w-full px-8 pb-2">
        <button (click)="cancelNotifyFUnct()" class="whiteBtn">
          {{ "NOTES.CANCEL_BTN" | translate | titlecase }}
        </button>
        <button (click)="notifyResponsiblesfunc()" class="colorBtn">
          {{ "NOTES.SEND_BTN" | translate | titlecase }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #CreationLoading>
  <app-spinner class="absolute left-1/2 top-1/2"></app-spinner>
</ng-template>
