<div class="w-full flex items-center justify-between gap-8 mb-6">
  <div class="inline-flex items-center gap-8">
    <strong class="text-xl">{{ question.Position }}</strong>
    <span
      matTooltip="{{ question.Question_Text }}"
      matTooltipClass="custom-tooltip"
      appShowIfTruncated
      class="text-xl"
      (contextmenu)="
        copyToClipBoard(question.Question_Text);
        openSnackBar(
          translate.instant('QUESTION_TYPES_FORM.TITLE_SENTENCE'),
          translate.instant('QUESTION_TYPES_FORM.CLOSE')
        )
      "
    >
      - {{ question.Question_Text }}
    </span>

    <span class="text-xl text-dialog font-semibold">
      {{
        isFileStored
          ? "1 file " + ("FILE.UPLOADED_SUCCESSFULLY" | translate)
          : currentFile
          ? "1 file " + ("FILE.UPLOADED_PENDING" | translate)
          : "0 file"
      }}
    </span>
  </div>

  <mat-icon
    *ngIf="question.Description !== ''"
    (click)="toggleDescriptionVisibility()"
    matTooltip="{{ plainText }}"
    class="text-[35px] text-main cursor-pointer ml-4"
    >help
  </mat-icon>
</div>
<div
  [ngClass]="{
    'disabled-border-color cursor-default pointer-events-none': reviewMode, 'hover:border-dialog': !reviewMode
  }"
  class="flex items-center gap-8 mat-ff-in-rems border-2 rounded-lg mb-6 transition"
>
  <button *ngIf="!reviewMode" (click)="toggleModelVisibility()" class="colorBtn flex items-center justify-center h-11 !rounded-lg ml-1">
    {{ isFileStored ? ("FILE.OPEN_FILE" | translate) : ("FILE.CHOOSE_FILE" | translate) }}
  </button>
  <input mat-input name="Input" [(ngModel)]="fileId" type="text" class="disabled hidden" />
  <p class="truncate max-w-[35vw]" [matTooltip]="uploadedFilename" appShowIfTruncated>
    {{ uploadedFilename }}
  </p>
</div>

<div id="upload-popup" *ngIf="showPopup" class="overlayHidden" [ngClass]="{ overlayShow: showPopup }">
  <div
    id="selections-div"
    class="px-6 py-12 w-[35vw] 2xl:w-[27vw] min-w-min h-[fit-content] rounded-3xl bg-white flex flex-col gap-6 items-center justify-center overflow-hidden relative"
    (click)="$event.stopPropagation()"
  >
    <button (click)="toggleModelVisibility()" mat-icon-button class="!absolute top-0 right-0 !m-4 !text-card">
      <mat-icon>close</mat-icon>
    </button>
    <div class="flex flex-col gap-2 items-center justify-center">
      <span id="title" class="text-card font-bold text-4xl font-popppins">{{ "FILE.DROPZONE_TITLE" | translate }}</span>
      <ng-container *ngIf="question.File_Type.toLowerCase() !== 'any'">
        <ng-container *ngFor="let fileInfo of ALLOWED_FILE_EXT">
          <span
            id="subtitle"
            class="text-[#707070] text-lg font-popppins"
            *ngIf="fileInfo.fileType === question.File_Type.toLowerCase()"
          >
            {{ "FILE.DROPZONE_SUBTITLE" | translate }}:
            <span class="text-dialog">{{ fileInfo.ext }}</span>
          </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="question.File_Type.toLowerCase() === 'any'">
        <span id="subtitleAny" class="text-[#707070] text-lg font-popppins">
          {{ "FILE.DROPZONE_SUBTITLE_ANY" | translate }}
        </span>
      </ng-container>
    </div>

    <div
      id="dropzone-box"
      appDragDrop
      (files)="dropFile($event, 'dropped')"
      class="w-4/5 border-2 border-dialog border-dashed rounded-lg flex flex-col gap-4 items-center justify-center p-12"
      [ngClass]="{
        'pointer-events-none opacity-30':
          (isInProgress$ | async) || ((storedFile$ | async) && (storedFile$ | async).length > 0)
      }"
    >
      <mat-icon>upload</mat-icon>
      <div id="content" class="flex flex-row items-center justify-center text-lg text-dialog text-center">
        <span class="text-[#707070] text-lg"
          >{{ "FILE.DROPZONE_DRAGDROP" | translate }}
          <button type="submit" (click)="fileInput.click()">
            <span class="underline text-dialog">{{ "FILE.DROPZONE_BROWSE" | translate }}</span>
            <input #fileInput type="file" (change)="dropFile($event, 'browsed')" style="display: none" />
          </button>
          {{ "FILE.DROPZONE_TEXT" | translate }}</span
        >
      </div>
      <span class="text-dialog text-base">{{ "FILE.DROPZONE_SIZE" | translate }} 30Mb</span>
    </div>

    <div
      id="filelist-box"
      *ngIf="currentFile || ((storedFile$ | async) && (storedFile$ | async).length > 0)"
      class="w-4/5 flex flex-col gap-4 items-start justify-center pb-4"
      [@fadeInOut]
    >
      <span class="text-dialog text-lg font-popppins"
        >{{
          (storedFile$ | async) && (storedFile$ | async).length > 0
            ? ("FILE.DROPZONE_UPLOADEDFILE" | translate)
            : ("FILE.DROPZONE_SELECTEDFILE" | translate)
        }}:</span
      >
      <div class="flex flex-row items-center justify-between gap-4 w-full">
        <ng-container *ngIf="(storedFile$ | async) && (storedFile$ | async).length > 0">
          <ng-container *ngFor="let file of storedFile$ | async">
            <img id="file-icons" src="{{ applyIcons(file.name) }}" />
            <div class="flex flex-col gap-1 w-full">
              <div class="inline-flex items-center justify-between">
                <span
                  class="text-dialog font-medium truncate max-w-[10vw]"
                  [matTooltip]="file.name"
                  appShowIfTruncated
                  >{{ file.name }}</span
                >
              </div>
              <span class="text-[#707070]">{{
                file.size / 1000 < 1024
                  ? (file.size / 1000).toFixed(2) + "Kb"
                  : (file.size / 1024 / 1000).toFixed(2) + "Mb"
              }}</span>
            </div>
            <div class="contents">
              <mat-icon class="check-icon">check</mat-icon>
              <button (click)="downloadFile(file.id)">
                <p class="text-dialog">
                  {{ "FILE.DROPZONE_DOWNLOAD" | translate }}
                </p>
              </button>
              <button (click)="deleteFile(file.id)">
                <p class="text-red-600">
                  {{ "FILE.DROPZONE_DELETE" | translate }}
                </p>
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="currentFile">
          <img id="file-icons" src="{{ applyIcons(currentFile.name) }}" />
          <div class="flex flex-col gap-1 w-full">
            <div class="inline-flex items-center justify-between">
              <span
                class="text-dialog font-medium truncate max-w-[10vw]"
                [matTooltip]="currentFile.name"
                appShowIfTruncated
                >{{ currentFile.name }}</span
              >
            </div>
            <span class="text-[#707070]">{{
              currentFile.size / 1000 < 1024
                ? (currentFile.size / 1000).toFixed(2) + "Kb"
                : (currentFile.size / 1024 / 1000).toFixed(2) + "Mb"
            }}</span>
          </div>
        </ng-container>
      </div>
      <div class="inline-flex justify-end w-full">
        <button class="colorBtn flex items-center justify-center h-11 !rounded-lg" (click)="toggleModelVisibility()">
          {{ "FILE.DROPZONE_DONE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="showDescription"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showDescription }"
  (click)="toggleDescriptionVisibility()"
>
  <div
    id="selections-div"
    class="p-6 w-[55rem] h-[fit-content] rounded-2xl bg-white flex flex-col gap-6 justify-between overflow-hidden"
    (click)="$event.stopPropagation()"
  >
    <div id="title" class="text-card font-bold text-4xl font-popppins">
      {{ "DESCRIZIONE" | translate }}
    </div>
    <div [innerHTML]="question.Description" class="text-base py-[3rem]">
      {{ question.Description }}
    </div>
    <div class="flex flex-row mt-[10px] !justify-end">
      <button
        (click)="
          copyToClipBoard(plainText);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.DESCRIPTION_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
        class="whiteBtn mr-3"
      >
        {{ "QUEST_CREATION.DESC_COP" | translate }}
      </button>
      <button class="colorBtn" (click)="toggleDescriptionVisibility()">
        {{ "UNDERSTOOD" | translate }}
      </button>
    </div>
  </div>
</div>
