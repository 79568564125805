import { createFeatureSelector, createSelector } from '@ngrx/store';
import { findIndexAtArray } from 'src/app/data/data-handlers/utils.service';
import { selectTopics } from '../topics-store/topics.selectors';
import { TopicsState } from '../topics-store/topics.state';

export const appState = createFeatureSelector<TopicsState>('topics');

export const selectTopicsByIndex = (topicIndex) =>
  createSelector(selectTopics, (allItems) => {
    if (allItems) {
      return allItems.find((item) => {
        return findIndexAtArray(allItems, item) === topicIndex;
      });
    } else {
      return;
    }
  });

export const selectQuestionsByTopicIndex = (topicIndex) =>
  createSelector(selectTopicsByIndex(topicIndex), (allItems) => {
    if (allItems) {
      return allItems['Questions'];
    } else {
      return;
    }
  });

export const selectQuestionsByID = (ID, topicIndex) =>
  createSelector(selectQuestionsByTopicIndex(topicIndex), (allItems) => {
    if (allItems) {
      return allItems.find((item) => {
        return item.id === ID;
      });
    } else {
      return;
    }
  });
