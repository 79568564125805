import { Observable, Subscription } from 'rxjs';
import { UserSend } from 'src/app/data/models/request-apis/User-request';
import {
  ChangeDetectionStrategy,
  Component, 
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { User } from 'src/app/data/models/User';
import { displayedUserColumns } from 'src/app/utils/config/questionnaire-table.configuration';
import { UsersStoreService } from 'src/app/Store/store-services/users-store.service';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { SpinnerModule } from '../spinner/spinner.component';
import { PipeModule } from 'src/app/modules/pipes.module';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, OnDestroy {
  @Input()
  inProfile = false;

  @Input()
  userAlreadyAssigned: User[] = [];

  @Input()
  selectedUsers: User[] = [];

  @Input()
  mode: Boolean = true;

  @Input()
  singleSelection = false;

  @Input()
  questionnaireOwner: string;

  @Input()
  displayedColumns = displayedUserColumns;

  @Input()
  usedForChangeOwnerProject: boolean = false;

  @Input()
  usedForWatchlist = false;

  @Input()
  watchListFullUsers: User[];

  @Output()
  userToBeAssigned = new EventEmitter<User>();

  @Output()
  deleteUserOut = new EventEmitter<{}>();

  @Output()
  reactivateUserOut = new EventEmitter<{}>();

  @Output()
  updateUserOut = new EventEmitter<UserSend>();

  @Output()
  changePasswordOut = new EventEmitter<string>();

  @Output()
  paginatorData = new EventEmitter<{ pageIndex: number; pageSize: number }>();

  @Output()
  numChecked = new EventEmitter<number>();

  numUsersChecked = 0;

  sourceData: Observable<User[]>;

  length: Observable<number>;

  filteredValues = {
    Name: '',
    Surname: '',
  };

  nameFilter = new FormControl();
  surnameFilter = new FormControl();
  roleFilter = new FormControl();

  showFilter = false;

  debounceTime = 1000;

  pageIndexUser = 0;
  pageSizeUser = 10;

  @Input() disabled: boolean;

  loadingUsers: Observable<boolean>;

  pageIndex: Observable<number>;

  usersSelectedBeforeSent: string[] = [];

  utentiPage = false;
  questPage = false;

  lang = '';

  langToUnsubscribe: Subscription;
  nameFilterSub: Subscription;

  constructor(private userStore: UsersStoreService, private router: Router, private store: Store) {}

  ngOnInit() {
    if (this.router.url === '/profile') {
      this.utentiPage = true;
    }
    if (this.router.url.includes('/answer-questionnaire')) {
      this.questPage = true;
    }

    let excludedUsers = this.selectedUsers;
    this.usersSelectedBeforeSent = [];
    if (this.usedForWatchlist) {
      excludedUsers = [{ username: this.questionnaireOwner }];
      this.selectedUsers.forEach((username) => {
        excludedUsers.push({ username: username as string });
      });

      this.selectedUsers.forEach((username) => {
        this.usersSelectedBeforeSent.push(username as string);
      });
    }
    this.getNewUsers(
      {
        pageIndex: this.pageIndexUser,
        pageSize: this.pageSizeUser,
      },
      excludedUsers,
      this.disabled
    );
    this.length = this.userStore.getUsersLength();
    this.loadingUsers = this.userStore.getLoadingUsers();

    this.sourceData = this.userStore.getUsersPaginated();
    this.nameFilterSub = this.nameFilter.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe((res) => {
      this.userStore.searchUser(res, this.selectedUsers, this.usedForChangeOwnerProject, this.disabled);
    });

    this.pageIndex = this.userStore.getUsersPageIndex();

    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
    });
  }

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
    this.nameFilterSub.unsubscribe();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  getRecord(element?: User, checkValue?: boolean) {
    if (checkValue) {
      this.numUsersChecked++;
    } else {
      this.numUsersChecked--;
    }
    this.numChecked.emit(this.numUsersChecked);

    if (this.singleSelection) {
      if (!!this.userAlreadyAssigned[0] && this.userAlreadyAssigned[0].username !== element.username) {
        this.usersSelectedBeforeSent = [element.username];
        this.userToBeAssigned.emit(element);
      } else {
        if (this.userAlreadyAssigned.length === 0) {
          this.usersSelectedBeforeSent = [element.username];
          this.userToBeAssigned.emit(element);
        } else {
          this.userToBeAssigned.emit(null);
        }
      }
    } else if (!this.singleSelection) {
      this.usersSelectedBeforeSent.push(element.username);
      this.userToBeAssigned.emit(element);
    }
  }

  updateUser(user: UserSend) {
    this.updateUserOut.emit(user);
  }

  deleteUser(user: UserSend) {
    console.log('disabled==', this.disabled);

    this.deleteUserOut.emit({
      user: user,
      pageIndex: this.pageIndexUser,
      pageSize: this.pageSizeUser,
      disabled: this.disabled,
    });
  }

  reactivateUser(user: UserSend) {
    this.reactivateUserOut.emit({
      user: user,
      pageIndex: this.pageIndexUser,
      pageSize: this.pageSizeUser,
      disabled: this.disabled,
    });
  }

  changePassword(user: UserSend) {
    this.changePasswordOut.emit(user.username);
  }

  changePage(event) {
    this.pageIndexUser = event.pageIndex;
    this.pageSizeUser = event.pageSize;
    this.getNewUsers(
      {
        pageIndex: this.pageIndexUser,
        pageSize: this.pageSizeUser,
      },
      this.selectedUsers,
      this.disabled
    );
    this.paginatorData.emit({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  getNewUsers(event: { pageIndex: number; pageSize: number }, selectedUsers: User[], disabled: boolean) {
    this.pageSizeUser = event.pageSize;
    this.pageIndexUser = event.pageIndex;
    this.selectedUsers = selectedUsers;
    this.disabled = disabled;
    this.userStore.dispatchUsersPaginated(
      this.usedForChangeOwnerProject,
      this.pageIndexUser,
      this.pageSizeUser,
      this.selectedUsers,
      this.disabled
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    SpinnerModule,
    FormsModule,
    PipeModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [UsersTableComponent],
  exports: [UsersTableComponent],
})
export class UsersTableModule {}
