import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocufileState, Status } from './upload-file.state';

export const selectUploadFileFeatureState = createFeatureSelector<DocufileState>('uploadFile');

export const selectUploadFileReady = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.status === Status.Ready
);

export const selectUploadFileError = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.error
);

export const selectUploadFileProgress = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.progress
);

export const selectUploadFileFailed = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.status === Status.Failed
);

export const selectUploadFileCompleted = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.status === Status.Completed
);

export const selectUploadFileInProgress = createSelector(selectUploadFileFeatureState, (state): boolean => {
  return state.status === Status.Requested && state.progress !== null && state.progress >= 0;
});

export const selectStoredFiles = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.getFiles.files
);

export const selectIsFileStored = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.getFiles.getStatus === Status.Completed && state.getFiles.files.length > 0
);

export const selectIsFileLoading = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.getFiles.filesLoading
);

export const selectIsFileDeleted = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.deleteFile === Status.Completed
);

export const selectIsFileUploading = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.filesUploading
);

export const selectIsFileDownloading = createSelector(
  selectUploadFileFeatureState,
  (state: DocufileState) => state.download === Status.Requested
);

export const selectSingleFile = (questionId: string) =>
  createSelector(
    selectUploadFileFeatureState,
    (state: DocufileState) =>
      state.getFiles.files &&
      state.getFiles.files.length &&
      state.getFiles.files.filter((file) => file.FK_QUESTION === questionId)
  );
