import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { QuestionDetailsModalComponent } from '../components/analytics-panel/analytics-panel-details/question-details-modal/question-details-modal.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { DomandaVincolataDialogComponent } from '../components/domanda-vincolata-dialog/domanda-vincolata-dialog.component';
import { FileRepositoryComponent } from '../components/file-repository/file-repository.component';
import { InfoDialogComponent } from '../components/info-dialog/info-dialog.component';
import { ListBoxComponentComponent } from '../components/list-box-component/list-box-component.component';
import { NotesComponent } from '../components/notes/notes.component';
import { ElencoTopic } from '../data/models/ElencoTopic';
import { Question } from '../data/models/Question';
import { QuestionnairesAssigned } from '../data/models/QuestionnairesAssigned';
import { TopicsAnswered } from '../data/models/TopicsAnswered';
import { User } from '../data/models/User';
import { HomeDialogComponent } from '../features/home-page/home-dialog/home-dialog.component';
import { ChangePasswordComponent } from '../features/profile/change-password/change-password/change-password.component';
import { CreateNewTopicComponent } from '../features/questionnaire-creation/create-new-topic/create-new-topic.component';
import { NewQuestionModalComponent } from '../features/questionnaire-creation/questions/new-question-modal/new-question-modal.component';
import { CloneProjectOptionsComponent } from '../features/questionnaire-management/clone-project-options/clone-project-options.component';
import { EditProjectComponent } from '../features/questionnaire-management/edit-project/edit-project.component';
import { ReAssignTopicComponent } from '../features/questionnaire-to-answer/re-assign-topic/re-assign-topic.component';
import { AddQuestionInterface } from './config/addQuestion.config';

@Injectable({
  providedIn: 'root',
})
export class GeneralDialogService {
  modifyDialog: MatDialogRef<CreateNewTopicComponent>;

  confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

  infoDialog: MatDialogRef<InfoDialogComponent>;

  addQuestionDialog: MatDialogRef<NewQuestionModalComponent>;

  editProjectModal: MatDialogRef<EditProjectComponent>;

  listBoxModal: MatDialogRef<ListBoxComponentComponent>;

  domanDaVincoModal: MatDialogRef<DomandaVincolataDialogComponent>;

  reAssingCompiledTopic: MatDialogRef<ReAssignTopicComponent>;

  notesModal: MatDialogRef<NotesComponent>;

  homeModal: MatDialogRef<HomeDialogComponent>;

  cloneProjectModal: MatDialogRef<CloneProjectOptionsComponent>;

  changePasswordModal: MatDialogRef<ChangePasswordComponent>;

  fileRepoModal: MatDialogRef<FileRepositoryComponent>;

  analyticsQuestionDetailsModal: MatDialogRef<QuestionDetailsModalComponent>;

  constructor(public dialog: MatDialog) {}

  topicCRUDdialog(
    dialogData: {
      Topic?: ElencoTopic | TopicsAnswered;
      length?: number;
      index?: number;
      Action?: string;
      assignedMode: boolean;
      users?: User[];
      displayedUserColumns?: string[];
      modalTitle?: string;
      Quest_Title?: string;
      Quest_ID?: string;
      Quest_Assing_ID?: string;
      Quest_Exp_Date?: string;
    },
    dialogService?: GeneralDialogService
  ) {
    dialogService = this;
    this.modifyDialog = this.dialog.open(CreateNewTopicComponent, {
      data: { dialogData, dialogService },
      disableClose: true,
    });
  }

  topicCRUDdialogResponse(): Observable<any> {
    return this.modifyDialog.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openNotesModal(data: {
    notes: Observable<string[]>;
    Project_Title?: string;
    Quest_Assing_ID?: string;
    Topic_Assign_Id?: string;
    pageIndex: number;
    Status: string;
    user?: string;
    loadingNotes?: Observable<boolean>;
  }) {
    this.notesModal = this.dialog.open(NotesComponent, {
      data,
      disableClose: true,
    });
  }

  openNotificationUnfinishedResponsiblesModal(data: {
    notes: Observable<string[]>;
    Project_Title?: string;
    Quest_Assing_ID?: string;
    Topic_Assign_Id?: string;
    pageIndex: number;
    Status: string;
    user?: string;
    loadingNotes?: Observable<boolean>;
  }) {
    this.notesModal = this.dialog.open(NotesComponent, {
      data,
      disableClose: true,
    });
  }

  openFileRepoModal(data: { Quest_Assing_Id?: string; Quest_Template_Id?: string; Quest_Assign_Title?: string }) {
    this.fileRepoModal = this.dialog.open(FileRepositoryComponent, {
      data,
      disableClose: true,
      panelClass: 'fileRepo-modalbox',
    });
  }

  openNotesModalResponse() {
    return this.notesModal.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  cloneProjectDialog(projectId: string, totalUsers: number) {
    this.cloneProjectModal = this.dialog.open(CloneProjectOptionsComponent, {
      data: { projectId, totalUsers },
      disableClose: true,
    });
  }

  cloneProjectDialogResponse() {
    return this.cloneProjectModal.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  changePasswordDialog(username: string) {
    this.changePasswordModal = this.dialog.open(ChangePasswordComponent, {
      data: { username },
      disableClose: true,
    });
  }

  changePasswordDialogResponse() {
    return this.changePasswordModal.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openInfoDialog(data: { Info?: string; oldValue?: string; newValue?: string; disableClose?: boolean }) {
    this.infoDialog = this.dialog.open(InfoDialogComponent, {
      data,
      disableClose: data.disableClose,
      width: '40%',
      height: 'auto',
    });
  }

  infoDialogResponse(): Observable<any> {
    return this.infoDialog.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openConfirmDialog(data: { question?: Question; info: string; topic?: ElencoTopic; message?: string, length?:number}) {
    this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data,
    });
  }

  openConfirmDialogResponse(): Observable<any> {
    return this.confirmDialog.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openAddQuestionDialog(data: AddQuestionInterface) {
    data.dialogService = this;
    this.addQuestionDialog = this.dialog.open(NewQuestionModalComponent, {
      data,
      disableClose: true,
    });
  }

  addQuestionDialogResponse(): Observable<any> {
    return this.addQuestionDialog.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openQuestionDetails(data: {title: string, descriptions: string, zipName?: string, details: any, questionType: string, option?: any}) {
    this.analyticsQuestionDetailsModal = this.dialog.open(QuestionDetailsModalComponent, {
      data,
      disableClose: false,
      panelClass: 'question-details-modal',
    });
  }

  openEditProject(
    data: User[],
    existingQuest?: QuestionnairesAssigned,
    singleSelection?: boolean,
    selectedUsers?: User[]
  ) {
    let dialogService = this;
    this.editProjectModal = this.dialog.open(EditProjectComponent, {
      disableClose: true,
      data: {
        users: data,
        activeQuestionnaire: existingQuest,
        singleSelection: singleSelection,
        dialogService: dialogService,
        selectedUsers: selectedUsers,
      },
    });
  }

  editProjectResponse(): Observable<any> {
    return this.editProjectModal.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openListBoxModal(data: {
    AnswerOptions: string[];
    TypeOfAnswer: string;
    action: string;
    index?: number;
    option?: number;
  }) {
    this.listBoxModal = this.dialog.open(ListBoxComponentComponent, {
      disableClose: true,
      data,
    });
  }

  listBoxReturn() {
    return this.listBoxModal.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openDomandaVincolada(data: {
    questionToBeModified?: Question;
    selectedQuestion?: Question | string;
    selectedOptions?: string[];
    questions: Question[];
    action: string;
    currentQuestion?: Question;
    // freeTextConstraint?: boolean;
  }) {
    this.domanDaVincoModal = this.dialog.open(DomandaVincolataDialogComponent, {
      disableClose: true,
      data,
    });
  }

  DomandaVincoladaReturn() {
    return this.domanDaVincoModal.afterClosed().pipe(
      take(1),
      map((result) => {
        return this.resultOrFalse(result);
      })
    );
  }

  openReAssignTopic(data: any) {
    this.reAssingCompiledTopic = this.dialog.open(ReAssignTopicComponent, {
      disableClose: true,
      data,
    });
  }

  openReAssignTopicReturn() {
    return this.reAssingCompiledTopic.afterClosed().pipe(
      take(1),
      map((result) => {
        return result;
      })
    );
  }

  openHomePopUpReturn() {
    return this.homeModal.afterClosed().pipe(
      take(1),
      map((result) => {
        if (!!result) {
          return result;
        } else {
          return '';
        }
      })
    );
  }

  openHomePopUp(data: string) {
    this.homeModal = this.dialog.open(HomeDialogComponent, {
      disableClose: true,
      data,
    });
  }

  private resultOrFalse(result): any {
    if (!!result && result !== true) {
      return result;
    }
    return false;
  }
}
