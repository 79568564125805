<div class="relative overflow-hidden">
  <div class="flex items-center">
    <h1 *ngIf="utentiPage" class="absolute text-4xl text-dialog">
      {{ "USER_TABLE.TITLE" | translate }}
    </h1>
    <form [formGroup]="" class="w-[40vw] h-[fit-content] mx-auto">
      <mat-form-field class="search-input-rounded w-full !text-[0.8rem]" appearance="outline">
        <mat-icon class="relative" matPrefix>search</mat-icon>
        <input
          class="!text-[1.5rem]"
          type="search"
          matInput
          placeholder="{{ 'USER_TABLE.SEARCH' | translate }}"
          [formControl]="nameFilter"
          value=""
        />
      </mat-form-field>
    </form>
  </div>

  <div
    class="h-[50vh] overflow-auto mt-6"
    [ngClass]="{
      'flex flex-col justify-center items-center': (loadingUsers | async),
      'sm:!h-[40vh] lg:!h-[53vh] 2xl:!h-[68vh] lg:min-h-[27rem]': utentiPage,
      '!h-[40vh] 2xl:!h-[55vh]': questPage
    }"
  >
    <table
      *ngIf="!(loadingUsers | async)"
      class="h-full w-full overflow-hidden rounded-t-xl custom__table"
      [ngClass]="{ management__table: utentiPage }"
      mat-table
      [dataSource]="sourceData | async | addFullWatchListUsers: usedForWatchlist:watchListFullUsers"
      multiTemplateDataRows
      [attr.colspan]="displayedColumns.length"
    >
      <ng-container *ngFor="let column of displayedColumns">
        <ng-container *ngIf="column === 'Checkbox'" matColumnDef="Checkbox">
          <th mat-header-cell *matHeaderCellDef class="!border-b-0 !bg-dialog !text-center w-[10%]"></th>
          <ng-container *ngIf="singleSelection">
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="row !text-2xl">
              <mat-radio-button
                oninput="event.stopPropagation();"
                (click)="getRecord(element, i)"
                [checked]="usersSelectedBeforeSent.includes(element.username)"
                (change)="(i)"
              ></mat-radio-button>
            </td>
          </ng-container>
          <ng-container *ngIf="!singleSelection && userAlreadyAssigned.length > 0">
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="row !text-2xl">
              <mat-checkbox
                (change)="getRecord(element, $event.checked)"
                [checked]="usersSelectedBeforeSent.includes(element.username)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container *ngIf="!singleSelection">
            <td mat-cell *matCellDef="let element; let i = dataIndex" class="row !text-base">
              <mat-checkbox
                (change)="getRecord(element, $event.checked)"
                [value]="element"
                [checked]="usersSelectedBeforeSent.includes(element.username)"
              ></mat-checkbox>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="column !== 'Checkbox' && column !== 'Actions'" matColumnDef="{{ column }}">
          <th
            class="!font-bold !sticky !text-2xl !text-center !text-white !bg-dialog !border-b-0 w-[20%] h-[3.1rem]"
            mat-header-cell
            *matHeaderCellDef
          >
            <span *ngIf="column === 'Name'">{{ "USER_TABLE.NAME" | translate }}</span>
            <span *ngIf="column === 'Surname'">{{ "USER_TABLE.SURNAME" | translate }}</span>
            <span *ngIf="column === 'Email'">{{ "USER_TABLE.EMAIL" | translate }}</span>
            <span *ngIf="column === 'active'">Status</span>
          </th>
          <td mat-cell *matCellDef="let element; let i = dataIndex" class="row !text-lg">
            <div *ngIf="element[column] === true" class="active py-2">
              {{ "USER_TABLE.ACTIVE" | translate }}
            </div>
            <div *ngIf="element[column] === false" class="disabled py-2">
              {{ "USER_TABLE.DISABLED" | translate }}
            </div>
            <div *ngIf="element[column] !== true && element[column] !== false">
              {{ element[column] }}
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="column === 'Actions'" matColumnDef="{{ column }}">
          <th
            class="!font-bold !sticky !text-2xl !text-center !text-white !bg-dialog !border-b-0"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ "USER_TABLE.ACTIONS" | translate }}
          </th>
          <td mat-cell *matCellDef="let element; let i = dataIndex" class="row !text-lg">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon
                class="!text-dialog"
                matTooltip="{{ 'USER_TABLE.ACTIONS' | translate }}"
                matTooltipClass="custom-tooltip"
                >more_vert</mat-icon
              >
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="updateUser(element)" mat-menu-item>
                <mat-icon>launch</mat-icon>
                <span>{{ "USER_TABLE.ACTION_UPDATE" | translate }}</span>
              </button>
              <button *ngIf="!element.sso" (click)="changePassword(element)" mat-menu-item>
                <mat-icon>launch</mat-icon>
                <span>{{ "USER_TABLE.ACTION_CHANGE" | translate }}</span>
              </button>
              <button *ngIf="element['active'] === true" (click)="deleteUser(element)" mat-menu-item>
                <mat-icon>visibility_off</mat-icon>
                <span>{{ "USER_TABLE.ACTION_DISABLE" | translate }}</span>
              </button>
              <button *ngIf="element['active'] === false" (click)="reactivateUser(element)" mat-menu-item>
                <mat-icon>visibility</mat-icon>
                <span>{{ "USER_TABLE.REACTIVATE_BUTTON" | translate }}</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="!h-[6vh] !text-center"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns; let index = i" class="!h-16"></tr>
    </table>
    <div *ngIf="loadingUsers | async">
      <app-spinner class="flex justify-center" [width]="80"></app-spinner>
    </div>
  </div>
  <mat-paginator
    [length]="length | async"
    (page)="changePage($event)"
    [pageIndex]="pageIndex | async"
    class="mat-paginator-sticky"
    [pageSize]="10"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>
