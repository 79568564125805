<div class="grid h-full grid-rows-[min-content,auto,min-content] gap-3 rounded-3xl bg-white p-5 shadow-md">
  <h1 class="row-start-1 text-xl font-semibold text-dialog">
    {{ topics.length }} {{ "DICTIONARY.Topic" | translate }}
  </h1>

  <div class="row-start-2 flex flex-col gap-3 overflow-auto" cdkDropList (cdkDropListDropped)="drop($event)">
    <div
      cdkDrag
      [cdkDragDisabled]="Model_Status === 'Archived'"
      *ngFor="let topic of topics; let i = index"
      (click)="showTopicData(topic, i)"
      class="mx-3 flex cursor-pointer content-between items-center rounded-lg bg-bgTopic p-3 text-topicLetter"
      [ngClass]="{
        activeTopic: selectedTopic.Title === topic.Title
      }"
    >
      <h3
        class="w-10/12 overflow-hidden overflow-ellipsis whitespace-nowrap break-words text-xl"
        appShowIfTruncated
        matTooltip="{{ topic.Title }}"
      >
        {{ (i < 9 ? "00" : i < 99 ? "0" : "") + (i + 1) + " - " }}{{ topic.Title }}
      </h3>

      <div class="flex content-between">
        <button cdkDragHandle mat-icon-button matTooltip="Drag & Drop">
          <mat-icon>drag_handle</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="Model_Status !== 'Completed'"
          [matMenuTriggerFor]="menu"
          id="actions_button"
          matTooltip="Menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="Model_Status === 'Completed'"
          (click)="modifyTopic(topic, i)"
          matTooltip="{{ 'QUEST_CREATION.TOOL_EDIT' | translate }}"
        >
          <mat-icon>edit</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button
            *ngIf="Model_Status === 'Archived'"
            (click)="modifyTopic(topic, i)"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>launch</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_OPEN" | translate }}</span>
          </button>
          <button
            *ngIf="Model_Status !== 'Archived'"
            (click)="modifyTopic(topic, i)"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>edit</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_EDIT" | translate }}</span>
          </button>
          <button
            (click)="deleteTopic(topic, i)"
            *ngIf="topics.length > 1 && Model_Status !== 'Archived'"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>delete</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_DELETE" | translate }}</span>
          </button>
          <button
            (click)="duplicateTopic(topic, i)"
            *ngIf="Model_Status !== 'Archived'"
            class="flex items-center"
            mat-menu-item
          >
            <mat-icon>content_copy</mat-icon>
            <span>{{ "QUEST_CREATION.TOOL_CLONE" | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="row-start-3 flex items-end justify-end">
    <button
      (click)="createTopic()"
      class="colorBtn"
      *ngIf="ReadOnlyMode === false && Model_Status !== 'Completed' && Model_Status !== 'Archived'"
    >
      {{ "QUEST_CREATION.ADD" | translate | uppercase }}
      {{ "DICTIONARY.Topic" | translate | uppercase }}
    </button>
  </div>
</div>
