import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ability } from 'src/app/data/models/abilities';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = new Subject<boolean>();

  abilities: Ability[];

  token: string;

  user: string;

  constructor(private store: Store) {}

  public isAuthenticated(): boolean {
    if (this.token) {
      return true;
    }
    return false;
  }

  authenticatedAsObservable() {
    return this.isLoggedIn;
  }

  public setUserInfo(user: any) {
    this.abilities = user.abilities;
    this.token = user.token;
    this.user = user.user;

    this.isLoggedIn.next(true);
  }

  getUserAbilities() {
    return this.abilities;
  }

  public getUserToken() {
    return this.token;
  }

  getUserName() {
    return this.user;
  }

  public logout() {
    document.cookie = 'user=';
    document.cookie = 'abilities=';
    document.cookie = 'token=';
    this.abilities = undefined;
    this.token = undefined;
    this.user = undefined;
  }

  checkAuthorization(ability: object) {
    let userAbilities = this.getUserAbilities();

    if (userAbilities)
      for (let uAbility of userAbilities) {
        if (uAbility['Ability'] === ability['Ability']) {
          return true;
        }
      }
    return false;
  }
}
