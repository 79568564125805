import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-title-of-questionnaire',
  templateUrl: './title-of-questionnaire.component.html',
  styleUrls: ['./title-of-questionnaire.component.scss'],
})
export class TitleOfQuestionnaireComponent {
  @Input()
  inputForm: FormGroup;
}
