import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { QuestionnairesStructure } from '../../models/QuestionnairesStructure';
import { Answers } from '../../models/request-apis/Answers';
import { updateAssignmentData } from '../../models/request-apis/updateAssignmentData';
import { createNotes } from '../../models/request-apis/createNotes';
import { assignQuestionnaireRequest } from '../../models/request-apis/assignQuestionnaireRequest';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostQuestionnaireService {
  constructor(private httpClient: HttpClient) {}

  public createQuestionnaire(quest: QuestionnairesStructure, clone?: boolean) {
    if (clone) {
      quest.Clone = clone;
    }
    const body = quest;
    return this.httpClient.post('createQuestionnaire', body);
  }

  public updateQuestionnaire(obj: QuestionnairesStructure) {
    const body: any = obj;
    return this.httpClient.post('updateQuestionnaire', body);
  }

  public assignQuestionnaires(value: { project: assignQuestionnaireRequest; flag: boolean }) {
    const body: any = value;
    return this.httpClient.post('assignQuestionnaire', body);
  }

  public updateAssignQuestionnaires(data: { obj: updateAssignmentData; flag: boolean }) {
    return this.httpClient.post('updateAssignment', data);
  }

  public archiveQuestionnaire(body: string[]) {
    return this.httpClient.post('archiveQuestionnaire', body);
  }

  public changeQuestionnaireStatus(body: any[]) {
    return this.httpClient.post('changeQuestionnaireStatus', body);
  }

  public deleteQuestionnaire(ids: { id: string }[]) {
    return this.httpClient.post('deleteQuestionnaire', ids);
  }

  public deleteAssignedQuestionnaire(ids: { id: string }[]) {
    return this.httpClient.post('deleteAssignment', ids);
  }

  public postAnswers(value: { data: Answers; status: string }) {
    return this.httpClient.post('submitAnswers', {
      data: value.data,
      status: value.status,
    });
  }

  public submitQuestionnaires(body: { id: string }[]) {
    return this.httpClient.post('submitAssignment', body);
  }

  public submitChangeProjectOwner(body: { projectId: string; newUser: string }) {
    return this.httpClient.post('changeResponsibleOfProject', body);
  }

  public createNotes(data: createNotes) {
    return this.httpClient.post('createNote', data);
  }

  public readNotes(body: { Assigned_id: string }) {
    return this.httpClient.post('readNotes', body);
  }

  public exportQuestionnaire(id: string) {
    return this.httpClient.get(`exportQuestionnaire?questId=${id}`);
  }

  public updateTopicsUser(body: { assignedId: string; topicAssignedIds: { Assigned_id: string }[]; note: string }) {
    return this.httpClient.post('updateTopicsUser', body);
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', 'upload', formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.httpClient.request(req);
  }

  getFiles(): Observable<any> {
    return this.httpClient.get('files');
  }
}
