import { QuestionnairesAssigned } from 'src/app/data/models/QuestionnairesAssigned';
import { QuestionnairesAssignedToUser } from 'src/app/data/models/QuestionnairesAssignedToUser';

export interface StateAssigned {
  AssignedQuestionnaires: QuestionnairesAssigned[];
  AssignedQuestionnairesToUser?: { [id: string]: QuestionnairesAssignedToUser };
  topicMetrics: {
    allTopics: number;
    completedTopics: number;
    completedTopicsOfUser: number;
    topicsOfUser: number;
  };
  questionnaireToBeAnswered?: QuestionnairesAssignedToUser;
  submittingAnswers?: boolean;
  TopicId?: string;
  loadingAssigned?: boolean;
  loadingFileRepo?: boolean;
  homeUser?: string;
  temporaryAnswers?: any[];
  Notes?: string[];
  loadingNotes?: boolean;
  pageIndex?: number;
  pageSizeValue?: number;
  sortColValue?: string;
  total?: number;
  sortOrderValue?: string;
  idsSubmitted?: string[];
  containsFile?: boolean;
  error: boolean;
}

export const initialStateAssigned: StateAssigned = {
  AssignedQuestionnaires: [],
  AssignedQuestionnairesToUser: {},
  topicMetrics: {
    allTopics: 0,
    completedTopics: 0,
    completedTopicsOfUser: 0,
    topicsOfUser: 0,
  },
  questionnaireToBeAnswered: null,
  loadingAssigned: false,
  loadingFileRepo: false,
  TopicId: null,
  homeUser: null,
  temporaryAnswers: [],
  Notes: [],
  loadingNotes: false,
  total: 0,
  pageIndex: 0,
  pageSizeValue: 10,
  sortColValue: '',
  sortOrderValue: '',
  idsSubmitted: [],
  submittingAnswers: null,
  containsFile: false,
  error: false,
};
