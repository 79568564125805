import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { addQuestionnaires, updateQuestionnaires } from '../questionnaire-store/state.actions';
import { selectLoading } from '../questionnaire-store/state.selectors';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireStoreService {
  constructor(private store: Store) {}

  addQuestionnaire(newQuestionnaire: QuestionnairesStructure) {
    this.store.dispatch(addQuestionnaires({ newQuestionnaire }));
  }

  updateQuestionnaire(modifiedQuestionnaire: QuestionnairesStructure) {
    this.store.dispatch(updateQuestionnaires({ modifiedQuestionnaire }));
  }

  selectLoadingOfQuestionnnaires(): Observable<boolean> {
    return this.store.select(selectLoading);
  }
}
