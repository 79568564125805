import { first, map, skip, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Answers } from 'src/app/data/models/request-apis/Answers';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import {
  errorHandled,
  postAnswersOfQuestionnaire,
  getQuestionnaireToBeAnswered,
  SubmitQuestionnaires,
} from 'src/app/Store/assignedQuestionnaires/actions/assigned-questionnaires.actions';
import {
  isQuestionnaireSubmitted,
  selectAnwerQuestionnairedTopicMetrics,
  selectError,
  seletOneQuestionnairFilterUser,
} from 'src/app/Store/assignedQuestionnaires/selectors/assigned-questionnaires.selectors';
import { updateTopicsUser } from 'src/app/Store/topics-store/topic.actions';
import { selectTopicsLoading } from 'src/app/Store/topics-store/topics.selectors';
import { selectUsersFullname } from 'src/app/Store/users/users.selectors';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { QuestionFormComponent } from './question-form/question-form.component';
import { QuestionnairesAssignedToUser } from 'src/app/data/models/QuestionnairesAssignedToUser';
import { toastAnimation } from 'src/app/utils/animations/toast-animation';
import { Clipboard } from '@angular/cdk/clipboard';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { VerbPronounDictionaryPipe } from 'src/app/utils/pipes/verb-pronoun-dictionary.pipe';
import { PrepositionDictionaryPipe } from 'src/app/utils/pipes/preposition-dictionary.pipe';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { DocufileUplaodService } from '../administrative/service/docufileUpload.service';

import * as fromFileUploadActions from 'src/app/Store/upload-file-store/upload-file.actions';
import * as fromFileUploadSelectors from 'src/app/Store/upload-file-store/upload-file.selectors';
import { closeProjectAction, getSingleProject } from 'src/app/Store/projects/actions/projects.actions';
import { selectProjectToBeModified } from 'src/app/Store/projects/selectors/projects.selectors';

@Component({
  selector: 'app-questionnaire-to-answer',
  templateUrl: './questionnaire-to-answer.component.html',
  styleUrls: ['./questionnaire-to-answer.component.scss'],
  animations: [toastAnimation],
})
export class QuestionnaireToAnswerComponent implements OnInit, OnDestroy {
  @ViewChild(QuestionFormComponent, { static: false })
  childForm: QuestionFormComponent;

  private ngUnsubscribe = new Subject();

  toggleAnimation = 'hide';

  isQuestionnaireSubmitted: Observable<boolean>;
  submitterOrError: Observable<[boolean, boolean]>;
  isSubmitted = false;

  usersFullNameObs: Observable<{}>;
  getUserFullName = {};
  saveAnswersPressed = false;
  topicsAssignedToUser = 0;
  TopicsCompletedByUser = 0;
  completedTopics = 0;
  allTopics = 0;

  questionnaireToAnswer: Observable<QuestionnairesAssignedToUser>;
  questionnaireAccesibleInComponent: QuestionnairesAssignedToUser;

  currentUser: string;
  loadingTopic: Observable<boolean>;

  wordNext = '';
  verbPipe = new VerbPronounDictionaryPipe();
  prepPipe = new PrepositionDictionaryPipe();

  submitionFlag:
    | 'GoHome'
    | 'GoNextOrPrev'
    | 'GoSpecific'
    | 'justSaveAndContinue'
    | 'whatToDoModal'
    | 'SubmitQuestionnaire'
    | 'notResponsibleAndfinishedWithOneTopic';

  temporaryIndex = undefined;

  unsaved = false;

  firstTime = true;

  goToTopiButtonPressed = false;

  formvalidity = true;

  activeTopicIndex = 0;

  quest_id;

  attentzione = false;

  backbuttonPressed = false;

  showObligatoryQuestionsNotCompleted = false;
  nextOrPrevPressed = 0;

  goHomeFromAnnula = false;

  clearAns: boolean = false;
  annullaIsPressed: boolean = false;

  typeOfDialog: string;
  isWhatToDoModalOpen = false;

  uncompletedQuestionsIndexes = [];
  saveTooltip = ``;
  saveTooltipENG = ``;
  cancelTooltip = ``;
  cancelTooltipENG = '';
  topicMetrics: Observable<{
    allTopics: number;
    completedTopics: number;
    completedTopicsOfUser: number;
    topicsOfUser: number;
  }>;
  amIDone = false;
  obs: Observable<
    [
      QuestionnairesAssignedToUser,
      {
        allTopics: number;
        completedTopics: number;
        completedTopicsOfUser: number;
        topicsOfUser: number;
      },
      boolean,
      {}
    ]
  >;

  lang = '';
  topicName = '';
  topicNameENG = '';
  singleTopicName = '';
  singleTopicNameENG = '';

  appSvgTheme = 0;

  fetchedFiles: any[] = [];
  deletedFiles: any[] = [];
  msg = '';

  isUploadCompleted$: Observable<boolean>;
  progress$: Observable<number | null>;
  isInProgress$: Observable<boolean>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: GeneralDialogService,
    private clipboard: Clipboard,
    private activeRoute: ActivatedRoute,
    private store: Store,
    private magicStrings: MagicStringsService,
    private _docuFileService: DocufileUplaodService
  ) {}

  ngOnInit(): void {
    this.appSvgTheme = +this.magicStrings.IdTheme;
    this.topicName = this.magicStrings.Topic;
    this.topicNameENG = this.magicStrings.TopicENG;
    this.singleTopicName = this.magicStrings.SingleTopic;
    this.singleTopicNameENG = this.magicStrings.SingleTopicENG;
    this.quest_id = this.activeRoute.snapshot.paramMap.get('questionnaireId');
    this.currentUser = this.authService.getUserName();
    this.initialize();
    this.reloadData();

    this.wordNext = 'SUCCESSIVA ' + this.magicStrings.SingleTopic;

    this.store
      .select(selectLanguage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((lang) => {
        this.lang = lang;
      });

    this.cancelTooltip =
      'Annulla le modifiche effettuate ' +
      this.prepWord('su ' + this.singleTopicName, 'singular') +
      " e ripristina l'ultima versione salvata";
    this.cancelTooltipENG =
      'Undo changes made on the ' + this.singleTopicNameENG.toLowerCase() + ' and restore the last saved version';

    this.saveTooltip =
      'Salva le modifiche effettuate ' +
      this.prepWord('su ' + this.singleTopicName, 'singular') +
      ' e passa ' +
      this.prepWord('a ' + this.verbWord('prossimo ' + this.singleTopicName, 'singular'), 'singular') +
      ' con stato diverso da completato';
    this.saveTooltipENG =
      'Save changes made on the ' +
      this.singleTopicNameENG.toLowerCase() +
      ' and go to the next with a status other than completed';

    //Files Handling
    this.checkFetchedFiles();
    this.checkDeletedFiles();
    this.getStoredFiles();
  }

  initialize() {
    this.topicMetrics = this.store.select(selectAnwerQuestionnairedTopicMetrics).pipe(
      skip(1),
      map((topicMetrics) => {
        return topicMetrics;
      })
    );
    this.loadingTopic = this.store.select(selectTopicsLoading).pipe(
      map((val) => {
        return val;
      })
    );
    this.usersFullNameObs = this.store.select(selectUsersFullname).pipe(
      map((val) => {
        return val;
      })
    );

    this.submitterOrError = combineLatest([
      this.store.select(isQuestionnaireSubmitted),
      this.store.select(selectError),
    ]).pipe(
      map((value) => {
        if (this.saveAnswersPressed || this.firstTime) {
          this.handleErrorOrSubmittion(value);
        }
        return value;
      })
    );
    this.questionnaireToAnswer = this.store.select(seletOneQuestionnairFilterUser, { user: this.currentUser }).pipe(
      map((value) => {
        return value;
      })
    );

    this.obs = combineLatest([
      this.questionnaireToAnswer,
      this.topicMetrics,
      this.loadingTopic,
      this.usersFullNameObs,
    ]).pipe(
      map((value) => {
        this.handlingLoadingTopics(value[2]);
        this.getUserFullName = value[3];
        this.TopicsCompletedByUser = value[1].completedTopicsOfUser;
        this.topicsAssignedToUser = value[1].topicsOfUser;
        this.allTopics = value[1].allTopics;
        this.completedTopics = value[1].completedTopics;

        if (!!value[0]) {
          this.questionnaireAccesibleInComponent = value[0];
          this.findUnfinishedTopicOfUser(true);
          this.setFlagBasedOnTopics();
        }
        return value;
      })
    );
  }

  reloadData() {
    this.store.dispatch(getQuestionnaireToBeAnswered({ id: this.quest_id }));
  }

  discardAndContinue() {
    if (this.annullaIsPressed) {
      this.annullaIsPressed = false;
      this.unsaved = false;
      this.attentzione = false;
      this.reloadData();
    } else {
      this.unsaved = false;

      if (this.attentzione) {
        this.togleAnnulaPopUp();
      }
      if (!this.backbuttonPressed && !this.goHomeFromAnnula) {
        const indexOfuserslasTopic = this.questionnaireAccesibleInComponent.Topics.findIndex(
          (topic) => topic.Assigned_To === this.currentUser && topic.Order - 1 > this.activeTopicIndex
        );

        if (indexOfuserslasTopic === -1) {
          let foundIndex = this.questionnaireAccesibleInComponent.Topics.findIndex(
            (topic) => topic.Assigned_To === this.currentUser
          );
          this.activeTopicIndex = foundIndex;
        } else {
          let foundIndex = this.questionnaireAccesibleInComponent.Topics.findIndex(
            (topic) => topic.Assigned_To === this.currentUser && topic.Order - 1 > this.activeTopicIndex
          );
          this.activeTopicIndex = foundIndex;
        }
      } else if (this.goHomeFromAnnula) {
        this.homePressed();
      } else {
        this.goToPriviousTopic();
      }
    }
  }

  animateToast() {
    this.toggleAnimation = 'show';
    setTimeout(() => {
      this.toggleAnimation = 'hide';
    }, 2000);
  }

  copyTitleAndAnimate(text: string) {
    if (!!text && text !== '') {
      this.clipboard.copy(text);
      this.animateToast();
    }
    return false;
  }

  setActiveTopic(index: number) {
    if (this.unsaved) {
      this.nextOrPrevPressed = index;
      this.submitionFlag = 'GoSpecific';
      this.togleAnnulaPopUp();
    } else {
      this.activeTopicIndex = index;
    }
    this.goToTopiButtonPressed = true;
  }

  homePressed() {
    if (this.unsaved) {
      this.togleAnnulaPopUp();
      this.goHomeFromAnnula = true;
    } else {
      this.GoHome();
    }
  }

  clearTopicAnswer() {
    if (this.unsaved) {
      this.togleAnnulaPopUp();
      this.annullaIsPressed = true;
    }
  }

  goToNextTopic() {
    if (this.unsaved) {
      this.nextOrPrevPressed = 1;
      this.togleAnnulaPopUp();
    } else {
      this.activeTopicIndex++;
    }
  }

  goToPriviousTopic() {
    if (this.unsaved) {
      this.nextOrPrevPressed = -1;
      this.backbuttonPressed = true;
      this.togleAnnulaPopUp();
    } else {
      this.backbuttonPressed = false;
      this.activeTopicIndex--;
    }
  }

  togleAnnulaPopUp() {
    this.attentzione = !this.attentzione;
  }

  setUnsaved(val: boolean) {
    this.unsaved = val;
  }

  saveQuestionnaire(value: Answers) {
    let status = 'Completed';
    if (this.uncompletedQuestionsIndexes.length > 0) {
      status = 'In Progress';
    }
    this.store.dispatch(postAnswersOfQuestionnaire({ data: value, status: status }));
  }

  //Files Handling -----------------------------------------------------------
  private getStoredFiles() {
    this.store.dispatch(fromFileUploadActions.GetFilesAction({ questAssignId: this.quest_id }));

    //Realod files after submitting answers
    this.store
      .select(isQuestionnaireSubmitted)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((submitting) => {
        if (!submitting) {
          this.store.dispatch(
            fromFileUploadActions.GetFilesAction({
              questAssignId: this.quest_id,
            })
          );
        }
      });
  }

  private checkFetchedFiles() {
    this._docuFileService.currentFile$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((file) => {
      if (this.fetchedFiles.length === 0) {
        this.fetchedFiles.push(file);
      }
      if (this.fetchedFiles.length > 0) {
        let noFileMatching = this.fetchedFiles.every((uploaded) => {
          if (uploaded.questionId !== file.questionId) {
            return true;
          } else {
            return false;
          }
        });

        if (noFileMatching) {
          this.fetchedFiles.push(file);
        } else {
          for (let i = 0; i < this.fetchedFiles.length; i++) {
            if (file.questionId === this.fetchedFiles[i].questionId) {
              this.fetchedFiles[i] = file;
            }
          }
        }
      }
    });
  }

  private checkDeletedFiles() {
    this._docuFileService.deletedFile$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((file) => {
      if (this.deletedFiles.length === 0) {
        this.deletedFiles.push(file);
      }
      if (this.deletedFiles.length > 0) {
        let noFileMatching = this.deletedFiles.every((deleted) => {
          if (deleted.questionId !== file.questionId) {
            return true;
          } else {
            return false;
          }
        });

        if (noFileMatching) {
          this.deletedFiles.push(file);
        } else {
          this.deletedFiles.forEach((deleted) => {
            if (file.questionId === deleted.questionId) {
              deleted = file;
            }
          });
        }
      }
    });
  }

  private deleteFilesAndSubmit() {
    this.deletedFiles.forEach((file) => {
      if (file) {
        this.store.dispatch(fromFileUploadActions.DeleteFileAction({ file: file }));
      }
    });

    this.store
      .select(fromFileUploadSelectors.selectIsFileDeleted)
      .pipe(first((val) => val))
      .subscribe((val) => {
        if (val) {
          this.childForm.submitQuestions();
          this.deletedFiles.length = 0;
        }
      });
  }

  private uploadFilesAndSubmit() {
    this.progress$ = this.store.select(fromFileUploadSelectors.selectUploadFileProgress);

    this.isInProgress$ = this.store.select(fromFileUploadSelectors.selectUploadFileInProgress);

    for (let i = 0; i < this.fetchedFiles.length; i++) {
      if (this.fetchedFiles[i].file) {
        this.store.dispatch(
          fromFileUploadActions.UploadRequestAction({
            file: this.fetchedFiles[i].file,
            fileId: this.fetchedFiles[i].id,
            creationDate: this.fetchedFiles[i].creationDate,
            topicName: this.fetchedFiles[i].topicName,
            questionName: this.fetchedFiles[i].questionName,
            comment: this.fetchedFiles[i].comment,
            questionId: this.fetchedFiles[i].questionId,
            questAssignId: this.quest_id,
            deletedFileId: this.deletedFiles.map((file) => file.id).join(),
          })
        );
      }
    }

    this.store
      .select(fromFileUploadSelectors.selectUploadFileCompleted)
      .pipe(first((val) => val))
      .subscribe((val) => {
        if (val) {
          this.childForm.submitQuestions();
          this.fetchedFiles.length = 0;
          this.deletedFiles.length = 0;
        }
      });
  }
  //-----------------------------------------------------------------------------

  saveAnswers() {
    if (this.uncompletedQuestionsIndexes.length > 0) {
      this.showObligatoryQuestionsNotCompleted = true;
    } else {
      if (this.fetchedFiles.length > 0) {
        this.uploadFilesAndSubmit();
      } else if (this.deletedFiles.length > 0) {
        this.deleteFilesAndSubmit();
      } else {
        this.childForm.submitQuestions();
      }
    }
    this.saveAnswersPressed = true;
  }

  showWhatToDoModal() {
    if (this.topicsAssignedToUser === this.TopicsCompletedByUser && this.topicsAssignedToUser === 1) {
      if (this.questionnaireAccesibleInComponent.Assigned_To !== this.currentUser) {
        this.submitionFlag = 'notResponsibleAndfinishedWithOneTopic';
      } else {
        this.submitionFlag = 'whatToDoModal';
      }
    } else if (this.topicsAssignedToUser === this.TopicsCompletedByUser) {
      this.submitionFlag = 'whatToDoModal';
    } else {
      this.submitionFlag = 'justSaveAndContinue';
    }

    if (this.submitionFlag === 'whatToDoModal') {
      this.typeOfDialog =
        this.questionnaireAccesibleInComponent.Assigned_To === this.currentUser
          ? this.completedTopics === this.allTopics
            ? 'ResponsibleAndFinished'
            : 'ResponsibleAndUnfinished'
          : this.topicsAssignedToUser === this.TopicsCompletedByUser
          ? 'notResponsibleAndfinished'
          : 'notResponsibleAndUnfinished';
    } else {
      this.typeOfDialog = 'notResponsibleAndfinishedWithOneTopic';
    }

    this.saveModalWhenDone(this.typeOfDialog);
  }

  saveWithUnFinished() {
    this.showObligatoryQuestionsNotCompleted = false;
    this.submitionFlag = 'justSaveAndContinue';
    if (this.fetchedFiles.length > 0) {
      this.uploadFilesAndSubmit();
    } else if (this.deletedFiles.length > 0) {
      this.deleteFilesAndSubmit();
    } else {
      this.childForm.submitQuestions();
    }
  }

  questionFormScrollTop() {
    document.querySelector('#form-questions').scrollTop = 0;
  }

  unFinishedQuestions(questionsNumber: number[]) {
    this.uncompletedQuestionsIndexes = questionsNumber;
  }

  submitionOfTopicDone() {
    if (!this.amIDone) {
      switch (this.submitionFlag) {
        case 'GoHome':
          this.GoHome();
          break;
        case 'GoNextOrPrev':
          this.activeTopicIndex += this.nextOrPrevPressed;
          this.nextOrPrevPressed = 0;
          break;
        case 'GoSpecific':
          this.activeTopicIndex = this.nextOrPrevPressed;
          this.nextOrPrevPressed = 0;
          break;
        case 'justSaveAndContinue':
          let lastUncompletedTopicOfUserExistsIndex = this.questionnaireAccesibleInComponent.Topics.findIndex(
            (topic) => topic.Assigned_Status !== 'Completed' && topic.Assigned_To === this.currentUser
          );
          let amIinLastTopic = this.activeTopicIndex + 1 === this.allTopics;
          if (amIinLastTopic) {
            if (lastUncompletedTopicOfUserExistsIndex === -1) {
              this.submitionFlag = 'whatToDoModal';
              this.submitionOfTopicDone();
            } else {
              this.activeTopicIndex = lastUncompletedTopicOfUserExistsIndex;
            }
          } else {
            let nextUnAnsweredUsersTopic = this.questionnaireAccesibleInComponent.Topics.findIndex(
              (topic) =>
                topic.Assigned_Status !== 'Completed' &&
                topic.Assigned_To === this.currentUser &&
                topic.Order - 1 > this.activeTopicIndex
            );
            if (nextUnAnsweredUsersTopic === -1) {
              nextUnAnsweredUsersTopic = this.questionnaireAccesibleInComponent.Topics.findIndex(
                (topic) => topic.Assigned_Status !== 'Completed' && topic.Assigned_To === this.currentUser
              );
            }
            this.activeTopicIndex = nextUnAnsweredUsersTopic;
          }
          break;
        case 'whatToDoModal':
          this.typeOfDialog =
            this.questionnaireAccesibleInComponent.Assigned_To === this.currentUser
              ? this.completedTopics === this.allTopics
                ? 'ResponsibleAndFinished'
                : 'ResponsibleAndUnfinished'
              : this.topicsAssignedToUser === this.TopicsCompletedByUser
              ? 'notResponsibleAndfinished'
              : 'notResponsibleAndUnfinished';
          this.saveModalWhenDone(this.typeOfDialog);
          break;
        case 'SubmitQuestionnaire':
          this.GoHome();
          break;
        case 'notResponsibleAndfinishedWithOneTopic':
          this.saveModalWhenDone('notResponsibleAndfinishedWithOneTopic');
        default:
          break;
      }
    }
    this.submitionFlag = null;
  }

  private saveModalWhenDone(popuptype: string) {
    this.isWhatToDoModalOpen = true;
    this.dialog.openHomePopUp(popuptype);
    this.dialog
      .openHomePopUpReturn()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isWhatToDoModalOpen = false;
        switch (res.result) {
          case 'goToUnfinishedTopic':
            this.findUnfinishedTopicOfUser(false);
            break;
          case 'CompleteFromNotResponsible':
            this.assignBackToResponsible(res.note);
            break;
          case 'Submit':
            this.submitQuestionnaires();
            break;
          case 'goToNotResponsibleNextTopic':
            let elseIndex = this.questionnaireAccesibleInComponent.Topics.findIndex(
              (topic) => topic.Assigned_To === this.currentUser
            );
            this.goToNextTopicAfterSave(elseIndex);
            break;
          case 'goToResponsibleNextTopic':
            this.goToNextTopicAfterSave(0);
            break;
          case 'cancel':
            this.GoHome();
          default:
            break;
        }
      });
  }

  private goToNextTopicAfterSave(elseIndex) {
    if (this.topicsAssignedToUser > 1) {
      let nextUnAnsweredUsersTopic = this.questionnaireAccesibleInComponent.Topics.findIndex(
        (topic) => topic.Assigned_To === this.currentUser && topic.Order - 1 > this.activeTopicIndex
      );
      if (nextUnAnsweredUsersTopic !== -1) {
        this.activeTopicIndex = nextUnAnsweredUsersTopic;
      } else {
        this.activeTopicIndex = elseIndex;
      }
    }
  }

  private submitQuestionnaires() {
    let ids = [];
    ids.push({ id: this.questionnaireAccesibleInComponent.Assigned_id });

    this.store.dispatch(SubmitQuestionnaires({ ids }));

    // Close project if every questAssign is submitted
    this.store
      .select(isQuestionnaireSubmitted)
      .pipe(first((val) => !val))
      .subscribe((loading) => {
        if (!loading) {
          this.store.dispatch(
            getSingleProject({
              projectId: this.questionnaireAccesibleInComponent.PROJECT.PK_PROJECT,
            })
          );
          this.store
            .select(selectProjectToBeModified)
            .pipe(
              map((project) => {
                if (project) {
                  return Object.keys(project.questionnaires)
                    .map((k) => project.questionnaires[k])
                    .every((quest) => {
                      return quest.Status === 'Submitted';
                    });
                }
              }),
              first((val) => val)
            )
            .subscribe((isAllSubmitted) => {
              if (isAllSubmitted) {
                this.store.dispatch(
                  closeProjectAction({
                    projectId: this.questionnaireAccesibleInComponent.PROJECT.PK_PROJECT,
                  })
                );
              }
            });
        }
      });

    this.submitionFlag = 'SubmitQuestionnaire';
  }

  private assignBackToResponsible(note) {
    let topicsId = [];
    this.questionnaireAccesibleInComponent.Topics.map((topic) => {
      if (topic.Assigned_To === this.currentUser) {
        topicsId.push({ id: topic.Assigned_id });
      }
    });
    this.store.dispatch(
      updateTopicsUser({
        Assigned_id: this.questionnaireAccesibleInComponent.Assigned_id,
        topicAssignedIds: topicsId,
        note: note,
        backToResponsible: true,
        fromHome: false,
      })
    );
  }

  private findUnfinishedTopicOfUser(isOnInit: boolean) {
    let foundIndex = -1;
    if (this.questionnaireAccesibleInComponent.Assigned_To === this.currentUser) {
      foundIndex = this.questionnaireAccesibleInComponent.Topics.findIndex(
        (topic) => topic.Assigned_Status !== 'Completed'
      );
    } else {
      foundIndex = this.questionnaireAccesibleInComponent.Topics.findIndex(
        (topic) => topic.Assigned_Status !== 'Completed' && topic.Assigned_To === this.currentUser
      );
    }
    if (foundIndex !== -1) {
      if (!isOnInit) {
        this.activeTopicIndex = foundIndex;
      } else {
        if (this.firstTime) {
          this.activeTopicIndex = foundIndex;
        }
        this.firstTime = false;
      }
    } else {
      foundIndex = this.questionnaireAccesibleInComponent.Topics.findIndex(
        (topic) => topic.Assigned_To === this.currentUser
      );
      if (foundIndex === -1) {
        this.activeTopicIndex = 0;
      } else {
        if (!isOnInit) {
          this.activeTopicIndex++;
        }
      }
    }
  }

  private handlingLoadingTopics(loading: boolean) {
    if (loading[2] === false) {
      if (this.typeOfDialog === 'notResponsibleAndfinished') {
        this.GoHome();
      }
      if (!!this.questionnaireAccesibleInComponent) {
        if (
          this.questionnaireAccesibleInComponent.Assigned_To !== this.currentUser &&
          this.questionnaireAccesibleInComponent.ALL_REASSIGN &&
          !this.questionnaireAccesibleInComponent.VIEWABLE_FROM_ALL &&
          this.topicsAssignedToUser === 1
        ) {
          this.GoHome();
        }
      }
    }
  }

  private setFlagBasedOnTopics() {
    if (this.topicsAssignedToUser === this.TopicsCompletedByUser && this.topicsAssignedToUser === 1) {
      if (this.questionnaireAccesibleInComponent.Assigned_To !== this.currentUser) {
        this.submitionFlag = 'notResponsibleAndfinishedWithOneTopic';
      } else {
        this.submitionFlag = 'whatToDoModal';
      }
    } else if (this.topicsAssignedToUser === this.TopicsCompletedByUser) {
      this.submitionFlag = 'whatToDoModal';
    } else {
      this.submitionFlag = 'justSaveAndContinue';
    }
  }

  private handleErrorOrSubmittion(submitAndError: [boolean, boolean]) {
    this.isSubmitted = submitAndError[0];
    const hasError = submitAndError[1];
    if (!hasError && !this.isWhatToDoModalOpen) {
      this.unsaved = false;
      if (!this.isSubmitted) {
        // the submition is done
        if (this.submitionFlag === 'SubmitQuestionnaire') {
          this.GoHome();
        }
        this.submitionOfTopicDone();
      }
    } else {
      this.store.dispatch(errorHandled());
    }
  }

  private GoHome() {
    this.router.navigateByUrl(`/${ROUTES_STRINGS.HOME}`);
    this.amIDone = true;
  }

  prepWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.prepPipe.transform(word, plural, this.lang);
    return newWord;
  }

  verbWord(word: string, plural: string) {
    let newWord: string;
    newWord = this.verbPipe.transform(word, plural, this.lang);
    return newWord;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
