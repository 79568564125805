import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { checkIfDuplicateExists } from 'src/app/data/data-handlers/utils.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-list-box-component',
  templateUrl: './list-box-component.component.html',
  styleUrls: ['./list-box-component.component.scss'],
})
export class ListBoxComponentComponent implements OnInit {
  allNumbers = [];

  Option_index: any;

  option: any;

  options = [];

  temporaryOptions = [];

  constructor(
    public QuestionDialog: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: NotifyService
  ) {}
  ngOnInit(): void {
    this.options = this.data.AnswerOptions;
    if (this.data.action === 'Modify') {
      this.Option_index = this.data.index;
      this.option = this.data.option;
    }
  }

  cancel() {
    this.QuestionDialog.close('Cancel');
  }

  isNumber(n) {
    // return !isNaN(parseFloat(n)) && !isNaN(n - 0) ||; rb
    return isFinite(n.replaceAll(',', ''));
  }

  addOption() {
    if (this.option === undefined || this.option === '') {
      this.utils.openWarningSwal('Inserire una risposta valida');
      return;
    } else if (this.data.action === 'Modify') {
      this.checksInModifyCase();
    } else if (this.data.action === 'Add') {
      this.checksInAddCase();
      if (this.checksInAddCase() && !checkIfDuplicateExists(this.options)) {
        this.QuestionDialog.close(this.options);
      } else if (checkIfDuplicateExists(this.options)) {
        this.utils.openWarningSwal('Inserire solo distinte risposte');
      }
    }
  }

  checksInModifyCase() {
    let tempModifiedOption;

    tempModifiedOption = this.option.split(';;')[0];

    let isNumber = !this.isNumber(tempModifiedOption) ? false : true;

    if (this.data.TypeOfAnswer === 'Number') {
      if (isNumber === false) {
        this.utils.openWarningSwal('Inserire un numero');
        return;
      } else {
        if (!this.options.includes(tempModifiedOption) && tempModifiedOption !== '') {
          return this.QuestionDialog.close(tempModifiedOption);
        } else if (tempModifiedOption === '') {
          this.utils.openWarningSwal('Inserire una valida opzione');
        } else {
          this.utils.openWarningSwal('Questa risposta è già presente');
        }
      }
    } else {
      if (!this.options.includes(tempModifiedOption) && tempModifiedOption !== '') {
        this.QuestionDialog.close(tempModifiedOption);
      } else if (tempModifiedOption === '') {
        this.utils.openWarningSwal('Inserira una valida opzione');
      } else {
        this.utils.openWarningSwal('Questa risposta è già presente');
      }
    }
  }

  checksInAddCase() {
    let tempOptions;

    tempOptions = this.option.split(';;');

    tempOptions.forEach((element) => {
      if (element !== '') {
        this.allNumbers.push(this.isNumber(element));
      }
    });

    if (this.data.TypeOfAnswer === 'Number') {
      if (this.allNumbers.includes(false)) {
        this.utils.openWarningSwal('Inserire solo numeri');
        this.allNumbers = [];
        return;
      } else {
        tempOptions.forEach((element) => {
          if (!this.options.includes(element) && element !== '') {
            this.options.push(element);
          }
        });
        return true;
      }
    } else {
      tempOptions.forEach((element) => {
        if (!this.options.includes(element) && element !== '') {
          this.options.push(element);
        }
      });
      return true;
    }
  }
}
