<div *ngIf="!showPanel" id="search-filter-form" class="relative filter lg:w-[30vw]">
  <mat-form-field id="search-input" class="w-full ![1.25rem] text-dialog">
    <mat-label class="ml-4">
      {{ "HOME_PAGE.HOME_FILTER.SEARCHBAR" | translate | titlecase }}
      {{ "DICTIONARY.Progetti" | translate | titlecase }}
    </mat-label>
    <span matPrefix>
      <mat-icon id="glass" matSuffix>search</mat-icon>
    </span>
    <input type="text" matInput [ngModel]="searchByName" (ngModelChange)="searchTerm($event)" />
    <button
      mat-mini-fab
      color="warn"
      id="button-nobck"
      class="!bg-transparent focus:bg-transparent"
      mat-icon
      matSuffix
      (click)="hideShowPanel($event)"
    >
      <mat-icon [matBadge]="badgeCounter" [matBadgeHidden]="hideBadge" class="my-fab">filter_list </mat-icon>
    </button>
  </mat-form-field>
</div>
<div *ngIf="showPanel" class="inline-flex items-center filter">
  <span id="filter-text" class="relative bottom-6 right-4 text-xl font-bold text-white"
    >{{ "HOME_PAGE.HOME_FILTER.FILTER_TEXT" | translate | titlecase }}
  </span>
  <button
    mat-mini-fab
    color="warn"
    id="button-nobck"
    class="bottom-6 !bg-white"
    mat-icon
    matSuffix
    (click)="hideShowPanel($event)"
  >
    <mat-icon [matBadge]="badgeCounter" [matBadgeHidden]="hideBadge" class="my-fab">filter_list </mat-icon>
  </button>
</div>
<div
  [ngClass]="{
    '!grid h-[fit-content] w-full gap-10 rounded-b-3xl bg-white p-6 text-base shadow md:grid-cols-2': showPanel
  }"
  id="filter-panel"
  class="absolute right-0 z-10 hidden min-h-[20vh]"
>
  <mat-radio-group id="radioButtons" class="w-1/2" [(ngModel)]="chosenItem" name="fieldName">
    <div id="radioButton1" class="p-2">
      <!-- all questionnaires -->
      <mat-radio-button [ngSwitch]="lang" (change)="filterO('showAll')" [value]="'showAll'">
        <span *ngSwitchCase="'it'">Mostra Tutti</span>
        <span *ngSwitchCase="'en'">Shows All</span>
      </mat-radio-button>
    </div>
    <div id="radioButton2" class="p-2">
      <!-- i am responsible for this -->
      <mat-radio-button [ngSwitch]="lang" (change)="filterO('responsible')" [value]="'responsible'">
        <span *ngSwitchCase="'it'">
          {{ "DICTIONARY.Questionari" | translate | titlecase }} per cui sono
          {{ "DICTIONARY.Responsabile" | translate | lowercase }}
        </span>
        <span *ngSwitchCase="'en'">
          {{ "DICTIONARY.Questionari" | translate | titlecase }} for which I am the
          {{ "DICTIONARY.Responsabile" | translate | lowercase }}
        </span>
      </mat-radio-button>
    </div>
    <div id="radioButton3" class="p-2">
      <!-- questionnaire with topics to compile -->
      <mat-radio-button [ngSwitch]="lang" (change)="filterO('topicsToCompile')" [value]="'topicsToCompile'">
        <span *ngSwitchCase="'it'">
          {{ "DICTIONARY.Questionari" | translate | titlecase }} con {{ "DICTIONARY.Topic" | translate | lowercase }} da
          compilare
        </span>
        <span *ngSwitchCase="'en'">
          {{ "DICTIONARY.Questionari" | translate | titlecase }} with
          {{ "DICTIONARY.Topic" | translate | lowercase }} to be filled out
        </span>
      </mat-radio-button>
    </div>
    <div id="radioButton4" class="p-2">
      <!-- questionnaire with completed topics -->
      <mat-radio-button [ngSwitch]="lang" (change)="filterO('completedTopics')" [value]="'completedTopics'">
        <span *ngSwitchCase="'it'">
          {{ "DICTIONARY.Questionari" | translate | titlecase }} con
          {{ "DICTIONARY.Topic" | translate | lowercase }}
          {{ "completato " + ("DICTIONARY.Topic" | translate) | verbPronounDictionary: "plural":lang }}
        </span>
        <span *ngSwitchCase="'en'">
          {{ "DICTIONARY.Questionari" | translate | titlecase }} with no
          {{ "DICTIONARY.Topic" | translate | lowercase }} to be filled out
        </span>
      </mat-radio-button>
    </div>
    <div id="radioButton5" class="p-2">
      <!-- questionnaires with notes -->
      <mat-radio-button (change)="filterO('notesToBeRead')" [value]="'notesToBeRead'">
        <span>{{ "HOME_PAGE.HOME_FILTER.QUESTIONNAIRES_WITH_NOTES" | translate }}</span>
      </mat-radio-button>
    </div>
    <div id="radioButton6" class="p-2">
      <!-- expired questionnaires -->
      <mat-radio-button [ngSwitch]="lang" (change)="filterO('expired')" [value]="'expired'">
        <span *ngSwitchCase="'it'"> {{ "DICTIONARY.Questionari" | translate | titlecase }} scaduti </span>
        <span *ngSwitchCase="'en'"> Expired {{ "DICTIONARY.Questionari" | translate | titlecase }} </span>
      </mat-radio-button>
      <div class="mt-4 flex w-[max-content] flex-row items-center gap-2" *ngIf="showCalendar">
        <div id="start-date" class="input-fix">
          <mat-form-field class="" appearance="outline">
            <mat-label>
              {{ "HOME_PAGE.HOME_FILTER.FROM" | translate | titlecase }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="startPicker"
              name="filterStartDate"
              [ngModel]="filterStartDate"
              (ngModelChange)="startChange($event)"
            />
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div id="end-date" class="input-fix">
          <mat-form-field class="" appearance="outline">
            <mat-label>
              {{ "HOME_PAGE.HOME_FILTER.TO" | translate | titlecase }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="endPicker"
              name="filterEndDate"
              [ngModel]="filterEndDate"
              (ngModelChange)="endChange($event)"
            />
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <button class="relative top-1 !border-none whiteBtn" (click)="resetForm()">Clear All</button>
      </div>
    </div>
  </mat-radio-group>

  <div id="selection-list" class="h-20 w-1/2 p-2">
    <mat-label [ngSwitch]="lang">
      <span *ngSwitchCase="'it'">Ordina {{ "DICTIONARY.Questionari" | translate | titlecase }} per: </span>
      <span *ngSwitchCase="'en'"> Order {{ "DICTIONARY.Questionari" | translate | titlecase }} by: </span>
    </mat-label>
    <mat-select
      class="mt-2 border-[1.5px] border-dialog p-2"
      [(ngModel)]="selectedValue"
      (selectionChange)="orderQuests($event)"
      name="fieldName"
      panelClass="options-panel"
    >
      <mat-option [ngSwitch]="lang" value="Questionnaire_Title">
        <span *ngSwitchCase="'it'"> Titolo {{ "DICTIONARY.Questionario" | translate | titlecase }} </span>
        <span *ngSwitchCase="'en'"> {{ "DICTIONARY.Questionario" | translate | titlecase }}'s Title </span>
      </mat-option>
      <mat-option [ngSwitch]="lang" value="Assigned_Status">
        <span *ngSwitchCase="'it'"> Stato {{ "DICTIONARY.Questionario" | translate | titlecase }} </span>
        <span *ngSwitchCase="'en'"> {{ "DICTIONARY.Questionario" | translate | titlecase }}'s status </span>
      </mat-option>
      <mat-option [ngSwitch]="lang" value="Expiry_Date">
        <span *ngSwitchCase="'it'">Data di Scadenza</span>
        <span *ngSwitchCase="'en'">Expiration Date</span>
      </mat-option>
      <mat-option [ngSwitch]="lang" value="Assigned_to">
        <span *ngSwitchCase="'it'">
          {{ "DICTIONARY.Responsabile" | translate | titlecase }}
          {{ "DICTIONARY.Questionario" | translate | titlecase }}
        </span>
        <span *ngSwitchCase="'en'">
          {{ "DICTIONARY.Questionario" | translate | titlecase }}'s
          {{ "DICTIONARY.Responsabile" | translate | titlecase }}
        </span>
      </mat-option>
      <mat-option [ngSwitch]="lang" value="Completed_Topics">
        <span *ngSwitchCase="'it'"> {{ "DICTIONARY.Topic" | translate | titlecase }} da Completare </span>
        <span *ngSwitchCase="'en'"> {{ "DICTIONARY.Topic" | translate | titlecase }} to be Completed </span>
      </mat-option>
    </mat-select>
  </div>
</div>
