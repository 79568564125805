import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import * as selectors from 'src/app/Store/questionnaire-store/state.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { getSingleQuestionnaire } from 'src/app/Store/questionnaire-store/state.actions';
import { selectLoading } from 'src/app/Store/questionnaire-store/state.selectors';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { ElencoTopic } from 'src/app/data/models/ElencoTopic';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { selectTopics } from 'src/app/Store/topics-store/topics.selectors';
import { objectsAreSame } from 'src/app/data/data-handlers/utils.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modify-questionnaire',
  templateUrl: './modify-questionnaire.component.html',
  styleUrls: ['./modify-questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModifyQuestionnaireComponent implements OnInit {
  @Input()
  questID: string;

  @Input()
  visualizeArchivedModel1 = false;

  @Input()
  visualizeArchivedModel2 = false;

  activeQuestionnaire: Observable<QuestionnairesStructure>;
  activeQSubCount: number;

  topics: Observable<ElencoTopic[]>;

  loading: Observable<boolean>;
  formChanges = { titleChange: false, formChange: false };
  currentTopic: ElencoTopic[];
  currentQuest: QuestionnairesStructure;
  newQuestionnaire: QuestionnairesStructure;
  modelSavedTrigger: boolean;

  constructor(
    private store: Store,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dialogService: GeneralDialogService
  ) {}

  ngOnInit(): void {
    try {
      this.questID = this.activeRoute.snapshot.paramMap.get('id');
    } catch (error) {}
    this.store.dispatch(getSingleQuestionnaire({ id: this.questID }));
    this.activeQuestionnaire = this.store.select(selectors.selectQuestionnaireToBeModified);
    this.loading = this.store.select(selectLoading);
    this.checkChanges();
  }

  changes(changes) {
    this.formChanges = changes;
  }

  modelSaved(saved) {
    this.modelSavedTrigger = saved;
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate() {
    if (!this.modelSavedTrigger) {
      if (!objectsAreSame(this.currentQuest, this.newQuestionnaire) || this.formChanges.formChange) {
        history.pushState(null, null, this.router.url);
        this.dialogService.openConfirmDialog({
          info: CONFIRMDIALOGSTRINGS.CANCEL_MODIFICATION_CREATION,
        });
        this.dialogService.openConfirmDialogResponse();
        return this.dialogService.confirmDialog.componentInstance.navigateAwaySelection$;
      } else {
        return true;
      }
    }
    return true;
  }

  checkChanges() {
    // set newQuestionnaire for the first time
    this.activeQSubCount = 0;

    this.activeQuestionnaire.pipe(take(2)).subscribe((res) => {
      this.activeQSubCount++;
      if (this.activeQSubCount === 2) {
        this.currentQuest = res;
        this.newQuestionnaire = {
          id: this.questID,
          Title: this.currentQuest?.Title,
          Status: this.currentQuest?.Status,
          Description: this.currentQuest?.Description,
          Topics: [],
        } as QuestionnairesStructure;

        // Update Topics of newQuestionnaire
        this.store.select(selectTopics).subscribe((res) => {
          this.currentTopic = res;
          this.newQuestionnaire = {
            ...this.newQuestionnaire,
            Topics: this.currentTopic,
          };
        });
      }
    });
  }
}
