<div class="userForm">
  <div class="title mb-[5vh] text-3xl text-dialog">
    {{ "CHANGE_PASS.TITLE" | translate }}
  </div>
  <form class="user-form" [formGroup]="passwordForm" (submit)="changePassword()">
    <div>
      <mat-form-field appearance="outline" class="input">
        <input
          required
          matInput
          [type]="hideOld ? 'password' : 'text'"
          formControlName="old_password"
          name="old_password"
          placeholder="{{ 'CHANGE_PASS.OLD' | translate }}"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="setHideOld($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideOld"
        >
          <mat-icon>{{ hideOld ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-error
        *ngIf="passwordForm.get('old_password').hasError('required') && passwordForm.get('old_password').touched"
        >{{ "USER_POP_UP.PASSWORD_REQUIRED" | translate }}</mat-error
      >
    </div>

    <div>
      <mat-form-field appearance="outline" class="input">
        <input
          required
          matInput
          (ngModelChange)="checkPassValues()"
          [type]="hide ? 'password' : 'text'"
          formControlName="new_password"
          name="new_password"
          placeholder="{{ 'CHANGE_PASS.NEW' | translate }}"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="setHide($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-error
        *ngIf="passwordForm.get('new_password').hasError('required') && passwordForm.get('new_password').touched"
        >{{ "USER_POP_UP.PASSWORD_REQUIRED" | translate }}</mat-error
      >
    </div>
    <div>
      <mat-form-field appearance="outline" class="input">
        <input
          required
          matInput
          (ngModelChange)="checkPassValues()"
          [type]="hideConf ? 'password' : 'text'"
          formControlName="repeatPassword"
          name="repeatPassword"
          placeholder="{{ 'CHANGE_PASS.CONFIRM' | translate }}"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="setHideConf($event)"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideConf"
        >
          <mat-icon>{{ hideConf ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-error *ngIf="repeatPassword.value !== primPassword.value"
          >{{ "CHANGE_PASS.ERROR" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-error
        *ngIf="passwordForm.get('repeatPassword').hasError('required') && passwordForm.get('repeatPassword').touched"
        >{{ "USER_POP_UP.PASSWORD_REQUIRED" | translate }}</mat-error
      >
    </div>

    <br />

    <div class="mt-[5vh] flex justify-end">
      <button class="mr-3 whiteBtn" (click)="cancel()">
        {{ "CHANGE_PASS.CANCEL" | translate }}
      </button>
      <button class="colorBtn" [ngClass]="{'disabled':!passwordForm.valid}" (click)="updatePassword()">
        {{ "CHANGE_PASS.UPDATE" | translate }}
      </button>
    </div>
  </form>
</div>
