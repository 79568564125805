import { typeOfQuestionDeletion } from 'src/app/utils/config/questionnaire-table.configuration';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { addQuestion, deleteQuestion, duplcateQuestionAction, updateQuestion } from '../questions/questions.actions';
import { selectQuestionsByID, selectQuestionsByTopicIndex, selectTopicsByIndex } from '../questions/questions.selector';
import { Question } from 'src/app/data/models/Question';

@Injectable({
  providedIn: 'root',
})
export class QuestionsStoreService {
  constructor(private store: Store) {}

  addQuestion(index: number, question: Question) {
    return this.store.dispatch(addQuestion({ index: index, question: question }));
  }

  deleteQuestion(index: number, idsToBeRemoved: number[], typeOfDeletion: typeOfQuestionDeletion) {
    return this.store.dispatch(
      deleteQuestion({
        index: index,
        idsToBeRemoved: idsToBeRemoved,
        typeOfDeletion: typeOfDeletion,
      })
    );
  }

  updateQuestion(
    question: Question,
    topicIndex: number,
    changedPosition: { flag: boolean; oldPosition: number }
  ) {
    return this.store.dispatch(
      updateQuestion({
        question: question,
        topicIndex: topicIndex,
        changedPosition: changedPosition,
      })
    );
  }

  duplicateQuestion(topicIndex: number, question: Question) {
    return this.store.dispatch(duplcateQuestionAction({ topicIndex: topicIndex, question: question }));
  }

  selectTopicsByIndex(topicIndex: number) {
    return this.store.select(selectTopicsByIndex(topicIndex));
  }

  selectQuestionsByTopicIndex(topicIndex: number) {
    return this.store.select(selectQuestionsByTopicIndex(topicIndex));
  }

  selectQuestionsByID(questionID: string, topicIndex: number) {
    return this.store.select(selectQuestionsByID(questionID, topicIndex));
  }
}
