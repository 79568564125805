import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectState } from '../project-state';

export const appState = createFeatureSelector<ProjectState>('Projects');

export const selectActiveProject = createSelector(appState, (state) => state.activeProject);

export const selectProjectsTotalNumber = createSelector(appState, (state) => state.total);
export const selectDeleteMode = createSelector(appState, (state) => state.deleteStatus);
export const selectLoadingDeleteMode = createSelector(appState, (state) => state.loadingDeleteMode);

export const selectProjectPageIndex = createSelector(appState, (state) => state.pageIndex);
export const selectProjectsTotalAndIndex = createSelector(
  selectProjectsTotalNumber,
  selectProjectPageIndex,
  (total, index) => {
    return { total, pageIndex: index };
  }
);

export const selectLoadingProjects = createSelector(appState, (state) => state.loadingProjects);
export const selectloadingSingleProject = createSelector(appState, (state) => state.loadingSingleProject);

export const getAllProjectsSelector = createSelector(appState, (state) => state.Projects);

export const selectProjects = createSelector(appState, (state) => state.Projects);

export const getProjectByPK = (pk) =>
  createSelector(selectProjects, (allItems) => {
    if (allItems) {
      return allItems.find((item) => {
        return item.PK_PROJECT === pk;
      });
    } else {
      return;
    }
  });

export const selectProjectToBeModified = createSelector(appState, (state) => {
  return state.projectToBeModified;
});

export const selectProjectLoading = createSelector(appState, (state) => {
  return state.projectLoading;
});

export const selectAnalyticsData = createSelector(appState, (state) => {
  return state.analyticsData;
});

export const selectAnalyticsDataLoading = createSelector(appState, (state) => {
  return state.loadingAnalyticsData;
});

export const selectAnalyticsFiltersNames = createSelector(appState, (state) => {
  return state.analyticsFilterNames;
});
export const selectAnalyticsFiltersSelections = createSelector(appState, (state) => {
  return state.analyticsFilterSelections;
});

export const selectCloneLoading = createSelector(appState, (state) => {
  return state.loadingClone;
});

export const selectModelFilter = createSelector(appState, (state) => {
  return state.modelFilter;
});

export const selectModelClickedFromProjects = createSelector(appState, (state) => {
  return state.modelClickedFromProjects;
});

export const selectProjectFilterOptions = createSelector(appState, (state) => {
  return state.filterOptions;
});

export const selectCheckedProjects = createSelector(appState, (state) => state.checkedProjects);
