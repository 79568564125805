import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Password } from '../../models/request-apis/Password';
import { UserSend } from '../../models/request-apis/User-request';
import { NewUser, User } from '../../models/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  public getUsers() {
    return this.httpClient.get(`getAllUsers?flag=false`);
  }

  public getUserByUsername(username: string) {
    return this.httpClient.get(`getUserByUsername?username=${username}`);
  }

  public importUsersToProject(data) {
    return this.httpClient.post('checkIfUsersExistForImport', { users: data });
  }

  public getUsersPaginated(pageIndex?: number, pageSize?: number, usersExcluded?: User[], disabled?: boolean) {
    let usersExcl = '';
    if (!!usersExcluded && usersExcluded.length > 0) {
      for (let each of usersExcluded) {
        if (!!each['username']) {
          usersExcl += `&usersExcluded[]=${each.username}`;
        } else {
          usersExcl += `&usersExcluded[]=${each}`;
        }
      }
    } else {
      usersExcl += `&usersExcluded[]=${usersExcl}`;
    }
    if (disabled === true) {
      return this.httpClient.get(`getAllUsers?flag=disabled&pageSize=${pageSize}&pageIndex=${pageIndex}` + usersExcl);
    } else {
      return this.httpClient.get(`getAllUsers?flag=true&pageSize=${pageSize}&pageIndex=${pageIndex}` + usersExcl);
    }
  }

  public getUsersPaginatedForOwner(pageIndex?: number, pageSize?: number, usersExcluded?: User[]) {
    return this.httpClient.get(`getResponsibleUsers?pageSize=${pageSize}&pageIndex=${pageIndex}`);
  }

  searchUsers(
    searchTerm: string,
    pageIndex?: number,
    pageSize?: number,
    sortCol?: string,
    sortOrder?: string,
    filters?: {
      clientFilter?: string;
      groupFilter?: string;
      emailFilter?: string;
      statusActive?: boolean;
      statusDisabled?: boolean;
    }
  ) {
    return this.httpClient.get(
      `searchUsers?pageSize=${pageSize}&pageIndex=${pageIndex}&searchTerm=${encodeURIComponent(searchTerm)}` +
        `&sortCol=${sortCol}&sortOrder=${sortOrder}&clientFilter=${filters.clientFilter}&groupFilter=${filters.groupFilter}` +
        `&emailFilter=${filters.emailFilter}&statusActive=${filters.statusActive}&statusDisabled=${filters.statusDisabled}`
    );
  }

  reassignClientGroup(username: string[], client_id?: string, group_id?: string) {
    return this.httpClient.post('reassignClientGroup', { username, client_id, group_id });
  }

  searchClientGroup(
    searchTerm: string,
    field: 'Client' | 'Group',
    pageIndex?: number,
    pageSize?: number,
    sortCol?: string,
    sortOrder?: string,
    client_id?: string
  ) {
    return this.httpClient.get(
      `search${field}?pageSize=${pageSize}&pageIndex=${pageIndex}&searchTerm=${encodeURIComponent(searchTerm)}` +
        `&sortCol=${sortCol}&sortOrder=${sortOrder}&client_id=${client_id}`
    );
  }

  public searchAllClientGroup(admin: string) {
    return this.httpClient.get(`searchAllClientGroup?admin=${admin}`);
  }

  public createUser(user: UserSend) {
    return this.httpClient.post('createUser', user);
  }

  public createNewUser(user: NewUser) {
    return this.httpClient.post('createNewUser', user);
  }

  public updateUser(user: UserSend) {
    return this.httpClient.post('updateUser', user);
  }

  public deleteUser(username: string | string[]) {
    return this.httpClient.post('deleteUser', { username });
  }

  public disableUser(username: string | string[]) {
    return this.httpClient.post('disableUser', { username });
  }

  public reactivateUser(username: string) {
    return this.httpClient.post('reactivateUser', { username });
  }

  public updatePassword(passwordInfo: Password) {
    return this.httpClient.post('changePassword', passwordInfo);
  }
}
