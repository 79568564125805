<ng-container>
  <div id="q-text" class="mb-6 flex min-w-[11vw] items-center justify-between">
    <div
      class="line-clamp-2"
      matTooltip="{{ question.Question_Text }}"
      matTooltipClass="custom-tooltip"
      appShowIfTruncated
    >
      <strong class="text-xl">{{ question.Position }}</strong>
      <span
        class="text-xl"
        (contextmenu)="
          copyToClipBoard(question.Question_Text);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.TITLE_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
      >
        - {{ question.Question_Text }}
      </span>
    </div>
    <mat-icon
      *ngIf="question.Description !== ''"
      (click)="toggleDescriptionVisibility()"
      matTooltip="{{ plainText }}"
      class="ml-4 cursor-pointer text-[35px] text-main"
      >help
    </mat-icon>
  </div>
  <div
    (click)="!reviewMode && toggleModelVisibility()"
    id="choose-model"
    [ngClass]="{ 'disabled-border-color': reviewMode, 'cursor-pointer hover:border-dialog': !reviewMode }"
    class="mat-ff-in-rems flex items-center justify-between rounded-lg border-2 transition"
  >
    <div id="answers-to-show" class="flex flex-row gap-2 px-2">
      <span
        class="truncate line-clamp-1"
        appShowIfTruncated
        matTooltip="{{ answer }}"
        matTooltipClass="custom-tooltip"
        *ngFor="let answer of answersToShow; let i = index"
      >
        {{ answer.Answer_Value }} {{ i < answersToShow.length - 1 ? "," : "" }}
      </span>
      <span *ngIf="!reviewMode && answersToShow.length === 0" class="text-gray opacity-60">{{
        "QUEST_ANS.LIST_BOX_TITLE" | translate
      }}</span>
    </div>
    <mat-icon> arrow_drop_down </mat-icon>
  </div>
</ng-container>

<div
  *ngIf="showOptions"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showOptions }"
  (click)="toggleModelVisibility()"
>
  <div
    id="selections-div h-[40vh]"
    class="w-[35vw] min-w-min gap-6 overflow-hidden rounded-3xl bg-white p-6"
    (click)="$event.stopPropagation()"
  >
    <div [ngSwitch]="lang" id="title" class="font-popppins pb-8 text-4xl font-bold text-card">
      <p *ngSwitchCase="'it'">{{ titleDisplayed }}</p>
      <p *ngSwitchCase="'en'">{{ titleDisplayedENG }}</p>
    </div>
    <div [ngSwitch]="lang" id="description" class="pb-4 text-xl">
      <p *ngSwitchCase="'it'">{{ messageDisplayed }}</p>
      <p *ngSwitchCase="'en'">{{ messageDisplayedENG }}</p>
    </div>
    <div id="option-body" class="flex h-full w-full flex-col gap-4 overflow-y-auto overflow-x-hidden">
      <div
        id="one-option"
        class="flex w-full flex-row items-center gap-6 break-words rounded-xl bg-body p-4"
        *ngFor="let option of question.Check_List_Box_Options"
      >
        <mat-checkbox
          [checked]="answersPicked | isIncluded: option"
          [value]="option['Answer_Value']"
          (change)="setValue(option)"
        >
        </mat-checkbox>
        <label *ngIf="option['Answer_Value'].length < 150" class="max-w-xl truncate" for="{{ option['id'] }}"
          >{{ option["Answer_Value"] }}
        </label>
        <label
          *ngIf="option['Answer_Value'].length >= 150"
          class="max-w-xl truncate line-clamp-3"
          for="{{ option['id'] }}"
          matTooltip
          matTooltipClass="custom-tooltip"
          >{{ option["Answer_Value"] }}
        </label>
      </div>
    </div>

    <div id="button-group" class="flex flex-row items-end justify-end gap-6 pt-8">
      <button (click)="toggleModelVisibility()" class="whiteBtn">
        {{ "ANNULLA" | translate }}
      </button>
      <button
        [disabled]="!formValid"
        (click)="getCheckListBoxAnswer()"
        color="primary"
        matTooltipClass="custom-tooltip"
        class="colorBtn disabled:colorBtn-disabled"
      >
        {{ "CONFERMA" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="showDescription"
  class="overlayHidden"
  [ngClass]="{ overlayShow: showDescription }"
  (click)="toggleDescriptionVisibility()"
>
  <div
    id="selections-div"
    class="flex h-[fit-content] w-[55rem] flex-col justify-between gap-6 overflow-hidden rounded-2xl bg-white p-6"
    (click)="$event.stopPropagation()"
  >
    <div id="title" class="font-popppins text-4xl font-bold text-card">
      {{ "DESCRIZIONE" | translate }}
    </div>
    <div class="py-[3rem] text-base">
      {{ question.Description }}
    </div>
    <div class="mt-[10px] flex flex-row !justify-end">
      <button
        (click)="
          copyToClipBoard(plainText);
          openSnackBar(
            translate.instant('QUESTION_TYPES_FORM.DESCRIPTION_SENTENCE'),
            translate.instant('QUESTION_TYPES_FORM.CLOSE')
          )
        "
        class="mr-3 whiteBtn"
      >
        {{ "QUEST_CREATION.DESC_COP" | translate }}
      </button>
      <button class="colorBtn" (click)="toggleDescriptionVisibility()">
        {{ "UNDERSTOOD" | translate }}
      </button>
    </div>
  </div>
</div>
