import { AnalyticsData } from 'src/app/data/models/AnalyticsData';
import { Project } from 'src/app/data/models/Project';

export interface ProjectState {
  topicMetrics: {
    allTopics: number;
    completedTopics: number;
    completedTopicsOfUser: number;
    topicsOfUser: number;
  };
  Projects?: Project[];
  activeProject?: any[];
  projectToBeModified?: Project;
  analyticsData: AnalyticsData;
  analyticsFilterNames: { users: any[]; topics: any[] };
  analyticsFilterSelections: { users: any[]; topics: any[]; questions: any[] };
  pageIndex?: number;
  pageSizeValue?: number;
  sortColValue?: string;
  total?: number;
  sortOrderValue?: string;
  projectLoading?: boolean;
  error: boolean;
  loadingProjects: boolean;
  loadingSingleProject: boolean;
  loadingAnalyticsData: boolean;
  loadingClone: boolean;
  modelFilter: string;
  modelClickedFromProjects: string;
  deleteStatus: boolean;
  loadingDeleteMode: boolean;
  filterOptions: {
    saved: boolean;
    modelFilter: string;
    creatorFilter: string;
    searchTerm: string;
    expireFromFilter: string;
    expireToFilter: string;
    creationFromFilter: string;
    creationToFilter: string;
    status: {
      draft: boolean;
      active: boolean;
      closed: boolean;
      archived: boolean;
    };
  };
  checkedProjects: Project[];
}

export const initialProjectState: ProjectState = {
  topicMetrics: {
    allTopics: 0,
    completedTopics: 0,
    completedTopicsOfUser: 0,
    topicsOfUser: 0,
  },
  Projects: [],
  activeProject: [],
  total: 0,
  pageIndex: 0,
  pageSizeValue: 10,
  sortColValue: '',
  sortOrderValue: '',
  projectToBeModified: null,
  analyticsData: null,
  analyticsFilterNames: { users: [], topics: [] },
  analyticsFilterSelections: { users: [], topics: [], questions: [] },
  projectLoading: false,
  loadingClone: false,
  error: false,
  loadingProjects: false,
  loadingSingleProject: false,
  loadingAnalyticsData: true,
  modelFilter: '',
  modelClickedFromProjects: '',
  deleteStatus: false,
  loadingDeleteMode: null,
  filterOptions: {
    saved: false,
    modelFilter: '',
    creatorFilter: '',
    searchTerm: '',
    expireFromFilter: '',
    expireToFilter: '',
    creationFromFilter: '',
    creationToFilter: '',
    status: {
      draft: true,
      active: true,
      closed: true,
      archived: false,
    },
  },
  checkedProjects: [],
};
