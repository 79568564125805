import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { SpinnerModule } from 'src/app/components/spinner/spinner.component';
import { UsersTableModule } from 'src/app/components/users-table/users-table.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [AppMaterialModule, UsersTableModule, SpinnerModule],
})
export class SharedModule {}
