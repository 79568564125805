<div class="chart-container min-h-full flex items-center justify-between text-card p-6">
  <div class="flex w-1/3 flex-col items-center">
    <h1 class="text-4xl font-bold">
      {{ "ANALYTICS_PANEL.QUESTIONNAIRE" | translate }}
    </h1>
    <apx-chart
      class="relative"
      [series]="questionnaires"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [fill]="chartOptions.color.fill"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
      [responsive]="chartOptions.responsive"
      [legend]="chartOptions.legend"
    >
    </apx-chart>
    <div>
      <div class="mb-2 flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#FF8D80]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.TO_BE_DONE" | translate }}
        </p>
      </div>
      <div class="mb-2 flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#66A7FF]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.IN_PROGRESS" | translate }}
        </p>
      </div>
      <div class="flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#9EFF87]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.COMPLETED" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="flex w-1/3 flex-col items-center">
    <h1 class="text-4xl font-bold">{{ "DICTIONARY.Topic" | translate }}</h1>
    <apx-chart
      class="relative"
      [series]="topics"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [fill]="chartOptions.color.fill"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
      [responsive]="chartOptions.responsive"
      [legend]="chartOptions.legend"
    >
    </apx-chart>
    <div>
      <div class="mb-2 flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#FF8D80]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.TO_BE_DONE" | translate }}
        </p>
      </div>
      <div class="mb-2 flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#66A7FF]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.IN_PROGRESS" | translate }}
        </p>
      </div>
      <div class="flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#9EFF87]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.COMPLETED" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="flex w-1/3 flex-col items-center">
    <h1 class="text-4xl font-bold">
      {{ "ANALYTICS_PANEL.QUESTIONS" | translate }}
    </h1>
    <apx-chart
      class="relative"
      [series]="questions"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [fill]="chartOptions.color.fill"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
      [responsive]="chartOptions.responsive"
      [legend]="chartOptions.legend"
    >
    </apx-chart>
    <div>
      <div class="mb-2 flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#FF8D80]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.TO_BE_DONE" | translate }}
        </p>
      </div>
      <div class="mb-2 flex items-center">
        <div class="mr-2 h-4 w-4 rounded-xl bg-[#9EFF87]"></div>
        <p class="text-xl font-medium">
          {{ "ANALYTICS_PANEL.COMPLETED" | translate }}
        </p>
      </div>
      <p class="text-transparent">dummy</p>
    </div>
  </div>
</div>
