import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-access-tab',
  templateUrl: './access-tab.component.html',
  styleUrls: ['./access-tab.component.scss'],
})
export class AccessTabComponent implements OnInit {
  @Input() set missingValue(value: boolean) {
    if (value) {
      this.accessForm.markAllAsTouched();
    }
  }
  @Output() newAccess = new EventEmitter<FormGroup>();
  accessForm: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.accessForm = new FormGroup({
      models: new FormControl(null, {
        validators: [Validators.required],
      }),
      projects: new FormControl(null, {
        validators: [Validators.required],
      }),
      users: new FormControl(null, {
        validators: [Validators.required],
      }),
      clientGroup: new FormControl(null, {
        validators: [Validators.required],
      }),
      admin: new FormControl(null, {
        validators: [Validators.required],
      }),
      debug: new FormControl(null, {
        validators: [Validators.required],
      }),
      custom: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
    this.accessForm.valueChanges.subscribe((val) => {
      if (this.accessForm.valid) {
        this.newAccess.emit(this.accessForm);
      }
    });
  }
}
