import { TopicsState } from './topics.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const appState = createFeatureSelector<TopicsState>('topics');

export const selectTopics = createSelector(appState, (state) => state.Topics);
export const selectTopicsLoading = createSelector(appState, (state) => state.loading);
export const selectAssingedIdForReview = createSelector(appState, (state) => state.AssingedIdReviewed);

export const selectTitlesOfAllTopics = createSelector(selectTopics, (topics) => {
  let Titles = [];
  topics.forEach((title) => {
    Titles.push(title.Title.toLowerCase().replace(/\s/g, ''));
  });
  return Titles;
});

export const selectTopicsWithTheirQuestions = createSelector(selectTopics, (topics) => {
  let TitlesAndQuestions = [];
  topics.forEach((title) => {
    const questions = [];
    title.Questions.forEach((question) => {
      questions.push(question.Question_Text.toLowerCase().replace(/\s/g, ''));
    });
    TitlesAndQuestions.push({ Topic: title.Title, Questions: questions });
  });
  return TitlesAndQuestions;
});

export const selectDragAnDropHelpers = createSelector(selectTopics, (topics, { topicIndex }) => {
  let dragAndDropChecker = {};
  let dragAndDropIndexHelper = [];
  let constraintsParentHelperObj: { [id: string]: number[] } = {};
  let index = 0;
  if (!!topics && !!topics[topicIndex]) {
    topics[topicIndex].Questions.forEach((question) => {
      dragAndDropChecker[question.id] = {
        currentIndex: index,
        isConstrained: question.Constrained,
        constrainedId: question.Constrained_with as string,
      };
      dragAndDropIndexHelper[index] = question.id;
      if (question.Constrained) {
        if (!!constraintsParentHelperObj[question.Constrained_with as string]) {
          constraintsParentHelperObj[question.Constrained_with as string].push(index);
        } else {
          constraintsParentHelperObj[question.Constrained_with as string] = [index];
        }
      }
      index++;
    });

    return {
      dragAndDropChecker,
      dragAndDropIndexHelper,
      constraintsParentHelperObj,
    };
  }
});
