import { findIndexAtArray } from 'src/app/data/data-handlers/utils.service';
import { updateTopicsUser } from './../../../Store/topics-store/topic.actions';
import { selectTopicsLoading } from './../../../Store/topics-store/topics.selectors';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { Component, OnInit, AfterViewInit, Output, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionnairesAssignedToUser } from 'src/app/data/models/QuestionnairesAssignedToUser';
import { User } from 'src/app/data/models/User';
import { TopicsAnswered } from 'src/app/data/models/TopicsAnswered';
import { Input, EventEmitter } from '@angular/core';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { QuestionnaireActionTypes } from 'src/app/Store/assignedQuestionnaires/actions/assigned-questionnaires.actions';

@Component({
  selector: 'app-topic-menu',
  templateUrl: './topic-menu.component.html',
  styleUrls: ['./topic-menu.component.scss'],
})
export class TopicMenuComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  questionnaire: QuestionnairesAssignedToUser;

  @Input()
  getUsername: {};

  @Input()
  activeTopicIndex = 0;

  @Input()
  countTopicsOfUser = 0;

  @Input()
  currentUser: string;

  @Input()
  lang: string;

  @Output()
  activeTopicEmit = new EventEmitter<number>();

  @Output()
  reloadAssingedment = new EventEmitter<boolean>();

  @Output()
  totalTopics = new EventEmitter<number>();

  assignedUser: string[];

  topicsChecked: TopicsAnswered[] = [];

  topicLoading: Observable<boolean>;

  reAssignMultiplaMode = false;

  shouldShowMultipla = false;

  filterOfTopics:
    | 'Completed'
    | 'In Progress'
    | 'Draft'
    | 'No Filter'
    | 'Assigned_To_Me'
    | 'Assigned_To_Others'
    | 'NotCompleted' = 'No Filter';

  overlay = false;

  completedQuests = 0;

  numberOfUnreadedNotes = 0;

  reviewMode: boolean = false;

  constructor(private dialog: GeneralDialogService, private store: Store, private magicStrings: MagicStringsService) {}
  ngAfterViewInit(): void {
    this.scrollToIndex(this.activeTopicIndex);
  }

  ngOnChanges() {
    this.scrollToIndex(this.activeTopicIndex);
    this.reviewMode = this.currentUser !== this.questionnaire.Assigned_To;
  }

  ngOnInit(): void {
    this.totalTopics.emit(this.questionnaire.Topics.length);

    if (Number(this.questionnaire['Number_Of_Notes']) > Number(this.questionnaire['Number_of_Readed_Notes'])) {
      this.numberOfUnreadedNotes =
        Number(this.questionnaire['Number_Of_Notes']) - Number(this.questionnaire['Number_of_Readed_Notes']);
    } else {
      this.numberOfUnreadedNotes = null;
    }

    this.shouldShowMultipla =
      this.questionnaire.Collaborative &&
      (this.questionnaire.Assigned_To === this.currentUser ||
        (this.questionnaire.ALL_REASSIGN && this.countTopicsOfUser !== 0));

    this.topicLoading = this.store.select(selectTopicsLoading).pipe(
      map((res) => {
        if (!res) {
          this.reloadAssingedment.emit(true);
        }
        return res;
      })
    );
  }

  getNotes() {
    this.numberOfUnreadedNotes = null;

    this.dialog.openNotesModal({
      notes: null,
      Quest_Assing_ID: this.questionnaire.Assigned_id,
      pageIndex: 0,
      Status: this.questionnaire.Assigned_Status,
      user: this.questionnaire.Assigned_To,
    });

    this.dialog.openNotesModalResponse().subscribe((modalclosed) => this.reloadAssingedment.emit(true));
  }

  setActiveTopic($event, index: number) {
    this.activeTopicEmit.emit(index);
  }

  reAssignTopic(topic: TopicsAnswered) {
    this.assignedUser = [topic.Assigned_To];
    this.topicsChecked = [];
    this.toggleVisibility();
  }

  reassignMultiplaMode() {
    this.reAssignMultiplaMode = false;
    this.toggleVisibility();
  }
  closeMultiplaMode() {
    this.reAssignMultiplaMode = false;
  }
  openMultiplaMode() {
    this.reAssignMultiplaMode = true;
  }

  cancel() {
    this.topicsChecked = [];
    this.toggleVisibility();
  }

  toggleVisibility() {
    this.overlay = !this.overlay;
  }

  reAssingMultiple(value: { user: User; note: string }) {
    this.toggleVisibility();
    let topicsId = [];

    if (this.topicsChecked.length > 0) {
      this.topicsChecked.map((topic) => {
        topicsId.push({ id: topic.Assigned_id });
      });
      this.topicsChecked = [];
    } else {
      topicsId.push({
        id: this.questionnaire.Topics[this.activeTopicIndex].Assigned_id,
      });
    }
    this.store.dispatch(
      updateTopicsUser({
        Assigned_id: this.questionnaire.Assigned_id,
        topicAssignedIds: topicsId,
        note: value.note,
        backToResponsible: false,
        newUser: value.user.username,
        fromHome: false,
      })
    );
    const notes = {
      Assigned_id: this.questionnaire.Assigned_id,
      Topic_Assigned_id: '',
      Note: value.note,
      pageIndex: 0,
    };
    if (value.note !== '' && !!value.note) {
      this.store.dispatch({
        type: QuestionnaireActionTypes.SubmitNotes,
        notes,
      });
      this.store.dispatch({
        type: QuestionnaireActionTypes.ReadNotes,
        quest_id: this.questionnaire.Assigned_id,
      });
    }
  }

  addRemoveTopic(event: Event, topic: TopicsAnswered) {
    if (this.topicsChecked.includes(topic)) {
      let index = this.topicsChecked.findIndex((top) => top.id === topic.id);
      this.topicsChecked.splice(index, 1);
    } else {
      this.topicsChecked.push(topic);
    }
  }

  filterTopics(
    filter:
      | 'Completed'
      | 'In Progress'
      | 'Draft'
      | 'No Filter'
      | 'NotCompleted'
      | 'Assigned_To_Me'
      | 'Assigned_To_Others'
  ) {
    this.filterOfTopics = filter;
    var index;
    switch (filter) {
      case 'No Filter':
        this.setActiveTopic(null, 0);
        this.scrollToIndex(0);
        break;
      case 'Assigned_To_Me':
        index = findIndexAtArray(
          this.questionnaire.Topics,
          this.questionnaire.Topics.find((o) => o.Assigned_To === this.currentUser)
        );
        this.activeTopicIndex = index;
        this.setActiveTopic(null, index);
        break;
      case 'Assigned_To_Others':
        index = findIndexAtArray(
          this.questionnaire.Topics,
          this.questionnaire.Topics.find((o) => o.Assigned_To !== this.currentUser)
        );
        this.activeTopicIndex = index;
        this.setActiveTopic(null, index);
        break;
      case 'Completed':
        index = findIndexAtArray(
          this.questionnaire.Topics,
          this.questionnaire.Topics.find((o) => o.Assigned_Status === 'Completed')
        );
        this.activeTopicIndex = index;
        this.setActiveTopic(null, index);
        break;
      case 'Draft':
        index = findIndexAtArray(
          this.questionnaire.Topics,
          this.questionnaire.Topics.find((o) => o.Assigned_Status === 'Assigned')
        );
        this.activeTopicIndex = index;
        this.setActiveTopic(null, index);
        break;
      case 'In Progress':
        index = findIndexAtArray(
          this.questionnaire.Topics,
          this.questionnaire.Topics.find((o) => o.Assigned_Status === 'In Progress')
        );
        this.activeTopicIndex = index;
        this.setActiveTopic(null, index);
        break;
      default:
        this.setActiveTopic(null, 0);
    }
  }

  scrollToIndex(i: number) {
    // inside ngAfterViewInit() to make sure the list items render or inside ngAfterViewChecked() if you are anticipating live data using @Inputs
    const itemToScrollTo = document.getElementById('item-' + i);
    // null check to ensure that the element actually exists
    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView(true);
    }
  }
}
