import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private httpClient: HttpClient) {}

  public login(usernameValue, passwordValue) {
    const body: any = {
      username: usernameValue,
      password: passwordValue,
    };

    return this.httpClient.post('login', body);
  }

  public loginWithSso(usernameValue, token) {
    const body: any = {
      username: usernameValue,
      token: token,
    };

    return this.httpClient.post('loginWithSSO', body);
  }

  public forgotPassword(emailValue) {
    const body: any = {
      email: emailValue,
    };

    return this.httpClient.post('forgot-password', body);
  }

  public resetPassword(body: { username: string; password: string }) {
    return this.httpClient.put('reset-password', body);
  }
}
