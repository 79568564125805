<!-- <div> -->
<mat-dialog-content class="dialogCustomDim">
  <div>
    <strong>{{ dialogTitle }}</strong>
  </div>
  <div>
    <strong>{{ strongText }}</strong>
  </div>
  <div [formGroup]="freeTextForm">
    <mat-form-field class="my-form-field" style="width: 100%">
      <textarea
        [placeholder]="placeholder"
        type="text"
        matInput
        name="note"
        rows="3"
        name="freeText"
        formControlName="freeText"
        cols="50"
      ></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button *ngIf="!showSaveButton" (click)="save()">
    {{ saveText }}
  </button>
  <button mat-button (click)="close()" [mat-dialog-close]="true">
    {{ cancelText }}
  </button>
</mat-dialog-actions>
<!-- </div> -->
