import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BottomCalculationPipePipe } from '../features/questionnaire-creation/vincolata-lines/bottom-calculation-pipe/bottom-calculation-pipe.pipe';
import { AddFullWatchListUsersPipe } from '../features/questionnaire-management/assign-model-page/assing-model-table/addWatchListUsers/add-full-watch-list-users.pipe';
import { GetQuestionnaireWatchListPipe } from '../features/questionnaire-management/assign-model-page/assing-model-table/getWatchlistPipe/get-questionnaire-watch-list.pipe';
import { TopicsCompletedPipePipe } from '../features/questionnaire-to-answer/topic-menu/topics-pipe/topics-completed-pipe.pipe';
import { ArticleDictionaryPipe } from '../utils/pipes/article-dictionary.pipe';
import { ConvertToItalianPipe } from '../utils/pipes/convert-to-italian.pipe';
import { HomeQuestionnaireFilterPipe } from '../utils/pipes/home-questionnaire-filter.pipe';
import { IsCompletedProjectPipe } from '../utils/pipes/is-completed-project.pipe';
import { IsConstraintPipe } from '../utils/pipes/is-constraint.pipe';
import { IsIncludedPipe } from '../utils/pipes/is-included.pipe';
import { ModelFilterPipe } from '../utils/pipes/model-filter.pipe';
import { PrepositionDictionaryPipe } from '../utils/pipes/preposition-dictionary.pipe';
import { ReverseArrayPipe } from '../utils/pipes/reverse-array.pipe';
import { roundPipe } from '../utils/pipes/round.pipe';
import { TrueFalsePipe } from '../utils/pipes/true-false.pipe';
import { VerbPronounDictionaryPipe } from '../utils/pipes/verb-pronoun-dictionary.pipe';
import { VerifyOptionsPipe } from '../utils/pipes/verify-options.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ArticleDictionaryPipe,
    BottomCalculationPipePipe,
    ConvertToItalianPipe,
    HomeQuestionnaireFilterPipe,
    IsCompletedProjectPipe,
    IsConstraintPipe,
    IsIncludedPipe,
    ModelFilterPipe,
    ReverseArrayPipe,
    TrueFalsePipe,
    TopicsCompletedPipePipe,
    VerifyOptionsPipe,
    GetQuestionnaireWatchListPipe,
    AddFullWatchListUsersPipe,
    roundPipe,
    PrepositionDictionaryPipe,
    VerbPronounDictionaryPipe,
  ],
  exports: [
    ArticleDictionaryPipe,
    PrepositionDictionaryPipe,
    VerbPronounDictionaryPipe,
    BottomCalculationPipePipe,
    ConvertToItalianPipe,
    HomeQuestionnaireFilterPipe,
    IsCompletedProjectPipe,
    IsConstraintPipe,
    IsIncludedPipe,
    ModelFilterPipe,
    ReverseArrayPipe,
    TopicsCompletedPipePipe,
    TrueFalsePipe,
    roundPipe,
    VerifyOptionsPipe,
    GetQuestionnaireWatchListPipe,
    AddFullWatchListUsersPipe,
  ],
})
export class PipeModule {}
