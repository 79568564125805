import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { requestNotes } from '../../models/request-apis/requestNotes';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/utils/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GetQuestionnairService {
  constructor(private httpClient: HttpClient, private authService: AuthService) {}

  public exportSingleCsv(id: string, lang: string, topic: string, downloadFiles: boolean) {
    let link;
    link = environment.apiUrl;

    window.open(
      `${link}/exportSingleQuestionnaire?questId=${id}&lang=${lang}&topic=${topic}&downloadFiles=${downloadFiles}&secret_token=${this.authService.getUserToken()}`,
      '_blank'
    );
  }

  public getModelCsv(id: string, typeExcel: string, lang: string, topic: string) {
    let link;
    link = environment.apiUrl;

    window.open(
      `${link}/getModelCsv?id=${id}&lang=${lang}&topic=${topic}&secret_token=${this.authService.getUserToken()}&type=${typeExcel}`,
      '_blank'
    );
  }

  public getQuestionnairyStructuresLength(usernameValue) {
    const body: any = {
      user: usernameValue,
    };

    return this.httpClient.get('getQuestionnairyStructuresLength', body);
  }

  public getQuestionnairyStructures(
    pageIndexValue,
    pageSizeValue,
    sortCol,
    sortOrderValue,
    getArchived,
    searchString?: string,
    modelFilter?: string,
    creatorFilter?: string,
    draft?: boolean,
    active?: boolean,
    lastAssignmentFromFilter?: string,
    lastAssignmentToFilter?: string,
    lastChangeFromFilter?: string,
    lastChangeToFilter?: string,
    creationFromFilter?: string,
    creationToFilter?: string
  ) {
    searchString = searchString ? `&searchString=${encodeURIComponent(searchString)}` : ``;
    modelFilter = modelFilter ? `&modelFilter=${encodeURIComponent(modelFilter)}` : ``;
    creatorFilter = creatorFilter ? `&creatorFilter=${encodeURIComponent(creatorFilter)}` : ``;
    let archivedQuery = getArchived ? `&archived=${getArchived}` : ``;
    let draftQuery = draft ? `&statusDraftFilter=${draft}` : ``;
    let activeQuery = active ? `&statusActiveFilter=${active}` : ``;
    lastAssignmentFromFilter = lastAssignmentFromFilter
      ? `&lastAssignmentFromFilter=${encodeURIComponent(lastAssignmentFromFilter)}`
      : ``;
    lastAssignmentToFilter = lastAssignmentToFilter
      ? `&lastAssignmentToFilter=${encodeURIComponent(lastAssignmentToFilter)}`
      : ``;
    lastChangeFromFilter = lastChangeFromFilter
      ? `&lastChangeFromFilter=${encodeURIComponent(lastChangeFromFilter)}`
      : ``;
    lastChangeToFilter = lastChangeToFilter ? `&lastChangeToFilter=${encodeURIComponent(lastChangeToFilter)}` : ``;
    creationFromFilter = creationFromFilter ? `&creationFromFilter=${encodeURIComponent(creationFromFilter)}` : ``;
    creationToFilter = creationToFilter ? `&creationToFilter=${encodeURIComponent(creationToFilter)}` : ``;

      var getString = `searchModel?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortCol}&sortOrder=${sortOrderValue}${archivedQuery}${searchString}${modelFilter}${creatorFilter}${draftQuery}${activeQuery}${lastAssignmentFromFilter}${lastAssignmentToFilter}${lastChangeFromFilter}${lastChangeToFilter}${creationFromFilter}${creationToFilter}`;
    return this.httpClient.get(getString);
  }

  public getQuestionnairesAssignedLength(usernameValue) {
    const body: any = {
      user: usernameValue,
    };

    return this.httpClient.get('getQuestionnairesAssignedLength', body);
  }

  public getQuestionnairesAssigned(usernameValue, pageIndexValue, pageSizeValue, sortColValue, sortOrderValue) {
    const body: any = {
      user: usernameValue,
      pageIndex: pageIndexValue,
      pageSize: pageSizeValue,
      sortCol: sortColValue,
      sortOrder: sortOrderValue,
    };

    const getString = `getQuestionnairesAssigned?pageIndex=${pageIndexValue}&pageSize=${pageSizeValue}&sortCol=${sortColValue}&sortOrder=${sortOrderValue}`;

    return this.httpClient.get(getString);
  }

  public getQuestionnaire(body: { id: string }) {
    let stringOfRequest = `getQuestionnaireByID?id=${body.id}`;
    return this.httpClient.get(stringOfRequest);
  }

  public getNotes(body: requestNotes) {
    let stringOfRequest = `getNotes?Assigned_id=${body['data'].Assigned_id}&Topic_Assigned_id=${body['data'].Topic_Assigned_id}&user=${body['data'].user}`;
    return this.httpClient.get(stringOfRequest);
  }

  public getQuestionnaireByUser(body: { pageIndex: number }) {
    let stringOfRequest = `getQuestionnairesHomePage?pageIndex=${body.pageIndex}`;
    return this.httpClient.get(stringOfRequest);
  }

  public getFullQuestionnaire(body: string) {
    let stringOfRequest = `getAssignment?id=${body}`;
    return this.httpClient.get(stringOfRequest);
  }
}
