import { FormGroup } from '@angular/forms';
import { AssignQuestionnairesModel } from '../models/AssignQuestionnairesModel';
import { ElencoTopic } from '../models/ElencoTopic';
import { Question } from '../models/Question';
import { QuestionnairesAssigned } from '../models/QuestionnairesAssigned';
import { QuestionnairesAssignedToUser } from '../models/QuestionnairesAssignedToUser';
import { QuestionnairesStructure } from '../models/QuestionnairesStructure';

import { assignQuestionnaireRequest } from '../models/request-apis/assignQuestionnaireRequest';
import { createNotes } from '../models/request-apis/createNotes';
import { getAssignment } from '../models/response-apis/getAssignment';
import { User } from '../models/User';

// insert function that allows us to insert items at a specific position of an array
export function insert(arr, index, newItem) {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
}

export const copyArrayAndAddValueIntoArray = <T>(array: T[], value: T): T[] => [].concat(array, value);

export function reOrder(resultOrder: number, arrayOfItems: ElencoTopic[] | any, addFlag: boolean): ElencoTopic[] {
  let index = 0;
  return arrayOfItems
    .map((topic) => ({ ...topic }))
    .map((element) => {
      index++;
      return { ...element, Order: index, id: element.id };
    });
}

export function filterOutElements(arr: any[], element: any): string[] {
  return arr.filter((option) => option !== element.toLowerCase().replace(/\s/g, ''));
}

export function filterOutElementsForQuestions(arr: any[], element: any, index: number) {
  return arr[index].Questions.filter((option) => option !== element.toLowerCase().replace(/\s/g, ''));
}

export function deepCopy(oldObj: any) {
  let newObj = oldObj;

  if (oldObj && typeof oldObj === 'object') {
    newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};

    for (const i of oldObj) {
      newObj[i] = this.deepCopy(oldObj[i]);
    }
  }
  return newObj;
}
export function editTopic<T>(oldObj: T[], index: number, newTopic: any, indexOld: number): T[] {
  if (indexOld === index) {
    oldObj[index] = newTopic;
  } else {
    let temp = oldObj[index];
    oldObj[index] = newTopic;
    oldObj[indexOld] = temp;
  }
  return oldObj;
}

export function copyAndDelete<T>(oldObj: T[], index: number): T[] {
  let newobj = deepCopyV2(oldObj);

  newobj.splice(index, 1);
  return newobj;
}

// deepCopy function allows us to copy objects without being identical after the copy
export function deepCopyV2(oldObj: any[] | any): any[] | any {
  return JSON.parse(JSON.stringify(oldObj));
}

export function getTheIds(array: any[]): string[] {
  let ids = [];
  array.forEach((quest) => {
    ids.push(quest.Questionnaire_id);
  });

  return ids;
}

export function createDeleteArray(array: any[]): { id: string }[] {
  let ids = [];
  array.forEach((quest) => {
    ids.push({ id: quest.Questionnaire_id });
  });

  return ids;
}

export function createAssignedDeleteArray(array: any[]): { id: string }[] {
  let ids = [];
  array.forEach((quest) => {
    ids.push({ id: quest.Assigned_id, type: 'QUESTIONNAIRE' });
  });

  return ids;
}

export function transformBody(id: string[], status: string): any[] {
  const body = [];

  id.forEach((id) => {
    body.push({ id, Status: status });
  });

  return body;
}

export function transformBody2(id: string[]): any[] {
  const body = [];

  id.forEach((id) => {
    body.push({ id });
  });

  return body;
}

// Function that returns the index of topic inside the Questionnaire object
export function findIndexAtArray(array: any[], element): number {
  const indexNum = array.findIndex((x) => {
    return x === element;
  });
  return indexNum;
}

export function convertToAssigned(data: {
  quest: QuestionnairesStructure;
  project: string;
  collab: boolean;
  expiryDate: string;
  user: User;
}): QuestionnairesAssigned {
  return {
    ...data.quest,
    Project: data.project,
    Assigned_To: data.user.username,
    Assigned_Status: 'Assigned',
    Expiry_Date: data.expiryDate,
    Collaborative: data.collab,
  } as QuestionnairesAssigned;
}

export function convertDataToSubmitNotes(notes: createNotes) {
  return {
    ...notes,
    Assigned_id: notes.Assigned_id,
    Topic_Assigned_id: notes.Topic_Assigned_id,
    Note: notes.Note,
  };
}

export function convertToAssignRequest(data: AssignQuestionnairesModel): assignQuestionnaireRequest {
  let quests = [];
  let project: assignQuestionnaireRequest = {
    EXPIRY_DATE: data.expiry_date,
    COLLABORATIVE: data.collaborative,
    PROJECT_STATUS: data.PROJECT_STATUS,
    TITLE: data.project,
    DESCRIPTION: data.projectDescription,
    VIEWABLE_FROM_ALL: data.VIEWABLE_FROM_ALL,
    ALL_REASSIGN: data.ALL_REASSIGN,
    RESPONSIBLE_REASSIGN: data.RESPONSIBLE_REASSIGN,
    quests: [],
    EXPORTABLE_DATA: data.EXPORTABLE_DATA,
  };

  if (data.users.length > 0) {
    data.users.forEach((user) => {
      data.questionnairesToBeAssigned.forEach((quest) => {
        let username = !!user.username ? user.username : user;

        quests.push({
          id: quest.Questionnaire_id,
          Project: data.project,
          user: username,
          Note: data.Note,
          watchlist: data.watchListUsers[username as string]!! ? data.watchListUsers[username as string] : [],
        });
      });
    });
  } else {
    data.questionnairesToBeAssigned.forEach((quest) => {
      quests.push({
        id: quest.Questionnaire_id,
        Project: data.project,
        user: null,
        Note: data.Note,
        watchlist: [],
      });
    });
  }
  project.quests = quests;
  return project;
}

export function areAllCompleted(quests: QuestionnairesAssigned[] | QuestionnairesStructure[]): boolean {
  let flag = false;
  for (const quest of quests) {
    flag = quest.Questionnaire_Status === 'Draft';
    if (flag) {
      break;
    }
  }
  return flag;
}

export function convertToNewQuest(quest: any, newTitle: string): QuestionnairesStructure {
  const newQuestionnaire = {
    id: quest.id,
    Title: newTitle,
    Description: quest.Description,
    Status: 'Draft',
    Topics: quest.Topics,
  } as QuestionnairesStructure;
  return newQuestionnaire;
}

export function checkIfDuplicateExists(w) {
  return new Set(w).size !== w.length;
}

export function convertQuestionToBeStored(data: FormGroup, ids): Question {
  let question = {
    id: data.value.id,
    Position: data.value.Question_Position,
    TypeOfQuestion: data.value.Question_Type,
    Obligatory: data.value.Question_Obbligatory,
    Constrained: data.value.Constraints.Constrained,
    Constrained_with: data.value.Constraints.Constrained_with,
    ConstraintOptions: data.value.Constraints.Constraint_options,
    Description: data.value.Question_Desc,
    Question_Text: data.value.Question_Text,
    Reg_Ex: data.value.Verification.Regular_Expression,
    TypeOfAnswer: data.value.Answer_Type,
    TypeOfVerificationInput: data.value.Verification.Verify_Input_Options,
    Verify_Input: data.value.Verification.Verify_Input,
    // freeTextConstraint: true,
    Check_List_Box_Options: data.value.ListBoxOption.Check_List_Box_Options,
    Check_List_Box_Options_id: ids,
    Check_List_Box_Options_Score: data.value.ListBoxOption.Check_List_Box_Options_Score,
    minimum_answers: data.value.ListBoxOption.MinimumOptions,
    maximum_answers: data.value.ListBoxOption.MaximumOptions,
    score: data.value.Score,
    File_Type: data.value.File_Type,
    yesNo_Score: data.value.ListBoxOption.yesNo_Score,
    Comment: data.value.Comment,
  };

  if (question['Constrained'] === undefined) {
    question['Constrained'] === false;
  }
  if (question['ConstraintOptions'] === undefined) {
    question['ConstraintOptions'] = [];
  }

  if (!question['Constrained']) {
    delete question['Constrained_with'];
    delete question['ConstraintOptions'];
    // delete question['freeTextConstraint'];
  } else {
    // If it has constraintOptions it doesn't have parent Free text or File
    if (
      !!question['ConstraintOptions'] &&
      question['ConstraintOptions'].length > 0 &&
      question['ConstraintOptions'][0] !== null
    ) {
      // question['freeTextConstraint'] = false;
    } else {
      question['ConstraintOptions'] = [];
      // question['freeTextConstraint'] = true;
    }
  }

  if (question['TypeOfQuestion'] === 'Free text') {
    if (
      question['Verify_Input'] === false ||
      question['Verify_Input'] === undefined ||
      question['TypeOfAnswer'] === 'Number'
    ) {
      delete question['TypeOfVerificationInput'];
      delete question['Verify_Input_Options'];
      delete question['Reg_Ex'];
    } else if (question['Verify_Input'] === true && question['TypeOfVerificationInput'] !== 'customized') {
      delete question['Reg_Ex'];
    }
  } else {
    delete question['Verify_Input'];
    delete question['TypeOfVerificationInput'];
    delete question['Verify_Input_Options'];
    delete question['TypeOfAnswer'];
    delete question['Reg_Ex'];
  }

  if (question['TypeOfQuestion'] === 'List box' || question['TypeOfQuestion'] === 'Check-list box') {
    if (question['TypeOfQuestion'] === 'List box') {
      question['minimum_answers'] = question['maximum_answers'] = 1;
    }
    question.Check_List_Box_Options.forEach((element) => {
      if (element === '') {
        question.Check_List_Box_Options.splice(findIndexAtArray(question.Check_List_Box_Options, element), 1);
      }
    });
    if (checkIfDuplicateExists(question['Check_List_Box_Options'])) {
      question['Check_List_Box_Options'] = question['Check_List_Box_Options'].filter((v, i, a) => a.indexOf(v) === i);
    }
  } else {
    question['Check_List_Box_Options'] = [];
    question['Check_List_Box_Options_Score'] = [];
    delete question['minimum_answers'];
    delete question['maximum_answers'];
  }

  if (question['TypeOfQuestion'] !== 'Yes or no') {
    question['yesNo_Score'] = [];
  }

  if (question['TypeOfQuestion'] !== 'File') {
    delete question['File_Type'];
  }

  return question;
}

export function convertToQuestionnaireAssingedToUser(data: getAssignment): {
  questionnaire: QuestionnairesAssignedToUser;
  topicMetrics: {
    allTopics: number;
    completedTopics: number;
    completedTopicsOfUser: number;
    topicsOfUser: number;
  };
} {
  const template = data['QUESTIONNAIRE'];
  const assingment = data['ASSIGNMENT'];
  let assignmentTopics = {};
  let assignmentAnswers = {};
  assingment.Topics.forEach((topic) => {
    assignmentTopics[topic.Topic_id] = topic;
  });
  assingment.Answers_Inserted.forEach((answer) => {
    assignmentAnswers[answer.Question_id] = answer;
  });

  let questionnaire = new QuestionnairesAssignedToUser();
  questionnaire = { ...questionnaire, ...template };
  questionnaire.Assigned_To = assingment.Assigned_To;
  questionnaire.Assigned_id = assingment.Assigned_id;
  questionnaire.Collaborative = assingment.Collaborative;
  questionnaire.Number_Of_Topics = template['Topics'].length;
  questionnaire.Project = assingment.Project;
  questionnaire.PROJECT = assingment.PROJECT;
  questionnaire.Assigned_Status = assingment.Assigned_Status;
  questionnaire.Expiry_Date = assingment.Expiry_Date;
  questionnaire.Completed_Topics = 0;
  questionnaire.Number_Of_Notes = assingment['Number_Of_Notes'];
  questionnaire.Number_of_Readed_Notes = assingment['Number_of_Readed_Notes'];
  questionnaire.Collaborative = assingment.PROJECT.COLLABORATIVE;
  questionnaire.RESPONSIBLE_REASSIGN = assingment.PROJECT.RESPONSIBLE_REASSIGN;
  questionnaire.ALL_REASSIGN = assingment.PROJECT.ALL_REASSIGN;
  questionnaire.VIEWABLE_FROM_ALL = assingment.PROJECT.VIEWABLE_FROM_ALL;
  questionnaire.PROJECT_DESCRIPTION = assingment.PROJECT.DESCRIPTION;
  questionnaire.EXPORTABLE_DATA = assingment.PROJECT.EXPORTABLE_DATA;
  questionnaire.Topics = questionnaire.Topics.filter((topic) => {
    if (assignmentTopics[topic.id]) {
      topic.Assigned_To = assignmentTopics[topic.id].Assigned_To;
      topic.Assigned_Status = assignmentTopics[topic.id].Assigned_Status;
      topic.Assigned_id = assignmentTopics[topic.id].Assigned_id;
      topic.Expiry_Date = assignmentTopics[topic.id].Expiry_Date;
      topic.Compile_from = assignmentTopics[topic.id]['Compile_from'];
    } else {
      topic.Assigned_To = questionnaire.Assigned_To;
      topic.Assigned_Status = questionnaire.Assigned_Status;
      topic.Assigned_id = questionnaire.Assigned_id;
      topic.Expiry_Date = questionnaire.Expiry_Date;
    }

    if (topic.Assigned_Status === 'Completed') {
      questionnaire.Completed_Topics++;
    }
    topic.Questions.forEach((quest) => {
      if (assignmentAnswers[quest.id]) {
        quest.Answers = assignmentAnswers[quest.id].Answers;
      } else {
        quest.Answers = [{ Answer_id: null, Value_Answer: null, Comment: null }];
      }
    });
    return topic;
  });
  return { questionnaire: questionnaire, topicMetrics: data['topicMetrics'] };
}

export function isPatternValid(pattern) {
  try {
    ''.match(new RegExp(pattern));
    return true;
  } catch (err) {
    return false;
  }
}

export function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

export function arraysAreIdentical(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (var i = 0, len = arr1.length; i < len; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export function arraysWithObjectsIdentical(arr1, arr2) {
  arr1.length === arr2.length && arr1.every((o, idx) => objectsAreSame(o, arr2[idx]));
}

export function objectsAreSame(x, y) {
  var objectsAreSame = true;
  for (var propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}
