import { createReducer, on } from '@ngrx/store';
import { deepCopyV2 } from 'src/app/data/data-handlers/utils.service';
import { QuestionnairesAssignedToUser } from 'src/app/data/models/QuestionnairesAssignedToUser';
import { updateTopicsUser, updateTopicsUserSuccess } from '../../topics-store/topic.actions';
import * as AssignedQuestionnairesActions from '../actions/assigned-questionnaires.actions';
import {
  emptyQuestionnaireToBeAnswered,
  errorHandled,
  getQuestionnaireAssingedToUser,
  getQuestionnaireAssingedToUserSuccess,
  getQuestionnaireNotes,
  getQuestionnaireNotesSuccess,
  getQuestionnaireToBeAnswered,
  getQuestionnaireToBeAnsweredSuccess,
  loadAssignedQuestionnairessFailure,
  loadAssignedQuestionnairessSuccess,
  postAnswersOfQuestionnaire,
  postAnswersOfQuestionnaireSuccess,
  SubmitNotesSucess,
  SubmitQuestionnaires,
  SubmitQuestionnairesSuccess,
} from '../actions/assigned-questionnaires.actions';
import { initialStateAssigned } from '../assigned-state';

export const assignedQuestionnairesFeatureKey = 'assignedQuestionnaires';

export const asssignedQuestionnaireReducer = createReducer(
  initialStateAssigned,
  on(loadAssignedQuestionnairessSuccess, (state, { AssignedQuestionnaires, total, pageIndex }) => ({
    ...state,
    AssignedQuestionnaires,
    total,
    pageIndex,
    loadingAssigned: false,
  })),
  on(getQuestionnaireNotes, (state) => ({
    ...state,
    loadingNotes: true,
  })),
  on(loadAssignedQuestionnairessFailure, (state) => ({
    ...state,
    loadingNotes: false,
    loadingAssigned: false,
    projectLoading: false,
    submittingAnswers: false,
    error: true,
  })),
  on(errorHandled, (state) => ({
    ...state,
    error: false,
  })),
  on(AssignedQuestionnairesActions.ERROR, (state) => ({
    ...state,
    loadingAssigned: false,
    loadingNotes: false,
  })),
  on(getQuestionnaireNotesSuccess, (state, notes) => ({
    ...state,
    Notes: notes['data']['data'],
    loadingNotes: false,
  })),
  on(SubmitNotesSucess, (state) => ({
    ...state,
    Notes: [],
    loadingNotes: false,
  })),
  on(SubmitQuestionnaires, (state, ids) => {
    let idsa = [];
    ids['ids'].forEach((idObj) => idsa.push(idObj.id));
    return { ...state, idsSubmitted: idsa, submittingAnswers: true };
  }),
  on(SubmitQuestionnairesSuccess, (state, ids) => {
    return {
      ...state,
      submittingAnswers: false,
    };
  }),
  on(getQuestionnaireAssingedToUser, (state) => ({
    ...state,
    loadingAssigned: true,
  })),
  on(getQuestionnaireAssingedToUserSuccess, (state, data) => {
    let objs = {};
    data['data'].forEach((quest) => {
      objs[quest.questAssignId] = quest;
    });
    return {
      ...state,
      loadingAssigned: false,
      AssignedQuestionnairesToUser: objs,
    };
  }),
  on(getQuestionnaireToBeAnswered, (state, data) => ({
    ...state,
    loadingFileRepo: true,
  })),
  on(getQuestionnaireToBeAnsweredSuccess, (state, id) => ({
    ...state,
    questionnaireToBeAnswered: id['data']['questionnaire'],
    topicMetrics: id['data']['topicMetrics'],
    loadingFileRepo: false,
    containsFile: id['data'].questionnaire.Topics.some((topic) =>
      topic.Questions.some((question) => {
        if (question.TypeOfQuestion === 'File' && question.Answers[0].Value_Answer !== null) {
          return true;
        }
      })
    ),
  })),
  on(emptyQuestionnaireToBeAnswered, (state) => ({
    ...state,
    questionnaireToBeAnswered: null,
  })),
  on(postAnswersOfQuestionnaire, (state) => ({
    ...state,
    submittingAnswers: true,
  })),
  on(postAnswersOfQuestionnaireSuccess, (state, data) => ({
    ...state,
    questionnaireToBeAnswered: updateTopicOfQuestionnaireToBeAnswered(
      state.questionnaireToBeAnswered,
      data['data']
    ),
    topicMetrics: data['data']['topicMetrics'],
    submittingAnswers: false,
  })),
  on(AssignedQuestionnairesActions.loadAssignedQuestionnaires, (state) => ({
    ...state,
    loadingAssigned: true,
  })),
  on(AssignedQuestionnairesActions.updateAssingedQuestionnaireSuccess, (state) => ({
    ...state,
    loadingAssigned: false,
  })),
  on(AssignedQuestionnairesActions.deleteAssignQuestionnairesAction, (state, payload) => ({
    ...state,
    AssignedQuestionnaires: state.AssignedQuestionnaires.filter((quest) => !payload['deletedIds'].includes(quest)),
  })),
  on(AssignedQuestionnairesActions.deleteAssignQuestionnairesSuccess, (state) => state),
  on(AssignedQuestionnairesActions.updateAssingedQuestionnaire, (state, payload) => ({
    ...state,
    loadingAssigned: true,
  })),
  on(AssignedQuestionnairesActions.reAssignmentTopicSuccess, (state, payload) => ({
    ...state,
    questionnaireToBeAnswered: {
      ...state.questionnaireToBeAnswered,
      Topics: state.questionnaireToBeAnswered.Topics.map((topic) => {
        if (topic.Assigned_id === state.TopicId) {
          return { ...topic, Assigned_Status: 'In Progress' };
        } else {
          return topic;
        }
      }),
    },
  })),
  on(AssignedQuestionnairesActions.reAssignTopic, (state, payload) => ({
    ...state,
    TopicId: payload.id,
  })),
  on(updateTopicsUser, (state) => ({
    ...state,
    loadingAssigned: true,
  })),
  on(updateTopicsUserSuccess, (state, { Assigned_id, fromHome }) => {
    const newArray = deepCopyV2(JSON.stringify(state.AssignedQuestionnairesToUser));
    const str = JSON.parse(newArray);
    if (fromHome) {
      delete str[Assigned_id];
    }
    return {
      ...state,
      AssignedQuestionnairesToUser: str,
      loadingAssigned: false,
    };
  })
);

function updateTopicOfQuestionnaireToBeAnswered(
  questionnaire: QuestionnairesAssignedToUser,
  data: {}
) {
  let topics = questionnaire.Topics.map((topicAssinged) => {
    if (topicAssinged.Assigned_id === data['Topic_Assigned_id']) {
      let newQuestions = [];
      newQuestions = topicAssinged.Questions.map((question) => {
        let newAnswers = [];
        let hasAnswer = false;
        data['answers'].forEach(answer => {
          if(answer['Question_id'] === question.id) {
            hasAnswer = true;
          }
        })
        // If question has no answer then add an empty null answer else add new Answer if changed
        if(!hasAnswer) {
          newAnswers.push({
            Answer_id: null,
            Value_Answer: null,
            Comment: null,
          });
        }else {
          data['answers'].map((answer) => {
            if (question.id === answer['Question_id']) {
              newAnswers.push({
                Answer_id: answer['Answer_id'],
                Value_Answer: answer['Value_Answer'],
                Comment: answer['Comment'],
              });
            }
          });
        }
        if (newAnswers.length > 0) {
          return {
            ...question,
            Answers: newAnswers,
          };
        }
        return question;
      });
      return {
        ...topicAssinged,
        Assigned_Status: data['topicStatus'],
        Questions: newQuestions,
      };
    }
    return topicAssinged;
  });
  
  return {
    ...questionnaire,
    Completed_Topics: data['topicMetrics']['completedTopics'],
    Completed_Topics_Of_User: data['topicMetrics']['completedTopicsOfUser'],
    TopicsAssignedToCurrentUser: data['topicMetrics']['topicsOfUser'],
    Topics: topics
  };
}
