import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trueFalse',
})
export class TrueFalsePipe implements PipeTransform {
  transform(value: any): string {
    return value ? 'Si' : 'No';
  }
}
