import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { arraysAreIdentical } from 'src/app/data/data-handlers/utils.service';
import { Project } from 'src/app/data/models/Project';
import { QuestionnairesAssigned } from 'src/app/data/models/QuestionnairesAssigned';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { User } from 'src/app/data/models/User';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { QuestionnaireActionTypes } from 'src/app/Store/assignedQuestionnaires/actions/assigned-questionnaires.actions';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';

@Component({
  selector: 'app-assing-model-table',
  templateUrl: './assing-model-table.component.html',
  styleUrls: ['./assing-model-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssingModelTableComponent implements OnChanges, OnInit {
  @Input()
  finalUsersChosen = [];

  @Input()
  importProjectLoadingUsers = [];

  @Input()
  isModified = false;

  @Input()
  projectToBeModified: Project;

  @Input()
  getUserFullName: { [id: string]: User };

  @Input()
  activeQuestionnaire: QuestionnairesStructure;

  @Input()
  isProjectDraft = true;

  @Input()
  ProjectFormGroup: FormGroup;

  @Input()
  userInSession: string;

  @Input()
  starterusers = [];
  @Input()
  formChange: {
    title: boolean;
    checkboxes: boolean;
    users: boolean;
    watchlist: boolean;
  };

  @Input()
  lang: string;

  @Output()
  exportQuest = new EventEmitter<{ link: string; assingId: string }>();

  @Output()
  goToAnswer = new EventEmitter<String>();

  @Output()
  changeSend = new EventEmitter<{
    title: boolean;
    checkboxes: boolean;
    users: boolean;
    watchlist: boolean;
  }>();

  @Output()
  openNotesModal = new EventEmitter<{
    notes: any;
    Quest_Assing_ID: string;
    pageIndex: 0;
    Status: string;
    user: string;
  }>();

  @Output()
  addUserResponsible = new EventEmitter<User[]>();

  @Output()
  setFinalChosenUsers = new EventEmitter<User[]>();

  @Output()
  setWatchListUsers = new EventEmitter<{ [id: string]: string[] }>();

  @Output()
  setActiveProjectData = new EventEmitter<
    {
      oldUser: string;
      newUser: string;
      backEndFlag: 'delete' | 'update' | 'add new user';
      allTopicsToNewUR?: boolean;
    }[]
  >();

  @Output()
  sendUsersFromExcel = new EventEmitter<[{ NAME: string; SURNAME: string; EMAIL: string }]>();

  usersChosenForShowing = [];
  changeResponsinbleTableShow = false;

  showUserTable = false;

  showWatchListDialog = false;

  questPreselectedWatchlist = [];

  oldUser = null;

  watchListUsers: { [id: string]: string[] } = {};
  watchListUsersClone: { [id: string]: string[] } = {};

  questionnaireUserToAddToWatchlist: string;

  activeProjectData: {
    oldUser: string;
    newUser: string;
    backEndFlag: 'delete' | 'update' | 'add new user';
    allTopicsToNewUR?: boolean;
  }[] = [];

  allTopicsToNewUR: boolean;

  firstTimeOpenWatchlist = true;

  usersChosen = [];

  userToBeResponsible: User = null;

  fullUsersWatchlist: any[] = [];

  questIDToExport: string;

  newDate = new Date().toISOString();

  responsabileName: string;

  responsabiliName: string;

  questionariName: string;

  topicName: string;

  constructor(
    private magicStringsService: MagicStringsService,
    private translate: TranslateService,
    private store: Store,
    private dialog: GeneralDialogService,
    private cdRef:ChangeDetectorRef,

  ) {}
  ngOnInit(): void {
    // this.magicStringsService.refresh();
    this.responsabileName = this.magicStringsService.Responsabile;
    this.responsabiliName = this.magicStringsService.Responsabili;
    this.topicName = this.magicStringsService.Topic;
    this.questionariName = this.magicStringsService.Questionari;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['importProjectLoadingUsers']) {
      if (
        !changes['importProjectLoadingUsers'].currentValue[0] &&
        changes['importProjectLoadingUsers'].currentValue[1].length > 0
      ) {
        this.importUsers(changes['importProjectLoadingUsers'].currentValue[1]);
      }
    }

    if (!!changes['finalUsersChosen'] && !!changes['finalUsersChosen'].currentValue) {
      this.usersChosenForShowing = changes['finalUsersChosen'].currentValue;
    }

    if (!!changes['projectToBeModified'] && !!changes['projectToBeModified'].currentValue) {
      this.projectToBeModified = {
        ...changes['projectToBeModified'].currentValue,
      };
      this.watchListUsers = {};
      Object.keys(changes['projectToBeModified'].currentValue.questionnaires).map((user) => {
        if (changes['projectToBeModified'].currentValue.questionnaires[user]) {
          let existingUsers = changes['projectToBeModified'].currentValue.questionnaires[user].watchlist;
          this.watchListUsers[user] = existingUsers;
        }
      });
      this.setWatchListUsers.emit(this.watchListUsers);
    }
  }

  importUsers(users: User[]) {
    users.forEach((user) => {
      if (!this.finalUsersChosen.includes(user.username)) {
        if (this.isModified && this.projectToBeModified.PROJECT_STATUS === 'Active') {
          this.activeProjectData.push({
            oldUser: null,
            newUser: user.username,
            backEndFlag: 'add new user',
          });
          this.setActiveProjectData.emit(this.activeProjectData);
        }
        if (!this.isModified) {
          this.finalUsersChosen.push(user);
        } else {
          this.finalUsersChosen.push(user.username);
        }
        this.setFinalChosenUsers.emit(this.finalUsersChosen);
      }
    });

    this.formChange.users = true;
    this.changeSend.emit(this.formChange);
  }

  goToAnwswer(questId: string) {
    this.goToAnswer.emit(questId);
  }

  toggleUserTable(changeResponsible: boolean, oldUser?: string) {
    if (changeResponsible) {
      this.oldUser = oldUser;

      this.changeResponsinbleTableShow = !this.changeResponsinbleTableShow;
    } else {
      this.showUserTable = !this.showUserTable;
    }
    this.cdRef.detectChanges();

  }

  addUsersToWatchlistDialog(userToChange: string | User) {
    let username: string;
    if (userToChange.hasOwnProperty('username')) {
      username = userToChange['username'];
    } else {
      username = userToChange as string;
    }
    if (!!this.projectToBeModified) {
      let users = this.projectToBeModified['questionnaires'][username]['watchlist'];
      if (!this.watchListUsers[username]) {
        this.watchListUsers[username] = users;
        this.watchListUsers = { ...this.watchListUsers };
      }

      this.questPreselectedWatchlist = this.watchListUsers[username];
    } else {
      if (!!this.watchListUsers[username]) {
        this.questPreselectedWatchlist = this.watchListUsers[username];
      } else {
        this.watchListUsers[username] = [];
        this.questPreselectedWatchlist = [];
      }
    }
    this.fullUsersWatchlist = !!this.projectToBeModified
      ? this.projectToBeModified['questionnaires'][username]['watchlistFullUsers']
      : !!this.watchListUsers[username]
      ? this.watchListUsers[username]
      : null;

    this.questionnaireUserToAddToWatchlist = username;
    this.showWatchListDialog = true;
    this.firstTimeOpenWatchlist = true;
    this.cdRef.detectChanges();

  }

  exportQuestionnairefunc(quest: string) {
    this.questIDToExport = quest['PK_QUEST_ASSIGN'];

    this.store.dispatch({
      type: QuestionnaireActionTypes.exportSingleCsv,
      data: [this.questIDToExport],
      lang: this.lang,
      topic: this.translate.instant('DICTIONARY.SingleTopic'),
      downloadFiles: false,
    });
  }

  removeUser(index: number) {
    if (this.isModified && this.projectToBeModified.PROJECT_STATUS === 'Active') {
      if (this.projectToBeModified.USERS.includes(this.finalUsersChosen[index])) {
        this.activeProjectData.push({
          oldUser: this.finalUsersChosen[index],
          newUser: null,
          backEndFlag: 'delete',
        });
      } else {
        this.activeProjectData = this.activeProjectData.filter((oneRecord) => {
          if (oneRecord.newUser !== this.finalUsersChosen[index]) {
            return oneRecord;
          }
        });
      }
      this.setActiveProjectData.emit(this.activeProjectData);
    }

    this.finalUsersChosen = [...this.finalUsersChosen];

    this.finalUsersChosen.splice(index, 1);
    let identical = arraysAreIdentical(this.starterusers, this.finalUsersChosen);
    this.setFinalChosenUsers.emit(this.finalUsersChosen);
    if (identical) {
      this.formChange.users = false;
      this.changeSend.emit(this.formChange);
    } else {
      this.formChange.users = true;
      this.changeSend.emit(this.formChange);
    }
  }

  getNotes(questionnaire: QuestionnairesAssigned, user: string) {
    this.openNotesModal.emit({
      notes: null,
      Quest_Assing_ID: questionnaire['PK_QUEST_ASSIGN'],
      pageIndex: 0,
      Status: questionnaire.Status,
      user: user,
    });
  }

  findElement(array, element: string) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].newUser === element) {
        return i;
      }
    }
    return;
  }

  getUser(user: User) {
    if (this.usersChosen.includes(user)) {
      let index = this.usersChosen.findIndex((userfound) => user === userfound);
      this.usersChosen.splice(index, 1);
      this.activeProjectData.splice(this.findElement(this.activeProjectData, user.username), 1);
    } else {
      if (this.isModified && this.projectToBeModified.PROJECT_STATUS === 'Active') {
        this.activeProjectData.push({
          oldUser: null,
          newUser: user.username,
          backEndFlag: 'add new user',
        });
        this.setActiveProjectData.emit(this.activeProjectData);
      }
      this.usersChosen.push(user);
    }
  }

  confirmUsers() {
    if (!this.isModified) {
      this.finalUsersChosen = this.finalUsersChosen.concat(this.usersChosen);
      this.setFinalChosenUsers.emit(this.finalUsersChosen);
    } else {
      this.addUserResponsible.emit(this.usersChosen);
    }
    this.formChange.users = true;
    this.changeSend.emit(this.formChange);
    this.usersChosen = [];
    this.toggleUserTable(false);
    this.showUserTable = false;
  }

  toggleWatchlistDialog() {
    this.showWatchListDialog = false;
  }

  closeWatchListWithNoConfirmation() {
    this.showWatchListDialog = false;
    this.firstTimeOpenWatchlist = true;
  }

  getUserWatchList(user: User) {
    if (this.firstTimeOpenWatchlist) {
      this.watchListUsersClone = { ...this.watchListUsers };
      this.firstTimeOpenWatchlist = false;
    }

    if (!this.watchListUsersClone[this.questionnaireUserToAddToWatchlist]) {
      this.watchListUsersClone[this.questionnaireUserToAddToWatchlist] = [];
    }

    if (this.watchListUsersClone[this.questionnaireUserToAddToWatchlist].includes(user.username)) {
      this.watchListUsersClone[this.questionnaireUserToAddToWatchlist] = this.watchListUsersClone[
        this.questionnaireUserToAddToWatchlist
      ].filter((username) => username !== user.username);
    } else {
      this.watchListUsersClone[this.questionnaireUserToAddToWatchlist] = this.watchListUsersClone[
        this.questionnaireUserToAddToWatchlist
      ].filter((user) => user);
      this.watchListUsersClone[this.questionnaireUserToAddToWatchlist].push(user.username);
    }

    this.questPreselectedWatchlist = this.watchListUsersClone[this.questionnaireUserToAddToWatchlist];
  }

  confirmWatchListUsers() {
    this.watchListUsers = { ...this.watchListUsersClone };
    this.setWatchListUsers.emit(this.watchListUsers);
  }

  changeResponsible(user: User) {
    this.userToBeResponsible = user;

    if (this.isModified && this.projectToBeModified.PROJECT_STATUS === 'Active') {
      if (this.projectToBeModified.USERS.includes(this.oldUser)) {
        this.activeProjectData.push({
          oldUser: this.oldUser,
          newUser: this.userToBeResponsible.username,
          backEndFlag: 'update',
          allTopicsToNewUR: this.allTopicsToNewUR,
        });
      } else {
        this.activeProjectData = this.activeProjectData.map((onerecord) => {
          if (onerecord.newUser === this.oldUser) {
            onerecord = {
              ...onerecord,
              newUser: this.userToBeResponsible.username,
              allTopicsToNewUR: this.allTopicsToNewUR,
            };
          }
          return onerecord;
        });
      }
      this.setActiveProjectData.emit(this.activeProjectData);
    }
    let index = this.finalUsersChosen.findIndex((val) => val === this.oldUser);

    let newArray = {};

    this.finalUsersChosen = [...this.finalUsersChosen];

    if (this.isModified && !!this.projectToBeModified) {
      Object.keys(this.projectToBeModified.questionnaires).map((key) => {
        if (key !== this.oldUser) {
          newArray[key] = this.projectToBeModified.questionnaires[key];
        } else {
          newArray[this.userToBeResponsible.username] = this.projectToBeModified.questionnaires[key];
        }
      });
      this.finalUsersChosen[index] = this.userToBeResponsible.username;
      this.projectToBeModified = {
        ...this.projectToBeModified,
        questionnaires: newArray,
      };
    } else {
      this.finalUsersChosen[index] = this.userToBeResponsible;
    }
    this.setFinalChosenUsers.emit(this.finalUsersChosen);
    let identical = arraysAreIdentical(this.starterusers, this.finalUsersChosen);
    if (identical) {
      this.formChange.users = false;
      this.changeSend.emit(this.formChange);
    } else {
      this.formChange.users = true;
      this.changeSend.emit(this.formChange);
    }
    this.toggleUserTable(true);
  }

  collaborativeOptions(option: string) {
    if (option === 'allTopicsToNewUR') {
      this.allTopicsToNewUR = true;
    } else {
      this.allTopicsToNewUR = false;
    }
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      let sheetName = '';
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        sheetName = name;
        return initial;
      }, {});
      const dataJson = jsonData[sheetName];
      this.sendUsersFromExcel.emit(dataJson);
    };
    reader.readAsBinaryString(file);
  }

  openFileRepo(quest: any) {
    this.dialog.openFileRepoModal({
      Quest_Assing_Id: quest.PK_QUEST_ASSIGN,
      Quest_Assign_Title: this.projectToBeModified.TITLE,
    });
  }
}
