import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/datepick/datepick.component';

@Component({
  selector: 'app-home-filter',
  templateUrl: './home-filter.component.html',
  styleUrls: ['./home-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeFilterComponent implements OnInit {
  @Input()
  lang: string;

  @Output()
  filter = new EventEmitter<string>();

  @Output()
  filterStartDateChange = new EventEmitter();

  @Output()
  filterEndDateChange = new EventEmitter();

  @Output()
  sort = new EventEmitter<string>();

  @Output()
  searchObservable = new EventEmitter<string>();

  @Output()
  HideShowPanel = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  clickout() {
    // if mat-option and mat-datepicker don't exist
    if (
      this.showPanel &&
      !document.querySelector('mat-option') &&
      !document.querySelector('mat-datepicker') &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.showPanel = false;
      this.HideShowPanel.emit(false);
    }
  }

  showPanel = false;

  searchByName = '';
  filterStartDate = '';
  filterEndDate = '';

  checked = false;

  checkedValues = [
    { showAll: false, name: 'showAll', ID: 'D1' },
    { responsible: false, name: 'responsible', ID: 'D2' },
    { expired: false, name: 'expired', ID: 'D3' },
    { topicsToCompile: false, name: 'topicsToCompile', ID: 'D4' },
    { notesToBeRead: false, name: 'notesToBeRead', ID: 'D5' },
    { completedTopics: false, name: 'completedTopics', ID: 'D6' },
  ];

  chosenItem = this.checkedValues[0].name;

  selectedValue = 'Questionnaire_Title';

  badgeCounter: number = 1;
  hideBadge: boolean = true;
  showCalendar: boolean = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  searchTerm(term: string) {
    this.searchObservable.emit(term);
    if (!this.showPanel) {
      this.searchByName = term;
    } else {
      // if the input element disappears ngModel makes it null
      this.searchByName = this.searchByName;
    }
  }

  startChange(start) {
    this.filterStartDateChange.emit(start);
  }

  endChange(end) {
    this.filterEndDateChange.emit(end);
  }

  hideShowPanel(event: Event) {
    this.showPanel = !this.showPanel;
    this.HideShowPanel.emit(this.showPanel);
    event.preventDefault();
    event.stopPropagation();
  }

  orderQuests(event: Event) {
    this.sort.emit(this.selectedValue);
  }

  filterO(value: string) {
    if (value !== 'showAll') {
      this.hideBadge = false;
    } else {
      this.hideBadge = true;
    }

    if (value === 'expired') {
      this.showCalendar = true;
    } else {
      this.showCalendar = false;
    }

    Object.keys(this.checkedValues).map((key) => {
      if (this.checkedValues[key].name !== value) {
        this.checkedValues[key] = false;
      } else {
        this.checkedValues[key] = true;
      }
    });
    this.filter.emit(value);
  }

  resetForm() {
    this.filterStartDate = '';
    this.filterEndDate = '';
    this.filterStartDateChange.emit(this.filterStartDate);
    this.filterEndDateChange.emit(this.filterEndDate);
  }
}
