import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './users.state';

export const usersState = createFeatureSelector<UserState>('users');

export const selectLanguage = createSelector(usersState, (state) => state.language);

export const selectUsers = createSelector(usersState, (state) => state.users);
export const selectUsersPaginated = createSelector(usersState, (state) => state.usersPaginated);
export const selectUsersLength = createSelector(usersState, (state) => state.length);
export const selectUsersPageIndex = createSelector(usersState, (state) => state.usersPageIndex);
export const selectUsersLoading = createSelector(usersState, (state) => state.loadingUsers);
export const selectLengthAndIndex = createSelector(selectUsersLength, selectUsersPageIndex, (total, index) => {
  return { total: total, pageIndex: index };
});
export const selectAllClientGroup = createSelector(usersState, (state) => state.allClientGroup);
export const selectSearchTerm = createSelector(usersState, (state) => state.searchTermClientGroup);
export const selectFilteredClientGroup = createSelector(
  selectAllClientGroup,
  selectSearchTerm,
  (allClientGroup, searchTerm) => filtersClientGroup(allClientGroup, searchTerm)
);
export const selectSearchTermModel = createSelector(usersState, (state) => state.searchTermClientGroupModel);
export const selectFilteredClientGroupModel = createSelector(
  selectAllClientGroup,
  selectSearchTermModel,
  (allClientGroup, searchTerm) => filtersClientGroup(allClientGroup, searchTerm)
);
export const selectClientPaginated = createSelector(usersState, (state) => state.clientPaginated);
export const selectClientLength = createSelector(usersState, (state) => state.clientlength);
export const selectClientPageIndex = createSelector(usersState, (state) => state.clientPageIndex);
export const selectClientLoading = createSelector(usersState, (state) => state.loadingClient);
export const selectClientLengthAndIndex = createSelector(selectClientLength, selectClientPageIndex, (total, index) => {
  return { total: total, pageIndex: index };
});
export const selectGroupPaginated = createSelector(usersState, (state) => state.groupPaginated);
export const selectGroupLength = createSelector(usersState, (state) => state.grouplength);
export const selectGroupPageIndex = createSelector(usersState, (state) => state.groupPageIndex);
export const selectGroupLoading = createSelector(usersState, (state) => state.loadingGroup);
export const selectGroupLengthAndIndex = createSelector(selectGroupLength, selectGroupPageIndex, (total, index) => {
  return { total: total, pageIndex: index };
});

export const selectImportLoading = createSelector(usersState, (state) => state.importingUsersLoading);
export const selectUsersToBeImported = createSelector(usersState, (state) => state.usersToImport);

export const selectUsersFullname = createSelector(usersState, (state) => {
  let obj = {};

  if (state.users && state.users.length && state.users.length > 0) {
    state.users.forEach((user) => {
      obj[user.username] = user.Name + ' ' + user.Surname;
    });
  }
  return obj;
});

export const selectSessionUserFullName = createSelector(usersState, (state) =>
  state.users.filter((user) => user.username === state.username)
);

const filtersClientGroup = (allClientGroup, searchTerm) => {
  if (searchTerm && searchTerm.length > 0) {
    const filteredClient = allClientGroup.filter((client) => {
      let namePresent = false;
      if (client.client_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        namePresent = true;
      }
      if (client.groups.length > 0) {
        client.groups.forEach((group) => {
          if (group.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            namePresent = true;
          }
        });
      }
      return namePresent;
    });

    const filteredClientGroup = JSON.parse(JSON.stringify(filteredClient));
    filteredClient.forEach((client, index) => {
      if (client.groups.length > 0) {
        const newGroups = client.groups.filter((group) => group.name.toLowerCase().includes(searchTerm.toLowerCase()));
        filteredClientGroup[index].groups = [...newGroups];
      }
    });
    return filteredClientGroup;
  } else {
    return allClientGroup;
  }
};
