import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ActionTypes } from '../questionnaire-store/state.actions';
import { ActionTopicTypes } from './topic.actions';
import { QuestionnaireActionTypes } from '../assignedQuestionnaires/actions/assigned-questionnaires.actions';
import { PostQuestionnaireService } from 'src/app/data/api/Questionnaire/post-questionnaire.service';
import { NotifyService } from 'src/app/utils/notification.service';
import { QuestionnairesStructure } from 'src/app/data/models/QuestionnairesStructure';
import { Router } from '@angular/router';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';

@Injectable()
export class TopicEffects {
  TopicOfAQuestionnaireEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTypes.setModifiedQuestionnaire),
      mergeMap((payload: { type: string; questionnaire: QuestionnairesStructure }) =>
        of(payload.questionnaire !== null ? payload.questionnaire.Topics : []).pipe(
          map((topics) => ({
            type: ActionTopicTypes.topicsInitialise,
            topics,
          })),
          catchError((error) => {
            this.notifyService.openWarningSwal('Error occured, topic failed to get');
            return of({ type: ActionTopicTypes.ERROR });
          })
        )
      )
    );
  });

  PostQuestionnaireTopicEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.postAnswersOfQuestionnaire),
      mergeMap(() =>
        of(true).pipe(
          map(() => ({ type: ActionTopicTypes.PostQuestionnaire })),
          catchError((error) => {
            this.notifyService.openWarningSwal('Error occured, topic failed to update');
            return of({ type: ActionTopicTypes.ERROR });
          })
        )
      )
    );
  });

  UpdateTopicsUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTopicTypes.updateTopicsUser),
      mergeMap((payload) =>
        this.postService.updateTopicsUser(payload).pipe(
          map(() => {
            return {
              type: ActionTopicTypes.updateTopicsUserSuccess,
              Assigned_id: payload['Assigned_id'],
              fromHome: payload['fromHome'],
              backToResponsible: payload['backToResponsible'],
            };
          }),
          catchError((error) => {
            this.notifyService.openWarningSwal('Error occured, user cannot be updated');
            return of({ type: ActionTopicTypes.ERROR });
          })
        )
      )
    );
  });

  updateTopicsUserSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionTopicTypes.updateTopicsUserSuccess),
      mergeMap((payload) =>
        of(true).pipe(
          map(() => {
            if (!payload['fromHome'] && payload['backToResponsible']) {
              this.route.navigateByUrl(`/${ROUTES_STRINGS.HOME}`);
              return { type: ActionTypes.REDIRECT_DONE };
            } else {
              return { type: ActionTopicTypes.noAction };
            }
          }),
          catchError((err) => of(err))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private notifyService: NotifyService,
    private postService: PostQuestionnaireService,
    private route: Router
  ) {}
}
