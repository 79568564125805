import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { DocufileUplaodService } from 'src/app/features/administrative/service/docufileUpload.service';
import * as fromFileUploadActions from './upload-file.actions';

@Injectable()
export class UploadFileEffects {
  uploadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileUploadActions.UploadRequestAction),
      concatMap((payload) => {
        return this._docuFileService
          .uploadFile(
            payload.file,
            payload.fileId,
            payload.creationDate,
            payload.topicName,
            payload.questionName,
            payload.comment,
            payload.questionId,
            payload.questAssignId,
            payload?.deletedFileId
          )
          .pipe(
            takeUntil(this.actions$.pipe(ofType(fromFileUploadActions.UploadCancelAction))),
            map((event) => this.getActionFromHttpEvent(event)),
            catchError((error) => of(error))
          );
      })
    )
  );

  downloadFileEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileUploadActions.DownloadFileAction),
      concatMap((payload) => {
        return this._docuFileService.downloadFile(payload.fileId).pipe(
          map((res) => {
            var blob = new Blob([res], { type: res.type });
            var filename = payload.fileId.substring(37);
            saveAs(blob, filename);
          }),
          map(() => fromFileUploadActions.DownloadFileSuccess()),
          catchError((error) => of(error))
        );
      })
    )
  );

  getFilesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileUploadActions.GetFilesAction),
      concatMap((payload) => {
        return this._docuFileService.getFiles(payload.questAssignId).pipe(
          map((fileList) => fromFileUploadActions.GetFilesSuccess({ fileList })),
          catchError((error) => of(error))
        );
      })
    )
  );

  deleteFileEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileUploadActions.DeleteFileAction),
      concatMap((payload) => {
        return this._docuFileService.deleteFile(payload.file).pipe(
          map(() =>
            fromFileUploadActions.DeleteFileSuccess({
              deletedFileId: payload.file.id,
            })
          ),
          catchError((error) => of(error))
        );
      })
    )
  );

  downloadZipEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileUploadActions.DownloadZipAction),
      concatMap((payload) => {
        return this._docuFileService.downloadZip(payload.fileList, payload.questTitle).pipe(
          map((res) => {
            var blob = new Blob([res], { type: res.type });
            var filename = `${payload.questTitle}.zip`;
            saveAs(blob, filename);
          }),
          map(() => fromFileUploadActions.DownloadZipSuccess()),
          catchError((error) => of(error))
        );
      })
    )
  );

  constructor(private _docuFileService: DocufileUplaodService, private actions$: Actions) {}

  private getActionFromHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return fromFileUploadActions.UploadStartedAction();
      }
      case HttpEventType.UploadProgress: {
        return fromFileUploadActions.UploadProgressAction({
          progress: Math.round((100 * event.loaded) / event.total!),
        });
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          if (event.type === 4) {
            return fromFileUploadActions.UploadCompletedAction();
          } else {
            return fromFileUploadActions.UploadWaitingAction();
          }
        } else {
          return fromFileUploadActions.UploadErrorAction({
            error: event.statusText,
          });
        }
      }
      default: {
        if (event.type === 3) {
          return fromFileUploadActions.UploadWaitingAction();
        } else {
          return fromFileUploadActions.UploadErrorAction({
            error: `Unknown Event: ${JSON.stringify(event)}`,
          });
        }
      }
    }
  }
}
