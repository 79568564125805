import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { InfoType } from 'src/app/components/info-dialog/info-dialog.component';
import { typeOfQuestionDeletion } from 'src/app/utils/config/questionnaire-table.configuration';
import { filterOutElementsForQuestions, isPatternValid } from 'src/app/data/data-handlers/utils.service';
import { QuestionsStoreService } from 'src/app/Store/store-services/questions-store.service';
import { QUESTION_MODAL_ERRORS } from './errors';
import { getPositionOfParentQuestion, getTypeOfParentQuestion } from './new-question-modal-service';
import {
  TYPE_OF_QUEST_OPTIONS,
  TYPE_OF_VERIFICATIONS_OPTIONS,
  TYPE_OF_ANSWER_OPTIONS,
  AddQuestionInterface,
  FILE_OPTIONS,
} from 'src/app/utils/config/addQuestion.config';
import { NotifyService } from 'src/app/utils/notification.service';
import { Question } from 'src/app/data/models/Question';
import { Store } from '@ngrx/store';
import { selectLanguage } from 'src/app/Store/users/users.selectors';

@Component({
  selector: 'app-new-question-modal',
  templateUrl: './new-question-modal.component.html',
  styleUrls: ['./new-question-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewQuestionModalComponent implements OnInit, OnDestroy {
  positions = [];

  TypeOfQuestionOptions = TYPE_OF_QUEST_OPTIONS;

  TypeOfVerificationOptions = TYPE_OF_VERIFICATIONS_OPTIONS;

  TypeOfAnswerOptions = TYPE_OF_ANSWER_OPTIONS;

  fileOptions = FILE_OPTIONS;

  constrained_disabled = false;

  Constrained_with: Observable<Question>;

  QuestionInfo = null;

  cantModify: boolean;

  modifyStartingQuestionType = null;
  modifyChangeQuestionTypeWithConstrainedChilds = false;
  constraintsChangeIds = [];
  modifyDependentAnswerDeleted = false;
  constraintsDeleteIds = [];

  langToUnsubscribe: Subscription;
  lang: string;

  optionsNumber = 0;

  constructor(
    private store: Store,
    public QuestionDialog: MatDialogRef<NewQuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddQuestionInterface,
    private fb: FormBuilder,
    private notify: NotifyService,
    private questionStore: QuestionsStoreService,
    private cdRef:ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
  }

  ngOnInit(): void {
    this.QuestionInfo = this.fb.group({
      id: this.data.currentQuestion.id,
      Question_Position: [this.data.Topic.Questions.length + 1, [Validators.required]],
      Question_Text: [null, [Validators.required]],
      Question_Desc: '',
      Question_Type: [null, [Validators.required]],
      Answer_Type: '',
      Score: [0, [Validators.max(10000), Validators.min(-10000)]],
      Question_Obbligatory: true,
      Verification: this.fb.group({
        Verify_Input: false,
        Verify_Input_Options: null,
        Regular_Expression: '',
      }),
      Constraints: this.fb.group({
        Constrained: [false],
        Constrained_with: [{ value: null, disabled: true }, [Validators.required]],
        Constraint_options: [{ value: null, disabled: true }, [Validators.required]],
      }),
      ListBoxOption: this.fb.group({
        Check_List_Box_Options: this.fb.array([]),
        Check_List_Box_Options_Score: this.fb.array([]),
        yesNo_Score: this.fb.array([]),
        MinimumOptions: 1,
        MaximumOptions: 100,
      }),
      File_Type: '',
      Comment: false,
    });

    this.positions = Array.from({ length: this.data.Topic.Questions.length + 1 }, (_, i) => i + 1);

    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.lang = lang;
    });

    // CanModify can be undefiened, true, false
    this.cantModify = this.data.readOnlyMode;

    if (this.data.modelStatus === 'Archived') {
      this.QuestionInfo.disable();
      this.cantModify = true;
    }

    if (this.data.action === 'Modify') {
      this.positions = Array.from({ length: this.data.Topic.Questions.length }, (_, i) => i + 1);
      this.setUpFormForModifyAction();
    }
    this.constrained_disabled =
      this.data.Topic.Questions.length === 0 ||
      (this.data.Topic.Questions.length <= 1 && this.data.action === 'Modify') ||
      (this.data.Topic.Questions.length === 2 &&
        this.data.currentQuestion.Position === 1 &&
        this.data.Topic.Questions[1].Constrained);
  }

  getParentQuestion(event) {
    this.Constrained_with = this.questionStore.selectQuestionsByID(event.value, this.data.TopicIndex);
    let parent = getTypeOfParentQuestion(this.data.Topic, this.QuestionInfo.get('Constraints.Constrained_with').value);
    if (parent === 'Free text' || parent === 'File') {
      this.QuestionInfo.patchValue({
        Constraints: { Constraint_options: null },
      });
      this.QuestionInfo.controls['Constraints'].controls['Constraint_options'].disable();
    } else {
      this.QuestionInfo.controls['Constraints'].controls['Constraint_options'].enable();
      this.QuestionInfo.controls['Constraints'].controls['Constraint_options'].markAsUntouched();
    }
  }

  get Check_List_Box_Options(): FormArray {
    return this.QuestionInfo.get('ListBoxOption.Check_List_Box_Options') as FormArray;
  }

  get Check_List_Box_Options_Score(): FormArray {
    return this.QuestionInfo.get('ListBoxOption.Check_List_Box_Options_Score') as FormArray;
  }

  get yesNo_Score(): FormArray {
    return this.QuestionInfo.get('ListBoxOption.yesNo_Score') as FormArray;
  }

  addInput() {
    this.Check_List_Box_Options.push(this.fb.control(null, Validators.required));
    this.Check_List_Box_Options_Score.push(this.fb.control(0, Validators.required));
    this.optionsNumber++;
  }

  changeVerificationInput(event) {
    this.QuestionInfo.patchValue({ Verification: { Regular_Expression: '' } });
    this.QuestionInfo.patchValue({
      Verification: { Verify_Input_Options: null },
    });
    if(event.checked) {
      this.QuestionInfo.get('Verification.Verify_Input_Options').setValidators(Validators.required);
    }else {
      this.QuestionInfo.get('Verification.Verify_Input_Options').clearValidators();
      this.QuestionInfo.get('Verification.Verify_Input_Options').setValidators(null);
      this.QuestionInfo.get('Verification.Verify_Input_Options').updateValueAndValidity();
    }
    this.cdRef.detectChanges();
  }

  changeConstraintQuestion(event) {
    this.QuestionInfo.patchValue({
      Constraints: { Constrained: event.checked },
    });
    this.QuestionInfo.patchValue({ Constraints: { Constrained_with: null } });
    this.QuestionInfo.patchValue({ Constraints: { Constraint_options: null } });
    if (event.checked === true) {
      this.QuestionInfo.controls['Constraints'].controls['Constrained_with'].enable();
      this.QuestionInfo.controls['Constraints'].controls['Constrained_with'].markAsUntouched();
    } else {
      this.QuestionInfo.controls['Constraints'].controls['Constrained_with'].disable();
      this.QuestionInfo.controls['Constraints'].controls['Constraint_options'].disable();
      this.Constrained_with = null;
    }
  }

  getInstructions() {
    this.data.dialogService.openInfoDialog({
      Info: InfoType.textQuestionInfo,
      disableClose: false,
    });
  }

  deleteInput(element, i) {
    const foundOption = [];
    // if not modifyDependentAnswerDeleted (else no need)
    if (!this.modifyDependentAnswerDeleted) {
      // Find all the children
      for (const [indexOfChild, question] of this.data.Topic.Questions.entries()) {
        if (question.ConstraintOptions !== undefined && question.ConstraintOptions.includes(element.value)) {
          // find all the children of the child (grand children)
          for (const [indexOfGrandChild, question2] of this.data.Topic.Questions.entries()) {
            if (question2.Constrained_with === this.data.Topic.Questions[indexOfChild].id) {
              foundOption.push(indexOfGrandChild);
            }
          }
          foundOption.push(indexOfChild);
        }
      }
      foundOption.sort();
    }

    if (foundOption.length > 0) {
      this.data.dialogService.openConfirmDialog({
        info: CONFIRMDIALOGSTRINGS.CONSTRAINT_OPTION,
      });

      this.data.dialogService.openConfirmDialogResponse().subscribe((res) => {
        if (res !== 'Cancel') {
          this.Check_List_Box_Options.removeAt(i);
          this.Check_List_Box_Options_Score.removeAt(i);
          // Save ids (index of children and grand children to Delete)
          this.modifyDependentAnswerDeleted = true;
          this.constraintsDeleteIds = foundOption;
        }
      });
    } else if (foundOption.length === 0) {
      this.Check_List_Box_Options.removeAt(i);
      this.Check_List_Box_Options_Score.removeAt(i);
    }
    this.optionsNumber--;
  }

  changeQuestionType(newRequestedType) {
    if (this.data.action === 'Modify') {
      const oldQuestionType = this.QuestionInfo.get('Question_Type').value;

      // if question type changes again to modifyStartingQuestionType then we make modifyChangeQuestionTypeWithConstrainedChilds false
      if (newRequestedType === this.modifyStartingQuestionType) {
        this.modifyChangeQuestionTypeWithConstrainedChilds = false;
        this.modifyDependentAnswerDeleted = false;
      }

      if (oldQuestionType !== newRequestedType) {
        let foundOption = [];
        // check only when it is not true (no need)
        if (!this.modifyChangeQuestionTypeWithConstrainedChilds) {
          // find all the childen
          for (const [indexOfChild, question] of this.data.Topic.Questions.entries()) {
            if (question.Constrained && question.Constrained_with === this.data.currentQuestion.id) {
              // find all the children of the child found (grand children)
              for (const [indexOfGrandChild, question2] of this.data.Topic.Questions.entries()) {
                if (question2.Constrained_with === this.data.Topic.Questions[indexOfChild].id) {
                  foundOption.push(indexOfGrandChild);
                }
              }
              foundOption.push(indexOfChild);
            }
          }
          foundOption.sort();
        }

        this.data.dialogService.openInfoDialog({
          Info: InfoType.questionTypeOfExistingInfo,
          oldValue: oldQuestionType,
          newValue: newRequestedType,
          disableClose: true,
        });

        this.data.dialogService.infoDialogResponse().subscribe((res) => {
          if (res !== oldQuestionType) {
            if (foundOption.length > 0) {
              this.modifyChangeQuestionTypeWithConstrainedChilds = true;
              // save found ids (index of children, grand children) to delete
              this.constraintsChangeIds = foundOption;
            }
            // reset data/validators when type is changed
            this.changeQuestionTypeFillData(res);
          } else {
            // if you click cancel you get res the old question type. So we don't change any data and we set the old type
            this.QuestionInfo.get('Question_Type').patchValue(res);
          }
        });
      }
    } else {
      this.changeQuestionTypeFillData(newRequestedType);
    }
  }

  changeQuestionTypeFillData(newQuestionType) {
    this.QuestionInfo.get('Verification.Verify_Input').patchValue(false);
    this.QuestionInfo.get('Verification.Verify_Input_Options').patchValue('');
    this.QuestionInfo.get('Verification.Regular_Expression').patchValue('');
    this.QuestionInfo.get('ListBoxOption.Check_List_Box_Options').clear();
    this.QuestionInfo.get('ListBoxOption.Check_List_Box_Options_Score').clear();
    this.QuestionInfo.get('ListBoxOption.yesNo_Score').clear();
    this.QuestionInfo.get('Score').patchValue(null);
    this.QuestionInfo.get('ListBoxOption.MinimumOptions').patchValue(1);
    this.QuestionInfo.get('ListBoxOption.MaximumOptions').patchValue(100);
    this.QuestionInfo.get('Answer_Type').patchValue('');
    this.QuestionInfo.get('Answer_Type').clearValidators();
    this.QuestionInfo.get('Answer_Type').updateValueAndValidity();
    this.QuestionInfo.get('File_Type').patchValue('');
    this.QuestionInfo.get('File_Type').clearValidators();
    this.QuestionInfo.get('File_Type').updateValueAndValidity();
    this.QuestionInfo.get('Constraints.Constrained').patchValue(false);
    this.QuestionInfo.get('Constraints.Constrained_with').patchValue(null);
    this.QuestionInfo.get('Constraints.Constraint_options').patchValue(null);
    this.QuestionInfo.controls['Constraints'].controls['Constrained_with'].disable();
    this.QuestionInfo.controls['Constraints'].controls['Constraint_options'].disable();

    if (newQuestionType === this.modifyStartingQuestionType) {
      this.setUpFormForModifyAction();
    } else {
      if (newQuestionType === this.TypeOfQuestionOptions[0].value) {
        this.QuestionInfo.get('Answer_Type').setValidators(Validators.required);
        this.QuestionInfo.get('Answer_Type').updateValueAndValidity();
        this.QuestionInfo.get('Answer_Type').markAsUntouched();
        this.QuestionInfo.get('Score').patchValue(0);
      } else {
        if (
          newQuestionType === this.TypeOfQuestionOptions[1].value ||
          newQuestionType === this.TypeOfQuestionOptions[2].value
        ) {
          // default 2 empty answers
          this.optionsNumber = 0;
          this.addInput();
          this.addInput();
        } else if (newQuestionType === this.TypeOfQuestionOptions[3].value) {
          this.yesNo_Score.push(this.fb.control(0, Validators.required));
          this.yesNo_Score.push(this.fb.control(0, Validators.required));
        } else if (newQuestionType === this.TypeOfQuestionOptions[4].value) {
          this.QuestionInfo.get('Score').patchValue(0);
          this.QuestionInfo.get('File_Type').setValidators(Validators.required);
          this.QuestionInfo.get('File_Type').updateValueAndValidity();
          this.QuestionInfo.get('File_Type').markAsUntouched();
        }
      }
    }
  }

  setUpFormForModifyAction() {
    this.modifyStartingQuestionType = this.data.currentQuestion.TypeOfQuestion;
    this.data.topicsWithQuestions[this.data.TopicIndex].Questions = filterOutElementsForQuestions(
      this.data.topicsWithQuestions,
      this.data.currentQuestion.Question_Text,
      this.data.TopicIndex
    );

    this.Constrained_with = this.questionStore.selectQuestionsByID(
      this.data.currentQuestion.Constrained_with as string,
      this.data.TopicIndex
    );
    this.QuestionInfo.get('Score').patchValue(null);

    if (this.data.currentQuestion.TypeOfQuestion === 'Free text') {
      this.QuestionInfo.get('Score').patchValue(this.data.currentQuestion.score);
      this.QuestionInfo.patchValue({
        Verification: { Verify_Input: this.data.currentQuestion.Verify_Input },
      });
      this.QuestionInfo.patchValue({
        Verification: {
          Verify_Input_Options: this.data.currentQuestion.TypeOfVerificationInput,
        },
      });
      this.QuestionInfo.patchValue({
        Answer_Type: this.data.currentQuestion.TypeOfAnswer,
      });
      this.QuestionInfo.patchValue({
        Verification: { Regular_Expression: this.data.currentQuestion.Reg_Ex },
      });
    } else if (this.data.currentQuestion.TypeOfQuestion === 'Yes or no') {
      this.data.currentQuestion.yesNo_Score.forEach((option) => {
        this.yesNo_Score.push(this.fb.control(`${option}`, Validators.required));
      });
    } else if (this.data.currentQuestion.TypeOfQuestion !== 'File') {
      this.optionsNumber = 0;
      this.QuestionInfo.patchValue({
        ListBoxOption: {
          MinimumOptions: this.data.currentQuestion['minimum_answers'],
        },
      });
      this.QuestionInfo.patchValue({
        ListBoxOption: {
          MaximumOptions: this.data.currentQuestion['maximum_answers'],
        },
      });
      this.data.currentQuestion.Check_List_Box_Options.forEach((option) => {
        this.Check_List_Box_Options.push(this.fb.control(`${option}`, Validators.required));
        this.optionsNumber++;
      });
      this.data.currentQuestion.Check_List_Box_Options_Score.forEach((option) => {
        this.Check_List_Box_Options_Score.push(this.fb.control(`${option}`, Validators.required));
      });
    } else {
      this.QuestionInfo.get('Score').patchValue(this.data.currentQuestion.score);
      this.QuestionInfo.patchValue({
        File_Type: this.data.currentQuestion.File_Type,
      });
    }

    this.QuestionInfo.patchValue({
      Question_Text: this.data.currentQuestion.Question_Text,
    });
    this.QuestionInfo.patchValue({
      Question_Type: this.data.currentQuestion.TypeOfQuestion,
    });
    this.QuestionInfo.patchValue({
      Question_Desc: this.data.currentQuestion.Description,
    });
    this.QuestionInfo.patchValue({
      Question_Position: this.data.currentQuestion.Position,
    });
    this.QuestionInfo.patchValue({
      Question_Obbligatory: this.data.currentQuestion.Obligatory,
    });
    this.QuestionInfo.patchValue({
      Comment: this.data.currentQuestion.Comment,
    });
    this.QuestionInfo.patchValue({
      Constraints: { Constrained: this.data.currentQuestion.Constrained },
    });
    this.QuestionInfo.patchValue({
      Constraints: {
        Constrained_with: this.data.currentQuestion.Constrained
          ? this.data.currentQuestion.Constrained_with
          : 'Binding',
      },
    });
    if (this.data.currentQuestion.Constrained === true) {
      this.QuestionInfo.controls['Constraints'].controls['Constrained_with'].enable();
      let parent = getTypeOfParentQuestion(this.data.Topic, this.data.currentQuestion.Constrained_with);
      if (this.data.currentQuestion.Constrained_with && parent !== this.TypeOfQuestionOptions[0].value) {
        this.QuestionInfo.patchValue({
          Constraints: {
            Constraint_options: this.data.currentQuestion.ConstraintOptions,
          },
        });
        this.QuestionInfo.controls['Constraints'].controls['Constraint_options'].enable();
      }
    }
  }

  discard() {
    const question_title = this.QuestionInfo.get('Question_Text').value
      ? this.QuestionInfo.get('Question_Text').value.toLowerCase().replace(/\s/g, '')
      : null;
    const question_type = this.QuestionInfo.get('Question_Type').value
      ? this.QuestionInfo.get('Question_Type').value.toLowerCase().replace(/\s/g, '')
      : null;
    if (this.QuestionInfo.dirty) {
      this.data.dialogService.openConfirmDialog({
        info: CONFIRMDIALOGSTRINGS.CANCEL_MODIFICATION_CREATION,
      });
      this.data.dialogService.openConfirmDialogResponse().subscribe((res) => {
        if (res !== 'Cancel') {
          this.QuestionDialog.close(false);
        }
      });
    } else {
      this.QuestionDialog.close(false);
    }
  }

  save() {
    let questionType = this.QuestionInfo.get('Question_Type').value;
    if (questionType !== this.TypeOfQuestionOptions[1].value && questionType !== this.TypeOfQuestionOptions[2].value) {
      this.QuestionInfo.get('ListBoxOption.Check_List_Box_Options').clear();
      this.QuestionInfo.get('ListBoxOption.Check_List_Box_Options_Score').clear();
    }
    const question_title_exists = this.QuestionInfo.get('Question_Text').value
      ? this.data?.topicsWithQuestions[this.data.TopicIndex].Questions.includes(
          this.QuestionInfo.get('Question_Text').value.toLowerCase().replace(/\s/g, '')
        )
      : null;
    let outOfBounds =
      Number(this.QuestionInfo.value['Score']) > 10000 || Number(this.QuestionInfo.value['Score']) < -10000;
    let answerOptioss = false;
    this.QuestionInfo.value['ListBoxOption']['Check_List_Box_Options_Score'].forEach((element) => {
      if (element > 10000 || element < -10000) {
        answerOptioss = true;
      }
    });
    this.QuestionInfo.value['ListBoxOption']['yesNo_Score'].forEach((element) => {
      if (element > 10000 || element < -10000) {
        answerOptioss = true;
      }
    });

    let answersNum = this.QuestionInfo.get('ListBoxOption.Check_List_Box_Options').value.length;
    if (questionType === this.TypeOfQuestionOptions[2].value && answersNum > 0) {
      let min = this.QuestionInfo.get('ListBoxOption.MinimumOptions').value;
      let max = this.QuestionInfo.get('ListBoxOption.MaximumOptions').value;

      if (min > max) {
        if (this.lang === 'it') {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.MIN_BIGGER_THAN_MAX}`);
        } else {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.MIN_BIGGER_THAN_MAX_ENG}`);
        }
        this.QuestionDialog.close(false);
      } else if (min > answersNum) {
        if (this.lang === 'it') {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.MIN_BIGGER_THAN_ANSWERS_NUM}`);
        } else {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.MIN_BIGGER_THAN_ANSWERS_NUM_ENG}`);
        }
        this.QuestionDialog.close(false);
      }
    }

    if (outOfBounds || answerOptioss) {
      if (this.lang === 'it') {
        this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.SCORE_OUT_OF_BOUNDS}`);
      } else {
        this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.SCORE_OUT_OF_BOUNDS_ENG}`);
      }
      this.QuestionDialog.close(false);
    }
    let positionCheck = this.positionCheck();

    if (this.QuestionInfo.valid && positionCheck) {
      if (!question_title_exists) {
        if (this.QuestionInfo.get('Verification.Verify_Input_Options').value === 'customized') {
          const regEx = this.QuestionInfo.get('Verification.Regular_Expression').value;
          if (regEx === '') {
            if (this.lang === 'it') {
              this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.REGULAR_EXPRESSION_EMPTY}`);
            } else {
              this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.REGULAR_EXPRESSION_EMPTY_ENG}`);
            }
          } else if (isPatternValid(regEx) === false) {
            if (this.lang === 'it') {
              this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.REGULAR_EXPRESSION_ERROR}`);
            } else {
              this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.REGULAR_EXPRESSION_ERROR_ENG}`);
            }
          }
        }

        let canSave = false;
        if (
          questionType === this.TypeOfQuestionOptions[1].value ||
          questionType === this.TypeOfQuestionOptions[2].value
        ) {
          if (answersNum > 0) {
            canSave = true;
          } else {
            if (this.lang === 'it') {
              this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.LIST_BOX_OPTIONS_LENGTH}`);
            } else {
              this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.LIST_BOX_OPTIONS_LENGTH_ENG}`);
            }
            this.QuestionDialog.close(false);
          }
        } else {
          canSave = true;
        }

        if (canSave) {
          let idsToDelete = [];
          if (this.modifyChangeQuestionTypeWithConstrainedChilds) {
            // all the constrained children...
            idsToDelete = this.constraintsChangeIds;
          } else if (this.modifyDependentAnswerDeleted) {
            // only the constrained children... that have the dependent Answer/s
            idsToDelete = this.constraintsDeleteIds;
          }

          if (idsToDelete.length > 0) {
            this.questionStore.deleteQuestion(this.data.TopicIndex, idsToDelete, typeOfQuestionDeletion.DELETEALL);
          }

          this.QuestionDialog.close(this.QuestionInfo);
        }
      } else if (question_title_exists) {
        if (this.lang === 'it') {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.QUESTION_TITLE_ALREADY_EXISTS}`);
        } else {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.QUESTION_TITLE_ALREADY_EXISTS_ENG}`);
        }
      }
    } else {
      this.QuestionInfo.markAllAsTouched();
      if (this.checkForExistenceOfConstraintQuestion()) {
        if (this.lang === 'it') {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.SELECT_PARENT_QUESTION}`);
        } else {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.SELECT_PARENT_QUESTION_ENG}`);
        }
      } else if (this.checkForExistenceOfConstraintOptions()) {
        if (this.lang === 'it') {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.SELECT_OPTION_OF_PARENT_QUESTION}`);
        } else {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.SELECT_OPTION_OF_PARENT_QUESTION_ENG}`);
        }
      } else if (!positionCheck) {
        if (this.lang === 'it') {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.NON_VALID_POSITION}`);
        } else {
          this.notify.openWarningSwal(`${QUESTION_MODAL_ERRORS.NON_VALID_POSITION_ENG}`);
        }
      }
    }
  }

  positionCheck() {
    if (this.checkForExistenceOfConstraintQuestion() && this.checkForExistenceOfConstraintOptions()) {
      if (this.QuestionInfo.get('Constraints.Constrained').value) {
        if (
          getPositionOfParentQuestion(this.data.Topic, this.QuestionInfo.get('Constraints.Constrained_with').value) >=
          this.QuestionInfo.get('Question_Position').value
        ) {
          return false;
        } else {
          return true;
        }
      } else if (
        this.data.childAndParentQuestionsWithPositions !== undefined &&
        this.data.childAndParentQuestionsWithPositions.length > 0
      ) {
        for (let i = 0; i < this.data.childAndParentQuestionsWithPositions.length; i++) {
          if (
            this.QuestionInfo.get('Question_Position').value >=
            this.data.childAndParentQuestionsWithPositions[i].Position
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkForExistenceOfConstraintQuestion() {
    if (this.QuestionInfo.get('Constraints.Constrained').value === true) {
      if (this.QuestionInfo.get('Constraints.Constrained_with').value === null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkForExistenceOfConstraintOptions() {
    if (this.QuestionInfo.get('Constraints.Constrained').value) {
      let constrained_with = this.QuestionInfo.get('Constraints.Constrained_with').value;

      const typeOfParentQuestion = constrained_with ? getTypeOfParentQuestion(this.data.Topic, constrained_with) : null;

      if (
        typeOfParentQuestion === 'Free text' ||
        typeOfParentQuestion === 'File' ||
        typeOfParentQuestion === null ||
        this.QuestionInfo.get('Constraints.Constraint_options').value !== null
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
