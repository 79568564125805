<div id="question-body-container" class="flex flex-col">
  <form id="active-topic" [formGroup]="questionsFormGroup">
    <ul id="questions-body" class="flex flex-col w-full h-auto overflow-hidden">
      <ng-container *ngFor="let question of activeTopic.Questions; let index = index">
        <ng-container *ngIf="question['Assigned_Status'] !== 'Completed'">
          <!------------------- not constrained Question ------------------->
          <ng-container *ngIf="!question.Constrained">
            <ng-container *ngTemplateOutlet="matCardTemplate; context: { question: question }"></ng-container>
          </ng-container>
          <!------------------- constrained Question ------------------->
          <ng-container *ngIf="question.Constrained">
            <ng-container [ngSwitch]="question_dict[question.Constrained_with]['TypeOfQuestion']">
              <ng-container
                *ngSwitchCase="
                  ['Free text', 'File'].includes(question_dict[question.Constrained_with]['TypeOfQuestion'])
                    ? question_dict[question.Constrained_with]['TypeOfQuestion']
                    : ''
                "
              >
                <!-- constrained with free text or File question  freetextconstraint = true -->
                <ng-container
                  *ngIf="
                    questionsFormGroup.controls[question.Constrained_with].valid &&
                    questionsFormGroup.value[question.Constrained_with]['Input'] &&
                    questionsFormGroup.value[question.Constrained_with]['Input'] !== ''
                  "
                >
                  <ng-container *ngTemplateOutlet="matCardTemplate; context: { question: question }"></ng-container>
                </ng-container>
              </ng-container>
              <!-- constrained with check or simple listbox yes/no -->
              <ng-container *ngSwitchDefault>
                <ng-container
                  *ngIf="
                    questionsFormGroup.value[question_dict[question.Constrained_with]['id']]
                      | isConstraint
                        : question['ConstraintOptions']
                        : activeTopic.Questions[question_dict[question.Constrained_with]['Position'] - 1]
                  "
                >
                  <ng-container *ngTemplateOutlet="matCardTemplate; context: { question: question }"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </form>
</div>

<ng-template #matCardTemplate let-question="question">
  <mat-card class="m-6 overflow-hidden">
    <div class="w-full h-full">
      <img
        class="ml-[-16px] mt-[-16px] absolute"
        src="assets/images/Risposta_Obbligatoria_montain.svg"
        *ngIf="question.Obligatory && appSvgTheme === 1"
      />
      <img
        class="ml-[-16px] mt-[-16px] absolute"
        src="assets/images/Risposta_Obbligatoria_sea.svg"
        *ngIf="question.Obligatory && appSvgTheme === 0"
      />
      <img
        class="ml-[-16px] mt-[-16px] absolute"
        src="assets/images/Risposta_Obbligatoria_forest.svg"
        *ngIf="question.Obligatory && appSvgTheme === 2"
      />
      <ng-container [ngSwitch]="question.TypeOfQuestion">
        <app-free-text-question
          *ngSwitchCase="'Free text'"
          (copyToClipBoardEvent)="showCliboardAnimation.emit(true)"
          (formValidity)="pushValidation($event)"
          [reviewMode]="reviewMode"
          [freeTextForm]="questionsFormGroup.controls[question.id]"
          [question]="question"
          [lang]="lang"
        >
        </app-free-text-question>
        <app-list-box-question
          *ngSwitchCase="'List box'"
          (copyToClipBoardEvent)="showCliboardAnimation.emit(true)"
          [reviewMode]="reviewMode"
          [listBoxForm]="questionsFormGroup.controls[question.id]"
          [question]="question"
          [lang]="lang"
        >
        </app-list-box-question>
        <app-check-list-question
          *ngSwitchCase="'Check-list box'"
          (copyToClipBoardEvent)="showCliboardAnimation.emit(true)"
          [reviewMode]="reviewMode"
          [clistBoxForm]="questionsFormGroup.controls[question.id]"
          [question]="question"
          [lang]="lang"
        >
        </app-check-list-question>
        <app-yes-no-question
          *ngSwitchCase="'Yes or no'"
          (copyToClipBoardEvent)="showCliboardAnimation.emit(true)"
          [reviewMode]="reviewMode"
          [yesNoForm]="questionsFormGroup.controls[question.id]"
          [question]="question"
          [lang]="lang"
        >
        </app-yes-no-question>
        <app-file-question
          *ngSwitchCase="'File'"
          (copyToClipBoardEvent)="showCliboardAnimation.emit(true)"
          [reviewMode]="reviewMode"
          [fileForm]="questionsFormGroup.controls[question.id]"
          [question]="question"
          [activeTopic]="activeTopic"
          [lang]="lang"
        >
        </app-file-question>
      </ng-container>
      <app-comment-question
        *ngIf="question.Comment"
        [question]="question"
        [commentForm]="questionsFormGroup.controls[question.id + 'Comment']"
      >
      </app-comment-question>
    </div>
  </mat-card>
</ng-template>
