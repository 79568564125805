import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AdminGroupsService } from 'src/app/features/shared/services/admin-groups.service';
import { AdminGroupsActionTypes } from './admin-groups.actions';

@Injectable()
export class AdminGroupsEffects {
  getAllClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.getAllClients),
      mergeMap((payload) =>
        this.adminGroupsService
          .getAllClients(payload['pageIndex'], 4, payload['name'], payload['ascdesc'], payload['searchString'])
          .pipe(
            map((response) => {
              return {
                type: AdminGroupsActionTypes.getAllClientsSuccess,
                clientsData: response['clientData'],
                clientsDataTotal: response['total'],
                clientsDataPageIndex: response['pageIndex'],
              };
            }),
            catchError((response) => {
              return of({ type: AdminGroupsActionTypes.getAllClientsFail });
            })
          )
      )
    );
  });

  createClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.createClient),
      mergeMap((payload) =>
        this.adminGroupsService.createClient(payload['name'], payload['description'], payload['imageLocation']).pipe(
          map((response: any) => {
            this.store.dispatch({
              type: AdminGroupsActionTypes.setClientId,
              client_id: response.client.id,
            });

            let client = {
              client_id: response.client.id,
              name: response.client.name,
              description: response.client.description,
              image: response.client.image,
            };
            this.store.dispatch({
              type: AdminGroupsActionTypes.setActiveClient,
              activeClient: client,
            });
            return {
              type: AdminGroupsActionTypes.getAllClientsSuccess,
              pageIndex: 0,
              name: '',
              ascdesc: 'DESC',
              searchString: '',
            };
          }),
          catchError((response) => {
            return of({ type: AdminGroupsActionTypes.createClientFail });
          })
        )
      )
    );
  });

  updateClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.updateClient),
      mergeMap((payload) =>
        this.adminGroupsService
          .updateClient(payload['id'], payload['name'], payload['description'], payload['image'])
          .pipe(
            map((response) => {
              this.store.dispatch({
                type: AdminGroupsActionTypes.getAllClients,
                pageIndex: 0,
                name: 'name',
                ascdesc: 'DESC',
                searchString: '',
              });

              return {
                type: AdminGroupsActionTypes.getAllClientsSuccess,
                pageIndex: 0,
                name: '',
                ascdesc: 'DESC',
                searchString: '',
              };
            }),
            catchError((response) => {
              return of({ type: AdminGroupsActionTypes.updateClientFail });
            })
          )
      )
    );
  });

  deleteClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.deleteClient),
      mergeMap((payload) =>
        this.adminGroupsService.deleteClient(payload['client_id']).pipe(
          map((response) => {
            return {
              type: AdminGroupsActionTypes.getAllClients,
              pageIndex: 0,
              name: 'name',
              ascdesc: 'DESC',
              searchString: '',
            };
          }),
          catchError((response) => {
            return of({ type: AdminGroupsActionTypes.deleteClientFail });
          })
        )
      )
    );
  });

  createGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.createGroup),
      mergeMap((payload) =>
        this.adminGroupsService
          .createGroup(payload['client_id'], payload['group_id'], payload['name'], payload['description'])
          .pipe(
            map((response) => {
              this.router.navigateByUrl('/admin-groups/clients-table');
              return {
                type: AdminGroupsActionTypes.createGroupSuccess,
              };
            }),
            catchError((response) => {
              return of({ type: AdminGroupsActionTypes.createGroup });
            })
          )
      )
    );
  });

  getGroupWithUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.getGroupWithUsers),
      mergeMap((payload) =>
        this.adminGroupsService
          .getGroupWithUsers(
            payload['group_id'],
            payload['pageIndex'],
            6,
            payload['name'],
            payload['ascdesc'],
            payload['searchString']
          )
          .pipe(
            map((response) => {
              return {
                type: AdminGroupsActionTypes.getGroupWithUsersSuccess,
                activeUsers: response['groupsData'],
                usersTotal: response['total'],
                usersPageIndex: response['pageIndex'],
              };
            }),
            catchError((response) => {
              return of({ type: AdminGroupsActionTypes.getGroupWithUsersFail });
            })
          )
      )
    );
  });

  getGroupsByClientId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.getGroupsByClientId),
      mergeMap((payload) =>
        this.adminGroupsService
          .getGroupsByClientId(
            payload['client_id'],
            payload['pageIndex'],
            6,
            payload['name'],
            payload['ascdesc'],
            payload['searchString']
          )
          .pipe(
            map((response) => {
              return {
                type: AdminGroupsActionTypes.getGroupsByClientIdSuccess,
                clientGroups: response['groups'],
                clientGroupsTotal: response['total'],
                clientGroupsPageIndex: response['pageIndex'],
              };
            }),
            catchError((response) => {
              return of({ type: AdminGroupsActionTypes.getGroupsByClientIdFail });
            })
          )
      )
    );
  });

  updateGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.updateGroup),
      mergeMap((payload) =>
        this.adminGroupsService.updateGroup(payload['group_id'], payload['name'], payload['description']).pipe(
          map((response) => {
            this.store.dispatch({
              type: AdminGroupsActionTypes.getGroupsByClientId,
              client_id: payload['client_id'],
              pageIndex: 0,
              name: '',
              ascdesc: 'DESC',
              searchString: '',
            });

            this.store.dispatch({
              type: AdminGroupsActionTypes.setIsUpdate,
              isUpdate: true,
            });
            this.store.dispatch({
              type: AdminGroupsActionTypes.setFirstTimeOfCreateClient,
              firstTimeCreateClient: false,
            });
            // this.store.dispatch({
            //   type: AdminGroupsActionTypes.setCreateMode,
            //   createMode: 'noUpdate',
            // });
            this.router.navigate(['/admin-groups/create-client']);
            return {
              type: AdminGroupsActionTypes.getGroupsByClientIdSuccess,
            };
          }),
          catchError((response) => {
            return of({ type: AdminGroupsActionTypes.updateGroupFail });
          })
        )
      )
    );
  });

  deleteGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminGroupsActionTypes.deleteGroup),
      mergeMap((payload) =>
        this.adminGroupsService.deleteGroup(payload['group_id']).pipe(
          map((response) => {
            this.store.dispatch({
              type: AdminGroupsActionTypes.getGroupsByClientId,
              client_id: payload['client_id'],
              pageIndex: 0,
              name: '',
              ascdesc: 'DESC',
              searchString: '',
            });

            return {
              type: AdminGroupsActionTypes.getGroupsByClientIdSuccess,
            };
          }),
          catchError((response) => {
            return of({ type: AdminGroupsActionTypes.deleteGroupFail });
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private adminGroupsService: AdminGroupsService
  ) {}
}
