import { createAction, props } from '@ngrx/store';

export const enum AdminGroupsActionTypes {
  getAllClients = '[AdminGroups] get All Clients',
  getAllClientsSuccess = '[AdminGroups] get All Clients Success',
  getAllClientsFail = '[AdminGroups] get All Clients Fail',

  createClient = '[AdminGroups] create Client',
  createClientFail = '[AdminGroups] create Client Fail',

  setActiveClient = '[AdminGroups] set Active Client to State',

  setEditMode = '[AdminGroups] set Edit Mode',

  updateClient = '[AdminGroups] update Client',
  updateClientFail = '[AdminGroups] update Client Fail',

  setClientId = '[AdminGroups] set clientId',

  createGroup = '[AdminGroups] create Group',
  createGroupSuccess = '[AdminGroups] create Group success',
  createGroupFail = '[AdminGroups] create Group Fail',

  getGroupWithUsers = '[AdminGroups] getGroup With Users',
  getGroupWithUsersSuccess = '[AdminGroups] getGroup With User success',
  getGroupWithUsersFail = '[AdminGroups] getGroup With Users Fail',

  updateGroup = '[AdminGroups] update Group',
  updateGroupFail = '[AdminGroups] update Group Fail',

  getGroupsByClientId = '[AdminGroups] get Groups By ClientId',
  getGroupsByClientIdSuccess = '[AdminGroups] get Groups By ClientId success',
  getGroupsByClientIdFail = '[AdminGroups] get Groups By ClientId Fail',

  setGroupId = '[AdminGroups] set GroupId',

  deleteGroup = '[AdminGroups] delete Group',
  deleteGroupFail = '[AdminGroups] delete Group Fail',

  setCreateMode = '[AdminGroups] set Create Mode',

  deleteClient = '[AdminGroups] delete client',
  deleteClientFail = '[AdminGroups] delete client Fail',

  setActiveGroup = '[AdminGroups] set Active Group to State',

  setFirstTimeOfCreateClient = '[AdminGroups] first time of create client',

  setIsUpdate = '[AdminGroups] is update',
}

export const getAllClients = createAction(
  AdminGroupsActionTypes.getAllClients,
  props<{
    pageIndex: number;
    name?: string;
    ascdesc?: string;
    searchString: string;
  }>()
);

export const getAllClientsSuccess = createAction(
  AdminGroupsActionTypes.getAllClientsSuccess,
  props<{ clientsData: any[]; clientsDataTotal: number; clientsDataPageIndex: number }>()
);

export const getAllClientsFail = createAction(AdminGroupsActionTypes.getAllClientsFail);

export const createClient = createAction(
  AdminGroupsActionTypes.createClient,
  props<{
    name: string;
    description: string;
    imageLocation: File;
  }>()
);

export const createClientFail = createAction(AdminGroupsActionTypes.createClientFail);

export const setActiveClient = createAction(AdminGroupsActionTypes.setActiveClient, props<{ activeClient: any[] }>());

export const setEditMode = createAction(AdminGroupsActionTypes.setEditMode, props<{ editMode: boolean }>());

export const updateClient = createAction(
  AdminGroupsActionTypes.updateClient,
  props<{ id: string; name: string; description: string; image: File }>()
);

export const updateClientFail = createAction(AdminGroupsActionTypes.updateClientFail);

export const setClientId = createAction(AdminGroupsActionTypes.setClientId, props<{ client_id: string }>());

export const createGroup = createAction(
  AdminGroupsActionTypes.createGroup,
  props<{
    client_id: string;
    group_id: string;
    name: string;
    description: string;
  }>()
);
export const createGroupSuccess = createAction(AdminGroupsActionTypes.createGroupSuccess);

export const createGroupFail = createAction(AdminGroupsActionTypes.createGroupFail);

export const getGroupWithUsers = createAction(
  AdminGroupsActionTypes.getGroupWithUsers,
  props<{
    group_id: string;
    pageIndex: number;
    name?: string;
    ascdesc?: string;
    searchString: string;
  }>()
);
export const getGroupWithUsersSuccess = createAction(
  AdminGroupsActionTypes.getGroupWithUsersSuccess,
  props<{
    activeUsers: any[];
    usersTotal: number;
    usersPageIndex: number;
  }>()
);

export const getGroupWithUsersFail = createAction(AdminGroupsActionTypes.getGroupWithUsersFail);

export const updateGroup = createAction(
  AdminGroupsActionTypes.updateGroup,
  props<{ group_id: string; name: string; description: string; client_id: string }>()
);

export const updateGroupFail = createAction(AdminGroupsActionTypes.updateGroupFail);

export const getGroupsByClientId = createAction(
  AdminGroupsActionTypes.getGroupsByClientId,
  props<{ client_id: string; pageIndex: number; name?: string; ascdesc?: string; searchString: string }>()
);

export const getGroupsByClientIdSuccess = createAction(
  AdminGroupsActionTypes.getGroupsByClientIdSuccess,
  props<{ clientGroups: any[]; clientGroupsTotal: number; clientGroupsPageIndex: number }>()
);

export const getGroupsByClientIdFail = createAction(AdminGroupsActionTypes.getGroupsByClientIdFail);

export const setGroupId = createAction(AdminGroupsActionTypes.setGroupId, props<{ group_id: string }>());

export const setCreateMode = createAction(AdminGroupsActionTypes.setCreateMode, props<{ createMode: string }>());

/**
 * @param  {Array}  group_id - Array of group ids.
 */
export const deleteGroup = createAction(AdminGroupsActionTypes.deleteGroup, props<{ group_id: any[] }>());

export const deleteGroupFail = createAction(AdminGroupsActionTypes.deleteGroupFail);

export const deleteClient = createAction(AdminGroupsActionTypes.deleteClient, props<{ client_id: string }>());

export const deleteClientFail = createAction(AdminGroupsActionTypes.deleteClientFail);

export const setActiveGroup = createAction(AdminGroupsActionTypes.setActiveGroup, props<{ activeGroup: {} }>());

export const setFirstTimeOfCreateClient = createAction(
  AdminGroupsActionTypes.setFirstTimeOfCreateClient,
  props<{ firstTimeCreateClient: boolean }>()
);

export const setIsUpdate = createAction(AdminGroupsActionTypes.setIsUpdate, props<{ isUpdate: boolean }>());
