import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { state, style, trigger } from '@angular/animations';
import { WSService } from 'src/app/data/api/WSServices/WSService';
import { WebSocketRequest } from 'src/app/data/models/request-apis/WebSocketRequest';
import { NotificationsStoreService } from 'src/app/Store/store-services/notifications-store.service';
import { NotificationImpl } from 'src/app/data/models/NotificationImpl';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/utils/auth/auth.service';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { selectLogoHeader } from 'src/app/features/administrative/store/administrative-page.selectors';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { selectLanguage, selectSessionUserFullName, selectUsersLoading } from 'src/app/Store/users/users.selectors';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/data/models/User';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { UserActions } from 'src/app/Store/users/users.actions';
import { CONFIRMDIALOGSTRINGS } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      state('close', style({})),
      state(
        'open',
        style({
          transform: 'rotate(45deg)',
          transformOrigin: 'left',
          margin: '6px',
        })
      ),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  show: Observable<boolean>;
  opened = false;
  notifications$: Observable<{
    length: number;
    notifications: NotificationImpl[];
  }>;
  abilities;
  imageUrl: any;
  language: string;

  load = false;

  userMenu = false;
  userFullName$: Observable<User[]>;
  isUserLoaded$: Observable<boolean>;

  // close sidenav when escape button is pressed
  @HostListener('document:keyup.esc')
  onkeyup() {
    this.opened = false;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }

  constructor(
    private sanitizer: DomSanitizer,
    public authService: AuthService,
    private route: Router,
    private store: Store,
    private translate: TranslateService,
    public location: Location,
    private eRef: ElementRef,
    private notificationStore: NotificationsStoreService,
    private wsService: WSService,
    private _dialogService: GeneralDialogService
  ) {}

  ngOnInit(): void {
    this.isUserLoaded$ = this.store.select(selectUsersLoading);
    this.userFullName$ = this.store.select(selectSessionUserFullName);

    this.store
      .select(selectLanguage)
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe((lang) => {
        this.language = lang;
        this.translate.use(lang);

        this.store.dispatch({
          type: UserActions.updateUser,
          user: {
            username: this.authService.getUserName(),
            Language: this.language,
          },
        });
      });

    this.notifications$ = this.notificationStore.selectNotes();
    this.store
      .select(selectLogoHeader)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(data);
        this.load = true;
      });
  }

  readNotifications(notifications: NotificationImpl[]) {
    const notificationsToBeSent: WebSocketRequest = {
      type: 'notifications',
      data: {
        notifications: notifications,
        action: 'Read',
        username: this.authService.getUserName(),
      },
    };
    this.wsService.sendMessageToWebSocket(notificationsToBeSent);
    this.notificationStore.readNotes(notifications);
  }

  navigateToNotification(notification: NotificationImpl) {
    let id: string = notification.notification_link;
    if (id) {
      switch (notification.Class_Notification) {
        case 'Note_created':
          this.getNotes(id);
          break;
        case 'QUESTIONNAIRE_NEED_COMPLETION':
          this._dialogService.openConfirmDialog({
            info: CONFIRMDIALOGSTRINGS.NOTIFICATION_NAVIGATE_TO_PROJECT,
            message: notification.message,
          });
          this._dialogService.openConfirmDialogResponse().subscribe((res) => {
            if (res !== 'Cancel') {
              this.route.navigateByUrl(`/${ROUTES_STRINGS.ANSWERQUESTIONNAIRE}/${id}`);
            }
          });
          break;
        default:
          this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.route.navigateByUrl(`/${ROUTES_STRINGS.ANSWERQUESTIONNAIRE}/${id}`);
          });
          break;
      }
    }
  }

  private getNotes(id: string) {
    this._dialogService.openNotesModal({
      notes: null,
      Quest_Assing_ID: id,
      pageIndex: 0,
      Status: 'Assigned',
    });
  }

  getAbilities() {
    this.abilities = this.authService.getUserAbilities();
    this.opened = true;
    this.userMenu = false;
  }

  openUserMenu() {
    this.abilities = this.authService.getUserAbilities();
    this.opened = true;
    this.userMenu = true;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
