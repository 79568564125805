import { AuthService } from 'src/app/utils/auth/auth.service';
import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsStoreService } from '../../Store/store-services/notifications-store.service';
import { NotificationImpl } from '../models/NotificationImpl';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { Subscription } from 'rxjs';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketDataHandlerService implements OnDestroy {
  snabacRef = null;
  language: string;
  langToUnsubscribe: Subscription;

  constructor(
    private notificationStore: NotificationsStoreService,
    private route: Router,
    private auth: AuthService,
    private _snackBar: MatSnackBar,
    private store: Store,
    private _dialogService: GeneralDialogService
  ) {}

  ngOnInit(): void {
    this.langToUnsubscribe = this.store.select(selectLanguage).subscribe((lang) => {
      this.language = lang;
    });
  }

  ngOnDestroy(): void {
    this.langToUnsubscribe.unsubscribe();
  }

  dataHander(obj: {
    type: string;
    body: {
      data?: any;
      length?: number;
      user?: string;
      newNotification?: NotificationImpl;
    };
  }) {
    switch (obj.type) {
      case 'notifications':
        this.notificationStore.loadInitialNotifications(obj.body.data);
        break;
      case 'new-notification':
        if (this.auth.getUserName() === obj.body.user) {
          if (
            obj.body.newNotification.Class_Notification === 'Topic_AS_removed' ||
            obj.body.newNotification.Class_Notification === 'Multiple_Topic_AS_removed' ||
            obj.body.newNotification.Class_Notification === 'All_Topics_AS_removed' ||
            obj.body.newNotification.Class_Notification === 'User_deleted' ||
            obj.body.newNotification.Class_Notification === 'Project_Res_Removed'
          ) {
            this.snabacRef = this._snackBar.open(
              this.language === 'it'
                ? obj.body.newNotification.Text_Notification
                : obj.body.newNotification.Text_Notification_eng,
              '✖',
              {
                duration: 10000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'notif-error',
              }
            );
          } else {
            this.snabacRef = this._snackBar.open(
              this.language === 'it'
                ? obj.body.newNotification.Text_Notification
                : obj.body.newNotification.Text_Notification_eng,
              this.language === 'it' ? 'Apri' : 'Open',
              {
                duration: 10000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                panelClass: 'notif-error',
              }
            );
          }
          this.snabacRef.onAction().subscribe(() => {
            this.navigateToNotification(obj.body.newNotification);
          });

          this.notificationStore.newNotificationIncoming(obj.body.newNotification);
        }
        break;
      default:
        break;
    }
  }

  navigateToNotification(notification: NotificationImpl) {
    let id = notification.notification_link;
    this.notificationStore.snackBarMarkAsRead(notification);
    if (id) {
      switch (notification.Class_Notification) {
        case 'Note_created':
          this.getNotes(id);
          break;
        case 'Project_close':
          this.route.navigateByUrl(`/${ROUTES_STRINGS.MODIFYPROJECT}/${id}`);
          break;
        default:
          this.route.navigateByUrl(`/${ROUTES_STRINGS.ANSWERQUESTIONNAIRE}/${id}`);
          break;
      }
    }
  }

  private getNotes(id: string) {
    this._dialogService.openNotesModal({
      notes: null,
      Quest_Assing_ID: id,
      pageIndex: 0,
      Status: 'Assigned',
    });
  }
}
