export interface adminGroupsState {
  loading: boolean;
  clientsData: any[];
  clientsDataTotal?: number;
  clientsDataPageIndex?: number;
  activeClient: any[];
  editMode: boolean;
  client_id: string;
  activeUsers: any[];
  group_id: string;
  clientGroups: any[];
  clientGroupsTotal?: number;
  clientGroupsPageIndex?: number;
  usersTotal?: number;
  usersPageIndex?: number;
  createMode?: string;
  activeGroup: {};
  firstTimeCreateClient?: boolean;
  isUpdate?: boolean;
}

export const initialAdminGroupsState: adminGroupsState = {
  loading: false,
  clientsData: [],
  clientsDataTotal: 0,
  clientsDataPageIndex: 0,
  activeClient: [],
  editMode: false,
  client_id: '',
  activeUsers: [],
  group_id: '',
  clientGroups: [],
  clientGroupsTotal: 0,
  clientGroupsPageIndex: 0,
  usersTotal: 0,
  usersPageIndex: 0,
  createMode: 'create',
  activeGroup: {},
  firstTimeCreateClient: false,
  isUpdate: false,
};
