import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { selectUsersFullname } from 'src/app/Store/users/users.selectors';
import { GeneralDialogService } from 'src/app/utils/general-dialog.service';
import { Project } from 'src/app/data/models/Project';
import { User } from 'src/app/data/models/User';
import {
  addProjectWatchList,
  archiveProject,
  changeOwner,
  deleteProject,
  ProjectActionTypes,
  storeCheckedProjects,
} from 'src/app/Store/projects/actions/projects.actions';
import { selectLanguage } from 'src/app/Store/users/users.selectors';
import { selectModelFilter, selectProjects } from 'src/app/Store/projects/selectors/projects.selectors';
import { VerbPronounDictionaryPipe } from 'src/app/utils/pipes/verb-pronoun-dictionary.pipe';
import { MagicStringsService } from 'src/app/utils/magic-strings.service';
import { AdvancedFilterComponent } from '../advanced-filter/advanced-filter.component';
import { ActionTypes } from 'src/app/Store/questionnaire-store/state.actions';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { CONFIRMDIALOGSTRINGS } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsTableComponent implements OnInit {
  @Input()
  data: Project[];

  @Input()
  lang;

  @Input()
  totalPageSizeAndIndex: { total: number; pageIndex: number };

  @Input()
  loadingProjects = true;

  @Output()
  showModelSelected = new EventEmitter<void>();

  @ViewChild(AdvancedFilterComponent)
  private advancedFilter: AdvancedFilterComponent;

  private ngUnsubscribe = new Subject();

  watchListUsers = {};

  showWatchListDialog = false;

  usersFullName: Observable<{}>;

  structureTableSortingOptions = {
    pageIndex: 0,
    title: 'TITLE',
    ascdesc: 'ASC',
  };

  modelFilter = '';
  creatorFilter = '';
  searchTerm = '';
  expireFromFilter = '';
  expireToFilter = '';
  creationFromFilter = '';
  creationToFilter = '';
  status = {
    draft: true,
    active: true,
    closed: true,
    archived: false,
  };

  projectIdToBeAddedToWatchlist: string;
  showUserOwnerTableForChange: boolean = false;

  selectedProjectId: string;

  //Export Popup
  showExportPopup = false;
  exportMethod: string;
  exMethodsArr: string[] = ['Project', 'Project and Attachments'];

  indexToChange: any;
  indexToExport: any;

  fullUsersWatchlist: User[] = [];
  preselectedUsers = [];

  projIDToExport: string;

  showFilter: boolean = false;

  verbPipe = new VerbPronounDictionaryPipe();

  selection = new SelectionModel<Project>(true, []);
  checkedProjects: Project[] = [];

  constructor(
    private store: Store,
    private route: Router,
    private dialogService: GeneralDialogService,
    private magic: MagicStringsService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private cdRef:ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.selection.isSelected = this.isRowChecked.bind(this);

    this.store
      .select(selectModelFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((filter) => {
        if (filter) {
          this.modelFilter = filter;
          this.advancedFilter.resetFilters();
          this.advancedFilter.closeAllExpands();
          this.advancedFilter.toggleFilter(false);
        }
      });

    this.usersFullName = this.store.select(selectUsersFullname);
  }

  updateProjectsFilters(options) {
    this.modelFilter = options.modelFilter;
    this.creatorFilter = options.creatorFilter;
    this.searchTerm = options.searchTerm;
    this.expireFromFilter = options.expireFromFilter;
    this.expireToFilter = options.expireToFilter;
    this.creationFromFilter = options.creationFromFilter;
    this.creationToFilter = options.creationToFilter;
    this.status = { ...options.status };
  }

  projectReset() {
    this.store.dispatch({
      type: ProjectActionTypes.getAllProjects,
      allStatus: true,
    });
    this.modelFilter = '';
    this.creatorFilter = '';
    this.searchTerm = '';
    this.expireFromFilter = '';
    this.expireToFilter = '';
    this.creationFromFilter = '';
    this.creationToFilter = '';
    this.status = {
      draft: true,
      active: true,
      closed: true,
      archived: false,
    };

    this.store.dispatch({
      type: ProjectActionTypes.saveProjectFilters,
      saved: false,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      searchTerm: this.searchTerm,
      expireFromFilter: this.expireFromFilter,
      expireToFilter: this.expireToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
      status: {
        draft: this.status.draft,
        active: this.status.active,
        closed: this.status.closed,
        archived: this.status.archived,
      },
    });
  }

  projectSearch(data) {
    this.updateProjectsFilters(data);

    if (
      data.saved === true ||
      this.modelFilter ||
      this.searchTerm ||
      this.creatorFilter ||
      this.creationFromFilter ||
      this.creationToFilter ||
      this.expireFromFilter ||
      this.expireToFilter ||
      this.status.active === false ||
      this.status.closed === false ||
      this.status.draft === false ||
      this.status.archived === true
    ) {
      this.store.dispatch({
        type: ProjectActionTypes.saveProjectFilters,
        saved: true,
        modelFilter: this.modelFilter,
        creatorFilter: this.creatorFilter,
        searchTerm: this.searchTerm,
        expireFromFilter: this.expireFromFilter,
        expireToFilter: this.expireToFilter,
        creationFromFilter: this.creationFromFilter,
        creationToFilter: this.creationToFilter,
        status: {
          draft: this.status.draft,
          active: this.status.active,
          closed: this.status.closed,
          archived: this.status.archived,
        },
      });
    }

    this.store.dispatch({
      type: ProjectActionTypes.getAllProjects,
      pageIndex: 0,
      title: this.structureTableSortingOptions.title,
      AscDesc: this.structureTableSortingOptions.ascdesc,
      archived: this.status.archived,
      searchString: this.searchTerm,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      statusFilter: this.status,
      expireFromFilter: this.expireFromFilter,
      expireToFilter: this.expireToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
    });
  }

  archiveProject(ciao?: Project) {
  let infoDialog: string;
    let archivedArray: Project[] = [];
    let notArchivedArray: Project[] = [];

    if (ciao) {
      if (ciao.PROJECT_STATUS === 'Active') {
        infoDialog = CONFIRMDIALOGSTRINGS.ARCHIVE_SINGLE_ACTIVE_PROJECT;
      } else {
        infoDialog = CONFIRMDIALOGSTRINGS.ARCHIVE_SINGLE_NOTACTIVE_PROJECT;
      }
      archivedArray.push(ciao);
    } else if (this.checkedProjects.length > 0) {
      this.checkedProjects.forEach((checked) => {
        if (checked.PROJECT_STATUS === 'Draft' || checked.PROJECT_STATUS === 'Closed') {
          infoDialog = CONFIRMDIALOGSTRINGS.ARCHIVE_MULTIPLE_PROJECTS;
          archivedArray.push(checked);
        } else if (checked.PROJECT_STATUS === 'Active') {
          notArchivedArray.push(checked);
        }
      });
    }

    if (archivedArray.length > 0) {
      this.store.dispatch(storeCheckedProjects({ projectArray: archivedArray }));
      this.dialogService.openConfirmDialog({
        info: infoDialog,
      });
      this.dialogService
        .openConfirmDialogResponse()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((btn) => {
          if (btn !== 'Cancel') {
            archivedArray.forEach((project) => {
              this.store.dispatch(
                archiveProject({
                  projectId: project.PK_PROJECT,
                  pageIndex: this.structureTableSortingOptions.pageIndex,
                  title: this.structureTableSortingOptions.title,
                  AscDesc: this.structureTableSortingOptions.ascdesc,
                  archived: this.status.archived,
                  searchString: this.searchTerm,
                  modelFilter: this.modelFilter,
                  creatorFilter: this.creatorFilter,
                  statusFilter: this.status,
                  expireFromFilter: this.expireFromFilter,
                  expireToFilter: this.expireToFilter,
                  creationFromFilter: this.creationFromFilter,
                  creationToFilter: this.creationToFilter,
                })
              );
            });
            this.checkedProjects.length = 0;
            this.selection.clear();

            if (notArchivedArray.length > 0) {
              this._snackBar.open(
                this.lang === 'it'
                  ? `(${notArchivedArray.length}) ${this.magic.Progetti.toLowerCase()} attivi non sono stati archiviati`
                  : `(${
                      notArchivedArray.length
                    }) active ${this.magic.ProgettiENG.toLowerCase()} have not been archived`,
                this.translate.instant('QUESTION_TYPES_FORM.CLOSE'),
                {
                  duration: 4000,
                }
              );
            }
          }
        });
    } else {
      this._snackBar.open(
        this.lang === 'it'
          ? `Puoi archiviare solo ${this.magic.Progetti.toLowerCase()} in status draft e/o chiuso.`
          : `You can archive only ${this.magic.ProgettiENG.toLowerCase()} with status draft/closed`,
        this.translate.instant('QUESTION_TYPES_FORM.CLOSE'),
        {
          duration: 4000,
        }
      );
    }
  }

  deleteProject(project?: Project) {
    let infoDialog: string;
    let deletedArray: Project[] = [];
    let notDeletedArray: Project[] = [];

    if (project) {
      infoDialog = CONFIRMDIALOGSTRINGS.DELETE_SINGLE_PROJECT;
      deletedArray.push(project);
    } else if (this.checkedProjects.length > 0) {
      this.checkedProjects.forEach((checked) => {
        if (checked.PROJECT_STATUS === 'Archived' || checked.PROJECT_STATUS === 'Draft') {
          infoDialog = CONFIRMDIALOGSTRINGS.DELETE_MULTIPLE_PROJECTS;
          deletedArray.push(checked);
        } else {
          notDeletedArray.push(checked);
        }
      });
    }

    if (deletedArray.length > 0) {
      this.store.dispatch(storeCheckedProjects({ projectArray: deletedArray }));
      this.dialogService.openConfirmDialog({
        info: infoDialog,
      });
      this.dialogService
        .openConfirmDialogResponse()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((btn) => {
          if (btn !== 'Cancel') {
            let ids = [];
            let questionnairesReduceProject = {};

            deletedArray.forEach((project) => {
              ids.push(project.PK_PROJECT);

              questionnairesReduceProject[project.FK_QUEST] = !!questionnairesReduceProject[project.FK_QUEST]
                ? questionnairesReduceProject[project.FK_QUEST] + 1
                : 1;
            });
            this.store.dispatch(
              deleteProject({
                project_ids: ids,
                questionnairesReduceProject: questionnairesReduceProject,
                pageIndex: this.structureTableSortingOptions.pageIndex,
                title: this.structureTableSortingOptions.title,
                AscDesc: this.structureTableSortingOptions.ascdesc,
                archived: this.status.archived,
                searchString: this.searchTerm,
                modelFilter: this.modelFilter,
                creatorFilter: this.creatorFilter,
                statusFilter: this.status,
                expireFromFilter: this.expireFromFilter,
                expireToFilter: this.expireToFilter,
                creationFromFilter: this.creationFromFilter,
                creationToFilter: this.creationToFilter,
              })
            );
            this.checkedProjects.length = 0;
            this.selection.clear();

            if (notDeletedArray.length > 0) {
              this._snackBar.open(
                this.lang === 'it'
                  ? `(${
                      notDeletedArray.length
                    }) ${this.magic.Progetti.toLowerCase()} attivi e/o chiusi non sono stati eliminati`
                  : `(${
                      notDeletedArray.length
                    }) active/closed ${this.magic.ProgettiENG.toLowerCase()} have not been deleted`,
                this.translate.instant('QUESTION_TYPES_FORM.CLOSE'),
                {
                  duration: 4000,
                }
              );
            }
          }
        });
    } else {
      this._snackBar.open(
        this.lang === 'it'
          ? `Puoi eliminare solo ${this.magic.Progetti.toLowerCase()} con status draft e/o archiviato`
          : `Only ${this.magic.ProgettiENG.toLowerCase()} with status draft/archived can be deleted`,
        this.translate.instant('QUESTION_TYPES_FORM.CLOSE'),
        {
          duration: 4000,
        }
      );
    }
  }

  changeProjectOwner(user: User) {
    let username: string = user.username;
    this.store.dispatch(changeOwner({ projectId: this.selectedProjectId, newUser: username }));
  }

  toggleChangeOwner(projectId: string) {
    this.selectedProjectId = projectId;
    this.showUserOwnerTableForChange = !this.showUserOwnerTableForChange;
  }

  cloneProject(projectId: string, totalUsers: number) {
    this.dialogService.cloneProjectDialog(projectId, totalUsers);
    this.dialogService
      .cloneProjectDialogResponse()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
        } else if (!res) {
          return;
        }
      });
  }

  modifyProject(projectPK: string) {
    this.route.navigateByUrl(`/${ROUTES_STRINGS.MODIFYPROJECT}/${projectPK}`);
  }

  getNextProjectsPage(event: { length: number; pageIndex: number; pageSize: number; previousPageIndex: number }) {
    this.structureTableSortingOptions.pageIndex = event.pageIndex;
    this.store.dispatch({
      type: ProjectActionTypes.getAllProjects,
      pageIndex: this.structureTableSortingOptions.pageIndex,
      title: this.structureTableSortingOptions.title,
      AscDesc: this.structureTableSortingOptions.ascdesc,
      archived: this.status.archived,
      searchString: this.searchTerm,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      statusFilter: this.status,
      expireFromFilter: this.expireFromFilter,
      expireToFilter: this.expireToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
    });
  }

  sortProjects(event) {
    const direction = event.direction === ('asc' || '') ? 'ASC' : 'DESC';
    event.direction = direction;
    this.structureTableSortingOptions.ascdesc = direction;
    this.structureTableSortingOptions.title = event.active;

    this.store.dispatch({
      type: ProjectActionTypes.getAllProjects,
      pageIndex: 0,
      title: this.structureTableSortingOptions.title,
      AscDesc: this.structureTableSortingOptions.ascdesc,
      archived: this.status.archived,
      searchString: this.searchTerm,
      modelFilter: this.modelFilter,
      creatorFilter: this.creatorFilter,
      statusFilter: this.status,
      expireFromFilter: this.expireFromFilter,
      expireToFilter: this.expireToFilter,
      creationFromFilter: this.creationFromFilter,
      creationToFilter: this.creationToFilter,
    });
  }

  openExportZip(id: any, exportMethod: string) {
    this.projIDToExport = id;
    var isAttachment: boolean = false;
    if (exportMethod.toLowerCase().includes('attachments')) {
      isAttachment = true;
    }

    this.store.dispatch({
      type: ProjectActionTypes.exportProjectZipType,
      data: [this.projIDToExport],
      lang: this.lang,
      topic: this.translate.instant('DICTIONARY.SingleTopic'),
      downloadFiles: isAttachment,
    });
  }

  toggleExportVisibility() {
    this.showExportPopup = !this.showExportPopup;
  }

  closeWatchListWithNoConfirmation() {
    this.showWatchListDialog = false;
  }

  addUsersToProjectWatchlistDialog(projectId: string, users: User[], pre: []) {
    this.showWatchListDialog = true;
    this.projectIdToBeAddedToWatchlist = projectId;
    this.preselectedUsers = pre;
    this.fullUsersWatchlist = users;
    this.watchListUsers = {};
    this.cdRef.detectChanges();    
  }

  confirmWatchListUsers() {
    let userarray: User[] = Object.values(this.watchListUsers);
    if (userarray.length > 0) {
      this.store.dispatch(
        addProjectWatchList({
          projectId: this.projectIdToBeAddedToWatchlist,
          users: userarray,
        })
      );
    }
    this.projectIdToBeAddedToWatchlist = null;
  }

  getUserWatchList(user: User) {
    if (!!this.watchListUsers[user.username]) {
      delete this.watchListUsers[user.username];
    } else {
      this.watchListUsers[user.username] = user;
    }
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  showModelClicked(title, id) {
    this.store.dispatch({
      type: ActionTypes.loadQuestionnairesType,
      pageIndex: 0,
      AscDesc: 'ASC',
      archived: false,
      modelFilter: id,
    });

    this.showModelSelected.emit();
    this.store.dispatch({
      type: ProjectActionTypes.setModelClickedFromProjects,
      modelFilter: title,
    });
  }

  //Selection Methods
  masterToggle() {
    if (this.isPageChecked()) {
      this.data.forEach((project) => {
        this.selection.deselect(project);
        this.checkedProjects = this.checkedProjects.filter((checked) => checked.PK_PROJECT !== project.PK_PROJECT);
      });
    } else {
      this.checkedProjects = [...this.checkedProjects];
      //Reset page selection
      let resetArray: Project[] = [];
      this.data.forEach((project) => {
        this.selection.selected.filter((row) => {
          if (row.PK_PROJECT === project.PK_PROJECT) {
            resetArray.push(row);
          }
        });
      });
      resetArray.forEach((resetEl) => {
        this.checkedProjects = this.checkedProjects.filter((checked) => checked.PK_PROJECT !== resetEl.PK_PROJECT);
      });
      //Add page selection
      this.data.forEach((project) => {
        this.selection.select(project);
        this.checkedProjects = [...this.checkedProjects, project];
      });
    }
  }

  selectionToggle(row: Project, isChecked: boolean) {
    this.selection.toggle(row);
    if (isChecked) {
      this.checkedProjects.push(row);
    } else {
      this.checkedProjects = this.checkedProjects.filter((checked) => checked.PK_PROJECT !== row.PK_PROJECT);
    }
  }

  isPageChecked(): boolean {
    const fetched = this.data;
    let checkedProjectsPerPage = [];
    this.data.forEach((project) => {
      this.checkedProjects.forEach((checked) => {
        if (project.PK_PROJECT === checked.PK_PROJECT) {
          checkedProjectsPerPage.push(project);
        }
      });
    });

    let isPageChecked: boolean =
      checkedProjectsPerPage.length == fetched.length &&
      checkedProjectsPerPage.every((element, index) => {
        return element === fetched[index];
      });
    return isPageChecked;
  }

  isRowChecked(row: Project): boolean {
    const found = this.checkedProjects.find((project) => project.PK_PROJECT === row.PK_PROJECT);
    if (found) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
