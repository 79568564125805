import { NotificationImpl } from '../../data/models/NotificationImpl';

export interface NotificationsState {
  notifications: NotificationImpl[];
  readNotifications: NotificationImpl[];
}

export const initialStateNotifications: NotificationsState = {
  notifications: [],
  readNotifications: [],
};
