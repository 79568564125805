<div class="flex h-full items-center justify-center p-4">
  <div class="flex min-w-[18vw] flex-col gap-4">
    <span class="text-2xl font-semibold text-main">{{ "ADMIN_USER_TABS.USER_CAN_ACCESS" | translate }}</span>

    <div class="h-[1px] w-full bg-main"></div>

    <form [formGroup]="accessForm" class="flex min-w-full flex-col gap-4">
      <div class="flex items-center justify-between">
        <span class="text-xl font-semibold text-black">{{ "DICTIONARY.Modelli" | translate }}</span>
        <mat-radio-group formControlName="models" class="flex items-center gap-4">
          <mat-radio-button [value]="true"> Yes </mat-radio-button>
          <mat-radio-button [value]="false"> No </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-xl font-semibold text-black">{{ "DICTIONARY.Progetti" | translate }}</span>
        <mat-radio-group formControlName="projects" class="flex items-center gap-4">
          <mat-radio-button [value]="true"> Yes </mat-radio-button>
          <mat-radio-button [value]="false"> No </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-xl font-semibold text-black">{{ "ADMIN_USER_TABS.USERS" | translate }}</span>
        <mat-radio-group formControlName="users" class="flex items-center gap-4">
          <mat-radio-button [value]="true"> Yes </mat-radio-button>
          <mat-radio-button [value]="false"> No </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-xl font-semibold text-black"
          >{{ "ADMIN_USER_TABS.CLIENT" | translate }} & {{ "ADMIN_USER_TABS.GROUP" | translate }}</span
        >
        <mat-radio-group formControlName="clientGroup" class="flex items-center gap-4">
          <mat-radio-button [value]="true"> Yes </mat-radio-button>
          <mat-radio-button [value]="false"> No </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-xl font-semibold text-black">{{ "ADMIN_USER_TABS.ADMIN_PAGE" | translate }}</span>
        <mat-radio-group formControlName="admin" class="flex items-center gap-4">
          <mat-radio-button [value]="true"> Yes </mat-radio-button>
          <mat-radio-button [value]="false"> No </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-xl font-semibold text-black">{{ "ADMIN_USER_TABS.DEBUG_PAGE" | translate }}</span>
        <mat-radio-group formControlName="debug" class="flex items-center gap-4">
          <mat-radio-button [value]="true"> Yes </mat-radio-button>
          <mat-radio-button [value]="false"> No </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex items-center justify-between">
        <span class="text-xl font-semibold text-black">{{ "ADMIN_USER_TABS.CUSTOM_PAGE" | translate }}</span>
        <mat-radio-group formControlName="custom" class="flex items-center gap-4">
          <mat-radio-button [value]="true"> Yes </mat-radio-button>
          <mat-radio-button [value]="false"> No </mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </div>
</div>
