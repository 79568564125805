import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-score-panel',
  templateUrl: './analytics-score-panel.component.html',
  styleUrls: ['./analytics-score-panel.component.scss'],
})
export class AnalyticsScorePanelComponent implements OnInit {
  @Input()
  bestScore: { score: number; user: string };

  @Input()
  averageScore: number;

  @Input()
  worstScore: { score: number; user: string };

  @Input()
  totalScore: number;

  constructor() {}

  ngOnInit(): void {}
}
