<mat-sidenav-container [hasBackdrop]="true">
  <mat-toolbar class="container-toolbar !flex !h-[50px] !justify-between !overflow-hidden !bg-main !text-white">
    <div class="flex select-none items-center justify-around">
      <button
        (click)="getAbilities()"
        #menuTrigger="matMenuTrigger"
        mat-icon-button
        class="!bg-transparent"
        aria-label="Example icon-button with menu icon"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>{{ menuTrigger.menuOpen ? "close" : "menu" }}</mat-icon>
      </button>
      <img *ngIf="load" class="ml-3 h-[50px] w-[auto] max-w-[130px] py-[5px]" [src]="imageUrl" />
      <mat-menu #menu="matMenu">
        <app-side-nav
          *ngIf="opened"
          [abilities]="abilities"
          [lang]="language"
          #sidenav
          (closed)="opened = false"
          [location]="location"
          [opened]="opened"
          [userMenu]="userMenu"
        >
        </app-side-nav>
      </mat-menu>
    </div>

    <div class="flex items-center justify-around text-2xl" *ngIf="notifications$ | async as notifies">
      <div class="inline-flex items-center gap-6">
        <div id="username" class="inline-flex items-center gap-1 px-4" *ngIf="!(isUserLoaded$ | async)">
          <button
            (click)="openUserMenu()"
            mat-icon-button
            #menuTrigger="matMenuTrigger"
            mat-icon-button
            class="!bg-transparent"
            aria-label="Example icon-button with menu icon"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon id="username-icon">account_circle</mat-icon>
          </button>
            <div  [matMenuTriggerFor]="menu" id="username-text" class="text-xl cursor-pointer" *ngFor="let user of userFullName$ | async" (click)="openUserMenu()">
              <span>{{ user.Name }} {{ user.Surname }}</span>
          </div>
        </div>
        <hr id="vertical-line" class="h-10 border-l-[1px]" />
        <button (click)="readNotifications(notifies.notifications)" mat-icon-button [matMenuTriggerFor]="notifications">
          <mat-icon
            matTooltip="Notifications"
            matTooltipClass="custom-tooltip"
            *ngIf="notifies.length > 0"
            matBadge="{{ notifies.length }}"
          >
            notifications</mat-icon
          >
          <mat-icon matTooltip="Notifications" matTooltipClass="custom-tooltip" *ngIf="notifies.length === 0">
            notifications
          </mat-icon>
        </button>
      </div>
      <mat-menu #notifications="matMenu" class="notification-menu">
        <h2 class="bg-dialog p-2 pl-5 text-white">
          {{ "HEADER.NOTIFICATIONS" | translate }}
        </h2>
        <p
          *ngIf="notifies.notifications?.length === 0"
          class="notification-item bg-white py-2 px-3 font-medium text-dialog"
        >
          {{ "HEADER.NO_NOTIFICATIONS" | translate }}
        </p>
        <div *ngFor="let notify of notifies.notifications">
          <div [ngClass]="[notify['unclicked'] === true ? 'bg-white text-dialog' : 'bg-dialog text-white']">
            <div
              [ngClass]="{
                unread: notify['unclicked'] === false,
                'cursor-pointer': !!notify['notification_link']
              }"
              class="notification-item py-2 px-3 font-medium"
              (click)="navigateToNotification(notify)"
            >
              <p *ngIf="language === 'it'">{{ notify.Text_Notification }}</p>
              <p *ngIf="language === 'en'">
                {{ notify.Text_Notification_eng }}
              </p>
              <div class="mt-[0.125rem] pl-4 text-xs font-medium">
                {{ notify.Ts_Insert | date: "short" }}
              </div>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>
  </mat-toolbar>
</mat-sidenav-container>
