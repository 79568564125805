export enum UserAbilitiesEnum {
  HomepageFullAccess = 'HomepageFullAccess',
  ModelsProjectsAccess = 'Models&ProjectsAccess',
  UsersManagementFullAccess = 'UsersManagementFullAccess',
  CreateModifyModel = 'CreateModifyModel',
  AdminPageAccess = 'AdminPageAccess',
  UpdateActiveModels = 'UpdateActiveModels',
  CustomPageAccess = 'CustomPageAccess',
  AdminGroups = 'AdminGroups',
}

export type UserAbilities =
  | UserAbilitiesEnum.HomepageFullAccess
  | UserAbilitiesEnum.ModelsProjectsAccess
  | UserAbilitiesEnum.UsersManagementFullAccess
  | UserAbilitiesEnum.CreateModifyModel
  | UserAbilitiesEnum.AdminPageAccess
  | UserAbilitiesEnum.CustomPageAccess
  | UserAbilitiesEnum.AdminGroups
  | UserAbilitiesEnum.UpdateActiveModels;

export const ABILITIES_HELPER = {
  HomepageFullAccess: false,
  'Models&ProjectsAccess': false,
  UsersManagementFullAccess: false,
  CreateModifyModel: false,
  AdminPageAccess: false,
  UpdateActiveModels: false,
  AdminGroups: false,
  CustomPageAccess: false,
  '*': true,
};

export interface Ability {
  Ability: UserAbilitiesEnum;
}
