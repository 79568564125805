import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-vincolata-lines',
  templateUrl: './vincolata-lines.component.html',
  styleUrls: ['./vincolata-lines.component.scss'],
})
export class VincolataLinesComponent implements OnInit {
  @Input()
  childIndexes: number[] = [];

  @Input()
  parentIndex: number;

  distance: number;

  lineLength = 0;
  lastIndex = 0;
  oneRowHeight = 0;

  ngOnInit(): void {
    this.childIndexes.forEach((index) => {
      if (index > this.lastIndex) {
        this.lastIndex = index;
      }
    });

    this.distance = this.lastIndex - this.parentIndex;

    const remSize = +getComputedStyle(document.documentElement).fontSize.replace('px', '');

    this.lineLength = this.distance * remSize * 5.2 + 0.5 * remSize;

    this.oneRowHeight = this.lineLength / (this.lastIndex - this.parentIndex);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.ngOnInit();
  }
}
