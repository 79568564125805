import { logOut, resetPassword, resetPasswordSend } from './../login-store/login.actions';
import { createReducer, on } from '@ngrx/store';
import { forgotPassword, forgotPasswordSend, loggedIn, login } from '../login-store/login.actions';
import { initialState } from '../state';
import { cloneDeep } from 'lodash';
import {
  addQuestionnaires,
  addQuestionnairesFail,
  addQuestionnairesSuccess,
  deleteQuestionnairesAction,
  deleteQuestionnairesSuccess,
  getSingleQuestionnaire,
  loadQuestionnaires,
  removeAssignedQuestionnaire,
  setModifiedQuestionnaire,
  setQuestionnairesType,
  setUserAbilities,
  updateQuestionnaires,
  updateQuestionnairesFail,
  saveModelFilters,
  storeCheckedModels,
  reduceNumberOfProjects,
  saveUserFilters,
} from './state.actions';
import { setUsers } from '../users/users.actions';

const _questionnaireReducer = createReducer(
  initialState,
  on(
    saveModelFilters,
    (
      state,
      {
        saved,
        creatorFilter,
        searchTerm,
        lastAssignmentFromFilter,
        lastAssignmentToFilter,
        lastChangeFromFilter,
        lastChangeToFilter,
        creationFromFilter,
        creationToFilter,
        status,
      }
    ) => ({
      ...state,
      filterOptions: {
        saved: saved,
        creatorFilter: creatorFilter,
        searchTerm: searchTerm,
        lastAssignmentFromFilter: lastAssignmentFromFilter,
        lastAssignmentToFilter: lastAssignmentToFilter,
        lastChangeFromFilter: lastChangeFromFilter,
        lastChangeToFilter: lastChangeToFilter,
        creationFromFilter: creationFromFilter,
        creationToFilter: creationToFilter,
        status: {
          draft: status.draft,
          active: status.active,
          archived: status.archived,
        },
      },
    })
  ),
  on(
    saveUserFilters,
    (
      state,
      {
        saved,
        searchTerm,
        clientFilter,
        groupFilter,
        emailFilter,
        status,
      }
    ) => ({
      ...state,
      userFilterOptions: {
        saved: saved,
        searchTerm: searchTerm,
        clientFilter: clientFilter,
        groupFilter: groupFilter,
        emailFilter: emailFilter,
        status: {
          disabled: status.disabled,
          active: status.active,
        },
      },
    })
  ),
  on(loadQuestionnaires, (state, { dontResetQuestionnaireToBeModified }) => {
    let data = { ...state, loading: true };

    if (!dontResetQuestionnaireToBeModified) {
      data.questionnaireToBeModified = null;
    }

    return data;
  }),
  on(getSingleQuestionnaire, (state) => ({
    ...state,
    loading: true,
  })),
  on(addQuestionnaires, (state) => ({
    ...state,
    loading: true,
  })),
  on(addQuestionnairesSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(addQuestionnairesFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(setModifiedQuestionnaire, (state, { questionnaire }) => ({
    ...state,
    loading: false,
    questionnaireToBeModified: { ...questionnaire },
  })),
  on(setQuestionnairesType, (state, { data }) => ({
    ...state,
    pageIndex: data['pageIndex'],
    Questionnaires: data['data'],
    loading: false,
    total: data['length'],
  })),
  on(removeAssignedQuestionnaire, (state, assignedQuests) => ({
    ...state,
  })),
  on(updateQuestionnaires, (state, { modifiedQuestionnaire }) => {
    state.Questionnaires.forEach((quest) => {
      if (quest.Questionnaire_id === modifiedQuestionnaire.Questionnaire_id) {
        quest = modifiedQuestionnaire;
      }
    });
    return { ...state, loading: true };
  }),
  on(updateQuestionnairesFail, (state, { modifiedQuestionnaire }) => {
    state.Questionnaires.forEach((quest) => {
      if (quest.Questionnaire_id === modifiedQuestionnaire.Questionnaire_id) {
        quest = modifiedQuestionnaire;
      }
    });
    return { ...state, loading: false };
  }),
  on(deleteQuestionnairesAction, (state, { deletedIds }) => ({
    ...state,
    loading: true,
    Questionnaires: state.Questionnaires.filter((quest) => !deletedIds.includes(quest)),
  })),
  on(deleteQuestionnairesSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(reduceNumberOfProjects, (state, { questionnairesReduceProject }) => {
    let questionnairesCopy = cloneDeep(state.Questionnaires);

    for (let [key, value] of Object.entries(questionnairesReduceProject)) {
      let index = questionnairesCopy.findIndex((quest) => quest.Questionnaire_id === key);
      questionnairesCopy[index].Number_Of_Projects =
        Number(questionnairesCopy[index].Number_Of_Projects) - Number(value) + '';
    }
    return {
      ...state,
      Questionnaires: questionnairesCopy,
      loading: false,
    };
  }),
  on(login, (state) => ({
    ...state,
    loading: true,
  })),
  on(loggedIn, (state) => ({
    ...state,
    loading: false,
    isUserLoggedIn: true,
  })),
  on(logOut, (state) => ({
    ...state,
    loading: false,
    isUserLoggedIn: false,
  })),
  on(forgotPassword, (state) => ({
    ...state,
    loading: true,
  })),
  on(forgotPasswordSend, (state) => ({
    ...state,
    loading: false,
  })),
  on(resetPassword, (state) => ({
    ...state,
    loading: true,
  })),
  on(resetPasswordSend, (state) => ({
    ...state,
    loading: false,
  })),
  on(setUsers, (state, { users }) => ({
    ...state,
    users,
  })),
  on(setUserAbilities, (state, { data }) => ({
    ...state,
    UserAbilities: data,
  })),
  on(loggedIn, (state) => ({
    ...state,
    isUserLoggedIn: true,
  })),
  on(storeCheckedModels, (state, { modelArray }) => ({
    ...state,
    checkedModels: modelArray,
  }))
);

export function questionnaireReducer(state, action) {
  return _questionnaireReducer(state, action);
}
