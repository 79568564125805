import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { UserAbilitiesEnum } from '../data/models/abilities';
import { HomePageComponent } from '../features/home-page/home-page.component';
import { CreateNewQuestionnaireComponent } from '../features/questionnaire-creation/create-new-questionnaire/create-new-questionnaire.component';
import { AssignModelPageComponent } from '../features/questionnaire-management/assign-model-page/assign-model-page.component';
import { QuestionnaireManagementComponent } from '../features/questionnaire-management/management-questionnaire.component';
import { ModifyProjectComponent } from '../features/questionnaire-management/modify-project/modify-project.component';
import { ModifyQuestionnaireComponent } from '../features/questionnaire-management/modify-questionnaire/modify-questionnaire.component';
import { QuestionnaireToAnswerComponent } from '../features/questionnaire-to-answer/questionnaire-to-answer.component';
import { AuthGuardService } from '../utils/auth/auth-guard.service';
import { LoggedInAuthGuardService } from '../utils/auth/logged-in-auth-guard.service';
import { environment } from 'src/environments/environment';
import { UnAuthorisedGuardService } from '../utils/auth/unauthorised-guard.service.';
import { AdminUsersTabeComponent } from '../features/admin-groups/admin-users-tab/admin-users-tab.component';

export const enum ROUTES_STRINGS {
  LOGIN = 'login',
  HOME = 'home',
  PROFILE = 'profile',
  QUESTIONNAIRE_MANAGEMENT = 'questionnaires-management',
  CREATENEWQUESTIONNAIRE = 'create-new-questionnaire',
  DASHBOARD = 'dashboard',
  MODIFYQUESTIONNAIRE = 'modify-questionnaire',
  ARCHIVEQUESTIONNAIRE = 'archive-questionnaire',
  ANSWERQUESTIONNAIRE = 'answer-questionnaire',
  ASSIGNMODEL = 'assign-model',
  MODIFYPROJECT = 'modify-project',
  ADMINPAGE = 'admin-page',
  ADMINISTRATIVEPAGE = 'administrative-page',
  UNAUTHORISED = 'unauthorised/400',
  ADMINGROUPS = 'admin-groups',
  MODIFYUSER = 'user',
  CREATEUSER = 'create-user'
}

const publicRoutes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuardService],
    data: { Ability: 'HomepageFullAccess' },
  },
  {
    path: ROUTES_STRINGS.LOGIN,
    component: LoginComponent,
    canActivate: [LoggedInAuthGuardService],
  },
  { path: 'reset-password', component: ResetPasswordComponent },
];

const routes: Routes = [
  { path: '', children: publicRoutes },
  {
    path: `${ROUTES_STRINGS.ANSWERQUESTIONNAIRE}/:questionnaireId`,
    component: QuestionnaireToAnswerComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: ROUTES_STRINGS.QUESTIONNAIRE_MANAGEMENT,
    component: QuestionnaireManagementComponent,
    canActivate: [AuthGuardService],
    data: { Ability: UserAbilitiesEnum.ModelsProjectsAccess },
  },
  {
    path: ROUTES_STRINGS.HOME,
    component: HomePageComponent,
    canActivate: [AuthGuardService],
    data: { Ability: UserAbilitiesEnum.HomepageFullAccess },
  },
  {
    path: ROUTES_STRINGS.PROFILE,
    loadChildren: () => import('../features/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuardService],
    data: { Ability: UserAbilitiesEnum.UsersManagementFullAccess },
  },
  {
    path: ROUTES_STRINGS.ADMINGROUPS,
    loadChildren: () => import('../features/admin-groups/admin-groups.module').then((m) => m.AdminGroupsModule),
    canActivate: [AuthGuardService],
    data: { Ability: UserAbilitiesEnum.UsersManagementFullAccess },
  },
  {
    path: `${ROUTES_STRINGS.ASSIGNMODEL}/:id`,
    component: AssignModelPageComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },

  {
    path: `${ROUTES_STRINGS.CREATENEWQUESTIONNAIRE}`,
    component: CreateNewQuestionnaireComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
    data: { Ability: UserAbilitiesEnum.CreateModifyModel },
  },
  {
    path: ROUTES_STRINGS.ADMINPAGE,
    loadChildren: () => import('../features/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardService],
    data: { Ability: 'AdminPageAccess' },
  },
  {
    path: ROUTES_STRINGS.ADMINISTRATIVEPAGE,
    loadChildren: () => import('../features/administrative/administrative.module').then((m) => m.AdministrativeModule),
    canActivate: [AuthGuardService],
    data: { Ability: 'AdminPageAccess' },
  },
  {
    path: `${ROUTES_STRINGS.MODIFYQUESTIONNAIRE}/:id`,
    component: ModifyQuestionnaireComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: `${ROUTES_STRINGS.MODIFYPROJECT}/:projectPK`,
    component: ModifyProjectComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: `${ROUTES_STRINGS.MODIFYUSER}/:username`,
    component: AdminUsersTabeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: `${ROUTES_STRINGS.CREATEUSER}`,
    component: AdminUsersTabeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '**',
    redirectTo: ROUTES_STRINGS.LOGIN,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
