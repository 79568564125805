<div class="questionnaire-list flex flex-col justify-items-center px-[5vw] pt-0 pb-[2vh]">
  <ng-container>
    <mat-tab-group
      color="accent"
      class="flex flex-col"
      mat-align-tabs="center"
      #tabRef
      [(selectedIndex)]="modelsOrProjects"
    >
      <mat-tab label="Models" class="">
        <ng-template mat-tab-label>
          <h1 class="font-poppins text-3xl text-card">
            {{ "DICTIONARY.Modelli" | translate | titlecase }}
          </h1>
        </ng-template>
        <app-models-table
          [lang]="lang"
          [dataSource]="models | async"
          (showProjectsSelected)="showProjectsSelected()"
          (showModelSelected)="showModelSelected()"
          tab="0"
        ></app-models-table>
      </mat-tab>
      <mat-tab label="Projects">
        <ng-template mat-tab-label>
          <h1 class="font-poppins text-3xl text-card">
            {{ "DICTIONARY.Progetti" | translate | titlecase }}
          </h1>
        </ng-template>
        <app-projects-table
          (showModelSelected)="showModelSelected()"
          tab="1"
          [data]="projects | async"
          [lang]="lang"
          [totalPageSizeAndIndex]="totalQuestAssignedAndPageIndex | async"
          [loadingProjects]="loadingProjects | async"
        >
        </app-projects-table>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>
