import { Component, HostListener } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { resetPassword as resetAction } from 'src/app/Store/login-store/login.actions';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  usernameFormControl = new FormControl('', [Validators.required]);

  passwordFormControl = new FormControl('', [Validators.required]);

  error: any;
  hide = true;

  constructor(public store: Store, public router: Router) {}

  @HostListener('document:keyup.enter')
  onkeyup() {
    this.resetPassword();
  }

  // login user and get jwt token from passport
  resetPassword() {
    if (this.usernameFormControl.valid && this.passwordFormControl.valid) {
      this.store.dispatch(
        resetAction({
          username: this.usernameFormControl.value,
          password: this.passwordFormControl.value,
        })
      );
    } else {
      this.error = 'Please fill out the required fields';
    }
  }
}
