export function getPositionOfParentQuestion(topics, QuestionID) {
  return topics.Questions.find((o) => o.id === QuestionID).Position;
}

export function getTypeOfParentQuestion(topics, QuestionID) {
  return topics.Questions.find((o) => o.id === QuestionID).TypeOfQuestion;
}

export function getTitleOfParentQuestion(topics, QuestionID) {
  return topics.Questions.find((o) => o.id === QuestionID).Question_Text;
}
