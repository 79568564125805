import { FormControl } from '@angular/forms';
import { Component,  Input, OnInit } from '@angular/core';
import { QuestionAnswered } from 'src/app/data/models/QuestionAnswered';

@Component({
  selector: 'app-comment-question',
  templateUrl: './comment-question.component.html',
  styleUrls: ['./comment-question.component.scss'],
})
export class CommentQuestionComponent implements OnInit {

  @Input()
  question: QuestionAnswered;

  @Input()
  commentForm: FormControl;

  ngOnInit(): void {}
}
