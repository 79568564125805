import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from '../../models/Project';
import { cloneProjectOptions } from '../../models/request-apis/cloneProjectOptions';
import { updateActiveProjectRequest } from '../../models/request-apis/updateActiveProjectRequest';
import { assignQuestionnaireRequest } from '../../models/request-apis/assignQuestionnaireRequest';
import { User } from '../../models/User';

@Injectable({
  providedIn: 'root',
})
export class PostProjectService {
  constructor(private httpClient: HttpClient) {}

  public cloneProject(projectId: string, title: string, desc: string, options: cloneProjectOptions) {
    return this.httpClient.post('cloneProject', {
      projectId: projectId,
      title: title,
      description: desc,
      options: options,
    });
  }

  public archiveProject(projectId: string) {
    return this.httpClient.post('archiveProject', { projectId: projectId });
  }

  public deleteProject(projectId: any[]) {
    return this.httpClient.post('deleteProject', { projectId: projectId });
  }

  public closeProject(projectId: string) {
    return this.httpClient.post('closeProject', { projectId: projectId });
  }

  public publishProject(projectId: string, projectTitle: String) {
    return this.httpClient.post('publishProject', {
      projectId: projectId,
      projectTitle: projectTitle,
    });
  }

  public publishProjectNoUsersBeforeOrMore(projectId: string, project?: assignQuestionnaireRequest) {
    return this.httpClient.post('publishProjectNoPrevUsers', {
      projectId: projectId,
      project: project,
    });
  }
  public publishProjectDirectly(project?: assignQuestionnaireRequest) {
    return this.httpClient.post('publishProjectDirectly', { project: project });
  }

  public updateDraftProject(project: Project) {
    return this.httpClient.post('updateDraftProject', { project: project });
  }

  public updateActiveProject(project: updateActiveProjectRequest) {
    return this.httpClient.post('updateActiveProject', { project: project });
  }

  public changeUserResponsible(data: { projectId: string; username: string; oldUser: string; assingedId: string }) {
    return this.httpClient.post('updateProjectResponsible', data);
  }

  public addToProjectWatchList(data: { projectId: string; users: User[] }) {
    return this.httpClient.post('projectWatchlist', data);
  }

  public notifyUncompletedProjectsResponsibles(data: { projectId: string; message: string; fullName: string }) {
    return this.httpClient.post('notifyAllUnfinishedResponsibles', data);
  }
}
