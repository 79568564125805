<div *ngIf="data.Info === infotype.questionTypeOfExistingInfo" class="flex h-full flex-col justify-between gap-7 text-dialog">
    <p class="text-2xl font-semibold text-dialog">{{ "ATTENZIONE" | translate }}</p>
    <p>{{"MODEL_TABLE.POP_UP_CHANGE_QUESTION" | translate}}</p>
    <div class="flex justify-end">
        <button class="mr-3 whiteBtn" (click)="cancel()">{{"ANNULLA"|translate}}</button>
        <button class="colorBtn" (click)="save()">{{"CONFERMA"|translate}}</button>
    </div>
</div>

<div class="flex h-full flex-col justify-between gap-7 text-dialog" *ngIf="data.Info === infotype.textQuestionInfo">
    <h2 class="text-3xl font-semibold text-dialog">
        {{ "QUEST_CREATION.QUEST_POP_CONTROL_TOOL" | translate }}
    </h2>
    <p class="py-4 text-xl text-black">
        {{ "QUEST_CREATION.QUEST_POP_CONTROL_DESC" | translate }}
    </p>
    <div class="flex justify-end">
        <button class="colorBtn" (click)="cancel()">
      {{ "UNDERSTOOD" | translate }}
    </button>
    </div>
</div>
