import { FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-check-list-question',
  templateUrl: './check-list-question.component.html',
  styleUrls: ['./check-list-question.component.scss'],
})
export class CheckListQuestionComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  lang: string;
  @Input()
  clistBoxForm: FormControl;

  plainText = '';

  answersToShow = [];
  answersPicked = [];

  showOptions = false;
  showDescription = false;

  formValid: Boolean;
  minAnswers: number;
  maxAnswers: number;

  messageDisplayed: String;
  messageDisplayedENG: String;
  titleDisplayed: String;
  titleDisplayedENG: String;

  constructor(private clipboardc: Clipboard, private _snackBar: MatSnackBar, public translate: TranslateService) {
    super(clipboardc);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.plainText = this.extractContent(this.question.Description).replace(/  /gi, ' ');
    this.minAnswers = this.question.minimum_answers || 1;
    this.maxAnswers = this.question.maximum_answers || 10;
    this.messageDisplay(this.minAnswers, this.maxAnswers);

    this.question.Check_List_Box_Options.forEach((option) => {
      this.question.Answers.forEach((answer) => {
        if (answer.Answer_id === option['id']) {
          this.answersToShow.push(option);
          this.answersPicked.push(option);
        }
      });
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 1500 });
  }

  setValue(option) {
    if (this.answersPicked.includes(option)) {
      const index = this.answersPicked.findIndex((val) => val.id === option.id);
      this.answersPicked.splice(index, 1);
    } else {
      // Correct order of Answers based on qustion
      this.answersPicked.push(option);
      let orderedCorrectlyAnswers = [];
      this.question.Check_List_Box_Options.forEach((element) => {
        if (this.answersPicked.filter((answer) => answer === element).length > 0) {
          orderedCorrectlyAnswers.push(element);
        }
      });
      this.answersPicked = orderedCorrectlyAnswers;
    }
    if (this.answersPicked.length < this.minAnswers) {
      this.formValid = false;
    } else if (this.answersPicked.length >= this.minAnswers && this.answersPicked.length <= this.maxAnswers) {
      this.formValid = true;
    } else {
      this.formValid = false;
    }
  }

  toggleModelVisibility() {
    this.showOptions = !this.showOptions;
    // Keep only the old options (after cancel button clicked)
    if (this.showOptions === false) {
      // deepCopy
      this.answersPicked = [];
      this.answersToShow.forEach((option) => {
        this.answersPicked.push(option);
      });
    }
  }

  toggleDescriptionVisibility() {
    this.showDescription = !this.showDescription;
  }

  extractContent(stringToConvert) {
    var span = document.createElement('span');
    span.innerHTML = stringToConvert;
    return span.textContent || span.innerText;
  }

  getCheckListBoxAnswer() {
    this.showOptions = false;
    // When you click Conform save to answerToShow deepCopy
    this.answersToShow = [];
    let checkedBoxIds = [];
    this.answersPicked.forEach((option) => {
      this.answersToShow.push(option);
      checkedBoxIds.push(option['id']);
    });
    // Set final value in formControl (only the ids)
    this.clistBoxForm.setValue({ Input: checkedBoxIds });
  }

  messageDisplay(minAnswers: number, maxAnswers: number) {
    if (minAnswers === maxAnswers && maxAnswers === 1) {
      this.titleDisplayed = `Seleziona una Risposta`;
      this.titleDisplayedENG = `Select an Answer`;
      this.messageDisplayed = `Seleziona ${minAnswers} risposta.`;
      this.messageDisplayedENG = `Select ${minAnswers} answer.`;
    } else if (minAnswers === maxAnswers && minAnswers > 1) {
      this.titleDisplayed = `Seleziona più risposte`;
      this.titleDisplayedENG = `Select an Answer`;
      this.messageDisplayed = `Seleziona ${minAnswers} risposte.`;
      this.messageDisplayedENG = `Select ${minAnswers} answers.`;
    } else if (minAnswers === 1) {
      this.titleDisplayed = `Seleziona più risposte`;
      this.titleDisplayedENG = `Select an Answer`;
      this.messageDisplayed = `Seleziona almeno ${minAnswers} risposta, massimo ${maxAnswers}.`;
      this.messageDisplayed = `Select at least ${minAnswers} answer, utmost ${maxAnswers}.`;
    } else {
      this.titleDisplayed = `Seleziona più risposte`;
      this.titleDisplayedENG = `Select an Answer`;
      this.messageDisplayed = `Select at least ${minAnswers} answers, utmost ${maxAnswers}.`;
    }
  }
}
