<div class="app-container">
  <ng-container *ngIf="isUserLoggedIn | async">
    <app-header *ngIf="customazationLoaded"></app-header>
    <app-footer *ngIf="customazationLoaded"></app-footer>
  </ng-container>
</div>
<!-- <router-outlet></router-outlet> -->
<div
  [ngClass]="{
    'body-height': isUserLoggedIn | async,
    'app-body0': appSvgTheme !== 1 && appSvgTheme !== 2,
    'app-body1': appSvgTheme === 1,
    'app-body2': appSvgTheme === 2
  }"
>
  <router-outlet *ngIf="customazationLoaded && dictionaryLoaded"></router-outlet>
</div>
<simple-notifications></simple-notifications>
