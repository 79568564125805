import { createAction, props } from '@ngrx/store';
import { QuestionnairesAssigned } from 'src/app/data/models/QuestionnairesAssigned';
import { QuestionnairesAssignedToUser } from 'src/app/data/models/QuestionnairesAssignedToUser';
import { Answers } from 'src/app/data/models/request-apis/Answers';
import { createNotes } from 'src/app/data/models/request-apis/createNotes';
import { requestNotes } from 'src/app/data/models/request-apis/requestNotes';
import { updateAssignmentData } from 'src/app/data/models/request-apis/updateAssignmentData';
import { getNotesResponse } from 'src/app/data/models/response-apis/getNotesResponse';
import { homeQuestionnaire } from 'src/app/data/models/response-apis/homeQuestionnaire';

export const enum QuestionnaireActionTypes {
  exportQuestionnaire = '[AssignedQuestionnaires] export answersofQuestionnaire',
  exportQuestionnaireSuccess = '[AssignedQuestionnaires] export answersofQuestionnaire success',
  postAnswersOfQuestionnaire = '[AssignedQuestionnaires] post answersofQuestionnaire',
  postAnswersOfQuestionnaireSuccess = '[AssignedQuestionnaires] post answersofQuestionnaire success',
  deleteAssignQuestionnairesAction = '[AssignedQuestionnaires] delete AssignedQuestionnairess',
  deleteAssignQuestionnairesSuccess = '[AssignedQuestionnaires] delete AssignedQuestionnairess success',
  updateAssingedQuestionnaire = '[AssignedQuestionnaires] update AssignedQuestionnairess',
  updateAssingedQuestionnaireSuccess = '[AssignedQuestionnaires] update AssignedQuestionnairess Success',
  loadAssignedQuestionnaires = '[AssignedQuestionnaires] Load AssignedQuestionnairess',
  loadAssignedQuestionnairessSuccess = '[AssignedQuestionnaires] Load AssignedQuestionnairess Success',
  loadAssignedQuestionnairessFailure = '[AssignedQuestionnaires] Load AssignedQuestionnairess Failure',
  changeUsernames = '[Assigned Questionnaires] change usernames',
  getQuestionnaireAssingedToUser = '[Questionnaires] get questionnaires assigned to User',
  getQuestionnaireAssingedToUserSuccess = '[Questionnaires] get questionnaires assigned to User Success',
  getQuestionnaireToBeAnswered = '[Questionnaires] get questionnaires to be answered',
  getQuestionnaireToBeAnsweredrSuccess = '[Questionnaires] get questionnaires to be answered User Success',
  emptyQuestionnaireToBeAnswered = '[Questionnaires] empty questionnaires to be answered',
  reAssignTopic = '[Topics] reAssign Topic',
  reAssignmentTopicSuccess = '[Topics] reAssign Topic success',
  getQuestionnaireNotes = '[AssignedQuestionnaires] get the notes ',
  getQuestionnaireNotesSuccess = '[AssignedQuestionnaires] get the notes succesfully',
  SubmitQuestionnaires = '[AssignedQuestionnaires] questionnaire submitted',
  SubmitQuestionnairesSuccess = '[AssignedQuestionnaires] questionnaire submitted success',
  SubmitNotes = '[AssignedQuestionnaires] note submitted',
  SubmitNotesSucess = '[AssignedQuestionnaires] note submitted success',
  ReadNotes = '[AssignedQuestionnaires] note readed',
  ReadNotesSuccess = '[AssignedQuestionnaires] note readed Success',
  ERROR = '[AssignedQuestionnaires] error',
  ERRORHANDLED = '[AssignedQuestionnaires] error handled',
  exportSingleCsv = '[AssignedQuestionnaires] export Questionnaire Assigned in excel format',
}

export const ERROR = createAction(QuestionnaireActionTypes.ERROR);
export const errorHandled = createAction(QuestionnaireActionTypes.ERRORHANDLED);

export const exportSingleCsv = createAction(
  QuestionnaireActionTypes.exportSingleCsv,
  props<{ id: string; lang: string; topic: string; downloadFiles: boolean }>()
);

export const SubmitQuestionnaires = createAction(
  QuestionnaireActionTypes.SubmitQuestionnaires,
  props<{ ids: { id: string }[] }>()
);

export const SubmitNotes = createAction(QuestionnaireActionTypes.SubmitNotes, props<{ notes: createNotes }>());

export const ReadNotes = createAction(QuestionnaireActionTypes.ReadNotes, props<{ quest_id: string }>());

export const ReadNotesSuccess = createAction(QuestionnaireActionTypes.ReadNotesSuccess);

export const SubmitNotesSucess = createAction(QuestionnaireActionTypes.SubmitNotesSucess);

export const SubmitQuestionnairesSuccess = createAction(QuestionnaireActionTypes.SubmitQuestionnairesSuccess);

export const getQuestionnaireNotesSuccess = createAction(
  QuestionnaireActionTypes.getQuestionnaireNotesSuccess,
  props<{ notes: getNotesResponse }>()
);

export const getQuestionnaireNotes = createAction(
  QuestionnaireActionTypes.getQuestionnaireNotes,
  props<{ data: requestNotes }>()
);

export const reAssignTopic = createAction(
  QuestionnaireActionTypes.reAssignTopic,
  props<{ id: string; note: string }>()
);

export const reAssignmentTopicSuccess = createAction(QuestionnaireActionTypes.reAssignmentTopicSuccess);

export const emptyQuestionnaireToBeAnswered = createAction(QuestionnaireActionTypes.emptyQuestionnaireToBeAnswered);

export const postAnswersOfQuestionnaire = createAction(
  QuestionnaireActionTypes.postAnswersOfQuestionnaire,
  props<{ data: Answers; status: string }>()
);

export const postAnswersOfQuestionnaireSuccess = createAction(
  QuestionnaireActionTypes.postAnswersOfQuestionnaireSuccess,
  props<{
    data: {
      Topic_Id: string;
      message: string;
      answers: any[];
      questStatus: string;
      topicStatus: string;
    };
  }>()
);

export const getQuestionnaireToBeAnsweredSuccess = createAction(
  QuestionnaireActionTypes.getQuestionnaireToBeAnsweredrSuccess,
  props<{
    data: {
      questionnaire: QuestionnairesAssignedToUser;
      topicMetrics: {
        allTopics: number;
        completedTopics: number;
        completedTopicsOfUser: number;
        topicsOfUser: number;
      };
    };
  }>()
);

export const getQuestionnaireToBeAnswered = createAction(
  QuestionnaireActionTypes.getQuestionnaireToBeAnswered,
  props<{ id: string }>()
);

export const getQuestionnaireAssingedToUserSuccess = createAction(
  QuestionnaireActionTypes.getQuestionnaireAssingedToUserSuccess,
  props<{ data: homeQuestionnaire[] }>()
);

export const getQuestionnaireAssingedToUser = createAction(
  QuestionnaireActionTypes.getQuestionnaireAssingedToUser,
  props<{ id: string; pageIndex: number }>()
);

export const updateAssingedQuestionnaireSuccess = createAction(
  QuestionnaireActionTypes.updateAssingedQuestionnaireSuccess
);

export const deleteAssignQuestionnairesAction = createAction(
  QuestionnaireActionTypes.deleteAssignQuestionnairesAction,
  props<{ deletedIds: QuestionnairesAssigned[]; pageIndex: number }>()
);

export const deleteAssignQuestionnairesSuccess = createAction(
  QuestionnaireActionTypes.deleteAssignQuestionnairesSuccess
);

export const updateAssingedQuestionnaire = createAction(
  QuestionnaireActionTypes.updateAssingedQuestionnaire,
  props<{
    assignmentData: updateAssignmentData;
    pageIndex: number;
    flag?: boolean;
  }>()
);

export const loadAssignedQuestionnaires = createAction(
  QuestionnaireActionTypes.loadAssignedQuestionnaires,
  props<{ pageIndex: number; title?: string; AscDesc?: string }>()
);

export const loadAssignedQuestionnairessSuccess = createAction(
  QuestionnaireActionTypes.loadAssignedQuestionnairessSuccess,
  props<{
    AssignedQuestionnaires: QuestionnairesAssigned[];
    total: number;
    pageIndex: number;
  }>()
);

export const loadAssignedQuestionnairessFailure = createAction(
  QuestionnaireActionTypes.loadAssignedQuestionnairessFailure,
  props<{ error: any }>()
);

export const exportQuestionnaire = createAction(
  QuestionnaireActionTypes.exportQuestionnaire,
  props<{ questionnaireAssignedId: string }>()
);

export const exportQuestionnaireSuccess = createAction(QuestionnaireActionTypes.exportQuestionnaireSuccess);
