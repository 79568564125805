export const displayedColumnsQuestionnairesStructure: string[] = [
  'Checkbox',
  'Questionnaire_Status',
  'Questionnaire_Title',
  'Number_Of_Topics',
  'Icon',
];

export const TableColumnActions: string[] = ['Modifica Modello', 'Assegna Modello', 'Duplica Modello'];

export const displayedColumnsQuestionnairesAssigned: string[] = [
  'Checkbox',
  'Assigned_Status',
  'Questionnaire_Title',
  'Number_Of_Topics',
  'Project',
  'Assigned_To',
  'Collaborative',
  'Expiry_Date',
  'Icon',
];

export const displayedColumnsElencoTopic: string[] = ['Order', 'Title', 'Number_Of_Questions', 'Icon'];

export const displayedEmbeddedTopic: string[] = [
  'Assigned_Status',
  'Title',
  'Number_Of_Questions',
  'Assigned_To',
  'Expiry_Date',
  'Icon',
];

export const displayedColumnsInnerElencoTopic: string[] = [
  'Position',
  'Question_Text',
  'TypeOfQuestion',
  'Obligatory',
  'Constrained',
  'Icon',
];

export const displayedUserColumns: string[] = ['Checkbox', 'Name', 'Surname', 'Email'];

export const displayedAdminTableColumns: string[] = ['user', 'action', 'date'];

export const ProfileUserColumns: string[] = ['Name', 'Surname', 'Email', 'active', 'Actions'];

export const enum TopicTableOptionsEnum {
  ADDQUESTION = 'ADD QUESTION',
  DELETETOPIC = 'DELETE TOPIC',
  DUPLICATETOPIC = 'DUPLICATE TOPIC',
  MODIFY = 'MODIFY',
  NOTES = 'GET NOTES',
}

export const enum typeOfQuestionDeletion {
  DELETEONLYBINDING = 'Delete only Binding',
  DELETEALL = 'Delete All',
  DELETESIMPLEQUESTION = 'Delete Question',
}

export const enum TopicStatus {
  DRAFT = 'Draft',
  COMPLETED = 'Completed',
  ASSIGNED = 'Assigned',
  ARCHIVED = 'Archived',
}

export const englishToItalian: { [id: string]: string } = {
  Order: 'Ordine Visualizzazione',
  Position: 'Ordine Visualizzazione',
  Number_Of_Questions: 'N. Domande',
  Title: 'Titolo',
  Question_Text: 'Titolo',
  Constrained: 'Vincolata',
  Obligatory: 'Obbligatoria',
  TypeOfQuestion: 'Tipo Domanda',
  Questionnaire_Status: 'Stato',
  Assigned_Status: 'Stato',
  Questionnaire_Title: 'Titolo',
  Number_Of_Topics: 'N. Topics',
  Project: 'Progetto',
  Assigned_To: 'Assegnatario',
  Collaborative: 'Collaborativo',
  Checkbox: '',
  Expiry_Date: 'Data Scadenza',
  Name: 'Nome',
  Surname: 'Cognome',
  Completed: 'Completato',
  In_Progress: 'In corso',
  'In Progress': 'In corso',
  Assigned: 'Non Completato',
  Draft: 'Draft',
  'In Review': 'In Review',
  Archived: 'Archiviato',
  Submitted: 'Sottomesso',
  'Free text': 'Text box',
  'List box': 'List box',
  'Check-list box': 'Check-list box',
  Customized: 'Personalizzata',
  Data: 'Data',
  'E-mail': 'E-mail',
  Ora: 'Ora',
  URL: 'URL',
  Active: 'Attivo',
  CompletedModels: 'Attivo',
  Closed: 'Chiuso',
  'Sorry, something went wrong! This title exists already!':
    'Spiacente, qualcosa è andato storto!Questo titolo è già presente!',
  'Sorry, something went wrong! This title exists already to another Questionnaire!':
    'Spiacente, qualcosa è andato storto!Questo titolo è già presente!',
  'You have to assign at least one authorization key': 'Devi assegnare almeno una chiave di autorizzazione',
  'Please select at least one user and an expiration date': 'Seleziona almeno un utente e una data di scadenza',
  'Please assign at least one user': 'Assegnare almeno un utente',
};

export const englishToItalianWithTitle: { [id: string]: string } = {
  Topic_AS_removed: 'Non sei più assegnato a un Topic del questionario ',
  Topic_AS_added: 'Sei stato assegnato a un Topic del questionario ',
  Multiple_Topic_AS_added: 'Sei stato assegnato ad alcuni Topics del questionario',
  Multiple_Topic_AS_removed: 'Non sei più assegnato ad alcuni Topics del questionario',
  All_Topics_AS_removed: 'Non sei più assegnato ad alcuni Topics del questionario ',
  All_Topics_AS_added: 'Sei stato assegnato ad alcuni Topics del questionario ',
  Project_created: 'Sei ora il responsabile questionario ',
  User_added: 'Sei ora il responsabile questionario ',
  User_deleted: 'Non sei più il responsabile del questionario ',
  Project_Res_Removed: 'Non sei più il responsabile del progetto ',
  Project_Res_Added: 'Sei ora il responsabile del progetto ',
  Questionnaire_withTitle: 'Il tuo questionario con titolo ',
  Of_project: 'nel progetto ',
};
