import { Project } from './Project';
import { TopicsAnswered } from './TopicsAnswered';

export class QuestionnairesAssignedToUser {
  id?: string;
  Project?: string;
  Questionnaire_Title?: string;
  Description?: string;
  Topics?: TopicsAnswered[];
  Number_Of_Topics?: number;
  Icon?: string;
  Assigned_To: string;
  PROJECT: Project;
  PROJECT_DESCRIPTION: string;
  RESPONSIBLE_REASSIGN?: boolean;
  ALL_REASSIGN: boolean;
  VIEWABLE_FROM_ALL: boolean;
  Assigned_id: string;
  Expiry_Date: string;
  Assigned_Status: string;
  Collaborative: boolean;
  Completed_Topics?: number;
  TopicsAssignedToCurrentUser?: number;
  Number_Of_Notes?: number;
  Number_of_Readed_Notes?: number;
  Completed_Topics_Of_User: number;
  EXPORTABLE_DATA: boolean;
}
