<div id="upload-popup">
  <div
    id="selections-div"
    class="px-6 py-12 w-[27vw] min-w-[fit-content] h-[fit-content] rounded-3xl bg-[#f5f7f991] flex flex-col gap-6 items-center justify-center overflow-hidden relative"
  >
    <button (click)="closeDialog()" mat-icon-button class="!absolute top-0 right-0 !m-4 !text-card">
      <mat-icon>close</mat-icon>
    </button>

    <div class="flex justify-start items-center w-full">
      <div class="flex flex-col gap-2 items-start justify-center">
        <span id="title" class="text-card font-bold text-4xl font-popppins">File Repository</span>
        <span id="subtitle" class="text-[#707070] text-2xl font-popppins">{{ data.Quest_Assign_Title }}</span>
      </div>
    </div>

    <ng-container *ngIf="isFileLoading$ | async">
      <app-spinner [width]="70" [height]="70"></app-spinner>
    </ng-container>

    <!-- Table -->
    <ng-container *ngIf="!(isFileLoading$ | async) && (isFileStored$ | async)">
      <div id="search-bar" class="w-full">
        <mat-form-field appearance="outline" class="w-full">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
      <div id="table-container" class="mat-elevation-z0">
        <mat-table #table [dataSource]="dataSource" matSort>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let file" class="!text-base">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? onSelectionChange(file, $event.checked) : null"
                [checked]="selection.isSelected(file)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="topic">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Topic </mat-header-cell>
            <mat-cell *matCellDef="let file" class="!text-base">
              <span class="text-start max-w-full line-clamp-2" matTooltip="{{ file.TOP_NAME }}" appShowIfTruncated>{{
                file.TOP_NAME
              }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="question">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Question </mat-header-cell>
            <mat-cell *matCellDef="let file" class="!text-base">
              <span
                class="text-start max-w-full line-clamp-2"
                matTooltip="{{ file.QUESTION_NAME }}"
                appShowIfTruncated
                >{{ file.QUESTION_NAME }}</span
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef> Comment </mat-header-cell>
            <mat-cell *matCellDef="let file" class="!text-base">
              <span class="text-start max-w-full line-clamp-2" matTooltip="{{ file.COMMENT }}" appShowIfTruncated>{{
                file.COMMENT ? file.COMMENT : "- - -"
              }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="file">
            <mat-header-cell *matHeaderCellDef mat-sort-header> File </mat-header-cell>
            <mat-cell *matCellDef="let file" class="!text-base">
              <span class="text-start max-w-full line-clamp-2" matTooltip="{{ file.name }}" appShowIfTruncated>{{
                file.name
              }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef> Creation Date </mat-header-cell>
            <mat-cell *matCellDef="let file" class="!text-base">
              <span class="text-start">{{ file.DATE_CREATED | date: "yyyy-MM-dd" }}</span>
            </mat-cell>
          </ng-container>
        </mat-table>
      </div>
      <div class="flex items-center justify-end w-full gap-4">
        <button class="whiteBtn" (click)="closeDialog()">Cancel</button>
        <button
          class="colorBtn disabled:colorBtn-disabled flex justify-center items-center"
          (click)="downloadFile()"
          [disabled]="selectedFiles.length === 0 || (selectDownloading | async)"
        >
          <span>Download</span>
          <app-spinner
            *ngIf="selectDownloading | async"
            [width]="20"
            [height]="20"
            [color]="'white'"
            class="ml-4"
          ></app-spinner>
        </button>
      </div>
    </ng-container>

    <div
      class="flex items-center justify-center w-full gap-4"
      *ngIf="!(isFileLoading$ | async) && !(isFileStored$ | async)"
    >
      <mat-icon class="!text-dialog">error</mat-icon>
      <span class="text-xl">No File uploaded yet</span>
    </div>
  </div>
</div>
