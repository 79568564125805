import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import * as AssignedQuestionnairesActions from '../actions/assigned-questionnaires.actions';
import { QuestionnaireActionTypes } from '../actions/assigned-questionnaires.actions';
import { HomePageActionTypes } from '../../home-page/home-page.actions';
import { Router } from '@angular/router';
import { GetQuestionnairService } from 'src/app/data/api/Questionnaire/get-questionnaires.service';
import { PostQuestionnaireService } from 'src/app/data/api/Questionnaire/post-questionnaire.service';
import {
  convertDataToSubmitNotes,
  createAssignedDeleteArray,
  convertToQuestionnaireAssingedToUser,
} from 'src/app/data/data-handlers/utils.service';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
import { NotifyService } from 'src/app/utils/notification.service';
import { getAssignment } from 'src/app/data/models/response-apis/getAssignment';

@Injectable()
export class AssignedQuestionnairesEffects {
  exportSingleCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuestionnaireActionTypes.exportSingleCsv),
        tap((payload) =>
          this.getQuestService.exportSingleCsv(
            payload['data'][0],
            payload['lang'],
            payload['topic'],
            payload['downloadFiles']
          )
        )
      ),
    { dispatch: false }
  );

  loadAssignedQuestionnairess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssignedQuestionnairesActions.loadAssignedQuestionnaires),
      concatMap((payload) =>
        this.getQuestService
          .getQuestionnairesAssigned('username', payload['pageIndex'], 10, payload['title'], payload['AscDesc'])

          .pipe(
            map((data) => ({
              type: QuestionnaireActionTypes.loadAssignedQuestionnairessSuccess,
              AssignedQuestionnaires: data['data'],
              total: data['length'],
              pageIndex: data['pageIndex'],
            })),
            catchError((err) => {
              this.notifyService.openWarningSwal('Error occured, assignments could not be loaded');
              this.route.navigateByUrl(`/${ROUTES_STRINGS.HOME}​​​​​`);
              return of(AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({ error: err }));
            })
          )
      )
    );
  });

  updateAssignedQuestionnairess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssignedQuestionnairesActions.updateAssingedQuestionnaire),
      concatMap((payload) =>
        this.postQuestService
          .updateAssignQuestionnaires({
            obj: payload.assignmentData,
            flag: payload.flag,
          })
          .pipe(
            map(() => ({
              type: QuestionnaireActionTypes.loadAssignedQuestionnaires,
              pageIndex: payload.pageIndex,
            })),
            catchError((err) => {
              this.notifyService.openWarningSwal('Error occured, assingment could not be updated');
              return of({ type: QuestionnaireActionTypes.ERROR });
            })
          )
      )
    );
  });

  createNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.SubmitNotes),
      concatMap((payload) =>
        this.postQuestService.createNotes(convertDataToSubmitNotes(payload['notes'])).pipe(
          map(() => ({ type: QuestionnaireActionTypes.SubmitNotesSucess })),
          catchError((err) => {
            this.notifyService.openWarningSwal(
              'Impossibile aggiungere note. Si è verificato un errore. Per favore riprova più tardi'
            );
            return throwError(err);
          })
        )
      )
    );
  });

  ReadNotesEffects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.ReadNotes),
      concatMap((payload) =>
        this.postQuestService.readNotes({ Assigned_id: payload['quest_id'] }).pipe(
          map(() => ({ type: QuestionnaireActionTypes.ReadNotesSuccess })),
          catchError((err) => {
            this.notifyService.openWarningSwal(
              'Impossibile leggere le note. Si è verificato un errore. Per favore riprova più tardi'
            );
            return throwError(err);
          })
        )
      )
    );
  });

  getNotesEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.getQuestionnaireNotes),
      concatMap((payload) =>
        this.getQuestService.getNotes(payload).pipe(
          map((notes) => ({
            type: QuestionnaireActionTypes.getQuestionnaireNotesSuccess,
            data: notes,
          })),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, project could not be retrieved');
            return of(
              AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({
                error: err,
              })
            );
          })
        )
      )
    );
  });

  deleteQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.deleteAssignQuestionnairesAction),
      mergeMap((payload) =>
        this.postQuestService.deleteAssignedQuestionnaire(createAssignedDeleteArray(payload['deletedIds'])).pipe(
          map(() => ({
            type: QuestionnaireActionTypes.loadAssignedQuestionnaires,
            pageIndex: payload['pageIndex'],
          })),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, questionnaire could not be deleted');
            return of(AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({ error: err }));
          })
        )
      )
    );
  });

  getToUsersAssingedQuestionnaires$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        QuestionnaireActionTypes.getQuestionnaireAssingedToUser,
        QuestionnaireActionTypes.SubmitQuestionnairesSuccess
      ),
      mergeMap((payload) =>
        this.getQuestService.getQuestionnaireByUser({ pageIndex: 0 }).pipe(
          switchMap((datacame) => [
            {
              type: QuestionnaireActionTypes.getQuestionnaireAssingedToUserSuccess,
              data: datacame['data'],
            },
            {
              type: HomePageActionTypes.loadQuestionnairesHomepageSuccess,
              total: datacame['length'],
              pageIndex: !!datacame['pageIndex'] ? datacame['pageIndex'] : 0,
            },
          ]),
          catchError((err) => {
            this.notifyService.openWarningSwal("Error occured, user's questionnaire could not be retrieved");
            return of(
              AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({
                error: err,
              })
            );
          })
        )
      )
    );
  });

  getFullQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.getQuestionnaireToBeAnswered),
      mergeMap((payload) =>
        this.getQuestService.getFullQuestionnaire(payload['id']).pipe(
          map((datacame: getAssignment) => ({
            type: QuestionnaireActionTypes.getQuestionnaireToBeAnsweredrSuccess,
            data: convertToQuestionnaireAssingedToUser(datacame),
          })),
          catchError((err) => {
            this.route.navigateByUrl(`/${ROUTES_STRINGS.HOME}​​​​​`);
            this.notifyService.openWarningSwal('Error occured, questionnaire could not be retrieved');
            return of(
              AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({
                error: err,
              })
            );
          })
        )
      )
    );
  });

  PostAnswers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.postAnswersOfQuestionnaire),
      mergeMap((payload) =>
        this.postQuestService.postAnswers(payload).pipe(
          map(
            (payload: {
              Topic_Id: string;
              message: string;
              topicMetrics: {
                allTopics: number;
                completedTopics: number;
                completedTopicsOfUser: number;
                topicsOfUser: number;
              };
              questStatus: string;
              topicStatus: string;
            }) => ({
              type: QuestionnaireActionTypes.postAnswersOfQuestionnaireSuccess,
              data: payload,
            })
          ),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, questionnaire could not be saved, try again later');
            return of(
              AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({
                error: err,
              })
            );
          })
        )
      )
    );
  });

  SubmittedQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.SubmitQuestionnaires),
      mergeMap((payload) =>
        this.postQuestService.submitQuestionnaires(payload['ids']).pipe(
          map(() => {
            return {
              type: QuestionnaireActionTypes.SubmitQuestionnairesSuccess,
            };
          }),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, questionnaire submition failed');
            return of(
              AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({
                error: err,
              })
            );
          })
        )
      )
    );
  });

  ExportQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActionTypes.exportQuestionnaire),
      mergeMap((payload) =>
        this.postQuestService.exportQuestionnaire(payload['questionnaireAssignedId']).pipe(
          map(() => {
            return {
              type: QuestionnaireActionTypes.exportQuestionnaireSuccess,
            };
          }),
          catchError((err) => {
            this.notifyService.openWarningSwal('Error occured, questionnaire submition failed');
            return of(AssignedQuestionnairesActions.loadAssignedQuestionnairessFailure({ error: err }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private route: Router,
    private getQuestService: GetQuestionnairService,
    private postQuestService: PostQuestionnaireService,
    private notifyService: NotifyService
  ) {}
}
