import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indefiniteArticleDictionary',
})
export class IndefiniteArticleDictionaryPipe implements PipeTransform {
  isVowel(value: string): boolean {
    var charToCheck = value.charAt(0).toLowerCase();
    return (
      charToCheck === 'a' || charToCheck === 'e' || charToCheck === 'i' || charToCheck === 'o' || charToCheck === 'u'
    );
  }

  transform(value: string, pluralCheck: string, languageCheck: string): string {
    if (languageCheck === 'en') {
      return value;
    }

    var stringToCheck = '';
    var counter = value.indexOf(' '); // check number of words in string

    if (counter === -1) {
      // one word
      stringToCheck = value;
    } else {
      // 2+ words
      stringToCheck = value.substring(0, counter); // first word
    }

    var firstChar = stringToCheck.charAt(0).toLowerCase();
    var secondChar = stringToCheck.charAt(1).toLowerCase();
    var firstTwoChar = stringToCheck.substring(0, 2).toLowerCase();
    var lastChar = stringToCheck.charAt(stringToCheck.length - 1).toLowerCase();

    if (pluralCheck === 'singular') {
      // singular

      if (this.isVowel(firstChar) && lastChar === 'a') {
        return "un'" + value;
      } else {
        if (lastChar === 'a') {
          return 'una ' + value;
        } else if (
          firstChar === 'z' ||
          firstChar === 'x' ||
          firstChar === 'y' ||
          ((firstChar === 'i' || firstChar === 'j') && this.isVowel(secondChar)) ||
          (firstChar === 's' && !this.isVowel(secondChar)) ||
          (!this.isVowel(firstChar) &&
            firstChar !== 's' &&
            !this.isVowel(secondChar) &&
            secondChar !== 'l' &&
            secondChar != 'r') ||
          firstTwoChar === 'gn' ||
          firstTwoChar === 'ps' ||
          firstTwoChar === 'pn'
        ) {
          return 'uno ' + value;
        } else {
          return 'un ' + value;
        }
      }
    } else if (pluralCheck === 'plural') {
      // plural

      if (lastChar === 'e') {
        return 'le ' + value;
      } else if (
        this.isVowel(firstChar) ||
        firstChar === 'z' ||
        firstChar === 'x' ||
        firstChar === 'y' ||
        ((firstChar === 'i' || firstChar === 'j') && this.isVowel(secondChar)) ||
        (firstChar === 's' && !this.isVowel(secondChar)) ||
        (!this.isVowel(firstChar) &&
          firstChar !== 's' &&
          !this.isVowel(secondChar) &&
          secondChar !== 'l' &&
          secondChar != 'r') ||
        firstTwoChar === 'gn' ||
        firstTwoChar === 'ps' ||
        firstTwoChar === 'pn'
      ) {
        return 'gli ' + value;
      } else {
        return 'i ' + value;
      }
    } else {
      return value;
    }
  }
}
