import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Docufile } from 'src/app/data/models/Docufile';

@Injectable({
  providedIn: 'root',
})
export class DocufileUplaodService {
  private _currentFileSource = new Subject<any>();
  private _deletedFileSource = new Subject<any>();
  currentFile$ = this._currentFileSource.asObservable();
  deletedFile$ = this._deletedFileSource.asObservable();

  constructor(private httpClient: HttpClient) {}

  sendCurrentFile(file: any) {
    this._currentFileSource.next(file);
  }
  sendDeletedFile(file: any) {
    this._deletedFileSource.next(file);
  }

  uploadFile(
    file: File,
    fileId: string,
    creationDate: string,
    topicName: string,
    questionName: string,
    comment: string,
    questionId: string,
    questAssignId: string,
    deletedFileId?: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('fileId', fileId);
    formData.append('creationDate', creationDate);
    formData.append('topicName', topicName);
    formData.append('questionName', questionName);
    formData.append('comment', comment);
    formData.append('questionId', questionId);
    formData.append('questAssignId', questAssignId);
    formData.append('deletedFileId', deletedFileId);

    const request = new HttpRequest('POST', 'upload-file', formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.httpClient.request(request);
  }

  getFiles(questId: string): Observable<any> {
    return this.httpClient.get(`get-files/${questId}`);
  }

  deleteFile(id: object): Observable<any> {
    return this.httpClient.post(`delete-file`, id);
  }

  downloadFile(id: string): Observable<any> {
    return this.httpClient.get(`download-file/${id}/`, {
      responseType: 'blob' as 'json',
    });
  }

  downloadZip(fileList: Docufile[] | any, questTitle: string): Observable<any> {
    let fileIds = new HttpParams();
    for (let file of fileList) {
      // file.id in "File repository", file immediately in Analytics Details download file popup
      let id = file.id ? file.id : file;
      fileIds = fileIds.append('fileIds', id);
    }

    return this.httpClient.get(`download-zip/${fileIds}/${questTitle}`, {
      responseType: 'blob' as 'json',
    });
  }
}
