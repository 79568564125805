<div class="h-full w-full">
  <ng-container *ngIf="!(loading | async); else Loading">
    <app-create-new-questionnaire
      [formBody]="newQuestionnaire"
      (changesMade)="changes($event)"
      (saveChanges)="modelSaved($event)"
    >
    </app-create-new-questionnaire>
  </ng-container>

  <ng-template #Loading>
    <app-spinner class="h-100 flex justify-center"> </app-spinner>
  </ng-template>
</div>
