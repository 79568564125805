<div class="flex flex-col">
  <div class="flex flex-col gap-10">
    <h4 class="text-3xl font-black text-dialog" *ngIf="local_data.modalTitle">
      {{ local_data.modalTitle }}
    </h4>
    <h4 class="text-3xl font-black text-dialog" *ngIf="!local_data.modalTitle">
      {{ modalTitle }}
    </h4>
    <mat-dialog-content class="h-[fit-content] w-[60vw]">
      <form [formGroup]="formGroup">
        <div *ngIf="!local_data.assignedMode">
          <mat-form-field appearance="outline" class="mb-[1.75rem] w-full pt-4">
            <mat-label>{{ "QUEST_CREATION.QUEST_POP_POSITION_LABEL" | translate }}</mat-label>
            <mat-select
              name="position"
              [(value)]="selected"
              [disabled]="data.dialogData && data.dialogData.modelStatus === 'Archived'"
            >
              <mat-option class="text-black" *ngFor="let position of positions" [value]="position">
                {{ position }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="!local_data.assignedMode">
          <mat-form-field appearance="outline" class="mb-[1.75rem] w-full">
            <mat-label>{{ "QUEST_CREATION.QUEST_POP_ONLY_TITLE" | translate }}</mat-label>
            <input
              type="text"
              matInput
              placeholder="{{ 'QUEST_CREATION.PLACE_TITLE1' | translate }} {{
                'di ' + ('DICTIONARY.SingleTopic' | translate) | prepositionDictionary: 'singular':lang
              }} {{ 'QUEST_CREATION.PLACE_TITLE2' | translate }}"
              name="TopicTitleFormControl"
              formControlName="TopicTitleFormControl"
              #TopicTitle
              maxlength="500"
            />
            <mat-hint align="end">{{ TopicTitle.value.length }}/500</mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="!local_data.assignedMode">
          <mat-form-field appearance="outline" class="mb-[1.75rem] w-full">
            <mat-label>{{ "QUEST_CREATION.QUEST_POP_ONLY_DESC" | translate }}</mat-label>
            <input
              type="text"
              name="TopicDescriptionFormControl"
              matInput
              placeholder="{{ 'QUEST_CREATION.TOPIC_DESCRIPTION1' | translate }} {{
                'di ' + ('DICTIONARY.SingleTopic' | translate) | prepositionDictionary: 'singular':lang
              }} {{ 'QUEST_CREATION.TOPIC_DESCRIPTION2' | translate }}"
              formControlName="TopicDescriptionFormControl"
              #TopicDescription
              maxlength="3000"
            />
            <mat-hint align="end">{{ TopicDescription.value.length }}/3000</mat-hint>
          </mat-form-field>
        </div>
        <ng-container *ngIf="local_data.assignedMode">
          <p>
            <strong style="margin-right: 5px"
              >{{ "QUEST_CREATION.PLACE_TITLE1" | translate }}
              {{ "DICTIONARY.Questionario" | translate }}
              {{ "QUEST_CREATION.PLACE_TITLE2" | translate }} </strong
            ><i>{{ local_data.Quest_Title }}</i>
          </p>
          <hr style="margin-bottom: 30px" />
        </ng-container>
        <ng-container *ngIf="local_data.assignedMode">
          <p>
            <strong style="margin-right: 5px"
              >{{ "QUEST_CREATION.PLACE_TITLE1" | translate }}
              {{ "DICTIONARY.SingleTopic" | translate }}
              {{ "QUEST_CREATION.PLACE_TITLE2" | translate }}</strong
            ><i>{{ local_data.Topic.Title }}</i>
          </p>
          <hr style="margin-bottom: 10px" />
        </ng-container>
        <div *ngIf="local_data.assignedMode">
          <div layout="row" flex>
            <mat-form-field class="">
              <mat-label>{{ usersForAssignment[0].Name + " " + usersForAssignment[0].Surname }}</mat-label>
              <input type="text" matInput [readonly]="true" />
              <button mat-icon-button matSuffix (click)="showUsersTable($event)">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </mat-form-field>
            <ng-container *ngIf="showUsers">
              <app-users-table
                (userToBeAssigned)="getUsersForAssignment($event)"
                [singleSelection]="true"
                [userAlreadyAssigned]="usersForAssignment"
                [disabled]="false"
              >
              </app-users-table>
            </ng-container>
          </div>
          <app-datepick
            (date)="getExpiryDate($event)"
            [maxDate]="local_data.Quest_Exp_Date"
            [topicDate]="local_data.Topic.Expiry_Date"
          >
          </app-datepick>
          <div>
            <mat-form-field>
              <mat-label>{{ "QUEST_CREATION.NOTE" | translate }}</mat-label>
              <input type="text" matInput [readonly]="true" />
              <button mat-icon-button matSuffix (click)="toggleNotes()">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </mat-form-field>
            <div *ngIf="showNotes">
              <mat-form-field>
                <mat-label>{{ "QUEST_CREATION.INSERT_NOTE" | translate }}</mat-label>
                <textarea
                  matInput
                  placeholder="{{ 'QUEST_CREATION.INSERT_COMMENT' | translate }}"
                  [formControl]="NotesFormControl"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="flex items-center justify-end gap-3">
    <button [mat-dialog-close]="true" class="whiteBtn">
      {{
        data.dialogData && data.dialogData.modelStatus !== "Archived"
          ? ("QUEST_CREATION.CANCEL" | translate)
          : ("BACK" | translate)
      }}
    </button>
    <button
      *ngIf="data.dialogData && data.dialogData.modelStatus !== 'Archived'"
      (click)="saveTopic()"
      class="colorBtn"
    >
      {{ "QUEST_CREATION.SAVE" | translate }}
    </button>
  </div>
</div>
